import * as React from 'react'
import { Form, Input, Button, Row, Col, Divider, Radio, Select, InputNumber, DatePicker, Checkbox } from 'antd'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'

interface propsType {
  setCurrent: Function
  setFields: Function
  fields: FormTemplateFieldItem[]
  docreateFormTemplate: Function
}

const vcompsRule = [
  { label: '与初设相同', value: 'equal' },
  { label: '循环', value: 'recircle' },
  { label: '等差', value: 'arithmetic' },
  { label: '无', value: 'none' },
]

const CreateTemplateStepFour = (props: propsType) => {
  const { fields, setCurrent, setFields, docreateFormTemplate } = props

  const [editingField, setEditingField] = React.useState(0)

  const getCheckBoxOptions = (field_index: number) => {
    if (!fields[field_index].multiple_choice) {
      return [
        { label: '选项一', value: 'Apple' },
        { label: '选项二', value: 'Pear' },
        { label: '选项三', value: 'Orange' },
      ]
    }
    return fields[field_index].multiple_choice.map((x) => {
      return {
        label: x,
        value: x,
      }
    })
  }

  return fields.length > 0 ? (
    <Row gutter={8} justify="center">
      <Col
        span={8}
        style={{
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '100%' }}>
          {fields.map((field, field_index) => {
            const fieldType = field.field_type
            return (
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  flexDirection: 'column',
                  justifyContent: 'center',

                  width: '100%',
                  border: editingField === field_index ? '1px dashed #1890ff' : '1px dashed #ffffff',
                  minHeight: 100,
                  marginBottom: 10,
                  padding: 20,
                  backgroundColor: editingField === field_index ? '#FCFCFC' : '#ffffff',
                }}
                key={field_index}
                onClick={() => setEditingField(field_index)}
              >
                <h4>{field.label}</h4>
                <p style={{ color: '#9D9D9D', fontSize: 13 }}>{field.asset_comps.toString().replace(/,/g, '-')}</p>
                {fieldType === 'single_line_text' && (
                  <Input disabled style={{ width: '100%' }} placeholder="单行文本" />
                )}
                {fieldType === 'paragraph_text' && (
                  <Input.TextArea disabled style={{ width: '100%' }} placeholder="多行文本" rows={3} />
                )}

                {fieldType === 'phone' && (
                  <Input disabled style={{ width: '100%' }} placeholder="电话" prefix={<PhoneOutlined />} />
                )}
                {fieldType === 'email' && (
                  <Input disabled style={{ width: '100%' }} placeholder="email" prefix={<MailOutlined />} />
                )}
                {fieldType === 'number' && <InputNumber disabled style={{ width: '100%' }} placeholder="数字" />}
                {fieldType === 'date' && <DatePicker disabled style={{ width: '100%' }} />}
                {fieldType === 'drop_down' && <Select disabled style={{ width: '100%' }} placeholder="下拉框" />}
                {fieldType === 'multiple_choice' && (
                  <Checkbox.Group options={getCheckBoxOptions(field_index)} disabled />
                )}
                {fieldType === 'single_choice' && <Radio.Group options={getCheckBoxOptions(field_index)} disabled />}
              </div>
            )
          })}
        </div>
        <div style={{}}>
          <Button onClick={() => setCurrent(2)} type="primary" style={{ marginRight: 20 }}>
            上一步
          </Button>
          <Button
            onClick={() => {
              setCurrent(4)
              docreateFormTemplate()
            }}
            type="primary"
          >
            下一步
          </Button>
        </div>
      </Col>
      <Col
        span={16}
        style={{
          padding: 20,
          borderLeft: '2px solid #1890ff',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%' }}>
            {fields[editingField].v_asset_comps.map((comp, comp_index) => {
              return (
                <div key={comp + comp_index}>
                  <Divider orientation="left">{comp}</Divider>
                  <Form>
                    <Form.Item label="变化规则">
                      <Radio.Group
                        options={vcompsRule}
                        value={fields[editingField].v_asset_comps_regulation[comp_index].mode}
                        onChange={(e) => {
                          let newArr = fields.map((x) => x)
                          newArr[editingField].v_asset_comps_regulation[comp_index].mode = e.target.value
                            ? e.target.value
                            : ''
                          setFields(newArr)
                        }}
                      ></Radio.Group>
                    </Form.Item>
                    {fields[editingField].v_asset_comps_regulation[comp_index].mode === 'arithmetic' && (
                      <Form layout="inline">
                        <Form.Item label="公差">
                          <InputNumber
                            // defaultValue={0}
                            value={Number(fields[editingField].v_asset_comps_regulation[comp_index].value)}
                            onChange={(value) => {
                              let newArr = fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].value = value
                                ? value.toString()
                                : ''
                              setFields(newArr)
                            }}
                          />
                        </Form.Item>
                        <Form.Item label="最小值">
                          <InputNumber
                            // defaultValue={0}
                            value={Number(fields[editingField].v_asset_comps_regulation[comp_index].option[0])}
                            onChange={(value) => {
                              let newArr = fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].option[0] = value
                                ? value.toString()
                                : ''
                              setFields(newArr)
                            }}
                          />
                        </Form.Item>
                        <Form.Item label="最大值">
                          <InputNumber
                            // defaultValue={0}
                            value={Number(fields[editingField].v_asset_comps_regulation[comp_index].option[1])}
                            onChange={(value) => {
                              let newArr = fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].option[1] = value
                                ? value.toString()
                                : ''
                              setFields(newArr)
                            }}
                          />
                        </Form.Item>
                      </Form>
                    )}
                    {fields[editingField].v_asset_comps_regulation[comp_index].mode === 'recircle' && (
                      <Form.Item label="选项">
                        <Select
                          mode="tags"
                          value={fields[editingField].v_asset_comps_regulation[comp_index].option}
                          onChange={(value: string[]) => {
                            let newArr = fields.map((x) => x)
                            newArr[editingField].v_asset_comps_regulation[comp_index].option = value
                            setFields(newArr)
                          }}
                          placeholder="输入循环选项"
                        ></Select>
                      </Form.Item>
                    )}
                    {fields[editingField].v_asset_comps_regulation[comp_index].mode === 'equal' && (
                      <Form layout="inline">
                        <Form.Item label="初始值">
                          <Input
                            placeholder="请输入初始值"
                            value={fields[editingField].v_asset_comps_regulation[comp_index].value}
                            onChange={(e) => {
                              let newArr = fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].value = e.target.value
                              setFields(newArr)
                            }}
                          />
                        </Form.Item>
                      </Form>
                    )}
                  </Form>
                </div>
              )
            })}
          </div>
        </div>
      </Col>
    </Row>
  ) : null
}

export default CreateTemplateStepFour
