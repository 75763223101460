/// <reference path="type.d.ts" />
/// <reference path="tag.d.ts" />

import request from './_base'

/**
 * 创建tag
 * @param  {CreateTagReq} input
 */
export function createTag(input: CreateTagReq): Promise<AssetsResourceProto<{ data: TagItem }>> {
  return request({
    method: 'POST',
    url: `/asset/tag`,
    data: input,
  }) as any
}

/**
 * 更新tag(push新值)
 * @param  {UpdateTagReq} input
 */
export function updateTag(input: UpdateTagReq): Promise<AssetsResourceProto<{ data: TagItem }>> {
  return request({
    method: 'PUT',
    url: `/asset/tag`,
    data: input,
  }) as any
}

/**
 * 删除tag(包括其下所有历史，以及历史的历史)
 * @param  {DeleteTagReq} input
 */
export function deleteTag(input: DeleteTagReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/asset/tag/del`,
    data: input,
  }) as any
}

/**
 * 删除tag(hitory)
 * @param  {DeleteTagHistoryItemReq} input
 */
export function deleteTagHistoryItem(input: DeleteTagHistoryItemReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/asset/tag`,
    data: input,
  }) as any
}

/**
 * 根据assetId获取标签
 * @param  {string} asset_id
 */
export function getTagsByAssetId(asset_id: string): Promise<AssetsResourceProto<{ data: TagItem[] }>> {
  return request({
    method: 'GET',
    url: `/asset/tags/${asset_id}`,
  }) as any
}

/**
 * 更新历史值或最新值
 * @param  {UpdateTagHistoryItemReq} input
 */
export function updateTagHistoryItem(input: UpdateTagHistoryItemReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/asset/tag/history`,
    data: input,
  }) as any
}

/**
 * 根据tagId获取history
 * @param  {TagItem['asset_tag_id']} asset_tag_id
 */
export function getHistory(asset_tag_id: TagItem['asset_tag_id']): Promise<AssetsResourceProto<{ data: TagItem[] }>> {
  return request({
    method: 'GET',
    url: `/asset/tag/history/${asset_tag_id}`,
  }) as any
}
