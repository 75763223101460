/// <reference path="router.d.ts" />
import { withRouter } from 'react-router-dom';
import NoMatched from '@/ui-routes/404';
import AssetList from '@/ui-routes/AssetManagement/AssetList';
import StatusList from '@/ui-routes/AssetManagement/StatusList';
import AssetTemplateList from '@/ui-routes/AssetManagement/AssetTemplate';
import AssetStatusStandard from '@/ui-routes/AssetManagement/AssetStatusStandard';
import DfectList from '@/ui-routes/DefectManagement/DefectList';
import AddrList from '@/ui-routes/AddrManagement/pages/AddrList';
import CoordinateList from '@/ui-routes/AddrManagement/pages/CoordinateList';
import UserList from '@/ui-routes/authManagement/pages/UserManage';
import RoleManage from '@/ui-routes/authManagement/pages/RoleManagement';
import AuthManage from '@/ui-routes/authManagement/pages/AuthorityManage';
import OrgManage from '@/ui-routes/authManagement/pages/OrganizationManage';
import DataAuthManage from '@/ui-routes/authManagement/pages/DataAuth';
import MyFormTemplateList from '@/ui-routes/FormManagement/pages/MyFormTemplateList';
import CreateFormTemplate from '@/ui-routes/FormManagement/pages/CreateFormTemplate';
import FormTemplateDetail from '@/ui-routes/FormManagement/pages/FormTempalteDetail';
import CreateFreeFormTemplate from '@/ui-routes/FormManagement/pages/CreateFreeFormTemplate';
import ShareTemplateList from '@/ui-routes/FormManagement/pages/ShareTemplateList';
import ShareFormTemplateDetail from '@/ui-routes/FormManagement/pages/ShareFormTemplateDetail';
import CreateFormHead from '@/ui-routes/FormManagement/pages/CreateFormHead';
import CreateFreeFormHead from '@/ui-routes/FormManagement/pages/CreateFreeFormHead';
import FormList from '@/ui-routes/FormManagement/pages/FormList';
import FormDataDetail from '@/ui-routes/FormManagement/pages/FormDataDetail';
import FormDataWritePage from '@/ui-routes/FormManagement/pages/FormDataWritePage';
import FreeFormDataWritePage from '@/ui-routes/FormManagement/pages/FreeFormDataWritePage';
import BiHome from '@/ui-routes/ChartManagement/index';
import PreviewConfig from '@/ui-routes/ChartManagement/components/PreviewConfig';
import ImportConfigList from '@/ui-routes/ChartManagement/components/ImportConfigList';
import MyNormalTemplates from './NormalFormManagement/pages/MyNormalTemplates';
import ShareNormalTemplates from './NormalFormManagement/pages/ShareNormalTemplates';
import CreateNormalTemplate from './NormalFormManagement/pages/CreateNormalTemplate';
import NormalTemplateDetail from './NormalFormManagement/pages/NormalTemplateDetail';
import NormalFormWritePage from './NormalFormManagement/pages/NormalFormWritePage';
import FormStatistics from './FormManagement/pages/FormStatistics';
import NormalFormStatisticsData from './NormalFormManagement/pages/StatisticsData';
import ProjectList from './ProjectMannagement/pages/ProjectList';
import ProjectDetail from './ProjectMannagement/pages/ProjectDetail';
import LabelPage from './LabelManagement/pages/LabelPage';
import LabelProjectList from './LabelManagement/pages/LabelProjectList';
import LabelTaskList from './LabelManagement/pages/LabelTaskList';
import LabelTaskPage from './LabelManagement/pages/LabelTask';
import AIModelList from './AI/pages/AIModelList';
import ModelDetection from './AI/pages/AIModelUse';
import MyDetectRecords from './AI/pages/MyDetectRecords';
import DetectRecords from './AI/pages/DetectRecords';
import ModelTraining from './AI/pages/ModelTraining';
import InaccurateManagement from './AI/pages/InaccurateManagement';

export const ROUTE_CONFIG: BaseRouteConfigItem[] = [
  { exact: true, path: '/workbench/assets-management/assets-list', component: withRouter(AssetList) },
  { exact: true, path: '/workbench/assets-management/status-list', component: withRouter(StatusList) },
  {
    exact: true,
    path: '/workbench/assets-management/assets-status-standard',
    component: withRouter(AssetStatusStandard),
  },
  { exact: true, path: '/workbench/assets-management/assets-template-list', component: withRouter(AssetTemplateList) },
  { exact: true, path: '/workbench/forms-management/my-template', component: withRouter(MyFormTemplateList) },
  { exact: true, path: '/workbench/forms-management/share-template', component: withRouter(ShareTemplateList) },
  {
    exact: true,
    path: '/workbench/forms-management/my-template/create-form-template',
    component: withRouter(CreateFormTemplate),
  },
  {
    exact: true,
    path: '/workbench/forms-management/my-template/create-free-form-template',
    component: withRouter(CreateFreeFormTemplate),
  },
  {
    exact: true,
    path: '/workbench/forms-management/my-template/form-template-detail',
    component: withRouter(FormTemplateDetail),
  },
  {
    exact: true,
    path: '/workbench/forms-management/share-template/share-form-template-detail',
    component: withRouter(ShareFormTemplateDetail),
  },
  {
    exact: true,
    path: '/workbench/forms-management/share-template/create-form-head',
    component: withRouter(CreateFormHead),
  },
  {
    exact: true,
    path: '/workbench/forms-management/share-template/create-free-form-head',
    component: withRouter(CreateFreeFormHead),
  },
  { exact: true, path: '/workbench/forms-management/form-data-list', component: withRouter(FormList) },
  {
    exact: true,
    path: '/workbench/forms-management/form-data-list/submit-formdata',
    component: withRouter(FormDataWritePage),
  },
  {
    exact: true,
    path: '/workbench/forms-management/form-data-list/submit-free-formdata',
    component: withRouter(FreeFormDataWritePage),
  },
  {
    exact: true,
    path: '/workbench/forms-management/form-data-list/formdata-detail',
    component: withRouter(FormDataDetail),
  },
  {
    exact: true,
    path: '/workbench/forms-management/share-template/statistics',
    component: withRouter(FormStatistics),
  },
  { exact: true, path: '/workbench/chart-management/hi', component: withRouter(BiHome) },
  { exact: true, path: '/workbench/chart-management/hi/import-config-list', component: withRouter(ImportConfigList) },
  { exact: true, path: '/workbench/chart-management/hi/preview', component: withRouter(PreviewConfig) },
  { exact: true, path: '/workbench/defect-management/defect-list', component: withRouter(DfectList) },
  { exact: true, path: '/workbench/location/addr-list', component: withRouter(AddrList) },
  { exact: true, path: '/workbench/location/coordinate-list', component: withRouter(CoordinateList) },
  { exact: true, path: '/workbench/auth-management/user', component: withRouter(UserList) },
  { exact: true, path: '/workbench/auth-management/role', component: withRouter(RoleManage) },
  { exact: true, path: '/workbench/auth-management/auth', component: withRouter(AuthManage) },
  { exact: true, path: '/workbench/auth-management/organization', component: withRouter(OrgManage) },
  { exact: true, path: '/workbench/auth-management/data-auth', component: withRouter(DataAuthManage) },
  {
    exact: true,
    path: '/workbench/normal-form/my-normal-template',
    component: withRouter(MyNormalTemplates),
  },
  {
    exact: true,
    path: '/workbench/normal-form/share-normal-template',
    component: withRouter(ShareNormalTemplates),
  },
  {
    exact: true,
    path: '/workbench/normal-form/create-normal-template',
    component: withRouter(CreateNormalTemplate),
  },
  {
    exact: true,
    path: '/workbench/normal-form/my-normal-template/detail',
    component: withRouter(NormalTemplateDetail),
  },
  {
    exact: true,
    path: '/workbench/normal-form/write-page',
    component: withRouter(NormalFormWritePage),
  },
  {
    exact: true,
    path: '/workbench/normal-form/statistics',
    component: withRouter(NormalFormStatisticsData),
  },
  {
    exact: true,
    path: '/workbench/project/list',
    component: withRouter(ProjectList),
  },
  {
    exact: true,
    path: '/workbench/project/Detail',
    component: withRouter(ProjectDetail),
  },
  {
    exact: true,
    path: '/workbench/label/label-task/label-page',
    component: withRouter(LabelPage),
  },
  {
    exact: true,
    path: '/workbench/label/project-list',
    component: withRouter(LabelProjectList),
  },
  {
    exact: true,
    path: '/workbench/label/project-list/task-list',
    component: withRouter(LabelTaskList),
  },
  {
    exact: true,
    path: '/workbench/label/tasks',
    component: withRouter(LabelTaskPage),
  },
  {
    exact: true,
    path: '/workbench/ai/model-list',
    component: withRouter(AIModelList),
  },
  {
    exact: true,
    path: '/workbench/ai/model-list/detection',
    component: withRouter(ModelDetection),
  },
  {
    exact: true,
    path: '/workbench/ai/model-list/detect-records/my',
    component: withRouter(MyDetectRecords),
  },
  {
    exact: true,
    path: '/workbench/ai/model-list/detect-records',
    component: withRouter(DetectRecords),
  },
  {
    exact: true,
    path: '/workbench/ai/model-training',
    component: withRouter(ModelTraining),
  },
  {
    exact: true,
    path: '/workbench/ai/inaccurate-data',
    component: withRouter(InaccurateManagement),
  },
  { exact: true, path: '*', component: NoMatched },
];
