import * as React from 'react'
import * as DefectRequests from '@/requests/defect'
import {
  message,
  Table,
  Form,
  Select,
  Divider,
  Popconfirm,
  InputNumber,
  Button,
  Dropdown,
  Menu,
  Input,
  DatePicker,
  Modal,
} from 'antd'
import { DownloadOutlined, DownOutlined, RedoOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons'
import DrawerAssetEditor from '../components/DefectDetailDrawer'
import { connect } from '@/App.store'
import * as strUtils from '@/utils/string'
import * as SuggestionRequests from '@/requests/suggestion'
import { download } from '@/utils/string'
import './index.less'

function getColumnCfg<Row extends DefectItem>({
  maxv,
  maxg,
  setEditingDefectUid,
  doFetch,
}: {
  maxv: number
  maxg: number
  setEditingDefectUid: Function
  doFetch: Function
}): any[] {
  return [
    {
      title: '序号',
      align: 'center' as 'center',
      key: 'no',
      render(_: any, record: Row, idx: number) {
        return idx + 1
      },
    },
    {
      title: '缺陷名',
      align: 'center' as 'center',
      key: 'status_name',
      render(_: any, record: Row, idx: number) {
        return record.status_name
      },
    },
    {
      title: '缺陷值',
      align: 'center' as 'center',
      key: 'status_value',
      render(_: any, record: Row, idx: number) {
        return record.status_value
      },
    },
    ...Array(maxg)
      .fill(undefined)
      .map((g, g_index) => {
        return {
          title: `等级${g_index + 1}`,
          align: 'center' as 'center',
          key: `defect_grade_${g_index}`,
          render: (_: any, record: Row, idx: number) => {
            if (!record.defect_grade_list[g_index]) {
              return '-'
            }
            return record.defect_grade_list[g_index].defect_name
          },
        }
      }),
    {
      title: '资产名',
      align: 'center' as 'center',
      key: 'asset_name',
      render(_: any, record: Row, idx: number) {
        return record.asset_name
      },
    },
    ...Array(maxv)
      .fill(undefined)
      .map((v, v_index) => {
        return {
          title: `地理组件${v_index + 1}`,
          align: 'center' as 'center',
          key: `v_comps_${v_index}`,
          render: (_: any, record: Row, idx: number) => {
            if (!record.v_asset_status[v_index]) return '-'
            return `${record.v_asset_status[v_index].v_status_name}：${record.v_asset_status[v_index].v_status_value}`
          },
        }
      }),

    {
      title: '记录时间',
      align: 'center' as 'center',
      key: 'record_time',
      render(_: any, record: Row, idx: number) {
        return record.record_time
      },
    },
    {
      title: '操作',
      align: 'center' as 'center',
      key: 'action',
      render(_: any, record: Row, idx: number) {
        return (
          <span>
            <span
              className="span-btn ant-btn-link"
              onClick={() => {
                setEditingDefectUid(record.uid)
              }}
              key="edit"
            >
              编辑
            </span>
            {record.processing_status === 1 && <Divider type="vertical" />}
            {record.processing_status === 1 && (
              <Popconfirm
                title="确定关闭这条缺陷吗?"
                onConfirm={async () => {
                  const res = await DefectRequests.updateDefect({
                    status_defect_uid: record.uid,
                    close: true,
                  })
                  if (res.code === 0) {
                    message.success('关闭成功')
                    doFetch()
                  } else {
                    message.error(res.message)
                  }
                }}
                okType="danger"
                okText="确定"
                cancelText="不了"
              >
                <span className="span-btn ant-btn-link ant-btn-link__danger" key="delete">
                  关闭
                </span>
              </Popconfirm>
            )}
          </span>
        )
      },
    },
  ]
}

const selectAfter = (
  <Select defaultValue=".csv" style={{ width: 80 }}>
    <Select.Option value=".csv">.csv</Select.Option>
  </Select>
)

const DefectList = () => {
  const [fetching, setFetching] = React.useState(false)
  const [data, setData] = React.useState<DefectItem[]>([])
  const [isClose, setIsClose] = React.useState(2)
  const [isExitedAsset, setIsExitedAsset] = React.useState(2)
  const [maxVCompsCount, setMaxVCompsCount] = React.useState(0)
  const [maxGradeCount, setMaxGradeCount] = React.useState(0)
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })
  const [editingDefectUid, setEditingDefectUid] = React.useState('')
  const [showFilterArea, setShowFilterArea] = React.useState(false)

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])
  const [filter_assetName, set_filter_assetName] = React.useState<string | undefined>(undefined)
  const [filter_assetComps, set_filter_assetComps] = React.useState<string[] | undefined>(undefined)
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
  const [filter_vAssetComps, set_filter_vAssetComps] = React.useState<
    { v_status_name: string; v_status_value: string }[]
  >([])

  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([])
  const [filter_statusName, set_filter_statusName] = React.useState<{
    status_name: string
    status_value: string
  } | null>(null)
  const [statusNameOptions, setStatusNameOptions] = React.useState<string[]>([])
  const [filter_create_time, set_filter_create_time] = React.useState<{
    start: string
    end: string
    dates: any
  } | null>(null)
  const [recorder_user_id, set_recorder_user_id] = React.useState('')
  const [loadingExcel, setLoadingExcel] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [excelFileName, setExcelFileName] = React.useState('缺陷信息')

  const doFetch = async (page?: number, pageSize?: number) => {
    if (!page) page = 1
    if (!pageSize) pageSize = 10

    const res = await DefectRequests.getDefectList({
      limit: pageSize,
      skip: pageSize * (page - 1),
      ...(isClose !== 2 && { close: isClose === 1 ? true : false }),
      ...(isExitedAsset !== 2 && {
        asset_existed: isExitedAsset === 1 ? true : false,
      }),
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_statusName && {
        status_name: filter_statusName.status_name,
      }),

      ...(filter_statusName && {
        status_value: filter_statusName.status_value,
      }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps &&
        filter_vAssetComps.length > 0 && {
          v_asset_status: filter_vAssetComps.map((x) => x),
        }),

      ...(filter_create_time && {
        record_time_begin: filter_create_time.start,
        record_time_end: filter_create_time.end,
      }),
    })
    if (res.code === 0) {
      const vCompsList = res.data.map((x) => {
        return x.v_asset_status.length
      })
      vCompsList.sort((a, b) => b - a)
      const gradeList = res.data.map((x) => {
        return x.defect_grade_list.length
      })
      gradeList.sort((a, b) => b - a)

      setMaxVCompsCount(vCompsList[0])
      setMaxGradeCount(gradeList[0])
      setData(res.data)
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      })
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }
  const downloadExcel = async ({ total = pagination.total } = {}) => {
    setLoadingExcel(true)
    const res = await DefectRequests.CreateDefectListCsv({
      ...(isClose !== 2 && { close: isClose === 1 ? true : false }),
      ...(isExitedAsset !== 2 && {
        asset_existed: isExitedAsset === 1 ? true : false,
      }),
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_statusName && {
        status_name: filter_statusName.status_name,
      }),

      ...(filter_statusName && {
        status_value: filter_statusName.status_value,
      }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps &&
        filter_vAssetComps.length > 0 && {
          v_asset_status: filter_vAssetComps.map((x) => x),
        }),

      ...(filter_create_time && {
        record_time_begin: filter_create_time.start,
        record_time_end: filter_create_time.end,
      }),
      skip: 0,
      limit: total,
    })
    if (res.code === 0) {
      download(`v2/api/file/status/defect/csv/download/${res.data}`, excelFileName)
    } else {
      message.error(res.message)
    }
    setLoadingExcel(false)
    setVisible(false)
  }

  React.useEffect(() => {
    setFetching(true)
    doFetch()
  }, [isClose, isExitedAsset])

  const downloadMenu = (
    <Menu>
      <Menu.Item
        key="csv"
        onClick={(e) => {
          e.domEvent.preventDefault()
          setVisible(true)
        }}
      >
        导出为csv
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="defect-list-page">
      <div className="tools-wrapper">
        <div className="defect-list-filter-input-container">
          <Form layout="inline" colon={false}>
            <Form.Item label="是否关闭" className="filter-item">
              <Select
                style={{ minWidth: 168 }}
                value={isClose}
                placeholder="全部"
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                  }
                }}
                onSearch={async (value) => {
                  if (value) {
                  } else {
                    return
                  }
                }}
                onChange={(value: any) => {
                  setIsClose(value)
                }}
              >
                <Select.Option value={2}>全部</Select.Option>
                <Select.Option value={1}>已关闭</Select.Option>
                <Select.Option value={0}>未关闭</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="是否存在资产" className="filter-item">
              <Select
                style={{ minWidth: 168 }}
                value={isExitedAsset}
                placeholder="全部"
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                  }
                }}
                onSearch={async (value) => {
                  if (value) {
                  } else {
                    return
                  }
                }}
                onChange={(value: any) => {
                  setIsExitedAsset(value)
                }}
              >
                <Select.Option value={2}>全部</Select.Option>
                <Select.Option value={1}>存在资产</Select.Option>
                <Select.Option value={0}>不存在资产</Select.Option>
              </Select>
            </Form.Item>
            <Button type="link" onClick={() => setShowFilterArea(!showFilterArea)}>
              {showFilterArea ? '点击收起' : '点击展开'}
              {showFilterArea ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </Form>
          <div style={{ display: showFilterArea ? 'block' : 'none' }}>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产名称" className="filter-item">
                <Select
                  style={{ minWidth: 168 }}
                  showSearch
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (filter_assetName) return
                      const res = await SuggestionRequests.getAssetNameSuggestion()
                      if (res.code === 0) {
                        setAssetNameOptions(res.data)
                      } else {
                      }
                    }
                  }}
                  onSearch={async (value) => {
                    if (strUtils.isNull(value)) {
                      return
                    }
                    if (value) {
                      const res = await SuggestionRequests.getAssetNameSuggestion(value)
                      if (res.code === 0) {
                        setAssetNameOptions(res.data)
                      } else {
                      }
                    } else {
                      return
                    }
                  }}
                  onChange={(value: string) => set_filter_assetName(value)}
                  value={filter_assetName}
                >
                  {assetNameOptions.map((x: string) => {
                    return (
                      <Select.Option value={x} key={`asset_name_option:${x}`}>
                        {x}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="资产组件" className="filter-item">
                <Select
                  onChange={(values: string) => {
                    set_filter_assetComps(values.split(','))
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_assetComps ? filter_assetComps.toString() : undefined}
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (!filter_assetName) return
                      const res = await SuggestionRequests.getAssetCompsSuggestion(filter_assetName)
                      if (res.code === 0) {
                        setAssetCompsOptions(
                          res.data.map((comps) => {
                            return comps.toString()
                          })
                        )
                      } else {
                      }
                    }
                  }}
                >
                  {assetCompsOptions.map((x: string) => {
                    return (
                      <Select.Option value={x} key={`asset_comps_option:${x}`}>
                        {x}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>

            <Form layout="inline" colon={false}>
              <Form.Item label="地理信息" className="filter-item">
                <Select
                  onChange={(values: string) => {
                    set_filter_vAssetComps(
                      values.split(',').map((x) => {
                        let orgValue = filter_vAssetComps.find((item) => item.v_status_name === x)
                        return {
                          v_status_name: x,
                          v_status_value: orgValue ? orgValue.v_status_value : '',
                        }
                      })
                    )
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_vAssetComps ? filter_vAssetComps.map((x) => x.v_status_name).toString() : undefined}
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (!filter_assetName || !filter_assetComps) return
                      const res = await SuggestionRequests.getAssetVCompsSuggestion({
                        asset_name: filter_assetName,
                        asset_comps: filter_assetComps,
                      })
                      if (res.code === 0) {
                        setVAssetQueryOptions(
                          res.data.map((x) => {
                            return x.toString()
                          })
                        )
                      } else {
                      }
                    }
                  }}
                >
                  {vAssetQueryOptions.map((item, index) => {
                    return (
                      <Select.Option key={item + index} value={item}>
                        {item}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              {filter_vAssetComps &&
                filter_vAssetComps.length > 0 &&
                filter_vAssetComps.map((item) => {
                  const a = item.v_status_name.search('里程')
                  const b = item.v_status_name.search('轨枕号')
                  if (a === -1 && b === -1) {
                    return (
                      <Form.Item
                        label={item.v_status_name}
                        key={'status_value input' + item.v_status_name}
                        className="filter-item"
                      >
                        <Select
                          style={{ minWidth: 168 }}
                          mode="tags"
                          placeholder={`全部`}
                          value={item.v_status_value === '' ? undefined : item.v_status_value.split(',')}
                          onChange={(values: string[]) => {
                            let ary = filter_vAssetComps.map((x) => x)
                            const newAry = ary.map((x) => {
                              if (x.v_status_name === item.v_status_name) {
                                x.v_status_value = values.toString()
                              }
                              return x
                            })
                            set_filter_vAssetComps(newAry)
                          }}
                        ></Select>
                      </Form.Item>
                    )
                  } else {
                    return (
                      <Form.Item
                        label={item.v_status_name}
                        key={'status_value input' + item.v_status_name}
                        className="filter-item"
                      >
                        <InputNumber
                          onChange={(value) => {
                            if (!value) {
                              value = ''
                            }
                            let ary = filter_vAssetComps.map((x) => x)
                            const newAry = ary.map((x) => {
                              if (x.v_status_name === item.v_status_name) {
                                const range = x.v_status_value.split(',')

                                range[0] = value.toString()
                                x.v_status_value = range.toString()
                              }
                              return x
                            })
                            set_filter_vAssetComps(newAry)
                          }}
                          value={item.v_status_value === '' ? undefined : item.v_status_value.split(',')[0]}
                        />
                        ~
                        <InputNumber
                          onChange={(value) => {
                            if (!value) {
                              value = ''
                            }
                            let ary = filter_vAssetComps.map((x) => x)
                            const newAry = ary.map((x) => {
                              if (x.v_status_name === item.v_status_name) {
                                const range = x.v_status_value.split(',')
                                if (range.length === 2) {
                                  range[1] = value.toString()
                                } else {
                                  range.push(value.toString())
                                }

                                x.v_status_value = range.toString()
                              }
                              return x
                            })
                            set_filter_vAssetComps(newAry)
                          }}
                          value={item.v_status_value === '' ? undefined : item.v_status_value.split(',')[1]}
                        />
                      </Form.Item>
                    )
                  }
                })}
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产状态名" className="filter-item">
                <Select
                  allowClear
                  onChange={(value: string) => {
                    set_filter_statusName({
                      status_name: value,
                      status_value: '',
                    })
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_statusName ? filter_statusName.status_name : undefined}
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (!filter_assetName || !filter_assetComps) return
                      const res = await SuggestionRequests.getAssetStatusSuggestion({
                        asset_name: filter_assetName,
                        asset_comps: filter_assetComps,
                      })
                      if (res.code === 0) {
                        setStatusNameOptions(
                          res.data.map((x: StatusSuggestionItem) => {
                            return `${x.asset_status}`
                          })
                        )
                      } else {
                      }
                    }
                  }}
                >
                  {statusNameOptions.map((item, index) => {
                    return (
                      <Select.Option key={item + index} value={item}>
                        {item}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              {filter_statusName && filter_statusName.status_name && (
                <Form.Item label={filter_statusName.status_name} className="filter-item">
                  <Input
                    placeholder={`全部`}
                    value={filter_statusName.status_value}
                    onChange={(e) => {
                      set_filter_statusName({
                        ...filter_statusName,
                        status_value: e.target.value,
                      })
                    }}
                  />
                </Form.Item>
              )}
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="记录时间" className="filter-item">
                <DatePicker.RangePicker
                  value={filter_create_time ? filter_create_time.dates : undefined}
                  onChange={(dates, dateStrings) => {
                    if (!dates) return
                    set_filter_create_time(
                      dates.length > 0
                        ? {
                            ...filter_create_time,
                            start: dateStrings[0] + ' 00:00:00',
                            end: dateStrings[1] + ' 00:00:00',
                            dates: dates,
                          }
                        : null
                    )
                  }}
                />
              </Form.Item>
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="记录人ID" className="filter-item">
                <Input
                  placeholder="请输入记录人ID"
                  value={recorder_user_id}
                  onChange={(e) => {
                    set_recorder_user_id(e.target.value)
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
          }}
        >
          <Button type="primary" style={{ marginRight: 10 }} onClick={() => doFetch()} icon={<SearchOutlined />}>
            查询
          </Button>

          <Dropdown overlay={downloadMenu} key="download-menus">
            <Button style={{ marginRight: 10 }} icon={<DownloadOutlined />}>
              下载
            </Button>
          </Dropdown>
          <Button
            onClick={() => {
              set_filter_assetComps(undefined)
              set_filter_vAssetComps([])
              set_filter_assetName(undefined)
              set_recorder_user_id('')
            }}
            icon={<RedoOutlined />}
          >
            重置
          </Button>
        </div>
      </div>

      <Table
        dataSource={data}
        columns={getColumnCfg({
          setEditingDefectUid: setEditingDefectUid,
          maxv: maxVCompsCount,
          maxg: maxGradeCount,
          doFetch: doFetch,
        })}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => {
              setEditingDefectUid(record.uid)
            },
          }
        }}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        loading={fetching}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => doFetch(page, pageSize),
          showTotal: (total: number) => {
            return <span>{`共 ${total} 条数据`}</span>
          },
          showQuickJumper: true,
          style: { marginRight: 16 },
        }}
      />
      <DrawerAssetEditor
        status_defect_uid={editingDefectUid}
        onClose={() => {
          setEditingDefectUid('')
          doFetch()
        }}
      />
      <Modal
        title="导出csv"
        cancelText="取消"
        okText={loadingExcel ? '数据生成中' : '确定'}
        okButtonProps={{ loading: loadingExcel }}
        visible={visible}
        onOk={() => downloadExcel()}
        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setVisible(false)}
      >
        <div style={{ marginBottom: 16 }}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="文件名称">
              <Input
                addonAfter={selectAfter}
                defaultValue="缺陷信息"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setExcelFileName(e.target.value)
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  )
}

export default connect(DefectList)
