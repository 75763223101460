import * as React from 'react';
import { PageHeader, Button, Input, message, Spin, Empty, List, Card, Tooltip, Modal, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import * as FormRequests from '@/requests/normalForm';
import { DeleteOutlined, EyeOutlined, PlusOutlined, RedoOutlined, ShareAltOutlined } from '@ant-design/icons';
import '../styles/index.less';

const MyNormalTemplates = () => {
  const [data, setData] = React.useState<PersonalTemplateItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const history = useHistory();
  const location = useLocation();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await FormRequests.getPersonalTemplateList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      form_template_name: filterName,
    });

    if (res.code === 0) {
      setData(res.data);
      setPagination({
        ...pagination,
        current: page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const deleteForm = async (uid: number) => {
    const res = await FormRequests.deletePersonalTemplate({
      id: uid,
    });
    if (res.code === 0) {
      message.success('删除成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  const shareForm = async (template: PersonalTemplateItem) => {
    Modal.confirm({
      okText: '确认',
      cancelText: '取消',
      title: '提示',
      content: '确认将此模板分享至公共模板？',
      onOk: async () => {
        if (!template) return;
        const res = await FormRequests.sharePersonalTemplate({
          id: template.id,
        });
        if (res.code === 0) {
          message.success('分享成功，可在分享模板中查看！');
        } else {
          message.error(res.message);
        }
      },
    });
  };

  React.useEffect(() => {
    if (location.pathname === '/workbench/forms-management/my-template') {
      setFetching(true);
      fetchData();
    }
  }, [location]);
  return (
    <div className="my-template-list-page">
      <div className="tools-wrapper">
        {data.length > 0 && (
          <PageHeader
            title={
              <div className="filter-item">
                <Input.Search
                  placeholder="搜索我的模板"
                  onChange={(e) => {
                    setFilterName(e.target.value);
                  }}
                  onSearch={() => fetchData()}
                />
              </div>
            }
            ghost={true}
            style={{ padding: '8px 0 16px 0', width: '100%' }}
            extra={[
              <Button icon={<RedoOutlined />} onClick={() => fetchData()}>
                刷新
              </Button>,
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => history.push('/workbench/normal-form/create-normal-template')}
              >
                创建模板
              </Button>,
            ]}
          ></PageHeader>
        )}
      </div>
      <div style={{ width: '100%' }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
          renderItem={(item) => (
            <List.Item key={item.uid}>
              <Card
                title={item.form_template_name}
                actions={[
                  <Tooltip title="查看详情">
                    <span
                      onClick={() => {
                        if (item.fields.length < 1) {
                          message.info('自由表单');
                          return;
                        }
                        history.push({
                          pathname: `/workbench/normal-form/my-normal-template/detail`,
                          search: `uid=${item.uid}`,
                          state: {
                            data: item,
                          },
                        });
                      }}
                    >
                      <EyeOutlined key="eye" style={{ marginRight: 5, color: '#f39800' }} />
                      查看
                    </span>
                  </Tooltip>,
                  <Tooltip title="删除">
                    <span
                      onClick={() => {
                        console.log('xxxxxxxxxx');
                        Modal.confirm({
                          okText: '确认',
                          cancelText: '取消',
                          title: '提示',
                          content: '确认删除该表单模板吗？',
                          onOk: () => deleteForm(item.id),
                        });
                      }}
                    >
                      <DeleteOutlined style={{ marginRight: 5 }} />
                      删除
                    </span>
                  </Tooltip>,
                ]}
                extra={[
                  <Tooltip title="分享">
                    <ShareAltOutlined key="share" onClick={() => shareForm(item)} />
                  </Tooltip>,
                ]}
              >
                <Form labelCol={{ md: 5, lg: 5 }} labelAlign={'left'}>
                  <Form.Item label="简介" style={{ marginBottom: 0 }}>
                    {item.description}
                  </Form.Item>
                  <Form.Item label="表单编号" style={{ marginBottom: 0 }}>
                    {item.form_template_number}
                  </Form.Item>
                  <Form.Item label="版本号" style={{ marginBottom: 0 }}>
                    {item.version_name}
                  </Form.Item>
                  <Form.Item label="创建时间" style={{ marginBottom: 0 }}>
                    {item.create_time}
                  </Form.Item>
                </Form>
              </Card>
            </List.Item>
          )}
          locale={{
            emptyText: (
              <div>
                <Empty description="您暂未创建普通表单模板">
                  <Button type="primary" onClick={() => history.push('/workbench/normal-form/create-normal-template')}>
                    创建模板
                  </Button>
                </Empty>
              </div>
            ),
          }}
        ></List>
      </div>
      <Spin spinning={fetching} style={{ position: 'absolute', right: '50%', top: '50%' }} />
    </div>
  );
};

export default MyNormalTemplates;
