import React from 'react'
import * as IMRequests from '@/requests/rongcloud'
import * as RongIMLib from '@rongcloud/imlib-v4'

const im = RongIMLib.init({ appkey: 'cpj2xarlcm7ln', logLevel: 0 })

export function useIM() {
  const [hasConnect, setHasConnect] = React.useState(false)
  const [totalUnread, setTotalUnread] = React.useState(0)
  const [existedConversationList, setExistedConversationList] = React.useState([])
  //获取token
  const getToken = async () => {
    const res = await IMRequests.getUserRongcloudToken()
    if(res.code !==0) {
      return ''
    }
    return res.data.im_token
  }
  //获取会话列表
  const getExistedConversationList = () => {
    im.Conversation.getList({ order: 0, count: 10000, startTime: 0 })
      .then((conversationList) => {
        setExistedConversationList(conversationList)
        console.log('获取会话列表成功', conversationList)
      })
      .catch((error) => {
        console.log('获取会话列表失败: ', error.code, error.msg)
      })
  }

  //获取未读消息数
  const getUnread = () => {
    im.Conversation.getTotalUnreadCount()
      .then((total) => {
        setTotalUnread(total)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    // 添加事件监听
    im.watch({
      // 监听会话列表变更事件

      conversation(event) {
        console.log('会话列表变更')
        getExistedConversationList()
        getUnread()
      },
      status(event) {
        console.log('connection status:', event.status)
      },
    })

    getToken().then((res) => {
      if(!res) return 
      im.connect({ token: res })
        .then((user) => {
          console.log('链接成功, 链接用户 id 为: ', user.id)
          getExistedConversationList()
          getUnread()
        })
        .then(() => {
          setHasConnect(true)
        })
        .catch((error) => {
          console.log('链接失败: ', error.code, error.msg)
        })
    })
  }, [])

  return {
    im: im,
    hasConnect: hasConnect,
    totalUnread: totalUnread,
    existedConversationList: existedConversationList,
    getExistedConversationList: getExistedConversationList,
  }
}
