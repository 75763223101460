/// <reference path="type.d.ts" />
/// <reference path="defect.d.ts" />

import request from './_base'

/**
 * 获取defect list
 * @param  {number} limit 每页数量
 * @param  {number} skip 偏移量
 * @param  {boolean} close 筛选条件：是否关闭
 * @param  {boolean} asset_existed 筛选条件：是否存在资产
 */
export async function getDefectList(
  input: GetDefectListReq
): Promise<AssetsResourceProto<{ data: DefectItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/status/defect/list/get`,
    data: input,
  }) as any
}

/**
 * update缺陷 此api实现两个功能,1.关闭  2.修改状态值和状态名
 * 基本参数：
 *  @param  {string} status_defect_uid 缺陷uid
 * 1.关闭:
 * @param  {boolean} close 关闭参数
 * 2.修改状态值和状态名:
 * @param  {string} status_name 状态名
 * @param  {stirng} status_value 状态值
 */
export function updateDefect(input: UpdateDefectReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/status/defect/update`,
    data: input,
  }) as any
}

/**
 * 通过uid查询defect
 * @param  {string} status_defect_uid defect uid
 */
export function getDefectById(status_defect_uid: string): Promise<AssetsResourceProto<{ data: DefectItem }>> {
  return request({
    method: 'GET',
    url: `/status/defect/get/${status_defect_uid}`,
  }) as any
}

/**
 * 将defect绑定到资产
 * @param  {string} status_defect_uid defect uid
 *  @param  {string} asset_id 资产ID
 */
export function bindDefect2Asset(input: BindDefect2AssetReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/status/defect/bind`,
    data: input,
  }) as any
}

/**
 * 根据筛选条件创建csv
 * @param  {GetDefectListReq} input
 */
export function CreateDefectListCsv(input: GetDefectListReq): Promise<AssetsResourceProto<{ data: string }>> {
  return request({
    method: 'POST',
    url: `/file/status/defect/csv/new`,
    data: input,
  }) as any
}
