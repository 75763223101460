import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, PageHeader, Input, message, Spin, List, Avatar, Empty } from 'antd'
import * as BIRequests from '@/requests/bi'

const ImportConfigList = () => {
  const [pagination, setPagination] = React.useState({} as { current: number; pageSize: number; total: number })
  const [loading, setLoaading] = React.useState(false)
  const [list, setList] = React.useState<GetImportableConfigListRes[]>([])
  const [filterName, setFilterName] = React.useState<string | undefined>(undefined)
  const history = useHistory()
  const doFetch = async (page?: number) => {
    setLoaading(true)
    const res = await BIRequests.getImportableConfigList({
      limit: 10,
      skip: page ? (page - 1) * 10 : 0,
      ...(filterName && {
        config_name: filterName,
      }),
    })
    if (res.code === 0) {
      setList(res.data)
      setPagination({ ...pagination, total: res.total })
    } else {
      message.error(res.message)
    }
    setLoaading(false)
  }

  React.useEffect(() => {
    doFetch()
  }, [])
  return (
    <div style={{ backgroundColor: '#ffffff', padding: 16 }}>
      <PageHeader
        title="图表配置导入"
        ghost={true}
        style={{ padding: '0 0 16px 0', borderBottom: '1px solid #DFE5EA' }}
        extra={[
          <Input.Search
            placeholder="搜索配置名称"
            onSearch={(value) => doFetch()}
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            enterButton
          />,
        ]}
        onBack={() => history.goBack()}
      ></PageHeader>
      {list.length < 1 ? (
        <Spin spinning={loading} style={{ position: 'absolute', top: '50%', right: '50%' }} />
      ) : (
        <div style={{ margin: 20 }}>
          <List
            dataSource={list}
            renderItem={(item, index) => {
              return (
                <List.Item key={item.bi_config_share_uid}>
                  <List.Item.Meta
                    avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                    title={<span>{item.config_name}</span>}
                    description={
                      <>
                        <span>
                          <strong>创建者</strong>：{item.share_user_name ? item.share_user_name : '未知'}{' '}
                        </span>
                        <span style={{ paddingLeft: 10 }}>
                          <strong>分享时间</strong>：{item.share_time}
                        </span>
                      </>
                    }
                  />
                  <div>
                    <Button
                      type="primary"
                      onClick={() =>
                        history.push({
                          pathname: '/workbench/chart-management/hi/preview',
                          state: {
                            config: item,
                          },
                        })
                      }
                    >
                      预览
                    </Button>
                  </div>
                </List.Item>
              )
            }}
            loading={loading}
            pagination={{
              total: pagination.total,
              onChange: (page, pageSize) => {
                doFetch(page)
              },
            }}
            locale={{
              emptyText: <Empty />,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default ImportConfigList
