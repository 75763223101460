import * as React from 'react';
import {
  PageHeader,
  Button,
  Input,
  message,
  Spin,
  Empty,
  List,
  Card,
  Tooltip,
  Modal,
  Dropdown,
  Menu,
  Form,
  Tabs,
  Pagination,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonGroup from 'antd/lib/button/button-group';
import * as FormRequests from '@/requests/form';
import { DeleteOutlined, EyeOutlined, PlusOutlined, RedoOutlined, ShareAltOutlined } from '@ant-design/icons';
import '../styles/index.less';
import MyNormalTemplates from '@/ui-routes/NormalFormManagement/pages/MyNormalTemplates';

const MyFormTemplateList = () => {
  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        renderTabBar={(props: any, defaultTabBar: any) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <ButtonGroup>
                {props.panes.map((x: any, _index: number) => {
                  return (
                    <Button
                      type={x.key === props.activeKey ? 'primary' : undefined}
                      key={_index}
                      onClick={() => {
                        props.onTabClick(x.key);
                      }}
                      style={{ padding: '0 24px' }}
                    >
                      {x.props.tab}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </div>
          );
        }}
      >
        <Tabs.TabPane tab="资产状态表单" key="1">
          <StatusFormTemplates />
        </Tabs.TabPane>
        <Tabs.TabPane tab="普通表单" key="2">
          <MyNormalTemplates />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const StatusFormTemplates = () => {
  const [data, setData] = React.useState<FormTemplateItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });
  const Menus = (
    <Menu>
      <Menu.Item
        key="normal"
        onClick={(e) => {
          e.domEvent.preventDefault();
          history.push('/workbench/forms-management/my-template/create-form-template');
        }}
      >
        创建普通表单模板
      </Menu.Item>
      <Menu.Item
        key="free"
        onClick={(e) => {
          e.domEvent.preventDefault();
          history.push('/workbench/forms-management/my-template/create-free-form-template');
        }}
      >
        创建自由表单模板
      </Menu.Item>
    </Menu>
  );
  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await FormRequests.getPersonalFormTempalteList({
      page,
      pageSize,
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        current: page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const deleteForm = async (uid: string) => {
    const res = await FormRequests.deletePersonalTemplate({
      uid,
    });
    if (res.code === 0) {
      message.success('删除成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  const shareForm = async (template: FormTemplateItem) => {
    Modal.confirm({
      okText: '确认',
      cancelText: '取消',
      title: '提示',
      content: '确认将此模板分享至公共模板？',
      onOk: async () => {
        if (!template) return;
        const res = await FormRequests.sharePersonalTemplate({
          uid: template.uid,
        });
        if (res.code === 0) {
          message.success('分享成功，可在分享模板中查看！');
        } else {
          message.error(res.message);
        }
      },
    });
  };
  React.useEffect(() => {
    if (location.pathname === '/workbench/forms-management/my-template') {
      setFetching(true);
      fetchData();
    }
  }, [location]);

  return (
    <div className="my-template-list-page">
      <div className="tools-wrapper">
        {data.length > 0 && (
          <PageHeader
            title={
              <div className="filter-item">
                <Input.Search placeholder="搜索我的模板" />
              </div>
            }
            ghost={true}
            style={{ padding: '8px 0 16px 0', width: '100%' }}
            extra={[
              <Button icon={<RedoOutlined />} onClick={() => fetchData()}>
                刷新
              </Button>,
              <Dropdown overlay={Menus} key="menus">
                <Button icon={<PlusOutlined />} type="primary">
                  创建模板
                </Button>
              </Dropdown>,
            ]}
          ></PageHeader>
        )}
      </div>
      <div style={{ width: '100%' }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.form_name}
                actions={[
                  <Tooltip title="查看详情">
                    <span
                      onClick={() => {
                        if (item.fields.length < 1) {
                          message.info('自由表单');
                          return;
                        }
                        history.push({
                          pathname: `/workbench/forms-management/my-template/form-template-detail`,
                          search: `uid=${item.uid}`,
                          state: {
                            uid: item.uid,
                          },
                        });
                      }}
                    >
                      <EyeOutlined key="eye" style={{ marginRight: 5, color: '#f39800' }} />
                      查看
                    </span>
                  </Tooltip>,
                  <Tooltip title="删除">
                    <span
                      onClick={() => {
                        Modal.confirm({
                          okText: '确认',
                          cancelText: '取消',
                          title: '提示',
                          content: '确认删除该表单模板吗？',
                          onOk: () => deleteForm(item.uid),
                        });
                      }}
                    ></span>
                    <DeleteOutlined style={{ marginRight: 5 }} />
                    删除
                  </Tooltip>,
                ]}
                extra={[
                  <Tooltip title="分享">
                    <ShareAltOutlined key="share" onClick={() => shareForm(item)} />
                  </Tooltip>,
                ]}
              >
                <Form labelCol={{ md: 5, lg: 5 }} labelAlign={'left'}>
                  <Form.Item label="简介" style={{ marginBottom: 0 }}>
                    {item.description}
                  </Form.Item>
                  <Form.Item label="表单编号" style={{ marginBottom: 0 }}>
                    {item.form_template_id}
                  </Form.Item>
                  <Form.Item label="版本号" style={{ marginBottom: 0 }}>
                    {item.version_name}
                  </Form.Item>
                  <Form.Item label="创建时间" style={{ marginBottom: 0 }}>
                    {item.create_time}
                  </Form.Item>
                </Form>
              </Card>
            </List.Item>
          )}
          pagination={{
            ...pagination,
            showTotal: (total) => <span>共{total}条数据</span>,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
          locale={{
            emptyText: (
              <div>
                <Empty description="您暂未创建表单模板">
                  <Dropdown overlay={Menus} key="menus">
                    <Button type="primary">创建模板</Button>
                  </Dropdown>
                </Empty>
              </div>
            ),
          }}
        ></List>
      </div>
      <Spin spinning={fetching} style={{ position: 'absolute', right: '50%', top: '50%' }} />
    </div>
  );
};

export default MyFormTemplateList;
