import * as React from 'react'
import { Button, Spin, Result } from 'antd'
import { useHistory } from 'react-router-dom'

interface propsType {
  fetching: boolean
  isError: boolean
  formUid: string
}

const CreateTemplateStepFive = (props: propsType) => {
  const history = useHistory()
  const { fetching, isError, formUid } = props
  return fetching ? (
    <Spin spinning={fetching} style={{ position: 'absolute', right: '50%', top: '50%' }} tip="正在生成表单模板..." />
  ) : (
    <Result
      status={isError ? 'error' : 'success'}
      title={isError ? '创建表单失败!' : '创建表单成功！'}
      subTitle={isError ? '发生了一些错误,请您稍后重试' : `表单uid：${formUid},点击”完成“返回”我的创建“查看`}
      extra={[
        <Button type="primary" key="ok" onClick={() => history.goBack()}>
          完成
        </Button>,
      ]}
    />
  )
}

export default CreateTemplateStepFive
