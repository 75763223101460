import * as React from 'react'
import {
  Button,
  Col,
  Row,
  Avatar,
  Card,
  Divider,
  Select,
  message,
  Spin,
  Tooltip,
  Form,
  InputNumber,
  Table,
  Tag,
} from 'antd'
import { ColumnProps } from 'antd/es/table'
import * as UserRequests from '@/requests/user'
import * as SuggestionRequests from '@/requests/suggestion'
import * as AssetsRequest from '@/requests/assets'
import * as Utils from '@/utils/string'

const { Meta } = Card

interface PropsType {
  setCurrent: Function
  template: ShareFormTemplateItem
  formHeadData: CreateFormHeadReq
  setFormHeadData: Function
  userInfo: UserInfoItem
  doCreateFormHeader: Function
}

const ToolSlectorWidget = (props: { userTool: Usertool | undefined; addTool: Function; deleteTool: Function }) => {
  let { userTool, addTool } = props

  const [filter_assetName, set_filter_assetName] = React.useState<string | undefined>(undefined)

  const [filter_assetComps, set_filter_assetComps] = React.useState<string[] | undefined>(undefined)
  const [filter_vAssetComps, set_filter_vAssetComps] = React.useState<
    { status_name: string; status_value: string }[] | null
  >(null)

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([])

  const [pagination, setPagination] = React.useState<{
    page: number
    pageSize: number
    total: number
  }>({ page: 1, pageSize: 6, total: 0 })
  const [tools, setTools] = React.useState<Tool[]>([])
  // const [maxVStatusCount, setMaxVStatusCount] = React.useState(0)
  // const [maxCompsCount, setMaxCopmsCount] = React.useState(0)
  const [fetching, setFetching] = React.useState(false)

  const fetchTools = async (page?: number) => {
    let { pageSize, total } = pagination
    if (!filter_assetName && !filter_assetComps && !filter_vAssetComps) return
    setFetching(true)
    const res = await AssetsRequest.fetchFilteredAssetList({
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps && {
        v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
      }),
      ...(filter_vAssetComps && {
        v_asset_status: filter_vAssetComps.map((x) => x.status_value),
      }),
      skip: page ? page : (pagination.page - 1) * pageSize,
      limit: pageSize,
    })
    if (res.code === 0) {
      if (res.data.length <= 0 || !res.data) {
        setTools([])
      }
      if (res.data.length > 0) {
        const vAssetStatusList = res.data.map((x) => {
          if (x.v_asset_status) {
            return x.v_asset_status.length
          } else {
            return 0
          }
        })
        vAssetStatusList.sort((a, b) => b - a)
        const compsList = res.data.map((x) => {
          if (x.asset_comps) {
            return x.asset_comps.length
          } else {
            return 0
          }
        })
        compsList.sort((a, b) => b - a)
        // setMaxCopmsCount(compsList[0])
        // setMaxVStatusCount(vAssetStatusList[0])
      }
      setTools(
        res.data.map((x) => {
          return {
            asset_id: x.asset_id,
            asset_name: x.asset_name,
            asset_comps: x.asset_comps,
            v_asset_comps: x.v_asset_comps,
            v_asset_status: x.v_asset_status.map((item) => {
              return {
                v_status_name: item.v_status_name,
                v_status_value: item.v_status_value,
                v_status_type: item.v_status_type,
              }
            }),
          }
        })
      )
      setPagination({ ...pagination, total: res.total })
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }
  React.useEffect(() => {
    fetchTools()
  }, [filter_assetName, filter_assetComps, filter_vAssetComps])

  if (!userTool) return null
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ height: 150, width: '100%' }}>
        <h4>已选择的工具：</h4>
        {userTool.tools.length <= 0 ? (
          <div>未选择</div>
        ) : (
          <div>
            {userTool.tools.map((x, index) => {
              return (
                <Tag
                  key={x.asset_id + index}
                  color={'blue'}
                  closable={true}
                  onClose={() => {
                    props.deleteTool(index)
                  }}
                >
                  {x.asset_name}
                </Tag>
              )
            })}
          </div>
        )}
      </div>
      <Divider>工具搜索</Divider>
      <div>
        <Form layout="inline" style={{ marginBottom: 16 }}>
          <Form.Item label="资产名称">
            <Select
              style={{ minWidth: 168 }}
              showSearch
              placeholder="全部"
              onDropdownVisibleChange={async (open) => {
                if (open) {
                }
              }}
              onSearch={async (value) => {
                if (Utils.isNull(value)) {
                  return
                }
                if (value) {
                  const res = await SuggestionRequests.getAssetNameSuggestion(value)
                  if (res.code === 0) {
                    setAssetNameOptions(res.data)
                  } else {
                    message.error('网络错误')
                  }
                } else {
                  return
                }
              }}
              onChange={(value: string) => set_filter_assetName(value)}
              value={filter_assetName}
            >
              {assetNameOptions.map((x: string) => {
                return (
                  <Select.Option value={x} key={`asset_name_option:${x}`}>
                    {x}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item label="资产组件">
            <Select
              onChange={(values: string) => {
                set_filter_assetComps(values.split(','))
              }}
              style={{ minWidth: 168 }}
              value={filter_assetComps ? filter_assetComps.toString() : undefined}
              placeholder="全部"
              onDropdownVisibleChange={async (open) => {
                if (open) {
                  if (!filter_assetName) return
                  const res = await SuggestionRequests.getAssetCompsSuggestion(filter_assetName)
                  if (res.code === 0) {
                    setAssetCompsOptions(
                      res.data.map((comps) => {
                        return comps.toString()
                      })
                    )
                  } else {
                    message.error('网络错误')
                  }
                }
              }}
            >
              {assetCompsOptions.map((x: string) => {
                return (
                  <Select.Option value={x} key={`asset_comps_option:${x}`}>
                    {x}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
        <Form layout="inline">
          <Form.Item label="地理信息">
            <Select
              onChange={(values: string) => {
                set_filter_vAssetComps(
                  values.split(',').map((x) => {
                    return {
                      status_name: x,
                      status_value: '',
                    }
                  })
                )
              }}
              style={{ minWidth: 168 }}
              value={filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name).toString() : undefined}
              placeholder="全部"
              onDropdownVisibleChange={async (open) => {
                if (open) {
                  if (!filter_assetName || !filter_assetComps) return
                  const res = await SuggestionRequests.getAssetVCompsSuggestion({
                    asset_name: filter_assetName,
                    asset_comps: filter_assetComps,
                  })
                  if (res.code === 0) {
                    setVAssetQueryOptions(
                      res.data.map((x) => {
                        return x.toString()
                      })
                    )
                  } else {
                    message.error('网络错误')
                  }
                }
              }}
            >
              {vAssetQueryOptions.map((item, index) => {
                return (
                  <Select.Option key={item + index} value={item}>
                    {item}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          {filter_vAssetComps &&
            filter_vAssetComps.length > 0 &&
            filter_vAssetComps.map((item) => {
              const a = item.status_name.search('里程')
              const b = item.status_name.search('轨枕号')
              if (a === -1 && b === -1) {
                return (
                  <Form.Item label={item.status_name} key={'status_value input' + item.status_name}>
                    <Select
                      style={{ minWidth: 168 }}
                      mode="tags"
                      placeholder={`全部`}
                      value={item.status_value === '' ? undefined : item.status_value.split(',')}
                      onChange={(values: string[]) => {
                        let ary = filter_vAssetComps.map((x) => x)
                        const newAry = ary.map((x) => {
                          if (x.status_name === item.status_name) {
                            x.status_value = values.toString()
                          }
                          return x
                        })
                        set_filter_vAssetComps(newAry)
                      }}
                    ></Select>
                  </Form.Item>
                )
              } else {
                return (
                  <Form.Item label={item.status_name} key={'status_value input' + item.status_name}>
                    <InputNumber
                      onChange={(value) => {
                        if (!value) return
                        let ary = filter_vAssetComps.map((x) => x)
                        const newAry = ary.map((x) => {
                          if (x.status_name === item.status_name) {
                            const range = x.status_value.split(',')

                            range[0] = value.toString()
                            x.status_value = range.toString()
                          }
                          return x
                        })
                        set_filter_vAssetComps(newAry)
                      }}
                    />
                    ~
                    <InputNumber
                      onChange={(value) => {
                        if (!value) return
                        let ary = filter_vAssetComps.map((x) => x)
                        const newAry = ary.map((x) => {
                          if (x.status_name === item.status_name) {
                            const range = x.status_value.split(',')
                            if (range.length === 2) {
                              range[1] = value.toString()
                            } else {
                              range.push(value.toString())
                            }

                            x.status_value = range.toString()
                          }
                          return x
                        })
                        set_filter_vAssetComps(newAry)
                      }}
                    />
                  </Form.Item>
                )
              }
            })}
        </Form>
        <div style={{ marginTop: 20 }}>
          <Table
            dataSource={tools}
            size="small"
            loading={fetching}
            pagination={{
              ...pagination,
              onChange: (page: number, pageSize: any) => {
                console.log('xxxxxxxxxx', page, pageSize)
                setPagination({ ...pagination, page: page })
                fetchTools(page)
              },
            }}
            columns={((): ColumnProps<Tool>[] => {
              return [
                {
                  title: '序号',
                  align: 'center',
                  key: 'no',
                  render(_: any, record: Tool, idx: number) {
                    return (
                      <span style={{ fontSize: 12 }} key={idx}>
                        {idx + 1}
                      </span>
                    )
                  },
                },
                {
                  title: '名称',
                  align: 'center',
                  key: 'asset_name',
                  render(_: any, record: Tool, idx: number) {
                    if (!record.asset_name) return null
                    const curName = record.asset_name
                    return (
                      <span style={{ fontSize: 12 }} key={record.asset_name + idx}>
                        {curName}
                      </span>
                    )
                  },
                },
                {
                  title: '资产组件',
                  align: 'center',
                  key: 'asset_comps',
                  render(_: any, record: Tool, idx: number) {
                    const curName = record.asset_comps.toString()
                    return (
                      <span style={{ fontSize: 12 }} key={curName + idx}>
                        {curName}
                      </span>
                    )
                  },
                },
                {
                  title: '地点信息',
                  align: 'center',
                  key: 'v_asset_status',
                  render: (_: any, record: Tool, idx: number) => {
                    const curName = record.v_asset_status
                      .map((x) => `${x.v_status_name}:${x.v_status_value}`)
                      .toString()

                    return (
                      <span style={{ fontSize: 12 }} key={curName + idx}>
                        {curName}
                      </span>
                    )
                  },
                },
                {
                  title: '操作',
                  key: 'action',
                  align: 'center',
                  render: (_: string, record: Tool, idx: number) => {
                    if (!record) return null
                    return (
                      <span style={{ fontSize: 12 }} key={`add_btn${idx}`}>
                        <Button
                          size="small"
                          type="primary"
                          style={{ fontSize: 12 }}
                          onClick={() => {
                            addTool(record)
                          }}
                          disabled={userTool && !!userTool.tools.find((x) => x.asset_id === record.asset_id)}
                        >
                          添加
                        </Button>
                      </span>
                    )
                  },
                },
              ]
            })()}
          />
        </div>
        .
      </div>
    </div>
  )
}
const CreateFormHeadStepThree = (props: PropsType) => {
  const { setCurrent, userInfo, formHeadData, setFormHeadData, doCreateFormHeader } = props

  const [user2add, setUser2add] = React.useState<UserInfoItem | undefined>(undefined)
  const [cooperOptions, setCooperOptions] = React.useState<UserInfoItem[]>([])
  const [fetching, setFetching] = React.useState(false)
  const [editingUser, setEditingUser] = React.useState<Formcooperator>({
    user_id: userInfo.user_id,
    user_name: userInfo.username,
    avatar: userInfo.avatar,
  })

  const fetchingUserLsit = async (searchCondition: string) => {
    setFetching(true)
    const res = await UserRequests.getUserList({
      limit: 100,
      skip: 0,
      search_condition: searchCondition,
    })
    if (res.code === 0) {
      setCooperOptions(res.data)
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }
  React.useEffect(() => {
    fetchingUserLsit('')
    setFormHeadData({
      ...formHeadData,
      user_tools: [{ user_name: userInfo.username, user_id: userInfo.user_id, tools: [] }],
    })
  }, [])

  const addTool = (tool: Tool) => {
    let userTools = formHeadData.user_tools.map((x) => {
      if (x.user_id === editingUser.user_id) {
        let arr = x.tools.map((item) => item)
        arr.push(tool)
        return {
          ...x,
          tools: arr,
        }
      }
      return x
    })
    setFormHeadData({ ...formHeadData, user_tools: userTools })
  }
  const deleteTool = (index: number) => {
    let userTools = formHeadData.user_tools.map((x) => {
      if (x.user_id === editingUser.user_id) {
        let arr = x.tools.map((item) => item)
        arr.splice(index, 1)
        return {
          ...x,
          tools: arr,
        }
      }
      return x
    })
    setFormHeadData({ ...formHeadData, user_tools: userTools })
  }

  return (
    <div
      style={{
        width: '100%',
        minHeight: 700,
        padding: 20,
      }}
    >
      <Row gutter={36}>
        <Col span={8}>
          <div style={{ border: '1px solid #EEEEEE', borderRadius: 10, padding: 16, height: '100%' }}>
            <h3>
              <div className="title-prefix"></div> 团队设置
            </h3>

            <div>
              <Divider>创建者</Divider>

              <Tooltip title="点击选择工具">
                <Card
                  style={{
                    cursor: 'pointer',
                    border: editingUser.user_id === userInfo.user_id ? '1px solid #1890ff ' : undefined,
                  }}
                  onClick={() => {
                    setEditingUser({
                      user_id: userInfo.user_id,
                      user_name: userInfo.username,
                      avatar: userInfo.avatar,
                    })
                  }}
                >
                  <Meta
                    avatar={
                      <Avatar size={40} className="avatar-circle" src={`v2/api/user/avatar/${userInfo.avatar}`} />
                    }
                    title={userInfo.username}
                    description={`用户ID：${userInfo.user_id}`}
                  ></Meta>
                </Card>
              </Tooltip>
            </div>

            <div>
              <Divider>合作者</Divider>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: 16,
                }}
              >
                <Select
                  placeholder={'选择合作者'}
                  style={{ width: '75%' }}
                  onSearch={(value) => {
                    fetchingUserLsit(value)
                  }}
                  showSearch
                  showArrow={false}
                  notFoundContent={fetching ? <Spin size="small" /> : '无用户'}
                  onChange={(value: string) => {
                    const user = JSON.parse(value)
                    setUser2add(user)
                  }}
                  value={user2add ? user2add.username : undefined}
                >
                  {cooperOptions.map((x: UserInfoItem, _index) => {
                    return (
                      <Select.Option value={JSON.stringify(x)} key={x.user_id + _index}>
                        <div
                          style={{
                            padding: 2,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {x.avatar ? (
                            <Avatar
                              size={30}
                              src={`v2/api/user/avatar/${x.avatar}`}
                              style={{ marginRight: 10 }}
                            ></Avatar>
                          ) : (
                            <Avatar
                              size={30}
                              style={{
                                backgroundColor: '#c37878',
                                marginRight: 10,
                              }}
                            >
                              <strong>{x.username.slice(0, 1)}</strong>
                            </Avatar>
                          )}

                          {x.username}
                        </div>
                      </Select.Option>
                    )
                  })}
                </Select>
                <Button
                  type="primary"
                  onClick={() => {
                    if (!user2add) return
                    let newArr = formHeadData.form_cooperators.map((x) => x)
                    let toolsArr = formHeadData.user_tools.map((x) => x)
                    let ids = formHeadData.form_cooperators.map((x) => x.user_id)
                    let cooper = {
                      user_id: user2add.user_id,
                      user_name: user2add.username,
                      avatar: user2add.avatar,
                    }

                    if (ids.includes(cooper.user_id)) {
                      message.error('请勿重复添加该用户！')
                      return
                    } else {
                      newArr.push(cooper)
                      toolsArr.push({
                        user_name: cooper.user_name,
                        user_id: cooper.user_id,
                        tools: [],
                      })

                      setFormHeadData({
                        ...formHeadData,
                        user_tools: toolsArr,
                        form_cooperators: newArr,
                      })
                      setUser2add(undefined)
                    }
                  }}
                >
                  添加
                </Button>
              </div>
              {formHeadData.form_cooperators.map((x, c_index) => {
                return (
                  <Tooltip title="点击选择工具" key={x.user_id + c_index + x.avatar}>
                    <Card
                      style={{
                        cursor: 'pointer',
                        marginBottom: 5,
                        border: editingUser.user_id === x.user_id ? '1px solid #f39800 ' : undefined,
                      }}
                      onClick={() => {
                        setEditingUser({ ...x })
                      }}
                    >
                      <Meta
                        avatar={
                          x.avatar ? (
                            <Avatar size={45} className="avatar-circle" src={`v2/api/user/avatar/${x.avatar}`} />
                          ) : (
                            <Avatar size={45} style={{ backgroundColor: '#c37878' }}>
                              {x.user_name.slice(0, 1)}
                            </Avatar>
                          )
                        }
                        title={x.user_name}
                        description={`ID：${x.user_id}`}
                      ></Meta>
                    </Card>
                  </Tooltip>
                )
              })}
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              width: '100%',
              padding: 16,
              border: '1px solid #EEEEEE',
              borderRadius: 10,
              height: '100%',
            }}
          >
            <h3>
              <div className="title-prefix"></div>
              {`工具选择（用户：${editingUser.user_name}）`}
            </h3>

            <ToolSlectorWidget
              userTool={formHeadData.user_tools.find((x) => x.user_id === editingUser.user_id)}
              addTool={addTool}
              deleteTool={deleteTool}
            />
          </div>
        </Col>
      </Row>
      <div style={{ margin: '36px 0 0 0 ', display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => {
            setCurrent(1)
          }}
          type="primary"
          style={{ marginRight: 20, padding: '0 36px' }}
        >
          上一步
        </Button>
        <Button
          onClick={() => {
            doCreateFormHeader()
          }}
          type="primary"
          style={{ padding: '0 36px' }}
        >
          下一步
        </Button>
      </div>
    </div>
  )
}

export default CreateFormHeadStepThree
