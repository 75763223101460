import React from 'react'
import { Layout } from 'antd'
import { StickyContainer } from 'react-sticky'

import './index.less'

export default ({
  useStickyContainter = false,
  children,
}: {
  useStickyContainter?: boolean
  children: React.ReactChildren | any
}) => {
  const inNode = <Layout.Content className="workbench-page__content">{children}</Layout.Content>

  const El = useStickyContainter ? StickyContainer : 'div'

  return (
    <El
      className="workbench-page__content-wrapper"
      style={{
        ...(useStickyContainter && { position: 'relative' }),
      }}
    >
      {inNode}
    </El>
  )
}
