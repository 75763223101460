import * as React from 'react';
import { Divider, Button, Form, Input, Modal, Select, message, Avatar, DatePicker, Steps, Checkbox, Spin } from 'antd';
import { getAllLabelProjectList, getTasksByCondition, copyLabelTasks } from '@/requests/labelProject';
import moment from 'moment';
import { getAllUsers } from '@/requests/user';
import { SearchOutlined } from '@ant-design/icons';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  editingPorject: LabelProjectListItem;
  fetchData: Function;
}
const CopyPorjectModal = (props: PropsType) => {
  const { visible, setVisible, editingPorject, fetchData } = props;
  const [fetching, setFetching] = React.useState(false);
  const [projectOptions, setProjectOptions] = React.useState<LabelProjectListItem[]>([]);
  const [filterProject, setFilterProject] = React.useState<string>('');
  const [userOptions, setUserOptions] = React.useState<UserInfoItem[]>([]);
  const [filterCreator, setFilterCreator] = React.useState<number[]>([]);
  const [filterParter, setFilterParter] = React.useState<number[]>([]);
  const [filterStatus, setFilterStatus] = React.useState(0);
  const [filterCreateTime, setFilterCreateTime] = React.useState<any>();
  const [filterLabelTime, setFilterLabelTime] = React.useState<any>();
  const [filterName, setFilterName] = React.useState('');
  const [taskUids, setTaskUids] = React.useState<number[]>([]);
  const [step, setStep] = React.useState(0);
  const [principalUserIds, setPrincipalUserIds] = React.useState<number[]>([]);
  const [principalUserOptions, setPrincipalUserOptions] = React.useState<ParticipantUserList[]>([]);
  //搜索任务
  const doSearchTasks = async () => {
    setFetching(true);
    const res = await getTasksByCondition({
      limit: 10000,
      skip: 0,
      ...(filterProject && {
        project_uid: filterProject,
      }),
      ...(filterCreator.length > 0 && {
        create_user_ids: filterCreator,
      }),
      ...(filterParter.length > 0 && {
        principal_user_ids: filterParter,
      }),
      ...(filterStatus !== 0 && {
        label: filterStatus === 1 ? true : false,
      }),
      ...(filterCreateTime && {
        task_create_time_start: moment(filterCreateTime[0]).format('YYYY-MM-DD hh:mm:ss'),
        task_create_time_end: moment(filterCreateTime[1]).format('YYYY-MM-DD hh:mm:ss'),
      }),
      ...(filterLabelTime && {
        task_start_time: moment(filterLabelTime[0]).format('YYYY-MM-DD hh:mm:ss'),
        task_end_time: moment(filterLabelTime[1]).format('YYYY-MM-DD hh:mm:ss'),
      }),
      ...(filterName && {
        image_name: filterName,
      }),
    });

    if (res.code === 0) {
      if (res.data.length === 0) {
        message.error('未搜索到任务');
      }
      setTaskUids(res.data.map((x) => x.id));
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  //获取当前项目下的成员
  const doGetProjectUsers = async () => {
    if (!editingPorject) return;
    // const res = await getPrincipalUserIds(editingPorject.project_uid);
    // if (res.code === 0) {
    // }
    setFetching(true);
    setTimeout(() => {
      setPrincipalUserOptions(editingPorject.participant_user_list);
      setFetching(false);
    }, 1000);
  };
  //点击取消事件
  const handleCancel = () => {
    setVisible(false);
  };

  //点击完成事件
  const handleOk = async () => {
    if (!editingPorject) return;
    if (taskUids.length === 0) {
      message.error('请先搜索任务');
      return;
    }
    if (principalUserIds.length === 0) {
      message.error('请选择负责人');
      return;
    }
    setFetching(true);
    message.loading('正在导入，请稍后');
    const res = await copyLabelTasks({
      project_uid: editingPorject.project_uid,
      label_task_id_list: taskUids,
      principal_user_id_list: principalUserIds,
    });
    if (res.code === 0) {
      message.destroy();
      message.success('导入成功');
      fetchData();
      setVisible(false);
    } else {
      message.error(res.message);
    }

    setFetching(false);
  };

  //点击下一步事件
  const handleNext = () => {
    if (taskUids.length === 0) {
      message.error('请先搜索任务');
      return;
    }
    setStep(1);
  };

  //点击上一步事件
  const handlePrev = () => {
    setStep(0);
  };
  //点击下一步时加载项目成员列表
  React.useEffect(() => {
    if (step === 1) {
      doGetProjectUsers();
    }
  }, [step]);

  return (
    <Modal
      visible={visible}
      title={
        <span>
          <div className="title-prefix"></div>创建任务-从已有项目复制
        </span>
      }
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      width={650}
    >
      <Steps current={step} size="small">
        <Steps.Step title="选择任务" />
        <Steps.Step title="分配任务" />
      </Steps>
      <div style={{ display: step === 0 ? 'block' : 'none', marginTop: 16 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            当前任务数量：<h2 style={{ display: 'inline-block' }}>{taskUids.length}</h2>
          </span>
          <Button
            icon={<SearchOutlined />}
            loading={fetching}
            onClick={() => doSearchTasks()}
            style={{ borderRadius: 5 }}
          >
            搜索
          </Button>
        </div>
        <Form colon={true} layout="vertical">
          <Form.Item className="filter-item" label="项目">
            <Select
              placeholder="全部"
              allowClear
              onDropdownVisibleChange={async (open) => {
                if (open) {
                  const res = await getAllLabelProjectList({ limit: 10000, skip: 0 });
                  if (res.code === 0) {
                    setProjectOptions(res.data);
                  } else {
                    message.error('网络错误');
                  }
                }
              }}
              onChange={(value: string) => {
                setFilterProject(value);
              }}
            >
              {projectOptions.map((project) => {
                return (
                  <Select.Option value={project.project_uid} key={project.project_uid}>
                    {project.project_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item className="filter-item" label="创建人">
            <Select
              mode="multiple"
              placeholder="全部"
              onDropdownVisibleChange={async (open) => {
                if (open) {
                  const res = await getAllUsers({
                    limit: 1000,
                    skip: 0,
                    username: '',
                  });
                  if (res.code === 0) {
                    setUserOptions(res.data);
                  } else {
                    message.error('网络错误');
                  }
                }
              }}
              onChange={(values: number[]) => {
                setFilterCreator(values);
              }}
            >
              {userOptions.map((x) => {
                return (
                  <Select.Option value={x.id} key={x.user_id}>
                    <span>
                      {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.username}</Avatar>}{' '}
                      <span style={{ paddingLeft: 8 }}>{x.username}</span>
                    </span>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item className="filter-item" label="标注人">
            <Select
              placeholder="全部"
              mode="multiple"
              onDropdownVisibleChange={async (open) => {
                if (open) {
                  const res = await getAllUsers({
                    limit: 1000,
                    skip: 0,
                    username: '',
                  });
                  if (res.code === 0) {
                    setUserOptions(res.data);
                  } else {
                    message.error('网络错误');
                  }
                }
              }}
              onChange={(values: number[]) => {
                setFilterParter(values);
              }}
            >
              {userOptions.map((x) => {
                return (
                  <Select.Option value={x.id} key={x.user_id}>
                    <span>
                      {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.username}</Avatar>}{' '}
                      <span style={{ paddingLeft: 8 }}>{x.username}</span>
                    </span>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item className="filter-item" label="状态">
            <Select onChange={(value: number) => setFilterStatus(value)} placeholder="全部">
              <Select.Option value={0}>全部</Select.Option>
              <Select.Option value={1}>已完成</Select.Option>
              <Select.Option value={2}>未完成</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className="filter-item" label="图片名">
            <Input onChange={(e) => setFilterName(e.target.value)} placeholder="全部"></Input>
          </Form.Item>
          <Form.Item className="filter-item" label="创建时间">
            <DatePicker.RangePicker onChange={(e) => setFilterCreateTime(e)}></DatePicker.RangePicker>
          </Form.Item>

          <Form.Item className="filter-item" label="标注时间">
            <DatePicker.RangePicker onChange={(e) => setFilterLabelTime(e)}></DatePicker.RangePicker>
          </Form.Item>
        </Form>
      </div>
      <div style={{ display: step === 1 ? 'block' : 'none', marginTop: 16 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
            fontSize: 14,
            color: '#666',
          }}
        >
          请从以下项目成员中选择参与任务分配的人员，任务将随机平均分配给选择的成员：
        </div>
        {fetching ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 150 }}>
            <Spin spinning={fetching} tip="正在加载项目成员"></Spin>
          </div>
        ) : (
          <>
            <Checkbox.Group
              options={principalUserOptions.map((x) => {
                return {
                  label: (
                    <span>
                      {x.avatar ? (
                        <Avatar src={`v2/api/user/avatar/${x.avatar}`} size="small" />
                      ) : (
                        <Avatar size="small">{x.username}</Avatar>
                      )}
                      <span style={{ paddingLeft: 8 }}>{`${x.username}`}</span>
                    </span>
                  ),
                  value: x.id,
                };
              })}
              value={principalUserIds}
              onChange={(values: any[]) => {
                if (values.length > taskUids.length) {
                  message.error('分配人数不能大于任务数');
                  return;
                }
                setPrincipalUserIds(values);
                console.log(values);
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 20,
              }}
            >
              <Button
                style={{ borderRadius: 5, marginRight: 20 }}
                onClick={() => {
                  if (principalUserOptions.length > taskUids.length) {
                    message.error('分配人数不能大于任务数');
                    return;
                  }
                  setPrincipalUserIds(principalUserOptions.map((x) => x.id));
                }}
              >
                选择全部
              </Button>
              <Button
                style={{ borderRadius: 5 }}
                onClick={() => {
                  setPrincipalUserIds([]);
                }}
                disabled={principalUserIds.length < principalUserOptions.length}
              >
                取消全选
              </Button>
            </div>
          </>
        )}
      </div>
      <Divider></Divider>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
        <Button
          style={{ borderRadius: 5 }}
          onClick={() => {
            if (step === 0) {
              handleCancel();
            } else {
              handlePrev();
            }
          }}
        >
          {step === 0 ? '取消' : '上一步'}
        </Button>
        <Button
          style={{ borderRadius: 5 }}
          type="primary"
          disabled={taskUids.length === 0 || (step === 1 && principalUserIds.length === 0)}
          onClick={() => {
            if (step === 1) {
              handleOk();
            } else {
              handleNext();
            }
          }}
        >
          {step === 0 ? '下一步' : '完成'}
        </Button>
      </div>
    </Modal>
  );
};

export default CopyPorjectModal;
