import * as React from 'react'

import { PageHeader, Button, message, Spin, Modal } from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'
import { Responsive, WidthProvider } from 'react-grid-layout'
import * as BIRequests from '@/requests/bi'
import AddChartFormModal from './components/AddChartFormModal'
import { useCtxState } from '@/App.store'
import CustomChart from './components/CustomChart'
import EditChartDrawer from './components/EditChartDrawer'
import ShareConfigModal from './components/ShareConfigModal'
import { CloseCircleFilled, ShareAltOutlined, ImportOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons'

const ResponsiveGridLayout = WidthProvider(Responsive)

const BiHome = () => {
  const [{ userInfo }] = useCtxState()
  const [config, setConfig] = React.useState<BIconfigItem | null>(null)

  const [addChartModalVisible, setAadChartModalVisible] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [editingChartId, setEditingChartId] = React.useState('')
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const [shareVisible, setShareVisible] = React.useState(false)

  const [layout, setLayout] = React.useState<any>()
  const history = useHistory()
  React.useEffect(() => {
    setFetching(true)
    fetchConfig()
    fetchLayout()
  }, [])

  const fetchLayout = async () => {
    const res = await BIRequests.getLayout()
    if (res.code === 0) {
      if (res.data !== null) {
        setLayout(JSON.parse(res.data.config).layout)
      }
    } else {
      message.error(res.message)
    }
  }

  const fetchConfig = async () => {
    const res = await BIRequests.getBIconfig()
    if (res.code === 0) {
      setConfig(res.data)
    } else if (res.code === 30001) {
      message.info('您还没有创建过图表，点击添加图表创建您的第一张图表吧')
      setConfig(null)
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }
  return (
    <div style={{ height: '100%' }}>
      <PageHeader
        title={null}
        ghost={true}
        extra={[
          <Button key="refresh" icon={<RedoOutlined />} onClick={() => fetchConfig()}>
            刷新
          </Button>,
          <Button
            key="share-chart-config"
            icon={<ShareAltOutlined />}
            onClick={() => setShareVisible(true)}
            disabled={config === null}
          >
            图表配置分享
          </Button>,
          <Button
            key="import-chart-config"
            icon={<ImportOutlined />}
            onClick={() => history.push('/workbench/chart-management/hi/import-config-list')}
          >
            图表配置导入
          </Button>,
          <Button key="add-chart" type="primary" icon={<PlusOutlined />} onClick={() => setAadChartModalVisible(true)}>
            添加图表
          </Button>,
        ]}
        style={{ padding: 0 }}
      ></PageHeader>
      <div className="charts-container">
        {fetching ? (
          <div
            style={{
              width: '100%',
              height: '80vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin tip="加载中..." />
          </div>
        ) : config ? (
          <ResponsiveGridLayout
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 3 }}
            isDraggable={true}
            isDroppable={true}
            layouts={layout}
            onLayoutChange={async (currentLayout: any, allLayout) => {
              const res = await BIRequests.updateLayout({
                config: JSON.stringify({ ['layout']: allLayout }),
              })
              if (res.code === 0) {
              } else {
              }
            }}
            draggableCancel=".chart-el"
          >
            {config.charts.map((chart, index) => {
              const commonGrid = { x: 0, y: 0, w: 4, h: 2, minW: 4, maxW: 12 }
              const grid = JSON.parse(chart.echart_config.grid)
              return (
                <div
                  key={index}
                  data-grid={{ ...commonGrid, x: grid.x, y: grid.y || 0 }}
                  style={{
                    padding: 16,
                    backgroundColor: '#ffffff',
                    position: 'relative',
                    border: '1px solid #F0F0F0',
                  }}
                >
                  <CustomChart
                    chart={chart}
                    setDrawerVisible={setDrawerVisible}
                    setEditingChartId={setEditingChartId}
                  />
                  <span
                    style={{
                      position: 'absolute',
                      right: '2px',
                      top: 0,
                      cursor: 'pointer',
                    }}
                  >
                    <CloseCircleFilled
                      onClick={() => {
                        Modal.confirm({
                          okText: '确认',
                          onOk: async () => {
                            const res = await BIRequests.deleteChart(chart.chart_uid)
                            if (res.code === 0) {
                              message.success('删除成功')
                              fetchConfig()
                            } else {
                              message.error(res.message)
                            }
                          },
                          cancelText: '取消',
                          title: '温馨提示',
                          content: '确认删除这张图表吗？',
                        })
                      }}
                    />
                  </span>
                </div>
              )
            })}
          </ResponsiveGridLayout>
        ) : null}
      </div>
      {addChartModalVisible && (
        <AddChartFormModal
          visible={addChartModalVisible}
          setVisible={setAadChartModalVisible}
          config={config}
          setConfig={setConfig}
          userInfo={userInfo}
          fetchConfig={fetchConfig}
        />
      )}
      <EditChartDrawer
        chart_id={editingChartId}
        drawerVisible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        userInfo={userInfo}
        setEditingChartId={setEditingChartId}
        fetchConfig={fetchConfig}
      />
      <ShareConfigModal shareVisible={shareVisible} setShareVisible={setShareVisible} config={config} />
    </div>
  )
}

export default BiHome
