/// <reference path="type.d.ts" />
/// <reference path="role.d.ts" />

import request from './_base'

/**
 * 创建role
 * @param  {CreateRoleReq} input
 */
export function createRole(input: CreateRoleReq): Promise<AssetsResourceProto<{ data: RoleItem }>> {
  return request({
    method: 'POST',
    url: `/role/role`,
    data: input,
  }) as any
}

/**
 * 删除role
 * @param  {number} id 角色id
 */
export function deleteRole(id: number): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/role/role/${id}`,
  }) as any
}
/**
 * 更新role
 * @param  {UpdateRoleReq} input
 */
export function updateRole(input: UpdateRoleReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/role/role`,
    data: input,
  }) as any
}

/**
 * 给role赋权
 * @param  {AuthorityReq} input
 */
export function authorityRole(input: AuthorityReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/role/role/authority`,
    data: input,
  }) as any
}

/**
 * 删除role权限
 * @param  {AuthorityReq} input
 */
export function deleteAuthority(input: AuthorityReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/role/role/authority`,
    data: input,
  }) as any
}

/**
 * 查看role list
 * @param  {GetRoleListReq} input
 */
export function getRoleList(input: GetRoleListReq): Promise<AssetsResourceProto<{ data: RoleItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/role/roles`,
    data: input,
  }) as any
}

/**
 * 更新role的sysMenuAuthority
 * @param  {AuthorityReq} input
 */
export function updateRoleAuthes(
  input: AuthorityReq
): Promise<AssetsResourceProto<{ data: RoleItem[]; total: number }>> {
  return request({
    method: 'PUT',
    url: `/role/role/authorities`,
    data: input,
  }) as any
}

/**
 * 查通过roleId获取sysMenu
 * @param  {number} role_id
 */
export function getSysMenu(role_id: number): Promise<AssetsResourceProto<{ data: SysMenuItem[] }>> {
  return request({
    method: 'GET',
    url: `/role/sysmenu/${role_id}`,
  }) as any
}

/**
 * 新建sysMenuItem
 * @param  {SysMenuReq} input
 */
export function CreateSysMenuItem(input: SysMenuItemReq): Promise<AssetsResourceProto<{ data: SysMenuItem }>> {
  return request({
    method: 'POST',
    url: `/authority/sysmenu`,
    data: input,
  }) as any
}

/**
 * 更新sysMenuItem
 * @param  {SysMenuReq} input
 */
export function UpdateSysMenuItem(input: UpdateSysMenuItemReq): Promise<AssetsResourceProto<{ data: SysMenuItem }>> {
  return request({
    method: 'PUT',
    url: `/authority/sysmenu`,
    data: input,
  }) as any
}

/**
 * 根据id删除本身和其所有子节点
 * @param  {number} sysmenu的id
 */
export function deleteSysMenuItem(input: { id: number }): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/authority/sysmenu`,
    data: input,
  }) as any
}

/**
 * 获取authority 树结构
 */
export function getSysMenuTree(): Promise<AssetsResourceProto<{ data: SysMenuItem[] }>> {
  return request({
    method: 'GET',
    url: `/authority/sysmenu`,
  }) as any
}

/**
 * 新建数据权限
 * @param  {CreateDataAuthorityReq} input
 */
export function createDataAuthorityItem(
  input: CreateDataAuthorityReq
): Promise<AssetsResourceProto<{ data: DataAuthorityItem }>> {
  return request({
    method: 'POST',
    url: `/authority/data/authority`,
    data: input,
  }) as any
}

/**
 * 更新数据权限
 * @param  {UpdateDataAuthorityReq} input
 */
export function updateDataAuthorityItem(
  input: UpdateDataAuthorityReq
): Promise<AssetsResourceProto<{ data: DataAuthorityItem }>> {
  return request({
    method: 'PUT',
    url: `/authority/data/authority`,
    data: input,
  }) as any
}

/**
 * 根据id删除数据权限
 * @param  {number} data_authority_id
 */
export function deleteDataAuthorityItem(input: {
  data_authority_id: number
}): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/authority/data/authority`,
    data: input,
  }) as any
}

/**
 * 根据id查询数据权限
 * @param  {number} data_authority_id
 */
export function getDataAuthorityItem(data_authority_id: number): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'GET',
    url: `/authority/data/authority/${data_authority_id}`,
  }) as any
}

/**
 * 查询数据权限lis
 * @param  {GetDataAuthListReq} input
 */
export function getDataAuthorityList(
  input: GetDataAuthListReq
): Promise<AssetsResourceProto<{ data: DataAuthorityItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/authority/data/authority/list`,
    data: input,
  }) as any
}

/**
 * 绑定数据权限到sys_org
 * @param  {BindDataAuthorityToSysMenuReq} input
 */
export function bindDataAuthority2SysMenu(
  input: BindDataAuthorityToSysMenuReq
): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/authority/bind/org/data`,
    data: input,
  }) as any
}

/**
 * 创建organization
 * @param  {CreateOrgReq} input
 */
export function createOrg(input: CreateOrgReq): Promise<AssetsResourceProto<{ data: OrgItem }>> {
  return request({
    method: 'POST',
    url: `/authority/org`,
    data: input,
  }) as any
}

/**
 * 更新organization
 * @param  {UpdateOrgReq} input
 */
export function updateOrg(input: UpdateOrgReq): Promise<AssetsResourceProto<{ data: OrgItem }>> {
  return request({
    method: 'PUT',
    url: `/authority/org`,
    data: input,
  }) as any
}
/**
 * 删除 organization
 * @param  {UpdateOrgReq} input
 */
export function deleteOrg(input: DeleteOrgReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/authority/org`,
    data: input,
  }) as any
}

/**
 *  查询 organization by id
 * @param  {number} sys_org_id
 */
export function getOrgDetail(sys_org_id: number): Promise<AssetsResourceProto<{ data: OrgItem }>> {
  return request({
    method: 'GET',
    url: `/authority/org/${sys_org_id}`,
  }) as any
}

/**
 * 查询organization list
 * @param  {GetOrgListReq} input
 */
export function getOrgList(input: GetOrgListReq): Promise<AssetsResourceProto<{ data: OrgItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/authority/org/list`,
    data: input,
  }) as any
}

/**
 * 获取当前登录人的组织信息
 */
export function getUserOrgInfo(): Promise<AssetsResourceProto<{ data: OrgItem[] }>> {
  return request({
    method: 'GET',
    url: `/authority/org/current`,
  }) as any
}

/**
 * 添加role到organization
 * @param  {AddRolesToOrgReq} input
 */
export function addRoleToOrg(input: AddRolesToOrgReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/authority/role/bind/org`,
    data: input,
  }) as any
}
