import * as React from 'react';
import { Modal, Form, Input, message, Spin, Button, Table, Divider, Popconfirm } from 'antd';
import * as TagRequests from '@/requests/tag';
import { HistoryOutlined } from '@ant-design/icons';

import SingleUserCompanyIdInfoTooltip from '@/ui-components/SingleUserCompanyIdTooltip';
import TagValueHistoryTable from './TagValueHistoryTable';

interface PropsType {
  visible: boolean;
  asset_id: string | null;
  tag_name: string;
  setVisible: Function;
  setTagName: Function;
  reload: Function;
}

interface TagFormValues {
  tag_name: string;
  tag_value: string;
}

const TagDetail = (props: PropsType) => {
  const { visible, asset_id, tag_name, setVisible, setTagName } = props;
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [allTags, setAllTags] = React.useState<TagItem[]>([]);
  const [editingTag, setEditingTag] = React.useState<TagItem | undefined>(undefined);
  const [modifyVisible, setModifyVisible] = React.useState(false);
  const [modifyTagHistoryForm] = Form.useForm();
  const [updateTagForm] = Form.useForm();

  const doModifyTagHistory = async (values: TagFormValues) => {
    if (!editingTag) return;
    if (values.tag_value === editingTag.tag_value) {
      return;
    }
    setFetching(true);
    const res = await TagRequests.updateTagHistoryItem({
      asset_tag_id: editingTag.asset_tag_id,
      tag_name: editingTag.tag_name,
      tag_value: values.tag_value,
    });
    if (res.code === 0) {
      fetchAssetTagsData();
      setFetching(false);
      message.success('修改成功');
      setModifyVisible(false);
    } else {
      setFetching(false);
      message.error(res.message);
    }
  };

  const doUpdateTag = async (values: TagFormValues) => {
    const tag = allTags.filter((x) => x.is_use === 1)[0];
    if (values.tag_name === tag.tag_name && values.tag_value === tag.tag_value) {
      return;
    }
    setFetching(true);
    const res = await TagRequests.updateTag({
      asset_tag_id: tag.asset_tag_id,
      tag_name: values.tag_name,
      tag_value: values.tag_value,
    });
    if (res.code === 0) {
      if (res.data.tag_name === tag.tag_name) {
        fetchAssetTagsData();
      } else {
        setTagName(res.data.tag_name);
      }
      setFetching(false);
      message.success('保存成功');
    } else {
      setFetching(false);
      message.error(res.message);
    }
  };

  const handleUpdateTagOk = (values: TagFormValues) => {
    doUpdateTag(values);
  };

  const handleModifyTagHistoryOk = (values: TagFormValues) => {
    doModifyTagHistory(values);
  };

  const fetchAssetTagsData = async () => {
    if (!asset_id) return;
    if (!tag_name) return;
    const res = await TagRequests.getTagsByAssetId(asset_id);
    if (res.code === 0) {
      setAllTags(res.data.filter((x) => x.tag_name === tag_name));
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchAssetTagsData();
  }, [tag_name]);

  const ModifyTagHistoryForm = () => {
    if (!editingTag) return null;
    const { tag_name, tag_value } = editingTag;
    return (
      <Form form={modifyTagHistoryForm} onFinish={handleModifyTagHistoryOk} layout="vertical" preserve={false}>
        <Form.Item label="标签名称" name="tag_name" initialValue={tag_name}>
          <Input placeholder="请输入标签名称" disabled />
        </Form.Item>
        <Form.Item label="标签状态" name="tag_value" initialValue={tag_value}>
          <Input placeholder="请输入标签状态值" />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setModifyVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const UpdateAssetTagForm = () => {
    const tag = allTags.filter((x) => x.is_use === 1)[0];
    if (!tag) return null;
    return (
      <Form form={updateTagForm} onFinish={handleUpdateTagOk} layout="vertical" preserve={false}>
        <Form.Item label="标签名称" name="tag_name" initialValue={tag.tag_name}>
          <Input placeholder="请输入标签名称" />
        </Form.Item>
        <Form.Item label="标签状态" name="tag_value" initialValue={tag.tag_value}>
          <Input placeholder="请输入标签状态值" />
        </Form.Item>
      </Form>
    );
  };

  const removeTagHistoryItem = (id: number) => {
    Modal.confirm({
      title: 'Confirm',
      content: `确认删除该记录?`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const res = await TagRequests.deleteTagHistoryItem({
          asset_tag_id: id,
        });
        if (res.code === 0) {
          message.success('删除成功', 1);
          fetchAssetTagsData();
        } else {
          message.error(res.message);
        }
      },
    });
  };

  return (
    <Modal
      title={'标签详情'}
      okText="保存"
      cancelText="退出"
      destroyOnClose
      onCancel={() => {
        props.reload();
        setVisible(false);
      }}
      visible={visible}
      width={700}
      bodyStyle={{ minHeight: 300 }}
      onOk={(e) => {
        updateTagForm.submit();
      }}
      okButtonProps={{
        disabled: fetching,
        loading: fetching,
      }}
      afterClose={() => props.reload()}
    >
      {loading ? (
        <Spin spinning={loading} tip={'加载中...'} style={{ position: 'absolute', top: '50%', right: '50%' }}></Spin>
      ) : (
        <div>
          {editingTag && (
            <Modal
              title={'修改历史值'}
              destroyOnClose
              onCancel={() => setModifyVisible(false)}
              visible={modifyVisible}
              footer={null}
              afterClose={() => setEditingTag(undefined)}
            >
              <ModifyTagHistoryForm />
            </Modal>
          )}

          <UpdateAssetTagForm />
          <Divider>历史记录</Divider>
          <Table
            dataSource={allTags}
            loading={loading}
            rowKey={(record: TagItem) => record.asset_tag_id.toString()}
            pagination={false}
            size="small"
            columns={(() => {
              return [
                {
                  title: '标签值',
                  align: 'center' as 'center',
                  dataIndex: 'value',
                  key: 'value',
                  render(_: any, record: TagItem) {
                    return (
                      <>
                        {record.tag_value}
                        {/* 2020.08.25 对isuse===1 的状态提供修改和历史的展示*/}
                        {true && (
                          <Popconfirm
                            title={<TagValueHistoryTable asset_tag_id={record.asset_tag_id} />}
                            icon={null}
                            trigger="hover"
                            okButtonProps={{
                              style: { display: 'none' },
                            }}
                            cancelButtonProps={{
                              style: { display: 'none' },
                            }}
                            destroyTooltipOnHide
                          >
                            <HistoryOutlined
                              style={{
                                color: '#1691ff',
                                fontSize: 14,
                                marginLeft: 2,
                                cursor: 'pointer',
                              }}
                            />
                          </Popconfirm>
                        )}
                      </>
                    );
                  },
                },
                {
                  title: '记录人',
                  align: 'center' as 'center',
                  dataIndex: 'user_name',
                  key: 'user_name',
                  render(_: any, record: TagItem, idx: number) {
                    return (
                      <span>
                        {record.update_user_name}
                        <SingleUserCompanyIdInfoTooltip user_id={record.update_user_id} />
                      </span>
                    );
                  },
                },
                {
                  title: '记录时间',
                  align: 'center' as 'center',
                  dataIndex: 'creat_time',
                  key: 'creat_time',
                  render(_: any, record: TagItem, idx: number) {
                    return <>{record.update_time}</>;
                  },
                },

                {
                  title: '操作',
                  align: 'center' as 'center',
                  dataIndex: 'option',
                  key: 'option',
                  render(_: any, record: TagItem, idx: number) {
                    return (
                      <>
                        <Button
                          type="dashed"
                          size="small"
                          onClick={() => {
                            setEditingTag(record);
                            setModifyVisible(true);
                          }}
                        >
                          修改
                        </Button>
                        {record.is_use !== 1 && (
                          <Button
                            type="default"
                            size="small"
                            onClick={() => {
                              removeTagHistoryItem(record.asset_tag_id);
                            }}
                            style={{ marginLeft: 10 }}
                          >
                            删除
                          </Button>
                        )}
                      </>
                    );
                  },
                },
              ];
            })()}
          />
        </div>
      )}
    </Modal>
  );
};

export default TagDetail;
