/// <reference path="type.d.ts" />
/// <reference path="assetTemplate.d.ts" />

import request from './_base'

/**
 * 创建资产模板
 * @param  {CreateAssetTemplateReq} input
 */
export function createAssetTemplate(
  input: CreateAssetTemplateReq
): Promise<AssetsResourceProto<{ data: AssetTemplateItem }>> {
  return request({
    method: 'POST',
    url: `/asset-template/asset-template`,
    data: input,
  }) as any
}

/**
 * 更新资产模板
 * @param  {UpdateAssetTemplateReq} input
 */
export function updateAssetTemplate(
  input: UpdateAssetTemplateReq
): Promise<AssetsResourceProto<{ data: AssetTemplateItem }>> {
  return request({
    method: 'PUT',
    url: `/asset-template/asset-template`,
    data: input,
  }) as any
}

/**
 * 删除资产模板
 * @param  {DeleteAssetTemplateReq} input
 */
export function deleteAssetTemplate(input: DeleteAssetTemplateReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/asset-template/asset-template`,
    data: input,
  }) as any
}

/**
 * 查询资产模板list
 * @param  {GetAssetTemplateListReq} input
 */
export function getAssetTemplateList(
  input: GetAssetTemplateListReq
): Promise<AssetsResourceProto<{ data: AssetTemplateItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/asset-template/list`,
    data: input,
  }) as any
}

/**
 * assetName+assetComps 唯一校验
 * @param  {CheckNameAndCompsReq} input
 */
export function checkNameAndComps(input: CheckNameAndCompsReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'POST',
    url: `/asset-template/hashcode/unique`,
    data: input,
  }) as any
}
