import * as React from 'react'
import { Form, Button, Select, message } from 'antd'
import * as SuggestionRequests from '@/requests/suggestion'
import * as Utils from '@/utils/string'
import { DeleteOutlined } from '@ant-design/icons'

interface propsType {
  setCurrent: Function
  setFields: Function
  fields: FormTemplateFieldItem[]
}

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

const CreateTemplateStepTwo = (props: propsType) => {
  const [statusNameOptions, setStatusNameOptions] = React.useState<StatusSuggestionItem[]>([])
  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([])
  const [form] = Form.useForm()
  const handleSubmit = (values: any) => {
    console.log('Received values of form: ', values)
    const { fields, setFields } = props
    const newArr: FormTemplateFieldItem[] = fields.map((field, index) => {
      return {
        ...field,
        asset_name: values[`asset_name${field.field_index}`],
        asset_comps: values[`asset_comps${field.field_index}`]
          ? values[`asset_comps${field.field_index}`].split(',')
          : [],
        v_asset_comps: values[`v_asset_comps${field.field_index}`]
          ? values[`v_asset_comps${field.field_index}`].split(',')
          : [],
        status_name: JSON.parse(values[`status_name${field.field_index}`]).asset_status,
        status_value_type: JSON.parse(values[`status_name${field.field_index}`]).status_type,
        label:
          values[`asset_name${field.field_index}`] +
          '  ' +
          JSON.parse(values[`status_name${field.field_index}`]).asset_status,
        field_type: 'single_line_text',
        multiple_choice: ['选项一', '选项二', '选项三'],
        v_asset_comps_regulation: values[`v_asset_comps${field.field_index}`]
          ? values[`v_asset_comps${field.field_index}`].split(',').map((x: string) => {
              return {
                mode: 'none',
                value: '',
                option: [],
              }
            })
          : [],
      } as FormTemplateFieldItem
    })
    setFields(newArr)
    props.setCurrent(2)
  }
  React.useEffect(() => {
    const { fields, setFields } = props
    if (fields.length === 0) {
      setFields([
        {
          uid: '',
          form_template_uid: '',
          field_index: 0,
          field_type: '',
          asset_name: '',
          status_name: '',
          v_asset_name: '轨行位置',
          status_value_type: '',
          default_status_value: '',
          is_confirmed: 0,
          label: '',
          notes: '',
          is_private: 1,
          validation: '',
          allow_other: 1,
          is_use: 1,
          create_time: '',
          update_time: '',
          v_asset_comps: [],
          asset_comps: [],
          multiple_choice: [],
          vasset_comps_regulation: '',
          v_asset_comps_refulation: [],
        },
      ])
    }
  }, [])
  const addField = () => {
    const { fields, setFields } = props
    const newArr = fields.map((x) => x)
    newArr.push({
      uid: '',
      form_template_uid: '',
      field_index: fields[fields.length - 1].field_index + 1,
      field_type: '',
      asset_name: '',
      status_name: '',
      v_asset_name: '几何尺寸',
      status_value_type: '',
      default_status_value: '',
      is_confirmed: 0,
      label: '',
      notes: '',
      is_private: 0,
      validation: '',
      allow_other: 0,
      is_use: 0,
      create_time: '',
      update_time: '',
      v_asset_comps: [],
      asset_comps: [],
      multiple_choice: [],
      vasset_comps_regulation: '',
      v_asset_comps_regulation: [],
    })
    setFields(newArr)
  }
  const deleteField = (fieldIndex: number) => {
    const { fields, setFields } = props
    if (fields.length <= 1) return
    const newArr = fields.map((x) => x)
    newArr.splice(fieldIndex, 1)
    setFields(newArr)
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Form onFinish={handleSubmit} {...formItemLayout} style={{ width: 600 }} form={form}>
        {props.fields.map((field, index) => {
          return (
            <div key={index}>
              <Form.Item wrapperCol={{ xs: 20, sm: 20 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3>字段{index + 1}</h3>
                  {props.fields.length > 1 && (
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        deleteField(index)
                      }}
                      size="small"
                    >
                      删除
                    </Button>
                  )}
                </div>
              </Form.Item>

              <Form.Item
                label="资产名称"
                name={`asset_name${field.field_index}`}
                rules={[{ required: true, message: '请输入资产名称！' }]}
              >
                <Select
                  showSearch
                  placeholder="请输入资产名称"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                    }
                  }}
                  onSearch={async (value) => {
                    if (Utils.isNull(value)) {
                      return
                    }
                    if (value) {
                      const res = await SuggestionRequests.getAssetNameSuggestion(value)
                      if (res.code === 0) {
                        setAssetNameOptions(res.data)
                      } else {
                        message.error('网络错误')
                      }
                    } else {
                      return
                    }
                  }}
                >
                  {assetNameOptions.map((x: string) => {
                    return (
                      <Select.Option value={x} key={`asset_name_option:${x + index}`}>
                        {x}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="资产组件"
                name={`asset_comps${field.field_index}`}
                rules={[{ required: true, message: '请输入资产组件' }]}
              >
                <Select
                  placeholder="请选择资产组件"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      const res = await SuggestionRequests.getAssetCompsSuggestion(
                        form.getFieldValue(`asset_name${field.field_index}`)
                      )
                      if (res.code === 0) {
                        setAssetCompsOptions(
                          res.data.map((comps) => {
                            return comps.toString()
                          })
                        )
                      } else {
                        message.error('网络错误')
                      }
                    }
                  }}
                >
                  {assetCompsOptions.map((x: string) => {
                    return (
                      <Select.Option value={x} key={`asset_comps_option:${x + index}`}>
                        {x}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="地点信息"
                name={`v_asset_comps${field.field_index}`}
                rules={[{ required: true, message: '请输入地点信息！' }]}
              >
                <Select
                  placeholder="请选择地理信息"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      const res = await SuggestionRequests.getAssetVCompsSuggestion({
                        asset_comps: form.getFieldValue(`asset_comps${field.field_index}`)
                          ? form.getFieldValue(`asset_comps${field.field_index}`).split(',')
                          : [],
                        asset_name: form.getFieldValue(`asset_name${field.field_index}`),
                      })
                      if (res.code === 0) {
                        setVAssetQueryOptions(
                          res.data.map((comps) => {
                            return comps.toString()
                          })
                        )
                      }
                    }
                  }}
                  style={{ maxWidth: '50vw' }}
                >
                  {vAssetQueryOptions &&
                    vAssetQueryOptions.map((x: string, _index) => {
                      return (
                        <Select.Option value={x} key={`asset_comps_option:${x}+${index + _index}`}>
                          {x}
                        </Select.Option>
                      )
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label="资产状态名"
                name={`status_name${field.field_index}`}
                rules={[{ required: true, message: '请输入资产状态名！' }]}
              >
                <Select
                  placeholder="请选择资产状态名"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      const res = await SuggestionRequests.getAssetStatusSuggestion({
                        asset_comps: form.getFieldValue(`asset_comps${field.field_index}`)
                          ? form.getFieldValue(`asset_comps${field.field_index}`).split(',')
                          : [],
                        asset_name: form.getFieldValue(`asset_name${field.field_index}`),
                      })
                      if (res.code === 0) {
                        setStatusNameOptions(res.data)
                      } else {
                        message.error('网络错误')
                      }
                    }
                  }}
                >
                  {statusNameOptions.map((x: StatusSuggestionItem, _index) => {
                    return (
                      <Select.Option
                        value={JSON.stringify(x)}
                        key={`status_name_option:${x.asset_status + index + _index}`}
                      >
                        {x.asset_status}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </div>
          )
        })}
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" onClick={addField} style={{ marginRight: 20 }}>
              添加字段
            </Button>
            <Button type="primary" onClick={() => props.setCurrent(0)} style={{ marginRight: 20 }}>
              上一步
            </Button>
            <Button type="primary" htmlType={'submit'}>
              下一步
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CreateTemplateStepTwo
