/// <reference path="type.d.ts" />
/// <reference path="bi.d.ts" />

import request from './_base'

export async function getBIdatas(input: BIReq): Promise<
  AssetsResourceProto<{
    data: BIRes[]
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/api/bi`,
    data: input,
  }) as any
}

export async function createBIconfig(input: CreateBIconfigReq): Promise<
  AssetsResourceProto<{
    data: BIconfigItem
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/charts/new`,
    data: input,
  }) as any
}

export async function getBIconfig(): Promise<
  AssetsResourceProto<{
    data: BIconfigItem
  }>
> {
  return request({
    method: 'GET',
    url: `/bi/charts/get`,
  }) as any
}

export async function deleteChart(input: string): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    url: `/bi/chart/del/${input}`,
    data: input,
  }) as any
}

export async function addChart(input: AddChartReq): Promise<AssetsResourceProto<{ data: ChartItem }>> {
  return request({
    method: 'POST',
    url: `/bi/chart/new`,
    data: input,
  }) as any
}

export async function getChartInfo(input: string): Promise<AssetsResourceProto<{ data: ChartItem }>> {
  return request({
    method: 'GET',
    url: `/bi/chart/get/${input}`,
  }) as any
}

export async function updateChartInfo(input: ChartItem): Promise<AssetsResourceProto<{ data: ChartItem }>> {
  return request({
    method: 'PUT',
    url: `/bi/chart/update`,
    data: input,
  }) as any
}

export async function getBIdatasFlagByVStatus(input: BIReq): Promise<
  AssetsResourceProto<{
    data: BIRes[]
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/asset/data/get`,
    data: input,
  }) as any
}

export async function getBIdatasFlagByTime(input: BIReq): Promise<
  AssetsResourceProto<{
    data: BIRes[]
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/asset/data/time/get`,
    data: input,
  }) as any
}

export async function shareConfig(input: ShareConfigReq): Promise<
  AssetsResourceProto<{
    data: ShareConfigRes
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/config/share`,
    data: input,
  }) as any
}

//获取可导入的biConfig list

export async function getImportableConfigList(input: GetImportableConfigListReq): Promise<
  AssetsResourceProto<{
    data: GetImportableConfigListRes[]
    total: number
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/config/share/get`,
    data: input,
  }) as any
}

export async function importConig(input: { bi_config_share_uid: string; over_write: boolean }): Promise<
  AssetsResourceProto<{
    data: null
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/config/import`,
    data: input,
  }) as any
}

export async function updateLayout(input: { config: string }): Promise<
  AssetsResourceProto<{
    data: any
  }>
> {
  return request({
    method: 'POST',
    url: `/bi/charts/config`,
    data: input,
  }) as any
}

export async function getLayout(): Promise<
  AssetsResourceProto<{
    data: { config: string }
  }>
> {
  return request({
    method: 'GET',
    url: `/bi/charts/config`,
  }) as any
}

export async function getShareLayout({ config_id }: { config_id: string }): Promise<
  AssetsResourceProto<{
    data: { config: string }
  }>
> {
  return request({
    method: 'GET',
    url: `/bi/charts/share/config/${config_id}`,
  }) as any
}
