import * as React from 'react'
import * as FormRequests from '@/requests/form'
import { message, List, Skeleton, Avatar, Tabs, Button, Modal } from 'antd'
import ButtonGroup from 'antd/lib/button/button-group'
import { useHistory, useLocation } from 'react-router-dom'
const { TabPane } = Tabs

const MyUnsubmitedCooperativeForms = () => {
  const history = useHistory()
  const [fetching, setFetching] = React.useState(false)
  const [formDatas, setFormDatas] = React.useState<
    {
      data: FormDataItem | undefined
      loading: boolean
    }[]
  >([])

  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })

  const fetchData = async (page?: number, pageSize?: number) => {
    setFetching(true)
    setFormDatas(
      [...new Array(10)].map(() => ({
        data: undefined,
        loading: true,
      }))
    )
    if (!page) page = 1
    if (!pageSize) pageSize = 10

    const res = await FormRequests.getMyUnsubmitedCooperativeFormList((page - 1) * pageSize, pageSize)

    if (res.code === 0) {
      setPagination({ ...pagination, total: res.total })
      setFormDatas(
        res.data.map((x) => {
          return { data: x, loading: false }
        })
      )
    } else {
      setFormDatas([])
      message.error(res.message)
    }
    setFetching(false)
  }
  const doDeleteFormData = async (form_id: string) => {
    message.loading('正在删除')
    const res = await FormRequests.deleteFormDataById(form_id)
    if (res.code === 0) {
      message.destroy()
      message.success('删除成功')
      fetchData()
    } else {
      message.destroy()
      message.error(res.message)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <div style={{ padding: 20 }}>
      <div>
        <List
          loading={fetching}
          dataSource={formDatas}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              setPagination({ ...pagination, page: page })
              fetchData(page, pagesize)
            },
          }}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <a
                    key="list-loadmore-edit"
                    onClick={(e) => {
                      if (!item.data) return
                      e.preventDefault()
                      history.push({
                        pathname:
                          item.data.form_data.form_tag === 1
                            ? '/workbench/forms-management/form-data-list/submit-formdata'
                            : '/workbench/forms-management/form-data-list/submit-free-formdata',
                        state: {
                          form_id: item.data ? item.data.form_data.form_id : '',
                        },
                        search: `form_id=${item.data ? item.data.form_data.form_id : ''}`,
                      })
                    }}
                  >
                    {item.data ? (item.data.form_pages_data.length === 0 ? '开始填写' : '继续填写') : null}
                  </a>,
                  <a
                    key="list-loadmore-more"
                    onClick={(e) => {
                      e.preventDefault()

                      history.push({
                        pathname: `/workbench/forms-management/form-data-list/formdata-detail`,
                        state: {
                          form_id: item.data ? item.data.form_data.form_id : '',
                        },
                        search: `form_id=${item.data ? item.data.form_data.form_id : ''}`,
                      })
                    }}
                  >
                    详情
                  </a>,
                  <a
                    key="list-loadmore-more"
                    onClick={(e) => {
                      e.preventDefault()
                      Modal.confirm({
                        title: '温馨提示',
                        content: '确认删除该表单吗？',
                        okText: '确认',
                        onOk: () => {
                          if (!item.data) return
                          doDeleteFormData(item.data.form_data.form_id)
                        },
                        cancelText: '取消',
                        onCancel: () => {},
                      })
                    }}
                  >
                    删除
                  </a>,
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item.data ? `/v2/api/user/avatar/${item.data.form_data.creator_avatar}` : ''} />
                    }
                    title={item.data ? item.data.form_data.form_template_name : ''}
                    description={
                      item.data
                        ? `创建者：${item.data.form_data.creator_name}(ID：${item.data.form_data.creator_id})`
                        : ''
                    }
                  />
                  <div>{item.data ? item.data.form_data.submitted_time : null}</div>
                </Skeleton>
              </List.Item>
            )
          }}
        ></List>
      </div>
    </div>
  )
}
const MySubmitedCooperativeForms = () => {
  const history = useHistory()
  const [fetching, setFetching] = React.useState(false)
  const [formDatas, setFormDatas] = React.useState<
    {
      data: FormDataItem | undefined
      loading: boolean
    }[]
  >([])

  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })

  const fetchData = async (page?: number, pageSize?: number) => {
    setFetching(true)
    setFormDatas(
      [...new Array(10)].map(() => ({
        data: undefined,
        loading: true,
      }))
    )
    if (!page) page = 1
    if (!pageSize) pageSize = 10

    const res = await FormRequests.getMySubmitedCooperativeFormList((page - 1) * pageSize, pageSize)

    if (res.code === 0) {
      setPagination({ ...pagination, total: res.total })
      setFormDatas(
        res.data.map((x) => {
          return { data: x, loading: false }
        })
      )
    } else {
      setFormDatas([])
      message.error(res.message)
    }
    setFetching(false)
  }
  const doCancelSubmit = async (form_id: string) => {
    message.loading('撤回中')
    const res = await FormRequests.cancelSubmitFormData(form_id)

    if (res.code === 0) {
      message.destroy()
      message.success('撤回成功')
      fetchData()
    } else {
      message.destroy()
      message.error(res.message)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <div style={{ padding: 20 }}>
      <div>
        <List
          dataSource={formDatas}
          loading={fetching}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              setPagination({ ...pagination, page: page })
              fetchData(page, pagesize)
            },
          }}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <a
                    key="list-loadmore-edit"
                    onClick={(e) => {
                      e.preventDefault()

                      history.push({
                        pathname: `/workbench/forms-management/form-data-list/formdata-detail`,
                        state: {
                          form_id: item.data ? item.data.form_data.form_id : '',
                        },
                        search: `form_id=${item.data ? item.data.form_data.form_id : ''}`,
                      })
                    }}
                  >
                    详情
                  </a>,
                  <a
                    key="list-loadmore-more"
                    onClick={(e) => {
                      e.preventDefault()
                      Modal.confirm({
                        title: '温馨提示',
                        content: '确认撤销该表单下的所有数据提交吗？撤回后不可恢复',
                        okText: '确认',
                        onOk: () => {
                          if (!item.data) return
                          doCancelSubmit(item.data.form_data.form_id)
                        },
                        cancelText: '取消',
                        onCancel: () => {},
                      })
                    }}
                  >
                    撤回提交
                  </a>,
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item.data ? `/v2/api/user/avatar/${item.data.form_data.creator_avatar}` : ''} />
                    }
                    title={item.data ? item.data.form_data.form_template_name : ''}
                    description={
                      item.data
                        ? `创建者：${item.data.form_data.creator_name}(ID：${item.data.form_data.creator_id})`
                        : ''
                    }
                  />
                  <div>{item.data ? item.data.form_data.submitted_time : null}</div>
                </Skeleton>
              </List.Item>
            )
          }}
        ></List>
      </div>
    </div>
  )
}

const MyCooperativeForms = () => {
  const history = useHistory()
  const location = useLocation<any>()
  return (
    <div>
      <Tabs
        activeKey={
          location.search && location.search.split('&').length === 2 ? location.search.split('&')[1].split('=')[1] : '1'
        }
        renderTabBar={(props: any, defaultTabBar: any) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <ButtonGroup>
                {props.panes.map((x: any, _index: number) => {
                  return (
                    <Button
                      type={x.key === props.activeKey ? 'primary' : undefined}
                      key={_index}
                      onClick={() => {
                        let { search } = location
                        if (search) {
                          let arr = search.split('&')
                          if (arr.length === 2) {
                            search = `${arr[0]}&subKey=${x.key}`
                          } else if (arr.length === 1) {
                            search = `${arr[0]}&subKey=${x.key}`
                          } else {
                          }
                        } else {
                          search = `?key=1&subKey=1`
                        }
                        props.onTabClick(x.key)
                        history.push({
                          pathname: '/workbench/forms-management/form-data-list',
                          search: search,
                        })
                      }}
                      style={{ padding: '0 24px' }}
                    >
                      {x.props.tab}
                    </Button>
                  )
                })}
              </ButtonGroup>
            </div>
          )
        }}
      >
        <TabPane tab="未提交的表单" key="1">
          <MyUnsubmitedCooperativeForms />
        </TabPane>
        <TabPane tab="已提交的表单" key="2">
          <MySubmitedCooperativeForms />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default MyCooperativeForms
