import React from 'react';
import RcGantt from 'rc-gantt';
import dayjs from 'dayjs';
import { message, Avatar, Dropdown, Input, Menu, Divider, Button, Tooltip } from 'antd';
import { getTaskList, updateTask, assignTask, getTaskStageList } from '@/requests/project';
import { SearchOutlined } from '@ant-design/icons';
import TaskDetailModal from './TaskDetailModal';
import '../styles/grantt.less';

interface Data {
  name: string;
  startDate: string;
  endDate: string;
  children: Data[];
  id: number;
  collapsed: boolean;
  userInfo: Member;
  done: boolean;
}
interface PropsType {
  project: ProjectItem;
  users: Member[];
}
interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  is_join: number;
}

const GranttWidget = (props: PropsType) => {
  const { project, users } = props;
  const [loading, setLoading] = React.useState(false);
  const [tasks, setTasks] = React.useState<ProjectTaskItem[]>([]);
  const [stages, setStages] = React.useState<TaskStagesItem[]>([]);
  const [data, setData] = React.useState<Data[]>([]);

  const [editingTask, setEditingTask] = React.useState<ProjectTaskItem>();
  const [editTaskVisible, setEditTaskVisible] = React.useState(false);
  const [editingTaskParentStage, setEditingTaskParentStage] = React.useState<TaskStagesItem>();

  const doUpdateTask = async (input: UpdateTaskReq) => {
    const res = await updateTask({
      ...input,
    });
    if (res.code === 0) {
      fetchData();
      message.success('保存成功');
    } else {
      message.error('网络错误');
    }
  };

  //获取任务分组列表
  const fetchStages = async () => {
    if (!project) return;
    const { id } = project;
    const res = await getTaskStageList({
      limit: 10000,
      skip: 0,
      project_id: id,
    });
    if (res.code === 0) {
      setStages(res.data);
    } else {
      message.error('读取任务列表失败');
    }
  };

  const doAssignTask = async (input: any) => {
    const res = await assignTask({
      ...input,
    });

    if (res.code === 0) {
      message.success('保存成功');
      fetchData();
    } else {
      message.error('网络错误');
    }
  };
  const fetchData = async (id?: number, task_name?: string) => {
    if (!project) return;
    if (!id) {
      id = project.id;
    }
    setLoading(true);
    const res = await getTaskList({
      project_id: id,
      task_name: task_name ? task_name : '',
      user_type: 1,
      limit: 10000,
      skip: 0,
    });
    if (res.code === 0) {
      setTasks(res.data);
      setData(convertTaskListToTree(res.data));
      // setTasks(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  // 将任务列表转换为树形结构
  const convertTaskListToTree = (taskList: ProjectTaskItem[]) => {
    const len = taskList.length;
    function loop(parent_id: number): Data[] {
      let res: Data[] = [];
      for (let i = 0; i < len; i++) {
        let item = taskList[i];
        if (item.parent_task_id === parent_id) {
          let userInfo: Member = users.find((user) => user.user_id === item.assign_to) as Member;
          res.push({
            name: item.task_name,
            startDate: item.begin_time ? dayjs(item.begin_time).format('YYYY-MM-DD') : ('未设置' as any),
            endDate: item.end_time ? dayjs(item.end_time).format('YYYY-MM-DD') : ('未设置' as any),
            children: loop(item.id),
            id: item.id,
            userInfo,
            collapsed: true,
            done: item.done,
          });
        }
      }
      return res;
    }
    return loop(0);
  };
  //打开任务详情弹窗
  const openTaskDetailModal = (record: Data) => {
    const _task = tasks.find((x) => x.id === record.id);
    const _stage = stages.find((x) => x.id === _task?.task_stage_id);
    setEditTaskVisible(true);
    setEditingTask(_task);
    setEditingTaskParentStage(_stage);
  };

  React.useEffect(() => {
    fetchData();
    fetchStages();
  }, []);

  return (
    <div className="grantt-container">
      <RcGantt<Data>
        data={data}
        onExpand={(record) => {}}
        columns={[
          {
            name: 'name',
            label: '名称',
            width: 200,
            render: (record: any) => (
              <div
                style={{ color: record.done ? 'grey' : '', paddingLeft: 16, width: '100%' }}
                onDoubleClick={() => {
                  openTaskDetailModal(record);
                }}
              >
                {record.name}
              </div>
            ),
          },
          {
            name: 'startDate',
            label: '开始时间',
            render: (record: any) => (
              <span
                onDoubleClick={() => {
                  openTaskDetailModal(record);
                }}
              >
                {record.startDate}
              </span>
            ),
          },
          {
            name: 'endDate',
            label: '结束时间',
            render: (record: any) => (
              <span
                onDoubleClick={() => {
                  openTaskDetailModal(record);
                }}
              >
                {record.endDate}
              </span>
            ),
          },
          {
            name: 'user',
            label: '执行人',
            render: (record: any) => (
              <span>
                {users.length > 0 && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <div style={{ padding: 12 }}>
                          <Input prefix={<SearchOutlined />} style={{ height: 40 }} placeholder="搜索" />
                        </div>
                        <div style={{ height: 300, overflow: 'scroll' }}>
                          <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>执行者</span>

                          <div style={{ width: 250, padding: 12 }}>
                            <span>
                              <Avatar
                                style={{ marginRight: 8 }}
                                src={`v2/api/user/avatar/${record.userInfo.avatar}`}
                              ></Avatar>
                              {record.userInfo.user_name}
                            </span>
                          </div>
                          <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>其他参与者</span>
                          <div>
                            {users
                              .filter((x) => x.user_id !== record.userInfo.user_id)
                              .map((user) => {
                                return (
                                  <div
                                    key={user.user_id}
                                    style={{ width: 250, padding: 12, cursor: 'pointer' }}
                                    onClick={() =>
                                      doAssignTask({
                                        id: record.id,
                                        user_id: user.user_id,
                                      })
                                    }
                                    className="user-item"
                                  >
                                    <Avatar
                                      style={{ marginRight: 8 }}
                                      src={`v2/api/user/avatar/${user.avatar}`}
                                    ></Avatar>
                                    {user.user_name}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <Divider style={{ margin: 0 }}></Divider>
                        <div
                          style={{
                            height: 60,
                            display: 'flex',
                            padding: 12,
                          }}
                        >
                          <Button type="primary" style={{ width: '100%', borderRadius: 5, height: 40 }}>
                            邀请新成员
                          </Button>
                        </div>
                      </Menu>
                    }
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <Tooltip title="点击设置执行者">
                      <span style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}>
                        <Avatar
                          style={{ marginRight: 8 }}
                          size={'small'}
                          src={`v2/api/user/avatar/${record.userInfo.avatar}`}
                        ></Avatar>
                        {record.userInfo.user_name}
                      </span>
                    </Tooltip>
                  </Dropdown>
                )}
              </span>
            ),
          },
        ]}
        tableIndent={0}
        onUpdate={async (record, start, end) => {
          doUpdateTask({
            id: record.id,
            begin_time: dayjs(start).format('YYYY-MM-DD HH:mm:ss'),
            end_time: dayjs(end).format('YYYY-MM-DD HH:mm:ss'),
          });
          return true;
        }}
      />
      {editingTask && editingTaskParentStage && editTaskVisible && (
        <TaskDetailModal
          visible={editTaskVisible}
          setVisible={setEditTaskVisible}
          _task={editingTask}
          stage={editingTaskParentStage}
          project={project}
        />
      )}
    </div>
  );
};

export default GranttWidget;
