import * as React from 'react';
import * as AssetsRequest from '@/requests/assets';
import { message, Button, Modal, Tag, Table } from 'antd';
import { download } from '@/utils/string';
import './index.less';

const UploadCenter = () => {
  const [taskList, setTaskList] = React.useState<TaskItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const doFetch = async (page?: number, pageSize?: number) => {
    setFetching(true);
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await AssetsRequest.getTaskList(pageSize, page);
    if (res.code === 0) {
      setTaskList(res.data);
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    doFetch();
  }, []);

  const formatTableData = (data: TaskItem[]) => {
    const list = data.map((x: any) => {
      x.key = x.uploadId;
      return x;
    });
    return list;
  };
  enum Status {
    '解析中' = 0,
    '解析成功' = 1,
    '解析失败' = 2,
    '部分成功' = 3,
    '回滚成功' = -999,
  }

  enum Purpose {
    '创建资产' = 0,
    '更新资产' = 1,
    '删除资产' = 3,
    '创建资产状态' = 4,
    '更新资产状态' = 2,
    '创建资产标签' = 45,
    '更新资产标签' = 46,
    '删除资产标签' = 47,
    '创建地理坐标' = 48,
    '更新地理坐标' = 49,
    '删除地理坐标' = 50,
    '创建地理信息' = 41,
    '更新地理信息' = 42,
    '删除地理信息' = 43,
    '创建缺陷' = 51,
    '更新缺陷' = 52,
    '删除缺陷' = 53,
    '创建资产模板' = 54,
    '更新资产模板' = 55,
    '删除资产模板' = 56,
    '未知' = 60,
  }

  const columns = [
    {
      title: '文件名',
      dataIndex: 'file_name',
      key: 'fileName',
      align: 'center' as 'center',
    },
    {
      title: '上传时间',
      dataIndex: 'create_time',
      key: 'createTime',
      align: 'center' as 'center',
    },

    {
      title: '接口类型',
      dataIndex: 'purpose',
      key: 'purpose',
      align: 'center' as 'center',
      render: (purpose: number, record: TaskItem) => {
        return Purpose[purpose];
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as 'center',
      render: (status: number, record: TaskItem) => {
        if (9 < record.purpose && 15 > record.purpose) {
          return <Tag color="#2db7f5">回滚中</Tag>;
        }
        let color = '';
        if (status === 0) {
          color = '#FFDC35';
        }
        if (status === 1) {
          color = '#87d068';
        }
        if (status === 2) {
          color = '#f50';
        }
        if (status === 3) {
          color = '#0051ff';
        }
        if (status === -999) {
          color = '#ff00f2';
        }
        return <Tag color={color}>{Status[status]}</Tag>;
      },
    },
    {
      title: '解析结果',
      dataIndex: 'parse_result',
      key: 'parse_result',
      align: 'center' as 'center',
      render: (purpose: number, record: TaskItem) => {
        return record.parse_result;
      },
    },
    {
      title: '操作',
      dataIndex: 'purpose',
      key: 'purpose',
      align: 'center' as 'center',
      render: (purpose: number, record: TaskItem) => {
        return (
          <div>
            {(record.status === 0 || record.status === 1 || record.status === 3) && (
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    onOk: async () => {
                      const res = await AssetsRequest.uploadBack({
                        upload_id: record.upload_id,
                      });
                      if (res.code === 0) {
                        message.success('回滚任务提交成功！');
                        doFetch();
                      } else if (res.code === 10034) {
                        message.error('文件已超过可回滚时间');
                      } else {
                        message.error(res.message);
                      }
                    },
                    okText: '确认',
                    cancelText: '取消',
                    title: '提示',
                    content: '确认回滚该任务吗？',
                  });
                }}
              >
                回滚
              </Button>
            )}
            {9 < purpose && 15 > purpose && (
              <Button
                size="small"
                onClick={() => {
                  Modal.confirm({
                    onOk: async () => {
                      const res = await AssetsRequest.cancelUploadBack({
                        upload_id: record.upload_id,
                      });
                      if (res.code === 0) {
                        message.success('撤销回滚任务成功！');
                        doFetch();
                      } else {
                        message.error(res.message);
                      }
                    },
                    okText: '确认',
                    cancelText: '取消',
                    title: '提示',
                    content: '确认撤销该回滚任务吗？',
                  });
                }}
              >
                撤销回滚
              </Button>
            )}
            {(record.status === 2 || record.status === 3) && (
              <Button
                size="small"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  Modal.confirm({
                    onOk: async () => {
                      await download(
                        `/v2/api/file/download/csv/assettask/${record.upload_id}`,
                        `${record.upload_id}_error.csv`
                      );
                    },
                    okText: '确认',
                    cancelText: '取消',
                    title: '提示',
                    content: '确认下载错误文件吗？',
                  });
                }}
              >
                下载错误信息
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={formatTableData(taskList)}
      size="small"
      className="upload-center"
      columns={columns}
      loading={fetching}
      style={{ minWidth: 700, minHeight: 400 }}
      pagination={{
        ...pagination,
        onChange: (page, pageSize) => {
          doFetch(page, pageSize);
        },
        showTotal: (total) => <span>共{total}条数据</span>,
      }}
    />
  );
};

export default UploadCenter;
