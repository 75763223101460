import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import * as formRequests from '@/requests/form'

import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Switch,
  Select,
  InputNumber,
  DatePicker,
  Checkbox,
  message,
  Divider,
  PageHeader,
  Spin,
  Skeleton,
  Descriptions,
  Modal,
} from 'antd'
import { MailOutlined, MinusCircleOutlined, PhoneOutlined, ShareAltOutlined, SaveOutlined } from '@ant-design/icons'

const FormTemplateDetail = () => {
  const [template, setTemplate] = React.useState<FormTemplateItem | null>(null)

  const [fetching, setFetching] = React.useState(false)
  const [editingField, setEditingField] = React.useState(0)
  const history = useHistory()
  const location = useLocation<any>()
  const vcompsRule = [
    { label: '与初设相同', value: 'equal' },
    { label: '循环', value: 'recircle' },
    { label: '等差', value: 'arithmetic' },
    { label: '无', value: ' ' },
  ]
  const fieldTypeOptions = [
    { label: '单行文字', value: 'single_line_text', icon: 'pic-center' },
    { label: '多行文字', value: 'paragraph_text', icon: 'align-left' },
    { label: '单项选择', value: 'single_choice', icon: 'unordered-list' },
    { label: '日期', value: 'date', icon: 'calendar' },
  ]
  const getCheckBoxOptions = (field_index: number) => {
    if (!template) return
    if (!template.fields[field_index].multiple_choice) {
      return [
        { label: '选项一', value: 'Apple' },
        { label: '选项二', value: 'Pear' },
        { label: '选项三', value: 'Orange' },
      ]
    }
    return template.fields[field_index].multiple_choice.map((x) => {
      return {
        label: x,
        value: x,
      }
    })
  }

  const fetchData = async () => {
    let uid = ''
    // uid = location.state ? location.state.uid : location.search.split('=')[1]
    uid = location.search.split('=')[1]
    const res = await formRequests.queryPersonalTemplate({
      uid,
    })
    if (res.code === 0) {
      setTemplate(res.data)
    } else {
      message.error(res.code)
    }
    setFetching(false)
  }
  React.useEffect(() => {
    setFetching(true)
    fetchData()
  }, [])
  return template !== null ? (
    <div style={{ width: '100%', backgroundColor: '#ffffff' }}>
      <PageHeader
        title={template.form_name}
        ghost={true}
        style={{ padding: 16 }}
        extra={[
          <Button
            key="share"
            icon={<ShareAltOutlined />}
            onClick={() => {
              Modal.confirm({
                okText: '确认',
                cancelText: '取消',
                title: '提示',
                content: '确认将此模板分享至公共模板？',
                onOk: async () => {
                  if (!template) return
                  const res = await formRequests.sharePersonalTemplate({
                    uid: template.uid,
                  })
                  if (res.code === 0) {
                    message.success('分享成功，可在分享模板中查看！')
                  } else {
                    message.error(res.message)
                  }
                },
              })
            }}
          >
            分享
          </Button>,
          <Button
            key="save"
            icon={<SaveOutlined />}
            type="primary"
            onClick={async () => {
              const res = await formRequests.updatePersonalTemplate(template)
              if (res.code === 0) {
                message.success('保存成功')
                fetchData()
              } else {
                message.error(res.message)
              }
            }}
          >
            保存
          </Button>,
        ]}
        onBack={() => history.goBack()}
      ></PageHeader>
      <Row style={{ width: '100%', padding: '16px 0 16px 16px' }} justify="center" gutter={16}>
        <Col span={6}>
          <div style={{ width: '100%', padding: 16, height: '100%', backgroundColor: '#F9F9FB' }}>
            <h4
              style={{
                marginBottom: 20,
                color: 'rgba(0,0,0,0.85)',
                fontWeight: 'bold',
                fontSize: 16,
                lineHeight: 1.5,
              }}
            >
              <div className="title-prefix"></div>
              变化规则
            </h4>
            {template.fields[editingField].v_asset_comps.map((comp, comp_index) => {
              return (
                <div>
                  <Divider orientation="left">{comp}</Divider>
                  <Form>
                    <Form.Item label="变化规则">
                      <Radio.Group
                        options={vcompsRule}
                        value={template.fields[editingField].v_asset_comps_regulation[comp_index].mode}
                        onChange={(e) => {
                          let newArr = template.fields.map((x) => x)
                          newArr[editingField].v_asset_comps_regulation[comp_index].mode = e.target.value
                            ? e.target.value
                            : ''
                          setTemplate({
                            ...template,
                            fields: newArr,
                          })
                        }}
                      ></Radio.Group>
                    </Form.Item>
                    {template.fields[editingField].v_asset_comps_regulation[comp_index].mode === 'arithmetic' && (
                      <Form layout="horizontal">
                        <Form.Item label="公差">
                          <InputNumber
                            defaultValue={0}
                            onChange={(value) => {
                              let newArr = template.fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].value = value
                                ? value.toString()
                                : ''
                              setTemplate({
                                ...template,
                                fields: newArr,
                              })
                            }}
                            value={Number(template.fields[editingField].v_asset_comps_regulation[comp_index].value)}
                          />
                        </Form.Item>
                        <Form.Item label="最小值">
                          <InputNumber
                            defaultValue={0}
                            onChange={(value) => {
                              let newArr = template.fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].option[0] = value
                                ? value.toString()
                                : ''
                              setTemplate({
                                ...template,
                                fields: newArr,
                              })
                            }}
                            value={
                              template.fields[editingField].v_asset_comps_regulation[comp_index].option.length > 0
                                ? Number(template.fields[editingField].v_asset_comps_regulation[comp_index].option[0])
                                : 0
                            }
                          />
                        </Form.Item>
                        <Form.Item label="最大值">
                          <InputNumber
                            defaultValue={0}
                            onChange={(value) => {
                              let newArr = template.fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].option[1] = value
                                ? value.toString()
                                : ''
                              setTemplate({
                                ...template,
                                fields: newArr,
                              })
                            }}
                            value={
                              template.fields[editingField].v_asset_comps_regulation[comp_index].option.length > 0
                                ? Number(template.fields[editingField].v_asset_comps_regulation[comp_index].option[1])
                                : 0
                            }
                          />
                        </Form.Item>
                      </Form>
                    )}
                    {template.fields[editingField].v_asset_comps_regulation[comp_index].mode === 'recircle' && (
                      <Form layout="horizontal">
                        <Form.Item label="选项">
                          <Select
                            mode="tags"
                            onChange={(value: string[]) => {
                              let newArr = template.fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].option = value
                              setTemplate({
                                ...template,
                                fields: newArr,
                              })
                            }}
                            placeholder="输入循环选项"
                            value={template.fields[editingField].v_asset_comps_regulation[comp_index].option}
                          ></Select>
                        </Form.Item>
                      </Form>
                    )}
                    {template.fields[editingField].v_asset_comps_regulation[comp_index].mode === 'equal' && (
                      <Form layout="horizontal">
                        <Form.Item label="初始值">
                          <Input
                            placeholder="请输入初始值"
                            value={template.fields[editingField].v_asset_comps_regulation[comp_index].value}
                            onChange={(e) => {
                              let newArr = template.fields.map((x) => x)
                              newArr[editingField].v_asset_comps_regulation[comp_index].value = e.target.value
                              setTemplate({
                                ...template,
                                fields: newArr,
                              })
                            }}
                          />
                        </Form.Item>
                      </Form>
                    )}
                  </Form>
                </div>
              )
            })}
          </div>
        </Col>

        <Col span={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
              height: '100%',
              padding: 16,
              backgroundColor: '#F9F9FB',
            }}
          >
            <div style={{ width: '100%' }}>
              <Descriptions
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1 }}
                title={
                  <span>
                    <div className="title-prefix"></div>基本信息
                  </span>
                }
              >
                <Descriptions.Item label="表单名称">{template.form_name}</Descriptions.Item>
                <Descriptions.Item label="描述">{template.description}</Descriptions.Item>
                <Descriptions.Item label="表单id">{template.form_template_id}</Descriptions.Item>
                <Descriptions.Item label="版本号">{template.version_name}</Descriptions.Item>
                <Descriptions.Item label="创建时间">{template.create_time}</Descriptions.Item>
                <Descriptions.Item label="修改时间">{template.update_time}</Descriptions.Item>
              </Descriptions>
              <Divider />
              {template.fields.map((field, field_index) => {
                const fieldType = field.field_type
                return (
                  <div
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      flexDirection: 'column',
                      justifyContent: 'center',

                      width: '100%',
                      border: editingField === field_index ? '1px dashed #1890ff' : '1px dashed #ffffff',
                      minHeight: 100,
                      marginBottom: 10,
                      padding: 20,
                      backgroundColor: editingField === field_index ? '#FCFCFC' : '#ffffff',
                    }}
                    key={field_index}
                    onClick={() => setEditingField(field_index)}
                  >
                    <h4>{field.label}</h4>
                    <p style={{ color: '#9D9D9D', fontSize: 13 }}>{field.asset_comps.toString().replace(/,/g, '-')}</p>
                    {fieldType === 'single_line_text' && (
                      <Input disabled style={{ width: 400 }} placeholder="单行文本" />
                    )}
                    {fieldType === 'paragraph_text' && (
                      <Input.TextArea disabled style={{ width: 400 }} placeholder="多行文本" rows={3} />
                    )}

                    {fieldType === 'phone' && (
                      <Input
                        disabled
                        style={{ width: 400 }}
                        placeholder="电话"
                        prefix={<PhoneOutlined type="phone" />}
                      />
                    )}
                    {fieldType === 'email' && (
                      <Input disabled style={{ width: 400 }} placeholder="email" prefix={<MailOutlined />} />
                    )}
                    {fieldType === 'number' && <InputNumber disabled style={{ width: 400 }} placeholder="数字" />}
                    {fieldType === 'date' && <DatePicker disabled style={{ width: 400 }} />}
                    {fieldType === 'drop_down' && <Select disabled style={{ width: 400 }} placeholder="下拉框" />}
                    {fieldType === 'multiple_choice' && (
                      <Checkbox.Group options={getCheckBoxOptions(field_index)} disabled />
                    )}
                    {fieldType === 'single_choice' && (
                      <Radio.Group options={getCheckBoxOptions(field_index)} disabled />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ width: '100%', padding: 16, backgroundColor: '#F9F9FB' }}>
            <h4
              style={{
                marginBottom: 20,
                color: 'rgba(0,0,0,0.85)',
                fontWeight: 'bold',
                fontSize: 16,
                lineHeight: 1.5,
              }}
            >
              <div className="title-prefix"></div>
              编辑字段
            </h4>
            <Form layout="vertical" style={{ width: '100%' }}>
              <Form.Item label="字段类型">
                <Select
                  value={template.fields[editingField].field_type}
                  onChange={(value: string) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].field_type = value
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                >
                  {fieldTypeOptions.map((option) => {
                    return (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="标题">
                <Input
                  value={template.fields[editingField].label}
                  onChange={(e) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].label = e.target.value
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item label="默认值">
                <Input
                  value={template.fields[editingField].default_status_value}
                  onChange={(e) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].default_status_value = e.target.value
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item label="注意事项">
                <Input
                  defaultValue="无"
                  value={template.fields[editingField].notes}
                  onChange={(e) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].notes = e.target.value
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item label="值类型">
                <Select
                  defaultValue="数值"
                  value={template.fields[editingField].status_value_type}
                  onChange={(value: string) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].status_value_type = value
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                >
                  <Select.Option value="数值">数值</Select.Option>
                  <Select.Option value="文本">文本</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="是否公开">
                <Switch
                  defaultChecked={true}
                  checked={Boolean(template.fields[editingField].is_private)}
                  onChange={(checked: boolean) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].is_private = Number(checked)
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item label="是否允许协作">
                <Switch
                  defaultChecked={true}
                  checked={Boolean(template.fields[editingField].allow_other)}
                  onChange={(checked: boolean) => {
                    let newArr = template.fields.map((x) => x)
                    newArr[editingField].allow_other = Number(checked)
                    setTemplate({
                      ...template,
                      fields: newArr,
                    })
                  }}
                />
              </Form.Item>
              {(template.fields[editingField].field_type === 'single_choice' ||
                template.fields[editingField].field_type === 'multiple_choice' ||
                template.fields[editingField].field_type === 'drop_down') && (
                <Form.Item label="选项">
                  {template.fields[editingField].multiple_choice.map((x, option_index) => {
                    return (
                      <div
                        key={option_index}
                        style={{
                          display: 'flex',
                          marginBottom: 10,
                          alignItems: 'center',
                        }}
                      >
                        <Input
                          value={template.fields[editingField].multiple_choice[option_index]}
                          onChange={(e) => {
                            let newArr = template.fields.map((x) => x)
                            newArr[editingField].multiple_choice[option_index] = e.target.value
                            setTemplate({
                              ...template,
                              fields: newArr,
                            })
                          }}
                        />
                        <MinusCircleOutlined
                          style={{
                            cursor: 'pointer',
                            fontSize: 15,
                            padding: 5,
                          }}
                          onClick={() => {
                            let newArr = template.fields.map((x) => x)
                            if (newArr[editingField].multiple_choice.length <= 2) {
                              message.info('选项数量不能小于2')
                              return
                            }
                            newArr[editingField].multiple_choice.splice(option_index, 1)
                            setTemplate({
                              ...template,
                              fields: newArr,
                            })
                          }}
                        />
                      </div>
                    )
                  })}
                  <Button
                    size="small"
                    onClick={() => {
                      let newArr = template.fields.map((x) => x)
                      newArr[editingField].multiple_choice.push('')
                      setTemplate({
                        ...template,
                        fields: newArr,
                      })
                    }}
                  >
                    添加
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <>
      <Skeleton></Skeleton>
      <Spin style={{ position: 'absolute', right: '50%', top: '50%' }} spinning={fetching}></Spin>
    </>
  )
}

export default FormTemplateDetail
