import * as React from 'react'
import { Button, Input, Form, Divider, Select, InputNumber, DatePicker, message } from 'antd'
import * as BIRequests from '@/requests/bi'
import * as SuggestionRequests from '@/requests/suggestion'
import { DataSeriesItem, ChartConfigItem } from './AddChartFormModal'
import * as strUtils from '@/utils/string'

const DataSeriesItemForm = ({
  data,
  index,
  dataSeries,
  SetDataSeries,
  setFeching,
  chartConfigs,
}: {
  data: DataSeriesItem
  index: number
  dataSeries: DataSeriesItem[]
  SetDataSeries: Function
  setFeching: Function
  chartConfigs: ChartConfigItem
}) => {
  const [formData, setFormData] = React.useState<DataSeriesItem>(data)
  const [statusNameOptions, setStatusNameOptions] = React.useState<string[]>([])
  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([])
  const [vAssetFlagOptions, setVAssetFlagOptions] = React.useState<string[]>([])
  const [dataType, setDataType] = React.useState(0)
  const subFormItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  }
  const [selectedVAssetCopms, setSelectedVAssetCopms] = React.useState<string[]>()
  const [flagMin, setFlagMin] = React.useState('')
  const [selectedFlag, setSelectedFlag] = React.useState<string>('起始里程')
  React.useEffect(() => {
    if (chartConfigs.type === '0' || chartConfigs.type === '2') {
      setDataType(1)
    } else {
      setDataType(0)
    }
  }, [])
  return (
    <div id={`data_series-${index}`} style={{ width: '100%' }}>
      <Form {...subFormItemLayout} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Form.Item label="资产名称" style={{ width: '50%' }}>
          <Select
            showSearch
            placeholder="请输入资产名称"
            onDropdownVisibleChange={async (open) => {
              if (open) {
              }
            }}
            onSearch={async (value) => {
              if (strUtils.isNull(value)) {
                return
              }
              if (value) {
                const res = await SuggestionRequests.getAssetNameSuggestion(value)
                if (res.code === 0) {
                  setAssetNameOptions(res.data)
                } else {
                  message.error('网络错误')
                }
              } else {
                return
              }
            }}
            onChange={(value: string) =>
              setFormData({
                ...formData,
                postData: { ...formData.postData, asset_name: value },
              })
            }
          >
            {assetNameOptions.map((x: string) => {
              return (
                <Select.Option value={x} key={`asset_name_option:${x}`}>
                  {x}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item label="资产组件" style={{ width: '50%' }}>
          <Select
            placeholder="请选择资产组件"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await SuggestionRequests.getAssetCompsSuggestion(formData.postData.asset_name)
                if (res.code === 0) {
                  setAssetCompsOptions(
                    res.data.map((comps) => {
                      return comps.toString()
                    })
                  )
                } else {
                  message.error('网络错误')
                }
              }
            }}
            onChange={(option: string[]) =>
              setFormData({
                ...formData,
                postData: {
                  ...formData.postData,
                  asset_comps: option.toString(),
                },
              })
            }
          >
            {assetCompsOptions.map((x: string) => {
              return (
                <Select.Option value={x} key={`asset_comps_option:${x}`}>
                  {x}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item label="资产状态名" style={{ width: '50%' }}>
          <Select
            placeholder="请选择资产状态名"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await SuggestionRequests.getAssetStatusSuggestion({
                  asset_name: formData.postData.asset_name,
                  asset_comps: formData.postData.asset_comps.split(','),
                })
                if (res.code === 0) {
                  setStatusNameOptions(
                    res.data.map((x: StatusSuggestionItem) => {
                      return `${x.asset_status}`
                    })
                  )
                } else {
                  message.error('网络错误')
                }
              }
            }}
            onChange={(value: string) =>
              setFormData({
                ...formData,
                postData: { ...formData.postData, asset_status: value },
              })
            }
            defaultActiveFirstOption
          >
            {statusNameOptions.map((x: string) => {
              return (
                <Select.Option value={x} key={`status_name_option:${x}`}>
                  {x}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item label="地理信息" style={{ width: '50%' }}>
          <Select
            placeholder="请选择地理信息"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await SuggestionRequests.getAssetVCompsSuggestion({
                  asset_comps: formData.postData.asset_comps.split(','),
                  asset_name: formData.postData.asset_name,
                })
                if (res.code === 0) {
                  setVAssetQueryOptions(
                    res.data.map((comps) => {
                      return comps.toString()
                    })
                  )
                }
              }
            }}
            style={{ maxWidth: '50vw' }}
            onChange={(options: any) => {
              let neededVstatus: string[] = []
              let flags: string[] = []
              if (chartConfigs.type === '1' || chartConfigs.type === '3') {
                neededVstatus = options.split(',').filter((option: string) => {
                  const a = option.search('里程')
                  const b = option.search('轨枕号')
                  return a == -1 && b == -1
                })
                flags = options.split(',').filter((option: string) => {
                  const a = option.search('里程')
                  const b = option.search('轨枕号')
                  return a !== -1 || b !== -1
                })
              } else {
                neededVstatus = options.split(',')
              }

              if (neededVstatus.length > 0) {
                setSelectedVAssetCopms(neededVstatus)
                setVAssetFlagOptions(flags)
                setFormData({
                  ...formData,
                  postData: {
                    ...formData.postData,
                    v_asset_query: neededVstatus.map((x: string) => {
                      return {
                        v_status_name: x,
                        v_status_value: '',
                      }
                    }),
                  },
                })
              } else {
                return
              }
            }}
          >
            {vAssetQueryOptions &&
              vAssetQueryOptions.map((x: string, index) => {
                return (
                  <Select.Option value={x} key={`asset_comps_option:${x}+${index}`}>
                    {x}
                  </Select.Option>
                )
              })}
          </Select>
        </Form.Item>
        {selectedVAssetCopms &&
          selectedVAssetCopms.length > 0 &&
          selectedVAssetCopms.map((x) => {
            return (
              <Form.Item label={x} key={`selectedVAssetCopms:${x}`} style={{ width: '50%' }}>
                <Input
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      postData: {
                        ...formData.postData,
                        v_asset_query: formData.postData.v_asset_query.map((item) => {
                          if (item.v_status_name === x) {
                            item.v_status_value = e.target.value
                          }
                          return item
                        }),
                      },
                    })
                  }
                />
              </Form.Item>
            )
          })}
        {dataType === 0 && (
          <Form.Item label="筛选值" style={{ width: '50%' }}>
            <Select
              onChange={(option: any) => {
                setSelectedFlag(option)
                setFormData({
                  ...formData,
                  postData: {
                    ...formData.postData,
                    v_asset_flag: {
                      ...formData.postData.v_asset_flag,
                      v_status_name: option,
                    } as QueryItem,
                  },
                })
              }}
            >
              {vAssetFlagOptions.map((x: string) => {
                return (
                  <Select.Option value={x} key={`v_asset_flag_optuon:${x}`}>
                    {x}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )}
        {dataType === 0 && (
          <Form.Item label="筛选值范围" style={{ width: '50%' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <InputNumber
                onChange={(value) => {
                  setFlagMin(`${value}`)
                }}
                style={{ width: '45%' }}
              />
              <span>~</span>
              <InputNumber
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    postData: {
                      ...formData.postData,
                      v_asset_flag: {
                        ...formData.postData.v_asset_flag,
                        v_status_value: `${flagMin},${value}`,
                      } as QueryItem,
                    },
                  })
                }}
                style={{ width: '45%' }}
              />
            </div>
          </Form.Item>
        )}

        <Form.Item label="时间范围" style={{ width: '50%' }}>
          <DatePicker.RangePicker
            onChange={(dates, dateStrings) => {
              setFormData({
                ...formData,
                postData: {
                  ...formData.postData,
                  start_time: dateStrings[0] + ' 00:00:00',
                  end_time: dateStrings[1] + ' 00:00:00',
                },
              })
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>

      <div
        style={{
          display: 'inline',
          float: 'right',
          marginBottom: 5,
        }}
      >
        <span
          style={{ color: '#a3b5c6', cursor: 'pointer' }}
          onClick={async () => {
            setFeching(true)
            const res =
              dataType === 0
                ? await BIRequests.getBIdatasFlagByVStatus(formData.postData)
                : await BIRequests.getBIdatasFlagByTime(formData.postData)
            if (res.code === 0) {
              SetDataSeries(
                dataSeries.map((item, index) => {
                  if (item.id === data.id) {
                    return {
                      ...item,
                      postData: formData.postData,
                      res: res.data,
                    }
                  } else {
                    return item
                  }
                })
              )
            } else {
              message.error(res.message)
            }

            setFeching(false)
          }}
        >
          数据预览
        </span>
        <Divider type="vertical"></Divider>
        <span
          style={{ color: '#a3b5c6', cursor: 'pointer' }}
          onClick={() => {
            let data = dataSeries.map((x) => x)
            data.splice(index, 1)
            SetDataSeries(data)
          }}
        >
          删除
        </span>
      </div>
    </div>
  )
}

export default DataSeriesItemForm
