import * as React from 'react';
import {
  getMyTodoLabelTaskList,
  getTasksByCondition,
  getAllLabelProjectList,
  updateLabelTask,
  deleteLabelTask,
  downloadLabelResult,
} from '@/requests/labelProject';
import {
  message,
  Table,
  Image,
  Divider,
  Tree,
  Tooltip,
  Input,
  Form,
  Button,
  Select,
  Avatar,
  DatePicker,
  Checkbox,
  Modal,
  InputNumber,
} from 'antd';
import '../styles/labelTask.less';
import { useHistory, useLocation } from 'react-router-dom';
import { FolderOutlined, EditOutlined } from '@ant-design/icons';
import { getAllUsers } from '@/requests/user';
import moment from 'moment';
import SetLabelPersonModal from '../components/LabelPersonModal';

const LabelTaskPage = () => {
  const [data, setData] = React.useState<LabelTaskItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [todos, setTodos] = React.useState<PersonalLabelTasks>();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const history = useHistory();
  const [projectOptions, setProjectOptions] = React.useState<LabelProjectListItem[]>([]);
  const [filterProject, setFilterProject] = React.useState<string>();
  const [userOptions, setUserOptions] = React.useState<UserInfoItem[]>([]);
  const [filterCreator, setFilterCreator] = React.useState<number[]>([]);
  const [filterParter, setFilterParter] = React.useState<number[]>([]);
  const [filterStatus, setFilterStatus] = React.useState(0);
  const [filterCreateTime, setFilterCreateTime] = React.useState<any>();
  const [filterLabelTime, setFilterLabelTime] = React.useState<any>();
  const [filterName, setFilterName] = React.useState('');
  const [filterLabelNum, setFilterLabelNum] = React.useState<number[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [setLabelPersonVisible, setSetLabelPersonVisible] = React.useState(false);
  const [editTask, setEditTask] = React.useState<LabelTaskItem>();

  const doSearch = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getTasksByCondition({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterProject && {
        project_uid: filterProject,
      }),
      ...(filterCreator.length > 0 && {
        create_user_ids: filterCreator,
      }),
      ...(filterParter.length > 0 && {
        principal_user_ids: filterParter,
      }),
      ...(filterStatus !== 0 && {
        label: filterStatus === 1 ? true : false,
      }),
      ...(filterCreateTime && {
        task_create_time_start: moment(filterCreateTime[0]).format('YYYY-MM-DD hh:mm:ss'),
        task_create_time_end: moment(filterCreateTime[1]).format('YYYY-MM-DD hh:mm:ss'),
      }),
      ...(filterLabelTime && {
        task_start_time: moment(filterLabelTime[0]).format('YYYY-MM-DD hh:mm:ss'),
        task_end_time: moment(filterLabelTime[1]).format('YYYY-MM-DD hh:mm:ss'),
      }),
      ...(filterName && {
        image_name: filterName,
      }),
      ...(filterLabelNum[0] && {
        //标注数量
        label_num_gte: filterLabelNum[0],
      }),
      ...(filterLabelNum[1] && {
        //标注数量
        label_num_lte: filterLabelNum[1],
      }),
    });

    if (res.code === 0) {
      setData(res.data);
      setPagination({ current: page, pageSize: pageSize, total: res.total });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  const fetchTodoTask = async () => {
    const res = await getMyTodoLabelTaskList();
    if (res.code === 0) {
      setTodos(res.data);
    } else {
    }
  };

  const doDownload = async () => {
    try {
      message.loading('正在下载');
      const res = await downloadLabelResult({
        ...(filterProject && {
          project_uid: filterProject,
        }),
        ...(filterCreator.length > 0 && {
          create_user_ids: filterCreator,
        }),
        ...(filterParter.length > 0 && {
          principal_user_ids: filterParter,
        }),
        ...(filterStatus !== 0 && {
          label: filterStatus === 1 ? true : false,
        }),
        ...(filterCreateTime && {
          task_create_time_start: moment(filterCreateTime[0]).format('YYYY-MM-DD hh:mm:ss'),
          task_create_time_end: moment(filterCreateTime[1]).format('YYYY-MM-DD hh:mm:ss'),
        }),
        ...(filterLabelTime && {
          task_start_time: moment(filterLabelTime[0]).format('YYYY-MM-DD hh:mm:ss'),
          task_end_time: moment(filterLabelTime[1]).format('YYYY-MM-DD hh:mm:ss'),
        }),
        ...(filterName && {
          image_name: filterName,
        }),
      });

      let link = document.createElement('a');
      const body = document.querySelector('body');
      link.download = `${'标注结果'}.json`;
      link.href = 'data:text/plain,' + JSON.stringify(res);
      if (body !== null) {
        link.style.display = 'none';
        body.appendChild(link);

        link.click();
        body.removeChild(link);
      }

      message.destroy();
      message.success('下载成功');
    } catch (e) {
      message.error(e as any);
    }
  };

  //更新任务的状态
  const updateLabelTaskStatus = async (task_uid: string, status: number) => {
    setFetching(true);
    const res = await updateLabelTask({
      task_uid: task_uid,
      label_status: status,
    });
    if (res.code === 0) {
      message.success('更新成功');
      doSearch();
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  //删除任务
  const doDelete = async (id: string) => {
    const res = await deleteLabelTask(id);
    if (res.code === 0) {
      message.success('删除成功');
      doSearch();
    } else {
      message.error(res.message);
    }
  };

  const handleTreeData = (data: PersonalLabelTasks) => {
    return [
      {
        title: (
          <span style={{ fontWeight: 500 }}>
            已完成 ·{' '}
            <span>
              {data.label_project_list.length > 0
                ? data.label_project_list.map((x) => x.label_task_list.length).reduce((pre, cur) => pre + cur)
                : 0}
            </span>
          </span>
        ),
        key: 'done',
        children: data.label_project_list.map((x) => {
          return {
            title: x.project_name,
            key: `done-${x.project_uid}`,
            icon: <FolderOutlined />,
            children: x.label_task_list.map((task) => {
              return {
                title: (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Image src={`/v2/api/pic/picture/download/${24}/${task.image_uid}`} width={24} height={24} /> */}
                    <Image src={task.image_url} width={24} height={24} />
                    <Tooltip title="点击查看">
                      <span
                        style={{ paddingLeft: 8 }}
                        onClick={() => {
                          history.push({
                            pathname: '/workbench/label/label-task/label-page',
                            search: `task_uid=${task.task_uid}&project_uid=${task.project_uid}`,
                          });
                        }}
                      >
                        {task.image_name}
                      </span>
                    </Tooltip>
                  </span>
                ),
                key: `done-${x.project_uid}-${task.task_uid}`,
              };
            }),
          };
        }),
      },
      {
        title: (
          <span style={{ fontWeight: 500 }}>
            未完成 ·{' '}
            <span>
              {data.un_label_project_list.length > 0
                ? data.un_label_project_list.map((x) => x.label_task_list.length).reduce((pre, cur) => pre + cur)
                : 0}
            </span>
          </span>
        ),
        key: 'todo',
        children: data.un_label_project_list.map((x) => {
          return {
            title: x.project_name,

            key: `todo-${x.project_uid}`,
            children: x.label_task_list.map((task) => {
              return {
                title: (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Image src={`/v2/api/pic/picture/download/${24}/${task.image_uid}`} width={24} height={24} /> */}
                    <Image src={task.image_url} width={24} height={24} />
                    <Tooltip title="点击进入标注">
                      <span
                        style={{ paddingLeft: 8 }}
                        onClick={() => {
                          history.push({
                            pathname: '/workbench/label/label-task/label-page',
                            search: `task_uid=${task.task_uid}&project_uid=${task.project_uid}`,
                          });
                        }}
                      >
                        {task.image_name}
                      </span>
                    </Tooltip>
                  </span>
                ),
                key: `todo-${x.project_uid}-${task.task_uid}`,
              };
            }),
          };
        }),
      },
    ];
  };

  React.useEffect(() => {
    fetchTodoTask();
    doSearch();
  }, []);

  const columns = [
    {
      title: '状态',
      align: 'center' as 'center',
      key: 'status',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return (
          <Checkbox
            onChange={(e) => {
              updateLabelTaskStatus(record.task_uid, e.target.checked === true ? 1 : 0);
            }}
            checked={record.label_status === 1}
          >
            {record.label_status === 1 ? '已完成' : '未完成'}
          </Checkbox>
        );
      },
    },
    {
      title: '项目名称',
      align: 'center' as 'center',
      key: 'project_name',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.project_name ? record.project_name : '无';
      },
    },
    {
      title: '图片名',
      align: 'center' as 'center',
      key: 'fileName',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.image_name;
      },
    },

    {
      title: '标注人',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.principal_user ? (
          <span>
            {record.principal_user.username}{' '}
            <a
              onClick={(e) => {
                e.preventDefault();
                setEditTask(record);
                setSetLabelPersonVisible(true);
              }}
            >
              <EditOutlined></EditOutlined>
            </a>
          </span>
        ) : (
          <a
            onClick={(e) => {
              e.preventDefault();
              setEditTask(record);
              setSetLabelPersonVisible(true);
            }}
          >
            设置标注人
          </a>
        );
      },
    },
    {
      title: '创建时间',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.create_time;
      },
    },
    {
      title: '最后标注时间',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.end_time;
      },
    },
    {
      title: '上传人',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.create_user ? record.create_user.username : '无';
      },
    },

    {
      title: '图像',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        // return <Image src={`/v2/api/pic/picture/download/${24}/${record.image_uid}?type=1`} width={24} height={24} />;
        return <Image src={record.image_url} width={24} height={24} />;
      },
    },
    {
      title: '已标记数',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.label_num;
      },
    },
    {
      title: '操作',
      align: 'center' as 'center',
      key: 'actions',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return (
          <span>
            <a
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: '/workbench/label/label-task/label-page',
                  search: `task_uid=${record.task_uid}&project_uid=${record.project_uid}`,
                });
              }}
            >
              进入标注
            </a>
            <Divider type="vertical"></Divider>
            <a
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: '提示',
                  content: '确认删除该任务吗？',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => doDelete(record.task_uid),
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <div className="label-task-page">
      <div className="header">
        <div className="tools-wrapper">
          <div className="filter-input-container">
            <Form colon={true} layout="inline">
              <Form.Item className="filter-item" label="项目">
                <Select
                  placeholder="全部"
                  allowClear
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      const res = await getAllLabelProjectList({ limit: 10000, skip: 0 });
                      if (res.code === 0) {
                        setProjectOptions(res.data);
                      } else {
                        message.error('网络错误');
                      }
                    }
                  }}
                  onChange={(value: string) => {
                    setFilterProject(value);
                  }}
                >
                  {projectOptions.map((project) => {
                    return (
                      <Select.Option value={project.project_uid} key={project.project_uid}>
                        {project.project_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item className="filter-item" label="创建人">
                <Select
                  mode="multiple"
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      const res = await getAllUsers({
                        limit: 1000,
                        skip: 0,
                        username: '',
                      });
                      if (res.code === 0) {
                        setUserOptions(res.data);
                      } else {
                        message.error('网络错误');
                      }
                    }
                  }}
                  onChange={(values: number[]) => {
                    setFilterCreator(values);
                  }}
                >
                  {userOptions.map((x) => {
                    return (
                      <Select.Option value={x.id} key={x.user_id}>
                        <span>
                          {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.username}</Avatar>}{' '}
                          <span style={{ paddingLeft: 8 }}>{x.username}</span>
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item className="filter-item" label="标注人">
                <Select
                  placeholder="全部"
                  mode="multiple"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      const res = await getAllUsers({
                        limit: 1000,
                        skip: 0,
                        username: '',
                      });
                      if (res.code === 0) {
                        setUserOptions(res.data);
                      } else {
                        message.error('网络错误');
                      }
                    }
                  }}
                  onChange={(values: number[]) => {
                    setFilterParter(values);
                  }}
                >
                  {userOptions.map((x) => {
                    return (
                      <Select.Option value={x.id} key={x.user_id}>
                        <span>
                          {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.username}</Avatar>}{' '}
                          <span style={{ paddingLeft: 8 }}>{x.username}</span>
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item className="filter-item" label="状态">
                <Select onChange={(value: number) => setFilterStatus(value)} placeholder="全部">
                  <Select.Option value={0}>全部</Select.Option>
                  <Select.Option value={1}>已完成</Select.Option>
                  <Select.Option value={2}>未完成</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item className="filter-item" label="图片名">
                <Input onChange={(e) => setFilterName(e.target.value)} placeholder="全部"></Input>
              </Form.Item>
              <Form.Item className="filter-item" label="标注数量">
                <InputNumber
                  min={0}
                  placeholder="最小值"
                  value={filterLabelNum[0]}
                  onChange={(e) => {
                    setFilterLabelNum([e, filterLabelNum[1]]);
                  }}
                ></InputNumber>
                ~
                <InputNumber
                  min={1}
                  placeholder="最大值"
                  value={filterLabelNum[1]}
                  onChange={(e) => {
                    setFilterLabelNum([filterLabelNum[0], e]);
                  }}
                ></InputNumber>
              </Form.Item>
              <Form.Item className="filter-item" label="创建时间">
                <DatePicker.RangePicker onChange={(e) => setFilterCreateTime(e)}></DatePicker.RangePicker>
              </Form.Item>
              <Form.Item className="filter-item" label="标注时间">
                <DatePicker.RangePicker onChange={(e) => setFilterLabelTime(e)}></DatePicker.RangePicker>
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                doDownload();
              }}
              style={{ marginRight: 8 }}
            >
              下载
            </Button>
            <Button
              onClick={() => {
                doSearch(1, 10);
              }}
              type="primary"
            >
              搜索
            </Button>
          </div>
        </div>
      </div>
      <div className="task-content">
        <div className="all-task">
          <Table
            dataSource={data}
            columns={columns}
            style={{ maxHeight: '100%' }}
            pagination={{
              ...pagination,
              onChange: (page, pageSize) => doSearch(page, pageSize),
              showTotal: (total) => <span>共 {total} 条数据</span>,
              style: {
                marginRight: 16,
              },
            }}
            loading={loading}
          ></Table>
        </div>
        <div className="my-task">
          {todos && (
            <div>
              <Divider>我的任务</Divider>
              <Tree multiple style={{ width: '100%', height: '100%' }} treeData={handleTreeData(todos)} />
            </div>
          )}
        </div>
      </div>
      {setLabelPersonVisible && (
        <SetLabelPersonModal
          task={editTask}
          setTask={setEditTask}
          fetchData={doSearch}
          visible={setLabelPersonVisible}
          setVisible={setSetLabelPersonVisible}
          // project={null}
        />
      )}
    </div>
  );
};

export default LabelTaskPage;
