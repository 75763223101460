import Axios from 'axios';
import querystring from 'querystring';
import { message, Modal } from 'antd';

const singleton = Axios.create({
  baseURL: `/v2/api/`,
  paramsSerializer: (params) => querystring.stringify(params),
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

singleton.interceptors.response.use(
  function (response) {
    const { data } = response;
    if (data.code === 20001) {
      // message.error('请登录');
      setTimeout(() => window.location.replace(`/#/login`), 500);
    }
    if (data.code === 20006) {
      Modal.warning({
        title: '风险提示',
        okText: '我知道了',
        content: '您的账号已在另一设备登录，如果这不是您本人操作，您的密码可能已泄露，请及时修改密码！',
        onOk: () => {
          setTimeout(() => window.location.replace(`/#/login`), 500);
        },
        onCancel: () => {},
        cancelText: null,
      });
    }
    return response.data as any;
  },
  function (error) {
    let res = error.response;
    if (res) {
      let { status, data } = res;
      // if (status === 500 || status === 502) {
      //   window.location.assign(`/net-error`);
      // }
      return {
        code: status,
        message: data.message,
      } as any;
    }
    return Promise.reject(error);
  }
);

export default singleton;
