import * as React from 'react'
import { Modal, Input, Form, message, Button } from 'antd'
import * as BIRequests from '@/requests/bi'

const ShareConfigModal = ({
  config,
  shareVisible,
  setShareVisible,
}: {
  config: BIconfigItem | null
  shareVisible: boolean
  setShareVisible: Function
}) => {
  const [fetching, setFetching] = React.useState(false)
  const [form] = Form.useForm()
  const handleOk = async (values: any) => {
    doShare(values)
  }
  const handleCancel = () => {
    setShareVisible(false)
  }

  const doShare = async (values: any) => {
    if (!config || config === null) return
    setFetching(true)
    const res = BIRequests.shareConfig({
      config_id: config.config_id,
      config_name: values.config_name,
    })
    if ((await res).code === 0) {
      message.success('分享成功！')
      setShareVisible(false)
    } else {
      message.error((await res).message)
    }
    setFetching(false)
  }

  return (
    <Modal
      visible={shareVisible}
      destroyOnClose
      title={
        <span>
          <div className="title-prefix"></div>图表配置分享
        </span>
      }
      onCancel={handleCancel}
      footer={null}
    >
      <Form onFinish={handleOk} preserve={false} form={form}>
        <Form.Item
          label="配置名称"
          extra="将您创建的配置分享至云端，其他用户导入配置时名称可作为搜索关键词"
          name="config_name"
          rules={[{ required: true, message: '请输入配置名称' }]}
        >
          <Input placeholder="请输入配置名称" />
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ShareConfigModal
