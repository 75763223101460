import React from 'react'
import { Modal, Input, Form, Select, message, InputNumber, Button } from 'antd'
import * as CoordinateRequests from '@/requests/location'

interface ModalProps {
  visible: boolean
  setVisible: Function
  fetchData: Function
  data: CoordinateItem
}

const UpdateCoordinateModal = (props: ModalProps) => {
  const { visible, setVisible, fetchData, data } = props
  const [fetching, setFetching] = React.useState(false)
  const [selectedAddr, setSelectedAddr] = React.useState<string[]>(
    data.location_coordinate_addrs.map((x) => x.addr_comp_name)
  )
  const [form] = Form.useForm()

  const handleOk = async (values: any) => {
    doUpdate({
      location_coordinate_uid: props.data.location_coordinate_uid,
      location_name: values['location_name'],
      coordinate_x: values['coordinate_x'],
      coordinate_y: values['coordinate_y'],
      coordinate_z: values['coordinate_z'],
      order_num: values['order_num'],
      location_coordinate_addrs: values['location_coordinate_addrs'].map((x: string) => {
        return {
          addr_comp_name: x,
          addr_comp_value: values[x],
          addr_comp_type: '文本',
        } as CoorAddr
      }),
    })
  }

  const handleCancel = (e: any) => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }

  const doUpdate = async (input: UpdateCoordinateReq) => {
    setFetching(true)
    const res = await CoordinateRequests.updateCoordinate(input)
    if (res.code === 0) {
      message.success('保存成功')
      setVisible(false)
      fetchData()
    } else {
      message.error('保存失败')
    }
    setFetching(false)
  }

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>创建地理坐标
        </span>
      }
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        onFinish={handleOk}
        labelCol={{
          md: 5,
          xl: 5,
          sm: 5,
        }}
        preserve={false}
        form={form}
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues)[0] === 'location_coordinate_addrs') {
            setSelectedAddr(changedValues[Object.keys(changedValues)[0]])
          }
        }}
      >
        <Form.Item
          label="地理标签"
          name="location_name"
          rules={[{ required: true, message: '请输入地理标签！' }]}
          initialValue={data.location_name}
        >
          <Input placeholder="请输入地理标签" />
        </Form.Item>
        <Form.Item
          label="x坐标"
          name="coordinate_x"
          rules={[{ required: true, message: '请输入x坐标！' }]}
          initialValue={data.coordinate_x}
        >
          <Input placeholder="请输入x坐标" />
        </Form.Item>
        <Form.Item
          label="y坐标"
          name="coordinate_y"
          rules={[{ required: true, message: '请输入y坐标！' }]}
          initialValue={data.coordinate_y}
        >
          <Input placeholder="请输入y坐标" />
        </Form.Item>
        <Form.Item
          label="z坐标"
          name="coordinate_z"
          rules={[{ required: true, message: '请输入z坐标！' }]}
          initialValue={data.coordinate_z}
        >
          <Input placeholder="请输入z坐标" />
        </Form.Item>

        <Form.Item label="编号" name="order_num" initialValue={data.order_num}>
          <InputNumber placeholder="编号" />
        </Form.Item>
        <Form.Item
          label="地理组件"
          name="location_coordinate_addrs"
          rules={[{ required: true, message: '请输入地理组件！' }]}
          initialValue={data.location_coordinate_addrs.map((x) => x.addr_comp_name)}
        >
          <Select mode="tags" placeholder="请输入地理组件" />
        </Form.Item>
        {selectedAddr.map((x: string, _index: any) => {
          return (
            <Form.Item
              label={x}
              name={x}
              key={`Coordinate-comps-${_index}`}
              rules={[
                {
                  required: true,
                  message: `请输入${x}值！`,
                },
              ]}
              initialValue={
                data.location_coordinate_addrs[_index]
                  ? data.location_coordinate_addrs[_index].addr_comp_value
                  : undefined
              }
            >
              <Input placeholder={`请输入${x}值`} />
            </Form.Item>
          )
        })}
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateCoordinateModal
