import React, { forwardRef } from 'react';
import { message, List, Avatar, Progress, Divider, Tooltip, Modal } from 'antd';

import { getAllProjectList, deleteProject, collectProject, onCollectProject } from '@/requests/project';
import { DeleteOutlined, SettingOutlined, StarFilled, StarOutlined, UserAddOutlined } from '@ant-design/icons';
import '../styles/projectList.less';
import EditProjectModal from './EditProjectModal';
import MemberModal from './MemberModal';
import { useHistory } from 'react-router-dom';
import { useCtxState } from '@/App.store';

const MyProjectList = (props: any, ref: any) => {
  const [{ sysUsers }] = useCtxState();
  const history = useHistory();
  const [data, setData] = React.useState<ProjectItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 8,
    total: 0,
  });
  const [editingProject, setEditingProject] = React.useState<ProjectItem>();

  const [editVisible, setEditVisible] = React.useState(false);
  const [memberVisible, setMemberVisible] = React.useState(false);
  React.useImperativeHandle(ref, () => {
    return {
      fetchData,
    };
  });

  const [loading, setLoading] = React.useState(false);

  const handleEditClick = (project: ProjectItem) => {
    if (!project) return;

    setEditingProject(project);
    setEditVisible(true);
  };

  const handleMemberClick = (project: ProjectItem) => {
    if (!project) return;
    setEditingProject(project);
    setMemberVisible(true);
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getAllProjectList({
      limit: page,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const doDelete = async (project_code: ProjectItem['project_code']) => {
    if (!project_code) return;
    const res = await deleteProject({
      project_code,
    });
    if (res.code === 0) {
      message.success('操作成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  //收藏项目
  const doCollect = async (project_id: ProjectItem['id']) => {
    if (!project_id) return;
    const res = await collectProject(project_id);
    if (res.code === 0) {
      message.success('收藏成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  //取消收藏项目
  const doCancelCollect = async (project_id: ProjectItem['id']) => {
    if (!project_id) return;
    const res = await onCollectProject(project_id);
    if (res.code === 0) {
      message.success('取消收藏成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="project-list">
      <List
        dataSource={data}
        loading={loading}
        renderItem={(item) => {
          const user = sysUsers.find((x: UserInfoItem) => x.id === item.creator_id);
          return (
            <List.Item
              key={item.id}
              extra={[
                <div className="project-props-container">
                  <div className="key">创建日期</div>
                  <div className="value">{item.create_time}</div>
                </div>,
                <div className="project-props-container">
                  <div className="key">创建人</div>
                  <div className="value">{user ? user.username : '未知'}</div>
                </div>,
                <div className="project-props-container">
                  <div className="key">进度</div>
                  <div className="value" style={{ minWidth: 200 }}>
                    <Progress percent={Number(item.project_schedule)} status="active" />
                  </div>
                </div>,
                <div style={{ display: 'flex' }}>
                  <Tooltip title="添加成员">
                    <UserAddOutlined style={{ cursor: 'pointer' }} onClick={() => handleMemberClick(item)} />
                  </Tooltip>
                  <Divider type="vertical"></Divider>
                  <Tooltip title="项目设置">
                    <SettingOutlined style={{ cursor: 'pointer' }} onClick={() => handleEditClick(item)} />
                  </Tooltip>

                  <Divider type="vertical"></Divider>
                  <Tooltip title={item.collected ? '取消收藏' : '收藏'}>
                    {item.favorite ? (
                      <StarFilled
                        style={{ cursor: 'pointer', color: '#f38900' }}
                        onClick={() => doCancelCollect(item.id)}
                      />
                    ) : (
                      <StarOutlined style={{ cursor: 'pointer' }} onClick={() => doCollect(item.id)} />
                    )}
                  </Tooltip>

                  <Divider type="vertical"></Divider>
                  <Tooltip title="移至回收站">
                    <DeleteOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        Modal.confirm({
                          title: '温馨提示',
                          content: '确认将该项目移至回收站吗？',
                          okText: '确认',
                          onOk: () => doDelete(item.project_code),
                          cancelText: '取消',
                          onCancel: () => {},
                        });
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    style={{ width: 50, height: 50 }}
                    src={process.env.PUBLIC_URL + '/img/common/project-cover.png'}
                  />
                }
                title={
                  <a
                    style={{ color: '#F39800' }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/workbench/project/detail`,
                        state: {
                          project: item,
                        },
                        search: `id=${item.id}`,
                      });
                    }}
                  >
                    {item.project_name}
                  </a>
                }
                description={item.description}
              ></List.Item.Meta>
            </List.Item>
          );
        }}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            fetchData(page, pageSize);
          },
          showTotal: (total) => <span>共{total}条数据</span>,
        }}
      />
      {editVisible && (
        <EditProjectModal
          visible={editVisible}
          setVisible={setEditVisible}
          project={editingProject}
          setProject={setEditingProject}
          fetchData={fetchData}
        />
      )}
      {memberVisible && (
        <MemberModal
          visible={memberVisible}
          setVisible={setMemberVisible}
          project={editingProject}
          setProject={setEditingProject}
        />
      )}
    </div>
  );
};

export default forwardRef(MyProjectList);
