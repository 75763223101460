import * as React from 'react';
import {
  getTaskList,
  createTask,
  createStage,
  updateStage,
  getTaskStageList,
  deleteStage,
  getMembersById,
  getProjectAllTags,
  updateTask,
} from '@/requests/project';

import { useLocation } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Tooltip,
  Menu,
  Dropdown,
  Divider,
  Select,
  List,
  Avatar,
  Checkbox,
  Table,
  DatePicker,
  Tag,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UserOutlined,
  EllipsisOutlined,
  CalendarOutlined,
  BorderOutlined,
  TagsOutlined,
  FileTextOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';

import '../styles/taskList.less';
import { useCtxState } from '@/App.store';
import TaskDetailModal from './TaskDetailModal';
import moment from 'moment';

interface PropsType {
  project: ProjectItem;
  mode: boolean;
  filterTaskName: string;
}

interface CreateTaskFormField {
  task_name: string;
  assign_to: number;
  begin_time: moment.Moment;
  end_time: moment.Moment;
  participant_user_ids: number[];
  priority_level: number;
  task_tag_ids: number[];
}

interface CreateStageFormField {
  task_stages_name: string;
}
interface UpdateStageFormField {
  task_stages_name: string;
}
interface CreateTaskFormField {
  task_name: string;
  assign_to: number;
}
interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  is_join: number;
}

const TaskList = (props: PropsType) => {
  const { project, filterTaskName, mode } = props;
  const location = useLocation();
  const [{ userInfo, sysUsers }] = useCtxState();

  const [creatTaskVisible, setCreateTaskVisible] = React.useState(false);
  const [createTaskForm] = Form.useForm();
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tasks, setTasks] = React.useState<ProjectTaskItem[]>([]);
  const [stages, setStages] = React.useState<TaskStagesItem[]>([]);
  const [showCreateStageForm, setShowCreateStageForm] = React.useState(false);
  const [editStageVisible, setEditStageVisible] = React.useState(false);
  const [editingStage, setEditingStage] = React.useState<TaskStagesItem>();
  const [parentStage, setParentStage] = React.useState<TaskStagesItem>();

  const [editingTask, setEditingTask] = React.useState<ProjectTaskItem>();
  const [editTaskVisible, setEditTaskVisible] = React.useState(false);
  const [editingTaskParentStage, setEditingTaskParentStage] = React.useState<TaskStagesItem>();

  const [createStageForm] = Form.useForm();
  const [updateStageForm] = Form.useForm();

  const [userOptions, setUserOptions] = React.useState<Member[]>([]);
  const [showMore, setShowMore] = React.useState(false);
  const [taskTagOptions, setTaskTagOptions] = React.useState<TaskTagItem[]>([]);
  React.useEffect(() => {
    fetchTaskList(project.id, filterTaskName);
  }, [filterTaskName]);

  React.useEffect(() => {
    fetchStages();
    fetchMembers();
    fetchTaskList();
  }, [project]);
  //获取任务分组列表
  const fetchStages = async () => {
    if (!project) return;
    const { id } = project;
    const res = await getTaskStageList({
      limit: 10000,
      skip: 0,
      project_id: id,
    });
    if (res.code === 0) {
      setStages(res.data);
    } else {
      message.error('读取任务列表失败');
    }
  };
  //获取任务列表
  const fetchTaskList = async (id?: number, task_name?: string) => {
    if (!project) return;
    if (!id) {
      id = project.id;
    }
    setLoading(true);
    const res = await getTaskList({
      project_id: id,
      task_name: task_name ? task_name : '',
      user_type: 1,
      limit: 10000,
      skip: 0,
      // parent_task_id: 0,
    });
    if (res.code === 0) {
      setTasks(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  //获取项目成员
  const fetchMembers = async () => {
    if (!project) return;
    const memRes = await getMembersById(project.id);
    if (memRes.code === 0) {
      setUserOptions(
        memRes.data.map((x) => {
          const match = sysUsers.find((y: UserInfoItem) => y.id === x.user_id) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            is_join: 1,
          };
        })
      );
    } else {
      message.error('获取成员列表失败');
    }
  };
  // 创建任务列表
  const doCreateStage = async (name: string) => {
    if (!project) return;
    const res = await createStage({
      task_stages_name: name,
      project_id: project.id,
      sort: stages.length,
    });
    if (res.code === 0) {
      message.success('创建成功');
      createStageForm.setFieldsValue({ task_stages_name: '' });
      fetchStages();
    } else {
      message.error('创建失败');
    }
  };
  // 删除任务列表
  const doDeleteStage = (id: TaskStagesItem['id']) => {
    Modal.confirm({
      content: '确认删除该任务列表吗？',
      title: '提示',
      onOk: async () => {
        const res = await deleteStage(id);
        if (res.code === 0) {
          message.success('删除成功');
          fetchStages();
        } else {
          message.error('网络错误');
        }
      },
      okText: '确认',
      onCancel: () => {},
      cancelText: '取消',
    });
  };
  // 修改任务列表
  const doUpdateStage = async (values: UpdateStageFormField) => {
    if (!editingStage) return;
    setFetching(true);
    const res = await updateStage({
      id: editingStage.id,
      task_stages_name: values.task_stages_name,
      sort: editingStage.sort,
    });
    if (res.code === 0) {
      message.success('修改成功');
      fetchStages();
      setEditStageVisible(false);
      setEditingStage(undefined);
    } else {
      message.error('网络错误，请稍后再试');
    }
    setFetching(false);
  };
  // 创建任务
  const doCreateTask = async (values: CreateTaskFormField) => {
    if (!project) return;
    if (!parentStage) return;
    setFetching(true);
    const res = await createTask({
      ...values,
      project_id: project.id,
      task_stage_id: parentStage.id,
      assign_to: values.assign_to,
      ...(values.begin_time && { begin_time: values.begin_time.format('YYYY-MM-DD') + ' ' + '00:00:00' }),
      ...(values.end_time && { end_time: values.end_time.format('YYYY-MM-DD') + ' ' + '00:00:00' }),
    });
    if (res.code === 0) {
      message.success('添加任务成功');
      fetchTaskList(project.id);
      createTaskForm.setFieldsValue({ task_name: '' });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  //获取所有标签
  const fetchAllTags = async () => {
    if (!project) return;
    const res = await getProjectAllTags({
      project_id: project.id,
    });
    if (res.code === 0) {
      setTaskTagOptions(
        res.data.map((x) => {
          return x;
        })
      );
    } else {
      message.error('获取标签列表失败');
    }
  };

  //更新子任务完成状态
  const doUpdateChildTaskStatus = async (id: number, status: boolean) => {
    const res = await updateTask({
      id,
      done: status,
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchTaskList();
    } else {
      message.error('网络错误');
    }
  };

  const handleCreateTaskCancel = () => {
    setCreateTaskVisible(false);
  };
  const handleCreateTaskOk = (values: CreateTaskFormField) => {
    doCreateTask(values);
  };

  const handleCreateStageOk = (values: CreateStageFormField) => {
    doCreateStage(values.task_stages_name);
  };
  const handleUpdateStageOk = (values: UpdateStageFormField) => {
    doUpdateStage(values);
  };
  const handleUpdateStageCancel = () => {
    setEditStageVisible(false);
    setEditingStage(undefined);
  };

  const StageActions = ({ stage }: { stage: TaskStagesItem }) => {
    const stageActionmenus = (
      <Menu>
        <Menu.Item
          onClick={() => {
            setEditingStage(stage);
            setEditStageVisible(true);
          }}
        >
          <span>
            <EditOutlined /> 编辑列表
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            <UserOutlined /> 设置本列所有任务执行者
          </span>
        </Menu.Item>
        <Menu.Item>
          <span>
            <DeleteOutlined /> 本列所有任务移到回收站
          </span>
        </Menu.Item>
        <Menu.Item onClick={() => doDeleteStage(stage.id)}>
          <span>
            <DeleteOutlined /> 删除列表
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown overlay={stageActionmenus} placement="bottomCenter">
        <Tooltip title="编辑任务列表">
          <EllipsisOutlined />
        </Tooltip>
      </Dropdown>
    );
  };
  const getTaskContainerClassName = (task: ProjectTaskItem) => {
    if (task.done) {
      return 'task-item-container task-item-done';
    }
    if (task.priority_level === 1) {
      return 'task-item-container task-item-urgent';
    }
    if (task.priority_level === 2) {
      return 'task-item-container task-item-very-urgent';
    }
    return 'task-item-container task-item-undone';
  };

  const getTaskEndTimeColor = (task: ProjectTaskItem) => {
    if (!task.end_time) return 'grey';
    // 判断截止时间是否已经过期
    if (new Date(task.end_time) < new Date()) return '#e62412';

    // if (task.end_time.split(' ')[0] < new Date().toLocaleDateString()) return '#e62412';
    return '#1b9aee';
  };
  const getTaskEndTimeDescription = (task: ProjectTaskItem) => {
    //判断是否是今年
    if (task.end_time && new Date(task.end_time).getFullYear() === new Date().getFullYear()) {
      //判断是否是本周内
      // if (task.end_time && new Date(task.end_time).getDay() - new Date().getDay() < 7) {
      //   return '本周' + (new Date(task.end_time).getDay() + 1).toString();
      // }
      return moment(task.end_time).format('MM月DD日');
    } else {
      return moment(task.end_time).format('YYYY年MM月DD日');
    }
  };

  return (
    <div className="task-list-page">
      {mode ? (
        <>
          {stages.map((stage, index) => {
            return (
              <div className="stage-container" key={stage.id}>
                <div className="stage-header">
                  <span className="stage-name">
                    {stage.task_stages_name} ·{' '}
                    {
                      tasks.filter((task) => {
                        return task.task_stage_id === stage.id && task.parent_task_id === 0;
                      }).length
                    }
                  </span>
                  <span className="stage-actions">
                    <StageActions stage={stage} />
                  </span>
                </div>
                <div className="tasks-container">
                  {tasks
                    .filter((task) => {
                      return task.task_stage_id === stage.id && task.parent_task_id === 0 && task.done === false;
                    })
                    .concat(
                      tasks.filter((task) => {
                        return task.task_stage_id === stage.id && task.parent_task_id === 0 && task.done === true;
                      })
                    )
                    .map((x, index) => {
                      const user = userOptions.find((y) => y.user_id === x.assign_to);
                      const children = tasks.filter((y) => y.parent_task_id === x.id);
                      const children_done = children.filter((y) => y.done);
                      return (
                        <div className={getTaskContainerClassName(x)} key={index}>
                          <div className="task-item-content">
                            <Checkbox
                              onChange={(e) => {
                                doUpdateChildTaskStatus(x.id, e.target.checked);
                              }}
                              className="task-checkbox"
                              checked={x.done}
                            ></Checkbox>
                            <div
                              className="task-item-mid"
                              onClick={() => {
                                setEditingTask(x);
                                setEditingTaskParentStage(stage);
                                setEditTaskVisible(true);
                              }}
                            >
                              <div className={'task-item-header'}>{x.task_name}</div>
                              {!x.done && (
                                <>
                                  <div className="task-item-description">
                                    <div className="task-end-time">
                                      {x.end_time && (
                                        <Tag
                                          style={{
                                            padding: '0 4px',
                                          }}
                                          color={getTaskEndTimeColor(x)}
                                        >
                                          {getTaskEndTimeDescription(x)} 截止
                                        </Tag>
                                      )}
                                    </div>
                                    <div className="task-item-children">
                                      {children.length > 0 && (
                                        <Tooltip title="子任务">
                                          <OrderedListOutlined /> {children_done.length + '/' + children.length}
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            {user && (
                              <div className="task-user">
                                <Tooltip title={user.user_name}>
                                  {user.avatar ? (
                                    <Avatar src={`v2/api/user/avatar/${user.avatar}`} size={'small'} />
                                  ) : (
                                    <Avatar>{user.user_name}</Avatar>
                                  )}
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="create-task-container">
                  <div className="create-task-content">
                    {creatTaskVisible && parentStage && parentStage.id === stage.id ? (
                      <Form
                        form={createTaskForm}
                        preserve={false}
                        onFinish={handleCreateTaskOk}
                        style={{ width: '100%', marginTop: 4 }}
                      >
                        <Form.Item
                          name={'task_name'}
                          rules={[
                            {
                              required: true,
                              message: '请输入任务名称',
                            },
                          ]}
                          style={{ marginBottom: 12 }}
                        >
                          <Input.TextArea placeholder="任务内容" autoFocus />
                        </Form.Item>
                        <Form.Item initialValue={userInfo.id} name="assign_to" style={{ marginBottom: 0 }}>
                          <Select
                            bordered={false}
                            showArrow={false}
                            onDropdownVisibleChange={(open) => {
                              if (open) {
                                fetchMembers();
                              }
                            }}
                          >
                            {userOptions.map((member, index) => {
                              return (
                                <Select.Option value={member.user_id} key={index}>
                                  <List.Item.Meta
                                    avatar={
                                      member.avatar ? (
                                        <Avatar src={`v2/api/user/avatar/${member.avatar}`} size={'small'} />
                                      ) : (
                                        <Avatar>{member.user_name}</Avatar>
                                      )
                                    }
                                    title={<span style={{ color: '#f39800' }}>{member.user_name}</span>}
                                    description={null}
                                    style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                                  />
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        {!showMore && (
                          <div
                            style={{
                              color: 'grey',
                              margin: '8px 0',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              type="text"
                              icon={<EllipsisOutlined style={{ fontSize: 24 }} />}
                              style={{ padding: 0, color: 'grey', display: 'flex', alignItems: 'center' }}
                              onClick={() => {
                                setShowMore(true);
                              }}
                            >
                              更多
                            </Button>
                          </div>
                        )}
                        {showMore && (
                          <>
                            <Form.Item
                              name="begin_time"
                              style={{ marginBottom: 8, marginTop: 8 }}
                              label={<CalendarOutlined style={{ color: 'grey', fontSize: 16 }} />}
                              colon={false}
                            >
                              <DatePicker
                                bordered={false}
                                placeholder="设置开始时间"
                                style={{ width: '100%' }}
                                suffixIcon={null}
                              />
                            </Form.Item>
                            <Form.Item
                              name="end_time"
                              style={{ marginBottom: 8 }}
                              label={<CalendarOutlined style={{ color: 'grey', fontSize: 16 }} />}
                              colon={false}
                            >
                              <DatePicker
                                bordered={false}
                                placeholder="设置结束时间"
                                style={{ width: '100%' }}
                                suffixIcon={null}
                              />
                            </Form.Item>
                            <Form.Item
                              name="priority_level"
                              style={{ marginBottom: 8 }}
                              label={<BorderOutlined style={{ color: 'grey', fontSize: 16 }} />}
                              colon={false}
                              initialValue={0}
                            >
                              <Select bordered={false} suffixIcon={null} style={{ padding: '0 11px' }}>
                                <Select.Option value={0}>
                                  <Tag color="green">普通</Tag>
                                </Select.Option>
                                <Select.Option value={1}>
                                  <Tag color={'rgb(255, 153, 0)'}>紧急</Tag>
                                </Select.Option>
                                <Select.Option value={2}>
                                  <Tag color={'rgb(237, 63, 20)'}>非常紧急</Tag>
                                </Select.Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="task_tag_ids"
                              style={{ marginBottom: 8 }}
                              label={<TagsOutlined style={{ color: 'grey', fontSize: 16 }} />}
                              colon={false}
                            >
                              <Select
                                mode="tags"
                                onDropdownVisibleChange={(open) => {
                                  if (open) {
                                    fetchAllTags();
                                  }
                                }}
                                bordered={false}
                                suffixIcon={null}
                                // style={{ padding: '0 11px' }}
                                placeholder="添加标签"
                              >
                                {taskTagOptions.map((tag, index) => {
                                  return (
                                    <Select.Option value={tag.id} key={index}>
                                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div
                                          style={{
                                            width: 7,
                                            height: 7,
                                            borderRadius: 7,
                                            backgroundColor: tag.tag_color,
                                            marginRight: 12,
                                          }}
                                        ></div>
                                        <span style={{ color: 'grey', fontWeight: 500 }}> {tag.tag_name}</span>
                                      </div>
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              name="participant_user_ids"
                              style={{ marginBottom: 8 }}
                              label={<UserOutlined style={{ color: 'grey', fontSize: 16 }} />}
                              colon={false}
                            >
                              <Select
                                mode="multiple"
                                onDropdownVisibleChange={(open) => {
                                  if (open) {
                                    fetchMembers();
                                  }
                                }}
                                bordered={false}
                                suffixIcon={null}
                                // style={{ padding: '0 11px' }}
                                placeholder="添加参与者"
                                optionLabelProp="label"
                              >
                                {userOptions.map((member, index) => {
                                  return (
                                    <Select.Option value={member.user_id} key={index} label={member.user_name}>
                                      <List.Item.Meta
                                        avatar={
                                          member.avatar ? (
                                            <Avatar src={`v2/api/user/avatar/${member.avatar}`} size={'small'} />
                                          ) : (
                                            <Avatar>{member.user_name}</Avatar>
                                          )
                                        }
                                        title={<span style={{ color: '#f39800' }}>{member.user_name}</span>}
                                        description={null}
                                        style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                                      />
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="description"
                              style={{ marginBottom: 8, marginTop: 8 }}
                              label={<FileTextOutlined style={{ color: 'grey', fontSize: 16 }} />}
                              colon={false}
                            >
                              <Input.TextArea placeholder="输入备注"></Input.TextArea>
                            </Form.Item>

                            <div
                              style={{
                                color: 'grey',
                                margin: '8px 0',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                type="text"
                                icon={<EllipsisOutlined style={{ fontSize: 24 }} />}
                                style={{ padding: 0, color: 'grey', display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                  setShowMore(false);
                                }}
                              >
                                收起
                              </Button>
                            </div>
                          </>
                        )}
                        <Divider style={{ margin: '12px 0' }}></Divider>
                        <Form.Item noStyle>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Button onClick={handleCreateTaskCancel} style={{ borderRadius: 5, width: '48%' }}>
                              取消
                            </Button>
                            <Button
                              type="primary"
                              htmlType={'submit'}
                              style={{ borderRadius: 5, width: '48%' }}
                              loading={fetching}
                            >
                              创建
                            </Button>
                          </div>
                        </Form.Item>
                      </Form>
                    ) : (
                      <span
                        onClick={() => {
                          setCreateTaskVisible(true);
                          setParentStage(stage);
                        }}
                      >
                        <PlusCircleOutlined /> 添加任务
                      </span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="create-stage-container">
            {showCreateStageForm ? (
              <Form form={createStageForm} preserve={false} onFinish={handleCreateStageOk}>
                <Form.Item name={'task_stages_name'} rules={[{ required: true, message: '请输入任务列表名称' }]}>
                  <Input placeholder="新建任务列表..." style={{ width: 280 }}></Input>
                </Form.Item>
                <Form.Item noStyle>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="text" onClick={() => setShowCreateStageForm(false)}>
                      取消
                    </Button>
                    <Button style={{ borderRadius: 5 }} htmlType="submit" type="primary">
                      保存
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            ) : (
              <div
                className="create-btn"
                onClick={() => {
                  setShowCreateStageForm(true);
                }}
              >
                <div>
                  <PlusOutlined /> 新建任务列表
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={tasks}
            columns={[
              {
                dataIndex: 'task_name',
                title: '标题',
                render: (value, record, index) => {
                  return record.task_name;
                },
              },

              {
                dataIndex: 'assign_to',
                title: '开始时间',
                render: (value, record, index) => {
                  return '无';
                },
              },
              {
                dataIndex: 'assign_to',
                title: '截止时间',
                render: (value, record, index) => {
                  return '无';
                },
              },
              {
                dataIndex: 'assign_to',
                title: '执行人',
                render: (value, record, index) => {
                  const user = userOptions.find((y) => y.user_id === record.assign_to);
                  if (!user) {
                    return null;
                  }
                  return (
                    <div>
                      <Tooltip title={user.user_name}>
                        {user.avatar ? (
                          <Avatar src={`v2/api/user/avatar/${user.avatar}`} size={'small'} />
                        ) : (
                          <Avatar>{user.user_name}</Avatar>
                        )}
                        <span style={{ paddingLeft: 8 }}>{user.user_name}</span>
                      </Tooltip>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      )}

      {editingStage && (
        <Modal
          visible={editStageVisible}
          footer={null}
          title={
            <span>
              <div className="title-prefix"></div>编辑列表
            </span>
          }
          onCancel={handleUpdateStageCancel}
        >
          <Form form={updateStageForm} preserve={false} onFinish={handleUpdateStageOk}>
            <Form.Item
              name={'task_stages_name'}
              initialValue={editingStage.task_stages_name}
              rules={[
                {
                  required: true,
                  message: '请输入列表名称',
                },
              ]}
            >
              <Input placeholder="请输入列表名称" />
            </Form.Item>
            <Form.Item noStyle>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '36px 0 24px 0',
                  padding: '0 100px',
                }}
              >
                <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                  确认
                </Button>
                <Button onClick={handleUpdateStageCancel} style={{ padding: '0 36px' }}>
                  取消
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {editingTask && editingTaskParentStage && editTaskVisible && (
        <TaskDetailModal
          visible={editTaskVisible}
          setVisible={setEditTaskVisible}
          _task={editingTask}
          stage={editingTaskParentStage}
          project={project}
          onclose={fetchTaskList}
        />
      )}
    </div>
  );
};

export default TaskList;
