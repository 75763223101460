import * as React from 'react'
import { List } from 'antd'

const ProjectArchive = () => {
  return (
    <div>
      <List dataSource={[]} />
    </div>
  )
}

export default ProjectArchive
