/// <reference path="type.d.ts" />
/// <reference path="location.d.ts" />

import request from './_base'

/**
 * 新建
 * @param  {CreateAddrReq} input
 */
export function createAddr(input: CreateAddrReq): Promise<AssetsResourceProto<{ data: AddrItem }>> {
  return request({
    method: 'POST',
    url: `/addr/addr`,
    data: input,
  }) as any
}

/**
 * 更新
 * @param  {UpdateAddrReq} input
 */
export function updateAddr(input: UpdateAddrReq): Promise<AssetsResourceProto<{ data: AddrItem }>> {
  return request({
    method: 'PUT',
    url: `/addr/addr`,
    data: input,
  }) as any
}

/**
 * 删除
 * @param  {DeleteAddrReq} input
 */
export function deleteAddr(input: DeleteAddrReq): Promise<AssetsResourceProto<{ data: AddrItem }>> {
  return request({
    method: 'DELETE',
    url: `/addr/addr`,
    data: input,
  }) as any
}

/**
 * 查询
 * @param  {AddrItem['addr_id']} addr_id
 */
export function queryAddr(addr_id: AddrItem['addr_id']): Promise<AssetsResourceProto<{ data: AddrItem }>> {
  return request({
    method: 'GET',
    url: `/addr/addr/${addr_id}`,
  }) as any
}

/**
 * 查询List
 * @param  {QueryAddrsListReq} input
 */
export function queryAddrList(
  input: QueryAddrsListReq
): Promise<AssetsResourceProto<{ data: AddrItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/addr/addr/list`,
    data: input,
  }) as any
}

/**
 * 根据资产id查询资产所属的地理位置(如:资产螺栓在地理信息曲线1上)
 * @param  {string} asset_id
 */
export function queryAssetAddrList(asset_id: string): Promise<AssetsResourceProto<{ data: AddrItem[] }>> {
  return request({
    method: 'GET',
    url: `/addr/asset/${asset_id}`,
  }) as any
}

//地理坐标相关

/**
 * 新建
 * @param  {CreateCoordinateReq} input
 */
export function createCoordinate(input: CreateCoordinateReq): Promise<AssetsResourceProto<{ data: CoordinateItem }>> {
  return request({
    method: 'POST',
    url: `/location/coordinate`,
    data: input,
  }) as any
}

/**
 * 更新
 * @param  {UpdateCoordinateReq} input
 */
export function updateCoordinate(input: UpdateCoordinateReq): Promise<AssetsResourceProto<{ data: CoordinateItem }>> {
  return request({
    method: 'PUT',
    url: `/location/coordinate`,
    data: input,
  }) as any
}

/**
 * 删除
 * @param  {DeleteCoordinateReq} input
 */
export function deleteCoordinate(input: DeleteCoordinateReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/location/coordinate`,
    data: input,
  }) as any
}

/**
 * 查询List
 * @param  {BaseListReq} input
 */
export function queryCoordinateList(
  input: BaseListReq
): Promise<AssetsResourceProto<{ data: CoordinateItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/location/coordinate/get`,
    data: input,
  }) as any
}
