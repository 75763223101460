import * as React from 'react';
import { Table, message } from 'antd';
import * as AssetRequests from '@/requests/assets';
import SingleUserCompanyIdTooltip from '@/ui-components/SingleUserCompanyIdTooltip';

const colunms = [
  {
    title: '序号',
    dataIndex: 'order',
    key: 'order',
    align: 'center' as 'center',
    render: (text: any, record: Statushistory, index: number) => `${index + 1}`,
  },
  {
    title: '状态值',
    dataIndex: 'value',
    key: 'value',
    align: 'center' as 'center',
    render: (text: any, record: Statushistory, index: number) => `${record.status_value}`,
  },
  {
    title: '备注',
    dataIndex: 'comment',
    key: 'comment',
    align: 'center' as 'center',
    render: (text: any, record: Statushistory, index: number) => `${record.comment ? record.comment : '无'}`,
  },
  {
    title: '记录日期',
    dataIndex: 'date',
    key: 'date',
    align: 'center' as 'center',
    render: (text: any, record: Statushistory, index: number) => `${record.modify_time}`,
  },
  {
    title: '记录人',
    dataIndex: 'user',
    key: 'user',
    align: 'center' as 'center',
    render: (text: any, record: Statushistory, index: number) => {
      return (
        <span>
          {record.modify_user_name}
          <SingleUserCompanyIdTooltip user_id={record.modify_user_id} />
        </span>
      );
    },
  },
];

const StatusValueHistoryTable = ({ status_id }: { status_id: AssetStatusItem['status_id'] }) => {
  const [data, setData] = React.useState<Statushistory[] | undefined>(undefined);
  const [fetching, setFetching] = React.useState(false);
  const fetchData = async () => {
    if (!status_id) return;
    setFetching(true);
    const res = await AssetRequests.getStatusvalueHistory({ status_id });
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Table dataSource={data} columns={colunms} loading={fetching} size="small" pagination={false} />
    </div>
  );
};

export default StatusValueHistoryTable;
