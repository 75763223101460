import React from 'react'
import { Modal, Input, Form, Select, message, Tooltip, Button } from 'antd'

import * as StandardRequest from '@/requests/standard'
import * as SuggestionRequests from '@/requests/suggestion'

import * as Utils from '@/utils/string'
import { QuestionCircleOutlined } from '@ant-design/icons'

interface parentProps {
  visible: boolean
  setVisible: Function
  doFetch: Function
  userInfo: UserInfoItem
}

let geoInfoList = ['线别', '行别', '起始百米标', '起始轨枕号', '迄止百米标', '迄止轨枕号', '股别', '里外', '前后']
const levelsOptions = ['合格', '优良', '一般', '不合格']

const CreateStandardModal = (props: parentProps) => {
  const { visible, setVisible, doFetch, userInfo } = props
  const [fetching, setFetching] = React.useState(false)

  const [statusNameOptions, setStatusNameOptions] = React.useState<StatusSuggestionItem[]>([])
  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
  const [selectedGeoInfo, setSelectedGeoInfo] = React.useState<string[]>([])
  const [selectedLevels, setSelectedLevels] = React.useState<string[]>([])
  const [selectedStandardName, setSelectedStandardName] = React.useState<string[]>([])
  const formatVirtualAssetComps = (list: string[], getFieldValue: Function) => {
    if (!list) {
      return {}
    }
    const obj = {} as any
    list.map((comp) => {
      obj[`${comp}`] = getFieldValue(comp)
    })
    return obj
  }
  const [form] = Form.useForm()

  const handleOk = async (values: any) => {
    doCreate(values)
  }

  const doCreate = async (values: any) => {
    setFetching(true)
    const res = await StandardRequest.createStandardByForm({
      standard_names: values.standards,
      asset_status_name: JSON.parse(values.asset_status).asset_status,
      status_value_type: values.status_value_type,
      levels: values.levels,
      standards: values.standards.map((x: string) => {
        return {
          standard_name: x,
          ranges: values.levels.map((level: string, l_index: number) => {
            return {
              range_name: level,
              range: values[`${level}(${x})`],
            }
          }),
        }
      }),
      asset_name: values.asset_name,
      asset_comps: values.asset_comps.split(','),
      virtual_asset_name: values.v_asset_name ? values.v_asset_name : '',
      virtual_asset_comps: formatVirtualAssetComps(values.v_asset_comps, form.getFieldValue),
      form_id: `create_standard_form-${Utils.uuid()}`,
      task_id: `create_standard-${Utils.uuid()}`,
      user_id: String(userInfo.user_id),
      user_name: userInfo.username,
    })
    if (res.code === 0) {
      message.success('创建标准成功')
      setVisible(false)
      doFetch()
    } else if (res.code === 10008) {
      message.error('标准值范围格式不正确！')
    } else {
      message.error(res.message)
    }

    setFetching(false)
  }

  const handleCancel = (e: any) => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>创建资产状态标准
        </span>
      }
      visible={visible}
      footer={null}
      destroyOnClose
      onCancel={handleCancel}
    >
      <Form
        form={form}
        onFinish={handleOk}
        preserve={false}
        labelCol={{
          xs: { span: 6 },
          sm: { span: 6 },
        }}
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues)[0] === 'asset_status') {
            const value = changedValues[Object.keys(changedValues)[0]]
            const parseValue: StatusSuggestionItem = JSON.parse(value)
            console.log(value)
            form.setFieldsValue({
              status_value_type: parseValue.status_type,
            })
          }
          if (Object.keys(changedValues)[0] === 'v_asset_comps') {
            const value = changedValues[Object.keys(changedValues)[0]]

            console.log(value)
            setSelectedGeoInfo(value)
          }
          if (Object.keys(changedValues)[0] === 'standards') {
            const value = changedValues[Object.keys(changedValues)[0]]

            console.log(value)
            setSelectedStandardName(value)
          }
          if (Object.keys(changedValues)[0] === 'levels') {
            const value = changedValues[Object.keys(changedValues)[0]]

            console.log(value)
            setSelectedLevels(value)
          }
        }}
      >
        <Form.Item
          label="资产名称"
          name="asset_name"
          rules={[
            {
              required: true,
              message: '请输入资产名称',
            },
          ]}
        >
          <Select
            showSearch
            onSearch={async (value) => {
              if (Utils.isNull(value)) {
                return
              }
              if (value) {
                const res = await SuggestionRequests.getAssetNameSuggestion(value)
                if (res.code === 0) {
                  setAssetNameOptions(res.data)
                } else {
                  message.error('网络错误')
                }
              } else {
                return
              }
            }}
            size="small"
          >
            {assetNameOptions.map((x: string) => {
              return (
                <Select.Option value={x.toString()} key={`asset_name_option:${x}`}>
                  {x}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item label="资产组件" name="asset_comps" rules={[{ required: true, message: '请输入资产名称' }]}>
          <Select
            onDropdownVisibleChange={async (open) => {
              const assetName = form.getFieldValue('asset_name')
              if (open) {
                if (!assetName) {
                  form.validateFields(['asset_name'])
                  return
                }
                const res = await SuggestionRequests.getAssetCompsSuggestion(assetName)
                if (res.code === 0) {
                  setAssetCompsOptions(
                    res.data.map((comps) => {
                      return comps.toString()
                    })
                  )
                } else {
                  message.error('网络错误')
                }
              }
            }}
            size="small"
          >
            {assetCompsOptions.map((x: string) => {
              return (
                <Select.Option value={x} key={`asset_comps_option:${x}`}>
                  {x}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="资产状态"
          rules={[
            {
              required: true,
              message: '请选择资产状态',
            },
          ]}
          name="asset_status"
        >
          <Select
            onDropdownVisibleChange={async (open) => {
              const assetName = form.getFieldValue('asset_name')
              const assetComps = form.getFieldValue('asset_comps')
              if (open) {
                if (!assetName) {
                  form.validateFields(['asset_name'])
                  return
                }
                if (!assetComps) {
                  form.validateFields(['asset_comps'])
                  return
                }
                const res = await SuggestionRequests.getAssetStatusSuggestion({
                  asset_name: assetName,
                  asset_comps: assetComps.split(','),
                })
                if (res.code === 0) {
                  setStatusNameOptions(
                    res.data.map((x: StatusSuggestionItem) => {
                      return x
                    })
                  )
                } else {
                  message.error('网络错误')
                }
              }
            }}
            size="small"
          >
            {statusNameOptions.map((x: StatusSuggestionItem, index) => {
              return (
                <Select.Option value={JSON.stringify(x)} key={`status_name_option:${index}`} k>
                  {x.asset_status}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="状态值类型"
          rules={[{ required: true, message: '请选择状态值类型！' }]}
          name="status_value_type"
        >
          <Select style={{ width: '100%' }} placeholder="请选择状态值类型" disabled size="small">
            <Select.Option key="number" value="数值">
              数值
            </Select.Option>
            <Select.Option key="text" value="文本">
              文本
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="地理名称" name="v_asset_name">
          <Input placeholder="请输入地理名称" size="small" />
        </Form.Item>
        <Form.Item
          label="地点信息"
          rules={[
            {
              required: form.getFieldValue('v_asset_name') ? true : false,
              message: '请选择地点信息！',
            },
          ]}
          name="v_asset_comps"
        >
          <Select mode="tags" style={{ width: '100%' }} placeholder="请选择地点信息" size="small">
            {geoInfoList.map((item, index) => {
              return (
                <Select.Option key={item.toString() + index} value={item}>
                  {item}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        {selectedGeoInfo.map((item, index) => {
          return (
            <Form.Item
              label={item}
              key={item + index}
              name={item}
              rules={[{ required: true, message: `请输入${item}！` }]}
            >
              <Input placeholder="请输入值" size="small" />
            </Form.Item>
          )
        })}
        <Form.Item label="标准名称" name="standards" rules={[{ required: true, message: '请输入标准名称' }]}>
          <Select mode="tags" style={{ width: '100%' }} placeholder="请选择或输入标准名称" size="small"></Select>
        </Form.Item>
        <Form.Item label="等级分类" name="levels" rules={[{ required: true, message: '请输入等级分类' }]}>
          <Select mode="tags" style={{ width: '100%' }} placeholder="请选择或输入等级分类" size="small">
            {levelsOptions.map((item) => {
              return (
                <Select.Option key={item.toString()} value={item}>
                  {item}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        {selectedStandardName.map((standard) => {
          return selectedLevels.map((level) => {
            return (
              <Form.Item
                label={
                  <span>
                    {level}({standard})&nbsp;
                    <Tooltip title="数字请使用半角符号 “[”、“(”、“)”、“]”和并集“U”，字符请使用半角符号 “[”和“]”及用“,”隔开字符,示例:数值型:[1,2]U(3,5];文本型: [红,白,黄]">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                key={`${level}(${standard})`}
                name={`${level}(${standard})`}
              >
                <Input placeholder="请输入值范围" size="small" />
              </Form.Item>
            )
          })
        })}
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateStandardModal
