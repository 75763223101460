import * as React from 'react';

import { message, Modal, Button, Input, Empty, Divider, Table, Form, Select } from 'antd';
import * as AddrRequests from '@/requests/location';
import '../styles/index.less';
import CreateAddrModal from '../components/CreateAddrModal';
import UpdateAddrModal from '../components/UpdateAddrModal';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

const AddrList = () => {
  const [data, setData] = React.useState<AddrItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [createAddrModalVisible, setCreateAddrModalVisible] = React.useState(false);
  const [filterAddrs, setFilterAddrs] = React.useState<BaseAddrs[]>([]);
  const [editingAddr, setEditingAddr] = React.useState<AddrItem | undefined>(undefined);
  const [updateAddrModalVisible, setUpdateAddrVisible] = React.useState(false);
  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [filterAddrName, setFilterAddrName] = React.useState('');
  const [filterAddrStatus, setFilterAddrStatus] = React.useState<BaseAddrStatus[]>([]);

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    const res = await AddrRequests.queryAddrList({
      limit: pageSize,
      skip: pageSize * (page - 1),
      ...(filterAddrs.length > 0 && {
        addr_comps: filterAddrs,
      }),
      ...(filterAddrName && {
        addr_name: filterAddrName,
      }),
      ...(filterAddrStatus.length > 0 && {
        addr_statuses: filterAddrStatus,
      }),
    });
    if (res.code === 0) {
      if (res.data === null) {
        setLoading(false);
        return;
      }
      const vLengthList = res.data.map((x) => x.addr_comps.length);
      vLengthList.sort((a, b) => b - a);

      setMaxVStatusCount(vLengthList[0]);
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      });
      setData(res.data);
    } else {
      setData([]);
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const colunms = [
    {
      title: '序号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => `${index + 1}`,
    },
    {
      title: '地理状态名',
      dataIndex: 'addr_status_name',
      key: 'addr_status_name',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => `${record.addr_status_name}`,
    },
    {
      title: '地理状态值',
      dataIndex: 'addr_status_value',
      key: 'addr_status_value',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => `${record.addr_status_value}`,
    },
    {
      title: '状态值类型',
      dataIndex: 'addr_status_type',
      key: 'addr_status_type',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => `${record.addr_status_type}`,
    },
    {
      title: '地理名称',
      dataIndex: 'addr_name',
      key: 'addr_name',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => `${record.addr_name}`,
    },
    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((vcomp, index) => {
        return {
          title: `地理组件${index + 1}`,
          key: `vcomps-${index}`,
          align: 'center' as 'center',
          render: (_: any, record: AddrItem, _index: number) => {
            const { addr_comps } = record;
            let value = addr_comps[index];
            return value ? `${value.addr_comp_name}:${value.addr_comp_value}` : '';
          },
        };
      }),
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => `${record.create_time}`,
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center' as 'center',
      render: (text: any, record: AddrItem, index: number) => {
        return (
          <span>
            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                setEditingAddr(record);
                setUpdateAddrVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />

            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: '提示',
                  content: '确认删除该地点信息吗？',
                  okText: '确认',
                  onOk: async () => {
                    const { addr_uid } = record;
                    const res = await AddrRequests.deleteAddr({
                      addr_uid,
                    });
                    if (res.code === 0) {
                      message.success('删除成功！');
                      fetchData();
                    } else {
                      message.error(res.message);
                    }
                  },
                  cancelText: '取消',
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];

  return (
    <div className="addr-list-page">
      <div className="tools-wrapper">
        <div className="addr-list-filter-input-container">
          <Form layout="inline">
            <Form.Item label="地理名称" className="filter-item">
              <Input
                value={filterAddrName}
                placeholder="输入地理名称查询"
                onChange={(e) => {
                  setFilterAddrName(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item label="地理状态名" className="filter-item">
              <Select
                mode="tags"
                style={{ minWidth: 200 }}
                value={filterAddrStatus.map((x) => x.addr_status_name)}
                onChange={(value: string[]) => {
                  const arr: BaseAddrStatus[] = value.map((x) => {
                    let addr_status_value = '';
                    let org_obj = filterAddrStatus.find((item) => item.addr_status_name === x);
                    if (org_obj) {
                      addr_status_value = org_obj.addr_status_value.toString();
                    }
                    return {
                      addr_status_name: x,
                      addr_status_value: addr_status_value,
                    };
                  });
                  setFilterAddrStatus(arr);
                }}
                placeholder="输入地理状态名查询"
              ></Select>
            </Form.Item>
            <Form.Item label="地理组件" className="filter-item">
              <Select
                mode="tags"
                style={{ minWidth: 200 }}
                value={filterAddrs.map((x) => x.addr_comp_name)}
                onChange={(value: string[]) => {
                  const arr: BaseAddrs[] = value.map((x) => {
                    let addr_value = '';
                    let org_obj = filterAddrs.find((item) => item.addr_comp_name === x);
                    if (org_obj) {
                      addr_value = org_obj.addr_comp_value;
                    }
                    return {
                      addr_comp_name: x,
                      addr_comp_value: addr_value,
                    };
                  });
                  setFilterAddrs(arr);
                }}
                placeholder="输入地理组件查询"
              ></Select>
            </Form.Item>
          </Form>
          {filterAddrStatus.length > 0 && (
            <Form layout="inline" title={'地理状态值'}>
              {filterAddrStatus.map((status, index) => {
                return (
                  <Form.Item label={status.addr_status_name} key={'filter_addr_status' + index} className="filter-item">
                    <Input
                      onChange={(e) => {
                        let arr = filterAddrStatus.map((x) => x);
                        arr[index].addr_status_value = e.target.value;
                        setFilterAddrStatus(arr);
                      }}
                    />
                  </Form.Item>
                );
              })}
            </Form>
          )}
          {filterAddrs.length > 0 && (
            <Form layout="inline" title={'地理组件'}>
              {filterAddrs.map((add, index) => {
                return (
                  <Form.Item label={add.addr_comp_name} key={'filter_addr' + index} className="filter-item">
                    <Input
                      onChange={(e) => {
                        let arr = filterAddrs.map((x) => x);
                        arr[index].addr_comp_value = e.target.value;
                        setFilterAddrs(arr);
                      }}
                    />
                  </Form.Item>
                );
              })}
            </Form>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <Button type="primary" onClick={() => fetchData()} icon={<SearchOutlined />}>
            搜索
          </Button>

          <Button icon={<PlusOutlined />} onClick={() => setCreateAddrModalVisible(true)} style={{ marginLeft: 12 }}>
            新建
          </Button>
        </div>
      </div>

      <div>
        <Table
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
          dataSource={data}
          columns={colunms}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              setPagination({ ...pagination, page: page });
              fetchData(page, pagesize);
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            style: { marginRight: 16 },
            showQuickJumper: true,
          }}
          locale={{
            emptyText: (
              <Empty description="暂无地点信息">
                <Button type="primary" onClick={() => setCreateAddrModalVisible(true)}>
                  创建地理信息
                </Button>
              </Empty>
            ),
          }}
          style={{ backgroundColor: '#ffffff' }}
          loading={loading}
          rowKey={(record) => record.addr_uid.toString()}
        ></Table>
      </div>
      {createAddrModalVisible && (
        <CreateAddrModal
          visible={createAddrModalVisible}
          setVisible={setCreateAddrModalVisible}
          fetchData={fetchData}
        />
      )}
      {updateAddrModalVisible && editingAddr && (
        <UpdateAddrModal
          visible={updateAddrModalVisible}
          setVisible={setUpdateAddrVisible}
          data={editingAddr}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default AddrList;
