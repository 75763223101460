import * as React from 'react'
import { Form, Input, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import * as formRequests from '@/requests/form'

interface propsType {
  setCurrent: Function
  docreateFormTemplate: (input: CreateFormTemplateReq) => void
}
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

const CreateFreeTemplateStepOne = (props: propsType) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const handleSubmit = (values: any) => {
    console.log('Received values of form: ', values)
    props.setCurrent(1)
    props.docreateFormTemplate({
      form_name: values['form_name'],
      form_template_id: values['form_template_id'],
      version_name: values['version_name'],
      description: values['description'],
      fields: [],
    })
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Form onFinish={handleSubmit} {...formItemLayout} style={{ width: 600 }} preserve={false} form={form}>
        <Form.Item
          label="表单名称"
          name="form_name"
          rules={[
            { required: true, message: '请输入表单名称！' },
            {
              validator: async (rule, value, callback) => {
                if (!value) return
                const res = await formRequests.validateDuplication({
                  form_name: value,
                })
                if (res.code === 0) {
                  return Promise.resolve()
                } else if (res.code === 10025) {
                  return Promise.reject('该表单已存在')
                } else {
                  message.error(res.message)
                }
              },
            },
          ]}
        >
          <Input placeholder="表单名称" />
        </Form.Item>
        <Form.Item label="表单描述" name="description" rules={[{ required: true, message: '请输入表单描述' }]}>
          <Input placeholder="表单描述" />
        </Form.Item>
        <Form.Item
          label="表单编号"
          name="form_template_id"
          rules={[
            { required: true, message: '请输入表单编号！' },
            {
              validator: async (rule, value, callback) => {
                if (!value) return
                const res = await formRequests.validateDuplication({
                  form_template_id: value,
                })
                if (res.code === 0) {
                  return Promise.resolve()
                } else if (res.code === 10025) {
                  return Promise.reject('该表单编号已存在')
                } else {
                  message.error(res.message)
                }
              },
            },
          ]}
        >
          <Input placeholder="表单编号" />
        </Form.Item>
        <Form.Item
          label="版本号"
          name="version_name"
          rules={[
            { required: true, message: '请输入版本号！' },
            {
              validator: async (rule, value) => {
                if (!value) return
                const res = await formRequests.validateDuplication({
                  version_name: value,
                })
                if (res.code === 0) {
                  return Promise.resolve()
                } else if (res.code === 10025) {
                  return Promise.reject('该版本号已存在')
                } else {
                  message.error(res.message)
                }
              },
            },
          ]}
        >
          <Input placeholder="版本号" />
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: 36,
            }}
          >
            <Button
              onClick={() => {
                history.goBack()
              }}
              style={{ marginRight: 16 }}
            >
              取消
            </Button>

            <Button type="primary" htmlType={'submit'}>
              下一步
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CreateFreeTemplateStepOne
