import React, { useEffect } from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { useCtxState } from '@/App.store';
import * as UserRequests from '@/requests/user';
import { useWindowSize } from '@/react-helpers/hooks/xom';
import Sidebar from '@/ui-layouts/sidebar';
import Content from '@/ui-layouts/content';
import Header from '@/ui-layouts/header';
import { ROUTE_CONFIG } from '@/ui-routes/router';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import './index.less';
import { Watermark } from 'antd-v5';

const Workbench = () => {
  const [{ sidebarCollapsed = false, showHeader = true, userInfo }, dispatch] = useCtxState();
  const [loading, setLoading] = React.useState(true);
  const { width: wWidth = Infinity } = useWindowSize();
  //获取系统用户列表
  const fetchUsers = async () => {
    const res = await UserRequests.getAllUsers({
      limit: 100000,
      skip: 0,
      username: '',
    });
    if (res.code === 0) {
      dispatch({
        type: 'SAVE_SYS_USERS',
        users: res.data,
      });
    }
  };
  //获取用户信息
  const fetchUserInfo = async () => {
    setLoading(true);
    const res = await UserRequests.info();
    if (res.code === 0) {
      dispatch({
        type: 'SAVE_INFO',
        info: res.data,
      });
      fetchUsers();
      const authRes = await UserRequests.getUserSysMenuInfo(res.data.id);
      if (authRes.code === 0) {
        dispatch({
          type: 'SAVE_USER_SYSMENU',
          info: authRes.data,
        });
      }
    } else {
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  React.useLayoutEffect(() => {
    const nextCollapsed = wWidth <= 960;
    if (nextCollapsed === sidebarCollapsed) return;
    dispatch({
      type: 'toggle:sidebarCollapsed',
      sidebarCollapsed: nextCollapsed,
    });
  }, [wWidth]);

  return loading ? (
    <Spin style={{ position: 'fixed', top: '50%', left: '50%' }} tip="加载中" />
  ) : (
    <Layout className={['workbench-page', process.env.REACT_APP_SHY && 'shy'].filter((x) => x).join(' ')}>
      <Sidebar />
      <Layout>
        {showHeader && <Header />}
        <Watermark content={userInfo ? userInfo.username + ' ' + userInfo.company_user_id : ''}>
          <Content useStickyContainter>
            <CacheSwitch>
              {ROUTE_CONFIG.map((route) => {
                return (
                  <CacheRoute
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    cacheKey={route.path}
                  />
                );
              })}
            </CacheSwitch>
          </Content>
        </Watermark>
        <Route path="/workbench" exact>
          <Redirect to="/workbench/assets-management/assets-list" />
        </Route>
      </Layout>
    </Layout>
  );
};

export default withRouter(Workbench);
