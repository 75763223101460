/// <reference path="type.d.ts" />
/// <reference path="searchCondition.d.ts" />

import request from './_base'

/**
 * 创建搜索条件
 * @param  {CreateConditionReq} input
 */
export function createCondition(input: CreateConditionReq): Promise<AssetsResourceProto<{ data: ConditionItem }>> {
  return request({
    method: 'POST',
    url: `/search/condition/new`,
    data: input,
  }) as any
}

/**
 * 获取本人的搜索条件list
 * @param  {BaseListReq} input
 */
export function getMyConditionList(
  input: BaseListReq
): Promise<AssetsResourceProto<{ data: ConditionItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/search/condition/list`,
    data: input,
  }) as any
}

/**
 * 删除本人的搜索条件
 * @param  {number} id
 */
export function delMyConditionItem(id: number): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/search/condition/del/${id}`,
  }) as any
}

/**
 * 分享本人的搜索条件
 * @param  {number} id
 */
export function shareMyConditionItem(id: number): Promise<AssetsResourceProto<{ data: PublicConditionItem }>> {
  return request({
    method: 'GET',
    url: `/search/condition/share/${id}`,
  }) as any
}

/**
 * 获取公共的搜索条件list
 * @param  {getPublicConditionListReq} input
 */
export function getPublicConditionList(
  input: getPublicConditionListReq
): Promise<AssetsResourceProto<{ data: PublicConditionItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/search/condition/share/list`,
    data: input,
  }) as any
}

/**
 * 根据公共搜索条件id将公共的搜索条件拉下来个人
 * @param  {number} id
 */
export function importConditionFromPublic(id: number): Promise<AssetsResourceProto<{ data: ConditionItem[] }>> {
  return request({
    method: 'GET',
    url: `/search/condition/import/${id}`,
  }) as any
}

/**
 * 搜索条件名唯一性校验 code===70014为重复
 * @param  {{condition_name:string}} input
 */
export function validateConditionName(input: { condition_name: string }): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/search/condition/name/unique`,
    data: input,
  }) as any
}

/**
 * 更新搜索条件
 * @param  {UpdateConditionItem} input
 */
export function updateConditionItem(
  input: UpdateConditionItem
): Promise<AssetsResourceProto<{ data: ConditionItem[] }>> {
  return request({
    method: 'PUT',
    url: `/search/condition/update`,
    data: input,
  }) as any
}

/**
 * 删除公共的搜索条件 (只有创建者拥有权限删除)
 * @param  {number} id
 */
export function delPublicConditionItem(id: number): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/search/condition/share/del/${id}`,
  }) as any
}
