export function uniquify(arr) {
  if (!Array.isArray(arr)) return

  return Array.from(new Set(arr))
}

export function findMax(arr) {
  if (!Array.isArray(arr)) return
  const copyArr = arr.map((x) => x)
  copyArr.sort((a, b) => b.status_value - a.status_value)
  if (copyArr[0].status_value <= 1 && copyArr[0].status_value > 0) {
    return 1
  }
  if (copyArr[0].status_value < 0) {
    return 0
  }
  return copyArr[0].status_value
}
export function findMin(arr) {
  if (!Array.isArray(arr)) return
  const copyArr = arr.map((x) => x)
  copyArr.sort((a, b) => a.status_value - b.status_value)
  return copyArr[0].status_value
}

export function chunkByInterval(arr, interval) {
  if (interval === 0) return
  if (arr.length === 0) return []
  let [start, end, result] = [null, null, []]
  start = Number(
    arr.sort((a, b) => {
      return Number(a.v_asset_status_value) - Number(b.v_asset_status_value)
    })[0].v_asset_status_value
  )
  end = Number(
    arr.sort((a, b) => Number(a.v_asset_status_value) - Number(b.v_asset_status_value))[arr.length - 1]
      .v_asset_status_value
  )
  const range = end - start

  if (range < interval) {
    result.push(arr.map((x) => x.asset_status_value))
    return result
  }
  const time = Math.ceil((end - start) / interval)

  for (let i = 1; i <= time; i++) {
    let filteredArr = arr.filter((x) => x.v_asset_status_value >= start && x.v_asset_status_value <= start + interval)
    if (filteredArr.length > 0) {
      result.push(filteredArr.map((x) => x.asset_status_value))
    }

    start += interval
  }

  return result
}

export function getAxisLable(arr, interval) {
  if (interval === 0) return []
  if (arr.length === 0) return []
  let [start, end, result] = [null, null, []]
  start = Number(arr[0].v_asset_status_value)
  end = Number(arr[arr.length - 1].v_asset_status_value)
  const range = end - start
  if (range < interval) {
    result.push(`${start}~${end}`)
    return result
  }
  const time = Math.ceil((end - start) / interval)
  for (let i = 1; i <= time; i++) {
    let filteredArr = arr.filter((x) => x.v_asset_status_value >= start && x.v_asset_status_value <= start + interval)
    if (filteredArr.length > 0) {
      result.push(`${filteredArr[0].v_asset_status_value}~${filteredArr[filteredArr.length - 1].v_asset_status_value}`)
    }

    start += interval
  }
  return result
}

//数组去重(不包含NaN)
export function filterRepeat(arr) {
  let new_arr = []
  arr.map((x) => {
    if (new_arr.indexOf(x) === -1) {
      new_arr.push(x)
    }
  })
  return new_arr
}

//多个数组求交集
export function intersection(arrs) {
  if (arrs.length === 0) return []
  return Array.from(new Set(arrs.reduce((pre, cur) => pre.filter((x) => cur.includes(x)))))
}

// 多个数组求差集
export function differenceSet(data) {
  let res = []
  if (data.length === 0 || data.length === 1) return []
  let baseRes = data[0].concat(data[1]).filter((x) => !data[0].includes(x) || !data[1].includes(x))
  if (data.length === 2) return baseRes
  let jiaoji = intersection(data)
  let bingji = Array.from(new Set(data.reduce((pre, cur) => pre.concat(cur))))
  res = jiaoji.concat(bingji).filter((x) => !jiaoji.includes(x) || !bingji.includes(x))
  console.log(jiaoji, bingji)
  return res
}

// 多个数组求并集
export function arrSet(data) {
  let res = []
  if (data.length === 0 || !Array.isArray(data)) {
    return []
  }
  if (data.length === 1) {
    return data[0]
  }
  res = Array.from(new Set(data.reduce((pre, cur) => pre.concat(cur))))
  return res
}

/**
 * 数组转树  递归(部门管理用)
 */
export function toTree(list, parId) {
  let len = list.length
  function loop(parId) {
    let res = []
    for (let i = 0; i < len; i++) {
      let item = list[i]
      if (item.parent_id === parId) {
        item.children = loop(item.sys_org_id)
        res.push(item)
      }
    }
    return res
  }
  return loop(parId)
}

/**
 * 判断是否是数组
 */
export function isArray(arr) {
  return Object.prototype.toString.call(arr) == '[object Array]'
}
