import * as React from 'react'
import './index.less'
import {
  Button,
  PageHeader,
  message,
  Spin,
  Tabs,
  Form,
  Input,
  Radio,
  Tag,
  Avatar,
  Upload,
  List,
  Modal,
  Space,
} from 'antd'
import { HomeOutlined, LeftOutlined } from '@ant-design/icons'
import { useHistory, withRouter, Route } from 'react-router-dom'
import {
  info,
  updateUserInfo,
  getModifyPasswordSmsCode,
  modifyPassword,
  checkEmailDuplicate,
  checkPhoneDuplicate,
  getUpdateUserInfoSmsCode,
} from '@/requests/user'
import { getUserOrgInfo } from '@/requests/role'
import UploadAvatarWidget from '@/ui-components/UploadAvatarWidget'
import { encryptPwd } from '@/utils/string'
const { TabPane } = Tabs

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
}

interface UserinfoFormValues {
  username: string
  company: string
  company_user_id: string
  gender: string
}

interface ModifyPasswordFormValues {
  old_password: string
  password: string
  confirm: string
  sm_code: string
}

const UserInfoCenter = () => {
  const [infoForm] = Form.useForm()
  const history = useHistory()
  const [userInfo, setUserInfo] = React.useState<UserInfoItem | undefined>(undefined)
  const [userOrgInfo, setUserOrgInfo] = React.useState<OrgItem[]>([])
  const [fetching, setFetching] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [avatar, setAvatar] = React.useState<any>(null)
  const [modalVisible, setModalVisible] = React.useState(false)
  const [updateEmailVisible, setUpdateEmailVisible] = React.useState(false)
  const [updatePhoneVisible, setUpdatePhoneVisible] = React.useState(false)
  const [hasSendSms, setHasSendSms] = React.useState(false)
  const [passwordForm] = Form.useForm()
  const [updateEmailForm] = Form.useForm()
  const [updatePhoneForm] = Form.useForm()
  let timer = 60

  const data = [
    {
      title: '账户密码',
      description: '密码等级：强',
      actions: [
        <Button
          style={{ padding: '0 36px' }}
          onClick={() =>
            history.push('/userinfo-center/modify-password', {
              userInfo: userInfo,
            })
          }
        >
          修改
        </Button>,
      ],
    },
    {
      title: '手机号码',
      description: userInfo
        ? `已绑定手机：${userInfo.phone.slice(0, 3) + '****' + userInfo.phone.slice(7)}`
        : '未绑定手机号',
      actions: [
        <Button
          style={{ padding: '0 36px' }}
          onClick={(e) => {
            setUpdatePhoneVisible(true)
          }}
        >
          修改
        </Button>,
      ],
    },
    {
      title: '电子邮箱',
      description: userInfo
        ? `已绑定邮箱：${userInfo.email.slice(0, 3) + '****' + userInfo.email.slice(7)}`
        : '未绑定邮箱',
      actions: [
        <Button
          style={{ padding: '0 36px' }}
          onClick={(e) => {
            setUpdateEmailVisible(true)
          }}
        >
          修改
        </Button>,
      ],
    },
  ]

  const doFetch = async () => {
    const res = await info()
    if (res.code === 0) {
      setUserInfo(res.data)
    } else if (res.code === 20001) {
      message.error('未登录')
      history.replace('/login')
    } else {
      message.error(res.message)
    }
    const orgInfo = await getUserOrgInfo()
    if (orgInfo.code === 0) {
      setUserOrgInfo(orgInfo.data)
    } else {
      message.error('获取用户部门信息失败')
    }
    setLoading(false)
  }
  React.useEffect(() => {
    setLoading(true)
    doFetch()
  }, [])

  const doUpdateUserInfo = async (values: UserinfoFormValues) => {
    if (!userInfo) return
    const res = await updateUserInfo({
      ...values,
    })
    if (res.code === 0) {
      message.success('修改成功')
      doFetch()
    } else {
      message.error(res.message)
    }
  }

  const handleSave = (values: UserinfoFormValues) => {
    if (!values.username) {
      message.error('用户名不能为空')
      return
    }
    if (!values.company_user_id) {
      message.error('工号不能为空')
      return
    }
    if (!values.company) {
      message.error('公司名不能为空')
      return
    }
    if (!values.gender) {
      message.error('性别不能为空')
      return
    }
    doUpdateUserInfo(values)
  }

  const doModifyPassword = async (input: { old_password: string; password: string; sm_code: string }) => {
    if (!input) return

    setFetching(true)
    const res = await modifyPassword({
      ...input,
    })

    if (res.code === 0) {
      message.success('修改密码成功')

      setTimeout(() => {
        message.info('您的密码已修改，请重新登录')
        history.replace('/login')
      }, 1000)
    } else if (res.code === 400) {
      message.error(res.message)
    } else if (res.code === 20001) {
      message.error('登陆过时，请重新登录！')
      history.replace('/login')
    } else if (res.code === 20002) {
      message.error('原密码错误！')
    } else if (res.code === 10003) {
      message.error('验证码错误！')
    } else {
      message.info(res.message)
    }
  }

  const handleModifyPassword = (values: ModifyPasswordFormValues) => {
    const { old_password, password, sm_code } = values
    doModifyPassword({
      old_password: encryptPwd(old_password),
      password: encryptPwd(password),
      sm_code,
    })
  }

  const doUpdateEmail = async (email: string) => {
    if (!userInfo) return
    setFetching(true)
    const res = await updateUserInfo({
      company: userInfo.company,
      company_user_id: userInfo.company_user_id,
      email,
      gender: userInfo.gender,
      username: userInfo.username,
    })
    if (res.code === 0) {
      message.success('修改成功')
      setUpdateEmailVisible(false)
      doFetch()
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }

  const doUpdatePhone = async (values: { phone: string; sm_code: string }) => {
    if (!userInfo) return
    setFetching(true)
    const res = await updateUserInfo(values)
    if (res.code === 0) {
      message.success('修改成功')
      setUpdatePhoneVisible(false)
      doFetch()
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }

  const handleEmailModalOk = (values: { email: string }) => {
    doUpdateEmail(values.email)
  }
  const handlePhoneModalOk = (values: { phone: string; sm_code: string }) => {
    doUpdatePhone(values)
  }

  const UpdateEmailForm = () => {
    return (
      <Form form={updateEmailForm} onFinish={handleEmailModalOk}>
        <Form.Item
          name="email"
          label="电子邮箱"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: '请输入您的电子邮箱',
            },
            {
              validator: async (_, value) => {
                if (!value) return
                const res = await checkEmailDuplicate({
                  email: value,
                })
                if (res.code === 0) {
                  return Promise.resolve()
                } else if (res.code === 20012) {
                  return Promise.reject('该邮箱已被注册！')
                }
              },
            },
            {
              type: 'email',
              message: '请输入正确格式的邮箱',
            },
          ]}
        >
          <Input style={{ width: 300 }} placeholder="邮箱" />
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 24 }}>
            <Button type="primary" style={{ width: 130 }} htmlType={'submit'} loading={fetching}>
              确认
            </Button>
            <Button style={{ width: 130 }} onClick={() => setUpdateEmailVisible(false)}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    )
  }
  const UpdatePhoneForm = () => {
    if (!userInfo) return null
    return (
      <Form form={updatePhoneForm} layout="vertical" onFinish={handlePhoneModalOk}>
        <Form.Item
          name="phone"
          label="新手机号"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: '请输入您的新手机号',
            },
            {
              validator: async (_, value) => {
                if (!value) return
                const res = await checkPhoneDuplicate({
                  phone: value,
                })
                if (res.code === 0) {
                  return Promise.resolve()
                } else if (res.code === 20011) {
                  return Promise.reject('该手机号已被注册！')
                }
              },
            },
            {
              len: 11,
              message: '请输入正确格式的手机号',
            },
          ]}
        >
          <Input style={{ width: 300 }} placeholder="请输入新手机号" />
        </Form.Item>
        <Form.Item
          name="sm_code"
          label="请输入手机验证码"
          validateTrigger="onBlur"
          rules={[{ required: true, message: '请输入手机短信验证码' }]}
        >
          <Input
            style={{ width: 300 }}
            placeholder="手机验证码"
            addonAfter={
              <span
                id="send-msm"
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: hasSendSms ? '#A3B5C6' : '#F39800',
                  cursor: hasSendSms ? 'progress' : 'pointer',
                }}
                onClick={async () => {
                  if (hasSendSms) return
                  const phone = updatePhoneForm.getFieldValue('phone')

                  if (!phone) {
                    updatePhoneForm.validateFields(['phone'])
                    return
                  }
                  const res = await getUpdateUserInfoSmsCode({
                    phone,
                  })
                  if (res.code === 0) {
                    message.success('已成功向您的手机发送验证码，请注意查收!')
                    setHasSendSms(true)
                    const timers = setInterval(() => {
                      const btn = document.getElementById('send-msm') as any
                      if (!btn) {
                        clearInterval(timers)
                        return
                      }
                      timer -= 1
                      btn.innerText = `${timer}s重新获取`
                      if (timer === 0) {
                        setHasSendSms(false)
                        clearInterval(timers)
                      }
                    }, 1000)
                  } else if (res.code === 10020) {
                    message.error('图形验证码错误！')
                  } else {
                    message.error(res.message)
                  }
                }}
              >
                {hasSendSms ? `${timer}s重新获取` : '获取验证码'}
              </span>
            }
          />
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 24 }}>
            <Button type="primary" style={{ width: 130 }} htmlType={'submit'} loading={fetching}>
              确认
            </Button>
            <Button style={{ width: 130 }} onClick={() => setUpdatePhoneVisible(false)}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    )
  }
  return (
    <div className="userinfo-center">
      <div className="header">
        <PageHeader
          title={<span className="header-title">账号设置</span>}
          extra={[
            <Button
              icon={<HomeOutlined />}
              onClick={() => {
                history.replace('/')
              }}
            >
              主页
            </Button>,
            <Button icon={<LeftOutlined />} onClick={() => history.goBack()}>
              返回
            </Button>,
          ]}
          style={{ paddingBottom: 40 }}
        />
      </div>
      <div className="content">
        <Route path="/userinfo-center" exact>
          {userInfo && (
            <div className="avatar">
              {userInfo.avatar ? (
                <Avatar size={100} src={`v2/api/user/avatar/${userInfo.avatar}`} />
              ) : (
                <Avatar size={100}>{userInfo.username}</Avatar>
              )}
            </div>
          )}
          <Spin spinning={loading} tip="加载中..." style={{ position: 'absolute', left: '50%', top: '50%' }} />
          {avatar && (
            <UploadAvatarWidget
              setAvatar={setAvatar}
              setModalVisible={setModalVisible}
              visible={modalVisible}
              file={avatar}
              userInfo={userInfo as UserInfoItem}
              doFetch={doFetch}
            />
          )}
          <Modal
            visible={updateEmailVisible}
            title="修改邮箱"
            footer={null}
            onCancel={() => setUpdateEmailVisible(false)}
            bodyStyle={{ display: 'flex', justifyContent: 'center' }}
            destroyOnClose
          >
            <UpdateEmailForm />
          </Modal>

          <Modal
            visible={updatePhoneVisible}
            title="修改手机号"
            footer={null}
            bodyStyle={{ display: 'flex', justifyContent: 'center' }}
            onCancel={() => {
              setUpdatePhoneVisible(false)
              setHasSendSms(false)
            }}
            destroyOnClose
          >
            <UpdatePhoneForm />
          </Modal>
          <Tabs defaultActiveKey="1" style={{ marginLeft: 190 }} tabBarStyle={{ border: 'none' }} tabBarGutter={16}>
            <TabPane tab="基本信息" key="1">
              <div className="base-info">
                <div className="avatar-uploader">
                  <Upload
                    action="v2/api/user/avatar"
                    showUploadList={false}
                    beforeUpload={async (file, fileList) => {
                      setAvatar(file)
                      setModalVisible(true)
                      return Promise.reject(file) as any
                    }}
                  >
                    <Button style={{ width: 100 }}>修改头像</Button>
                  </Upload>
                </div>
                {userInfo && userOrgInfo && (
                  <Form
                    form={infoForm}
                    {...layout}
                    onFinish={handleSave}
                    colon={false}
                    initialValues={{
                      username: userInfo.username,
                      company: userInfo.company,
                      company_user_id: userInfo.company_user_id,
                      gender: userInfo.gender,
                      create_time: userInfo.createdat,
                    }}
                    style={{ width: 500, marginTop: 36 }}
                  >
                    <Form.Item name="username" label="用户名">
                      <Input />
                    </Form.Item>
                    <Form.Item name="company" label="公司名">
                      <Input />
                    </Form.Item>
                    <Form.Item name="company_user_id" label="工号">
                      <Input />
                    </Form.Item>
                    <Form.Item name="gender" label="性别">
                      <Radio.Group>
                        <Radio value={'male'}>男</Radio>
                        <Radio value={'female'}>女</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="职位">
                      <Space wrap>
                        {userInfo.roles.length > 0 ? userInfo.roles.map((x) => <Tag color="blue">{x.name}</Tag>) : '无'}
                      </Space>
                    </Form.Item>
                    <Form.Item label="所属部门">
                      <Space wrap>
                        {userOrgInfo.length > 0
                          ? userOrgInfo.map((x) => <Tag color="volcano">{x.org_name}</Tag>)
                          : '无'}
                      </Space>
                    </Form.Item>
                    <Form.Item label="注册时间" name="create_time">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType={'submit'} type="primary" style={{ padding: '0 36px', marginTop: 24 }}>
                        保存
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </div>
            </TabPane>
            <TabPane tab="安全设置" key="2">
              <List
                itemLayout="horizontal"
                dataSource={data}
                style={{ width: 650, padding: '24px 0' }}
                renderItem={(item, index) => (
                  <List.Item actions={item.actions} key={index}>
                    <List.Item.Meta title={item.title} description={item.description} />
                  </List.Item>
                )}
              />
            </TabPane>
          </Tabs>
        </Route>
        <Route path="/userinfo-center/modify-password">
          {userInfo && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '90px 0',
              }}
            >
              <h2 style={{ marginBottom: 36 }}>修改密码</h2>
              <Form form={passwordForm} onFinish={handleModifyPassword} style={{ width: 300 }}>
                <Form.Item
                  name="old_password"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: '请输入您的原密码！' }]}
                >
                  <Input.Password style={{ width: 300 }} placeholder="原密码" />
                </Form.Item>

                <Form.Item
                  name="password"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的密码!',
                    },
                  ]}
                >
                  <Input.Password style={{ width: 300 }} placeholder="新密码" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  validateTrigger="onBlur"
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: '请确认您的密码!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject('两次输入密码不一致!')
                      },
                    }),
                  ]}
                >
                  <Input.Password style={{ width: 300 }} placeholder="确认新密码" />
                </Form.Item>

                <Form.Item
                  name="sm_code"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: '请输入手机短信验证码' }]}
                  extra={`系统将向手机${userInfo.phone.slice(0, 3) + '****' + userInfo.phone.slice(7)}发送短信`}
                >
                  <Input
                    style={{ width: 300 }}
                    placeholder="手机验证码"
                    addonAfter={
                      <span
                        id="send-msm"
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: hasSendSms ? '#A3B5C6' : '#F39800',
                          cursor: hasSendSms ? 'progress' : 'pointer',
                        }}
                        onClick={async () => {
                          if (hasSendSms) return
                          const phone = userInfo.phone
                          const res = await getModifyPasswordSmsCode({
                            phone,
                          })
                          if (res.code === 0) {
                            message.success('已成功向您的手机发送验证码，请注意查收!')
                            setHasSendSms(true)
                            const timers = setInterval(() => {
                              const btn = document.getElementById('send-msm') as any
                              if (!btn) {
                                clearInterval(timers)
                                return
                              }
                              timer -= 1
                              btn.innerText = `${timer}s重新获取`
                              if (timer === 0) {
                                setHasSendSms(false)
                                clearInterval(timers)
                              }
                            }, 1000)
                          } else if (res.code === 10020) {
                            message.error('图形验证码错误！')
                          } else {
                            message.error(res.message)
                          }
                        }}
                      >
                        {hasSendSms ? `${timer}s重新获取` : '获取验证码'}
                      </span>
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 36 }}>
                    <Button type="primary" style={{ width: 130 }} htmlType={'submit'} loading={fetching}>
                      确认
                    </Button>
                    <Button style={{ width: 130 }} onClick={() => history.goBack()}>
                      取消
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </Route>
      </div>
    </div>
  )
}

export default withRouter(UserInfoCenter)
