import * as React from 'react';
import { Upload, Menu, Dropdown, message, Button, Modal, Image } from 'antd';
import { UpOutlined, DownOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import * as PicRequests from '@/requests/pictures';

const PicPreviewWidget = ({
  pics,
  current_pic,
  current_index,
}: {
  pics: any[];
  current_pic: any;
  current_index: number;
}) => {
  const [visible, setVisible] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  return (
    <>
      <Image
        src={`/v2/api/pic/picture/download/50/${current_pic.uid}`}
        style={{
          height: 50,
          width: 50,
          borderRadius: 4,
        }}
        preview={{ visible: false }}
        onClick={() => {
          setVisible(true);
          setCurrent(current_index);
        }}
      />

      <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis), current: current }}>
          {pics.length !== 0
            ? pics.map((x, index) => {
                return <Image src={`/v2/api/pic/picture/download/50/${x.uid}`} key={x.pic_uid} />;
              })
            : null}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

const DropdownPicWidget = ({
  status,
  upload,
  fetchAssetData,
}: {
  status: AssetStatusItem;
  upload: Function;
  fetchAssetData: Function;
}) => {
  const { status_pics } = status;
  const [visible, setVisible] = React.useState(false);

  const UploadWidget = () => {
    return (
      <Upload
        multiple={true}
        showUploadList={false}
        beforeUpload={(file, fileList) => {
          const checkType =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/tiff' ||
            file.type === 'image/bmp' ||
            file.type === 'image/gif';
          if (!checkType) {
            message.error('只支持上传 JPG/PNG/JEPG/TIFF/BMP/GIF 格式的图片!');
            return false;
          }
          const checkSize = file.size / 1024 / 1024 < 10;
          if (!checkSize) {
            message.error('图片大小不能大于10MB!');
            return false;
          }
          upload(file, status, '');
          return false;
        }}
      >
        <Button size="small" icon={<PlusOutlined />}></Button>
      </Upload>
    );
  };
  const handleDelete = (uid: string) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该图片吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const res = await PicRequests.deletePic(uid);
        if (res.code === 0) {
          message.success('删除成功');
          fetchAssetData();
        } else {
          message.error(res.code);
        }
      },
    });
  };

  if (status_pics.length < 1) {
    return <UploadWidget />;
  }
  const renderItems = (
    <Menu>
      {status_pics.map((pic, index) => {
        return (
          <Menu.Item
            key={pic.uid}
            onClick={(e) => {
              e.domEvent.preventDefault();
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              key={index}
            >
              <PicPreviewWidget pics={status_pics} current_pic={pic} current_index={index} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 5,
                }}
              >
                <UploadWidget />
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(pic.uid)}
                  style={{ marginTop: 2 }}
                ></Button>
              </div>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={renderItems} trigger={['click']} visible={visible}>
      <a
        className="ant-dropdown-link"
        onClick={(e) => {
          e.preventDefault();
          setVisible(!visible);
        }}
      >
        {`共${status.status_pics.length}图`}
        {visible ? <UpOutlined /> : <DownOutlined />}
      </a>
    </Dropdown>
  );
};
export default DropdownPicWidget;
