import React from 'react';
import * as AssetsRequest from '@/requests/assets';
import { useCtxState } from '@/App.store';
import './index.less';
import {
  Table,
  Divider,
  Popconfirm,
  Form,
  Button,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
  Tag,
  Dropdown,
  Menu,
  InputNumber,
  DatePicker,
  Radio,
  Spin,
} from 'antd';
import {
  InfoCircleOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  ImportOutlined,
  UpOutlined,
  DownOutlined,
  SearchOutlined,
  DownloadOutlined,
  RedoOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/zh-cn';
import DrawerAssetEditor from '@/ui-components/DrawerAssetEditor';
import CreateAssetModal from './CreateAssetModal';
import * as UserRequests from '@/requests/user';
import * as SuggestionRequests from '@/requests/suggestion';
import * as strUtils from '@/utils/string';
import { download } from '@/utils/string';
import { arrSet } from '@/utils/array';
import { selectAfterPDF, selectAfterCSV } from '@/ui-components/SelectAfters';
import * as ConditionRequest from '@/requests/searchCondition';
moment.locale('zh-cn');

const noOp = (...args: any[]) => undefined;

function getColumnConfigs<ROW extends AssetItem>({
  onEvent = noOp,
  maxVStatusCount,
  maxCompsCount,
}: {
  onEvent: Function;
  maxVStatusCount: number;
  maxCompsCount: number;
}): any[] {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'order',
      render(_: any, record: ROW, idx: number) {
        return idx + 1;
      },
    },
    {
      title: '资产名',
      align: 'center',
      key: 'asset_name',
      render(_: any, record: ROW) {
        if (!record.asset_name) return null;
        const curName = record.asset_name;
        return (
          <Tooltip title="双击进入资产编辑" key={`asset_name-${record.asset_id}`}>
            <span>{curName}</span>
          </Tooltip>
        );
      },
    },

    ...Array(maxCompsCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `AssetsComs-ColChild-${idx}`;
        return {
          key: key,
          align: 'center',
          title: `资产组件${count}`,
          render(_: any, record: ROW) {
            if (!record.asset_comps) return null;
            const curName = record.asset_comps[idx] ? record.asset_comps[idx] : '-';
            return (
              <Tooltip title="双击进入资产编辑">
                <span>{curName}</span>
              </Tooltip>
            );
          },
        };
      }),

    {
      title: '地理名称',
      align: 'center',
      dataIndex: 'v_assets_name',
      key: 'v_assets_name',
      render(_: any, record: ROW) {
        if (!record.v_asset_name) return null;
        const curName = record.v_asset_name || '未设置';
        return (
          <Tooltip title="双击进入资产编辑">
            <span key={record.toString()}>{curName ? curName : '-'}</span>
          </Tooltip>
        );
      },
    },
    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `GeoInfo-ColChild-${idx}`;
        return {
          key,
          align: 'center',
          title: `地理组件${count}`,
          sorter: (a: ROW, b: ROW) => {
            if (!a.v_asset_status[idx] || !b.v_asset_status[idx]) return;
            return Number(a.v_asset_status[idx].v_status_value) - Number(b.v_asset_status[idx].v_status_value);
          },

          sortDirections: ['descend', 'ascend'],
          render(_: any, record: ROW) {
            let curName = '';
            if (record.v_asset_status[idx]) {
              curName = `${record.v_asset_status[idx].v_status_name}：${record.v_asset_status[idx].v_status_value}`;
            }
            return (
              <Tooltip title="双击进入资产编辑" key={`v_asset_status-${record.asset_id}-${idx}`}>
                <span>{curName ? curName : '-'}</span>
              </Tooltip>
            );
          },
        };
      }),
    ,
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (_: string, record: ROW) => {
        if (!record) return null;
        return (
          <span>
            <span className="span-btn ant-btn-link" onClick={() => onEvent('start:update-asset', record)} key="edit">
              编辑
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title="确定删除这条资产信息么?"
              onConfirm={async () => {
                const res = await AssetsRequest.deleteAsset({
                  asset_id: record.asset_id,
                });
                if (res.code === 0) {
                  message.success('删除成功');
                  onEvent('start:delete-asset-success', record);
                } else {
                  message.error(res.message);
                }
              }}
              okType="danger"
              okText="确定"
              cancelText="不了"
            >
              <span className="span-btn ant-btn-link ant-btn-link__danger" key="delete">
                删除
              </span>
            </Popconfirm>
          </span>
        );
      },
    },
  ].filter((x) => x);
}

const AssetsList = () => {
  const [list, setList] = React.useState<AssetItem[]>([]);
  const [{ userInfo }, dispatch] = useCtxState();
  const [visible, setVisible] = React.useState(false);
  const [assetModalVisible, setAssetModalVisible] = React.useState(false);
  const [excelFileName, setExcelFileName] = React.useState('资产信息');
  const [loadingList, setLoadingList] = React.useState(false);
  const [loadingExcel, setLoadingExcel] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [conditionPagination, setConditionPagination] = React.useState({
    page: 1,
    pageSize: 100000,
    total: 0,
  });
  const [editingAssetUid, setEditingAssetUid] = React.useState<string | null>(null);
  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const [showFilterArea, setShowFilterArea] = React.useState(false);
  const [condition, setCondition] = React.useState<ConditionItem | PublicConditionItem | undefined>(undefined);
  const [conditionList, setConditionList] = React.useState<ConditionItem[]>([]);
  const [PublicConditionList, setPublicConditionList] = React.useState<PublicConditionItem[]>([]);
  const [conditionType, setConditionType] = React.useState(0);
  const [filterConditionName, setFilterConditionName] = React.useState('');
  const [loadingCondition, setLoadingCondition] = React.useState(false);
  const conditionName = React.useRef('');
  // 2020.04.12 添加高级筛选条件
  const [filterAssetIds, setFilterAssetIds] = React.useState<string[]>([]);
  const [filter_assetName, set_filter_assetName] = React.useState<string | undefined>(undefined);
  const [filter_tagName, set_filter_tagName] = React.useState<FilterTagItem[]>([]);
  const [filter_assetComps, set_filter_assetComps] = React.useState<string[] | undefined>(undefined);
  const [filter_vAssetComps, set_filter_vAssetComps] = React.useState<{ status_name: string; status_value: string }[]>(
    []
  );
  const [filter_status_create_time, set_filter_status_create_time] = React.useState<{
    start: string;
    end: string;
    dates: any;
  } | null>(null);

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([]);
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([]);
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([]);
  const [vStatusValueOptions, setVStatusValueOptions] = React.useState<string[]>([]);

  React.useEffect(() => {
    doFetch();
  }, []);

  const fetchConditionList = async ({ page = conditionPagination.page, pageSize = conditionPagination.pageSize }) => {
    setLoadingCondition(true);
    const res = await ConditionRequest.getMyConditionList({
      skip: (page - 1) * pageSize,
      limit: pageSize,
    });
    if (res.code === 0) {
      setConditionList(res.data);
    } else {
      message.error('网络错误');
    }
    setLoadingCondition(false);
  };
  const fetchPublicConditionList = async ({
    page = conditionPagination.page,
    pageSize = conditionPagination.pageSize,
  }) => {
    setLoadingCondition(true);
    const res = await ConditionRequest.getPublicConditionList({
      skip: (page - 1) * pageSize,
      limit: pageSize,
      ...(filterConditionName && {
        condition_name: filterConditionName,
      }),
    });
    if (res.code === 0) {
      setPublicConditionList(res.data);
    } else {
      message.error('网络错误');
    }
    setLoadingCondition(false);
  };

  const doFetch = async (page?: number, pageSize?: number) => {
    setLoadingList(true);
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await AssetsRequest.fetchFilteredAssetList({
      ...(filterAssetIds.length > 0 && {
        asset_ids: filterAssetIds,
      }),
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_tagName &&
        filter_tagName.length > 0 && {
          tags: filter_tagName.map((x) => x),
        }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps && {
        v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
      }),
      ...(filter_vAssetComps && {
        v_asset_status: filter_vAssetComps.map((x) => x.status_value),
      }),
      ...(filter_status_create_time && {
        start_status_create_time: filter_status_create_time.start,
        end_status_create_time: filter_status_create_time.end,
      }),
      skip: (page - 1) * pageSize,
      limit: pageSize,
    });
    if (res.code === 0) {
      const { data, total } = res;
      setList(data);
      const vLengthList = data.map((x) => x.v_asset_comps.length);
      const compsLengthList = data.map((x) => x.asset_comps.length);
      vLengthList.sort((a, b) => b - a);
      compsLengthList.sort((a, b) => b - a);
      setMaxVStatusCount(vLengthList[0]);
      setMaxCopmsCount(compsLengthList[0]);
      setPagination({ current: page, pageSize, total });
    } else {
      message.error(res.message);
    }
    setLoadingList(false);
  };

  const downloadExcel = async ({ total = pagination.total } = {}) => {
    setLoadingExcel(true);
    const res = await AssetsRequest.fetchFilteredAssetListDownload({
      ...(filterAssetIds.length > 0 && {
        asset_ids: filterAssetIds,
      }),
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps && {
        v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
      }),
      ...(filter_vAssetComps && {
        v_asset_status: filter_vAssetComps.map((x) => x.status_value),
      }),
      ...(filter_status_create_time && {
        start_status_create_time: filter_status_create_time.start,
        end_status_create_time: filter_status_create_time.end,
      }),
      ...(filter_tagName &&
        filter_tagName.length > 0 && {
          tags: filter_tagName.map((x) => x),
        }),

      skip: 0,
      limit: total,
    });
    if (res.code === 0) {
      const url = `v2/api/asset_data/v2/advance/download/${res.data}/${excelFileName}`;
      download(url, excelFileName);
    } else {
      message.error(res.message);
    }
    setLoadingExcel(false);
    setVisible(false);
  };

  const downloadQRcodePDF = () => {
    let name = '';
    let size = 10;
    Modal.confirm({
      title: '输入文件名',
      content: (
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
          <Form.Item label="文件名称">
            <Input addonAfter={selectAfterPDF} onChange={(e) => (name = e.target.value)} />
          </Form.Item>
          <Form.Item label="排版格式">
            <Select onChange={(value: number) => (size = value)} defaultValue={10}>
              <Select.Option value={18}>3x6</Select.Option>
              <Select.Option value={10}>2x5</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      ),
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const res = await AssetsRequest.getAssetQRcodePDFFile({
          ...(filterAssetIds.length > 0 && {
            asset_ids: filterAssetIds,
          }),
          ...(filter_assetName && {
            asset_name: filter_assetName,
          }),

          ...(filter_assetComps && { asset_comps: filter_assetComps }),
          ...(filter_vAssetComps && {
            v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
          }),
          ...(filter_vAssetComps && {
            v_asset_status: filter_vAssetComps.map((x) => x.status_value),
          }),
          ...(filter_status_create_time && {
            start_status_create_time: filter_status_create_time.start,
            end_status_create_time: filter_status_create_time.end,
          }),
          ...(filter_tagName &&
            filter_tagName.length > 0 && {
              tags: filter_tagName.map((x) => x),
            }),

          qr_pdf_size: size,
        });
        if (res.code === 0) {
          download(`v2/api/file/qr/pdf/asset/download/${res.data}`, name);
        } else {
          message.error(res.message);
        }
      },
    });
  };

  const doSaveCondition = async () => {
    if (!conditionName) {
      message.error('请输入搜索条件名');
      return;
    }
    if (condition) {
      const res = await ConditionRequest.updateConditionItem({
        asset_name: filter_assetName ? filter_assetName : '',
        condition_name: conditionName.current ? conditionName.current : '',
        asset_start_time: '',
        asset_end_time: '',
        tags: filter_tagName ? filter_tagName : [],
        status_start_time: filter_status_create_time ? filter_status_create_time.start : '',
        status_end_time: filter_status_create_time ? filter_status_create_time.end : '',
        original: 1,
        asset_ids: filterAssetIds ? filterAssetIds : [],
        asset_comps: filter_assetComps ? filter_assetComps : [],
        v_asset_comps: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : [],
        v_asset_status: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_value) : [],
        status_name: [],
        status_value: [],
        id: condition.id,
      });
      if (res.code === 0) {
        message.success('保存成功');
      } else {
        message.error('保存失败');
      }
    } else {
      const res = await ConditionRequest.createCondition({
        asset_name: filter_assetName ? filter_assetName : '',
        condition_name: conditionName.current ? conditionName.current : '',
        asset_start_time: '',
        asset_end_time: '',
        tags: filter_tagName ? filter_tagName : [],
        status_start_time: filter_status_create_time ? filter_status_create_time.start : '',
        status_end_time: filter_status_create_time ? filter_status_create_time.end : '',
        original: 1,
        asset_ids: filterAssetIds ? filterAssetIds : [],
        asset_comps: filter_assetComps ? filter_assetComps : [],
        v_asset_comps: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : [],
        v_asset_status: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_value) : [],
        status_name: [],
        status_value: [],
      });
      if (res.code === 0) {
        message.success('保存成功');
      } else {
        message.error('保存失败');
      }
    }
  };

  const SaveConditionContent = () => {
    return (
      <Form>
        <Form.Item label="搜索条件名">
          <Input
            defaultValue={conditionName.current}
            placeholder="请输入搜索条件名称"
            onChange={(e) => (conditionName.current = e.target.value)}
          />
        </Form.Item>
      </Form>
    );
  };

  const downloadMenu = (
    <Menu>
      <Menu.Item
        key="csv"
        onClick={(e) => {
          e.domEvent.preventDefault();
          setVisible(true);
        }}
      >
        导出为csv
      </Menu.Item>
      <Menu.Item key="pdf" onClick={() => downloadQRcodePDF()}>
        导出为pdf
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="asset-list-page">
      <div className="tools-wrapper">
        <div className="asset-list-filter-input-container">
          <Form layout="inline" colon={false}>
            <Form.Item label="资产名称" className="filter-item">
              <Select
                style={{ minWidth: 168 }}
                showSearch
                placeholder="全部"
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                    if (filter_assetName) return;
                    const res = await SuggestionRequests.getAssetNameSuggestion();
                    if (res.code === 0) {
                      setAssetNameOptions(res.data);
                    } else {
                    }
                  }
                }}
                onSearch={async (value) => {
                  if (strUtils.isNull(value)) {
                    return;
                  }

                  if (value) {
                    const res = await SuggestionRequests.getAssetNameSuggestion(value);
                    if (res.code === 0) {
                      setAssetNameOptions(res.data);
                    } else {
                    }
                  } else {
                    return;
                  }
                }}
                onChange={(value: string) => set_filter_assetName(value)}
                value={filter_assetName}
              >
                {assetNameOptions.map((x: string) => {
                  return (
                    <Select.Option value={x} key={`asset_name_option:${x}`}>
                      {x}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="资产组件" className="filter-item">
              <Select
                onChange={(values: string) => {
                  set_filter_assetComps(values.split(','));
                }}
                style={{ minWidth: 168 }}
                value={filter_assetComps ? filter_assetComps.toString() : undefined}
                placeholder="全部"
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                    if (!filter_assetName) return;
                    const res = await SuggestionRequests.getAssetCompsSuggestion(filter_assetName);
                    if (res.code === 0) {
                      setAssetCompsOptions(res.data.map((x) => x.toString()));
                    } else {
                    }
                  }
                }}
              >
                {assetCompsOptions.map((x: string, index) => {
                  return (
                    <Select.Option value={x} key={`asset_comps_option:${index}`}>
                      {x}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Button type="link" onClick={() => setShowFilterArea(!showFilterArea)}>
              {showFilterArea ? '点击收起' : '展开全部'}
              {showFilterArea ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </Form>

          <div style={{ display: showFilterArea ? 'block' : 'none' }}>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产ID" className="filter-item">
                <Select
                  style={{ minWidth: 168 }}
                  placeholder="全部"
                  mode="tags"
                  onChange={(value: string[]) => setFilterAssetIds(value)}
                  value={filterAssetIds}
                ></Select>
              </Form.Item>
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产标签" className="filter-item">
                <Select
                  mode="tags"
                  allowClear
                  onChange={(values: string[]) => {
                    set_filter_tagName(
                      values.map((x) => {
                        return {
                          tag_name: x,
                          tag_value: '',
                        };
                      })
                    );
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_tagName ? filter_tagName.map((x) => x.tag_name) : undefined}
                  placeholder="全部"
                ></Select>
              </Form.Item>
              {filter_tagName &&
                filter_tagName.length > 0 &&
                filter_tagName.map((item) => {
                  return (
                    <Form.Item label={item.tag_name} key={'tag_value input' + item.tag_name} className="filter-item">
                      <Input
                        placeholder={`全部`}
                        value={item.tag_value}
                        onChange={(e) => {
                          let ary = filter_tagName.map((x) => x);
                          const newAry = ary.map((x) => {
                            if (x.tag_name === item.tag_name) {
                              x.tag_value = e.target.value;
                            }
                            return x;
                          });
                          set_filter_tagName(newAry);
                        }}
                      />
                    </Form.Item>
                  );
                })}
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产状态创建时间" className="filter-item">
                <DatePicker.RangePicker
                  value={filter_status_create_time ? filter_status_create_time.dates : undefined}
                  onChange={(dates, dateStrings) => {
                    if (!dates) return;
                    set_filter_status_create_time(
                      dates.length > 0
                        ? {
                            ...filter_status_create_time,
                            start: dateStrings[0] + ' 00:00:00',
                            end: dateStrings[1] + ' 00:00:00',
                            dates: dates,
                          }
                        : null
                    );
                  }}
                />
              </Form.Item>
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="地理信息" className="filter-item">
                <Select
                  onChange={(values: string[]) => {
                    set_filter_vAssetComps(
                      values.map((x) => {
                        const a = x.search('里程');
                        const b = x.search('轨枕号');
                        let orgValue = filter_vAssetComps.find((item) => item.status_name === x);
                        if (a === -1 && b === -1) {
                          return {
                            status_name: x,
                            status_value: orgValue ? orgValue.status_value : '',
                          };
                        } else {
                          return {
                            status_name: x,
                            status_value: orgValue ? orgValue.status_value : '0,0',
                          };
                        }
                      })
                    );
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : undefined}
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (!filter_assetName || !filter_assetComps) return;
                      const res = await SuggestionRequests.getAssetVCompsSuggestion({
                        asset_name: filter_assetName,
                        asset_comps: filter_assetComps,
                      });
                      if (res.code === 0) {
                        setVAssetQueryOptions(arrSet(res.data));
                      } else {
                      }
                    }
                  }}
                  mode="tags"
                >
                  {vAssetQueryOptions.map((item, index) => {
                    return (
                      <Select.Option key={item + index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {filter_vAssetComps &&
                filter_vAssetComps.length > 0 &&
                filter_vAssetComps.map((item) => {
                  const a = item.status_name.search('里程');
                  const b = item.status_name.search('轨枕号');
                  if (a === -1 && b === -1) {
                    return (
                      <Form.Item
                        label={item.status_name}
                        key={'status_value input' + item.status_name}
                        className="filter-item"
                      >
                        <Select
                          style={{ minWidth: 168 }}
                          mode="tags"
                          placeholder={`全部`}
                          onDropdownVisibleChange={async (open) => {
                            if (open) {
                              const res = await SuggestionRequests.statusValueSuggestion({
                                v_status_name: item.status_name,
                              });
                              if (res.code === 0) {
                                setVStatusValueOptions(res.data.map((item) => item.v_status_value));
                              } else {
                              }
                            }
                            if (!open) {
                              setVStatusValueOptions([]);
                            }
                          }}
                          value={item.status_value === '' ? undefined : item.status_value.split(',')}
                          onChange={(values: string[]) => {
                            let ary = filter_vAssetComps.map((x) => x);
                            const newAry = ary.map((x) => {
                              if (x.status_name === item.status_name) {
                                x.status_value = values.toString();
                              }
                              return x;
                            });
                            set_filter_vAssetComps(newAry);
                          }}
                        >
                          {vStatusValueOptions.map((option) => {
                            return (
                              <Select.Option value={option} key={option}>
                                {option}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    );
                  } else {
                    const x = item.status_name.search('起始');
                    const y = item.status_name.search('迄止');
                    if (x === -1 && y === -1) {
                      return (
                        <Form.Item
                          label={item.status_name}
                          key={'status_value input' + item.status_name}
                          className="filter-item"
                        >
                          <InputNumber
                            onChange={(value: number) => {
                              if (!value) {
                                value = 0;
                              }
                              let ary = filter_vAssetComps.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.status_name === item.status_name) {
                                  const range = x.status_value.split(',');
                                  range[0] = value.toString();
                                  if (Number(range[0]) > Number(range[1])) {
                                    range[1] = value.toString();
                                  }
                                  x.status_value = range.toString();
                                }
                                return x;
                              });
                              set_filter_vAssetComps(newAry);
                            }}
                            value={Number(item.status_value.split(',')[0])}
                          />
                          ~
                          <InputNumber
                            onChange={(value: number) => {
                              if (!value) {
                                value = 0;
                              }
                              let ary = filter_vAssetComps.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.status_name === item.status_name) {
                                  const range = x.status_value.split(',');
                                  range[1] = value.toString();
                                  if (Number(range[0]) > Number(range[1])) {
                                    message.error('起始值不能大于迄止值');
                                    range[1] = range[0];
                                  }

                                  x.status_value = range.toString();
                                }
                                return x;
                              });
                              set_filter_vAssetComps(newAry);
                            }}
                            value={Number(item.status_value.split(',')[1])}
                          />
                        </Form.Item>
                      );
                    } else {
                      return (
                        <Form.Item
                          label={item.status_name}
                          key={'status_value input' + item.status_name}
                          className="filter-item"
                        >
                          <InputNumber
                            onChange={(value: number) => {
                              if (!value) {
                                value = 0;
                              }
                              let ary = filter_vAssetComps.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.status_name === item.status_name) {
                                  x.status_value = value.toString();
                                }
                                return x;
                              });
                              set_filter_vAssetComps(newAry);
                            }}
                            value={Number(item.status_value)}
                            style={{ width: '100%', border: 'none' }}
                          />
                        </Form.Item>
                      );
                    }
                  }
                })}
            </Form>
          </div>
          <div style={{ display: showFilterArea ? 'flex' : 'none' }}>
            <Form layout="inline" colon={false}>
              <Form.Item label="搜索条件" className="filter-item">
                <Select
                  allowClear
                  style={{ minWidth: 300 }}
                  placeholder="请选择搜索条件"
                  onChange={(value: string) => {
                    if (!value) {
                      setCondition(undefined);

                      set_filter_assetName(undefined);
                      set_filter_assetComps(undefined);
                      set_filter_tagName([]);
                      set_filter_vAssetComps([]);
                      set_filter_status_create_time(null);
                      setFilterAssetIds([]);
                      conditionName.current = '';
                      return;
                    }
                    const obj: ConditionItem | PublicConditionItem = JSON.parse(value);
                    setCondition(obj);
                    set_filter_assetName(obj.asset_name);
                    set_filter_assetComps(obj.asset_comps);
                    set_filter_tagName(obj.tags);
                    set_filter_vAssetComps(
                      obj.v_asset_comps.map((x, index) => {
                        return {
                          status_name: x,
                          status_value: obj.v_asset_status[index],
                        };
                      })
                    );
                    if (obj.status_start_time && obj.status_end_time) {
                      set_filter_status_create_time({
                        start: obj.status_start_time,
                        end: obj.status_end_time,
                        dates: [moment(obj.status_start_time.slice(0, 10)), moment(obj.status_end_time.slice(0, 10))],
                      });
                    }
                    setFilterAssetIds(obj.asset_ids);
                    conditionName.current = obj.condition_name;
                  }}
                  value={condition ? condition.condition_name : undefined}
                  onSearch={(value) => {
                    if (conditionType === 0) return;
                    setFilterConditionName(value);
                    fetchPublicConditionList({ page: 1, pageSize: 10 });
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      {loadingCondition ? (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 100,
                          }}
                        >
                          <Spin spinning={loadingCondition} tip="加载中..." />
                        </div>
                      ) : (
                        menu
                      )}
                    </div>
                  )}
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (conditionType === 0) {
                        fetchConditionList({ page: 1, pageSize: 10 });
                      } else {
                        fetchPublicConditionList({ page: 1, pageSize: 10 });
                      }
                    } else {
                    }
                  }}
                >
                  {conditionType === 0
                    ? conditionList.map((x, index) => {
                        return (
                          <Select.Option value={JSON.stringify(x)} key={`condition-item-${index}`}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {x.condition_name}
                              <span>
                                <Tooltip
                                  placement="bottom"
                                  title={
                                    <div>
                                      <div>
                                        <div>{`名称：${x.condition_name ? x.condition_name : '无'}`}</div>
                                        <div>{`资产名称：${x.asset_name ? x.asset_name : '无'}`}</div>
                                        <div>
                                          {`资产组件：`}
                                          {x.asset_comps && x.asset_comps.length > 0
                                            ? x.asset_comps.map((x, index) => <Tag key={`acomps-${index}`}>{x}</Tag>)
                                            : '无'}
                                        </div>
                                        <div>
                                          {`资产标签：`}{' '}
                                          {x.tags && x.tags.length > 0
                                            ? x.tags.map((x, index) => (
                                                <Tag key={`tag-${index}`}>{`${x.tag_name}:${x.tag_value}`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                        <div>
                                          {`地理组件：`}
                                          {x.v_asset_comps && x.v_asset_comps.length > 0
                                            ? x.v_asset_comps.map((v, v_index) => (
                                                <Tag key={`vcomp-${v_index}`}>{`${v}:${
                                                  x.v_asset_status[v_index] ? x.v_asset_status[v_index] : '全部'
                                                }`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      color: '#1890ff',
                                      paddingLeft: 8,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="分享">
                                  <ShareAltOutlined
                                    style={{
                                      color: '#6dff18',
                                      paddingLeft: 8,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认分享‘${x.condition_name}’到公共搜索条件吗？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.shareMyConditionItem(x.id);
                                          if (res.code === 0) {
                                            message.success('分享成功');
                                            // fetchConditionList({page:conditionPagination.page,pageSize:conditionPagination.pageSize})
                                          } else {
                                            message.error('分享失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="删除">
                                  <DeleteOutlined
                                    style={{
                                      color: 'red',
                                      paddingLeft: 8,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认删除吗`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.delMyConditionItem(x.id);
                                          if (res.code === 0) {
                                            message.success('删除成功');
                                            setCondition(undefined);
                                          } else {
                                            message.error('删除失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </span>
                          </Select.Option>
                        );
                      })
                    : PublicConditionList.map((x, index) => {
                        return (
                          <Select.Option value={JSON.stringify(x)} key={`public-condition-item-${index}`}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {x.condition_name}
                              <span>
                                <Tooltip
                                  placement="bottom"
                                  title={
                                    <div>
                                      <div>
                                        <div>{`名称：${x.condition_name ? x.condition_name : '无'}`}</div>
                                        <div>{`资产名称：${x.asset_name ? x.asset_name : '无'}`}</div>
                                        <div>
                                          {`资产组件：`}
                                          {x.asset_comps && x.asset_comps.length > 0
                                            ? x.asset_comps.map((x, index) => <Tag key={`acomps-${index}`}>{x}</Tag>)
                                            : '无'}
                                        </div>
                                        <div>
                                          {`资产标签：`}{' '}
                                          {x.tags && x.tags.length > 0
                                            ? x.tags.map((x, index) => (
                                                <Tag key={`tag-${index}`}>{`${x.tag_name}:${x.tag_value}`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                        <div>
                                          {`地理组件：`}
                                          {x.v_asset_comps && x.v_asset_comps.length > 0
                                            ? x.v_asset_comps.map((v, v_index) => (
                                                <Tag key={`vcomp-${v_index}`}>{`${v}:${
                                                  x.v_asset_status[v_index] ? x.v_asset_status[v_index] : '全部'
                                                }`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      color: '#1890ff',
                                      paddingLeft: 8,
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="引入到我的搜索条件">
                                  <ImportOutlined
                                    style={{
                                      color: 'green',
                                      paddingLeft: 8,
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认导入‘${x.condition_name}’到我的搜索条件吗？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.importConditionFromPublic(x.id);
                                          if (res.code === 0) {
                                            message.success('导入成功');
                                          } else {
                                            message.error('导入失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="删除">
                                  <DeleteOutlined
                                    style={{ color: 'red', paddingLeft: 8 }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认删除吗？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.delPublicConditionItem(x.id);
                                          if (res.code === 0) {
                                            message.success('删除成功');
                                            setCondition(undefined);
                                          } else {
                                            message.error('删除失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </span>
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
              <Form.Item label="类型">
                <Radio.Group value={conditionType} onChange={(e) => setConditionType(e.target.value)}>
                  <Radio value={0}>我的</Radio>
                  <Radio value={1}>公共</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    Modal.confirm({
                      title: '保存搜索条件',
                      onCancel: () => {},
                      onOk: doSaveCondition,
                      okText: '确认',
                      cancelText: '取消',
                      content: <SaveConditionContent />,
                    });
                  }}
                >
                  保存条件
                </a>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Button type="primary" style={{ marginRight: 10 }} onClick={() => doFetch()} icon={<SearchOutlined />}>
            查询
          </Button>

          <Dropdown overlay={downloadMenu} key="download-menus">
            <Button style={{ marginRight: 10 }} icon={<DownloadOutlined />}>
              下载
            </Button>
          </Dropdown>
          <Button
            onClick={() => {
              set_filter_assetComps(undefined);
              set_filter_vAssetComps([]);
              set_filter_assetName(undefined);
              set_filter_tagName([]);
              set_filter_status_create_time(null);
            }}
            style={{ marginRight: 10 }}
            icon={<RedoOutlined />}
          >
            重置
          </Button>
          <Button onClick={() => setAssetModalVisible(true)} icon={<PlusOutlined />}>
            创建资产
          </Button>
        </div>
      </div>

      <Table
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        style={{ backgroundColor: '#ffffff' }}
        rowKey={(record, idx) => `asset-${record.asset_id}/${idx}`}
        dataSource={list}
        loading={loadingList}
        columns={getColumnConfigs<(typeof list)[any]>({
          onEvent: (evt: string, payload: AssetItem) => {
            switch (evt) {
              case 'start:update-asset':
                setEditingAssetUid(payload.asset_id);
                break;
              case 'start:delete-asset-success':
                setLoadingList(true);
                doFetch();
                break;
            }
          },
          maxVStatusCount: maxVStatusCount,
          maxCompsCount: maxCompsCount,
        })}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => {
              setEditingAssetUid(record.asset_id);
            },
          };
        }}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => doFetch(page, pageSize),
          showTotal: (total: number) => {
            return <span>{`共 ${total} 条数据`}</span>;
          },
          showQuickJumper: true,
          style: { marginRight: 16 },
        }}
        className="asset-table"
      />
      {assetModalVisible && (
        <CreateAssetModal visible={assetModalVisible} setVisible={setAssetModalVisible} doFetch={doFetch} />
      )}

      <DrawerAssetEditor
        asset_id={editingAssetUid}
        onClose={() => {
          setEditingAssetUid(null);
          doFetch();
        }}
        info={userInfo}
        destroyOnClose
      ></DrawerAssetEditor>
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>导出资产
          </span>
        }
        cancelText="取消"
        okText={loadingExcel ? '数据生成中' : '确定'}
        okButtonProps={{ loading: loadingExcel }}
        visible={visible}
        onOk={() => downloadExcel()}
        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setVisible(false)}
      >
        <div style={{ marginBottom: 16 }}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="文件名称">
              <Input
                addonAfter={selectAfterCSV}
                defaultValue="资产信息"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setExcelFileName(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default AssetsList;
