import React from 'react';
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom';
import { useCtxState } from '@/App.store';
import { Layout, Menu, Collapse, Divider, message, Modal, Avatar } from 'antd';
import {
  LockOutlined,
  LayoutOutlined,
  PieChartOutlined,
  ExclamationCircleOutlined,
  FlagOutlined,
  CaretUpOutlined,
  SettingOutlined,
  LogoutOutlined,
  CaretDownOutlined,
  createFromIconfontCN,
  BranchesOutlined,
  FormOutlined,
  RobotOutlined,
} from '@ant-design/icons';
import './index.less';
import * as userRequest from '@/requests/user';

const MyIcon = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2518593_dd741qwuy69.js',
});

const { SubMenu } = Menu;
const { Panel } = Collapse;

interface MenuChildItem {
  to: string;
  label: string;
  hideInSiderBar: boolean;
  key: string;
}
interface MenuItem {
  to?: string;
  key: string;
  label: string;
  icon: React.ReactNode;
  children?: MenuChildItem[];
  hideInSiderBar: boolean;
}

interface SysMenuConfig {
  defaultActive: string;
  defaultOpeneds: string[];
  menus: MenuItem[];
}

const rootSubmenuKeys = [
  'assets-management',
  'forms-management',
  'normal-form',
  'chart-management',
  'defect-management',
  'location',
  'auth-management',
];
const SiderBarMenuConfig_new: SysMenuConfig = {
  defaultActive: 'assets-list',
  defaultOpeneds: ['assets-management'],
  menus: [
    {
      label: '资产管理',
      key: 'assets-management',
      icon: <MyIcon type="icon-Subway" />,
      children: [
        {
          to: '/workbench/assets-management/assets-list',
          label: '资产清单',
          hideInSiderBar: false,
          key: 'assets-list',
        },
        {
          to: '/workbench/assets-management/status-list',
          label: '资产状态清单',
          hideInSiderBar: false,
          key: 'status-list',
        },
        {
          to: '/workbench/assets-management/assets-status-standard',
          label: '资产状态标准',
          hideInSiderBar: false,
          key: 'assets-status-standard',
        },
        {
          to: '/workbench/assets-management/assets-template-list',
          label: '资产模板',
          hideInSiderBar: false,
          key: 'assets-template-list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '表单管理',
      key: 'forms-management',
      icon: <LayoutOutlined />,
      children: [
        {
          to: '/workbench/forms-management/my-template',
          label: '我的模板',
          hideInSiderBar: false,
          key: 'my-template',
        },
        {
          to: '/workbench/forms-management/share-template',
          label: '模板库',
          hideInSiderBar: false,
          key: 'share-template',
        },
        {
          to: '/workbench/forms-management/form-data-list',
          label: '我的表单',
          hideInSiderBar: false,
          key: 'form-data-list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '图表管理',
      key: 'chart-management',
      icon: <PieChartOutlined />,
      children: [
        {
          to: '/workbench/chart-management/hi',
          label: 'Hi',
          hideInSiderBar: false,
          key: 'hi',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '缺陷管理',
      key: 'defect-management',
      icon: <ExclamationCircleOutlined />,
      children: [
        {
          to: '/workbench/defect-management/defect-list',
          label: '缺陷清单',
          hideInSiderBar: false,
          key: 'defect-list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '地点信息',
      key: 'location',
      icon: <FlagOutlined />,
      children: [
        {
          to: '/workbench/location/addr-list',
          label: '地点信息清单',
          hideInSiderBar: false,
          key: 'addr-list',
        },
        {
          to: '/workbench/location/coordinate-list',
          label: '地理坐标清单',
          hideInSiderBar: false,
          key: 'coordinate-list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '任务管理',
      key: 'project',
      icon: <BranchesOutlined />,
      children: [
        {
          to: '/workbench/project/list',
          label: '项目列表',
          hideInSiderBar: false,
          key: 'list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '标注管理',
      key: 'label',
      icon: <FormOutlined />,
      children: [
        {
          to: '/workbench/label/tasks',
          label: '待办&搜索',
          hideInSiderBar: false,
          key: 'tasks',
        },
        {
          to: '/workbench/label/project-list',
          label: '标注项目',
          hideInSiderBar: false,
          key: 'project-list',
        },
      ],
      hideInSiderBar: false,
    },

    {
      label: 'AI管理',
      key: 'ai',
      icon: <RobotOutlined />,
      children: [
        {
          to: '/workbench/ai/model-list',
          label: 'AI应用',
          hideInSiderBar: false,
          key: 'model-list',
        },
        {
          to: '/workbench/ai/model-training',
          label: '模型训练',
          hideInSiderBar: false,
          key: 'model-training',
        },
        {
          to: '/workbench/ai/inaccurate-data',
          label: '不准确管理',
          hideInSiderBar: false,
          key: 'inaccurate-data',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '权限管理',
      key: 'auth-management',
      icon: <LockOutlined />,

      children: [
        {
          to: '/workbench/auth-management/user',
          label: '成员列表',
          hideInSiderBar: false,
          key: 'user',
        },
        {
          to: '/workbench/auth-management/role',
          label: '角色管理',
          hideInSiderBar: false,
          key: 'role',
        },
        {
          to: '/workbench/auth-management/auth',
          label: '系统权限',
          hideInSiderBar: false,
          key: 'auth',
        },
        {
          to: '/workbench/auth-management/organization',
          label: '部门管理',
          hideInSiderBar: false,
          key: 'organization',
        },
        {
          to: '/workbench/auth-management/data-auth',
          label: '数据权限',
          hideInSiderBar: false,
          key: 'data-auth',
        },
      ],
      hideInSiderBar: false,
    },
  ],
};

const SiderBarMenuConfig_bjmtr: SysMenuConfig = {
  defaultActive: 'assets-list',
  defaultOpeneds: ['assets-management'],
  menus: [
    {
      label: '资产管理',
      key: 'assets-management',
      icon: <MyIcon type="icon-Subway" />,
      children: [
        {
          to: '/workbench/assets-management/assets-list',
          label: '资产清单',
          hideInSiderBar: false,
          key: 'assets-list',
        },
        {
          to: '/workbench/assets-management/status-list',
          label: '资产状态清单',
          hideInSiderBar: false,
          key: 'status-list',
        },
        {
          to: '/workbench/assets-management/assets-status-standard',
          label: '资产状态标准',
          hideInSiderBar: false,
          key: 'assets-status-standard',
        },
        {
          to: '/workbench/assets-management/assets-template-list',
          label: '资产模板',
          hideInSiderBar: false,
          key: 'assets-template-list',
        },
      ],
      hideInSiderBar: false,
    },

    {
      label: '地点信息',
      key: 'location',
      icon: <FlagOutlined />,
      children: [
        {
          to: '/workbench/location/addr-list',
          label: '地点信息清单',
          hideInSiderBar: false,
          key: 'addr-list',
        },
        {
          to: '/workbench/location/coordinate-list',
          label: '地理坐标清单',
          hideInSiderBar: false,
          key: 'coordinate-list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '任务管理',
      key: 'project',
      icon: <BranchesOutlined />,
      children: [
        {
          to: '/workbench/project/list',
          label: '项目列表',
          hideInSiderBar: false,
          key: 'list',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '标注管理',
      key: 'label',
      icon: <FormOutlined />,
      children: [
        {
          to: '/workbench/label/tasks',
          label: '待办&搜索',
          hideInSiderBar: false,
          key: 'tasks',
        },
        {
          to: '/workbench/label/project-list',
          label: '标注项目',
          hideInSiderBar: false,
          key: 'project-list',
        },
      ],
      hideInSiderBar: false,
    },

    {
      label: 'AI管理',
      key: 'ai',
      icon: <RobotOutlined />,
      children: [
        {
          to: '/workbench/ai/model-list',
          label: 'AI应用',
          hideInSiderBar: false,
          key: 'model-list',
        },
        {
          to: '/workbench/ai/model-training',
          label: '模型训练',
          hideInSiderBar: false,
          key: 'model-training',
        },
        {
          to: '/workbench/ai/inaccurate-data',
          label: '不准确管理',
          hideInSiderBar: false,
          key: 'inaccurate-data',
        },
      ],
      hideInSiderBar: false,
    },
    {
      label: '权限管理',
      key: 'auth-management',
      icon: <LockOutlined />,

      children: [
        {
          to: '/workbench/auth-management/user',
          label: '成员列表',
          hideInSiderBar: false,
          key: 'user',
        },
        {
          to: '/workbench/auth-management/role',
          label: '角色管理',
          hideInSiderBar: false,
          key: 'role',
        },
        {
          to: '/workbench/auth-management/auth',
          label: '系统权限',
          hideInSiderBar: false,
          key: 'auth',
        },
        {
          to: '/workbench/auth-management/organization',
          label: '部门管理',
          hideInSiderBar: false,
          key: 'organization',
        },
        {
          to: '/workbench/auth-management/data-auth',
          label: '数据权限',
          hideInSiderBar: false,
          key: 'data-auth',
        },
      ],
      hideInSiderBar: false,
    },
  ],
};

function getNavLinkNode(child_item: MenuChildItem, collapsed: boolean) {
  return (
    <span>
      <Link
        id={child_item.key}
        to={child_item.to}
        style={{
          color: 'inherit',
          paddingRight: collapsed ? 0 : '100%',
        }}
      >
        {child_item.label}
      </Link>
    </span>
  );
}

const Sidebar = () => {
  const [SiderBarMenuConfig, setSiderBarMenuConfig] = React.useState(SiderBarMenuConfig_new);
  const [{ userSysMenus, userInfo, sidebarCollapsed }, dispatch] = useCtxState();
  const [openKeys, setOpenKeys] = React.useState(['assets-management']);
  const [showActions, setShowActions] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  //退出登录
  const logout = async () => {
    try {
      const res = (await userRequest.logout()) as any;
      console.log('logout res:', res);
      if (res.code === 20005) {
        dispatch({ type: 'LOGOUT' });
        message.warn('您已退出登录，请重新登录！');
        setTimeout(() => history.replace(`/login`), 500);
      } else {
        message.error(res.message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = (key: string) => {
    if (key === 'logout') {
      Modal.confirm({
        title: '温馨提示',
        content: '确认退出当前账号吗？',

        onOk() {
          logout();
        },
        onCancel() {},
        cancelText: '取消',
        okText: '确认',
      });
    } else {
      history.push('/userinfo-center');
    }
  };

  React.useEffect(() => {
    const key = location.pathname.split('/')[2];
    if (key) {
      setOpenKeys([key]);
    } else {
      return;
    }
  }, [location]);
  React.useEffect(() => {
    if (window.location.host === 'bjmtr.imetro.work') {
      setSiderBarMenuConfig(SiderBarMenuConfig_bjmtr);
    }
  }, []);

  React.useEffect(() => {
    if (location.pathname === '/workbench/project/detail') {
      if (!sidebarCollapsed) {
        dispatch({ type: 'toggle:sidebarCollapsed' });
      }
    }
  }, [location]);

  return (
    <Layout.Sider trigger={null} collapsible collapsed={sidebarCollapsed} className="workbench-page__sidebar">
      <div className="logo">
        {sidebarCollapsed ? (
          <img className="collapsed" src={process.env.PUBLIC_URL + '/img/common/logo.png'} />
        ) : (
          <img className="expanded" src={process.env.PUBLIC_URL + '/img/common/logo_title_white.png'} />
        )}
      </div>
      <Menu
        defaultSelectedKeys={[SiderBarMenuConfig.defaultActive]}
        defaultOpenKeys={SiderBarMenuConfig.defaultOpeneds}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        selectedKeys={[
          `${
            document.location.hash.slice(2)
              ? document.location.hash.slice(2).split('/')[2]
                ? document.location.hash.slice(2).split('/')[2].split('?')[0]
                : ''
              : ''
          }`,
        ]}
        mode="inline"
        theme="dark"
      >
        {SiderBarMenuConfig.menus.map((menu, _index) => {
          if (menu.children && menu.children.length > 0) {
            if (menu.key === 'auth-management') {
              const hasAuth = !!userSysMenus.find((x: SysMenuItem) => x.url === '/auth-management');
              if (!hasAuth) {
                return null;
              }
            }
            return (
              <SubMenu icon={menu.icon} key={menu.key} title={menu.label}>
                {menu.children.map((child) => {
                  return <Menu.Item key={child.key}>{getNavLinkNode(child, sidebarCollapsed)}</Menu.Item>;
                })}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={menu.key} icon={menu.icon}>
                {menu.label}
              </Menu.Item>
            );
          }
        })}
      </Menu>
      {sidebarCollapsed && (
        <div>
          <Menu mode="inline" theme="dark" className="collapsed-avatar">
            <SubMenu
              icon={
                userInfo.avatar ? (
                  <Avatar src={`v2/api/user/avatar/${userInfo.avatar}`} />
                ) : (
                  <Avatar>{userInfo.username}</Avatar>
                )
              }
            >
              <Menu.Item onClick={() => handleClick('userinfo-center')}>设置</Menu.Item>
              <Menu.Item onClick={() => handleClick('logout')}>退出</Menu.Item>
            </SubMenu>
          </Menu>
        </div>
      )}
      {!sidebarCollapsed && (
        <div className="action">
          {showActions && null}
          <Collapse ghost>
            <Panel
              header={
                <div className="info-container" onClick={() => setShowActions(!showActions)}>
                  {userInfo.avatar ? (
                    <Avatar src={`v2/api/user/avatar/${userInfo.avatar}`} />
                  ) : (
                    <Avatar>{userInfo.username}</Avatar>
                  )}
                  <span className="sider-username">
                    {userInfo.username} {showActions ? <CaretDownOutlined /> : <CaretUpOutlined />}
                  </span>
                </div>
              }
              key="1"
              showArrow={false}
            >
              <div className="action-container">
                <span className="action-btn" onClick={() => handleClick('userinfo-center')}>
                  <SettingOutlined /> 设置
                </span>
                <Divider type="vertical" style={{ backgroundColor: '#ffffffa6' }} />
                <span className="action-btn" onClick={() => handleClick('logout')}>
                  <LogoutOutlined /> 退出
                </span>
              </div>
            </Panel>
          </Collapse>
        </div>
      )}
    </Layout.Sider>
  );
};

export default withRouter(Sidebar);
