import * as React from 'react';
import 'react-chat-elements/dist/main.css';
import { ChatItem, Input as IMinput, SideBar } from 'react-chat-elements';
import { MessageOutlined, UserOutlined, SearchOutlined, PlusOutlined, EllipsisOutlined } from '@ant-design/icons';

import { Avatar, Input, Button, Tooltip, message, List, Badge, Checkbox, Row, Col, Modal } from 'antd';
import { useCtxState } from '@/App.store';
import './index.less';

import * as IMRequests from '@/requests/rongcloud';
import * as RongIMLib from '@rongcloud/imlib-v4';
import * as UserRequests from '@/requests/user';

import { getDateString, getLatesMessage } from './utils';
import MessageHistoryWindow from './MessageHistoryWindow';
import Contacts from './Contacts';

interface PropsType {
  im: RongIMLib.IMClient;
  setIMVisible: Function;
  existedConversationList: any[];
  getExistedConversationList: Function;
  totalUnread: number;
}

const IM = (props: PropsType) => {
  const { im, setIMVisible, existedConversationList, totalUnread } = props;
  const [{ userInfo }] = useCtxState();
  const [chatList, setChatList] = React.useState<ChatListItem[]>([]);
  const myGroups = React.useRef<GroupItem[]>([]);
  const allUsers = React.useRef<UserInfoItem[]>([]);
  const [visible, setVisible] = React.useState(false);
  const [route, setRoute] = React.useState('chat');
  const [filterChatName, setFilterChatName] = React.useState('');
  const [addChatVisible, setAddChatVisible] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<string[]>([]);
  const [currentChat, setCurrentChat] = React.useState<ChatListItem | undefined>(undefined);
  const [chatWindowTitle, setChatWindowTitle] = React.useState('');
  React.useEffect(() => {
    getMyGroups().then(() => {
      getAllUsers().then(() => {
        const list = getChatList();
        if (list.length > chatList.length) {
          setCurrentChat(list[0]);
        }
        setChatList(list);
      });
    });
  }, [existedConversationList]);
  React.useEffect(() => {
    getMyGroups().then(() => {
      getAllUsers().then(() => {
        const list = getChatList();
        setCurrentChat(list[0]);
      });
    });
  }, []);

  React.useEffect(() => {
    if (!currentChat) return;
    getChatWindowTitle(currentChat);
    sendHasReadMessage(currentChat, currentChat.latesMessage);
    clearUnread();
  }, [currentChat]);

  //本地搜索会话
  const chatFilter = () => {
    return chatList.filter((x) => x.title.startsWith(filterChatName));
  };

  const handleCreateChatOk = () => {
    if (selectedUser.length < 1) return;
    if (selectedUser.length === 1) {
      sendTrunIntoSingleNotice(JSON.parse(selectedUser[0]).user_id);
    }
    if (selectedUser.length > 1) {
      createGroup();
    }
  };

  //创建群聊
  const createGroup = async () => {
    if (selectedUser.length < 2) {
      message.error('群聊人数不能少于3人');
      return;
    }
    let groupName = '';
    let names = selectedUser.map((x) => JSON.parse(x).username);
    names.unshift(userInfo.username);
    if (names.length > 4) {
      groupName = names.slice(0, 4).toString() + '...';
    } else {
      groupName = names.toString();
    }
    const res = await IMRequests.createGroup({
      user_ids: selectedUser.map((x) => JSON.parse(x).user_id).concat([userInfo.user_id]),
      group_name: groupName,
    });
    if (res.code === 0) {
      sendCreateGroupNotice(res.data.group_id, `“${userInfo.username}”创建了群聊`);
      setAddChatVisible(false);
      setSelectedUser([]);
    } else {
      message.error(res.message);
    }
  };

  //进入单聊
  const sendTrunIntoSingleNotice = (targetId: string) => {
    const singleChat = chatList.find((x) => x.target_id === targetId);
    if (singleChat) {
      setCurrentChat(singleChat);
      setAddChatVisible(false);
      setSelectedUser([]);
    } else {
      let conversation = im.Conversation.get({
        targetId: targetId,
        type: 1,
      });
      conversation
        .send({
          messageType: RongIMLib.MESSAGE_TYPE.TEXT,
          content: {
            content: '你好', // 文本内容
          },
        })
        .then(function (message) {
          setAddChatVisible(false);
          setSelectedUser([]);
          console.log('发送消息成功', message);
        });
    }
  };

  //发送创建群聊通知
  const sendCreateGroupNotice = (targetId: string, content: string) => {
    const prefix = '**%%裠屪%%**';

    let conversation = im.Conversation.get({
      targetId: targetId,
      type: 3,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + content + prefix, // 文本内容
        },
      })
      .then(function (message) {
        console.log('发送更改群名称消息成功', message);
      });
  };

  //发送已读消息信息
  const sendHasReadMessage = (chat: any, latesMessaege: any) => {
    if (!chat || !latesMessaege) return;
    const conversation = im.Conversation.get({
      targetId: chat.targetId,
      type: chat.type,
    });
    const messageUId = latesMessaege.messageUId; // 消息唯一 Id, message 中的 messageUid
    const lastMessageSendTime = latesMessaege.sentTime; // 最后一条消息的发送时间
    const type = '1'; // 备用，默认赋值 1 即可
    conversation
      .send({
        messageType: 'RC:ReadNtf',
        content: {
          messageUId: messageUId,
          lastMessageSendTime: lastMessageSendTime,
          type: type,
        },
      })
      .then(function (message) {
        console.log('发送已读通知消息成功', message);
      });
  };
  //清除未读消息
  const clearUnread = () => {
    if (!currentChat) return;
    const conversation = im.Conversation.get({
      targetId: currentChat.target_id,
      type: currentChat.type,
    });
    conversation.read();
  };
  //获取登录人所属的所有群组信息
  const getMyGroups = async () => {
    const res = await IMRequests.getMyGroups({
      limit: 100000,
      skip: 0,
    });
    if (res.code === 0) {
      myGroups.current = res.data;
    } else {
    }
  };
  //获取所有用户信息
  const getAllUsers = async () => {
    const res = await UserRequests.getAllUsers({
      limit: 100000,
      skip: 0,
      username: '',
    });
    if (res.code === 0) {
      allUsers.current = res.data;
    } else {
    }
  };
  //生成聊天窗口title
  const getChatWindowTitle = async (chat: ChatListItem | undefined) => {
    if (!chat) return;
    let count = 0;
    const title = chat.title;
    if (chat.type === 3) {
      const res = await IMRequests.getUserInfoOfGroup(chat.target_id);
      if (res.code === 0) {
        count = res.data.length;
        setChatWindowTitle(`${title}(${count})`);
      }
    } else if (chat.type === 1) {
      setChatWindowTitle(title);
    }
  };
  //获取会话列表
  const getChatList = () => {
    const list: ChatListItem[] = existedConversationList.map((x) => {
      let name = '';
      let avatar = 'https://lq-fe-img.oss-cn-hangzhou.aliyuncs.com/lyd/group.png';
      let latesUser = '';
      if (x.type === 3) {
        const info = myGroups.current.find((item) => item.group_id === x.targetId);
        const latesUserInfo = allUsers.current.find((item) => item.user_id === x.latestMessage.senderUserId);
        if (info) {
          name = info.group_name;
        }
        if (latesUserInfo) {
          latesUser = latesUserInfo.username;
        }
      } else if (x.type === 1) {
        const info = allUsers.current.find((item) => item.user_id === x.targetId);
        if (info) {
          name = info.username;
          avatar = `v2/api/user/avatar/${info.avatar}`;
        }
      }

      return {
        avatar: avatar,
        title: name,
        subtitle: x.type === 1 ? getLatesMessage(x.latestMessage) : `${latesUser}：${getLatesMessage(x.latestMessage)}`,
        date: new Date(x.latestMessage.sentTime),
        unread: x.unreadMessageCount,
        target_id: x.targetId,
        type: x.type,
        dateString: getDateString(x.latestMessage.sentTime),
        latesMessage: x.latesMessage,
      };
    });

    return list;
  };

  return (
    <div style={{ display: 'flex', height: 700 }}>
      <div style={{ width: 50 }}>
        <SideBar
          top={
            <div
              style={{
                paddingTop: 36,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {userInfo.avatar !== '' ? (
                <Avatar
                  size={30}
                  className="avatar-circle"
                  src={`v2/api/user/avatar/${userInfo.avatar}`}
                  shape="square"
                />
              ) : (
                <Avatar size={30} className="avatar-circle" src="" shape="square">
                  {userInfo.username.slice(0, 1)}
                </Avatar>
              )}
              <div style={{ paddingTop: 32 }}>
                <Tooltip title="聊天" placement="top">
                  <Badge count={totalUnread} overflowCount={99} style={{ padding: '0 2px', boxShadow: 'none' }}>
                    <MessageOutlined
                      style={{
                        fontSize: 20,
                        cursor: 'pointer',
                        color: route === 'chat' ? '#1890ff' : '#ffffff',
                      }}
                      onClick={() => setRoute('chat')}
                    />
                  </Badge>
                </Tooltip>
              </div>
              <div style={{ paddingTop: 32 }}>
                <Tooltip title="通讯录" placement="top">
                  <UserOutlined
                    style={{
                      fontSize: 20,

                      cursor: 'pointer',
                      color: route === 'contacts' ? '#1890ff' : '#ffffff',
                    }}
                    onClick={() => setRoute('contacts')}
                  />
                </Tooltip>
              </div>
            </div>
          }
          type="dark"
        />
      </div>
      {route === 'chat' && (
        <div key="chat" style={{ display: 'flex' }}>
          <Modal
            visible={addChatVisible}
            okText="确认"
            cancelText="取消"
            onOk={() => {
              handleCreateChatOk();
            }}
            onCancel={() => {
              setAddChatVisible(false);
            }}
            title="创建会话"
            destroyOnClose
            okButtonProps={{ disabled: selectedUser.length < 1 }}
          >
            <AddChatWidget setSelectedUser={setSelectedUser} selectedUser={selectedUser} userInfo={userInfo} />
          </Modal>
          <div
            style={{
              width: 250,
              borderRight: '1px solid #e8e8e8',
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 16,
              }}
            >
              <Input
                placeholder="搜索"
                size={'small'}
                style={{ width: 180, fontSize: 12 }}
                value={filterChatName}
                onChange={(e) => setFilterChatName(e.target.value)}
                prefix={<SearchOutlined type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
              <Button
                size="small"
                icon={<PlusOutlined />}
                style={{ fontWeight: 100 }}
                onClick={() => setAddChatVisible(true)}
              />
            </div>
            <div
              style={{
                overflow: 'auto',
                height: 644,
              }}
            >
              {currentChat &&
                chatFilter().map((chat, index) => {
                  return (
                    <div
                      key={`chatItem_${index}`}
                      style={{
                        backgroundColor: chat.target_id === currentChat.target_id ? '#F5F5F5' : 'white',
                      }}
                    >
                      <ChatItem
                        avatar={chat.avatar}
                        title={chat.title}
                        subtitle={chat.subtitle}
                        date={chat.date}
                        unread={chat.unread}
                        onClick={() => {
                          setCurrentChat(chat);
                          setVisible(false);
                        }}
                        lazyLoadingImage={true}
                        dateString={chat.dateString}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: 900,
            }}
          >
            <div
              style={{
                height: 50,
                borderBottom: '1px solid #e8e8e8',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h4 style={{ padding: '0 16px', color: '#24292e' }}>{chatWindowTitle}</h4>
              {currentChat && currentChat.type === 3 && (
                <EllipsisOutlined
                  style={{
                    color: '#24292e',
                    paddingRight: 56,
                    fontSize: 25,
                    cursor: 'pointer',
                  }}
                  onClick={() => setVisible(!visible)}
                />
              )}
            </div>
            {currentChat && (
              <MessageHistoryWindow
                chat={currentChat}
                im={im}
                setIMVisible={setIMVisible}
                visible={visible}
                setVisible={setVisible}
                setChatWindowTitle={setChatWindowTitle}
              />
            )}
          </div>
        </div>
      )}
      {route === 'contacts' && <Contacts sendTrunIntoSingleNotice={sendTrunIntoSingleNotice} setRoute={setRoute} />}
    </div>
  );
};

const AddChatWidget = (props: { selectedUser: string[]; setSelectedUser: Function; userInfo: UserInfoItem }) => {
  const { selectedUser, setSelectedUser, userInfo } = props;
  const [allUser, setAllUser] = React.useState<UserInfoItem[]>([]);
  const [filterUserName, setFilterUserName] = React.useState('');

  const fetchAllUser = async () => {
    if (!userInfo) return;

    const res = await UserRequests.getAllUsers({
      limit: 10000,
      skip: 0,
      username: filterUserName,
    });
    if (res.code === 0) {
      setAllUser(res.data.filter((x) => x.user_id !== userInfo.user_id));
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchAllUser();
  }, []);

  return (
    <div style={{ height: 400 }}>
      <div>
        <Input.Search
          value={filterUserName}
          onChange={(e) => {
            setFilterUserName(e.target.value);
          }}
          placeholder="搜索用户"
          onSearch={(value) => fetchAllUser()}
          enterButton="搜索"
        />
      </div>
      <div
        style={{
          height: 350,
          overflow: 'scroll',
          marginTop: 16,
        }}
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(checkedValues) => {
            setSelectedUser(checkedValues);
          }}
          value={selectedUser}
        >
          {allUser.map((user) => {
            return (
              <Row key={user.user_id} style={{ padding: '10px 0', display: 'flex', alignItems: 'center' }}>
                <Checkbox value={JSON.stringify(user)} style={{ marginRight: 16 }}></Checkbox>
                <List.Item.Meta
                  avatar={
                    user.avatar ? (
                      <Avatar src={`/v2/api/user/avatar/${user.avatar}`} shape="square" />
                    ) : (
                      <Avatar shape="square" style={{ backgroundColor: '#1890ff' }}>
                        {user.username.slice(0, 1)}
                      </Avatar>
                    )
                  }
                  title={user.username}
                />
              </Row>
            );
          })}
        </Checkbox.Group>
      </div>
    </div>
  );
};

export default IM;
