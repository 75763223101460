import * as React from 'react';
import 'react-chat-elements/dist/main.css';
import { MessageBox } from 'react-chat-elements';

import {
  Avatar,
  Card,
  Tag,
  Modal,
  Drawer,
  message,
  Form,
  Input,
  List,
  // Icon,
  Checkbox,
  Image,
  Row,
  Col,
} from 'antd';
import DrawerAssetEditor from '@/ui-components/DrawerAssetEditor';
import { useCtxState } from '@/App.store';
import './index.less';
import { PlusOutlined } from '@ant-design/icons';
import * as IMRequests from '@/requests/rongcloud';
import * as RongIMLib from '@rongcloud/imlib-v4';
import * as UserRequests from '@/requests/user';
import InfiniteScroll from 'react-infinite-scroller';
import { MessageTypeMapper, TxtMsgType, getMessageDateString } from './utils';
import MessageSenderWidget from './MessageSenderWidget';

interface MessageHistoryWindowProps {
  chat: ChatListItem;
  im: RongIMLib.IMClient;
  setIMVisible: Function;
  visible: boolean;
  setVisible: Function;
  setChatWindowTitle: Function;
}

const MessageHistoryWindow = (props: MessageHistoryWindowProps) => {
  const { chat, im, setIMVisible, visible } = props;
  const [{ userInfo }] = useCtxState();
  const [messageList, setMessageList] = React.useState<MessageItem[]>([]);
  const [hasMore, setHasMore] = React.useState(true);
  const userInfos = React.useRef<UserInfoCacheItem[]>([]);
  const timestamp = React.useRef(0);
  const [editingStatus, setEditingStatus] = React.useState<StatusListItem | undefined>(undefined);
  const [editingAssetUid, setEditingAssetUid] = React.useState('');

  const getGroupInfo = async () => {
    if (!chat) return;
    if (chat.type === 3) {
      const res = await IMRequests.getUserInfoOfGroup(chat.target_id);
      if (res.code === 0) {
        userInfos.current = res.data.map((x) => {
          return {
            user_id: x.user_id,
            name: x.username,
            avatar: `v2/api/user/avatar/${x.avatar}`,
          };
        });
      }
    } else if (chat.type === 1) {
      const res = await UserRequests.getUserInfoByUserId(chat.target_id);
      if (res.code === 0) {
        userInfos.current = [
          {
            name: res.data.username,
            avatar: `v2/api/user/avatar/${res.data.avatar}`,
            user_id: res.data.user_id,
          },
        ].concat([
          {
            name: userInfo.username,
            avatar: `v2/api/user/avatar/${userInfo.avatar}`,
            user_id: userInfo.user_id,
          },
        ]);
      }
    }
  };

  React.useEffect(() => {
    setHasMore(false);
    timestamp.current = 0;
    getGroupInfo().then(() => {
      getMessageHistory();
    });
  }, [chat.target_id]);

  //当消息列表变化时，滑动到最底端
  React.useEffect(() => {
    console.log('消息列表变化', messageList);
    if (timestamp.current === 0) {
      scrollToBottom();
    }
  }, [messageList]);

  const handleRecieveNewMessage = (message: any) => {
    const list = messageList.map((x) => x);
    list.push(handleMessage(message));
    setMessageList(list);
  };

  //处理发送消息成功
  const handleSendMessageSuccess = (message: any) => {
    const list = messageList.map((x) => x);
    list.push(handleMessage(message));
    setMessageList(list);
  };
  React.useEffect(() => {
    im.watch({
      // 监听消息通知
      message(event) {
        // 新接收到的消息内容
        const message = event.message;
        // console.log("new messageaaaaaaaaaaa", message, chat.target_id);
        //ignore 消息状态
        if (
          message.messageType === 'RC:ReadNtf' ||
          message.messageType === 'RC:SRSMsg' ||
          message.messageType === 'RC:TypSts'
        ) {
          return;
        }
        if (message.targetId === chat.target_id) {
          handleRecieveNewMessage(message);
          // clearUnread();
          scrollToBottom();
        }
      },
    });
  });

  const ScrollContainerEl = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (ScrollContainerEl.current) {
      const scrollHeight = ScrollContainerEl.current.scrollHeight;
      const height = ScrollContainerEl.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      ScrollContainerEl.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  const handleMessage = (message: any) => {
    const info = userInfos.current.find((item) => item.user_id === message.senderUserId);
    let name = '';
    let avatar = '';
    if (info) {
      name = info.name;
      avatar = info.avatar;
    }
    if (message.messageType === 'RC:ImgMsg') {
      return {
        title: name,
        avatar: avatar,
        position: message.senderUserId === userInfo.user_id ? 'right' : 'left',
        type: MessageTypeMapper(message.messageType),
        data: {
          uri: message.content.imageUri,
          height: 100,
          width: 150,
        },
        date: new Date(message.sentTime),
        dateString: getMessageDateString(message.sentTime),
      };
    } else if (message.messageType === 'RC:SightMsg') {
      return {
        title: name,
        avatar: avatar,
        position: message.senderUserId === userInfo.user_id ? 'right' : 'left',
        type: MessageTypeMapper(message.messageType),
        data: {
          videoURL: message.content.sightUrl,
          status: {
            download: true,
          },
        },
        date: new Date(message.sentTime),
        dateString: getMessageDateString(message.sentTime),
      };
    } else if (message.messageType === 'RC:HQVCMsg') {
      return {
        title: name,
        avatar: avatar,
        position: message.senderUserId === userInfo.user_id ? 'right' : 'left',
        type: MessageTypeMapper(message.messageType),
        data: {
          audioURL: message.content.remoteUrl,
        },
        date: new Date(message.sentTime),
        dateString: getMessageDateString(message.sentTime),
        onLoad: (e: any) => console.log(e),
      };
    }
    return {
      title: name,
      avatar: avatar,
      position: message.senderUserId === userInfo.user_id ? 'right' : 'left',
      type: MessageTypeMapper(message.messageType),
      text: typeof message.content.content === 'string' ? message.content.content : '未知消息类型',
      date: new Date(message.sentTime),
      dateString: getMessageDateString(message.sentTime),
    };
  };

  //加载消息
  const getMessageHistory = async () => {
    const conversation = im.Conversation.get({
      targetId: chat.target_id,
      type: chat.type,
    });
    const option = {
      // 获取历史消息的时间戳，默认为 0，表示从当前时间获取
      timestamp: timestamp.current,
      // 获取条数，有效值 1-20，默认为 20
      count: 20,
    };
    conversation
      .getMessages(option)
      .then(async (result) => {
        const list = result.list; // 获取到的消息列表
        const _hasMore = result.hasMore; // 是否还有历史消息可获取
        setHasMore(_hasMore);
        console.log('获取历史消息成功', list, _hasMore);
        const _messageList: MessageItem[] = list.map((x: any) => {
          return handleMessage(x);
        });
        if (timestamp.current === 0) {
          setMessageList(_messageList.concat([]));
        } else {
          setMessageList(_messageList.concat(messageList));
        }
        if (_hasMore) {
          timestamp.current = list[0].sentTime;
        }
      })

      .catch((error) => {
        console.log('获取历史消息失败', error.code, error.msg);
      });
  };

  const AssetMeesageBox = (props: { content: string }) => {
    const { content } = props;
    const dataStr = content.replace(/\~\!Asset\#\&/gi, '');
    const asset: AssetItem = JSON.parse(dataStr);

    const { asset_comps, asset_id, v_asset_status, v_asset_name } = asset;
    return (
      <Card
        title={asset.asset_name}
        extra={
          <a
            href={``}
            onClick={(e) => {
              e.preventDefault();
              setEditingAssetUid(asset.asset_id);
            }}
          >
            详情
          </a>
        }
        style={{ width: 300 }}
      >
        <p>
          {`资产组件：`}
          {asset_comps.length > 0
            ? asset_comps.map((x, c_index) => <Tag key={`comps=${asset_id}-${c_index}`}>{x}</Tag>)
            : '无'}
        </p>
        <p>
          {`地理名称：`} {v_asset_name ? v_asset_name : '无'}
        </p>
        <p>
          {`地理信息：`}{' '}
          {v_asset_status.length > 0
            ? v_asset_status.map((x, v_index) => (
                <Tag
                  color={'#1890ff'}
                  key={`vcomps=${asset_id}-${v_index}`}
                >{`${x.v_status_name}：${x.v_status_value}`}</Tag>
              ))
            : '无'}
        </p>
      </Card>
    );
  };

  const AssetStatusMeesageBox = (props: { content: string }) => {
    const { content } = props;
    const dataStr = content.replace(/\*\&assetValue\#\%/gi, '');
    const status: StatusListItem = JSON.parse(dataStr);

    const { status_name, status_value, asset_id, status_type } = status;
    return (
      <Card
        title={status_name}
        extra={
          <a
            href={``}
            onClick={(e) => {
              e.preventDefault();
              setEditingAssetUid(status.asset_id);
              setEditingStatus(status);
            }}
          >
            详情
          </a>
        }
        style={{ width: 300 }}
      >
        <p>
          {`状态值：`}
          {status_value ? status_value : '无'}
        </p>
        <p>
          {`状态值类型`} {status_type ? status_type : '无'}
        </p>
      </Card>
    );
  };

  const FormMeesageBox = (props: { content: string }) => {
    const { content } = props;
    const dataStr = content.replace(/\@\#Form\&\*/gi, '');
    const formData: FormDataItem = JSON.parse(dataStr);
    if (!formData.form_data) return null;
    return (
      <Card
        title={formData.form_data.form_template_name}
        extra={
          <a
            href={`/#/workbench/forms-management/form-data-list/formdata-detail?form_id=${formData.form_data.form_id}`}
            onClick={() => setIMVisible(false)}
          >
            详情
          </a>
        }
        style={{ width: 300 }}
      >
        <p>{`创建者：${formData.form_data.creator_name}`}</p>
        <p>{`版本号：${formData.form_data.form_template_version_number}`}</p>
        <p>{`创建日期：${formData.form_data.submitted_time}`}</p>
      </Card>
    );
  };
  const NormalFormMeesageBox = (props: { content: string }) => {
    const { content } = props;
    const dataStr = content.replace(/\@\#NormalForm\&\*/gi, '');
    const formData: ShareTemplateItem = JSON.parse(dataStr);

    return (
      <Card
        title={formData.form_template_name}
        extra={
          <a
            href={`/#/workbench/normal-form/write-page?uid=${formData.id}&version_number=${formData.version_number}`}
            onClick={() => setIMVisible(false)}
          >
            详情
          </a>
        }
        style={{ width: 300 }}
      >
        <p>{`创建者：${formData.create_user_name}`}</p>
        <p>{`版本号：${formData.version_number}`}</p>
        <p>{`创建日期：${formData.create_time}`}</p>
      </Card>
    );
  };

  const RenderMessageItem = (props: { msg: MessageItem }) => {
    const { position, type, text, date, dateString, data, avatar } = props.msg;
    //区分文字消息与特殊消息，包括，表单，资产，资产状态等
    if (type === 'text' && text !== undefined) {
      //表单消息
      if (text.startsWith(TxtMsgType.FORM) && text.endsWith(TxtMsgType.FORM)) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <div style={{ padding: 5 }}>
                <FormMeesageBox content={text} />
              </div>
            </div>
          </div>
        );
      }
      //一般表单消息
      if (text.startsWith(TxtMsgType.NORMALFORM) && text.endsWith(TxtMsgType.NORMALFORM)) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <div style={{ padding: 5 }}>
                <NormalFormMeesageBox content={text} />
              </div>
            </div>
          </div>
        );
      }
      //资产状态消息
      else if (text.startsWith(TxtMsgType.ASSET_STATUS) && text.endsWith(TxtMsgType.ASSET_STATUS)) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <div style={{ padding: 5 }}>
                <AssetStatusMeesageBox content={text} />
              </div>
            </div>
          </div>
        );
      }
      //资产消息
      else if (text.startsWith(TxtMsgType.ASSET) && text.endsWith(TxtMsgType.ASSET)) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <div style={{ padding: 5 }}>
                <AssetMeesageBox content={text} />
              </div>
            </div>
          </div>
        );
      }
      //群系统消息
      else if (text.startsWith(TxtMsgType.GROUP) && text.endsWith(TxtMsgType.GROUP)) {
        return (
          <div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#969494',
                paddingBottom: 4,
              }}
            >
              {dateString}
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 12,
                color: '#646464',
                paddingBottom: 16,
              }}
            >
              {text.replace(/\*\*\%\%裠屪\%\%\*\*/gi, '')}
            </div>
          </div>
        );
      }
      //缺陷消息
      else if (text.startsWith(TxtMsgType.DEFECT) && text.endsWith(TxtMsgType.DEFECT)) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <div>缺陷消息</div>
            </div>
          </div>
        );
      }
      //地理信息消息
      else if (text.startsWith(TxtMsgType.GEOGRAPHIC) && text.endsWith(TxtMsgType.GEOGRAPHIC)) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <div>地理信息</div>
            </div>
          </div>
        );
      }
      //普通消息
      else {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
              padding: '8px 0',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: position === 'left' ? 'row' : 'row-reverse',
              }}
            >
              <Avatar src={`${avatar}`} size="default" />
              <MessageBox position={position} type={'text'} text={text} date={date} dateString={dateString} />
            </div>
          </div>
        );
      }
      //图片消息
    } else if (type === 'photo') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
            padding: '8px 0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: position === 'left' ? 'row' : 'row-reverse',
            }}
          >
            <Avatar src={`${avatar}`} size="default" />

            <Image src={data.uri} width={data.width} style={{ borderRadius: 5, padding: 5 }} />
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
            padding: '8px 0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: position === 'left' ? 'row' : 'row-reverse',
            }}
          >
            <Avatar src={`${avatar}`} size="default" />
            <MessageBox position={position} type={type} text={text} date={date} dateString={dateString} data={data} />
          </div>
        </div>
      );
    }
  };

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {chat && chat.type === 3 && visible && (
        <Drawer
          title=""
          visible={visible}
          getContainer={false}
          style={{ position: 'absolute' }}
          mask={false}
          closable={false}
          bodyStyle={{ padding: 16, overflow: 'scroll' }}
          destroyOnClose
        >
          <ChatEditor
            chat={chat}
            im={im}
            userInfo={userInfo}
            handleSendMessageSuccess={handleSendMessageSuccess}
            setChatWindowTitle={props.setChatWindowTitle}
          />
        </Drawer>
      )}
      <div
        style={{
          overflow: 'auto',
          padding: 16,
          height: 500,
        }}
        ref={ScrollContainerEl}
      >
        <div>
          <DrawerAssetEditor
            asset_id={editingAssetUid}
            destroyOnClose
            onClose={() => {
              setEditingAssetUid('');
              setEditingStatus(undefined);
            }}
            info={userInfo}
            status={editingStatus ? editingStatus : undefined}
          ></DrawerAssetEditor>

          <InfiniteScroll
            pageStart={1}
            loadMore={() => getMessageHistory()}
            hasMore={hasMore}
            loader={
              <div style={{ textAlign: 'center' }} key={0}>
                加载中
              </div>
            }
            isReverse={true}
            useWindow={false}
            initialLoad={false}
            getScrollParent={() => ScrollContainerEl.current}
          >
            {messageList.map((x, index) => {
              return <RenderMessageItem msg={x} key={`messageItem-${index}`} />;
            })}
          </InfiniteScroll>
        </div>
      </div>

      {chat && <MessageSenderWidget im={im} currentChat={chat} handleSendMessageSuccess={handleSendMessageSuccess} />}
    </div>
  );
};

const ChatEditor = (props: {
  chat: ChatListItem;
  im: RongIMLib.IMClient;
  userInfo: UserInfoItem;
  handleSendMessageSuccess: Function;
  setChatWindowTitle: Function;
}) => {
  const { chat, im, userInfo, handleSendMessageSuccess, setChatWindowTitle } = props;
  const [groupInfo, setGroupInfo] = React.useState<GroupItem | undefined>(undefined);
  const [groupUserInfo, setGroupUserInfo] = React.useState<UserInfoItem[]>([]);
  const [addUserModalVisible, setAddUserModalVisible] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<string[]>([]);
  const fetchGroupInfo = async () => {
    const res = await IMRequests.queryGroup(chat.target_id);
    if (res.code === 0) {
      setGroupInfo(res.data);
    } else {
      message.error('网络错误');
    }
  };

  const fetchGroupUserInfo = async () => {
    const res = await IMRequests.getUserInfoOfGroup(chat.target_id);
    if (res.code === 0) {
      setGroupUserInfo(res.data);
    } else {
      message.error('网络错误');
    }
  };

  const updateGroupName = async (newVal: string) => {
    if (!groupInfo) return;
    if (newVal === groupInfo.group_name) return;
    const res = await IMRequests.updateGroup({
      group_id: groupInfo.group_id,
      group_name: newVal,
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchGroupInfo();
      setChatWindowTitle(`${newVal}(${groupUserInfo.length})`);
      sendUpdateGroupNotice(`"${userInfo.username}"修改群名为"${newVal}"`);
    } else {
      message.error('保存失败');
    }
  };
  //发送更改群名称通知
  const sendUpdateGroupNotice = (content: string) => {
    const prefix = '**%%裠屪%%**';
    if (!chat) return;
    let conversation = im.Conversation.get({
      targetId: chat.target_id,
      type: chat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + content + prefix, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        console.log('发送更改群名称消息成功', message);
      });
  };
  //发送编辑群成员通知
  const sendUpdateGroupUsersNotice = (content: string) => {
    const prefix = '**%%裠屪%%**';
    if (!chat) return;
    let conversation = im.Conversation.get({
      targetId: chat.target_id,
      type: chat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + content + prefix, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        console.log('发送编辑群成员消息成功', message);
      });
  };

  const deleteUser = async (user_id: string, user_name: string) => {
    if (!user_id) return;
    if (!groupInfo) return;
    const res = await IMRequests.deleteUserFromGroup({
      group_id: groupInfo.group_id,
      user_ids: [user_id],
    });
    if (res.code === 0) {
      message.success('移除成功');
      fetchGroupUserInfo();
      setChatWindowTitle(`${chat.title}(${groupUserInfo.length - 1})`);
      sendUpdateGroupUsersNotice(`"${user_name}"被"${userInfo.username}"移出了群聊`);
    } else {
      message.error(res.message);
    }
  };

  const addUser = async (user_ids: string[], user_names: string[]) => {
    if (user_ids.length < 1) return;
    if (!groupInfo) return;
    const res = await IMRequests.addUser2Group({
      group_id: groupInfo.group_id,
      user_ids: user_ids,
    });
    if (res.code === 0) {
      message.success('添加成功');
      fetchGroupUserInfo();
      setChatWindowTitle(`${chat.title}(${groupUserInfo.length + user_ids.length})`);
      setSelectedUser([]);

      sendUpdateGroupUsersNotice(`"${userInfo.username}"邀请“${user_names.toString()}”进入群聊`);
      setAddUserModalVisible(false);
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    console.log('hahahhahahah');
    fetchGroupInfo();
    fetchGroupUserInfo();
  }, []);

  return (
    <div style={{ fontSize: 12 }}>
      {groupInfo && groupUserInfo && (
        <div>
          <Modal
            visible={addUserModalVisible}
            okText="确认"
            cancelText="取消"
            onOk={() => {
              addUser(
                selectedUser.map((x) => JSON.parse(x).user_id),
                selectedUser.map((x) => JSON.parse(x).username)
              );
            }}
            onCancel={() => {
              setAddUserModalVisible(false);
            }}
            title="添加成员"
            destroyOnClose
          >
            <AddUserWidget ExistedUser={groupUserInfo} setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
          </Modal>
          <Form layout="vertical">
            <Form.Item label="群聊名称：">
              <Input
                defaultValue={groupInfo.group_name}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    updateGroupName(e.currentTarget.value);
                  }
                }}
              />
            </Form.Item>
            <Form.Item label="成员：">
              <List
                dataSource={groupUserInfo}
                split={false}
                renderItem={(item) => {
                  return (
                    <List.Item
                      actions={[
                        <a
                          style={{ color: 'red' }}
                          key="list-loadmore-more"
                          onClick={(e) => {
                            e.preventDefault();
                            Modal.confirm({
                              title: '提示',
                              content: '确认移除该成员吗？',
                              onOk: () => deleteUser(item.user_id, item.username),
                              okText: '确认',
                              onCancel: () => {},
                              cancelText: '取消',
                            });
                          }}
                        >
                          移除
                        </a>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={<Avatar src={`/v2/api/user/avatar/${item.avatar}`} shape="square" />}
                        title={item.username}
                      />
                    </List.Item>
                  );
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 32,
                    height: 32,
                    border: '1px dashed #d9d9d9',
                    marginRight: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                  }}
                  onClick={() => setAddUserModalVisible(true)}
                >
                  <PlusOutlined style={{ fontSize: 18, color: '#999' }} />
                </div>
                <span style={{ fontWeight: 500, color: 'rgba(0, 0, 0, 0.65)' }}>添加成员</span>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

const AddUserWidget = (props: {
  ExistedUser: UserInfoItem[];

  selectedUser: string[];
  setSelectedUser: Function;
}) => {
  const { ExistedUser, selectedUser, setSelectedUser } = props;
  const [loading, setLoading] = React.useState(false);
  const [allUser, setAllUser] = React.useState<UserInfoItem[]>([]);
  const [filterUserName, setFilterUserName] = React.useState('');

  const fetchAllUser = async () => {
    setLoading(true);
    const res = await UserRequests.getAllUsers({
      limit: 10000,
      skip: 0,
      username: filterUserName,
    });
    if (res.code === 0) {
      setAllUser(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchAllUser();
  }, []);

  return (
    <div style={{ height: 300 }}>
      <div>
        <Input.Search
          value={filterUserName}
          onChange={(e) => {
            setFilterUserName(e.target.value);
          }}
          placeholder="搜索用户"
          onSearch={(value) => fetchAllUser()}
          enterButton="搜索"
        />
      </div>
      <div
        style={{
          height: 250,
          overflow: 'scroll',
          marginTop: 16,
        }}
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(checkedValues) => {
            setSelectedUser(checkedValues);
          }}
          value={selectedUser}
        >
          {allUser.map((user) => {
            return (
              <Row key={user.user_id} style={{ padding: '10px 0', display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  value={JSON.stringify(user)}
                  disabled={!!ExistedUser.find((x) => x.user_id === user.user_id)}
                  style={{ marginRight: 16 }}
                ></Checkbox>

                <List.Item.Meta
                  avatar={
                    user.avatar ? (
                      <Avatar src={`/v2/api/user/avatar/${user.avatar}`} shape="square" />
                    ) : (
                      <Avatar shape="square" style={{ backgroundColor: '#1890ff' }}>
                        {user.username.slice(0, 1)}
                      </Avatar>
                    )
                  }
                  title={user.username}
                />
              </Row>
            );
          })}
        </Checkbox.Group>
      </div>
    </div>
  );
};

export default MessageHistoryWindow;
