import React from 'react';
import { Modal, Input, Form, Select, message, Button } from 'antd';
import * as AssetsRequest from '@/requests/assets';
import * as Utils from '@/utils/string';
import * as SuggestionRequests from '@/requests/suggestion';
import * as strUtils from '@/utils/string';
import { arrSet } from '@/utils/array';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  doFetch: Function;
}

const AssetModal = (props: PropsType) => {
  const { visible, setVisible, doFetch } = props;
  const [fetching, setFetching] = React.useState(false);
  const [selectedGeos, setSelectedGeo] = React.useState<string[]>([]);
  const [form] = Form.useForm();

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([]);
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([]);
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([]);

  const handleOk = async (values: any) => {
    console.log(values);
    doCreateAsset({
      asset_name: values['asset_name'],
      asset_comps: values['asset_comps'],
      v_asset_name: values['v_asset_name'],
      v_asset_comps: values['v_asset_comps'],
      v_asset_status: values['v_asset_comps'].map((x: string) => {
        return {
          v_status_name: x,
          v_status_type: '文本',
          v_status_value: values[x],
        } as Vassetstatus;
      }),
      comment: 'no comment',
      form_id: Utils.uuid(),
      task_id: Utils.uuid(),
    });
  };

  const handleCancel = (e: any) => {
    setVisible(false);
  };

  const doCreateAsset = async (input: CreateAssetReq) => {
    setFetching(true);
    const res = await AssetsRequest.createAsset(input);
    if (res.code === 0) {
      message.success('创建成功');
      doFetch();
      setVisible(false);
    } else {
      message.error('创建失败');
    }
    setFetching(false);
  };

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>创建资产
        </span>
      }
      visible={visible}
      destroyOnClose
      afterClose={() => setSelectedGeo([])}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        onFinish={handleOk}
        preserve={false}
        onValuesChange={(changedValues, allValues) => {
          if (Object.keys(changedValues)[0] === 'v_asset_comps') {
            setSelectedGeo(changedValues[Object.keys(changedValues)[0]]);
          }
        }}
        labelAlign="right"
        layout="horizontal"
      >
        <Form.Item
          label="资产名称"
          name="asset_name"
          rules={[
            {
              required: true,
              message: '请输入资产名称',
            },
          ]}
        >
          <Select
            style={{ minWidth: 168 }}
            showSearch
            placeholder="请输入资产名称"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await SuggestionRequests.getAssetNameSuggestion();
                if (res.code === 0) {
                  setAssetNameOptions(res.data);
                } else {
                }
              }
            }}
            onSearch={async (value) => {
              if (strUtils.isNull(value)) {
                const res = await SuggestionRequests.getAssetNameSuggestion();
                if (res.code === 0) {
                  setAssetNameOptions(res.data);
                } else {
                }
              }

              if (value) {
                const res = await SuggestionRequests.getAssetNameSuggestion(value);
                if (res.code === 0) {
                  setAssetNameOptions([value].concat(res.data));
                } else {
                }
              } else {
                return;
              }
            }}
          >
            {assetNameOptions.map((x: string) => {
              return (
                <Select.Option value={x} key={`asset_name_option:${x}`}>
                  {x}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="资产组件" name="asset_comps" rules={[{ required: true, message: '请输入资产组件' }]}>
          <Select
            style={{ minWidth: 168 }}
            placeholder="请选择或输入资产组件"
            mode={'tags'}
            onDropdownVisibleChange={async (open) => {
              if (open) {
                if (!form.getFieldValue('asset_name')) return;
                const res = await SuggestionRequests.getAssetCompsSuggestion(form.getFieldValue('asset_name'));
                if (res.code === 0) {
                  setAssetCompsOptions(arrSet(res.data));
                } else {
                }
              }
            }}
          >
            {assetCompsOptions.map((x: string, index) => {
              return (
                <Select.Option value={x} key={`asset_comps_option:${index}`}>
                  {x}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="地理名称"
          name="v_asset_name"
          rules={[
            {
              required: true,
              message: '请输入地理名称',
            },
          ]}
        >
          <Input placeholder="请输入地理名称" />
        </Form.Item>
        <Form.Item label="地理组件" name="v_asset_comps" rules={[{ required: true, message: '请输入地理组件' }]}>
          <Select
            style={{ minWidth: 168 }}
            placeholder="全部"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const asset_name = form.getFieldValue('asset_name');
                const asset_comps = form.getFieldValue('asset_comps');
                if (!asset_name || !asset_comps) return;
                const res = await SuggestionRequests.getAssetVCompsSuggestion({
                  asset_name,
                  asset_comps,
                });
                if (res.code === 0) {
                  setVAssetQueryOptions(arrSet(res.data));
                } else {
                }
              }
            }}
            mode="tags"
          >
            {vAssetQueryOptions.map((item, index) => {
              return (
                <Select.Option key={item + index} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {selectedGeos.length > 0 &&
          selectedGeos.map((x, _index) => {
            return (
              <Form.Item
                label={x}
                name={x}
                key={_index}
                rules={[
                  {
                    required: true,
                    message: `请输入${x}`,
                  },
                ]}
              >
                <Input placeholder={`请输入${x}`} />
              </Form.Item>
            );
          })}
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssetModal;
