import * as React from 'react';
import { Table, message } from 'antd';
import * as TagRequests from '@/requests/tag';
import SingleUserCompanyIdTooltip from '@/ui-components/SingleUserCompanyIdTooltip';

const colunms = [
  {
    title: '序号',
    dataIndex: 'order',
    key: 'order',
    align: 'center' as 'center',
    render: (text: any, record: TagItem, index: number) => `${index + 1}`,
  },
  {
    title: '状态值',
    dataIndex: 'value',
    key: 'value',
    align: 'center' as 'center',
    render: (text: any, record: TagItem, index: number) => `${record.tag_value}`,
  },
  {
    title: '记录日期',
    dataIndex: 'date',
    key: 'date',
    align: 'center' as 'center',
    render: (text: any, record: TagItem, index: number) => `${record.update_time}`,
  },
  {
    title: '记录人',
    dataIndex: 'user',
    key: 'user',
    align: 'center' as 'center',
    render: (text: any, record: TagItem, index: number) => {
      return (
        <span>
          {record.update_user_name}
          <SingleUserCompanyIdTooltip user_id={record.update_user_id} />
        </span>
      );
    },
  },
];

const TagValueHistoryTable = ({ asset_tag_id }: { asset_tag_id: TagItem['asset_tag_id'] }) => {
  const [data, setData] = React.useState<TagItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const fetchData = async () => {
    if (!asset_tag_id) return;
    setFetching(true);
    const res = await TagRequests.getHistory(asset_tag_id);
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Table dataSource={data} columns={colunms} loading={fetching} size="small" pagination={false} />
    </div>
  );
};

export default TagValueHistoryTable;
