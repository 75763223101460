import * as React from 'react';
import { Input, message, Modal, List, Avatar, Button } from 'antd';
import { setLabelTaskWorker, getLabelProjectByUid } from '@/requests/labelProject';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { getAllUsers } from '@/requests/user';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  task: LabelTaskItem | undefined;
  setTask: Function;
  fetchData: Function;
  project?: LabelProjectListItem;
}

interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  company_user_id: string;
}

const SetLabelPersonModal: React.FC<PropsType> = (props) => {
  const { visible, setVisible, task, fetchData, setTask, project } = props;
  const [members, setMembers] = React.useState<Member[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');

  const searchUser = async () => {
    if (!task) return;
    setLoading(true);
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: filterName,
    });
    const _project = project
      ? await getLabelProjectByUid(project.project_uid)
      : await getLabelProjectByUid(task.project_uid);
    const memRes = _project.data.participant_user_list;
    if (res.code === 0) {
      if (res.data.length < 1) {
        setMembers([]);
      } else {
        setMembers(
          memRes.map((x) => {
            const match = res.data.find((y) => y.id === x.id) as any;
            return {
              user_id: match.id,
              avatar: match.avatar,
              user_name: match.username,
              email: match.email,

              company_user_id: match.company_user_id,
            };
          })
        );
      }
    }
    setLoading(false);
  };

  const doAddMember = async (user_id: UserInfoItem['id']) => {
    if (!task) return;
    setLoading(true);
    const res = await setLabelTaskWorker(task.task_uid, user_id);
    if (res.code === 0) {
      message.success('设置成功');
      setTask(undefined);
      setVisible(false);
      fetchData();
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const handleCnacel = () => {
    setVisible(false);
    setTask(undefined);
  };

  React.useEffect(() => {
    if (visible) {
      searchUser();
    }
  }, [visible]);
  React.useEffect(() => {
    if (filterName === '') {
      searchUser();
    }
  }, [filterName]);
  if (!task) return null;
  return (
    <Modal
      visible={visible}
      onCancel={handleCnacel}
      footer={null}
      title="设置标注人"
      bodyStyle={{ padding: 16, height: 450, overflow: 'scroll' }}
      destroyOnClose={true}
    >
      <div>
        <div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="输入昵称按回车键查找"
            onChange={(e) => setFilterName(e.target.value)}
            onPressEnter={() => {
              searchUser();
            }}
            value={filterName}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <List
            dataSource={members}
            locale={{
              emptyText: '没有搜索到相关成员',
            }}
            loading={loading}
            renderItem={(item) => (
              <List.Item
                extra={
                  <Button
                    onClick={() => {
                      doAddMember(item.user_id);
                    }}
                  >
                    设置
                  </Button>
                }
              >
                <List.Item.Meta
                  avatar={
                    item.avatar ? (
                      <Avatar src={`v2/api/user/avatar/${item.avatar}`} />
                    ) : (
                      <Avatar>{item.user_name}</Avatar>
                    )
                  }
                  title={`${item.user_name}(工号：${item.company_user_id})`}
                  description={item.email}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SetLabelPersonModal;
