import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Descriptions,
  Button,
  Radio,
  Row,
  Col,
  Slider,
  InputNumber,
  Image,
  Tabs,
  Divider,
  Input,
  Tooltip,
  Badge,
  Checkbox,
  Upload,
  message,
  notification,
  Table,
  Modal,
  Steps,
  Spin,
  Form,
  Select,
  DatePicker,
  Result,
  Tag,
  Empty,
} from 'antd';
import {
  QuestionCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  SearchOutlined,
  RedoOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import * as SuggestionRequests from '@/requests/suggestion';
import * as strUtils from '@/utils/string';
import ImgCrop from 'antd-img-crop';
import { getChildApi, detectImgs } from '@/requests/AIDetection';
import { arrSet } from '@/utils/array';
import * as AssetsRequest from '@/requests/assets';
import '../styles/assets.less';
import CreateAssetModal from '@/ui-routes/AssetManagement/AssetList/CreateAssetModal';
import { BaseStatusFormValues } from '@/ui-components/DrawerAssetEditor/index';
import { useCtxState } from '@/App.store';
import { useCacheRoute } from '@/ui-layouts/header';
import moment from 'moment';

const noOp = (...args: any[]) => undefined;

interface AddStatusModalProps {
  visible: boolean;
  setVisible: Function;
  imgList: ImgList[];
  setImgList: Function;
  editingImgID: string;
  selectApi: string[];
  setEditingImg: Function;
  editingImg: ImgList;
}

interface ImgList {
  image_uid: string;
  asset_status_list: ImgStatusInfo[];
  file_name: string;
}
interface ImgStatusInfo {
  asset: AssetItem;
  status: string;
  detect_data_result_type: string;
}

const { Step } = Steps;
function getColumnConfigs<ROW extends AssetItem>({
  onEvent = noOp,
  maxVStatusCount,
  maxCompsCount,
}: {
  onEvent: Function;
  maxVStatusCount: number;
  maxCompsCount: number;
}): any[] {
  return [
    {
      title: '资产名',
      key: 'asset_name',
      render(_: any, record: ROW) {
        if (!record.asset_name) return null;
        const curName = record.asset_name;
        return <span>{curName}</span>;
      },
    },

    ...Array(maxCompsCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `AssetsComs-ColChild-${idx}`;
        return {
          key: key,
          title: `资产组件${count}`,
          render(_: any, record: ROW) {
            if (!record.asset_comps) return null;
            const curName = record.asset_comps[idx] ? record.asset_comps[idx] : '';
            return <span>{curName}</span>;
          },
        };
      }),

    {
      title: '地理名称',
      dataIndex: 'v_assets_name',
      key: 'v_assets_name',
      render(_: any, record: ROW) {
        if (!record.v_asset_name) return null;
        const curName = record.v_asset_name || '未设置';
        return <span key={record.toString()}>{curName}</span>;
      },
    },
    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `GeoInfo-ColChild-${idx}`;
        return {
          key,
          title: `地理组件${count}`,
          sorter: (a: ROW, b: ROW) => {
            if (!a.v_asset_status[idx] || !b.v_asset_status[idx]) return;
            return Number(a.v_asset_status[idx].v_status_value) - Number(b.v_asset_status[idx].v_status_value);
          },

          sortDirections: ['descend', 'ascend'],
          render(_: any, record: ROW) {
            let curName = '';
            if (record.v_asset_status[idx]) {
              curName = `${record.v_asset_status[idx].v_status_name}：${record.v_asset_status[idx].v_status_value}`;
            }
            return <span>{curName}</span>;
          },
        };
      }),
    ,
  ].filter((x) => x);
}

const AddStatusModal = (props: AddStatusModalProps) => {
  const { visible, setVisible, imgList, setImgList, selectApi, editingImgID, setEditingImg, editingImg } = props;
  const [childApis, setChildApis] = React.useState<string[]>([]);
  const [statusOptions, setStatusOptions] = React.useState<AssetStatusItem[]>([]);
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [selectChildApi, setSelectChildApi] = React.useState<string>();
  const [selectAsset, setSelectAsset] = React.useState<AssetItem>();
  const [selectStatus, setSelectStatus] = React.useState('');
  const [createStatusVisible, setCreateStatusVisible] = React.useState(false);

  const [assets, setAssets] = React.useState<AssetItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const [showFilterArea, setShowFilterArea] = React.useState(false);

  // 2020.04.12 添加高级筛选条件
  const [filterAssetIds, setFilterAssetIds] = React.useState<string[]>([]);
  const [filter_assetName, set_filter_assetName] = React.useState<string | undefined>(undefined);
  const [filter_tagName, set_filter_tagName] = React.useState<FilterTagItem[]>([]);
  const [filter_assetComps, set_filter_assetComps] = React.useState<string[] | undefined>(undefined);
  const [filter_vAssetComps, set_filter_vAssetComps] = React.useState<{ status_name: string; status_value: string }[]>(
    []
  );
  const [filter_status_create_time, set_filter_status_create_time] = React.useState<{
    start: string;
    end: string;
    dates: any;
  } | null>(null);

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([]);
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([]);
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([]);
  const [vStatusValueOptions, setVStatusValueOptions] = React.useState<string[]>([]);
  const [assetModalVisible, setAssetModalVisible] = React.useState(false);
  const [createStatusForm] = Form.useForm();

  const fetchChildApi = () => {
    let arr: string[] = [];
    setLoading(true);
    selectApi.forEach(async (x) => {
      const res = await getChildApi(x);
      if (res.code === 0) {
        arr.push(...res.data);
      } else {
        message.error('获取检测细项失败');
      }
    });
    setTimeout(() => {
      setChildApis(arr);
      setLoading(false);
    }, 1000);
  };
  //根据选择的资产获取其资产状态信息
  const fetchStatus = async () => {
    if (!selectAsset) return;
    const { asset_id } = selectAsset;
    if (!asset_id) return;
    setLoading(true);
    const res = await AssetsRequest.getAssetItem(asset_id);
    if (res.code === 0) {
      setStatusOptions(res.data.asset_status.filter((x) => x.is_use === 1));
      if (res.data.asset_status.length < 1) {
        message.error('该资产没有状态');
      }
    } else {
      message.error('获取资产状态失败');
    }
    setLoading(false);
  };

  const handleNext = () => {
    if (step === 2) return;
    if (step === 0) {
      if (!selectChildApi) {
        message.error('请选择一个检测项');
        return;
      }
    }
    if (step === 1) {
      if (!selectAsset) {
        message.error('请选择一个资产');
        return;
      }
    }
    setStep(step + 1);
  };
  //上一步
  const handlePre = () => {
    if (step === 0) return;
    setStep(step - 1);
  };
  //下一步
  const handleCancel = () => {
    setVisible(false);
  };
  //点击完成
  const handleOK = () => {
    if (!selectStatus) {
      message.error('请选择一个状态');
      return;
    }
    if (!selectAsset) {
      message.error('请选择一个资产');
      return;
    }

    if (!selectChildApi) {
      message.error('请选择一个检测细项');
      return;
    }
    let _statusList = editingImg.asset_status_list.map((i) => i);
    _statusList.push({
      asset: selectAsset,
      status: selectStatus,
      detect_data_result_type: selectChildApi,
    });

    const newImgList = imgList.map((x) => {
      if (x.image_uid === editingImgID) {
        return {
          ...x,
          asset_status_list: _statusList,
        };
      } else {
        return x;
      }
    });

    setImgList(newImgList);
    setEditingImg({ ...editingImg, asset_status_list: _statusList });
    setVisible(false);
  };
  //获取资产清单
  const doFetchAssets = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await AssetsRequest.fetchFilteredAssetList({
      ...(filterAssetIds.length > 0 && {
        asset_ids: filterAssetIds,
      }),
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_tagName &&
        filter_tagName.length > 0 && {
          tags: filter_tagName.map((x) => x),
        }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps && {
        v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
      }),
      ...(filter_vAssetComps && {
        v_asset_status: filter_vAssetComps.map((x) => x.status_value),
      }),
      ...(filter_status_create_time && {
        start_status_create_time: filter_status_create_time.start,
        end_status_create_time: filter_status_create_time.end,
      }),
      skip: (page - 1) * pageSize,
      limit: pageSize,
    });
    if (res.code === 0) {
      const { data, total } = res;
      setAssets(data);
      const vLengthList = data.map((x) => x.v_asset_comps.length);
      const compsLengthList = data.map((x) => x.asset_comps.length);
      vLengthList.sort((a, b) => b - a);
      compsLengthList.sort((a, b) => b - a);
      setMaxVStatusCount(vLengthList[0]);
      setMaxCopmsCount(compsLengthList[0]);
      setPagination({ page, pageSize, total });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  //资产选择
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AssetItem[]) => {
      setSelectAsset(selectedRows[0]);
    },
  };
  //新建资产状态并绑定至已选择资产状态
  const doCreateStatus = async (values: BaseStatusFormValues) => {
    setSelectStatus(values.status_name);
    setCreateStatusVisible(false);
  };
  //完成新资产绑定
  const handleCreateStatusOk = async (values: BaseStatusFormValues) => {
    doCreateStatus(values);
  };

  const CreateStatusForm = () => {
    return (
      <Form form={createStatusForm} onFinish={handleCreateStatusOk} layout="vertical" preserve={false}>
        <Form.Item
          label="资产状态名"
          name="status_name"
          rules={[
            { required: true, message: '请输入资产状态名称！' },
            {
              validator: async (rule, value, callback) => {
                if (!value) return;
                const res = statusOptions.map((x) => x.status_name).indexOf(value);
                if (res === -1) {
                  return Promise.resolve();
                } else {
                  Modal.confirm({
                    title: '提示',
                    content: '该资产状态已存在，是否选择该资产状态？',
                    onOk: () => {
                      setSelectStatus(value);
                      setCreateStatusVisible(false);
                    },
                    onCancel: () => {
                      return callback('该资产状态已存在！');
                    },
                  });
                  return Promise.reject('该资产状态已存在！');
                }
              },
            },
          ]}
          initialValue={selectChildApi}
        >
          <Input placeholder="请输入资产状态名" />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }}>
              确认
            </Button>
            <Button onClick={() => setCreateStatusVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  React.useEffect(() => {
    if (visible) {
      fetchChildApi();
    }
  }, [visible]);
  React.useEffect(() => {
    if (step === 1) {
      if (assets.length > 0) {
        return;
      } else {
        doFetchAssets();
      }
    }
  }, [step]);
  React.useEffect(() => {
    if (step === 2) {
      setSelectStatus('');
      fetchStatus();
    }
  }, [step]);
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      title={'添加资产状态'}
      width={1400}
      bodyStyle={{
        maxHeight: 1000,
      }}
      footer={null}
    >
      <div style={{ width: '100%', minHeight: 300 }}>
        <Steps current={step}>
          <Step title="选择检测细项" />
          <Step title="绑定资产" />
          <Step title="选择资产状态" />
        </Steps>
        <Descriptions style={{ marginTop: 16 }}>
          <Descriptions.Item
            label={
              <span style={{ color: 'grey' }}>
                {selectChildApi && <CheckCircleFilled style={{ color: 'green' }} />}
                已选择检测细项
              </span>
            }
          >
            {selectChildApi ? selectChildApi : '未选择'}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span style={{ color: 'grey' }}>
                {selectAsset && <CheckCircleFilled style={{ color: 'green' }} />}
                已选择资产id
              </span>
            }
          >
            {selectAsset ? selectAsset.asset_id : '未选择'}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span style={{ color: 'grey' }}>
                {selectStatus && <CheckCircleFilled style={{ color: 'green' }} />}
                已选择资产状态
              </span>
            }
          >
            {selectStatus ? selectStatus : '未选择'}
          </Descriptions.Item>
        </Descriptions>
        {/* 第一步：选择检测细项*/}
        <div
          style={{
            width: '100%',
            maxHeight: 500,
            minHeight: 300,
            overflow: 'scroll',
            display: step === 0 ? 'block' : 'none',
          }}
        >
          <Divider>请从以下选项中选择一项作为检测细项</Divider>
          <Radio.Group value={selectChildApi} onChange={(e) => setSelectChildApi(e.target.value)}>
            <Row style={{ width: '100%' }}>
              {childApis.map((x) => (
                <Col span={8} style={{ margin: '4px 0' }} key={x}>
                  <Radio value={x}>{x}</Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
          <Spin spinning={loading} tip="加载中" style={{ position: 'absolute', top: 300, left: 650 }} />
        </div>
        {/* 第二步：选择资产*/}
        <div
          style={{
            width: '100%',
            maxHeight: 600,
            overflow: 'scroll',
            display: step === 1 ? 'block' : 'none',
            marginTop: 16,
          }}
        >
          <div className="tools-wrapper">
            <div className="asset-list-filter-input-container">
              <Form layout="inline" colon={false}>
                <Form.Item label="资产名称" className="filter-item">
                  <Select
                    style={{ minWidth: 168 }}
                    showSearch
                    placeholder="全部"
                    onDropdownVisibleChange={async (open) => {
                      if (open) {
                        if (filter_assetName) return;
                        const res = await SuggestionRequests.getAssetNameSuggestion();
                        if (res.code === 0) {
                          setAssetNameOptions(res.data);
                        } else {
                        }
                      }
                    }}
                    onSearch={async (value) => {
                      if (strUtils.isNull(value)) {
                        return;
                      }

                      if (value) {
                        const res = await SuggestionRequests.getAssetNameSuggestion(value);
                        if (res.code === 0) {
                          setAssetNameOptions(res.data);
                        } else {
                        }
                      } else {
                        return;
                      }
                    }}
                    onChange={(value: string) => set_filter_assetName(value)}
                    value={filter_assetName}
                  >
                    {assetNameOptions.map((x: string) => {
                      return (
                        <Select.Option value={x} key={`asset_name_option:${x}`}>
                          {x}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="资产组件" className="filter-item">
                  <Select
                    onChange={(values: string) => {
                      set_filter_assetComps(values.split(','));
                    }}
                    style={{ minWidth: 168 }}
                    value={filter_assetComps ? filter_assetComps.toString() : undefined}
                    placeholder="全部"
                    onDropdownVisibleChange={async (open) => {
                      if (open) {
                        if (!filter_assetName) return;
                        const res = await SuggestionRequests.getAssetCompsSuggestion(filter_assetName);
                        if (res.code === 0) {
                          setAssetCompsOptions(res.data.map((x) => x.toString()));
                        } else {
                        }
                      }
                    }}
                  >
                    {assetCompsOptions.map((x: string, index) => {
                      return (
                        <Select.Option value={x} key={`asset_comps_option:${index}`}>
                          {x}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Button type="link" onClick={() => setShowFilterArea(!showFilterArea)}>
                  {showFilterArea ? '点击收起' : '展开全部'}
                  {showFilterArea ? <UpOutlined /> : <DownOutlined />}
                </Button>
              </Form>

              <div style={{ display: showFilterArea ? 'block' : 'none' }}>
                <Form layout="inline" colon={false}>
                  <Form.Item label="资产ID" className="filter-item">
                    <Select
                      style={{ minWidth: 168 }}
                      placeholder="全部"
                      mode="tags"
                      onChange={(value: string[]) => setFilterAssetIds(value)}
                      value={filterAssetIds}
                    ></Select>
                  </Form.Item>
                </Form>
                <Form layout="inline" colon={false}>
                  <Form.Item label="资产标签" className="filter-item">
                    <Select
                      mode="tags"
                      allowClear
                      onChange={(values: string[]) => {
                        set_filter_tagName(
                          values.map((x) => {
                            return {
                              tag_name: x,
                              tag_value: '',
                            };
                          })
                        );
                      }}
                      style={{ minWidth: 168 }}
                      value={filter_tagName ? filter_tagName.map((x) => x.tag_name) : undefined}
                      placeholder="全部"
                    ></Select>
                  </Form.Item>
                  {filter_tagName &&
                    filter_tagName.length > 0 &&
                    filter_tagName.map((item) => {
                      return (
                        <Form.Item
                          label={item.tag_name}
                          key={'tag_value input' + item.tag_name}
                          className="filter-item"
                        >
                          <Input
                            placeholder={`全部`}
                            value={item.tag_value}
                            onChange={(e) => {
                              let ary = filter_tagName.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.tag_name === item.tag_name) {
                                  x.tag_value = e.target.value;
                                }
                                return x;
                              });
                              set_filter_tagName(newAry);
                            }}
                          />
                        </Form.Item>
                      );
                    })}
                </Form>
                <Form layout="inline" colon={false}>
                  <Form.Item label="资产状态创建时间" className="filter-item">
                    <DatePicker.RangePicker
                      value={filter_status_create_time ? filter_status_create_time.dates : undefined}
                      onChange={(dates, dateStrings) => {
                        if (!dates) return;
                        set_filter_status_create_time(
                          dates.length > 0
                            ? {
                                ...filter_status_create_time,
                                start: dateStrings[0] + ' 00:00:00',
                                end: dateStrings[1] + ' 00:00:00',
                                dates: dates,
                              }
                            : null
                        );
                      }}
                    />
                  </Form.Item>
                </Form>
                <Form layout="inline" colon={false}>
                  <Form.Item label="地理信息" className="filter-item">
                    <Select
                      onChange={(values: string[]) => {
                        set_filter_vAssetComps(
                          values.map((x) => {
                            const a = x.search('里程');
                            const b = x.search('轨枕号');
                            let orgValue = filter_vAssetComps.find((item) => item.status_name === x);
                            if (a === -1 && b === -1) {
                              return {
                                status_name: x,
                                status_value: orgValue ? orgValue.status_value : '',
                              };
                            } else {
                              return {
                                status_name: x,
                                status_value: orgValue ? orgValue.status_value : '0,0',
                              };
                            }
                          })
                        );
                      }}
                      style={{ minWidth: 168 }}
                      value={filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : undefined}
                      placeholder="全部"
                      onDropdownVisibleChange={async (open) => {
                        if (open) {
                          if (!filter_assetName || !filter_assetComps) return;
                          const res = await SuggestionRequests.getAssetVCompsSuggestion({
                            asset_name: filter_assetName,
                            asset_comps: filter_assetComps,
                          });
                          if (res.code === 0) {
                            setVAssetQueryOptions(arrSet(res.data));
                          } else {
                          }
                        }
                      }}
                      mode="tags"
                    >
                      {vAssetQueryOptions.map((item, index) => {
                        return (
                          <Select.Option key={item + index} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {filter_vAssetComps &&
                    filter_vAssetComps.length > 0 &&
                    filter_vAssetComps.map((item) => {
                      const a = item.status_name.search('里程');
                      const b = item.status_name.search('轨枕号');
                      if (a === -1 && b === -1) {
                        return (
                          <Form.Item
                            label={item.status_name}
                            key={'status_value input' + item.status_name}
                            className="filter-item"
                          >
                            <Select
                              style={{ minWidth: 168 }}
                              mode="tags"
                              placeholder={`全部`}
                              onDropdownVisibleChange={async (open) => {
                                if (open) {
                                  const res = await SuggestionRequests.statusValueSuggestion({
                                    v_status_name: item.status_name,
                                  });
                                  if (res.code === 0) {
                                    setVStatusValueOptions(res.data.map((item) => item.v_status_value));
                                  } else {
                                  }
                                }
                                if (!open) {
                                  setVStatusValueOptions([]);
                                }
                              }}
                              value={item.status_value === '' ? undefined : item.status_value.split(',')}
                              onChange={(values: string[]) => {
                                let ary = filter_vAssetComps.map((x) => x);
                                const newAry = ary.map((x) => {
                                  if (x.status_name === item.status_name) {
                                    x.status_value = values.toString();
                                  }
                                  return x;
                                });
                                set_filter_vAssetComps(newAry);
                              }}
                            >
                              {vStatusValueOptions.map((option) => {
                                return (
                                  <Select.Option value={option} key={option}>
                                    {option}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        );
                      } else {
                        const x = item.status_name.search('起始');
                        const y = item.status_name.search('迄止');
                        if (x === -1 && y === -1) {
                          return (
                            <Form.Item
                              label={item.status_name}
                              key={'status_value input' + item.status_name}
                              className="filter-item"
                            >
                              <InputNumber
                                onChange={(value: number) => {
                                  if (!value) {
                                    value = 0;
                                  }
                                  let ary = filter_vAssetComps.map((x) => x);
                                  const newAry = ary.map((x) => {
                                    if (x.status_name === item.status_name) {
                                      const range = x.status_value.split(',');
                                      range[0] = value.toString();
                                      if (Number(range[0]) > Number(range[1])) {
                                        range[1] = value.toString();
                                      }
                                      x.status_value = range.toString();
                                    }
                                    return x;
                                  });
                                  set_filter_vAssetComps(newAry);
                                }}
                                value={Number(item.status_value.split(',')[0])}
                              />
                              ~
                              <InputNumber
                                onChange={(value: number) => {
                                  if (!value) {
                                    value = 0;
                                  }
                                  let ary = filter_vAssetComps.map((x) => x);
                                  const newAry = ary.map((x) => {
                                    if (x.status_name === item.status_name) {
                                      const range = x.status_value.split(',');
                                      range[1] = value.toString();
                                      if (Number(range[0]) > Number(range[1])) {
                                        message.error('起始值不能大于迄止值');
                                        range[1] = range[0];
                                      }

                                      x.status_value = range.toString();
                                    }
                                    return x;
                                  });
                                  set_filter_vAssetComps(newAry);
                                }}
                                value={Number(item.status_value.split(',')[1])}
                              />
                            </Form.Item>
                          );
                        } else {
                          return (
                            <Form.Item
                              label={item.status_name}
                              key={'status_value input' + item.status_name}
                              className="filter-item"
                            >
                              <InputNumber
                                onChange={(value: number) => {
                                  if (!value) {
                                    value = 0;
                                  }
                                  let ary = filter_vAssetComps.map((x) => x);
                                  const newAry = ary.map((x) => {
                                    if (x.status_name === item.status_name) {
                                      x.status_value = value.toString();
                                    }
                                    return x;
                                  });
                                  set_filter_vAssetComps(newAry);
                                }}
                                value={Number(item.status_value)}
                                style={{ width: '100%', border: 'none' }}
                              />
                            </Form.Item>
                          );
                        }
                      }
                    })}
                </Form>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                onClick={() => doFetchAssets()}
                icon={<SearchOutlined />}
              >
                查询
              </Button>

              <Button
                onClick={() => {
                  set_filter_assetComps(undefined);
                  set_filter_vAssetComps([]);
                  set_filter_assetName(undefined);
                  set_filter_tagName([]);
                  set_filter_status_create_time(null);
                }}
                style={{ marginRight: 10 }}
                icon={<RedoOutlined />}
              >
                重置
              </Button>
              <Button
                onClick={() => {
                  setAssetModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                新建资产
              </Button>
            </div>
          </div>

          <Table
            scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
            style={{ backgroundColor: '#ffffff' }}
            rowKey={(record, idx) => `asset-${record.asset_id}/${idx}`}
            dataSource={assets}
            loading={loading}
            columns={getColumnConfigs<any>({
              onEvent: (evt: string, payload: AssetItem) => {
                switch (evt) {
                }
              },

              maxVStatusCount: maxVStatusCount,
              maxCompsCount: maxCompsCount,
            })}
            size="small"
            pagination={{
              ...pagination,
              onChange: (page, pageSize) => doFetchAssets(page, pageSize),
              showTotal: (total: number) => {
                return <span>{`共 ${total} 条数据`}</span>;
              },
              pageSizeOptions: ['10', '20', '50', '100'],
              showQuickJumper: true,
            }}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
          />
        </div>
        {/* 第三步：选择资产状态*/}
        <div
          style={{
            width: '100%',
            maxHeight: 500,
            minHeight: 300,
            overflow: 'scroll',
            display: step === 2 ? 'block' : 'none',
          }}
        >
          <Divider>
            请从以下资产状态中选择一项，或
            <a
              onClick={(e) => {
                e.preventDefault();
                setCreateStatusVisible(true);
              }}
            >
              新建资产状态
            </a>
          </Divider>
          {statusOptions.length > 0 ? (
            <div>
              <Radio.Group onChange={(e) => setSelectStatus(e.target.value)} value={selectStatus}>
                <Row style={{ width: '100%', minWidth: 100 }}>
                  {statusOptions.map((x) => (
                    <Col span={8} style={{ margin: '4px 0' }} key={x.status_id}>
                      <Radio value={x.status_name} style={{ minWidth: 200 }}>
                        {x.status_name}（{x.status_type}）
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </div>
          ) : !loading ? (
            <Empty description={'该资产下无资产状态，您可以为其创建资产状态，或选择其他资产'}>
              <Button
                type="primary"
                onClick={() => {
                  setCreateStatusVisible(true);
                }}
              >
                创建资产状态
              </Button>
            </Empty>
          ) : null}
          <Spin spinning={loading} tip="加载中" style={{ position: 'absolute', top: 300, left: 650 }} />
        </div>
        <div id="footer" style={{ display: 'flex', justifyContent: 'center', margin: '16px 0' }}>
          <Button style={{ marginRight: 24 }} onClick={handleCancel}>
            取消
          </Button>
          {(step === 1 || step === 2) && (
            <Button onClick={handlePre} style={{ marginRight: 24 }}>
              上一步
            </Button>
          )}
          {(step === 0 || step === 1) && (
            <Button type="primary" onClick={handleNext} style={{ marginRight: 24 }}>
              下一步
            </Button>
          )}
          {step === 2 && (
            <Button type="primary" disabled={!selectStatus} onClick={handleOK}>
              完成
            </Button>
          )}
        </div>
        {assetModalVisible && (
          <CreateAssetModal visible={assetModalVisible} setVisible={setAssetModalVisible} doFetch={doFetchAssets} />
        )}
        {createStatusVisible && (
          <Modal
            visible={createStatusVisible}
            destroyOnClose
            title={
              <span>
                <div className="title-prefix"></div>创建资产状态
              </span>
            }
            footer={null}
            onCancel={() => {
              setCreateStatusVisible(false);
            }}
          >
            <CreateStatusForm />
          </Modal>
        )}
      </div>
    </Modal>
  );
};

const ModelDetection = () => {
  const [model, setModel] = React.useState<AIModelInfoItem>();
  const location = useLocation();
  const history = useHistory();
  const [selectApi, setSelectApi] = React.useState<any[]>([]);
  const [editingImg, setEditingImg] = React.useState<ImgList>();
  const [editingImgID, setEditingImgID] = React.useState('');
  const [imgList, setImgList] = React.useState<ImgList[]>([]);
  const [addStatusVisible, setAddStatusVisible] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const { closeWindow } = useCacheRoute();
  const [{ userInfo }] = useCtxState();
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    if (location.pathname === '/workbench/ai/model-list/detection') {
      const state: any = location.state;
      if (state) {
        let model = state.model;
        model.api_info_list.forEach((item: any) => {
          item.api_param_info_list.forEach((x: any) => {
            x.value = x.default_value;
          });
        });
        setModel(model);
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (!model) return;
    const _img = imgList.find((x) => x.image_uid === editingImgID);
    if (_img) {
      setEditingImg(_img);
    }
  }, [editingImgID]);

  //保存检测项参数
  const doSetValue = (api: string, key: string, value: any) => {
    if (!model) return;
    let newModel = { ...model };
    newModel.api_info_list
      .filter((item) => item.detect_type === api)[0]
      .api_param_info_list.filter((item) => item.param_name === key)[0].value = value;
    setModel(newModel);
  };
  //恢复所有默认设置
  const resetAll = () => {
    if (!model) return;
    let newModel = { ...model };
    newModel.api_info_list.forEach((item) => {
      item.api_param_info_list.forEach((x) => {
        x.value = x.default;
      });
    });
    setModel(newModel);
  };

  //移除图片
  const removeImg = (uid: string) => {
    const _index = imgList.findIndex((x) => x.image_uid === uid);
    if (_index === 0) {
      setEditingImgID(imgList[1].image_uid);
    } else {
      setEditingImgID(imgList[_index - 1].image_uid);
    }
    setImgList(imgList.filter((x) => x.image_uid !== uid));
  };

  //开始检测
  const doDetect = async () => {
    if (!model) return;
    if (!userInfo) {
      message.error('获取用户信息失败，请重新登录后尝试');
      return;
    }
    setVisible(true);
  };

  const ProjectNameModal = () => {
    if (!model) return null;
    return (
      <Modal
        visible={visible}
        title={'填写检测项目名称'}
        destroyOnClose={true}
        width={500}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={async (values: any) => {
            console.log(values);
            setVisible(false);
            message.loading('检测中...');
            const res = await detectImgs({
              model_id: model.model_id,
              api_info_list: selectApi.map((x) => {
                let _api = model.api_info_list.find((y) => y.detect_type === x);
                if (_api) {
                  return {
                    detect_type: _api.detect_type,
                    api_param_info_list: _api.api_param_info_list.map((z) => {
                      return {
                        ...z,
                        value: z.value ? z.value : z.default,
                      };
                    }),
                  };
                } else {
                  return {} as any;
                }
              }),
              ai_detect_image_info_list: imgList.map((x) => {
                return {
                  image_uid: x.image_uid,
                  asset_status_list: x.asset_status_list.map((y) => {
                    return {
                      asset_id: y.asset.asset_id,
                      status_name: y.status,
                      detect_data_result_type: y.detect_data_result_type,
                    };
                  }),
                };
              }),
              project_name: values['project_name'],
            });
            if (res.code === 0) {
              message.destroy();
              setDone(true);
              message.success('检测成功');
            } else {
              message.error(res.message);
            }
          }}
        >
          <Form.Item
            initialValue={`${moment().format('YYYY年MM月DD日hh时mm分')}_${userInfo.username}_${selectApi
              .toString()
              .replace(',', '_')}`}
            name="project_name"
            rules={[
              {
                //校验项目名称是否除中文、英文、数字、下划线外的字符
                validator: (rule, value, callback) => {
                  if (!value.match(/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/g)) {
                    callback('项目名称只能包含中文、英文、数字、下划线');
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input placeholder="请输入项目名称" />
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  if (!model) return null;
  if (done)
    return (
      <div style={{ backgroundColor: '#ffffff', padding: '16px 0' }}>
        <Result
          status="success"
          title="图片检测成功"
          subTitle="请前往检测记录中查看检测结果"
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => {
                closeWindow();
                history.push({
                  pathname: '/workbench/ai/model-list/detect-records',
                  state: {
                    model_id: model.model_id,
                  },
                  search: `?model_id=${model.model_id}`,
                });
              }}
            >
              立即前往
            </Button>,
          ]}
        />
      </div>
    );
  return (
    <div>
      <div style={{ backgroundColor: '#ffffff', padding: '16px 0' }}>
        <Descriptions
          title={
            <span>
              <div className="title-prefix"></div>模型信息
            </span>
          }
          style={{ margin: '0 48px' }}
          extra={[
            <Button
              type="primary"
              style={{ borderRadius: 5 }}
              onClick={() => {
                if (!selectApi.length) return message.error('请选择检测项');
                if (!imgList.length) return message.error('请上传图片');

                doDetect();
              }}
            >
              开始检测
            </Button>,
          ]}
        >
          <Descriptions.Item label="模型名称">{model.model_name}</Descriptions.Item>
          <Descriptions.Item label="模型编号">{model.model_id}</Descriptions.Item>
          <Descriptions.Item label="厚度">{model.thickness}</Descriptions.Item>
          <Descriptions.Item label="模型创建时间">{model.model_create_time}</Descriptions.Item>
          <Descriptions.Item label="迭代次数">{model.iterations}</Descriptions.Item>
          <Descriptions.Item label="测试集比例">{model.test_frac}</Descriptions.Item>
        </Descriptions>
      </div>
      <div style={{ backgroundColor: '#ffffff', padding: 16, marginTop: 20 }}>
        <Tabs centered>
          <Tabs.TabPane tab="检测设置" key="decection_setting">
            <div id="decection_setting">
              <span>本次检测项目（多选）：</span>
              <Checkbox.Group
                options={model.api_info_list.map((item) => {
                  return {
                    label: item.api_name,
                    value: item.detect_type,
                  };
                })}
                onChange={(value) => {
                  setSelectApi(value);
                }}
              />
              <Divider>
                参数设置{' '}
                <Tooltip title={'请仔细检测每一检测项的检测参数设置'}>
                  <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
                </Tooltip>
              </Divider>
              {selectApi.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                  <Empty description="请选择检测项" />
                </div>
              )}
              {selectApi.length > 0 && (
                <Tabs
                  type="card"
                  tabBarExtraContent={[
                    <Button type="primary" style={{ borderRadius: 5 }} icon={<RedoOutlined />} onClick={resetAll}>
                      恢复默认设置
                    </Button>,
                  ]}
                >
                  {selectApi.map((api, index) => {
                    const _api = model.api_info_list.find((x) => x.detect_type === api);
                    if (!_api) return null;
                    return (
                      <Tabs.TabPane tab={_api.api_name} key={api}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {_api.api_param_info_list.map((x) => {
                            return (
                              <Row
                                style={{ width: '50%', margin: '4px 0', display: 'flex', alignItems: 'center' }}
                                gutter={16}
                              >
                                <Col span={8}>
                                  <span>
                                    {x.title}{' '}
                                    <Tooltip title={x.description}>
                                      <QuestionCircleOutlined style={{ cursor: 'pointer' }} />
                                    </Tooltip>
                                    ：
                                  </span>
                                </Col>
                                {x.type === 'integer' && (
                                  <Col span={7}>
                                    <Slider
                                      min={x.exclusive_minimum}
                                      max={x.exclusive_maximum}
                                      onChange={(value: any) => {
                                        doSetValue(api, x.param_name, value);
                                      }}
                                      defaultValue={x.default}
                                      value={x.value}
                                    />
                                  </Col>
                                )}
                                {x.type === 'number' && (
                                  <Col span={7}>
                                    <Slider
                                      min={x.exclusive_minimum}
                                      max={x.exclusive_maximum}
                                      onChange={(value: any) => {
                                        doSetValue(api, x.param_name, value);
                                      }}
                                      step={0.01}
                                      defaultValue={x.default}
                                      value={x.value}
                                    />
                                  </Col>
                                )}

                                {x.type === 'integer' && (
                                  <Col span={2}>
                                    <InputNumber
                                      min={x.exclusive_minimum}
                                      max={x.exclusive_maximum}
                                      defaultValue={x.default}
                                      onChange={(e) => {
                                        doSetValue(api, x.param_name, e);
                                      }}
                                      value={x.value}
                                    />
                                  </Col>
                                )}

                                {x.type === 'number' && (
                                  <Col span={2}>
                                    <InputNumber
                                      min={x.exclusive_minimum}
                                      max={x.exclusive_maximum}
                                      defaultValue={x.default}
                                      onChange={(e) => {
                                        doSetValue(api, x.param_name, e);
                                      }}
                                      step={0.01}
                                      value={x.value}
                                    />
                                  </Col>
                                )}

                                {x.type === 'string' && (
                                  <Col span={10}>
                                    <Input
                                      defaultValue={x.default}
                                      onChange={(e) => {
                                        doSetValue(api, x.param_name, e.target.value);
                                      }}
                                      value={x.value}
                                    />
                                  </Col>
                                )}
                              </Row>
                            );
                          })}
                        </div>
                      </Tabs.TabPane>
                    );
                  })}
                </Tabs>
              )}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <Badge count={imgList.length} offset={[10, 0]} showZero>
                检测图片
              </Badge>
            }
            key="detection_imgs"
          >
            <div id="detection_imgs">
              <div
                className="image-list"
                style={{ display: 'flex', width: '100%', overflow: 'scroll', alignItems: 'center' }}
              >
                {imgList.map((image) => {
                  return (
                    <div
                      style={{ cursor: 'pointer', marginRight: 8 }}
                      onClick={() => {
                        setEditingImgID(image.image_uid);
                      }}
                      key={image.image_uid}
                    >
                      <Image
                        src={`/v2/api/pic/picture/download/${100}/${image.image_uid}`}
                        height={image.image_uid === editingImgID ? 115 : 102}
                        width={image.image_uid === editingImgID ? 115 : 102}
                        preview={false}
                        style={{ border: image.image_uid === editingImgID ? '2px solid #f39800' : '' }}
                      />
                    </div>
                  );
                })}
                <ImgCrop showGrid quality={1}>
                  <Upload
                    listType="picture-card"
                    action={'/v2/api/pic/uploadPic'}
                    showUploadList={false}
                    beforeUpload={(file) => {
                      message.loading({ content: '上传中，请稍等...', key: 'uploading' });
                      return true;
                    }}
                    onChange={(info) => {
                      console.log(info);
                      if (info.file.status === 'done') {
                        message.destroy('uploading');
                        notification.open({
                          message: `${info.file.name} 上传成功`,
                          onClick: () => {},
                        });
                        let _imgList = imgList.map((x) => x);
                        _imgList.push({
                          image_uid: info.file.response.data,
                          asset_status_list: [],
                          file_name: info.file.name,
                        });
                        setImgList(_imgList);
                        setEditingImgID(info.file.response.data);
                      }
                    }}
                  >
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>添加图片</div>
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
              <Divider style={{ marginTop: 0 }}>绑定资产状态</Divider>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{}}>
                    <span style={{ color: 'grey' }}> 当前图片（点击图片切换）：</span>
                    <span>{editingImg ? editingImg.file_name : '暂无图片'}</span>
                  </div>
                  {editingImg && (
                    <div id="actions">
                      <Button
                        style={{ borderRadius: 5, marginRight: 12 }}
                        icon={<PlusOutlined />}
                        onClick={() => {
                          if (selectApi.length < 1) {
                            message.error('请先选择检测项目');
                            return;
                          }
                          setAddStatusVisible(true);
                        }}
                      >
                        添加资产状态
                      </Button>
                      <Button
                        style={{ borderRadius: 5 }}
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            content: '确认删除图片吗？',
                            onOk: () => {
                              removeImg(editingImgID);
                            },
                            onCancel: () => {},
                          });
                        }}
                      >
                        删除图片
                      </Button>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: 16 }}>
                  <Table
                    dataSource={editingImg ? editingImg.asset_status_list : []}
                    columns={[
                      {
                        key: 'order',
                        title: '序号',

                        render(value, record, index) {
                          return (
                            <div>
                              <span>{index + 1}</span>
                            </div>
                          );
                        },
                      },
                      {
                        key: 'asset_name',
                        title: '资产名称',
                        align: 'center',
                        render(value, record, index) {
                          return (
                            <div>
                              <span>{record.asset.asset_name}</span>
                            </div>
                          );
                        },
                      },

                      {
                        key: 'status',
                        title: '资产状态',
                        align: 'center',
                        render(value, record, index) {
                          return (
                            <div>
                              <span>{record.status}</span>
                            </div>
                          );
                        },
                      },
                      {
                        key: 'detect_type',
                        title: '检测项',
                        align: 'center',
                        render(value, record, index) {
                          return (
                            <div>
                              <span>{record.detect_data_result_type}</span>
                            </div>
                          );
                        },
                      },
                      {
                        key: 'asset_comps',
                        title: '资产组件',
                        align: 'center',
                        render(value, record, index) {
                          return (
                            <div>
                              {record.asset.asset_comps.map((x) => (
                                <Tag>{x}</Tag>
                              ))}
                            </div>
                          );
                        },
                      },
                      {
                        key: 'v_asset_status',
                        title: '地理信息',
                        align: 'center',
                        render(value, record, index) {
                          return (
                            <div>
                              {record.asset.v_asset_status.map((x) => (
                                <Tag>{x.v_status_name + '：' + x.v_status_value}</Tag>
                              ))}
                            </div>
                          );
                        },
                      },
                      {
                        key: 'actions',
                        title: '操作',
                        align: 'center',
                        render(value, record, index) {
                          return (
                            <div>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  Modal.confirm({
                                    title: '提示',
                                    content: '确认移除该资产状态吗？',
                                    onOk: () => {
                                      if (!editingImg) return;
                                      let _imgList = imgList.map((x) => x);
                                      _imgList.forEach((img) => {
                                        if (img.image_uid === editingImgID) {
                                          img.asset_status_list.splice(index, 1);
                                        }
                                      });
                                      setImgList(_imgList);
                                      setEditingImg({
                                        ...editingImg,
                                        asset_status_list: editingImg.asset_status_list.filter((x, i) => i !== index),
                                      });
                                    },
                                    onCancel: () => {},
                                  });
                                }}
                              >
                                移除
                              </a>
                            </div>
                          );
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      {addStatusVisible && editingImg && (
        <AddStatusModal
          visible={addStatusVisible}
          setVisible={setAddStatusVisible}
          imgList={imgList}
          setImgList={setImgList}
          editingImgID={editingImgID}
          selectApi={selectApi}
          setEditingImg={setEditingImg}
          editingImg={editingImg}
        />
      )}
      {visible && <ProjectNameModal />}
    </div>
  );
};

export default ModelDetection;
