import * as React from 'react';
import { Collapse, Tag, Button, Tooltip, Table, Modal, message, Form, Input, List, Select, Divider } from 'antd';
import './index.less';
import {
  PlusOutlined,
  RightOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  WarningFilled,
  SettingFilled,
  TagOutlined,
} from '@ant-design/icons';
import * as StandardRequest from '@/requests/standard';

import CreateStandardModal from '../CreateStandardModal';

import { FilterConditionsType } from '../..';
import CreateStandardTemplate from '../CreateStandardTemplate';
import EditableCell from '@/ui-components/EditableCell';
import * as Utils from '@/utils/string';
import * as SuggestionRequests from '@/requests/suggestion';

const { Panel } = Collapse;

const formatTableData = (levels: string[], standards: Standard[]) => {
  return levels.map((x, index) => {
    let obj = { level: x } as any;
    standards.map((standard) => {
      obj[standard.standard_name] = standard.ranges[index];
      obj['key'] = standard.ranges[index].range_name + index;
    });
    return obj;
  });
};

interface StandardListBaseProps {
  standard: Standards;
  doFetch: Function;
  userInfo: UserInfoItem;
  setEditingItemId: Function;
  setStandardToCopy: Function;
  setTemplateVisible: Function;
  templateVisible: Boolean;
}

interface ListItemBaseProps extends StandardListBaseProps {
  editing: boolean;
  setEditing: Function;
}

const StandardList = ({
  filterConditions,
  userInfo,
  createStandardModalVisible,
  setCreateStandardModalVisible,
}: {
  filterConditions: FilterConditionsType;
  userInfo: any;
  createStandardModalVisible: boolean;
  setCreateStandardModalVisible: Function;
}) => {
  const [editingItemId, setEditingItemId] = React.useState<string | null>(null);
  const [pagination, setPagination] = React.useState({
    curent: 1,
    pageSize: 5,
    total: 0,
  });
  const [fetching, setFetching] = React.useState<boolean>(false);
  const [standardList, setStandardList] = React.useState<Standards[]>([]);
  const [templateVisible, setTemplateVisible] = React.useState(false);
  const [standardToCopy, setStandardToCopy] = React.useState<Standards | undefined>(undefined);

  const doFetch = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.curent;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await StandardRequest.getStandardList(
      {
        standard_name: filterConditions.standard_name,
        asset_name: filterConditions.asset_name,
        status_name: filterConditions.status_name,
      },
      page,
      pageSize
    );

    if (res.code === 0) {
      setStandardList(res.data);
      setPagination({
        ...pagination,
        curent: page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    setFetching(true);
    doFetch();
  }, [filterConditions]);
  return (
    <div className="standard-item-container">
      <div>
        {createStandardModalVisible && (
          <CreateStandardModal
            visible={createStandardModalVisible}
            setVisible={setCreateStandardModalVisible}
            userInfo={userInfo}
            doFetch={doFetch}
          />
        )}
        {templateVisible && (
          <CreateStandardTemplate
            standard={standardToCopy}
            setStandardToCopy={setStandardToCopy}
            setTemplateVisible={setTemplateVisible}
            userInfo={userInfo}
            doFetch={doFetch}
          />
        )}

        <List
          dataSource={standardList}
          renderItem={(item, index) => (
            <StandardListRenderItem
              key={item.standard_condition_uid}
              standard={item}
              doFetch={doFetch}
              userInfo={userInfo}
              setEditingItemId={setEditingItemId}
              setStandardToCopy={setStandardToCopy}
              setTemplateVisible={setTemplateVisible}
              templateVisible={templateVisible}
            />
          )}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              doFetch(page, pagesize);
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            showQuickJumper: true,
          }}
          loading={fetching}
          split={false}
        />
      </div>
    </div>
  );
};

const StandardListRenderItem = (props: StandardListBaseProps) => {
  const { standard } = props;
  const [editing, setEditing] = React.useState(false);
  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      style={{ backgroundColor: '#FFFFFF' }}
      expandIcon={({ isActive }) => (
        <div>
          <RightOutlined
            style={{
              fontSize: 12,
              fontWeight: 400,
              cursor: 'pointer',
            }}
            rotate={isActive ? 90 : 0}
          />
        </div>
      )}
    >
      <Panel
        key={standard.standard_condition_uid}
        header={<ItemHeader {...props} editing={editing} setEditing={setEditing} />}
        showArrow={true}
        style={{
          borderRadius: 4,
          marginBottom: 20,
          position: 'relative',
        }}
        disabled={editing}
      >
        <CollapseContent {...props} editing={editing} setEditing={setEditing} />
      </Panel>
    </Collapse>
  );
};

const ItemHeader = (props: ListItemBaseProps) => {
  const { standard, doFetch, editing } = props;
  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([]);
  const [addCompsModalVisible, setAddCompsModalVisible] = React.useState(false);
  const [addVCompsModalVisible, setAddVCompsModalVisible] = React.useState(false);
  const AddCompsForm = () => {
    const [comps, setComps] = React.useState(standard.asset_comps);
    const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([]);
    const handleOk = async () => {
      message.loading('保存中');
      const res = await StandardRequest.UpdateStandards({
        ...standard,
        asset_comps: comps,
        task_id: `update_standards_${Utils.uuid()}`,
      });
      if (res.code === 0) {
        message.destroy();
        doFetch();
        message.success('保存成功');
        setAddCompsModalVisible(false);
      } else {
        message.destroy();
        message.error(res.message);
      }
    };
    const handleCancel = () => {
      setAddCompsModalVisible(false);
    };
    return (
      <Modal
        title="添加资产组件"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={addCompsModalVisible}
        okText="确认"
        cancelText="取消"
      >
        <Form>
          <Form.Item label="适用的资产组件">
            <Select
              onDropdownVisibleChange={async (open) => {
                const assetName = standard.asset_name;
                if (open) {
                  if (!assetName) {
                    return;
                  }
                  const res = await SuggestionRequests.getAssetCompsSuggestion(assetName);
                  if (res.code === 0) {
                    let options: string[] = [];
                    res.data.map((x) => (options = options.concat(x)));
                    const CompsSet = new Set<string>(options);
                    options = Array.from(CompsSet);
                    console.log(options, CompsSet);
                    setAssetCompsOptions(options);
                  } else {
                    message.error('网络错误');
                  }
                }
              }}
              mode="tags"
              value={comps}
              onChange={(option: string[]) => {
                setComps(option);
              }}
            >
              {assetCompsOptions.map((x: string) => {
                return (
                  <Select.Option value={x} key={`asset_comps_option:${x}`}>
                    {x}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const AddVCompsForm = () => {
    const [vcomps, setVcomps] = React.useState(standard.virtual_asset_comps);
    const [vassetCompsOptions, setVassetCompsOptions] = React.useState<string[]>([]);
    const handleOk = async () => {
      message.loading('保存中');
      const res = await StandardRequest.UpdateStandards({
        ...standard,
        virtual_asset_comps: vcomps,
        task_id: `update_standards_${Utils.uuid()}`,
      });
      if (res.code === 0) {
        message.destroy();
        doFetch();
        message.success('保存成功');
        setAddVCompsModalVisible(false);
      } else {
        message.destroy();
        message.error(res.message);
      }
    };
    const handleCancel = () => {
      setAddVCompsModalVisible(false);
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
      },
    };
    const getComps = () => {
      const keys = Object.keys(vcomps);
      return keys;
    };

    return (
      <Modal
        title="添加地点信息"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={addVCompsModalVisible}
        okText="确认"
        cancelText="取消"
      >
        <Form {...formItemLayout}>
          <Form.Item label="地点信息">
            <Select
              mode="tags"
              onDropdownVisibleChange={async (open) => {
                const assetName = standard.asset_name;
                const asset_comps = standard.asset_comps;
                if (open) {
                  if (!assetName) {
                    return;
                  }
                  const res = await SuggestionRequests.getAssetVCompsSuggestion({
                    asset_name: assetName,
                    asset_comps: asset_comps,
                  });
                  if (res.code === 0) {
                    let options: string[] = [];
                    res.data.map((x) => (options = options.concat(x)));
                    const CompsSet = new Set<string>(options);
                    options = Array.from(CompsSet);
                    console.log(options, CompsSet);
                    setVassetCompsOptions(options);
                  } else {
                    message.error('网络错误');
                  }
                }
              }}
              placeholder="请选择地点信息"
              value={Object.keys(vcomps).length > 0 ? getComps() : undefined}
              onChange={(option: string[]) => {
                let comps = JSON.parse(JSON.stringify(vcomps));
                let realcomps = {} as any;
                console.log(option);
                option.map((item) => {
                  if (!comps[item]) {
                    realcomps[item] = '';
                  } else {
                    realcomps[item] = comps[item];
                  }
                });
                setVcomps(realcomps);
              }}
            >
              {vassetCompsOptions.map((item, _index) => {
                return (
                  <Select.Option key={item.toString() + _index} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {getComps().map((item, _index) => {
            return (
              <Form.Item label={item} key={item + _index}>
                <Input
                  value={vcomps[item] ? vcomps[item] : undefined}
                  onChange={(e) => {
                    let comps = JSON.parse(JSON.stringify(vcomps));
                    comps[item] = e.target.value;
                    setVcomps(comps);
                  }}
                />
              </Form.Item>
            );
          })}
        </Form>
      </Modal>
    );
  };
  return (
    <div className="item-header" style={{ display: 'flex', height: '100%' }}>
      <AddCompsForm />
      <AddVCompsForm />
      <div className="status-name">
        {editing ? (
          <EditableCell
            child={standard.asset_status_name}
            OnFinishEditing={async (value: string) => {
              if (value === standard.asset_status_name) return;
              message.loading('保存中');
              const res = await StandardRequest.UpdateStandards({
                ...standard,
                asset_status_name: value,
                task_id: `update_standards_${Utils.uuid()}`,
              });
              if (res.code === 0) {
                message.destroy();
                doFetch();
                message.success('保存成功');
              } else {
                message.destroy();
                message.error(res.message);
              }
            }}
          />
        ) : (
          <span>
            <TagOutlined /> {standard.asset_status_name}
          </span>
        )}
      </div>
      <div className="standard-col1">
        <div className="standard-col2">
          <div className="key">值类型</div>
          <div className="value">
            {editing ? (
              <Select
                size="small"
                value={standard.status_value_type ? standard.status_value_type : undefined}
                placeholder="请选择值类型"
                onSelect={async (value: string) => {
                  message.loading('保存中');
                  const res = await StandardRequest.UpdateStandards({
                    ...standard,
                    status_value_type: value,
                    task_id: `update_standards_${Utils.uuid()}`,
                  });
                  if (res.code === 0) {
                    message.destroy();
                    doFetch();
                    message.success('保存成功');
                  } else {
                    message.destroy();
                    message.error(res.message);
                  }
                }}
                style={{ minWidth: 150 }}
              >
                {['数值', '文本'].map((item) => {
                  return (
                    <Select.Option value={item} key={`status_value_type:${item}`}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : (
              <Tag color={standard.status_value_type === '数值' ? 'green' : 'pink'}>{standard.status_value_type}</Tag>
            )}
          </div>
        </div>
      </div>
      <div className="standard-col1">
        <div className="standard-col2">
          <div className="key">适用的资产名</div>
          <div className="value">
            {editing ? (
              <Select
                showSearch
                onSearch={async (value) => {
                  if (Utils.isNull(value)) {
                    return;
                  }
                  if (value) {
                    const res = await SuggestionRequests.getAssetNameSuggestion(value);
                    if (res.code === 0) {
                      setAssetNameOptions(res.data);
                    } else {
                      message.error('网络错误');
                    }
                  } else {
                    return;
                  }
                }}
                size="small"
                value={standard.asset_name ? standard.asset_name : undefined}
                placeholder="输入适用的资产名称"
                onSelect={async (value: string) => {
                  message.loading('保存中');
                  const res = await StandardRequest.UpdateStandards({
                    ...standard,
                    asset_name: value,
                    task_id: `update_standards_${Utils.uuid()}`,
                  });
                  if (res.code === 0) {
                    message.destroy();
                    doFetch();
                    message.success('保存成功');
                  } else {
                    message.destroy();
                    message.error(res.message);
                  }
                }}
                style={{ minWidth: 150 }}
              >
                {assetNameOptions.map((x: string) => {
                  return (
                    <Select.Option value={x.toString()} key={`asset_name_option:${x}`}>
                      {x}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : (
              <Tag color="magenta">{standard.asset_name}</Tag>
            )}
          </div>
        </div>
      </div>

      <div className="standard-col1">
        <div className="standard-col2">
          <div className="key">适用地点</div>
          <div className="value">
            {Object.keys(standard.virtual_asset_comps).length > 0 ? (
              Object.entries(standard.virtual_asset_comps).map((x) => (
                <Tag key={x.toString()} color="orange">{`${x[0]}：${x[1]}`}</Tag>
              ))
            ) : (
              <Tag color="green">全适用</Tag>
            )}
            {editing ? (
              <Tag color="blue" style={{ cursor: 'pointer' }} onClick={() => setAddVCompsModalVisible(true)}>
                <PlusOutlined type="plus" />
              </Tag>
            ) : null}
          </div>
        </div>
      </div>
      <div className="standard-col1">
        <div className="standard-col2">
          <div className="key">适用的资产组件</div>
          <div className="value">
            {standard.asset_comps.map((x) => {
              return (
                <Tag color="blue" key={x}>
                  {x}
                </Tag>
              );
            })}
            {editing ? (
              <Tag color="blue" style={{ cursor: 'pointer' }} onClick={() => setAddCompsModalVisible(true)}>
                <PlusOutlined type="plus" />
              </Tag>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const CollapseContent = (props: ListItemBaseProps) => {
  const {
    standard,
    doFetch,
    userInfo,
    setEditingItemId,
    setStandardToCopy,
    setTemplateVisible,
    templateVisible,
    editing,
    setEditing,
  } = props;
  const [addStandardVisible, setAddStandardVisible] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [addLevelModalVisible, setAddLevelModalVisible] = React.useState(false);
  let defectName: string = '';
  const [addStandardForm] = Form.useForm();
  const [addLevelForm] = Form.useForm();

  const handleAddStandardOk = (values: any) => {
    doAddStandard(values);
  };
  const handleAddLevelOk = (values: { level: string }) => {
    doAddLevel(values);
  };

  const doAddLevel = async (values: { level: string }) => {
    const levels = standard.levels.map((x) => x);
    levels.push(values.level);
    const standards = standard.standards.map((x) => {
      const ranges = x.ranges.map((y) => y);
      ranges.push({
        range_name: values.level,
        range: '',
      } as any);
      return {
        ...x,
        ranges: ranges,
      };
    });
    message.loading('保存中');
    const res = await StandardRequest.UpdateStandards({
      ...standard,
      levels,
      standards,
    });
    if (res.code === 0) {
      message.destroy();
      doFetch();
      message.success('保存成功');
      setAddLevelModalVisible(false);
    } else {
      message.destroy();
      message.error(res.message);
    }
  };

  const doAddStandard = async (values: any) => {
    setFetching(true);
    const res = await StandardRequest.createStandardItem({
      standard_name: values.standard_name,
      ranges: standard.levels.map((level) => {
        return {
          range_name: level,
          range: values[level],
        };
      }),
      standard_condition_uid: standard.standard_condition_uid,
      user_id: String(userInfo.user_id),
      user_name: userInfo.username,
      form_id: 'not form',
      task_id: 'create-standard-item',
    });

    if (res.code === 0) {
      message.success('添加标准成功');
      setAddStandardVisible(false);
      doFetch();
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const AddStandardModal = () => {
    if (!standard) return null;
    return (
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>添加标准
          </span>
        }
        visible={addStandardVisible}
        footer={null}
        destroyOnClose
        onCancel={() => {
          setAddStandardVisible(false);
        }}
      >
        <Form form={addStandardForm} onFinish={handleAddStandardOk} preserve={false} layout="vertical">
          <Form.Item label="标准名称" name="standard_name" rules={[{ required: true, message: '请输入标准名称' }]}>
            <Input placeholder="请输入标准名称" />
          </Form.Item>
          {standard.levels.map((x, index) => {
            return (
              <Form.Item
                label={
                  <span>
                    {x}&nbsp;
                    <Tooltip title="数字请使用半角符号 “[”、“(”、“)”、“]”和并集“U”，字符请使用半角符号 “[”和“]”及用“,”隔开字符,示例:数值型:[1,2]U(3,5];文本型: [红,白,黄]">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name={x}
                key={`${x}-${index}`}
                rules={[
                  {
                    required: true,
                    message: `请输入${x}值范围`,
                  },
                ]}
              >
                <Input placeholder={`请输入值范围`} />
              </Form.Item>
            );
          })}
          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 36,
                padding: '0 100px',
              }}
            >
              <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                确认
              </Button>
              <Button onClick={() => setAddStandardVisible(false)} style={{ padding: '0 36px' }}>
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  const AddlevelModal = () => {
    const handleCancel = () => {
      setAddLevelModalVisible(false);
    };
    return (
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>添加等级
          </span>
        }
        footer={null}
        onCancel={handleCancel}
        visible={addLevelModalVisible}
      >
        <Form form={addLevelForm} preserve={false} onFinish={handleAddLevelOk}>
          <Form.Item label="等级名称" name="level" rules={[{ required: true, message: '请输入等级名称' }]}>
            <Input placeholder="请输入等级名称" />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 36,
                padding: '0 100px',
              }}
            >
              <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                确认
              </Button>
              <Button onClick={() => setAddLevelModalVisible(false)} style={{ padding: '0 36px' }}>
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <div style={{ position: 'relative', backgroundColor: '#ffffff' }}>
      <div>
        {editing && addStandardVisible && <AddStandardModal />}
        <AddlevelModal />
        <Table
          style={{
            paddingBottom: 30,
            marginRight: 140,
            paddingLeft: 160,
          }}
          dataSource={formatTableData(standard.levels, standard.standards)}
          size="middle"
          pagination={false}
          bordered={false}
          columns={[
            {
              title: '' as any,
              align: 'left' as 'left',
              key: 'level',
              render(_: any, record: any, idx: number) {
                return editing ? (
                  <EditableCell
                    child={record.level}
                    OnFinishEditing={async (value: string) => {
                      let levels = standard.levels.map((x) => x);
                      levels[idx] = value;
                      message.info('保存中');
                      const res = await StandardRequest.UpdateStandards({
                        ...standard,
                        levels,
                        standards: standard.standards.map((item, i_index) => {
                          return {
                            ...item,
                            ranges: item.ranges.map((range, r_index) => {
                              if (r_index === idx) {
                                return {
                                  ...range,
                                  range_name: value,
                                };
                              } else {
                                return range;
                              }
                            }),
                          };
                        }),
                        task_id: `update_standards_${Utils.uuid()}`,
                      });
                      if (res.code === 0) {
                        message.destroy();
                        doFetch();
                        message.success('保存成功');
                      } else {
                        message.destroy();
                        message.error(res.message);
                      }
                    }}
                  />
                ) : (
                  <span key={idx + record.level}>{record.level}</span>
                );
              },
            },
          ]
            .concat(
              standard.standards.map((x, standard_index) => {
                return {
                  title: editing ? (
                    <div
                      style={{
                        border: '1px solid #EEEEEE',
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '80%',
                        minWidth: 230,
                        maxWidth: 300,
                        padding: '0 16px',
                      }}
                    >
                      <EditableCell
                        child={x.standard_name}
                        OnFinishEditing={async (value: string) => {
                          message.loading('保存中');
                          const res = await StandardRequest.UpdateSingleStandard({
                            standard_uid: x.standard_uid,
                            standard_name: value,
                            ranges: x.ranges,
                            task_id: `update_single_standard_name_${Utils.uuid()}`,
                            form_id: 'not_form',
                          });
                          if (res.code === 0) {
                            message.destroy();
                            doFetch();
                            message.success('保存成功');
                          } else {
                            message.destroy();
                            message.error(res.message);
                          }
                        }}
                      />
                      <DeleteOutlined
                        type="delete"
                        style={{
                          color: '#1691ff',
                          marginLeft: 5,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            content: '确认删除此条标准吗',
                            okText: '删除',
                            cancelText: '取消',
                            autoFocusButton: 'cancel',
                            onOk: async () => {
                              const res = await StandardRequest.deleteStandardItem(x.standard_uid);
                              if (res.code === 0) {
                                message.success('删除成功');
                              } else {
                                message.error(res.message);
                              }
                              doFetch();
                            },
                            onCancel: () => {},
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <span style={{ color: '#2d3843', fontWeight: 'bold', padding: '0 16px' }}> {x.standard_name}</span>
                  ),

                  align: 'left' as 'left',

                  key: x.standard_name + x.standard_uid + standard_index,
                  render(_: any, record: any, idx: number) {
                    const res = record[x.standard_name].range ? record[x.standard_name].range : '-';
                    return (
                      <div
                        key={idx + record.key}
                        style={{
                          border: '1px solid #EEEEEE',
                          height: 50,
                          display: 'flex',
                          alignItems: 'center',
                          width: '80%',
                          minWidth: 230,
                          maxWidth: 300,
                        }}
                      >
                        <div style={{ width: '40%', textAlign: 'center' }}>
                          {editing ? (
                            <EditableCell
                              OnFinishEditing={async (value: string) => {
                                let arr = x.ranges.map((x) => x);
                                arr[idx].range = value;
                                message.loading('保存中');
                                const res = await StandardRequest.UpdateSingleStandard({
                                  standard_name: x.standard_name,
                                  standard_uid: x.standard_uid,
                                  task_id: `update_single_standard_range_${Utils.uuid()}`,
                                  form_id: 'not_form',
                                  ranges: arr,
                                });
                                if (res.code === 0) {
                                  message.destroy();
                                  doFetch();
                                  message.success('保存成功');
                                } else {
                                  message.destroy();
                                  message.error(res.message);
                                }
                              }}
                              child={res}
                            />
                          ) : (
                            res
                          )}
                        </div>
                        <div style={{ width: '60%', display: 'flex', justifyContent: 'space-around' }}>
                          <Tooltip
                            placement="right"
                            title={
                              <div>
                                <div>{`标准编号：${x.standard_uid}`}</div>
                                <div>{`记录人姓名：${x.user_name}`}</div>
                                <div>{`记录人编号：${x.user_id}`}</div>
                                <div>{`记录日期：${x.create_time}`}</div>
                              </div>
                            }
                          >
                            <QuestionCircleOutlined
                              style={{
                                paddingRight: 5,
                                color: '#8691A2',
                              }}
                            />
                            <span style={{ color: '#8691A2' }}>详情</span>
                          </Tooltip>
                          {record[x.standard_name].defect_existed && (
                            <Tooltip
                              placement="right"
                              title={
                                <div>
                                  <div>{`缺陷名称：${record[x.standard_name].defect_name}`}</div>
                                  <div>{`缺陷ID：${record[x.standard_name].defect_uid}`}</div>
                                </div>
                              }
                            >
                              <WarningFilled
                                style={{
                                  paddingRight: 5,
                                  color: '#F26872',
                                }}
                              />
                              <span style={{ color: '#F26872' }}>缺陷</span>
                            </Tooltip>
                          )}
                          {!!!record[x.standard_name].defect_existed && (
                            <Tooltip placement="right" title={'设为缺陷'}>
                              <SettingFilled
                                style={{
                                  paddingRight: 5,
                                  color: '#8691A2',
                                }}
                              />
                              <span
                                style={{ color: '#8691A2', cursor: 'pointer' }}
                                onClick={() => {
                                  Modal.confirm({
                                    title: '设定缺陷',
                                    content: (
                                      <Form>
                                        <Form.Item label="缺陷名称">
                                          <Input onChange={(e) => (defectName = e.target.value)} />
                                        </Form.Item>
                                      </Form>
                                    ),
                                    okText: '确认',
                                    onOk: async () => {
                                      setFetching(true);
                                      const res = await StandardRequest.setDefect({
                                        standard_condition_uid: standard.standard_condition_uid,
                                        standard_uid: x.standard_uid,
                                        detect_name: defectName,
                                        range_name: record[x.standard_name].range_name,
                                        range: record[x.standard_name].range,
                                        status_value_type: standard.status_value_type,
                                      });
                                      if (res.code === 0) {
                                        message.success('设置成功');
                                        doFetch();
                                      } else {
                                        message.error('设置失败');
                                      }
                                      setFetching(false);
                                    },
                                    cancelText: '取消',
                                    onCancel: () => {},
                                  });
                                }}
                              >
                                标记
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    );
                  },
                };
              })
            )
            .concat(
              editing
                ? {
                    title: (
                      <Button icon={<PlusOutlined />} onClick={() => setAddStandardVisible(true)}>
                        添加标准
                      </Button>
                    ),
                    align: 'center' as 'left',
                    key: 'add',

                    render(_: any, record: any, idx: number) {
                      return <div key={idx + '-'}>-</div>;
                    },
                  }
                : []
            )}
        />
      </div>
      {editing && (
        <Button
          style={{
            marginLeft: 160,
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setAddLevelModalVisible(true);
          }}
        >
          添加等级
        </Button>
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        {!templateVisible && (
          <Tooltip title="复制用于新建标准">
            <Button
              type="link"
              onClick={() => {
                if (templateVisible) return;
                setStandardToCopy(standard);
                setTemplateVisible(false);
                setTemplateVisible(true);
              }}
              style={{ padding: 0 }}
            >
              复制
            </Button>
          </Tooltip>
        )}
        {!templateVisible && <Divider type="vertical" />}
        {!editing && (
          <Button
            type="link"
            onClick={() => {
              setEditing(true);
              setEditingItemId(standard.standard_condition_uid);
            }}
            style={{ padding: 0 }}
          >
            编辑
          </Button>
        )}
        {!editing && <Divider type="vertical" />}
        <Button
          type="link"
          onClick={() => {
            Modal.confirm({
              title: '提示',
              content: '确认删除此条标准吗',
              okText: '删除',
              cancelText: '取消',
              autoFocusButton: 'cancel',
              onOk: async () => {
                const res = await StandardRequest.deleteStandard(standard.standard_condition_uid);
                if (res.code === 0) {
                  message.success('删除成功');
                } else {
                  message.error(res.message);
                }
                setEditing(false);
                doFetch();
              },
              onCancel: () => {},
            });
          }}
          style={{ padding: 0 }}
        >
          删除
        </Button>
        {editing && <Divider type="vertical" />}
        {editing && (
          <Button type="link" onClick={() => setEditing(false)} style={{ padding: 0 }}>
            完成
          </Button>
        )}
      </div>
    </div>
  );
};

export default StandardList;
