import React from 'react';
import { Layout, Menu, Dropdown, Modal, Badge, Button, Divider, Tooltip, Tabs } from 'antd';
import './index.less';
import { useCtxState } from '@/App.store';
import UploadCenter from '@/ui-components/UploadCenter';
import { useIM } from '@/react-helpers/hooks/rongcloud';
import IM from '@/ui-components/IM';
import { dropByCacheKey } from 'react-router-cache-route';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  GoldOutlined,
  MenuOutlined,
  CloudUploadOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import {
  CostomUploadForTemplateMenu,
  CustomMenuItemForTemplateMenu,
  CustomMenuItem,
} from '@/ui-components/CostomUploads';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useHistory, useLocation } from 'react-router-dom';

const templateMenus = (
  <Menu>
    <Menu.SubMenu title="上传模板">
      <Menu.Item>
        <CostomUploadForTemplateMenu id={1} title={'上传创建资产模板'} />
      </Menu.Item>
      <Menu.Item>
        <CostomUploadForTemplateMenu id={2} title={'上传更新资产模板'} />
      </Menu.Item>
      <Menu.Item>
        <CostomUploadForTemplateMenu id={3} title={'上传删除资产模板'} />
      </Menu.Item>
      <Menu.Item>
        <CostomUploadForTemplateMenu id={4} title={'上传创建资产状态模板'} />
      </Menu.Item>
      <Menu.Item>
        <CostomUploadForTemplateMenu id={5} title={'上传更新资产状态模板'} />
      </Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title="下载模板">
      <Menu.Item>
        <CustomMenuItemForTemplateMenu id={1} title={'下载创建资产模板'} />
      </Menu.Item>
      <Menu.Item>
        <CustomMenuItemForTemplateMenu id={2} title={'下载更新资产模板'} />
      </Menu.Item>
      <Menu.Item>
        <CustomMenuItemForTemplateMenu id={3} title={'下载删除资产模板'} />
      </Menu.Item>
      <Menu.Item>
        <CustomMenuItemForTemplateMenu id={4} title={'下载创建资产状态模板'} />
      </Menu.Item>
      <Menu.Item>
        <CustomMenuItemForTemplateMenu id={5} title={'下载更新资产状态模板'} />
      </Menu.Item>
    </Menu.SubMenu>
  </Menu>
);

const menus = (
  <Menu>
    <SubMenu title="资产">
      <Menu.Item key="createasset">
        <CustomMenuItem url={'v2/api/asset/excel/upload'} title={'通过csv创建资产'} />
      </Menu.Item>
      <Menu.Item key="updateasset">
        <CustomMenuItem url={'v2/api/asset/update/asset/excel/upload'} title={'通过csv更新资产'} />
      </Menu.Item>
      <Menu.Item key="deleteasset">
        <CustomMenuItem url={'v2/api/asset/delete/asset/excel/upload'} title={'通过csv删除资产'} />
      </Menu.Item>
    </SubMenu>
    <SubMenu title="资产状态">
      <Menu.Item key="updateAssetStatus">
        <CustomMenuItem url={'v2/api/asset/update/status/excel/upload'} title={'通过csv更新资产状态'} />
      </Menu.Item>
      <Menu.Item key="create_AssetStatus_by_file">
        <CustomMenuItem url={'v2/api/asset/new/status/excel/upload'} title={'通过csv创建资产状态'} isBatch={true} />
      </Menu.Item>
    </SubMenu>
    <SubMenu title="资产标签">
      <Menu.Item key="create_Asset_tag_by_file">
        <CustomMenuItem url={'v2/api/file/csv/upload/asset_tag/1'} title={'通过csv创建资产标签'} />
      </Menu.Item>
      <Menu.Item key="update_asset_tag_by_file">
        <CustomMenuItem url={'v2/api/file/csv/upload/asset_tag/2'} title={'通过csv更新资产标签'} />
      </Menu.Item>
      <Menu.Item key="delete_asset_tag_by_file">
        <CustomMenuItem url={'v2/api/file/csv/upload/asset_tag/3'} title={'通过csv删除资产标签'} />
      </Menu.Item>
    </SubMenu>
    <SubMenu title="缺陷">
      <Menu.Item key="create_defect_by_file">
        <CustomMenuItem url={'v2/api/file/csv/upload/defect/1'} title={'通过csv创建缺陷'} />
      </Menu.Item>
      <Menu.Item key="update_defect_by_file">
        <CustomMenuItem url={'v2/api/file/csv/upload/defect/2'} title={'通过csv更新缺陷'} />
      </Menu.Item>
      <Menu.Item key="delete_defect_by_file">
        <CustomMenuItem url={'v2/api/file/csv/upload/defect/3'} title={'通过csv删除缺陷'} />
      </Menu.Item>
    </SubMenu>
    <SubMenu title="地理信息">
      <Menu.Item key="createaddr">
        <CustomMenuItem url={'v2/api/file/csv/upload/addr/1'} title={'通过csv创建地理信息'} />
      </Menu.Item>
      <Menu.Item key="updateaddr">
        <CustomMenuItem url={'v2/api/file/csv/upload/addr/2'} title={'通过csv更新地理信息'} />
      </Menu.Item>
      <Menu.Item key="deleteaddr">
        <CustomMenuItem url={'v2/api/file/csv/upload/addr/3'} title={'通过csv删除地理信息'} />
      </Menu.Item>
    </SubMenu>
    <SubMenu title="地理坐标">
      <Menu.Item key="createcoordinatet">
        <CustomMenuItem url={'v2/api/file/csv/upload/location/coordinate/1'} title={'通过csv创建地理坐标'} />
      </Menu.Item>
      <Menu.Item key="updatecoordinatet">
        <CustomMenuItem url={'v2/api/file/csv/upload/location/coordinate/2'} title={'通过csv更新地理坐标'} />
      </Menu.Item>
      <Menu.Item key="deletcoordinate">
        <CustomMenuItem url={'v2/api/file/csv/upload/location/coordinate/3'} title={'通过csv删除地理坐标'} />
      </Menu.Item>
    </SubMenu>
  </Menu>
);
const titleCfg = [
  { path: '/workbench/assets-management/assets-list', title: '资产清单' },
  { path: '/workbench/assets-management/status-list', title: '资产状态清单' },
  { path: '/workbench/assets-management/assets-template-list', title: '资产模板' },
  {
    path: '/workbench/assets-management/assets-status-standard',
    title: '资产状态标准',
  },
  { path: '/workbench/forms-management/my-template', title: '我的模板' },
  { path: '/workbench/forms-management/share-template', title: '模板库' },
  {
    path: '/workbench/forms-management/my-template/create-form-template',
    title: '创建模板',
  },
  {
    path: '/workbench/forms-management/my-template/create-free-form-template',
    title: '创建自由表单模板',
  },
  {
    path: '/workbench/forms-management/my-template/form-template-detail',
    title: '模板详情',
  },
  {
    path: '/workbench/forms-management/share-template/share-form-template-detail',
    title: '模板详情',
  },
  { path: '/workbench/forms-management/share-template/statistics', title: '统计' },
  {
    path: '/workbench/forms-management/share-template/create-form-head',
    title: '创建表单',
  },
  {
    path: '/workbench/forms-management/share-template/create-free-form-head',
    title: '创建表单',
  },
  { path: '/workbench/forms-management/form-data-list', title: '我的表单' },
  {
    path: '/workbench/forms-management/form-data-list/submit-formdata',
    title: '表单填写',
  },
  {
    path: '/workbench/forms-management/form-data-list/submit-free-formdata',
    title: '填写表单',
  },
  {
    path: '/workbench/forms-management/form-data-list/formdata-detail',
    title: '表单详情',
  },
  {
    path: '/workbench/normal-form/my-normal-template',
    title: '我的模板（普通表单）',
  },
  {
    path: '/workbench/normal-form/my-normal-template/detail',
    title: '模板详情（普通表单）',
  },
  {
    path: '/workbench/normal-form/share-normal-template',
    title: '模板库（普通表单）',
  },
  {
    path: '/workbench/normal-form/normal-form-data-list',
    title: '我的表单（普通表单）',
  },
  {
    path: '/workbench/normal-form/create-normal-template',
    title: '创建普通表单模板',
  },
  {
    path: '/workbench/normal-form/write-page',
    title: '表单填写（普通表单）',
  },
  {
    path: '/workbench/normal-form/statistics',
    title: '数据统计（普通表单）',
  },
  {
    path: '/workbench/chart-management/hi',
    title: 'Hi',
  },
  {
    path: '/workbench/chart-management/hi/import-config-list',
    title: '导入配置',
  },
  {
    path: '/workbench/chart-management/hi/preview',
    title: '配置预览',
  },
  {
    path: '/workbench/defect-management/defect-list',
    title: '缺陷清单',
  },
  {
    path: '/workbench/location/addr-list',
    title: '地理信息清单',
  },
  {
    path: '/workbench/location/coordinate-list',
    title: '地理坐标清单',
  },
  {
    path: '/workbench/auth-management/user',
    title: '成员列表',
  },
  {
    path: '/workbench/auth-management/role',
    title: '角色管理',
  },
  {
    path: '/workbench/auth-management/auth',
    title: '系统权限',
  },
  {
    path: '/workbench/auth-management/organization',
    title: '部门管理',
  },
  {
    path: '/workbench/auth-management/data-auth',
    title: '数据权限',
  },
  {
    path: '/workbench/project/list',
    title: '项目列表',
  },
  {
    path: '/workbench/project/detail',
    title: '项目详情',
  },
  {
    path: '/workbench/label/project-list',
    title: '标注项目',
  },
  {
    path: '/workbench/label/project-list/task-list',
    title: '标注项目详情',
  },
  {
    path: '/workbench/label/label-task/label-page',
    title: '图片标注',
  },
  {
    path: '/workbench/label/tasks',
    title: '标注任务',
  },
  {
    path: '/workbench/ai/model-list',
    title: 'AI应用',
  },
  {
    path: '/workbench/ai/model-list/detection',
    title: '模型检测',
  },
  {
    path: '/workbench/ai/model-list/detect-records/my',
    title: '我的检测记录',
  },
  {
    path: '/workbench/ai/model-list/detect-records',
    title: '模型检测记录',
  },
  {
    path: '/workbench/ai/model-training',
    title: '模型训练',
  },
  {
    path: '/workbench/ai/inaccurate-data',
    title: '不准确管理',
  },
];

const urlMap2Title = (url: string) => {
  const matchedItem = titleCfg.find((x) => x.path === url);
  if (matchedItem) {
    return matchedItem.title;
  } else {
    return '';
  }
};

export const useCacheRoute = () => {
  const [{ cachedRoutes = [] }, dispatch] = useCtxState();
  const location = useLocation();
  const history = useHistory();
  const closeWindow = (targetKey?: string) => {
    if (!targetKey) {
      targetKey = location.pathname;
    }
    if (cachedRoutes.length < 2) {
      return;
    }
    let arr = cachedRoutes.filter((x: string) => x !== targetKey);
    dispatch({
      type: 'SAVE_CACHE_ROUTES',
      cachedRoutes: arr,
    });
    dropByCacheKey(targetKey.toString());
    history.push(`${arr[arr.length - 1]}`);
  };
  return {
    closeWindow,
  };
};

/**
 * @global_state
 */
const Header = () => {
  const [{ sidebarCollapsed = false, cachedRoutes = [], userInfo }, dispatch] = useCtxState();
  const [IMvisible, setIMvisible] = React.useState(false);
  const [uploadCenterVisible, setUploadCenterVisible] = React.useState(false);
  const { im, hasConnect, totalUnread, existedConversationList, getExistedConversationList } = useIM();
  const [title, setTitle] = React.useState('');
  const location = useLocation();
  const history = useHistory();
  const [activeRoute, setActiveRoute] = React.useState('');
  const [visible, setVisible] = React.useState(true);

  //进入新页面时，更新title，并将路由缓存到全局状态中
  React.useEffect(() => {
    const { pathname } = location;
    setTitle(urlMap2Title(pathname));
    setActiveRoute(pathname);
    const isExist = !!cachedRoutes.find((x: string) => x === pathname);
    if (!isExist && pathname !== '/workbench') {
      let arr = cachedRoutes.map((x: string) => x);
      arr.push(pathname);
      dispatch({
        type: 'SAVE_CACHE_ROUTES',
        cachedRoutes: arr,
      });
    }
  }, [location]);

  // 项目详情页不显示头部
  // React.useEffect(() => {
  //   if (location.pathname === '/workbench/project/detail') {
  //     setVisible(false);
  //   } else {
  //     setVisible(true);
  //   }
  // }, [location]);
  return (
    <div>
      <Layout.Header className="workbench-page__header" style={{ display: visible ? 'flex' : 'none' }}>
        <span
          style={{ fontSize: '20px', cursor: 'pointer' }}
          onClick={() => {
            dispatch({ type: 'toggle:sidebarCollapsed' });
          }}
        >
          {sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          <span style={{ color: '#2d3843', fontSize: 20, marginLeft: 36, fontWeight: 500 }}>{title}</span>
        </span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasConnect ? (
            <Tooltip title="消息" placement="bottom">
              <Badge count={totalUnread} overflowCount={99} offset={[-5, -3]}>
                <MessageOutlined className="header-icon-btn" type="message" onClick={() => setIMvisible(true)} />
              </Badge>
            </Tooltip>
          ) : null}
          <Tooltip title="上传中心" placement="bottom">
            <CloudUploadOutlined onClick={() => setUploadCenterVisible(true)} className="header-icon-btn" />
          </Tooltip>
          <Divider type="vertical"></Divider>

          <Dropdown overlay={templateMenus} key="template-menus">
            <Button icon={<GoldOutlined />} className="header-btn">
              模板管理
            </Button>
          </Dropdown>
          <Dropdown overlay={menus} key="asset-menus">
            <Button icon={<MenuOutlined />} className="header-btn">
              菜单
            </Button>
          </Dropdown>
        </div>

        {userInfo && (
          <Modal
            title={null}
            visible={IMvisible}
            onCancel={() => {
              setIMvisible(false);
            }}
            width={1200}
            okButtonProps={{ style: { display: 'none' } }}
            maskClosable={false}
            cancelText="关闭"
            footer={null}
            bodyStyle={{ padding: 0 }}
          >
            <IM
              im={im}
              setIMVisible={setIMvisible}
              existedConversationList={existedConversationList}
              getExistedConversationList={getExistedConversationList}
              totalUnread={totalUnread}
            ></IM>
          </Modal>
        )}
        <Modal
          title="上传中心"
          visible={uploadCenterVisible}
          width={1200}
          okText="关闭"
          onOk={() => setUploadCenterVisible(false)}
          onCancel={() => setUploadCenterVisible(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          destroyOnClose
        >
          <UploadCenter />
        </Modal>
      </Layout.Header>
      <div className="card-container">
        <Tabs
          type="editable-card"
          onChange={(activeKey) => {
            history.push(`${activeKey}`);
          }}
          activeKey={activeRoute}
          onEdit={(targetKey, action) => {
            if (action === 'remove') {
              if (cachedRoutes.length < 2) {
                return;
              }
              let arr = cachedRoutes.filter((x: string) => x !== targetKey);
              dispatch({
                type: 'SAVE_CACHE_ROUTES',
                cachedRoutes: arr,
              });
              dropByCacheKey(targetKey.toString());
              history.push(`${arr[arr.length - 1]}`);
            }
          }}
        >
          {cachedRoutes.map((route: string) => {
            return (
              <Tabs.TabPane tab={urlMap2Title(route)} key={route} closable>
                {null}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};
export default Header;
