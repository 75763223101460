import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { message, Modal, Button, Form, Input, Divider, Table, Empty, Spin, Checkbox, Row, Col } from 'antd';
import * as authRequests from '@/requests/role';

import { formItemLayout } from '../configs';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { PlusOutlined, SwapOutlined } from '@ant-design/icons';
import { OrganizationGraph } from '@ant-design/graphs';
interface DataType extends OrgItem {
  children: DataType[];
  id: string;
  label: string;
}

interface CreateOrgFormValues {
  org_name: string;
  parent_id: string;
  description: string;
}

interface UpdateOrgFormValues {
  org_name: string;
  description: string;
}
const OrgInfoModal = ({ org, setEditingOrg }: { org: OrgItem | undefined; setEditingOrg: Function }) => {
  if (!org) return null;
  const { org_name, sys_org_id, description, create_time } = org;
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>部门信息
        </span>
      }
      okText="确认"
      cancelText={null}
      visible={!!org}
      onOk={() => setEditingOrg(undefined)}
      onCancel={() => setEditingOrg(undefined)}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="部门名称">
          <Input value={org_name} disabled />
        </Form.Item>
        <Form.Item label="部门id">
          <Input value={sys_org_id} disabled />
        </Form.Item>
        <Form.Item label="部门描述">
          <Input.TextArea value={description} disabled />
        </Form.Item>
        <Form.Item label="创建时间">
          <Input value={create_time} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const GrantRoleModal = (props: {
  org: OrgItem | undefined;
  setGrantOrg: Function;
  grantRoleModalVisible: boolean;
  setGrantRoleModalVisible: Function;
  fetchData: Function;
  history: RouteComponentProps['history'];
}) => {
  const { org, setGrantOrg, grantRoleModalVisible, setGrantRoleModalVisible, fetchData, history } = props;
  const [selectVals, setSelectVals] = React.useState<CheckboxValueType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [roles, setRoles] = React.useState<RoleItem[]>([]);

  React.useEffect(() => {
    if (org) {
      setSelectVals(org.role_list.map((x) => x.id));
    }
    setLoading(true);
    fetchRoleData();
  }, []);

  const fetchRoleData = async () => {
    const res = await authRequests.getRoleList({
      limit: 10000,
      skip: 0,
      name: '',
    });
    if (res.code === 0) {
      setRoles(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const onChange = (value: CheckboxValueType[]) => {
    setSelectVals(value);
  };

  const doUpdate = async () => {
    if (!org) return;
    setFetching(true);
    const res = await authRequests.addRoleToOrg({
      sys_org_id: org.sys_org_id,
      role_ids: selectVals as number[],
    });
    if (res.code === 0) {
      message.success('设置成功');
      fetchData();
      setGrantRoleModalVisible(false);
      setGrantOrg(undefined);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const goCreateRoles = () => {
    history.push('/base/auth-management/role');
  };
  if (!org) return null;
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>分配角色
        </span>
      }
      okText="确认"
      cancelText="取消"
      visible={grantRoleModalVisible}
      onOk={() => {
        doUpdate();
      }}
      onCancel={() => {
        setGrantRoleModalVisible(false);
        setGrantOrg(undefined);
      }}
      okButtonProps={{ disabled: fetching, loading: fetching }}
      destroyOnClose
    >
      <div style={{ minHeight: 200 }}>
        {loading ? (
          <Spin tip="加载中" style={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : (
          <>
            {roles.length < 1 && (
              <div>
                <Empty description="暂无角色，请先创建角色">
                  <Button type="primary" onClick={goCreateRoles}>
                    去创建
                  </Button>
                </Empty>
              </div>
            )}
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={selectVals}>
              <Row>
                {roles.map((x) => {
                  return (
                    <Col span={8} key={x.id} style={{ padding: 10 }}>
                      <Checkbox value={x.id}>{x.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </>
        )}
      </div>
    </Modal>
  );
};

const GrantDataAuthModal = (props: {
  org: OrgItem | undefined;
  setGrantOrg: Function;
  grantDataAuthModalVisible: boolean;
  setGrantDataAuthModalVisible: Function;
  fetchData: Function;
  history: RouteComponentProps['history'];
}) => {
  const { org, setGrantOrg, grantDataAuthModalVisible, setGrantDataAuthModalVisible, fetchData, history } = props;
  const [selectVals, setSelectVals] = React.useState<CheckboxValueType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [data, setData] = React.useState<DataAuthorityItem[]>([]);
  React.useEffect(() => {
    if (org) {
      setSelectVals(org.sys_data_authority_list.map((x) => x.data_authority_id));
    }
    setLoading(true);
    fetchDataAuths();
  }, []);

  const fetchDataAuths = async () => {
    const res = await authRequests.getDataAuthorityList({
      limit: 10000,
      skip: 0,
      data_authority_name: '',
    });
    if (res.code === 0) {
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const onChange = (value: CheckboxValueType[]) => {
    setSelectVals(value);
  };

  const doUpdate = async () => {
    if (!org) return;
    setFetching(true);
    const res = await authRequests.bindDataAuthority2SysMenu({
      sys_org_id: org.sys_org_id,
      data_authority_ids: selectVals as number[],
    });
    if (res.code === 0) {
      message.success('设置成功');
      fetchData();
      setGrantDataAuthModalVisible(false);
      setGrantOrg(undefined);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const goCreateRoles = () => {
    history.push('/base/auth-management/data-auth');
  };
  if (!org) return null;
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>分配数据权限
        </span>
      }
      okText="确认"
      cancelText="取消"
      visible={grantDataAuthModalVisible}
      onOk={() => {
        doUpdate();
      }}
      onCancel={() => {
        setGrantDataAuthModalVisible(false);
        setGrantOrg(undefined);
      }}
      okButtonProps={{ disabled: fetching, loading: fetching }}
      destroyOnClose
    >
      <div style={{ minHeight: 200 }}>
        {loading ? (
          <Spin tip="加载中" style={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : (
          <>
            {data.length < 1 && (
              <div>
                <Empty description="暂无数据权限，请先创建数据权限">
                  <Button type="primary" onClick={goCreateRoles}>
                    去创建
                  </Button>
                </Empty>
              </div>
            )}
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={selectVals}>
              <Row>
                {data.map((x) => {
                  return (
                    <Col span={8} key={x.data_authority_id} style={{ padding: 10 }}>
                      <Checkbox value={x.data_authority_id}>{x.data_authority_name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </>
        )}
      </div>
    </Modal>
  );
};

const OrganizatonManagement = (props: RouteComponentProps) => {
  const [isTree, setIsTree] = React.useState(false);
  const [data, setData] = React.useState<OrgItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [createOrgModalVisible, setCreateOrgVisible] = React.useState(false);
  const [createOrgForm] = Form.useForm();
  const handleCreateOrgModalOK = async (values: CreateOrgFormValues) => {
    doCreate(values);
  };
  const handleCreateOrgCancel = () => {
    setCreateOrgVisible(false);
    setEditingParent(undefined);
  };
  const [editingParent, setEditingParent] = React.useState<number | undefined>(undefined);

  const [filterOrg, setFilterOrg] = React.useState('');
  const [editingOrg, setEditingOrg] = React.useState<OrgItem | undefined>(undefined);
  const [viewingOrg, setViewingOrg] = React.useState<OrgItem | undefined>(undefined);

  const [updateOrgModalVisible, setUpdateOrgVisible] = React.useState(false);
  const [updateOrgForm] = Form.useForm();
  const handleUpdateOrgModalOK = async (values: UpdateOrgFormValues) => {
    doUpdate(values);
  };
  const handleUpdateOrgCancel = () => {
    setUpdateOrgVisible(false);
  };

  const [updateOrgRolesVisible, setUpdateOrgRolesVisible] = React.useState(false);
  const [grantingOrg, setGrantingOrg] = React.useState<OrgItem | undefined>(undefined);
  const [treeData, setTreeData] = React.useState<any>({});

  const [grantDataAuthModalVisible, setGrantDataAuthModalVisible] = React.useState(false);

  const traverseData = (orgData: OrgItem[]) => {
    return orgData.map((x) => {
      return {
        ...x,
        id: x.sys_org_id.toString(),
        label: x.org_name,
        value: {
          name: x.org_name,
        },
        children: [],
      };
    });
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);

    if (!page) page = 1;
    if (!pageSize) pageSize = 1000;
    const res = await authRequests.getOrgList({
      limit: pageSize,
      skip: pageSize * (page - 1),
      org_name: filterOrg,
    });
    if (res.code === 0) {
      setPagination({
        ...pagination,
        total: res.total,
      });
      setData(res.data);
      setTreeData(toTree(traverseData(res.data), 0)[0]);
    } else {
      setData([]);
      message.error(res.message);
    }
    setLoading(false);
  };

  const doCreate = async (values: CreateOrgFormValues) => {
    setFetching(true);
    const res = await authRequests.createOrg({
      org_name: values['org_name'],
      description: values['description'],
      parent_id: editingParent ? editingParent : values['parent_id'],
    });
    if (res.code === 0) {
      message.success('创建成功');
      fetchData();

      setCreateOrgVisible(false);
      setEditingParent(undefined);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const doUpdate = async (values: UpdateOrgFormValues) => {
    if (!editingOrg) return;
    if (values['org_name'] === editingOrg.org_name && values['description'] === editingOrg.description) {
      setUpdateOrgVisible(false);
      return;
    }
    setFetching(true);
    const res = await authRequests.updateOrg({
      org_name: values['org_name'],
      description: values['description'],
      sys_org_id: editingOrg.sys_org_id,
      parent_id: editingOrg.parent_id,
    });
    if (res.code === 0) {
      message.success('更新成功');
      fetchData();
      setUpdateOrgVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const toTree = (list: DataType[], parent_id: number) => {
    let len = list.length;
    function loop(parent_id: number) {
      let res = [];
      for (let i = 0; i < len; i++) {
        let item = list[i];
        if (item.parent_id === parent_id) {
          item.children = loop(item.sys_org_id);
          res.push(item);
        }
      }
      return res;
    }
    return loop(parent_id);
  };

  const CreateOrgForm = () => {
    return (
      <Form form={createOrgForm} preserve={false} layout="vertical" onFinish={handleCreateOrgModalOK}>
        <Form.Item label="部门名称" name="org_name" rules={[{ required: true, message: '请输入部门名称！' }]}>
          <Input placeholder="请输入部门名称" />
        </Form.Item>
        {!!!editingParent && (
          <Form.Item
            label="上级部门id"
            extra="若为根部门，则为0"
            name="parent_id"
            rules={[{ required: true, message: '请输入parentId！' }]}
          >
            <Input placeholder="请输入parentId" />
          </Form.Item>
        )}
        <Form.Item label="部门描述" name="description" rules={[{ required: true, message: '请输入部门描述！' }]}>
          <Input.TextArea placeholder="请输入部门描述" />
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCreateOrgCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const UpdateOrgForm = () => {
    if (!editingOrg) return null;
    return (
      <Form form={updateOrgForm} preserve={false} layout="vertical" onFinish={handleUpdateOrgModalOK}>
        <Form.Item
          label="部门名称"
          name="org_name"
          rules={[{ required: true, message: '请输入部门名称！' }]}
          initialValue={editingOrg.org_name}
        >
          <Input placeholder="请输入部门名称" />
        </Form.Item>

        <Form.Item
          label="部门描述"
          name="description"
          rules={[{ required: true, message: '请输入部门描述！' }]}
          initialValue={editingOrg.description}
        >
          <Input.TextArea placeholder="请输入部门描述" />
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleUpdateOrgCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const colunms = [
    {
      title: '序号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'left',
      render: (text: any, record: OrgItem, index: number) => `${index + 1}`,
    },
    {
      title: '部门名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left' as 'left',
      render: (text: any, record: OrgItem, index: number) => `${record.org_name}`,
    },

    {
      title: '部门描述',
      dataIndex: 'description',
      key: 'description',
      align: 'left' as 'left',
      render: (text: any, record: OrgItem, index: number) => `${record.description ? record.description : '-'}`,
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      key: 'create_time',
      align: 'left' as 'left',
      render: (text: any, record: OrgItem, index: number) => `${record.create_time}`,
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center' as 'left',
      render: (text: any, record: OrgItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={(e) => {
                e.preventDefault();
                setViewingOrg(record);
              }}
            >
              查看
            </a>

            <Divider type="vertical" />

            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                setEditingOrg(record);
                setUpdateOrgVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />

            <a
              key="user-add"
              onClick={(e) => {
                e.preventDefault();
                setEditingParent(record.sys_org_id);
                setCreateOrgVisible(true);
              }}
            >
              创建子部门
            </a>

            <Divider type="vertical" />

            <a
              key="usergroup-add"
              onClick={(e) => {
                e.preventDefault();
                setGrantingOrg(record);
                setUpdateOrgRolesVisible(true);
              }}
            >
              添加角色
            </a>

            <Divider type="vertical" />

            <a
              key="data-auth-add"
              onClick={(e) => {
                e.preventDefault();
                setGrantingOrg(record);
                setGrantDataAuthModalVisible(true);
              }}
            >
              添加数据权限
            </a>

            <Divider type="vertical" />

            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: '提示',
                  content: '确认删除该部门吗？',
                  okText: '确认',
                  onOk: async () => {
                    const { sys_org_id } = record;
                    const res = await authRequests.deleteOrg({
                      sys_org_id,
                    });
                    if (res.code === 0) {
                      message.success('删除成功！');
                      fetchData();
                    } else {
                      message.error(res.message);
                    }
                  },
                  cancelText: '取消',
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];

  return (
    <div className="org-manage-page">
      <div className="tools-wrapper">
        {isTree && <div></div>}
        {!!!isTree && (
          <div className="filter-item">
            <Input.Search
              onSearch={() => fetchData()}
              style={{ maxWidth: 200, marginRight: 10 }}
              placeholder="搜索部门"
              value={filterOrg}
              onChange={(e) => setFilterOrg(e.target.value)}
            ></Input.Search>
          </div>
        )}
        <div>
          {!!!isTree && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setCreateOrgVisible(true)}
              style={{ marginRight: 12 }}
            >
              创建部门
            </Button>
          )}
          <Button icon={<SwapOutlined />} onClick={() => setIsTree(!isTree)}>
            {isTree ? '清单模式' : '图形模式'}
          </Button>
        </div>
      </div>

      {!!!isTree && (
        <div style={{ backgroundColor: '#ffffff', paddingTop: 0 }}>
          <Table
            dataSource={data}
            columns={colunms}
            expandIcon={() => null}
            pagination={{
              ...pagination,
              showTotal: (total: number) => {
                return <span>{`共 ${total} 条数据`}</span>;
              },
              style: { marginRight: 16 },
            }}
            locale={{
              emptyText: (
                <Empty description="暂无部门，点击添加部门，创建根部门">
                  <Button type="primary" onClick={() => setCreateOrgVisible(true)}>
                    创建部门
                  </Button>
                </Empty>
              ),
            }}
            loading={loading}
            rowKey={(record) => record.sys_org_id.toString()}
          ></Table>
        </div>
      )}

      {isTree && data.length > 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 24,
            backgroundColor: '#ffffff',
            width: '100%',
          }}
        >
          <OrganizationGraph
            data={treeData}
            width={1400}
            nodeCfg={{}}
            markerCfg={{}}
            edgeCfg={{
              endArrow: false,
            }}
            onReady={(graph) => {
              graph.on('node:click', (ent) => {
                const { item } = ent;
                if (!item) return;
                if (!item._cfg) return;
                if (!item._cfg.model) return;
                const id = item._cfg.model.sys_org_id as any;
                setEditingParent(id);
                setCreateOrgVisible(true);
              });
            }}
            behaviors={['drag-canvas', 'zoom-canvas', 'drag-node']}
          />
        </div>
      )}
      <OrgInfoModal org={viewingOrg} setEditingOrg={setViewingOrg} />
      <Modal
        visible={createOrgModalVisible}
        title={
          <span>
            <div className="title-prefix"></div>创建部门
          </span>
        }
        footer={null}
        onCancel={handleCreateOrgCancel}
        destroyOnClose
      >
        <CreateOrgForm />
      </Modal>
      <Modal
        visible={updateOrgModalVisible}
        title={
          <span>
            <div className="title-prefix"></div>编辑部门
          </span>
        }
        footer={null}
        onCancel={handleUpdateOrgCancel}
        destroyOnClose
      >
        <UpdateOrgForm />
      </Modal>
      {updateOrgRolesVisible && (
        <GrantRoleModal
          org={grantingOrg}
          setGrantOrg={setGrantingOrg}
          grantRoleModalVisible={updateOrgRolesVisible}
          setGrantRoleModalVisible={setUpdateOrgRolesVisible}
          fetchData={fetchData}
          history={props.history}
        />
      )}
      {grantDataAuthModalVisible && (
        <GrantDataAuthModal
          org={grantingOrg}
          setGrantOrg={setGrantingOrg}
          grantDataAuthModalVisible={grantDataAuthModalVisible}
          setGrantDataAuthModalVisible={setGrantDataAuthModalVisible}
          fetchData={fetchData}
          history={props.history}
        />
      )}
    </div>
  );
};

export default OrganizatonManagement;
