import * as React from 'react';
import {
  Modal,
  Menu,
  Form,
  Image,
  Upload,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  List,
  Avatar,
  Divider,
  message,
  Radio,
  Space,
  Switch,
  Tag,
} from 'antd';
import {
  WarningOutlined,
  DashOutlined,
  EyeOutlined,
  CarryOutOutlined,
  DeploymentUnitOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import '../styles/editModal.less';
import {
  updateProject,
  getExecuteStatusList,
  addExecuteStatus,
  deleteExecuteStatus,
  addProjectWorkTimeType,
  deleteProjectWorkTimeType,
  getProjectWorkTimeTypeList,
} from '@/requests/project';
import { useCtxState } from '@/App.store';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  project: ProjectItem | undefined;
  setProject?: Function;
  fetchData: Function;
}

const AddExecuteStatusModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, project, fetchData, setProject } = props;
  const [name, setName] = React.useState<string>('');
  const handleOk = () => {
    doAdd();
  };
  const handleCancel = () => {
    setVisible(false);
  };
  //新增执行状态
  const doAdd = async () => {
    if (!project) return;
    if (!name) return message.error('请输入执行状态名称');
    const res = await addExecuteStatus({
      project_id: project.id,
      execute_status: name,
    });
    if (res.code === 0) {
      message.success('添加成功');
      fetchData(project.id);
      setVisible(false);
    } else {
      message.error(res.message);
    }
  };

  return (
    <Modal visible={visible} title="添加执行状态" onOk={handleOk} onCancel={handleCancel}>
      <Form>
        <Form.Item label="名称">
          <Input placeholder="请输入执行状态名称" value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddWorkTimeTypeModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, project, fetchData, setProject } = props;
  const [name, setName] = React.useState<string>('');
  const handleOk = () => {
    doAdd();
  };
  const handleCancel = () => {
    setVisible(false);
  };
  //新增执行状态
  const doAdd = async () => {
    if (!project) return;
    if (!name) return message.error('请输入工时类型名称');
    const res = await addProjectWorkTimeType({
      project_id: project.id,
      hours_type: name,
    });
    if (res.code === 0) {
      message.success('添加成功');
      fetchData(project.id);
      setVisible(false);
    } else {
      message.error(res.message);
    }
  };
  return (
    <Modal visible={visible} title="添加工时班次类型" onOk={handleOk} onCancel={handleCancel}>
      <Form>
        <Form.Item label="名称">
          <Input placeholder="请输入工时班次类型" value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditProjectModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, project, fetchData, setProject } = props;
  const [{ sysUsers }] = useCtxState();
  const [menuType, setMenuType] = React.useState('1');
  const [form] = Form.useForm();
  const [ownerInfo, setOwnerInfo] = React.useState<UserInfoItem>();
  const [IDEditable, setIDEditable] = React.useState(false);
  const [executeStatus, setExecuteStatus] = React.useState<ExecuteStatusItem[]>([]);
  const [addStatusVisible, setAddStatusVisible] = React.useState(false);
  const [AddWorkTimeTypeVsisble, setAddWorkTimeTypeVsisble] = React.useState(false);
  const [workTimeType, setWorkTimeType] = React.useState<ProjectWorkTimeTypeItem[]>([]);
  const handleCancel = () => {
    setProject && setProject(undefined);
    setVisible(false);
  };
  const handleOK = (values: any) => {
    if (!project) return;
    doUpdate({
      project_code: project.project_code,
      project_name: values['project_name'],
      description: values['description'],
      template_code: project.template_code,
      id: project.id,
    });
    console.log(values);
  };

  const handleExit = () => {
    Modal.confirm({
      title: '退出项目',
      content: '一旦你退出了该项目，你将不能查看任何关于该项目的信息。退出项目后，如果想重新加入，请联系项目管理员。',
      okText: '确定退出',
      cancelText: '再想想',
      onOk: () => {},
      onCancel: () => {},
    });
  };
  const doUpdate = async (values: UpdateProjectReq) => {
    if (!project) return;
    const res = await updateProject({
      ...values,
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  //根据projectId查询项目下的任务执行状态
  const fetchTaskStatus = async (id: number) => {
    const res = await getExecuteStatusList({ project_id: id, limit: 1000000, skip: 0 });
    if (res.code === 0) {
      setExecuteStatus(res.data);
    } else {
      message.error('获取任务状态失败');
    }
  };
  //删除任务执行状态
  const doDeleteExecuteStatus = async (id: number) => {
    if (!project) return;
    const res = await deleteExecuteStatus(id);
    if (res.code === 0) {
      message.success('删除成功');
      fetchTaskStatus(project.id);
    } else {
      message.error('网络错误');
    }
  };

  //查询项目下的工时班次类型
  const fetchWorkTimeType = async (id: number) => {
    const res = await getProjectWorkTimeTypeList({ project_id: id, limit: 1000000, skip: 0 });
    if (res.code === 0) {
      setWorkTimeType(res.data);
    } else {
      message.error('获取工时班次类型失败');
    }
  };
  //删除工时班次类型
  const doDeleteWorkTimeType = async (id: number) => {
    if (!project) return;
    const res = await deleteProjectWorkTimeType(id);
    if (res.code === 0) {
      message.success('删除成功');
      fetchWorkTimeType(project.id);
    } else {
      message.error('网络错误');
    }
  };

  React.useEffect(() => {
    if (!project) return;
    fetchTaskStatus(project.id);
    fetchWorkTimeType(project.id);
    const owner = sysUsers.find((x: UserInfoItem) => x.id === project.creator_id);
    if (owner) {
      setOwnerInfo(owner);
    }
  }, [project]);
  if (!project) return null;
  return (
    <Modal
      title={'项目设置'}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={800}
      bodyStyle={{ padding: 0 }}
      destroyOnClose={true}
    >
      <div className={'edit-project-container'}>
        <div style={{ width: 180 }}>
          <Menu
            mode="inline"
            style={{ height: '100%' }}
            activeKey={menuType}
            onChange={(e) => console.log(e)}
            onClick={({ key }) => {
              setMenuType(key as any);
            }}
          >
            <Menu.Item key="1" icon={<WarningOutlined />} style={{ marginBottom: 16 }}>
              概览
            </Menu.Item>
            <Menu.Item key="2" icon={<EyeOutlined />} style={{ marginBottom: 16 }}>
              项目偏好
            </Menu.Item>
            <Menu.Item key="3" icon={<CarryOutOutlined />} style={{ marginBottom: 16 }}>
              任务设置
            </Menu.Item>
            <Menu.Item key="4" icon={<DeploymentUnitOutlined />} style={{ marginBottom: 16 }}>
              任务流转
            </Menu.Item>
            <Menu.Item key="5" icon={<DashOutlined />} style={{ marginBottom: 16 }}>
              更多
            </Menu.Item>
          </Menu>
        </div>
        <div style={{ width: 620, padding: 16 }}>
          {menuType === '1' && (
            <div>
              <Form form={form} preserve={false} colon={false} layout="vertical" onFinish={handleOK}>
                <Row>
                  <Col span={13}>
                    <Form.Item label="项目封面">
                      <Image
                        src={project.cover ? '' : process.env.PUBLIC_URL + '/img/common/project-cover.png'}
                        width={300}
                        height={150}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label=" " name="cover" extra="最佳图片比例为300*150">
                      <Upload>
                        <Button>上传新封面</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      label="项目名称"
                      name={'project_name'}
                      initialValue={project.project_name}
                      rules={[
                        {
                          required: true,
                          message: '请输入项目名称',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="项目进度(%)" name={'project_schedule'} initialValue={project.project_schedule}>
                      <InputNumber style={{ width: '100%' }} min={0} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="项目简介"
                  name={'description'}
                  initialValue={project.description}
                  rules={[
                    {
                      required: true,
                      message: '请输入项目简介',
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item label="项目公开性" name={'privates'} initialValue={project.privates === true ? 1 : 0}>
                  <Select>
                    <Select.Option value={1}>私有项目（仅项目成员可查看和编辑）</Select.Option>
                    <Select.Option value={0}>公开项目（所有人都可通过链接访问）</Select.Option>
                  </Select>
                </Form.Item>
                {ownerInfo && (
                  <Form.Item label="项目拥有者">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {ownerInfo.avatar ? (
                        <Avatar src={`v2/api/user/avatar/${ownerInfo.avatar}`} />
                      ) : (
                        <Avatar>{ownerInfo.username}</Avatar>
                      )}
                      <span style={{ paddingLeft: 8 }}>{ownerInfo.username}</span>
                    </div>
                  </Form.Item>
                )}
                <Divider></Divider>
                <Form.Item>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
                    <Button type="primary" htmlType="submit">
                      保存
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
          {menuType === '2' && (
            <div>
              <Form colon={false} layout="vertical">
                <Form.Item label={'看板风格'}>
                  <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>
                    切换任务看板的主题风格，内置两种风格。
                  </div>
                  <Radio.Group onChange={() => {}} value={1}>
                    <Space direction="vertical">
                      <Radio value={1}>默认</Radio>
                      <Radio value={2}>简约</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Divider />
              </Form>
              <Form colon={false}>
                <Form.Item label={'自动更新项目进度'} style={{ marginBottom: 0 }}>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Switch defaultChecked onChange={() => {}} />
                  </div>
                </Form.Item>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>
                  根据当前任务的完成情况自动计算项目进度。
                </div>
              </Form>
              <Divider />
            </div>
          )}
          {menuType === '3' && (
            <div>
              <Form colon={false}>
                <Form.Item label={'项目ID'} style={{ marginBottom: 0 }}>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Switch
                      defaultChecked
                      onChange={(e) => {
                        setIDEditable(e);
                      }}
                      checked={IDEditable}
                    />
                  </div>
                </Form.Item>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>
                  设置项目编号，编号将作为ID前缀，用以区别项目。支持2-6位字母。
                </div>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)', display: 'flex' }}>
                  <Input
                    placeholder="如Pear Project可以设置为EP"
                    style={{ width: 250, marginRight: 16 }}
                    disabled={!IDEditable}
                  ></Input>
                  <Button type="primary" disabled={!IDEditable}>
                    保存
                  </Button>
                </div>
              </Form>
              <Divider />
              <Form colon={false}>
                <Form.Item label={'任务开始时间'} style={{ marginBottom: 0 }}>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Switch defaultChecked onChange={() => {}} />
                  </div>
                </Form.Item>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>为任务设置开始时间，更科学的规划工作。</div>
              </Form>
              <Divider />
              <Form colon={false}>
                <Form.Item label={'新任务默认开启隐私模式'} style={{ marginBottom: 0 }}>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    <Switch onChange={() => {}} />
                  </div>
                </Form.Item>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>
                  对本项目内的新任务默认开启隐私模式。创建成功后仅参与者可见
                </div>
              </Form>
              <Divider />
              <Form colon={false}>
                <Form.Item label={'任务执行状态'} style={{ marginBottom: 0 }}></Form.Item>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>
                  {executeStatus.map((item) => {
                    return (
                      <Tag
                        key={item.id}
                        closable
                        onClose={() => {
                          doDeleteExecuteStatus(item.id);
                        }}
                      >
                        {item.execute_status}
                      </Tag>
                    );
                  })}
                  <Tag
                    icon={<PlusOutlined />}
                    style={{ cursor: 'pointer' }}
                    color="blue"
                    onClick={() => setAddStatusVisible(true)}
                  >
                    添加
                  </Tag>
                </div>
              </Form>
              <Divider />
              <Form colon={false}>
                <Form.Item label={'工时班次种类'} style={{ marginBottom: 0 }}></Form.Item>
                <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>
                  {workTimeType.map((item) => {
                    return (
                      <Tag
                        key={item.id}
                        closable
                        onClose={() => {
                          doDeleteWorkTimeType(item.id);
                        }}
                      >
                        {item.hours_type}
                      </Tag>
                    );
                  })}
                  <Tag
                    icon={<PlusOutlined />}
                    style={{ cursor: 'pointer' }}
                    color="blue"
                    onClick={() => setAddWorkTimeTypeVsisble(true)}
                  >
                    添加
                  </Tag>
                </div>
              </Form>
            </div>
          )}
          {menuType === '4' && (
            <div>
              <Button type="primary" icon={<PlusOutlined />}>
                创建规则
              </Button>
              <List />
            </div>
          )}
          {menuType === '5' && (
            <div>
              <Form colon={false} layout="vertical">
                <Form.Item label={'项目操作'}>
                  <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>您可以执行以下操作</div>
                  <Button.Group>
                    <Space>
                      <Button>归档项目</Button>
                      <Button onClick={handleExit}>退出</Button>
                      <Button danger>移至回收站</Button>
                    </Space>
                  </Button.Group>
                </Form.Item>
                <Divider />
              </Form>
            </div>
          )}
        </div>
      </div>
      {addStatusVisible && (
        <AddExecuteStatusModal
          visible={addStatusVisible}
          setVisible={setAddStatusVisible}
          project={project}
          setProject={setProject}
          fetchData={fetchTaskStatus}
        />
      )}
      {AddWorkTimeTypeVsisble && (
        <AddWorkTimeTypeModal
          visible={AddWorkTimeTypeVsisble}
          setVisible={setAddWorkTimeTypeVsisble}
          project={project}
          setProject={setProject}
          fetchData={fetchWorkTimeType}
        />
      )}
    </Modal>
  );
};

export default EditProjectModal;
