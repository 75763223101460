/// <reference path="type.d.ts" />
/// <reference path="assets.d.ts" />

import request from './_base'
import uploadFileRequest from './formdata'

//获取资产清单 高级筛选
export async function fetchFilteredAssetList(input: {
  asset_name?: string
  asset_comps?: string[]

  v_asset_comps?: string[]
  v_asset_status?: string[]
  start_status_create_time?: string
  end_status_create_time?: string
  asset_ids?: string[]
  tags?: FilterTagItem[]
  limit: number
  skip: number
}): Promise<
  AssetsResourceProto<{
    data: AssetItem[]
    total: number
  }>
> {
  return request({
    method: 'POST',
    url: '/asset_data/v2/advance',
    data: input,
  }) as any
}
export async function fetchFilteredAssetListDownload(input: {
  asset_name?: string
  asset_comps?: string[]
  v_asset_comps?: string[]
  v_asset_status?: string[]
  start_status_create_time?: string
  end_status_create_time?: string
  tags?: FilterTagItem[]
  limit: number
  skip: number
}): Promise<AssetsResourceProto<{ data: string }>> {
  return request({
    method: 'POST',
    url: '/asset_data/v2/asset/advance/download',
    data: input,
  }) as any
}
export async function fetchFilteredStatusListDownload(input: {
  asset_name?: string
  asset_comps?: string[]
  status_name?: string[]
  status_value?: string[]
  v_asset_comps?: string[]
  v_asset_status?: string[]
  start_status_create_time?: string
  end_status_create_time?: string
  range_names?: string[]
  status_standard_uids?: string[]
  is_all_data?: boolean
  limit: number
  skip: number
}): Promise<AssetsResourceProto<{ data: string }>> {
  return request({
    method: 'POST',
    url: '/asset_data/v2/status/advance/download',
    data: {
      ...input,
    },
  }) as any
}

//创建资产
export async function createAsset(input: CreateAssetReq): Promise<AssetsResourceProto<{ data: AssetItem }>> {
  return request({
    method: 'POST',
    url: `/asset/new`,
    data: input,
  }) as any
}

//删除资产
export async function deleteAsset(input: { asset_id: string }): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/asset/del/${input.asset_id}`,
  }) as any
}

//获取单个资产信息
export async function getAssetItem(asset_id: string): Promise<
  AssetsResourceProto<{
    data: AssetItem
  }>
> {
  return request({
    method: 'GET',
    url: `/asset/v2/${asset_id}`,
  }) as any
}

//更新资产

export async function updateAssetItem(input: UpdateAssetReq): Promise<
  AssetsResourceProto<{
    data: AssetItem
  }>
> {
  return request({
    method: 'PUT',
    url: `/asset/update`,
    data: input,
  }) as any
}

// 创建资产状态
export async function createAssetStatus(
  input: AssetStatusItem
): Promise<AssetsResourceProto<{ asset_status: AssetStatusItem }>> {
  // if (!input.asset_id)
  //   throw new Error(`[createAssetStatus] asset_id is required`);
  // if (!input.status_name)
  //   throw new Error(`[createAssetStatus] status_name is required`);

  // if (!input.hasOwnProperty("value")) input.status_value = "";
  // if (!input.comment) input.comment = input.status_name;

  // // JUST for mock purpose
  // if (!input.user_id) input.user_id = "9527";
  // if (!input.user_name) input.user_name = "random";
  // if (typeof input.status_value !== "string")
  //   input.status_value = String(input.status_value);
  // if (!input.form_id) input.form_id = "9528";
  // if (!input.task_id) input.task_id = "9528";

  return request({
    method: 'POST',
    url: '/assetstatus/new',
    data: input,
  }) as any
}
//更新资产状态
export async function updateAssetStatus(
  input: AssetStatusItem
): Promise<AssetsResourceProto<{ asset_status: AssetStatusItem }>> {
  if (!input) return Promise.reject(new Error(`no status provided!`))
  if (!input.asset_id) throw new Error(`[createAssetStatus] asset_id is required`)
  if (!input.status_name) throw new Error(`[createAssetStatus] status_name is required`)

  if (!input.comment) input.comment = input.status_name

  return request({
    method: 'POST',
    url: '/assetstatus/update',
    data: input,
  }) as any
}
//修改资产状态值历史
export async function modifyStatusValueHistory(
  input: AssetStatusItem
): Promise<AssetsResourceProto<{ asset_status: AssetStatusItem }>> {
  return request({
    method: 'POST',
    url: '/assetstatus/update/history',
    data: input,
  }) as any
}

// 删除资产状态一条历史
export async function removeAssetStatus(status_id: AssetStatusItem['status_id']): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'POST',
    url: '/assetstatus/del',
    data: { status_id },
  }) as any
}
// 删除资产状态(所有内容)
export async function deleteAssetStatus(status_uid: AssetStatusItem['status_uid']): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    url: '/assetstatus/status',
    data: { status_uid },
  }) as any
}

// 根据uid更新当前状态Status的name和type
export async function updateStatusNameAndType(input: {
  status_uid: string
  status_type: string
  status_name: string
}): Promise<
  AssetsResourceProto<{
    data: AssetStatusItem
  }>
> {
  return request({
    method: 'PUT',
    url: '/assetstatus/update/name/type',
    data: input,
  }) as any
}

// 根据uid更新相同assetName+assetComps+statusName的Status的name和type
export async function updateStatusNameAndTypeByBatch(input: {
  status_uid: string
  status_type: string
  status_name: string
}): Promise<
  AssetsResourceProto<{
    data: null
  }>
> {
  return request({
    method: 'PUT',
    url: '/assetstatus/update/batch/name/type',
    data: input,
  }) as any
}
//获取status的历史的历史
export async function getStatusvalueHistory(input: { status_id: AssetStatusItem['status_id'] }): Promise<
  AssetsResourceProto<{
    data: Statushistory[]
  }>
> {
  return request({
    method: 'GET',
    url: `/assetstatus/get/history/${input.status_id}`,
  }) as any
}

// 通过excel创建资产
export async function createAssetByFile(input: createAssetByFileItem): Promise<
  AssetsResourceProto<{
    asset_ids: string[]
    v_asset_ids: string[]
  }>
> {
  return request({
    method: 'POST',
    url: '/images/api/asset/excel/upload',
    data: input,
  }) as any
}
export async function createAssetByFileV2(input: { file: any }): Promise<
  AssetsResourceProto<{
    asset_ids: string[]
    v_asset_ids: string[]
  }>
> {
  return request({
    method: 'POST',
    url: '/images/api/asset/excel/upload',
    data: input,
  }) as any
}

// 获取上传中心任务列表
export async function getTaskList(
  pageSize?: number,
  page?: number
): Promise<
  AssetsResourceProto<{
    data: TaskItem[]
    total: number
  }>
> {
  return request({
    method: 'GET',
    url: `/asset/uploadtask/all/${pageSize}/${page}`,
  }) as any
}
//获取资产图片uid
export async function getImagesUid(asset_id: string): Promise<
  AssetsResourceProto<{
    data: string[]
  }>
> {
  return request({
    method: 'GET',
    url: `/pic/uuid/asset/${asset_id} `,
  }) as any
}

//获取资产状态图片UID
export async function getStatusImagesUid(status_uid: string): Promise<
  AssetsResourceProto<{
    data: string[]
  }>
> {
  return request({
    method: 'GET',
    url: `/pic/uuid/status/${status_uid} `,
  }) as any
}

// 上传文件by token
export async function uploadExcelByToken(input: { file: any; token: string }): Promise<AssetsResourceProto<{}>> {
  const formData = new FormData()
  input.file.forEach((file: any) => {
    formData.append('file', file)
  })
  return uploadFileRequest({
    method: 'POST',
    url: `/asset/excel/upload`,
    data: formData,
    headers: {
      token: input.token,
    },
  }) as any
}
// 通过excel创建资产
export async function uploadExcelWithoutToken(input: { file: any }): Promise<AssetsResourceProto<{}>> {
  const formData = new FormData()
  input.file.forEach((file: any) => {
    formData.append('file', file)
  })
  return uploadFileRequest({
    method: 'POST',
    url: `/asset/excel/upload`,
    data: formData,
  }) as any
}
// 通过excel更新资产
export async function uploadExcelWithoutToken2UpdateAsset(input: { file: any }): Promise<AssetsResourceProto<{}>> {
  const formData = new FormData()
  input.file.forEach((file: any) => {
    formData.append('file', file)
  })
  return uploadFileRequest({
    method: 'POST',
    url: `/asset/update/asset/excel/upload`,
    data: formData,
  }) as any
}
// 通过excel更新资产状态
export async function uploadExcelWithoutToken2UpdateStatus(input: { file: any }): Promise<AssetsResourceProto<{}>> {
  const formData = new FormData()
  input.file.forEach((file: any) => {
    formData.append('file', file)
  })
  return uploadFileRequest({
    method: 'POST',
    url: `/asset/update/status/excel/upload`,
    data: formData,
  }) as any
}
// 通过excel删除资产
export async function uploadExcelWithoutToken2DeleteAsset(input: { file: any }): Promise<AssetsResourceProto<{}>> {
  const formData = new FormData()
  input.file.forEach((file: any) => {
    formData.append('file', file)
  })
  return uploadFileRequest({
    method: 'POST',
    url: `/asset/delete/asset/excel/upload`,
    data: formData,
  }) as any
}

// 任务回滚
export async function uploadBack(input: { upload_id: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'GET',
    url: `/asset/task/upload/excel/back/${input.upload_id}`,
  }) as any
}

// 撤销任务回滚
export async function cancelUploadBack(input: { upload_id: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'GET',
    url: `/asset/task/upload/excel/back/cancel${input.upload_id}`,
  }) as any
}
// 下载解析失败文件
export async function downloadErrorInfoFIle(input: { upload_id: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'GET',
    url: `/file/download/csv/assettask/${input.upload_id}`,
  }) as any
}

//获取资产状态清单 高级筛选
export async function fetchFilteredStatusList(input: {
  asset_name?: string
  asset_comps?: string[]
  status_name?: string[]
  status_value?: string[]
  v_asset_comps?: string[]
  v_asset_status?: string[]
  start_status_create_time?: string
  end_status_create_time?: string
  range_names?: string[]
  is_all_data?: boolean
  status_standard_uids?: string[]
  limit: number
  skip: number
}): Promise<
  AssetsResourceProto<{
    data: StatusListItem[]
    total: number
  }>
> {
  return request({
    method: 'POST',
    url: '/asset_data/v2/status/advance',
    data: {
      ...input,
    },
  }) as any
}

//上传模板

export async function uploadCSVtemplate(id: string): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'POST',
    url: `/asset/csv/template/upload/${id}`,
  }) as any
}

//下载模板

export async function downloadCSVtemplate(id: number): Promise<Blob> {
  return request({
    method: 'GET',
    url: `/asset/csv/template/download/${id}`,
  }) as any
}

// 生成二维码pdf
export async function getAssetQRcodePDFFile(input: {
  asset_name?: string
  asset_comps?: string[]
  status_name?: string[]
  status_value?: string[]
  v_asset_comps?: string[]
  v_asset_status?: string[]
  start_status_create_time?: string
  end_status_create_time?: string
  range_names?: string[]
  status_standard_uids?: string[]
  asset_ids?: string[]
  qr_pdf_size: number
}): Promise<
  AssetsResourceProto<{
    data: string
  }>
> {
  return request({
    method: 'POST',
    url: '/file/qr/pdf/asset/new',
    data: {
      ...input,
    },
  }) as any
}

/**
 * 通过statusUid更新最新值的状态值和所有值的状态名
 * @param  {UpdateAssetTagReq} input
 */
export function updateAssetTag(input: UpdateAssetTagReq): Promise<AssetsResourceProto<{ data: AssetStatusItem }>> {
  return request({
    method: 'PUT',
    url: `/assetstatus/update/names/value`,
    data: input,
  }) as any
}
