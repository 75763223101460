import * as React from 'react'
import { Button, Row, Col, Divider, Form, Radio, InputNumber, Select, Input } from 'antd'
interface PropsType {
  setCurrent: Function
  template: ShareFormTemplateItem
  formHeadData: CreateFormHeadReq
  setFormHeadData: Function
  userInfo: UserInfoItem
}
const vcompsRule = [
  { label: '与初设相同', value: 'equal' },
  { label: '循环', value: 'recircle' },
  { label: '等差', value: 'arithmetic' },
  { label: '无', value: ' ' },
]
const CreateFormHeadStepTwo = (props: PropsType) => {
  const { setCurrent, setFormHeadData, formHeadData, template, userInfo } = props

  return (
    <div
      style={{
        width: '100%',
        padding: 16,
      }}
    >
      <Row gutter={36}>
        <Col span={8}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#F9F9FB',
              height: '100%',
              padding: 16,
            }}
          >
            <h3>
              <div className="title-prefix"></div>公有规则
            </h3>
            <div>
              {formHeadData.public_init_regulations.length < 0 ? (
                <div>无</div>
              ) : (
                formHeadData.public_init_regulations.map((x, _index) => {
                  return (
                    <div key={_index}>
                      <Divider orientation="left">{x.v_asset_comp}</Divider>
                      <Form>
                        <Form.Item label="变化规则">
                          <Radio.Group
                            options={vcompsRule}
                            value={x.mode}
                            onChange={(e) => {
                              let arr = formHeadData.public_init_regulations.map((x) => x)
                              arr[_index].mode = e.target.value
                              setFormHeadData({
                                ...formHeadData,
                                public_init_regulations: arr,
                              })
                            }}
                          ></Radio.Group>
                        </Form.Item>
                        {x.mode === 'arithmetic' && (
                          <>
                            <Form layout="inline">
                              <Form.Item label="公差">
                                <InputNumber
                                  onChange={(value) => {
                                    let arr = formHeadData.public_init_regulations.map((x) => x)
                                    arr[_index].value = value ? value.toString() : ''
                                    setFormHeadData({
                                      ...formHeadData,
                                      public_init_regulations: arr,
                                    })
                                  }}
                                  value={Number(x.value)}
                                  size="small"
                                />
                              </Form.Item>
                              <Form.Item label="最小值">
                                <InputNumber
                                  size="small"
                                  onChange={(value) => {
                                    let arr = formHeadData.public_init_regulations.map((x) => x)
                                    arr[_index].option[0] = value ? value.toString() : ''
                                    setFormHeadData({
                                      ...formHeadData,
                                      public_init_regulations: arr,
                                    })
                                  }}
                                  value={x.option.length > 0 ? Number(x.option[0]) : 0}
                                />
                              </Form.Item>
                              <Form.Item label="最大值">
                                <InputNumber
                                  size="small"
                                  onChange={(value) => {
                                    let arr = formHeadData.public_init_regulations.map((x) => x)
                                    arr[_index].option[1] = value ? value.toString() : ''
                                    setFormHeadData({
                                      ...formHeadData,
                                      public_init_regulations: arr,
                                    })
                                  }}
                                  value={x.option.length > 0 ? Number(x.option[1]) : 0}
                                />
                              </Form.Item>
                            </Form>
                          </>
                        )}
                        {x.mode === 'recircle' && (
                          <Form layout="inline">
                            <Form.Item label="选项">
                              <Select
                                mode="tags"
                                onChange={(value: string[]) => {
                                  let arr = formHeadData.public_init_regulations.map((x) => x)
                                  arr[_index].option = value
                                  setFormHeadData({
                                    ...formHeadData,
                                    public_init_regulations: arr,
                                  })
                                }}
                                placeholder="输入循环选项"
                                value={x.option}
                                style={{ minWidth: 200 }}
                              ></Select>
                            </Form.Item>
                          </Form>
                        )}
                        {x.mode === 'equal' && (
                          <Form layout="inline">
                            <Form.Item label="初始值">
                              <Input
                                onChange={(e) => {
                                  let arr = formHeadData.public_init_regulations.map((x) => x)
                                  arr[_index].value = e.target.value
                                  setFormHeadData({
                                    ...formHeadData,
                                    public_init_regulations: arr,
                                  })
                                }}
                                placeholder="初始值"
                                value={x.value}
                                size="small"
                              ></Input>
                            </Form.Item>
                          </Form>
                        )}
                      </Form>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#F9F9FB',
              height: '100%',
              padding: 16,
            }}
          >
            <h3>
              <div className="title-prefix"></div>私有规则
            </h3>
            {formHeadData.private_init_regulations.map((x, _index) => {
              return (
                <div
                  key={_index}
                  style={{
                    border: '1px dashed #f39800',
                    width: '100%',
                    marginTop: 20,
                    padding: 20,
                  }}
                >
                  <h3>{'字段' + (Number(x.field_index) + 1) + '：' + x.status_name}</h3>
                  {x.v_asset_comp_regulation.length < 0 ? (
                    <div>无</div>
                  ) : (
                    x.v_asset_comp_regulation.map((y, y_index) => {
                      return (
                        <div key={y_index}>
                          <Divider orientation="left">{y.v_asset_comp}</Divider>
                          <Form>
                            <Form.Item label="变化规则">
                              <Radio.Group
                                options={vcompsRule}
                                value={y.mode}
                                onChange={(e) => {
                                  let arr = formHeadData.private_init_regulations.map((x) => x)

                                  arr[_index].v_asset_comp_regulation[y_index].mode = e.target.value
                                  setFormHeadData({
                                    ...formHeadData,
                                    private_init_regulations: arr,
                                  })
                                }}
                              ></Radio.Group>
                            </Form.Item>
                            {y.mode === 'arithmetic' && (
                              <>
                                <Form layout="inline">
                                  <Form.Item label="公差">
                                    <InputNumber
                                      onChange={(value) => {
                                        let arr = formHeadData.private_init_regulations.map((x) => x)

                                        arr[_index].v_asset_comp_regulation[y_index].value = value
                                          ? value.toString()
                                          : ''
                                        setFormHeadData({
                                          ...formHeadData,
                                          private_init_regulations: arr,
                                        })
                                      }}
                                      value={Number(y.value)}
                                      size="small"
                                    />
                                  </Form.Item>
                                  <Form.Item label="最小值">
                                    <InputNumber
                                      size="small"
                                      onChange={(value) => {
                                        let arr = formHeadData.private_init_regulations.map((x) => x)

                                        arr[_index].v_asset_comp_regulation[y_index].option[0] = value
                                          ? value.toString()
                                          : ''
                                        setFormHeadData({
                                          ...formHeadData,
                                          private_init_regulations: arr,
                                        })
                                      }}
                                      value={y.option.length > 0 ? Number(y.option[0]) : 0}
                                    />
                                  </Form.Item>
                                  <Form.Item label="最大值">
                                    <InputNumber
                                      size="small"
                                      onChange={(value) => {
                                        let arr = formHeadData.private_init_regulations.map((x) => x)

                                        arr[_index].v_asset_comp_regulation[y_index].option[1] = value
                                          ? value.toString()
                                          : ''
                                        setFormHeadData({
                                          ...formHeadData,
                                          private_init_regulations: arr,
                                        })
                                      }}
                                      value={y.option.length > 0 ? Number(y.option[1]) : 0}
                                    />
                                  </Form.Item>
                                </Form>
                              </>
                            )}
                            {y.mode === 'recircle' && (
                              <Form layout="inline">
                                <Form.Item label="选项">
                                  <Select
                                    mode="tags"
                                    onChange={(value: string[]) => {
                                      let arr = formHeadData.private_init_regulations.map((x) => x)

                                      arr[_index].v_asset_comp_regulation[y_index].option = value
                                      setFormHeadData({
                                        ...formHeadData,
                                        private_init_regulations: arr,
                                      })
                                    }}
                                    placeholder="输入循环选项"
                                    value={y.option}
                                    style={{ minWidth: 200 }}
                                  ></Select>
                                </Form.Item>
                              </Form>
                            )}
                            {y.mode === 'equal' && (
                              <Form layout="inline">
                                <Form.Item label="初始值">
                                  <Input
                                    onChange={(e) => {
                                      let arr = formHeadData.private_init_regulations.map((x) => x)

                                      arr[_index].v_asset_comp_regulation[y_index].value = e.target.value
                                      setFormHeadData({
                                        ...formHeadData,
                                        private_init_regulations: arr,
                                      })
                                    }}
                                    placeholder="初始值"
                                    value={y.value}
                                    size="small"
                                  ></Input>
                                </Form.Item>
                              </Form>
                            )}
                          </Form>
                        </div>
                      )
                    })
                  )}
                </div>
              )
            })}
          </div>
        </Col>
      </Row>
      <div style={{ margin: '36px 0 0 0 ', display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => {
            setCurrent(0)
          }}
          type="primary"
          style={{ marginRight: 20, padding: '0 36px' }}
        >
          上一步
        </Button>
        <Button
          onClick={() => {
            setCurrent(2)
          }}
          type="primary"
          style={{ padding: '0 36px' }}
        >
          下一步
        </Button>
      </div>
    </div>
  )
}

export default CreateFormHeadStepTwo
