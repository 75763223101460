import * as React from 'react';
import {
  Form,
  Input,
  Radio,
  FormItemProps,
  Checkbox,
  Image,
  Select,
  InputNumber,
  DatePicker,
  TimePicker,
  Cascader,
} from 'antd';

interface PropsType extends FormItemProps {
  field: Field;
  show: boolean;
}

const CostumFormItem: React.FC<PropsType> = (props: PropsType) => {
  const { field, show } = props;
  const { field_type, required, page_num, field_num, title, subtitle, default_value } = field;

  if (field_type === '单行文字')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Input />
      </Form.Item>
    );
  if (field_type === '多行文字')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Input.TextArea />
      </Form.Item>
    );
  if (field_type === '单项选择')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Radio.Group>
          {field.multiple_choices.map((x, index) => (
            <Radio key={index} value={x.value}>
              {x.value}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  if (field_type === '多项选择')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Checkbox.Group
          options={field.multiple_choices.map((x) => {
            return {
              label: x.value,
              value: x.value,
            };
          })}
          defaultValue={[field.default_value]}
        ></Checkbox.Group>
      </Form.Item>
    );
  if (field_type === '图片单选')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Radio.Group>
          {field.multiple_choices.map((x, index) => (
            <Radio key={index} value={x.value}>
              <Image src={`/v2/api/file/pic/download/${x.value}`} width={58}></Image>
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  if (field_type === '图片多选')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Checkbox.Group>
          {field.multiple_choices.map((x, index) => (
            <Checkbox key="index" value={x.value}>
              <Image src={`/v2/api/file/pic/download/${x.value}`} width={58}></Image>
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    );
  if (field_type === '下拉框')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <Select style={{ width: '100%' }}>
          {field.multiple_choices.map((x, index) => {
            return (
              <Select.Option value={x.value} key={index}>
                {x.value}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  if (field_type === '数字')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    );
  if (field_type === '日期')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
      >
        <DatePicker placeholder="日期" style={{ width: '100%' }} />
      </Form.Item>
    );
  if (field_type === '时间')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
      >
        <TimePicker placeholder="时间" style={{ width: '100%' }} />
      </Form.Item>
    );
  if (field_type === '多级下拉框')
    return (
      <Form.Item
        style={{ display: show ? 'inherit' : 'none' }}
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
      >
        <Cascader
          placeholder="请选择"
          options={field.multi_levels.map((x) => JSON.parse(x))}
          style={{ width: '100%' }}
        />
      </Form.Item>
    );
  if (field_type === '资产')
    return (
      <Form.Item
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    );
  if (field_type === '资产状态')
    return (
      <Form.Item
        name={`${page_num}-${field_num}`}
        label={title}
        rules={[{ required: required ? true : false, message: `请输入${title}` }]}
        extra={subtitle}
        initialValue={default_value}
      >
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    );
  return null;
};

export default CostumFormItem;
