import * as React from 'react';
import {
  Modal,
  Menu,
  Form,
  Image,
  Upload,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  message,
  Radio,
  Space,
} from 'antd';
import { WarningOutlined, DashOutlined } from '@ant-design/icons';

import '../styles/editModal.less';
import { updateLabelProject, getLabelProjectByUid } from '@/requests/labelProject';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  project: LabelProjectListItem | undefined;
  setProject: Function;
  fetchData: Function;
}

const EditProjectModal: React.FC<PropsType> = (props: PropsType) => {
  const { visible, setVisible, project, fetchData, setProject } = props;
  const [menuType, setMenuType] = React.useState('1');
  const [form] = Form.useForm();
  const handleCancel = () => {
    setProject(undefined);
    setVisible(false);
  };
  const handleOK = (values: any) => {
    if (!project) return;
    doUpdate({
      project_name: values['project_name'],
      project_remark: values['project_remark'],
      label_tag_list: values['label_tag_list'].map((x: string) => {
        return {
          key: x,
          value: x,
        };
      }),
      label_type: values['label_type'],
      line_type: values['line_type'],
      project_uid: project.project_uid,
      ...(values.cover &&
        values.cover.file.status === 'done' && {
          project_thumbnail_url: values.cover.file.response.data,
        }),
    });
    console.log(values);
  };

  const refresh = async () => {
    if (!project) return;
    const res = await getLabelProjectByUid(project.project_uid);
    if (res.code === 0) {
      setProject(res.data);
    } else {
      message.error(res.message);
    }
  };

  const handleExit = () => {
    Modal.confirm({
      title: '退出项目',
      content: '一旦你退出了该项目，你将不能查看任何关于该项目的信息。退出项目后，如果想重新加入，请联系项目管理员。',
      okText: '确定退出',
      cancelText: '再想想',
      onOk: () => {},
      onCancel: () => {},
    });
  };

  const doUpdate = async (values: UpdateLabelProjectReq) => {
    if (!project) return;
    console.log(values);
    const res = await updateLabelProject({
      ...values,
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchData();
      refresh();
    } else {
      message.error(res.message);
    }
  };

  if (!project) return null;
  return (
    <Modal
      title={'项目设置'}
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      width={800}
      bodyStyle={{ padding: 0 }}
      destroyOnClose={true}
    >
      <div className={'edit-project-container'}>
        <div style={{ width: 180 }}>
          <Menu
            mode="inline"
            style={{ height: '100%' }}
            activeKey={menuType}
            onChange={(e) => console.log(e)}
            onClick={({ key }) => {
              setMenuType(key as any);
            }}
          >
            <Menu.Item key="1" icon={<WarningOutlined />} style={{ marginBottom: 16 }}>
              概览
            </Menu.Item>
            <Menu.Item key="5" icon={<DashOutlined />} style={{ marginBottom: 16 }}>
              更多
            </Menu.Item>
          </Menu>
        </div>
        <div style={{ width: 620, padding: 16 }}>
          {menuType === '1' && (
            <div>
              <Form form={form} preserve={false} colon={false} layout="vertical" onFinish={handleOK}>
                <Row>
                  <Col span={13}>
                    <Form.Item label="项目封面">
                      <Image
                        src={
                          project.project_thumbnail_url
                            ? `/v2/api/file/pic/download/${project.project_thumbnail_url}`
                            : process.env.PUBLIC_URL + '/img/common/project-cover.png'
                        }
                        width={300}
                        height={150}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label=" " name="cover" extra="最佳图片比例为300*150">
                      <Upload
                        action={`/v2/api/file/pic/upload`}
                        beforeUpload={(file, fileList) => {
                          console.log('file is:', file);

                          const checkSize = file.size / 1024 / 1024 < 1;
                          if (!checkSize) {
                            message.error('图片大小不能大于1MB!');
                            return false;
                          }

                          return checkSize;
                        }}
                      >
                        <Button>上传新封面</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      label="项目名称"
                      name={'project_name'}
                      initialValue={project.project_name}
                      rules={[
                        {
                          required: true,
                          message: '请输入项目名称',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="项目进度(%)" name={'project_schedule'} initialValue={project.project_schedule}>
                      <InputNumber style={{ width: '100%' }} min={0} />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="项目简介"
                  name={'project_remark'}
                  initialValue={project.project_remark}
                  rules={[
                    {
                      required: true,
                      message: '请输入项目简介',
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>

                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      label="标注类型"
                      name={'label_type'}
                      initialValue={project.label_type}
                      rules={[
                        {
                          required: true,
                          message: '请选择标注类型',
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={1}>多线段标记</Radio>
                        <Radio value={2}>多边形标记</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="线段类型"
                      name={'line_type'}
                      initialValue={project.line_type}
                      rules={[
                        {
                          required: true,
                          message: '请选择标注类型',
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={'直线'}>直线</Radio>
                        <Radio value={'曲线'}>曲线</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Form.Item
                    label="标注标签"
                    name={'label_tag_list'}
                    initialValue={project.label_tag_list.map((x) => x.key)}
                    rules={[
                      {
                        required: true,
                        message: '请选择标注类型',
                      },
                    ]}
                  >
                    <Select mode="tags" style={{ width: 590 }} />
                  </Form.Item>
                </Row>

                <Form.Item>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 24 }}>
                    <Button type="primary" htmlType="submit">
                      保存
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}

          {menuType === '5' && (
            <div>
              <Form colon={false} layout="vertical">
                <Form.Item label={'项目操作'}>
                  <div style={{ marginBottom: 12, color: 'rgba(0,0,0,.45)' }}>您可以执行以下操作</div>
                  <Button.Group>
                    <Space>
                      <Button>归档项目</Button>
                      <Button onClick={handleExit}>退出</Button>
                      <Button danger>移至回收站</Button>
                    </Space>
                  </Button.Group>
                </Form.Item>
                <Divider />
              </Form>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditProjectModal;
