import React from "react";
import { Input, Modal, Switch } from "antd";

const noOp = () => undefined;

const LF = "\n";
// const CRLF = '\r\n';
// const LINEBREAK_EXP = /(?:\r\n|\r|\n)/g;
const DEFAULT_DELIMITER = "|";

function semantic(
  origValue: string | number | string[] = "",
  delimiter = DEFAULT_DELIMITER
) {
  let val: string;
  if (Array.isArray(origValue)) val = origValue.join();
  else val = origValue.toString();

  if (!delimiter) return val;

  if (typeof delimiter !== "string")
    throw new Error(
      `[EditableControlPrototype::semantic] delimiter must be string!`
    );

  return val
    .replace(/\r?\n/g, LF)
    .split(delimiter)
    .filter((x) => !!x)
    .join(LF);
}

function pack(semanticValue: string | number | string[] = "", delimiter = "|") {
  let val: string;

  if (!semanticValue) return "";
  if (Array.isArray(semanticValue)) val = semanticValue.join();
  else val = semanticValue.toString();

  if (!delimiter) return val;

  if (typeof delimiter !== "string")
    throw new Error(
      `[EditableControlPrototype::pack] delimiter must be string!`
    );

  return val
    .replace(/\r?\n/g, LF)
    .split(LF)
    .filter((x) => !!x)
    .join(delimiter);
}

type EditableInputElement = HTMLInputElement | HTMLTextAreaElement;

function EditableControlPrototype(
  {
    as: _as = "div",
    children = [],
    useTextArea = false,
    disabled = false,
    usePromotModal: initUsePromotModal = false,
    promotModalProps = {},
    onFinishEditing = noOp,
    inputProps = {},
    ...props
  }: React.PropsWithChildren<{
    children: React.ReactNode;

    as?: string | React.Component;
    disabled?: boolean;

    onFinishEditing?: Function;
    promotModalProps?: any;
    inputProps?: any;
    useTextArea?: boolean;
    usePromotModal?: boolean;
  }> &
    React.HTMLAttributes<any>,
  ref: any
) {
  const { value: initValue = "" } = inputProps;

  const [editing, setEditing] = React.useState(false);

  const [delimiterInModal, setDelimiterInModal] = React.useState("");
  const [value, setValue] = React.useState(
    delimiterInModal ? semantic(initValue, delimiterInModal) : initValue
  );
  const [usePromotModal, setUsePromotModal] = React.useState(
    initUsePromotModal
  );

  const splitedValue = delimiterInModal
    ? semantic(value, delimiterInModal)
    : value;
  const packedValue = pack(value, delimiterInModal || DEFAULT_DELIMITER);

  const finishEditInNonPromotMode = () => {
    if (usePromotModal) return;

    onFinishEditing(packedValue);
    setEditing(false);
  };

  React.useEffect(() => {
    if (delimiterInModal) setValue(splitedValue);
    else setValue(packedValue);
  }, [delimiterInModal]);

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputEl && inputEl.current) inputEl.current.focus();
    },
  }));

  const inputEl: React.MutableRefObject<null | EditableInputElement> = React.useRef(
    null
  );

  React.useLayoutEffect(() => {
    if (!editing) return;
    if (inputEl && inputEl.current) inputEl.current.focus();
  }, [editing]);

  const { onBlur = noOp, onKeyDown = noOp } = inputProps || {};

  if (!editing || disabled) {
    const El = (typeof _as === "function" ? _as : _as) as any;
    return (
      <El
        {...props}
        onClick={(evt: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (evt.shiftKey || evt.altKey) setUsePromotModal(true);
          setEditing(true);
        }}
      >
        {children}
      </El>
    );
  }

  const InputNodeType = usePromotModal || useTextArea ? Input.TextArea : Input;

  const inputNode = (
    <InputNodeType
      ref={inputEl}
      {...inputProps}
      value={value}
      onChange={(evt: React.ChangeEvent<EditableInputElement>) => {
        const newVal = evt.target.value || "";
        setValue(
          delimiterInModal ? semantic(newVal, delimiterInModal) : newVal
        );
      }}
      onBlur={(evt: React.KeyboardEvent<EditableInputElement>) => {
        onBlur(evt);

        finishEditInNonPromotMode();
      }}
      onKeyDown={(evt: React.KeyboardEvent<EditableInputElement>) => {
        onKeyDown(evt);

        if (evt.keyCode !== 13 || evt.shiftKey || evt.altKey) return;

        finishEditInNonPromotMode();
      }}
    />
  );

  const { onOk = noOp, onCancel = noOp } = promotModalProps || {};

  const modelNode = (
    <Modal
      visible={!!editing}
      title="editorModal"
      centered
      {...promotModalProps}
      onOk={(...args) => {
        onFinishEditing(packedValue);
        setEditing(false);

        onOk(...args);
      }}
      onCancel={(...args) => {
        setEditing(false);

        onCancel(...args);
      }}
    >
      {/* <div style={{marginBottom: 8}}>
        <span style={{width: '7em', marginRight: 4}}>使用 {DEFAULT_DELIMITER} 分割:</span>
        <Switch
          checked={!!delimiterInModal}
          onChange={(nextChecked) => {
            setDelimiterInModal(nextChecked ? DEFAULT_DELIMITER : '')
          }}
        />
      </div>

      {delimiterInModal && (
        <div style={{marginBottom: 8}}>
          <span style={{width: '7em', marginRight: 4}}>最终值: </span>
          {packedValue}
        </div>
      )} */}

      <div style={{ marginBottom: 8 }}>
        {/* <span style={{width: '7em', marginRight: 4}}>放置词: </span> */}
        <span>{inputNode}</span>
      </div>
    </Modal>
  );

  if (usePromotModal) return <>{modelNode}</>;

  return <>{inputNode}</>;
}

const EditableControl = React.forwardRef(EditableControlPrototype);

export default EditableControl;
