import * as React from 'react';
import { Input, message, Modal, List, Avatar, Button } from 'antd';
import { updateLabelProject, getLabelProjectByUid } from '@/requests/labelProject';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { getAllUsers } from '@/requests/user';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  project: LabelProjectListItem | undefined;
  setProject?: Function;
  setEditingProject?: Function;
  reload?: Function;
}

interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  is_join: number;
  company_user_id: string;
}

const MemberModal: React.FC<PropsType> = (props) => {
  const { visible, setVisible, project, setProject, setEditingProject, reload } = props;
  const [members, setMembers] = React.useState<Member[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const [existMember, setExistMember] = React.useState<ParticipantUserList[]>([]);

  const fetchMembers = async () => {
    if (!project) return;
    setLoading(true);
    const userRes = await getAllUsers({
      limit: 100,
      skip: 0,
      username: '',
    });
    const _project = await getLabelProjectByUid(project.project_uid);
    const memRes = _project.data.participant_user_list;
    if (userRes.code === 0) {
      setExistMember(memRes);
      setMembers(
        memRes.map((x) => {
          const match = userRes.data.find((y) => y.id === x.id) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            is_join: 1,
            company_user_id: match.company_user_id,
          };
        })
      );
    } else {
      message.error('获取成员列表失败');
    }
    setLoading(false);
  };

  const searchUser = async () => {
    setLoading(true);
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: filterName,
    });
    if (res.code === 0) {
      let arr = res.data.map((user) => {
        const match = existMember.find((x) => x.user_id === user.user_id);
        return {
          user_id: user.id,
          avatar: user.avatar,
          user_name: user.username,
          email: user.email,
          is_join: match ? 1 : 0,
          company_user_id: user.company_user_id,
        };
      });

      setMembers(arr);
    }
    setLoading(false);
  };

  const doAddMember = async (project_id: LabelProjectListItem['project_uid'], user_id: UserInfoItem['id']) => {
    if (!project) return;
    setLoading(true);
    let participant_user_list = project.participant_user_list.map((x) => {
      return { id: x.id };
    });
    participant_user_list.push({ id: user_id });
    console.log(participant_user_list);
    const res = await updateLabelProject({
      project_uid: project_id,
      participant_user_list: participant_user_list,
    });
    if (res.code === 0) {
      message.success('添加成功');
      let arr = members.map((x) => {
        if (x.user_id === user_id) {
          return {
            ...x,
            is_join: 1,
          };
        } else {
          return x;
        }
      });
      setMembers(arr);
      if (setProject) {
        const _res = await getLabelProjectByUid(project.project_uid);
        if (res.code === 0) {
          setProject(_res.data);
          setExistMember(_res.data.participant_user_list);
        }
      }
      if (reload) {
        reload();
      }
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  const doRemoveMemver = async (project_id: LabelProjectListItem['project_uid'], user_id: UserInfoItem['id']) => {
    if (!project) return;
    setLoading(true);
    let participant_user_list = project.participant_user_list.map((x) => {
      return { id: x.id };
    });
    const newArr = participant_user_list.filter((x) => x.id !== user_id);
    const res = await updateLabelProject({
      project_uid: project_id,
      participant_user_list: newArr,
    });
    if (res.code === 0) {
      message.success('移除成功');
      let arr = members.map((x) => {
        if (x.user_id === user_id) {
          return {
            ...x,
            is_join: 0,
          };
        } else {
          return x;
        }
      });
      setMembers(arr);
      if (setProject) {
        const _res = await getLabelProjectByUid(project.project_uid);
        if (res.code === 0) {
          setProject(_res.data);
          setExistMember(_res.data.participant_user_list);
        }
      }
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchMembers();
  }, []);
  // React.useEffect(() => {
  //   if (filterName === '') {
  //     searchUser();
  //   }
  // }, [filterName]);

  const handleCnacel = () => {
    setEditingProject && setEditingProject(undefined);
    setVisible(false);
  };
  if (!project) return null;
  return (
    <Modal
      visible={visible}
      onCancel={handleCnacel}
      footer={null}
      title="邀请新成员"
      bodyStyle={{ padding: 16, height: 450, overflow: 'scroll' }}
      destroyOnClose={true}
    >
      <div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
            <span>账号邀请</span>
            {/* <span>
              <a>通过链接邀请</a>
            </span> */}
          </div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="输入昵称按回车键查找"
            onChange={(e) => setFilterName(e.target.value)}
            onPressEnter={() => {
              searchUser();
            }}
            value={filterName}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <List
            dataSource={members}
            locale={{
              emptyText: '没有搜索到相关成员',
            }}
            loading={loading}
            renderItem={(item) => (
              <List.Item
                extra={
                  item.is_join === 1 ? (
                    <span style={{ color: 'rgba(0,0,0,.45)' }}>
                      <>
                        <UserOutlined style={{ paddingRight: 8 }} />
                        已加入
                      </>
                      <Button
                        style={{ marginLeft: 8 }}
                        type="primary"
                        danger
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            content: '确认移除该成员吗？',
                            okText: '确认',
                            onOk: () => doRemoveMemver(project.project_uid, item.user_id),
                            cancelText: '取消',
                            onCancel: () => {},
                          });
                        }}
                      >
                        移除
                      </Button>
                    </span>
                  ) : (
                    <Button
                      onClick={() => {
                        doAddMember(project.project_uid, item.user_id);
                      }}
                    >
                      邀请
                    </Button>
                  )
                }
              >
                <List.Item.Meta
                  avatar={
                    item.avatar ? (
                      <Avatar src={`v2/api/user/avatar/${item.avatar}`} />
                    ) : (
                      <Avatar>{item.user_name}</Avatar>
                    )
                  }
                  title={`${item.user_name}(工号：${item.company_user_id})`}
                  description={item.email}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MemberModal;
