import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Button, Input, PageHeader, Pagination, message, Result } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import { useCtxState } from '@/App.store'
import { ClearOutlined } from '@ant-design/icons'
import moment from 'moment'
import CostumFormItem from '../components/CostumFormItem'
import { filterRepeat } from '@/utils/array'
import { createNormalForm, getShareTemplateById } from '@/requests/normalForm'
import * as Utils from '@/utils/string'
import { uploadSignaturePic } from '@/requests/form'

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

const NormalFormWritePage = () => {
  const location = useLocation()
  const [tempalte, setTemplate] = React.useState<ShareTemplateItem>()
  const [step, setStep] = React.useState(1)
  const [signature, setSignature] = React.useState<HandSignature>()
  const SignatureCanvasRef = React.useRef(null) as any
  const [{ userInfo }, dispatch] = useCtxState()
  const [pagination, setPagination] = React.useState({ page: 1, total: 0 })
  const [form] = Form.useForm()
  const uploadSignature = async (): Promise<AssetsResourceProto<{ data: string }>> => {
    if (!signature) return Promise.reject()
    const blob = Utils.dataURLtoFile(signature.hand_signature_pic_uid, `${Utils.uuid()}.png`)
    const res = await uploadSignaturePic({ file: blob })
    if (res.code === 0) {
      return Promise.resolve(res)
    } else {
      message.error(res.message)
      return Promise.resolve(res)
    }
  }
  const history = useHistory()

  React.useEffect(() => {
    const state: any = location.state
    if (!state) {
      fetchData()
    } else {
      if (state.template) {
        setTemplate(state.template)
        setPagination({
          ...pagination,
          total: filterRepeat(state.template.fields.map((x: Field) => x.page_num)).sort((a, b) => b - a)[0],
        })
      }
    }
  }, [])

  const fetchData = async () => {
    let uid = ''
    let version_number = 0
    uid = location.search.split('&')[0].split('=')[1]

    version_number = Number(location.search.split('&')[1].split('=')[1])
    const res = await getShareTemplateById(uid)
    if (res.code === 0) {
      setTemplate(res.data)
    } else {
      message.error(res.code)
    }
  }

  const doSubmit = async (values: any) => {
    if (!tempalte) return
    const uploadSignatureRes = await uploadSignature()
    if (uploadSignatureRes.code === 0) {
      const res = await createNormalForm({
        normal_form_template_share_id: tempalte.id,
        version_name: tempalte.version_name,
        hand_signatures: signature
          ? [
              {
                ...signature,
                hand_signature_pic_uid: uploadSignatureRes.data,
              },
            ]
          : [],
        version_number: tempalte.version_number,
        fields: tempalte.fields.map((field, index) => {
          let field_value = ''
          let multiple_choice_value = []
          if (
            field.field_type === '单行文字' ||
            field.field_type === '多行文字' ||
            field.field_type === '单项选择' ||
            field.field_type === '图片单选' ||
            field.field_type === '下拉框' ||
            field.field_type === '数字' ||
            field.field_type === '资产' ||
            field.field_type === '资产状态'
          ) {
            field_value = values[`${field.page_num}-${field.field_num}`]
          }
          if (field.field_type === '时间') {
            field_value = moment(values[`${field.page_num}-${field.field_num}`]).format('HH:mm:ss')
          }
          if (field.field_type === '多级下拉框') {
            field_value = values[`${field.page_num}-${field.field_num}`].toString()
          }
          if (field.field_type === '日期') {
            field_value = moment(values[`${field.page_num}-${field.field_num}`]).format('YYYY-MM-DD')
          }
          if (field.field_type === '多项选择') {
            multiple_choice_value = values[`${field.page_num}-${field.field_num}`].map((x: any) => {
              return {
                value: x,
                type: 0,
                comment: '',
                pic_num: 0,
              }
            })
          }
          if (field.field_type === '图片多选') {
            multiple_choice_value = values[`${field.page_num}-${field.field_num}`].map((x: any, index: any) => {
              return {
                value: x,
                type: 1,
                comment: '',
                pic_num: index,
              }
            })
          }
          return {
            template_field_num: field.field_num,
            template_page_num: field.page_num,
            fill_num: index + 1,
            field_type: field.field_type,
            field_value: field_value,
            multiple_choices: field.multiple_choices,
            multiple_choice_value,
            multi_level_value: [],
            asset: null,
            addr_status: null,
            asset_status: null,
          }
        }),
      })
      if (res.code === 0) {
        message.success('提交成功!')
        setStep(3)
      } else {
      }
    } else {
      message.error(uploadSignatureRes.message)
    }
  }
  return tempalte ? (
    <div style={{ backgroundColor: '#ffffff', padding: 16 }}>
      <PageHeader
        title={step === 1 ? '签名' : '填写表单'}
        extra={[
          step === 2 && (
            <Button type="primary" onClick={() => form.submit()}>
              提交
            </Button>
          ),
          <Button onClick={() => history.goBack()}>退出</Button>,
        ]}
      ></PageHeader>
      {step === 1 && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Form style={{ width: 600 }} {...formItemLayout}>
            <Form.Item label="填写人">
              <Input value={userInfo.username} disabled />
            </Form.Item>
            <Form.Item label="填写人ID">
              <Input value={userInfo.user_id} disabled />
            </Form.Item>
            <Form.Item label="填写人签名">
              <SignatureCanvas
                penColor="blue"
                canvasProps={{
                  width: 400,
                  height: 200,
                  className: 'sigCanvas',
                }}
                backgroundColor="#f0f0f0"
                onEnd={(e) => {
                  setSignature({
                    user_id: userInfo.user_id,
                    hand_signature_pic_uid: SignatureCanvasRef.current.toDataURL(),
                  })
                }}
                ref={SignatureCanvasRef}
              />
              <Button
                style={{ position: 'absolute', right: 0 }}
                onClick={() => SignatureCanvasRef.current.clear()}
                icon={<ClearOutlined />}
              >
                清除
              </Button>
            </Form.Item>
            <Form.Item label="填写日期">{moment().format('YYYY-MM-DD')}</Form.Item>
            <Form.Item label=" " colon={false}>
              <Button
                type="primary"
                onClick={() => {
                  setStep(2)
                }}
                disabled={signature ? false : true}
                style={{ padding: '0 36px' }}
              >
                下一步
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      {step === 2 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

              justifyContent: 'center',
              border: '1px solid #1890ff',
              width: 500,
              padding: 32,
            }}
          >
            <Form
              layout="vertical"
              form={form}
              onFinish={(values) => {
                console.log(values)
                doSubmit(values)
              }}
            >
              {tempalte.fields
                .sort((a, b) => a.field_num - b.field_num)
                .map((field, index) => {
                  return (
                    <CostumFormItem
                      field={field}
                      key={index}
                      show={field.page_num === pagination.page ? true : false}
                    />
                  )
                })}
            </Form>
            <Pagination
              style={{ margin: '0 auto' }}
              current={pagination.page}
              total={pagination.total}
              onChange={(page) => {
                setPagination({
                  ...pagination,
                  page,
                  total: pagination.total,
                })
              }}
              pageSize={1}
              showTotal={(total) => <span>共{total}页</span>}
            ></Pagination>
          </div>
        </div>
      )}
      {step === 3 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Result
            status="success"
            title="提交成功"
            subTitle=""
            extra={[
              <Button type="primary" key="console" onClick={() => history.goBack()}>
                确认
              </Button>,
              <Button key="buy" onClick={() => history.goBack()}>
                返回
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  ) : null
}

export default NormalFormWritePage
