/// <reference path="type.d.ts" />
/// <reference path="normalForm.d.ts" />

import request from './_base';

/**
 * 创建个人模板
 * @param  {CreatePersonalTemplateReq} input
 */
export function createPersonalTemplate(
  input: CreatePersonalTemplateReq
): Promise<AssetsResourceProto<{ data: PersonalTemplateItem }>> {
  return request({
    method: 'POST',
    url: `/normal-form-template/new`,
    data: input,
  }) as any;
}

/**
 * 更新个人模板
 * @param  {PersonalTemplateItem} input
 */
export function updatePersonalTemplate(
  input: UpdatePersonalTemplateReq
): Promise<AssetsResourceProto<{ data: PersonalTemplateItem }>> {
  return request({
    method: 'PUT',
    url: `/normal-form-template/update`,
    data: input,
  }) as any;
}

/**
 * 删除个人模板
 * @param  {DeletePersonalTemplateReq} input
 */
export function deletePersonalTemplate(input: DeletePersonalTemplateReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/normal-form-template/del`,
    data: input,
  }) as any;
}

/**
 * 查询个人模板list
 * @param  {GetPersonalTemplateListReq} input
 */
export function getPersonalTemplateList(
  input: GetPersonalTemplateListReq
): Promise<AssetsResourceProto<{ data: PersonalTemplateItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/normal-form-template/list`,
    data: input,
  }) as any;
}

/**
 * 个人模板name唯一性校验
 * @param  {CheckNameUniqueReq} input
 */
export function checkNameUnique(input: CheckNameUniqueReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'POST',
    url: `/normal-form-template/name/unique`,
    data: input,
  }) as any;
}

/**
 * 分享个人模板
 * @param  {DeletePersonalTemplateReq}} input
 */
export function sharePersonalTemplate(input: DeletePersonalTemplateReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'POST',
    url: `/normal-form-template/share`,
    data: input,
  }) as any;
}

/**
 * 查看分享模板的list
 * @param  {BaseListReq} input
 */
export function getShareTemplateList(
  input: GetShareNormalTemplateListReq
): Promise<AssetsResourceProto<{ data: ShareTemplateItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/normal-form-template-share/list`,
    data: input,
  }) as any;
}

/**
 * 根据分享模板的id获取分享模板
 * @param  {string} id
 */
export function getShareTemplateById(id: string): Promise<AssetsResourceProto<{ data: ShareTemplateItem }>> {
  return request({
    method: 'GET',
    url: `/normal-form-template-share/${id}`,
  }) as any;
}

/**
 * 删除分享模板
 * @param  {DeletePersonalTemplateReq} input
 */
export function deleteShareTemplate(input: DeletePersonalTemplateReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/normal-form-template-share/delete`,
    data: input,
  }) as any;
}

/**
 * 创建一般表单
 * @param  {CreateNormalFormReq} input
 */
export function createNormalForm(input: CreateNormalFormReq): Promise<AssetsResourceProto<{ data: NormalFormItem }>> {
  return request({
    method: 'POST',
    url: `/normal-form-data/new`,
    data: input,
  }) as any;
}

/**
 * 更新一般表单
 * @param  {NormalFormItem} input
 */
export function updateNormalForm(input: NormalFormItem): Promise<AssetsResourceProto<{ data: NormalFormItem }>> {
  return request({
    method: 'PUT',
    url: `/normal-form-data/update`,
    data: input,
  }) as any;
}

/**
 * 删除一般表单
 * @param  {HandleNormalFormReq} input
 */
export function deleteNormalForm(input: HandleNormalFormReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/normal-form-data/delete`,
    data: input,
  }) as any;
}

/**
 * 删除模板中多选项的图片
 * @param  {string} uid
 */
export function deleteNormalFormMultipleChoicePic(uid: string): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/pic/normal-form-template/multiple-choice/delete/${uid}`,
  }) as any;
}

/**
 * 查看表单数据list
 * @param  {GetNormalFormDataListReq} input
 */
export function getNormalFormData(
  input: GetNormalFormDataListReq
): Promise<AssetsResourceProto<{ data: NormalFormItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/normal-form-data/list`,
    data: input,
  }) as any;
}

/**
 * 关闭填写的普通表单数据,关闭后,无法继续填写,只有创建者可以关闭
 * @param  {HandleNormalFormReq} input
 */
export function closeNormalForm(input: HandleNormalFormReq): Promise<AssetsResourceProto<{ data: null[] }>> {
  return request({
    method: 'POST',
    url: `/normal-form-data/close`,
    data: input,
  }) as any;
}

/**
 * 获取表单填写数据
 * @param  {GetNormalFormStatisticsDataReq} input
 */
export function getNormalFormStatisticsData(
  input: GetNormalFormStatisticsDataReq
): Promise<AssetsResourceProto<{ data: NormalFormStatisticsDataItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/form/statistics/normal`,
    data: input,
  }) as any;
}
