import * as React from 'react';
import { trainModel, getModelTrainTaskList } from '@/requests/AIDetection';
import { getTasksByCondition, getAllLabelProjectList } from '@/requests/labelProject';
import {
  Divider,
  PageHeader,
  Table,
  Button,
  Form,
  Input,
  InputNumber,
  Slider,
  Modal,
  Select,
  message,
  Avatar,
  Result,
  DatePicker,
  List,
  Tag,
} from 'antd';
import { PlusOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useCacheRoute } from '@/ui-layouts/header';
import { getAllUsers } from '@/requests/user';
import moment from 'moment';

interface TrainProjectItem {
  project_info: {
    project_name: string;
  };
  creator: {
    username: string;
    user_id: number;
    avatar: string;
  }[];
  label_person: {
    username: string;
    user_id: number;
    avatar: string;
  }[];
  label_status: number;
  img_name: string;
  create_time: any;
  label_time: any;
  task_uids: string[];
}
interface AddProjectModalProps {
  visible: boolean;
  setVisible: Function;
  projects: TrainProjectItem[];
  setProjects: Function;
}
enum LabelStatus {
  '全部' = 0,
  '已完成' = 1,
  '未完成' = 2,
}

const AddProjectModal = (props: AddProjectModalProps) => {
  const { visible, setVisible, projects, setProjects } = props;
  const [projectOptions, setProjectOptions] = React.useState<LabelProjectListItem[]>([]);
  const [filterProject, setFilterProject] = React.useState<string>('');
  const [userOptions, setUserOptions] = React.useState<UserInfoItem[]>([]);
  const [filterCreator, setFilterCreator] = React.useState<number[]>([]);
  const [filterParter, setFilterParter] = React.useState<number[]>([]);
  const [filterStatus, setFilterStatus] = React.useState(0);
  const [filterCreateTime, setFilterCreateTime] = React.useState<any>();
  const [filterLabelTime, setFilterLabelTime] = React.useState<any>();
  const [filterName, setFilterName] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [taskUids, setTaskUids] = React.useState<string[]>([]);

  const doSearch = async () => {
    setFetching(true);
    const res = await getTasksByCondition({
      limit: 10000,
      skip: 0,
      ...(filterProject && {
        project_uid: filterProject,
      }),
      ...(filterCreator.length > 0 && {
        create_user_ids: filterCreator,
      }),
      ...(filterParter.length > 0 && {
        principal_user_ids: filterParter,
      }),
      ...(filterStatus !== 0 && {
        label: filterStatus === 1 ? true : false,
      }),
      ...(filterCreateTime && {
        task_create_time_start: moment(filterCreateTime[0]).format('YYYY-MM-DD hh:mm:ss'),
        task_create_time_end: moment(filterCreateTime[1]).format('YYYY-MM-DD hh:mm:ss'),
      }),
      ...(filterLabelTime && {
        task_start_time: moment(filterLabelTime[0]).format('YYYY-MM-DD hh:mm:ss'),
        task_end_time: moment(filterLabelTime[1]).format('YYYY-MM-DD hh:mm:ss'),
      }),
      ...(filterName && {
        image_name: filterName,
      }),
    });

    if (res.code === 0) {
      if (res.data.length === 0) {
        message.error('未搜索到任务');
      }
      setTaskUids(res.data.map((x) => x.task_uid));
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = () => {
    if (taskUids.length === 0) {
      message.error('当前未选择任务');
      return;
    }
    let _projects = projects.map((x) => x);
    let _project = projectOptions.find((x) => x.project_uid === filterProject);
    let _creator = filterCreator.map((x) => {
      const _user = userOptions.find((y) => y.id === x);
      if (_user) {
        return {
          username: _user.username,
          user_id: _user.id,
          avatar: _user.avatar,
        };
      } else {
        return {} as any;
      }
    });
    let _label_person = filterParter.map((x) => {
      const _user = userOptions.find((y) => y.id === x);
      if (_user) {
        return {
          username: _user.username,
          user_id: _user.id,
          avatar: _user.avatar,
        };
      } else {
        return {} as any;
      }
    });
    _projects.push({
      project_info: {
        project_name: _project ? _project.project_name : '',
      },
      creator: _creator,
      label_person: _label_person,
      label_status: filterStatus,
      img_name: filterName,
      create_time: filterCreateTime,
      label_time: filterLabelTime,
      task_uids: taskUids,
    });
    setProjects(_projects);
    message.success('添加成功');
    setVisible(false);
  };

  return (
    <Modal
      title={'新增标注'}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleOk}
      maskClosable={false}
      okText={'保存'}
      okButtonProps={{ disabled: taskUids.length === 0 }}
    >
      <Form colon={true} layout="vertical">
        <Form.Item className="filter-item" label="项目">
          <Select
            placeholder="全部"
            allowClear
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await getAllLabelProjectList({ limit: 10000, skip: 0 });
                if (res.code === 0) {
                  setProjectOptions(res.data);
                } else {
                  message.error('网络错误');
                }
              }
            }}
            onChange={(value: string) => {
              setFilterProject(value);
            }}
          >
            {projectOptions.map((project) => {
              return (
                <Select.Option value={project.project_uid} key={project.project_uid}>
                  {project.project_name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item className="filter-item" label="创建人">
          <Select
            mode="multiple"
            placeholder="全部"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await getAllUsers({
                  limit: 1000,
                  skip: 0,
                  username: '',
                });
                if (res.code === 0) {
                  setUserOptions(res.data);
                } else {
                  message.error('网络错误');
                }
              }
            }}
            onChange={(values: number[]) => {
              setFilterCreator(values);
            }}
          >
            {userOptions.map((x) => {
              return (
                <Select.Option value={x.id} key={x.user_id}>
                  <span>
                    {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.username}</Avatar>}{' '}
                    <span style={{ paddingLeft: 8 }}>{x.username}</span>
                  </span>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item className="filter-item" label="标注人">
          <Select
            placeholder="全部"
            mode="multiple"
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await getAllUsers({
                  limit: 1000,
                  skip: 0,
                  username: '',
                });
                if (res.code === 0) {
                  setUserOptions(res.data);
                } else {
                  message.error('网络错误');
                }
              }
            }}
            onChange={(values: number[]) => {
              setFilterParter(values);
            }}
          >
            {userOptions.map((x) => {
              return (
                <Select.Option value={x.id} key={x.user_id}>
                  <span>
                    {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.username}</Avatar>}{' '}
                    <span style={{ paddingLeft: 8 }}>{x.username}</span>
                  </span>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item className="filter-item" label="状态">
          <Select onChange={(value: number) => setFilterStatus(value)} placeholder="全部">
            <Select.Option value={0}>全部</Select.Option>
            <Select.Option value={1}>已完成</Select.Option>
            <Select.Option value={2}>未完成</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className="filter-item" label="图片名">
          <Input onChange={(e) => setFilterName(e.target.value)} placeholder="全部"></Input>
        </Form.Item>
        <Form.Item className="filter-item" label="创建时间">
          <DatePicker.RangePicker onChange={(e) => setFilterCreateTime(e)}></DatePicker.RangePicker>
        </Form.Item>

        <Form.Item className="filter-item" label="标注时间">
          <DatePicker.RangePicker onChange={(e) => setFilterLabelTime(e)}></DatePicker.RangePicker>
        </Form.Item>
      </Form>
      <Divider>搜索结果</Divider>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          当前任务数量：<h2 style={{ display: 'inline-block' }}>{taskUids.length}</h2>
        </span>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          loading={fetching}
          onClick={() => doSearch()}
          style={{ borderRadius: 5 }}
        >
          搜索
        </Button>
      </div>
    </Modal>
  );
};

const ModelTrainTaskModel = (props: { tasks: ModelTrainTaskListItem[]; visible: boolean; setVisible: Function }) => {
  const { tasks, visible, setVisible } = props;
  const getStatusTag = (status: string) => {
    switch (status) {
      case '训练中':
        return <Tag color="processing">训练中</Tag>;
      case '训练完成':
        return <Tag color="success">训练完成</Tag>;
      case '训练失败':
        return <Tag color="error">训练失败</Tag>;
      case '未开始':
        return <Tag color="yellow">未开始</Tag>;
      default:
        return <Tag color="default">未知</Tag>;
    }
  };
  return (
    <Modal visible={visible} onCancel={() => setVisible(false)} footer={null} width={800} title={'模型训练记录'}>
      <div>
        <List
          dataSource={tasks}
          pagination={{
            pageSize: 5,
          }}
          renderItem={(item, index) => {
            return (
              <List.Item extra={[getStatusTag(item.model_status)]}>
                <List.Item.Meta
                  title={item.custom_model_name ? item.custom_model_name : '未知'}
                  description={
                    <div>
                      <div>
                        <span style={{ marginRight: 16 }}>
                          thickness：<span style={{ color: '#646464' }}>{item.thickness}</span>
                        </span>
                        <span style={{ marginRight: 16 }}>
                          测试集比例：<span style={{ color: '#646464' }}>{item.test_frac}</span>
                        </span>
                        <span>
                          迭代次数：<span style={{ color: '#646464' }}>{item.iterations}</span>
                        </span>
                      </div>
                      <div>
                        <span style={{ marginRight: 16 }}>
                          任务创建者：<span style={{ color: '#646464' }}>{item.create_user_name}</span>
                        </span>
                        <span style={{ marginRight: 16 }}>
                          训练任务时间：<span style={{ color: '#646464' }}>{item.create_time}</span>
                        </span>
                      </div>
                    </div>
                  }
                ></List.Item.Meta>
              </List.Item>
            );
          }}
        ></List>
      </div>
    </Modal>
  );
};

const ModelTraining = () => {
  const [trainProjectList, setTrainProjectList] = React.useState<TrainProjectItem[]>([]);
  const [thickness, setThickness] = React.useState<number>(3);
  const [testFrac, setTestFrac] = React.useState<number>(0.2);
  const [iterations, setIterations] = React.useState<number>(500);
  const [name, setname] = React.useState<string>('');
  const [remark, setremark] = React.useState<string>('');
  const [visible, setVisible] = React.useState<boolean>(false);
  const [done, setDone] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modelTrainTaskList, setModelTrainTaskList] = React.useState<ModelTrainTaskListItem[]>([]);
  const [modelTrainTaskVisible, setModelTrainTaskVisible] = React.useState(false);

  const { closeWindow } = useCacheRoute();
  //获取模型训练记录数量
  const fetchModelTrainTaskList = async () => {
    const res = await getModelTrainTaskList({
      limit: 10000,
      skip: 0,
    });
    if (res.code === 0) {
      setModelTrainTaskList(res.data);
    }
  };
  React.useEffect(() => {
    fetchModelTrainTaskList();
  }, []);

  const doTrain = async () => {
    if (trainProjectList.length === 0) {
      message.error('请选择标注项目');
      return;
    }
    setLoading(true);
    const res = await trainModel({
      thickness,
      test_frac: testFrac,
      iterations,
      remark,
      custom_model_name: name,
      label_task_uid_list: trainProjectList.map((x) => x.task_uids).flat(),
    });
    if (res.code === 0) {
      setDone(true);
      message.success('训练成功');
      fetchModelTrainTaskList();
    } else {
      message.error('训练失败');
    }
    setLoading(false);
  };

  return (
    <div>
      <PageHeader
        title={'模型训练'}
        style={{ backgroundColor: '#ffffff', marginBottom: 16 }}
        extra={[
          <Button
            type="primary"
            style={{ borderRadius: 5 }}
            onClick={() => {
              setModelTrainTaskVisible(true);
            }}
          >
            模型训练管理：{modelTrainTaskList.length}
          </Button>,
        ]}
      />
      {done ? (
        <div style={{ backgroundColor: '#ffffff', padding: '16px 0' }}>
          <Result
            status="success"
            title="模型训练成功"
            subTitle="请前往模型训练管理中查看训练结果"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setModelTrainTaskVisible(true);
                }}
              >
                立即前往
              </Button>,
              <Button
                key="console"
                onClick={() => {
                  closeWindow();
                }}
              >
                关闭
              </Button>,
            ]}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', padding: 16, backgroundColor: '#ffffff' }}>
          <div
            style={{
              width: '75%',
              minHeight: 600,
              maxHeight: 700,
              overflow: 'scroll',
              borderRight: '1px solid #F39800',
            }}
          >
            <Divider>标注项目选择</Divider>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
              <span></span>
              <Button
                icon={<PlusOutlined />}
                style={{ borderRadius: 5 }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                添加标注项目
              </Button>
            </div>
            <div>
              <Table
                dataSource={trainProjectList}
                pagination={{
                  pageSize: 6,
                  size: 'small',
                }}
                columns={[
                  {
                    title: '序号',
                    key: 'index',
                    render: (text: any, record: TrainProjectItem, index) => {
                      return <span>{index + 1}</span>;
                    },
                  },
                  {
                    title: '项目名称',
                    key: 'project_name',
                    render: (text: any, record: TrainProjectItem) => {
                      let _name = record.project_info.project_name;
                      return <span>{_name ? _name : '全部'}</span>;
                    },
                  },
                  {
                    title: '创建人',
                    key: 'creator',
                    render: (text: any, record: TrainProjectItem) => {
                      return (
                        <span>
                          {record.creator.length > 0
                            ? record.creator.map((x) => (
                                <div key={x.user_id} style={{ marginBottom: 4 }}>
                                  <span>
                                    {x.avatar ? (
                                      <Avatar src={`v2/api/user/avatar/${x.avatar}`} size="small" />
                                    ) : (
                                      <Avatar size="small">{x.username}</Avatar>
                                    )}{' '}
                                    <span style={{ paddingLeft: 8 }}>{x.username}</span>
                                  </span>
                                </div>
                              ))
                            : '全部'}
                        </span>
                      );
                    },
                  },
                  {
                    title: '标注人',
                    key: 'label_person',
                    render: (text: any, record: TrainProjectItem) => {
                      return (
                        <span>
                          {record.label_person.length > 0
                            ? record.label_person.map((x) => {
                                return (
                                  <div key={x.user_id} style={{ marginBottom: 4 }}>
                                    <span>
                                      {x.avatar ? (
                                        <Avatar src={`v2/api/user/avatar/${x.avatar}`} size="small" />
                                      ) : (
                                        <Avatar size="small">{x.username}</Avatar>
                                      )}{' '}
                                      <span style={{ paddingLeft: 8 }}>{x.username}</span>
                                    </span>
                                  </div>
                                );
                              })
                            : '全部'}
                        </span>
                      );
                    },
                  },
                  {
                    title: '标注状态',
                    key: 'label_status',
                    render: (text: any, record: TrainProjectItem) => {
                      return <span>{LabelStatus[record.label_status]}</span>;
                    },
                  },
                  {
                    title: '图片名',
                    key: 'img_name',
                    render: (text: any, record: TrainProjectItem) => {
                      let _name = record.img_name;
                      return <span>{_name ? _name : '全部'}</span>;
                    },
                  },
                  {
                    title: '创建时间',
                    key: 'create_time',
                    render: (text: any, record: TrainProjectItem) => {
                      let _time = record.create_time;
                      return (
                        <span>
                          {_time
                            ? `${moment(_time[0]).format('YYYY年MM月DD日')} - ${moment(_time[1]).format(
                                'YYYY年MM月DD日'
                              )}`
                            : '全部'}
                        </span>
                      );
                    },
                  },
                  {
                    title: '标注时间',
                    key: 'label_time',
                    render: (text: any, record: TrainProjectItem) => {
                      let _time = record.label_time;
                      return (
                        <span>
                          {_time
                            ? `${moment(_time[0]).format('YYYY年MM月DD日')} - ${moment(_time[1]).format(
                                'YYYY年MM月DD日'
                              )}`
                            : '全部'}
                        </span>
                      );
                    },
                  },
                  {
                    title: '任务数量',
                    key: 'task_uids',
                    render: (text: any, record: TrainProjectItem) => {
                      return <span>{record.task_uids.length}</span>;
                    },
                  },
                  {
                    title: '操作',
                    key: 'actions',
                    render: (text: any, record: TrainProjectItem, index) => {
                      return (
                        <span>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              // 从trainProjectList中删除该项并用Modal.confirm确认
                              Modal.confirm({
                                title: '提示？',
                                icon: <ExclamationCircleOutlined />,
                                content: '确认删除该项？',
                                okText: '确认',
                                cancelText: '取消',
                                onOk: () => {
                                  let _trainProjectList = trainProjectList.map((x) => x);
                                  _trainProjectList.splice(index, 1);
                                  setTrainProjectList(_trainProjectList);
                                },
                              });
                            }}
                          >
                            删除
                          </a>
                        </span>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>

          <div style={{ width: '25%', padding: '0 16px 16px 16px' }}>
            <Divider>参数设置</Divider>
            <div>
              <Form layout="vertical" colon>
                <Form.Item label={'名称：'}>
                  <Input value={name} onChange={(e) => setname(e.target.value)} placeholder="请输入模型名称" />
                </Form.Item>
                <Form.Item label={'备注：'}>
                  <Input.TextArea value={remark} onChange={(e) => setremark(e.target.value)} placeholder={'备注'} />
                </Form.Item>
                <Form.Item label={'thickness：'}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Slider
                      min={0}
                      max={100}
                      onChange={(value: any) => {
                        setThickness(value);
                      }}
                      value={thickness}
                      style={{ width: '70%' }}
                    />

                    <InputNumber value={thickness} onChange={(value) => setThickness(value)} />
                  </div>
                </Form.Item>
                <Form.Item label={'测试集比例：'}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Slider
                      min={0}
                      max={1}
                      onChange={(value: any) => {
                        setTestFrac(value);
                      }}
                      step={0.01}
                      value={testFrac}
                      style={{ width: '70%' }}
                    />

                    <InputNumber value={testFrac} onChange={(value) => setTestFrac(value)} />
                  </div>
                </Form.Item>
                <Form.Item label={'迭代次数：'}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Slider
                      min={0}
                      max={1000}
                      onChange={(value: any) => {
                        setIterations(value);
                      }}
                      value={iterations}
                      style={{ width: '70%' }}
                    />

                    <InputNumber value={iterations} onChange={(value) => setIterations(value)} />
                  </div>
                </Form.Item>
              </Form>
              <Button
                type="primary"
                style={{ borderRadius: 5, width: '100%' }}
                loading={loading}
                onClick={() => {
                  Modal.confirm({
                    title: '提示？',
                    icon: <ExclamationCircleOutlined />,
                    content: '确认开始训练？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                      doTrain();
                    },
                  });
                }}
              >
                开始训练
              </Button>
            </div>
          </div>
        </div>
      )}

      {visible && (
        <AddProjectModal
          visible={visible}
          setVisible={setVisible}
          setProjects={setTrainProjectList}
          projects={trainProjectList}
        />
      )}
      {modelTrainTaskVisible && (
        <ModelTrainTaskModel
          visible={modelTrainTaskVisible}
          setVisible={setModelTrainTaskVisible}
          tasks={modelTrainTaskList}
        />
      )}
    </div>
  );
};

export default ModelTraining;
