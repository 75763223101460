import * as React from 'react'
import { Tree, Form, Tooltip } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined, EditOutlined } from '@ant-design/icons'
import EditableControl from '@/ui-components/EditableControl'

interface Props {
  onChange: (value: NodeItem[]) => void
  value?: NodeItem[]
}

export interface NodeItem {
  title: string
  key: string | number
  children: NodeItem[]
}

const EditableTree: React.FC<Props> = (props: Props) => {
  const { onChange, value } = props
  const [treeData, setTreeData] = React.useState<NodeItem[]>([
    {
      title: '选项1',
      key: '1',
      children: [
        {
          title: '选项1-1',
          key: '1-1',
          children: [],
        },
      ],
    },
    {
      title: '选项2',
      key: '2',
      children: [
        {
          title: '选项2-1',
          key: '2-1',
          children: [],
        },
        {
          title: '选项2-2',
          key: '2-2',
          children: [],
        },
      ],
    },
  ])

  React.useEffect(() => {
    if (value) {
      setTreeData(value)
    }
  }, [])

  React.useEffect(() => {
    triggerChange(treeData)
  }, [treeData])
  const triggerChange = (changedValue: NodeItem[]) => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  const editNode = (title: NodeItem['title'], key: NodeItem['key'], data: NodeItem[]) => {
    data.forEach((item) => {
      if (item.key === key) {
        item.title = title
        return
      }
      if (item.children.length > 0) {
        editNode(title, key, item.children)
      }
    })
  }

  const addNode = (key: NodeItem['key'], data: NodeItem[]) => {
    data.forEach((item) => {
      if (item.key === key) {
        item.children.push({
          title: `${item.title}的子选项${item.children.length + 1}`,
          key: `${item.key}-${item.children.length + 1}`,
          children: [],
        })
        return
      }
      if (item.children.length > 0) {
        addNode(key, item.children)
      }
    })
  }

  const deleteNode = (key: NodeItem['key'], data: NodeItem[]) => {
    data.forEach((item, index) => {
      if (item.key === key) {
        data.splice(index, 1)
        return
      }
      if (item.children) {
        deleteNode(key, item.children)
      }
    })
  }

  return (
    <Form.Item
      label="选项"
      extra={
        <Tooltip title="添加子选项">
          <PlusCircleOutlined
            style={{
              cursor: 'pointer',
              fontSize: 15,
              padding: 5,
            }}
            onClick={() => {
              let arr = treeData.map((x) => x)
              arr.push({
                title: `选项${treeData.length + 1}`,
                key: `${treeData.length + 1}`,
                children: [],
              })
              setTreeData(arr)
            }}
          />
        </Tooltip>
      }
    >
      <Tree
        treeData={treeData}
        selectable={false}
        titleRender={(node) => {
          return (
            <span>
              <span>
                <EditableControl
                  style={{ cursor: 'pointer', display: 'inline' }}
                  inputProps={{
                    value: node.title,
                    placeholder: '编辑选项名称',
                    style: { width: 160, fontSize: 14 },
                  }}
                  onFinishEditing={async (newVal: string) => {
                    if (newVal === node.title) return
                    let data = treeData.map((x) => x)
                    editNode(newVal, node.key, data)
                    setTreeData(data)
                  }}
                >
                  <span>{node.title}</span>
                  <span>
                    <Tooltip title="编辑选项名">
                      <EditOutlined
                        style={{
                          cursor: 'pointer',
                          fontSize: 15,
                          padding: 5,
                        }}
                        onClick={() => {
                          console.log('hahahahhahah')
                        }}
                      />
                    </Tooltip>
                  </span>
                </EditableControl>
              </span>
              <span>
                <Tooltip title="添加子选项">
                  <PlusCircleOutlined
                    style={{
                      cursor: 'pointer',
                      fontSize: 15,
                      padding: 5,
                    }}
                    onClick={() => {
                      let data = treeData.map((x) => x)
                      addNode(node.key, data)
                      setTreeData(data)
                    }}
                  />
                </Tooltip>
                <Tooltip title="删除">
                  <MinusCircleOutlined
                    style={{
                      cursor: 'pointer',
                      fontSize: 15,
                      padding: 5,
                    }}
                    onClick={() => {
                      let data = treeData.map((x) => x)
                      deleteNode(node.key, data)
                      setTreeData(data)
                    }}
                  />
                </Tooltip>
              </span>
            </span>
          )
        }}
      />
    </Form.Item>
  )
}

export default EditableTree
