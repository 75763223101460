import React from 'react'
import { Modal, Input, Form, Select, message, InputNumber, Radio, Button } from 'antd'
import * as authRequests from '@/requests/role'

import { formItemLayout } from '../configs'
interface FormValues {
  name: string
  url: string
  comment: string
  request_type: string
  order_num: number
  menu_type: string
  // icon: string
  visible: string
}

interface PropsType {
  visible: boolean
  setVisible: Function
  fetchData: Function
  authItem: SysMenuItem | undefined
}

const UpdateAuthTable = (props: PropsType) => {
  const { visible, setVisible, fetchData, authItem } = props
  const [fetching, setFetching] = React.useState(false)
  const [form] = Form.useForm()

  const handleOk = async (values: FormValues) => {
    if (!authItem) return
    doUpdate({
      name: values['name'],
      id: authItem.id,
      parent_id: authItem.parent_id,
      order_num: values['order_num'],
      url: values['url'],
      request_type: values['request_type'],
      menu_type: values['menu_type'],
      visible: values['visible'] ? values['visible'] : '',
      comment: values['comment'] ? values['comment'] : '',
      icon: '',
    })
  }

  const handleCancel = (e: any) => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }

  const doUpdate = async (input: UpdateSysMenuItemReq) => {
    setFetching(true)
    const res = await authRequests.UpdateSysMenuItem(input)
    if (res.code === 0) {
      message.success('保存成功')
      setVisible(false)
      fetchData()
    } else {
      message.error('保存失败')
    }
    setFetching(false)
  }
  if (!authItem) return null
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>编辑权限
        </span>
      }
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form onFinish={handleOk} {...formItemLayout} preserve={false} form={form}>
        <Form.Item
          label="权限名"
          name="name"
          rules={[{ required: true, message: '请输入权限名称！' }]}
          initialValue={authItem.name}
        >
          <Input placeholder="请输入权限名" />
        </Form.Item>
        <Form.Item
          label="权限地址"
          name="url"
          rules={[{ required: true, message: '请输入权限地址！' }]}
          initialValue={authItem.url}
        >
          <Input placeholder="请输入权限地址" />
        </Form.Item>
        <Form.Item
          label="描述"
          name="comment"
          rules={[{ required: true, message: '请输入权限描述！' }]}
          initialValue={authItem.comment}
        >
          <Input.TextArea placeholder="请输入权限描述" />
        </Form.Item>
        <Form.Item
          label="请求类型"
          name="request_type"
          rules={[{ required: true, message: '请输入请求类型！' }]}
          initialValue={authItem.request_type}
        >
          <Select>
            <Select.Option value={'get'}>get</Select.Option>
            <Select.Option value={'post'}>post</Select.Option>
            <Select.Option value={'delete'}>delete</Select.Option>
            <Select.Option value={'put'}>put</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="序列号"
          name="order_num"
          rules={[{ required: true, message: '请输入序列号！' }]}
          initialValue={authItem.order_num}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="权限类型" name="menu_type" initialValue={authItem.menu_type}>
          <Select>
            <Select.Option value={'按钮'}>按钮</Select.Option>
            <Select.Option value={'网络请求'}>网络请求</Select.Option>
            <Select.Option value={'其他'}>其他</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="是否可见"
          name="visible"
          rules={[{ required: true, message: '请输入序列号！' }]}
          initialValue={authItem.visible}
        >
          <Radio.Group>
            <Radio value={'可见'}>可见</Radio>
            <Radio value={'不可见'}>不可见</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default UpdateAuthTable
