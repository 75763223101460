/// <reference path="type.d.ts" />
/// <reference path="project.d.ts" />

import request from './_base';

/**
 * 获取所有项目列表
 * @param  {getProjectListReq} input
 
 */
export async function getAllProjectList(
  input: getProjectListReq
): Promise<AssetsResourceProto<{ data: ProjectItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/teamProject/list`,
    data: input,
  }) as any;
}

/**
 * 创建项目
 * @param  {CreateProjectReq} input
 */
export async function createProject(input: CreateProjectReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/teamProject/project/save`,
    data: input,
  }) as any;
}

/**
 * 编辑项目
 * @param  {UpdateProjectReq} input
 */
export async function updateProject(input: UpdateProjectReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/teamProject/project/edit`,
    data: input,
  }) as any;
}

/**
 * 删除项目
 * @param  {DeleteProjectReq} input
 */
export async function deleteProject(input: DeleteProjectReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/teamProject/project/recycle`,
    data: input,
  }) as any;
}

/**
 * 创建任务
 * @param  {CreateTaskReq} input
 */
export async function createTask(input: CreateTaskReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/team/task/createTask`,
    data: input,
  }) as any;
}

// /**
//  * 分配任务
//  * @param  {CreateTaskReq} input
//  */
// export async function assignTask(input: CreateTaskReq): Promise<AssetsResourceProto<{ data: any }>> {
//   return request({
//     method: 'POST',
//     url: `/team/task/createTask`,
//     data: input,
//   }) as any
// }

/**
 * 根据项目id获取项目成员列表
 * @param  {ProjectItem['id']} id
 */
export async function getMembersById(
  id: ProjectItem['id']
): Promise<AssetsResourceProto<{ data: ProjectMemberItem[] }>> {
  return request({
    method: 'GET',
    url: `/teamProjectMember/member/list/${id}`,
  }) as any;
}

/**
 * 新增项目成员
 * @param  {ProjectItem['id']} project_id
 *  @param  {UserInfoItem['id']} user_id
 */
export async function addMember(
  project_id: ProjectItem['id'],
  user_id: UserInfoItem['id']
): Promise<AssetsResourceProto<{ data: ProjectMemberItem[] }>> {
  return request({
    method: 'PUT',
    url: `/teamProjectMember/member/${project_id}/${user_id}`,
  }) as any;
}

/**
 * 删除项目成员
 * @param  {ProjectItem['id']} project_id
 *  @param  {UserInfoItem['id']} user_id
 */
export async function removeMember(
  project_id: ProjectItem['id'],
  user_id: UserInfoItem['id']
): Promise<AssetsResourceProto<{ data: ProjectMemberItem[] }>> {
  return request({
    method: 'DELETE',
    url: `/teamProjectMember/member/${project_id}/${user_id}`,
  }) as any;
}

/**
 * 收藏项目
 * @param  {ProjectItem['id']} project_id
 */
export async function collectProject(project_id: ProjectItem['id']): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'GET',
    url: `teamProjectFavorite/favorite/${project_id}`,
  }) as any;
}

/**
 * 取消收藏项目
 * @param  {ProjectItem['id']} project_id
 */
export async function onCollectProject(project_id: ProjectItem['id']): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `teamProjectFavorite/cancelFavorite/${project_id}`,
  }) as any;
}

/**
 * 获取收藏项目列表
 
 */
export async function getCollectedProjectList(): Promise<
  AssetsResourceProto<{ data: CollectedProjectItem[]; total: number }>
> {
  return request({
    method: 'GET',
    url: `/teamProjectFavorite/list`,
  }) as any;
}

/**
 * 获取任务列表
 * @param {getTaskListReq} input
 */
export async function getTaskList(
  input: getTaskListReq
): Promise<AssetsResourceProto<{ data: ProjectTaskItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/team/task/listTask`,
    data: input,
  }) as any;
}

/**
 * 获取任务分组列表
 * @param {getStageListReq} input
 */
export async function getTaskStageList(
  input: getStageListReq
): Promise<AssetsResourceProto<{ data: TaskStagesItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/team-task-stages/list`,
    data: input,
  }) as any;
}

/**
 * 创建任务分组列表
 * @param {CreateStageReq} input
 */
export async function createStage(input: CreateStageReq): Promise<AssetsResourceProto<{ data: TaskStagesItem }>> {
  return request({
    method: 'POST',
    url: `/team-task-stages/new`,
    data: input,
  }) as any;
}

/**
 * 更新任务分组列表
 * @param {UpdateStageReq} input
 */
export async function updateStage(input: UpdateStageReq): Promise<AssetsResourceProto<{ data: TaskStagesItem }>> {
  return request({
    method: 'POST',
    url: `/team-task-stages/update`,
    data: input,
  }) as any;
}

/**
 * 删除任务分组
 * @param {TaskStagesItem['id']} id
 */
export async function deleteStage(id: TaskStagesItem['id']): Promise<AssetsResourceProto<{ data: TaskStagesItem }>> {
  return request({
    method: 'DELETE',
    url: `/team-task-stages/delete/${id}`,
  }) as any;
}

/**
 * 获取任务下Tag List
 * @param {TaskStagesItem['id']} id
 */
export async function getTaskTags(id: TaskStagesItem['id']): Promise<AssetsResourceProto<{ data: TaskTagItem[] }>> {
  return request({
    method: 'GET',
    url: `/teamTaskTag/listByTaskId/${id}`,
  }) as any;
}

/**
 * 创建任务Tags
 * @param {CreateTaskTagReq} input
 */
export async function createTaskTag(input: CreateTaskTagReq): Promise<AssetsResourceProto<{ data: TaskTagItem }>> {
  return request({
    method: 'POST',
    url: `/teamTaskTag/save`,
    data: input,
  }) as any;
}

/**
 * 获取项目内的所有tags
 * @param {GetProjectAllTagsReq} input
 */
export async function getProjectAllTags(
  input: GetProjectAllTagsReq
): Promise<AssetsResourceProto<{ data: TaskTagItem[] }>> {
  return request({
    method: 'POST',
    url: `/teamTaskTag/list`,
    data: input,
  }) as any;
}

/**
 * 删除项目的tag
 * @param {TaskTagItem['id']} id
 */
export async function deleteProjectTag(id: TaskTagItem['id']): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/teamTaskTag/delete/${id}`,
  }) as any;
}

/**
 * 修改任务下的Tag List
 * @param {UpdateTaskTagsReq} input
 */
export async function updateTaskTag(input: UpdateTaskTagsReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/teamTaskTag/updateTaskTagsByTaskId`,
    data: input,
  }) as any;
}

/**
 * 更新任务
 * @param {UpdateTaskReq} input
 */
export async function updateTask(input: UpdateTaskReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/team/task/update`,
    data: input,
  }) as any;
}

/**
 * 根据id查询单个任务
 * @param {number} id
 */
export async function getTaskById(id: number): Promise<AssetsResourceProto<{ data: ProjectTaskItem }>> {
  return request({
    method: 'GET',
    url: `/team/task/one/${id}`,
  }) as any;
}

/**
 * 分配任务
 * @param {AssignTaskReq} input
 */
export async function assignTask(input: AssignTaskReq): Promise<AssetsResourceProto<{ data: ProjectTaskItem }>> {
  return request({
    method: 'POST',
    url: `/team/task/assignTask`,
    data: input,
  }) as any;
}

/**
 * 新增任务工时
 * @param {AddWorkTimeReq} input
 */
export async function addWorkTime(input: AddWorkTimeReq): Promise<AssetsResourceProto<{ data: WorkTimeItem }>> {
  return request({
    method: 'POST',
    url: `/team/taskWorkTime/save`,
    data: input,
  }) as any;
}

/**
 * 修改任务工时
 * @param {UpdateWorkTimeReq} input
 */
export async function updateWorkTime(input: UpdateWorkTimeReq): Promise<AssetsResourceProto<{ data: WorkTimeItem }>> {
  return request({
    method: 'PUT',
    url: `/team/taskWorkTime/update`,
    data: input,
  }) as any;
}

/**
 * 删除任务工时
 * @param {number} id
 */
export async function deleteWorkTime(id: number): Promise<AssetsResourceProto<{ data: WorkTimeItem }>> {
  return request({
    method: 'DELETE',
    url: `/team/taskWorkTime/delete/${id}`,
  }) as any;
}

/**
 * 根据任务id获取工时列表
 * @param {number} task_id
 */
export async function getWorkTimeByTaskId(task_id: number): Promise<AssetsResourceProto<{ data: WorkTimeItem[] }>> {
  return request({
    method: 'GET',
    url: `/team/taskWorkTime/list/${task_id}`,
  }) as any;
}

/**
 * 根据任务id获取关联文件
 * @param {GetTaskFileReq} input
 */
export async function getTaskFiles(input: GetTaskFileReq): Promise<AssetsResourceProto<{ data: TaskFileItem[] }>> {
  return request({
    method: 'POST',
    url: `/team/task/file/list`,
    data: input,
  }) as any;
}

/**
 * 根据projectId新增任务执行状态
 * @param {AddExecuteStatusReq} input
 */
export async function addExecuteStatus(
  input: AddExecuteStatusReq
): Promise<AssetsResourceProto<{ data: ExecuteStatusItem }>> {
  return request({
    method: 'POST',
    url: `/teamTaskExecuteStatusConfig/new`,
    data: input,
  }) as any;
}

/**
 * 更新任务执行状态
 * @param {UpdateExecuteStatusReq} input
 */
export async function updateExecuteStatus(
  input: UpdateExecuteStatusReq
): Promise<AssetsResourceProto<{ data: ExecuteStatusItem }>> {
  return request({
    method: 'PUT',
    url: `/teamTaskExecuteStatusConfig/update`,
    data: input,
  }) as any;
}

/**
 * 删除任务执行状态
 * @param {number} id
 */
export async function deleteExecuteStatus(id: number): Promise<AssetsResourceProto<{ data: ExecuteStatusItem }>> {
  return request({
    method: 'DELETE',
    url: `/teamTaskExecuteStatusConfig/delete/${id}`,
  }) as any;
}

/**
 * 根据projectId查询项目下的任务执行状态
 * @param {getExecuteStatusListReq} input
 */
export async function getExecuteStatusList(
  input: getExecuteStatusListReq
): Promise<AssetsResourceProto<{ data: ExecuteStatusItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/teamTaskExecuteStatusConfig/list`,
    data: input,
  }) as any;
}

/**
 * 根据taskId查看任务的参与人员
 * @param {number} id
 */
export async function getTaskMembers(
  id: number
): Promise<AssetsResourceProto<{ data: TaskMemberItem[]; total: number }>> {
  return request({
    method: 'GET',
    url: `/team/task/member/list/${id}`,
  }) as any;
}

/**
 * 添加任务的参与者
 * @param {number} task_id
 * @param {number} user_id
 */
export async function joinTaskMember(
  task_id: number,
  user_id: number
): Promise<AssetsResourceProto<{ data: TaskMemberItem[]; total: number }>> {
  return request({
    method: 'PUT',
    url: `/team/task/inviteParticipant/${task_id}/${user_id}`,
  }) as any;
}

/**
 * 移除任务的参与者
 * @param {number} task_id
 * @param {number} user_id
 */
export async function removeTaskMember(
  task_id: number,
  user_id: number
): Promise<AssetsResourceProto<{ data: TaskMemberItem[]; total: number }>> {
  return request({
    method: 'DELETE',
    url: `/team/task/removeParticipant/${task_id}/${user_id}`,
  }) as any;
}

/**
 * 根据projectId新增工时种类
 * @param {AddProjectWorkTimeTypeReq} input
 */
export async function addProjectWorkTimeType(
  input: AddProjectWorkTimeTypeReq
): Promise<AssetsResourceProto<{ data: ProjectWorkTimeTypeItem }>> {
  return request({
    method: 'POST',
    url: `/teamTaskHoursTypeConfig/new`,
    data: input,
  }) as any;
}

/**
 * 更新任务工时种类
 * @param {UpdateProjectWorkTimeTypeReq} input
 */
export async function updateProjectWorkTimeType(
  input: UpdateProjectWorkTimeTypeReq
): Promise<AssetsResourceProto<{ data: ProjectWorkTimeTypeItem }>> {
  return request({
    method: 'PUT',
    url: `/teamTaskHoursTypeConfig/update`,
    data: input,
  }) as any;
}

/**
 * 删除任务工时种类
 * @param {number} id
 */
export async function deleteProjectWorkTimeType(
  id: number
): Promise<AssetsResourceProto<{ data: ProjectWorkTimeTypeItem }>> {
  return request({
    method: 'DELETE',
    url: `/teamTaskHoursTypeConfig/delete/${id}`,
  }) as any;
}

/**
 * 根据projectId查询项目下的任务工时种类list
 * @param {getProjectWorkTimeTypeListReq} input
 */
export async function getProjectWorkTimeTypeList(
  input: getProjectWorkTimeTypeListReq
): Promise<AssetsResourceProto<{ data: ProjectWorkTimeTypeItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/teamTaskHoursTypeConfig/list`,
    data: input,
  }) as any;
}

/**
 * 取消任务文件关联
 * @param {number} task_id
 * @param {string} file_id
 */
export async function deleteTaskFlile(task_id: number, file_id: string): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/team/task/file/delete/${task_id}/${file_id}`,
  }) as any;
}

/**
 * 获取项目操作日志
 * @param {GetProjectLogsReq} input
 */
export async function getProjectLogs(
  input: GetProjectLogsReq
): Promise<AssetsResourceProto<{ data: ProjectLogItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/teamProjectLog/list`,
    data: input,
  }) as any;
}

/**
 * 任务评论
 * @param {AddProjectLogReq} input
 */
export async function commentTask(input: AddProjectLogReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/teamProjectLog/new`,
    data: input,
  }) as any;
}

/**
 * 撤回任务评论
 * @param {ProjectLogItem['id']} log_id
 */
export async function retractCommentTask(log_id: ProjectLogItem['id']): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/teamProjectLog/retract/${log_id}`,
  }) as any;
}

/**
 * 根据项目id获取关联文件
 * @param {GetProjectFileReq} input
 */
export async function getProjectFiles(
  input: GetProjectFileReq
): Promise<AssetsResourceProto<{ data: TaskFileItem[] }>> {
  return request({
    method: 'POST',
    url: `/team/task/file/v2/list`,
    data: input,
  }) as any;
}

/**
 * 删除项目文件
 * @param {number} project_id
 * @param {string} file_id
 */
export async function deleteProjectFlile(
  project_id: number,
  file_id: string
): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/team/task/file/delete/project/${project_id}/${file_id}`,
  }) as any;
}

/**
 * 根据项目ID查询单个项目
 * @param {number} project_id
 */
export async function getProjectById(project_id: number): Promise<AssetsResourceProto<{ data: ProjectItem }>> {
  return request({
    method: 'GET',
    url: `/teamProject/one/${project_id}`,
  }) as any;
}

/**
 * 根据项目ID和任务id查询文件树结构
 * @param {getFileListTreeReq} input
 */
export async function getFileListTree(input: getFileListTreeReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/team/folderPath/listTree`,
    data: input,
  }) as any;
}
