import * as React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import {
  Divider,
  Tabs,
  Drawer,
  Input,
  List,
  Button,
  Avatar,
  message,
  Form,
  Select,
  DatePicker,
  Menu,
  Tooltip,
  Modal,
} from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  ExportOutlined,
  MenuOutlined,
  ProfileOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  TableOutlined,
  TagOutlined,
  UploadOutlined,
  UserOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import '../styles/projectDetail.less';
import TaskList from '../components/TaskList';
import { getAllUsers } from '@/requests/user';
import { addMember, getMembersById, removeMember, getProjectById } from '@/requests/project';
import { useCtxState } from '@/App.store';
import ProjectFileList from '../components/FileList';
import EditProjectModal from '../components/EditProjectModal';
import GranttWidget from '../components/Grantt';
import { UserInfo } from 'os';

const { TabPane } = Tabs;
interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  is_join: number;
}

interface FilterTaskFormField {
  task_name: string;
}

const ProjectDetail = () => {
  const location = useLocation();
  const [project, setProject] = React.useState<ProjectItem>();
  const [boardMode, setBoardMode] = React.useState(true);
  const [editMemberVisible, setEditMemberVisible] = React.useState(false);
  const [filterTaskVisible, setFilterTaskVisible] = React.useState(false);
  const [members, setMembers] = React.useState<Member[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const [filterTaskName, setFilterTaskName] = React.useState('');
  const [existMember, setExistMember] = React.useState<ProjectMemberItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [settingDrawerVisible, setSettingDrawerVisible] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState('tasks');

  const [filterTaskForm] = Form.useForm();
  const [{ sysUsers }] = useCtxState();
  const [editProjectVisible, setEditProjectVisible] = React.useState(false);
  const history = useHistory();

  const fetchProject = async (id?: number) => {
    if (!id) {
      if (location.search) {
        id = Number(location.search.split('=')[1]);
      } else {
        return;
      }
    }
    const res = await getProjectById(id);
    if (res.code === 0) {
      setProject(res.data);
    } else {
      message.error('获取项目详情失败');
    }
  };

  const fetchMembers = async () => {
    if (!project) return;

    const memRes = await getMembersById(project.id);
    if (memRes.code === 0) {
      setExistMember(memRes.data);
      if (members.length === 0) {
        setMembers(
          memRes.data.map((x) => {
            const match = sysUsers.find((y: UserInfoItem) => y.id === x.user_id) as any;
            return {
              user_id: match.id,
              avatar: match.avatar,
              user_name: match.username,
              email: match.email,
              is_join: 1,
            };
          })
        );
      }
    } else {
      message.error('获取成员列表失败');
    }
  };

  const searchUser = async () => {
    setLoading(true);
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: filterName,
    });
    if (res.code === 0) {
      let arr = res.data.map((user) => {
        const match = existMember.find((x) => x.user_id === user.id);
        return {
          user_id: user.id,
          avatar: user.avatar,
          user_name: user.username,
          email: user.email,
          is_join: match ? 1 : 0,
        };
      });

      setMembers(arr);
    }
    setLoading(false);
  };
  //添加参与者
  const doAddMember = async (project_id: ProjectItem['id'], user_id: UserInfoItem['id']) => {
    setLoading(true);
    const res = await addMember(project_id, user_id);
    if (res.code === 0) {
      message.success('添加成功');
      fetchMembers();
      let arr = members.map((x) => {
        if (x.user_id === user_id) {
          return {
            ...x,
            is_join: 1,
          };
        } else {
          return x;
        }
      });
      setMembers(arr);
      setFilterName('');
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  //移除参与者
  const doRemoveMember = async (project_id: ProjectItem['id'], user_id: UserInfoItem['id']) => {
    setLoading(true);
    const res = await removeMember(project_id, user_id);
    if (res.code === 0) {
      message.success('移除成功');
      let arr = members.map((x) => {
        if (x.user_id === user_id) {
          return {
            ...x,
            is_join: 0,
          };
        } else {
          return x;
        }
      });
      setMembers(arr);
      fetchMembers();
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (location.pathname === '/workbench/project/detail') {
      if (location.state) {
        const { project } = location.state as any;

        setProject(project);
      } else {
        fetchProject();
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (project) {
      fetchMembers();
    }
  }, [project]);

  React.useEffect(() => {
    if (filterName === '') {
      fetchMembers();
    }
  }, [filterName]);

  const handleFilterTaskOK = (values: FilterTaskFormField) => {
    console.log(values);
    setFilterTaskName(values.task_name);
  };

  const switchViewMode = () => {
    setBoardMode(!boardMode);
  };
  if (!project) return null;
  return (
    <div className="project-detail-page">
      <Tabs
        defaultActiveKey="task"
        onChange={(key) => {
          setActiveKey(key);
        }}
        tabBarStyle={{
          backgroundColor: '#ffffff',
          borderBottom: '1px solid #d9d9d9',
        }}
        destroyInactiveTabPane
        centered
        activeKey={activeKey}
        tabBarExtraContent={{
          right: [
            <div
              style={{
                color: 'grey',
                display: activeKey === 'tasks' ? 'block' : 'none',
                position: 'absolute',
                right: 0,
                top: 12,
              }}
            >
              <span style={{ padding: '0 24px 0 0' }} className="extraBtn" onClick={switchViewMode}>
                {boardMode && (
                  <span>
                    <ProfileOutlined /> <span>看板视图</span>
                  </span>
                )}
                {!!!boardMode && (
                  <span>
                    <TableOutlined /> <span>表格视图</span>
                  </span>
                )}
              </span>
              <span style={{ padding: 8 }} className="extraBtn">
                <span
                  onClick={() => {
                    setFilterTaskVisible(!filterTaskVisible);
                    setEditMemberVisible(false);
                    setSettingDrawerVisible(false);
                  }}
                >
                  <SearchOutlined /> <span>筛选</span>
                </span>
              </span>
              <Divider type="vertical" />
              <span style={{ padding: 8 }} className="extraBtn">
                <span
                  onClick={() => {
                    setEditMemberVisible(!editMemberVisible);
                    setFilterTaskVisible(false);
                    setSettingDrawerVisible(false);
                  }}
                >
                  <UserOutlined /> <span>{existMember.length}</span>
                </span>
              </span>
              <Divider type="vertical" />
              <span style={{ padding: '0 16px 0 8px' }} className="extraBtn">
                <span
                  onClick={() => {
                    setSettingDrawerVisible(!settingDrawerVisible);
                    setEditMemberVisible(false);
                    setFilterTaskVisible(false);
                  }}
                >
                  <MenuOutlined /> <span>菜单</span>
                </span>
              </span>
            </div>,
          ],
          left: [
            <a
              className="goback"
              style={{ cursor: 'pointer', paddingLeft: 16, color: 'grey' }}
              onClick={(e) => {
                e.preventDefault();
                if (history.length > 1) {
                  history.goBack();
                } else {
                  history.push('/workbench/project/list');
                }
              }}
            >
              <LeftOutlined></LeftOutlined>
              <span style={{ paddingLeft: 4 }}>{project.project_name}</span>
            </a>,
          ],
        }}
      >
        <TabPane tab="任务" key="tasks">
          <TaskList project={project} mode={boardMode} filterTaskName={filterTaskName} />
        </TabPane>
        <TabPane tab="文件" key="files">
          <ProjectFileList project={project} />
        </TabPane>
        <TabPane tab="甘特图" key="gantt">
          <GranttWidget project={project} users={members} />
        </TabPane>
      </Tabs>
      {editMemberVisible && (
        <Drawer
          title="项目成员"
          placement="right"
          visible={editMemberVisible}
          onClose={() => {
            setEditMemberVisible(false);
          }}
          mask={false}
          maskClosable={false}
          style={{ marginTop: 100, marginRight: 16 }}
          width={350}
          getContainer={() => document.getElementsByClassName('task-list-page')[0] as any}
        >
          <div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
                <span>账号邀请</span>
                <span>
                  <a>通过链接邀请</a>
                </span>
              </div>
              <Input
                prefix={<SearchOutlined />}
                placeholder="输入昵称或邮箱查找"
                onChange={(e) => setFilterName(e.target.value)}
                onPressEnter={() => {
                  searchUser();
                }}
                value={filterName}
              />
            </div>
            <div style={{ marginTop: 16 }}>
              <List
                dataSource={members}
                locale={{
                  emptyText: '没有搜索到相关成员',
                }}
                loading={loading}
                renderItem={(item) => (
                  <List.Item
                    extra={
                      item.is_join === 1 ? (
                        <span style={{ color: 'rgba(0,0,0,.45)' }}>
                          <UserOutlined style={{ paddingRight: 8 }} />
                          已加入
                          <Divider type="vertical"></Divider>
                          <Tooltip title="移除成员">
                            <DeleteOutlined
                              onClick={() => {
                                Modal.confirm({
                                  title: '移除成员',
                                  content: `确定要移除${item.user_name}吗？`,
                                  onOk: () => {
                                    doRemoveMember(project.id, item.user_id);
                                  },
                                });
                              }}
                            >
                              移除
                            </DeleteOutlined>
                          </Tooltip>
                        </span>
                      ) : (
                        <Button
                          onClick={() => {
                            doAddMember(project.id, item.user_id);
                          }}
                        >
                          邀请
                        </Button>
                      )
                    }
                  >
                    <List.Item.Meta
                      avatar={
                        item.avatar ? (
                          <Avatar src={`v2/api/user/avatar/${item.avatar}`} />
                        ) : (
                          <Avatar>{item.user_name}</Avatar>
                        )
                      }
                      title={item.user_name}
                      description={item.email}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </Drawer>
      )}
      <Drawer
        title="任务筛选"
        placement="right"
        visible={filterTaskVisible}
        onClose={() => {
          setFilterTaskVisible(false);
        }}
        mask={false}
        maskClosable={false}
        style={{ marginTop: 100, marginRight: 16 }}
        width={350}
        destroyOnClose
        getContainer={() => document.getElementsByClassName('task-list-page')[0] as any}
      >
        <Form
          colon={false}
          labelAlign={'right'}
          labelCol={{
            md: 6,
          }}
          form={filterTaskForm}
          onFinish={handleFilterTaskOK}
        >
          <Form.Item label={'标题'} name="task_name">
            <Input placeholder="输入任务名称筛选" />
          </Form.Item>
          <Form.Item label={'执行者'}>
            <Select placeholder="请选择">
              {members.map((member, index) => {
                return (
                  <Select.Option value={member.user_id} key={index}>
                    <List.Item.Meta
                      avatar={
                        member.avatar ? (
                          <Avatar src={`v2/api/user/avatar/${member.avatar}`} size={'small'} />
                        ) : (
                          <Avatar>{member.user_name}</Avatar>
                        )
                      }
                      title={<span style={{ color: '#f39800' }}>{member.user_name}</span>}
                      description={null}
                      style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                    />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={'创建者'}>
            <Select placeholder="请选择">
              {members.map((member, index) => {
                return (
                  <Select.Option value={member.user_id} key={index}>
                    <List.Item.Meta
                      avatar={
                        member.avatar ? (
                          <Avatar src={`v2/api/user/avatar/${member.avatar}`} size={'small'} />
                        ) : (
                          <Avatar>{member.user_name}</Avatar>
                        )
                      }
                      title={<span style={{ color: '#f39800' }}>{member.user_name}</span>}
                      description={null}
                      style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                    />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={'参与者'}>
            <Select placeholder="请选择">
              {members.map((member, index) => {
                return (
                  <Select.Option value={member.user_id} key={index}>
                    <List.Item.Meta
                      avatar={
                        member.avatar ? (
                          <Avatar src={`v2/api/user/avatar/${member.avatar}`} size={'small'} />
                        ) : (
                          <Avatar>{member.user_name}</Avatar>
                        )
                      }
                      title={<span style={{ color: '#f39800' }}>{member.user_name}</span>}
                      description={null}
                      style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                    />
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={'是否完成'}>
            <Select placeholder="请选择" defaultValue={0}>
              <Select.Option value={0}>全部</Select.Option>
              <Select.Option value={1}>已完成</Select.Option>
              <Select.Option value={2}>未完成</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'优先级'}>
            <Select placeholder="请选择">
              <Select.Option value={1}>普通</Select.Option>
              <Select.Option value={2}>紧急</Select.Option>
              <Select.Option value={3}>非常紧急</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'执行状态'}>
            <Select placeholder="请选择">
              <Select.Option value={0}>未开始</Select.Option>
              <Select.Option value={1}>已完成</Select.Option>
              <Select.Option value={2}>进行中</Select.Option>
              <Select.Option value={4}>挂起</Select.Option>
              <Select.Option value={5}>测试中</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'截止日期'}>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label={'创建日期'}>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label={'完成日期'}>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item label={'开始日期'}>
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item noStyle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button onClick={() => setFilterTaskVisible(false)} style={{ borderRadius: 5, width: '48%' }}>
                取消
              </Button>
              <Button type="primary" htmlType={'submit'} style={{ borderRadius: 5, width: '48%' }} loading={fetching}>
                搜索
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        title="菜单"
        placement="right"
        visible={settingDrawerVisible}
        onClose={() => {
          setSettingDrawerVisible(false);
        }}
        mask={false}
        maskClosable={false}
        style={{ marginTop: 100, marginRight: 16 }}
        width={350}
        bodyStyle={{ padding: 8 }}
        className="setting-drawer"
        destroyOnClose
        getContainer={() => document.getElementsByClassName('task-list-page')[0] as any}
      >
        <div>
          <Menu style={{ margin: 0, padding: 0 }}>
            <Menu.Item
              style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}
              onClick={() => setEditProjectVisible(true)}
            >
              <span>
                <SettingOutlined /> 项目设置
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <TagOutlined /> 标签
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <DeleteOutlined /> 查看回收站
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <CopyOutlined /> 下载导入任务模板
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <UploadOutlined /> 上传文件导入任务
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <ExportOutlined /> 导出任务 *
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <CopyOutlined /> 复制项目
              </span>
            </Menu.Item>
            <Menu.Item style={{ margin: 0, padding: ' 0 16px', fontWeight: 500, fontSize: 15 }}>
              <span>
                <SaveOutlined /> 保存为项目模板
              </span>
            </Menu.Item>
          </Menu>
        </div>
      </Drawer>
      {editProjectVisible && project && (
        <EditProjectModal
          visible={editProjectVisible}
          setVisible={setEditProjectVisible}
          project={project}
          // setProject={setProject}
          fetchData={fetchProject}
        />
      )}
    </div>
  );
};

export default ProjectDetail;
