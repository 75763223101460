import * as React from 'react'
import {
  Drawer,
  message,
  Spin,
  Skeleton,
  Form,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Tabs,
  Divider,
  Tooltip,
  Modal,
} from 'antd'
import * as BIRequests from '@/requests/bi'
import '../styles/edit-drawer.less'
import * as SuggestionRequests from '@/requests/suggestion'
import BIDataTableComponent from './BIDataTableComponent'
import moment from 'moment'
import * as strUtils from '@/utils/string'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

const EditChartDrawer = ({
  chart_id,
  drawerVisible,
  setDrawerVisible,
  setEditingChartId,
  fetchConfig,
}: {
  chart_id: string
  drawerVisible: boolean
  setEditingChartId: Function
  setDrawerVisible: Function
  userInfo: UserInfoItem
  fetchConfig: Function
}) => {
  const [chartInfo, setChartInfo] = React.useState<ChartItem | null>(null)
  const [fetching, setFetching] = React.useState(false)

  const [vAssetFlagOptions, setVAssetFlagOptions] = React.useState<string[]>([])
  const [statusNameOptions, setStatusNameOptions] = React.useState<string[]>([])
  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([])
  const [activeKey, setActiveKey] = React.useState(1)
  let postData = JSON.parse(JSON.stringify(chartInfo))

  const FormItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  }

  const fetchChartInfo = async () => {
    if (!chart_id) return
    setChartInfo(null)
    setFetching(true)
    const res = await BIRequests.getChartInfo(chart_id)
    if (res.code === 0) {
      setChartInfo(res.data)
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }

  React.useEffect(() => {
    fetchChartInfo()
    return setActiveKey(1)
  }, [chart_id])

  const onClose = () => {
    setDrawerVisible(false)
    setEditingChartId('')
    fetchConfig()
  }
  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={onClose}
      visible={drawerVisible}
      bodyStyle={{ height: '100%' }}
      mask={false}
      width={500}
      title={
        <Button
          onClick={async () => {
            if (chartInfo === null) return
            setFetching(true)
            const res = await BIRequests.updateChartInfo(postData)
            if (res.code === 0) {
              message.success('修改成功')
              chartInfo.data_series.forEach((x, index) => {
                localStorage.removeItem(`${chartInfo.chart_uid}_cache_data${index}`)
              })
              onClose()
            } else {
              message.error(res.message)
            }
            setFetching(false)
          }}
          type="primary"
        >
          保存
        </Button>
      }
    >
      <div style={{ height: '100%' }} className="edit-bi-form">
        {chartInfo && (
          <Form.Item label="图表名称">
            <Input
              style={{ width: 300 }}
              onChange={(e) => {
                postData.echart_config.title = e.target.value
              }}
              defaultValue={chartInfo.echart_config.title}
            />
          </Form.Item>
        )}
        {chartInfo ? (
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={
              <span>
                <Tooltip title="删除当前数据系列">
                  <DeleteOutlined
                    onClick={() => {
                      if (postData.data_series.length < 2) return
                      Modal.confirm({
                        title: '提示',
                        okText: '确认',
                        onOk: () => {
                          console.log(postData)
                          console.log(activeKey)
                          postData.data_series.splice(activeKey - 1, 1)
                          console.log(postData)
                          setChartInfo(postData)
                          if (activeKey > postData.data_series.length) {
                            setActiveKey(activeKey - 1)
                          }
                        },
                        cancelText: '取消',
                        onCancel: () => {},
                        content: '确认删除该系列数据吗',
                      })
                    }}
                    style={{ marginRight: 10 }}
                  ></DeleteOutlined>
                </Tooltip>
                <Tooltip title="添加数据系列">
                  <PlusOutlined
                    onClick={() => {
                      let pushData = JSON.parse(JSON.stringify(postData.data_series[0]))
                      postData.data_series.push(pushData)
                      setChartInfo(postData)
                      setActiveKey(postData.data_series.length)
                    }}
                    style={{ color: '#f39800' }}
                  ></PlusOutlined>
                </Tooltip>
              </span>
            }
            activeKey={activeKey.toString()}
            onChange={(key) => setActiveKey(Number(key))}
          >
            {chartInfo.data_series.map((x, index) => {
              return (
                <Tabs.TabPane tab={`数据系列${index + 1}`} key={`${index + 1}`}>
                  <Form {...FormItemLayout} key={index} style={{ marginBottom: 16 }}>
                    <Form.Item label="资产名称">
                      <Select
                        showSearch
                        placeholder="请输入资产名称"
                        onDropdownVisibleChange={async (open) => {
                          if (open) {
                          }
                        }}
                        onSearch={async (value) => {
                          if (strUtils.isNull(value)) {
                            return
                          }
                          if (value) {
                            const res = await SuggestionRequests.getAssetNameSuggestion(value)
                            if (res.code === 0) {
                              setAssetNameOptions(res.data)
                            } else {
                              message.error('网络错误')
                            }
                          } else {
                            return
                          }
                        }}
                        size="small"
                        onChange={(value: string) => {
                          postData.data_series[index].req_data.asset_name = value
                          setChartInfo(postData)
                        }}
                        value={x.req_data.asset_name}
                      >
                        {assetNameOptions.map((x: string) => {
                          return (
                            <Select.Option value={x} key={`asset_name_option:${x}`}>
                              {x}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="资产组件">
                      <Select
                        placeholder="请选择资产组件"
                        onDropdownVisibleChange={async (open) => {
                          if (open) {
                            const res = await SuggestionRequests.getAssetCompsSuggestion(
                              postData.data_series[index].req_data.asset_name
                            )
                            if (res.code === 0) {
                              setAssetCompsOptions(
                                res.data.map((comps) => {
                                  return comps.toString()
                                })
                              )
                            } else {
                              message.error('网络错误')
                            }
                          }
                        }}
                        size="small"
                        onChange={(option: string) => {
                          postData.data_series[index].req_data.asset_comps = option
                          setChartInfo(postData)
                        }}
                        value={x.req_data.asset_comps}
                      >
                        {assetCompsOptions.map((x: string) => {
                          return (
                            <Select.Option value={x} key={`asset_comps_option:${x}`}>
                              {x}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="资产状态名">
                      <Select
                        placeholder="请选择资产状态名"
                        onDropdownVisibleChange={async (open) => {
                          if (open) {
                            const res = await SuggestionRequests.getAssetStatusSuggestion({
                              asset_name: postData.data_series[index].req_data.asset_name,
                              asset_comps: postData.data_series[index].req_data.asset_comps.split(','),
                            })
                            if (res.code === 0) {
                              setStatusNameOptions(
                                res.data.map((x: StatusSuggestionItem) => {
                                  return `${x.asset_status}`
                                })
                              )
                            } else {
                              message.error('网络错误')
                            }
                          }
                        }}
                        onChange={(value: string) => {
                          postData.data_series[index].req_data.asset_status = value
                          setChartInfo(postData)
                        }}
                        size="small"
                        defaultActiveFirstOption
                        value={chartInfo.data_series[index].req_data.asset_status}
                      >
                        {statusNameOptions.map((x: string) => {
                          return (
                            <Select.Option value={x} key={`status_name_option:${x}`}>
                              {x}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label="地理信息">
                      <Select
                        size="small"
                        mode="tags"
                        onDropdownVisibleChange={async (open) => {
                          if (open) {
                            const res = await SuggestionRequests.getAssetVCompsSuggestion({
                              asset_comps: postData.data_series[index].req_data.asset_comps.split(','),
                              asset_name: postData.data_series[index].req_data.asset_name,
                            })
                            if (res.code === 0) {
                              let arr: string[] = []
                              res.data.forEach((comps) => {
                                arr = arr.concat(comps)
                              })
                              let filterData = Array.from(new Set(arr))
                              setVAssetQueryOptions(filterData)
                            }
                          }
                        }}
                        value={x.req_data.v_asset_query.map((item) => item.v_status_name)}
                        onChange={(options: string[]) => {
                          // setSelectedVAssetCopms(options);
                          postData.data_series[index].req_data.v_asset_query = options.map((option) => {
                            const origin = postData.data_series[index].req_data.v_asset_query.find(
                              (item: any) => item.v_status_name === option
                            )
                            if (origin !== undefined) {
                              return {
                                ...origin,
                              }
                            } else {
                              return {
                                v_status_name: option,
                                v_status_value: '',
                              }
                            }
                          })
                          setChartInfo(postData)
                          console.log(postData.data_series[index].req_data.v_asset_query)
                        }}
                      >
                        {vAssetQueryOptions.map((x: string) => {
                          return (
                            <Select.Option value={x} key={`v_status_name_option:${x}`}>
                              {x}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                    {chartInfo.data_series[index].req_data.v_asset_query.map((comps) => {
                      const origin = x.req_data.v_asset_query.find((item) => item.v_status_name === comps.v_status_name)
                      return (
                        <Form.Item label={comps.v_status_name} key={`selectedVAssetCopms:${comps.v_status_name}`}>
                          <Input
                            size="small"
                            value={origin ? origin.v_status_value : ''}
                            onChange={(e) => {
                              console.log(postData.data_series[index].req_data.v_asset_query)
                              postData.data_series[index].req_data.v_asset_query = postData.data_series[
                                index
                              ].req_data.v_asset_query.map((item: any) => {
                                if (item.v_status_name === comps.v_status_name) {
                                  item.v_status_value = e.target.value
                                }
                                return item
                              })
                              setChartInfo(postData)
                            }}
                          />
                        </Form.Item>
                      )
                    })}
                    <Form.Item label="筛选值">
                      {x.req_data.v_asset_flag ? (
                        <Select
                          onDropdownVisibleChange={(open) => {
                            if (open) {
                              setVAssetFlagOptions(['起始里程', '起始轨枕号', '时间'])
                            }
                          }}
                          value={x.req_data.v_asset_flag.v_status_name}
                          size="small"
                          disabled
                          onChange={(option: any) => {
                            postData.data_series[index].req_data.v_asset_flag.v_status_name = option
                            setChartInfo(postData)
                          }}
                        >
                          {vAssetFlagOptions.map((x: string) => {
                            return (
                              <Select.Option value={x} key={`v_asset_flag_optuon:${x}`}>
                                {x}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      ) : (
                        <Select defaultValue={'时间'} size="small" disabled></Select>
                      )}
                    </Form.Item>

                    {x.req_data.v_asset_flag && (
                      <Form.Item label="筛选值范围">
                        <div>
                          <InputNumber
                            size="small"
                            onChange={(value) => {
                              postData.data_series[index].req_data.v_asset_flag.v_status_value = `${value},${
                                postData.data_series[index].req_data.v_asset_flag.v_status_value.split(',')[1]
                              }`
                              setChartInfo(postData)
                            }}
                            defaultValue={Number(x.req_data.v_asset_flag.v_status_value.split(',')[0])}
                          />
                          <span>~</span>
                          <InputNumber
                            size="small"
                            onChange={(value) => {
                              postData.data_series[index].req_data.v_asset_flag.v_status_value = `${
                                postData.data_series[index].req_data.v_asset_flag.v_status_value.split(',')[0]
                              },${value}`
                              setChartInfo(postData)
                            }}
                            value={Number(x.req_data.v_asset_flag.v_status_value.split(',')[1])}
                          />
                        </div>
                      </Form.Item>
                    )}

                    <Form.Item label="时间范围">
                      <DatePicker.RangePicker
                        onChange={(dates, dateStrings) => {
                          postData.data_series[index].req_data.start_time = dateStrings[0] + ' 00:00:00'
                          postData.data_series[index].req_data.end_time = dateStrings[1] + ' 00:00:00'
                          setChartInfo(postData)
                        }}
                        size="small"
                        value={
                          chartInfo.data_series[index].req_data.start_time &&
                          chartInfo.data_series[index].req_data.end_time
                            ? [
                                moment(chartInfo.data_series[index].req_data.start_time),
                                moment(chartInfo.data_series[index].req_data.end_time),
                              ]
                            : undefined
                        }
                      />
                    </Form.Item>
                  </Form>
                  <Divider>数据预览</Divider>
                  <BIDataTableComponent req_data={x.req_data} type={chartInfo.echart_config.type} />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        ) : (
          <Skeleton />
        )}
        <Spin spinning={fetching} style={{ position: 'absolute', top: '50%', right: '50%' }} />
      </div>
    </Drawer>
  )
}

export default EditChartDrawer
