import * as React from 'react'
import { Form, Input, Button, Layout, message, Row, Col, Result } from 'antd'
import * as userRequest from '@/requests/user'
import { withRouter, useHistory } from 'react-router-dom'
import './index.less'
import { encryptPwd } from '@/utils/string'

interface ResetPasswordFormValues {
  phone: string
  password: string
  comfirm: string
  sm_code: string
  img_code: string
}

const ResetPasswordPage = () => {
  const [fetching, setFetching] = React.useState(false)
  const { Content } = Layout
  const history = useHistory()
  const [isOk, setIsOk] = React.useState(false)
  const [form] = Form.useForm()
  const [phone, setPhone] = React.useState('')
  const [hasSendSms, setHasSendSms] = React.useState(false)
  let timer = 60

  const resetPassword = async (input: { phone: string; password: string; sm_code: string }) => {
    if (!input) return
    try {
      setFetching(true)
      const res = await userRequest.resetPassword({
        ...input,
      })

      if (res.code === 0) {
        message.success('重置密码成功')
        setFetching(false)
        setIsOk(true)
        setTimeout(() => {
          history.replace('/login')
        }, 3000)
      } else if (res.code === 400) {
        message.error(res.message)
      } else if (res.code === 10003) {
        message.error('验证码错误！')
      } else if (res.code === 20007) {
        message.error('您输入的手机号与用户名不匹配')
      } else if (res.code === 20010) {
        message.error('验证码错误！')
      } else {
        message.info(res.message)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setFetching(false)
    }
  }

  const onFinish = (values: ResetPasswordFormValues) => {
    const { phone, password, sm_code } = values
    resetPassword({ phone, password: encryptPwd(password), sm_code })
  }

  return (
    <div className="reset-container">
      <Layout>
        <Content className="reset-page-content">
          <div className="reset-wrapper">
            <img src={process.env.PUBLIC_URL + '/img/common/logo_title_white.png'} alt="logo-header" height={28}></img>
            <div className="reset-content">
              {isOk && !!!fetching && (
                <Content className="reset-wrapper">
                  <Result
                    status="success"
                    title="重置登录密码成功！"
                    subTitle="即将跳转登录页面"
                    extra={[
                      <Button type="primary" key="console" onClick={() => history.replace('/login')}>
                        立即前往登录
                      </Button>,
                    ]}
                  />
                </Content>
              )}
              {!!!isOk && (
                <>
                  <div className="welcome">重置密码</div>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    style={{ width: 300 }}
                    onValuesChange={(c_value) => {
                      if (Object.keys(c_value)[0] === 'phone') setPhone(c_value[Object.keys(c_value)[0]])
                    }}
                    initialValues={{ gender: 'male', agreement: true }}
                  >
                    <Form.Item
                      name="phone"
                      validateTrigger="onBlur"
                      rules={[
                        { required: true, message: '请输入您的手机号码！' },
                        {
                          len: 11,
                          message: '请输入正确格式的手机号!',
                        },
                        {
                          validator: async (rule, value) => {
                            if (!value) return
                            const res = await userRequest.checkPhoneDuplicate({
                              phone: value,
                            })
                            if (res.code === 0) {
                              return Promise.reject('该手机号未注册')
                            } else if (res.code === 20011) {
                              return Promise.resolve()
                            } else {
                              message.error(res.message)
                            }
                          },
                        },
                      ]}
                    >
                      <Input style={{ width: '100%' }} placeholder="手机号" />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: '请输入您的密码!',
                        },
                      ]}
                    >
                      <Input.Password style={{ width: 300 }} placeholder="新密码" />
                    </Form.Item>
                    <Form.Item
                      name="confirm"
                      validateTrigger="onBlur"
                      dependencies={['password']}
                      rules={[
                        {
                          required: true,
                          message: '请确认您的密码!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject('两次输入密码不一致!')
                          },
                        }),
                      ]}
                    >
                      <Input.Password style={{ width: 300 }} placeholder="确认新密码" />
                    </Form.Item>
                    <Form.Item>
                      <Row style={{ width: 300 }} gutter={8}>
                        <Col span={10}>
                          <Form.Item
                            name="img_code"
                            validateTrigger="onBlur"
                            noStyle
                            rules={[{ required: true, message: '请输入图形验证码' }]}
                          >
                            <Input disabled={fetching} placeholder="图形验证码" />
                          </Form.Item>
                        </Col>
                        <Col span={14}>
                          {phone && phone.length === 11 && (
                            <Row
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <Col>
                                <img
                                  src={`/v2/api/user/register/image/code/${phone}`}
                                  id="img-code"
                                  alt="图形验证码"
                                  height={30}
                                />
                              </Col>
                              <Col>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const img = document.getElementById('img-code') as HTMLImageElement
                                    if (img) {
                                      img.src = `/v2/api/user/register/image/code/${phone}?code=${Math.random()}`
                                    }
                                  }}
                                >
                                  换一张
                                </a>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item
                      name="sm_code"
                      validateTrigger="onBlur"
                      rules={[{ required: true, message: '请输入手机短信验证码' }]}
                    >
                      <Input
                        style={{ width: 300 }}
                        placeholder="手机验证码"
                        addonAfter={
                          <span
                            id="send-msm"
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: hasSendSms ? '#A3B5C6' : '#F39800',
                              cursor: hasSendSms ? 'progress' : 'pointer',
                            }}
                            onClick={async () => {
                              if (hasSendSms) return
                              const phone = form.getFieldValue('phone')
                              const checkCode = form.getFieldValue('img_code')
                              if (!phone || !checkCode) {
                                form.validateFields(['phone', 'img_code'])
                                return
                              }

                              const res = await userRequest.getSmsCode({
                                phone,
                                checkCode,
                              })
                              if (res.code === 0) {
                                message.success('已成功向您的手机发送验证码，请注意查收!')
                                setHasSendSms(true)
                                const timers = setInterval(() => {
                                  const btn = document.getElementById('send-msm') as any
                                  if (!btn) {
                                    clearInterval(timers)
                                    return
                                  }
                                  timer -= 1
                                  btn.innerText = `${timer}s重新获取`
                                  if (timer === 0) {
                                    setHasSendSms(false)
                                    clearInterval(timers)
                                  }
                                }, 1000)
                              } else if (res.code === 10020) {
                                message.error('图形验证码错误！')
                              } else {
                                message.error(res.message)
                              }
                            }}
                          >
                            {hasSendSms ? `${timer}s重新获取` : '获取验证码'}
                          </span>
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button type="primary" style={{ width: 130 }} htmlType={'submit'} loading={fetching}>
                          确认
                        </Button>
                        <Button style={{ width: 130 }} onClick={() => history.goBack()}>
                          取消
                        </Button>
                      </div>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default withRouter(ResetPasswordPage)
