/// <reference path="type.d.ts" />
/// <reference path="AIDetection.d.ts" />

import request from './_base';

/**
 * 查询模型列表
 * @param  {GetAIModelListReq} input
 */
export function getAIModelList(
  input: GetAIModelListReq
): Promise<AssetsResourceProto<{ data: AIModelInfoItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/aiModelInfo/list`,
    data: input,
  }) as any;
}

/**
 * 查询检测细项
 * @param  {string} apiName
 */
export function getChildApi(apiName: string): Promise<AssetsResourceProto<{ data: string[] }>> {
  return request({
    method: 'GET',
    url: `/aiDetectProject/aiDetectDataResultTypeEnum/${apiName}`,
  }) as any;
}

/**
 * 根据模型检测图片
 * @param  {DetectImgReq} input
 */
export function detectImgs(input: DetectImgReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/aiModelInfo/detectDataByModelV2`,
    data: input,
  }) as any;
}

/**
 * 用户检测记录数量
 */
export function getMyDetectRecordCount(): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'GET',
    url: `/aiDetectData/myDetectDataNum`,
  }) as any;
}

/**
 * 查询用户检测记录
 *  * @param  {GetMyDetectRecordsReq} input
 */
export function getMyDetectRecords(
  input: GetMyDetectRecordsReq
): Promise<AssetsResourceProto<{ data: MyDetectRecordsItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/aiDetectProject/myDetectData`,
    data: input,
  }) as any;
}

/**
 * 查询模型检测记录
 *  * @param  {GetDetectRecordsOfModelReq} input
 */
export function getDetectRecordsOfModel(
  input: GetDetectRecordsOfModelReq
): Promise<AssetsResourceProto<{ data: DetectRecordsItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/aiDetectProject/list`,
    data: input,
  }) as any;
}
/**
 * 检测记录用户suggestion
 *  * @param  {string} model_id
 */
export function getDetectedUsers(model_id: string): Promise<AssetsResourceProto<{ data: UserInfoItem[] }>> {
  return request({
    method: 'GET',
    url: `/aiDetectData/suggestionUserListByModelUid/${model_id}`,
  }) as any;
}

/**
 * 标记检测结果
 *  * @param  {CheckDetectResultReq} input
 */
export function checkDetectResult(input: CheckDetectResultReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/aiDetectData/accurateDetectionList`,
    data: input,
  }) as any;
}

/**
 * 根据检测项目id获取绑定的资产状态信息
 *  * @param  {number} aiDetectProjectId
 */
export function getBindAssetStatus(
  aiDetectProjectId: number
): Promise<AssetsResourceProto<{ data: DetectAssetStatus[] }>> {
  return request({
    method: 'GET',
    url: `/aiDetectData/listAssetStatus/${aiDetectProjectId}`,
  }) as any;
}

/**
 * 模型训练
 * @param  {TrainModelReq} input
 */
export function trainModel(input: TrainModelReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/aiModelInfo/trainModel`,
    data: input,
  }) as any;
}

/**
 * 获取检测报告url
 * @param  {number} aiDetectProjectId
 */
export function getDetectPdf(aiDetectProjectId: number): Promise<AssetsResourceProto<{ data: string }>> {
  return request({
    method: 'GET',
    url: `/aiDetectProject/downloadDetectPdf/${aiDetectProjectId}`,
  }) as any;
}

/**
 * 查询模型训练任务队列
 * @param  {GetModelTrainTaskListReq} input
 */
export function getModelTrainTaskList(
  input: GetModelTrainTaskListReq
): Promise<AssetsResourceProto<{ data: ModelTrainTaskListItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/aiModelInfo/trainModel/taskList`,
    data: input,
  }) as any;
}

/**
 * 查询模型历史列表
 * @param  {GetModelHistoryListReq} input
 */
export function getModelHistoryList(
  input: GetModelHistoryListReq
): Promise<AssetsResourceProto<{ data: AIModelInfoItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/aiModelInfo/history/list`,
    data: input,
  }) as any;
}
/**
 * 删除模型
 * @param  {string} model_id
 */
export function deleteModel(model_id: string): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/aiModelInfo/deleteModel/${model_id}`,
  }) as any;
}

/**
 * 删除检测记录
 * @param  {number} id
 */
export function deleteDetectProject(id: number): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/aiDetectProject/deleteByProjectId/${id}`,
  }) as any;
}
