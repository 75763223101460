import React, { FC } from 'react';
import './App.less';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from '@/ui-auth/login';
import RegisterPage from '@/ui-auth/register';
import ResetPasswordPage from '@/ui-auth/ResetPassword';
import NoMatched from '@/ui-routes/404';
import { connect } from '@/App.store';
import WorkBench from '@/ui-routes/WorkBench';
import UserInfoCenter from '@/ui-auth/UserInfoCenter';
import BadRequest from '@/ui-routes/NetError';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

const App: FC = () => {
  const [locale] = React.useState(zhCN);

  return (
    <ConfigProvider locale={locale}>
      <Router basename="/">
        <Switch>
          <Route path="/" exact>
            <Redirect to="/workbench" />
          </Route>

          <Route path="/workbench" component={WorkBench}></Route>
          <Route path="/login" component={LoginPage}></Route>
          <Route path="/register" component={RegisterPage}></Route>
          <Route path="/reset-password" component={ResetPasswordPage}></Route>
          <Route path="/userinfo-center" component={UserInfoCenter}></Route>
          <Route path="/net-error" exact>
            <BadRequest />
          </Route>
          <Route path="*">
            <NoMatched />
          </Route>
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default connect(App);
