import * as React from 'react';
import { Input, message, Modal, List, Avatar, Button, Tooltip, Divider } from 'antd';
import { getMembersById, addMember, removeMember } from '@/requests/project';
import { DeleteOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { getAllUsers } from '@/requests/user';
import { useCtxState } from '@/App.store';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  project: ProjectItem | undefined;
  setProject: Function;
}

interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  is_join: number;
}

const MemberModal: React.FC<PropsType> = (props) => {
  const { visible, setVisible, project, setProject } = props;

  const [{ sysUsers }] = useCtxState();
  const [members, setMembers] = React.useState<Member[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const [existMember, setExistMember] = React.useState<ProjectMemberItem[]>([]);

  const fetchMembers = async () => {
    if (!project) return;

    const memRes = await getMembersById(project.id);
    if (memRes.code === 0) {
      setExistMember(memRes.data);
      setMembers(
        memRes.data.map((x) => {
          const match = sysUsers.find((y: UserInfoItem) => y.id === x.user_id) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            is_join: 1,
          };
        })
      );
    } else {
      message.error('获取成员列表失败');
    }
  };

  const searchUser = async () => {
    setLoading(true);
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: filterName,
    });
    if (res.code === 0) {
      let arr = res.data.map((user) => {
        const match = existMember.find((x) => x.user_id === user.id);
        return {
          user_id: user.id,
          avatar: user.avatar,
          user_name: user.username,
          email: user.email,
          is_join: match ? 1 : 0,
        };
      });

      setMembers(arr);
    }
    setLoading(false);
  };
  //添加参与者
  const doAddMember = async (project_id: ProjectItem['id'], user_id: UserInfoItem['id']) => {
    setLoading(true);
    const res = await addMember(project_id, user_id);
    if (res.code === 0) {
      message.success('添加成功');
      let arr = members.map((x) => {
        if (x.user_id === user_id) {
          return {
            ...x,
            is_join: 1,
          };
        } else {
          return x;
        }
      });
      setMembers(arr);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  //移除参与者
  const doRemoveMember = async (project_id: ProjectItem['id'], user_id: UserInfoItem['id']) => {
    setLoading(true);
    const res = await removeMember(project_id, user_id);
    if (res.code === 0) {
      message.success('移除成功');
      let arr = members.map((x) => {
        if (x.user_id === user_id) {
          return {
            ...x,
            is_join: 0,
          };
        } else {
          return x;
        }
      });
      setMembers(arr);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (project) {
      fetchMembers();
    }
  }, [project]);
  React.useEffect(() => {
    if (filterName === '') {
      fetchMembers();
    }
  }, [filterName]);

  const handleCnacel = () => {
    setProject(undefined);
    setVisible(false);
  };
  if (!project) return null;
  return (
    <Modal
      visible={visible}
      onCancel={handleCnacel}
      footer={null}
      title="邀请新成员"
      bodyStyle={{ padding: 16, height: 450, overflow: 'scroll' }}
      destroyOnClose={true}
    >
      <div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 12 }}>
            <span>账号邀请</span>
            {/* <span>
              <a>通过链接邀请</a>
            </span> */}
          </div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="输入昵称或邮箱查找"
            onChange={(e) => setFilterName(e.target.value)}
            onPressEnter={() => {
              searchUser();
            }}
            value={filterName}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <List
            dataSource={members}
            locale={{
              emptyText: '没有搜索到相关成员',
            }}
            loading={loading}
            renderItem={(item) => (
              <List.Item
                extra={
                  item.is_join === 1 ? (
                    <span style={{ color: 'rgba(0,0,0,.45)' }}>
                      <UserOutlined style={{ paddingRight: 8 }} />
                      已加入
                      <Divider type="vertical"></Divider>
                      <Tooltip title="移除成员">
                        <DeleteOutlined
                          onClick={() => {
                            Modal.confirm({
                              title: '移除成员',
                              content: `确定要移除${item.user_name}吗？`,
                              onOk: () => {
                                doRemoveMember(project.id, item.user_id);
                              },
                            });
                          }}
                        >
                          移除
                        </DeleteOutlined>
                      </Tooltip>
                    </span>
                  ) : (
                    <Button
                      onClick={() => {
                        doAddMember(project.id, item.user_id);
                      }}
                    >
                      邀请
                    </Button>
                  )
                }
              >
                <List.Item.Meta
                  avatar={
                    item.avatar ? (
                      <Avatar src={`v2/api/user/avatar/${item.avatar}`} />
                    ) : (
                      <Avatar>{item.user_name}</Avatar>
                    )
                  }
                  title={item.user_name}
                  description={item.email}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MemberModal;
