import React from 'react'
import { Modal, Input, Form, Select, message, InputNumber, Radio, Button } from 'antd'
import * as authRequests from '@/requests/role'
import { formItemLayout } from '../configs'

interface FormValues {
  name: string
  url: string
  comment: string
  request_type: string
  order_num: number
  menu_type: string
  // icon: string
  visible: string
}

const CreateAuthModal = (props: any) => {
  const { visible, setVisible, fetchData, parent_id } = props
  const [fetching, setFetching] = React.useState(false)
  const [form] = Form.useForm()

  const handleOk = (values: FormValues) => {
    doCreate({
      name: values['name'],
      parent_id: parent_id,
      order_num: values['order_num'],
      url: values['url'],
      request_type: values['request_type'],
      menu_type: values['menu_type'],
      visible: values['visible'] ? values['visible'] : '',
      comment: values['comment'] ? values['comment'] : '',
      icon: '',
    })
  }

  const handleCancel = () => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }

  const doCreate = async (input: SysMenuItemReq) => {
    setFetching(true)
    const res = await authRequests.CreateSysMenuItem(input)
    if (res.code === 0) {
      message.success('创建成功')
      setVisible(false)
      fetchData()
    } else {
      message.error('创建失败')
    }
    setFetching(false)
  }

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>创建权限
        </span>
      }
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <Form onFinish={handleOk} {...formItemLayout} preserve={false} form={form}>
        <Form.Item label="权限名" name="name" rules={[{ required: true, message: '请输入权限名称！' }]}>
          <Input placeholder="请输入权限名" />
        </Form.Item>
        <Form.Item label="权限地址" name="url" rules={[{ required: true, message: '请输入权限地址！' }]}>
          <Input placeholder="请输入权限地址" />
        </Form.Item>
        <Form.Item label="描述" name="comment" rules={[{ required: true, message: '请输入权限描述！' }]}>
          <Input.TextArea placeholder="请输入权限描述" />
        </Form.Item>
        <Form.Item
          label="请求类型"
          name="request_type"
          rules={[{ required: true, message: '请输入请求类型！' }]}
          initialValue={'get'}
        >
          <Select>
            <Select.Option value={'get'}>get</Select.Option>
            <Select.Option value={'post'}>post</Select.Option>
            <Select.Option value={'delete'}>delete</Select.Option>
            <Select.Option value={'put'}>put</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="序列号"
          name="order_num"
          rules={[{ required: true, message: '请输入序列号！' }]}
          initialValue={0}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="权限类型" name="menu_type">
          <Select>
            <Select.Option value={'按钮'}>按钮</Select.Option>
            <Select.Option value={'网络请求'}>网络请求</Select.Option>
            <Select.Option value={'其他'}>其他</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="是否可见"
          name="visible"
          rules={[{ required: true, message: '请输入序列号！' }]}
          initialValue={'可见'}
        >
          <Radio.Group>
            <Radio value={'可见'}>可见</Radio>
            <Radio value={'不可见'}>不可见</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateAuthModal
