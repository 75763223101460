import * as React from 'react';
import {
  PageHeader,
  Button,
  Input,
  message,
  Spin,
  Empty,
  List,
  Card,
  Tooltip,
  Modal,
  Dropdown,
  Menu,
  Form,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import * as FormRequests from '@/requests/normalForm';
import { DeleteOutlined, EyeOutlined, EditOutlined, OrderedListOutlined, RedoOutlined } from '@ant-design/icons';
import '../styles/index.less';

const ShareNormalTemplates = () => {
  const [data, setData] = React.useState<ShareTemplateItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');
  const history = useHistory();
  const location = useLocation();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await FormRequests.getShareTemplateList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      user_id: '456',
    });

    if (res.code === 0) {
      setData(res.data.reverse());
      setPagination({
        ...pagination,
        current: page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const deleteForm = async (uid: number) => {
    const res = await FormRequests.deleteShareTemplate({
      id: uid,
    });
    if (res.code === 0) {
      message.success('删除成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    if (location.pathname === '/workbench/forms-management/share-template') {
      setFetching(true);
      fetchData();
    }
  }, [location]);
  return (
    <div className="my-template-list-page">
      <div className="tools-wrapper">
        {data.length > 0 && (
          <PageHeader
            title={
              <div className="filter-item">
                <Input.Search
                  placeholder="输入模板名称搜索"
                  onChange={(e) => {
                    setFilterName(e.target.value);
                  }}
                  onSearch={() => fetchData()}
                />
              </div>
            }
            extra={[
              <Button icon={<RedoOutlined />} onClick={() => fetchData()} type="primary">
                刷新
              </Button>,
            ]}
            ghost={true}
            style={{ padding: '8px 0 16px 0', width: '100%' }}
          ></PageHeader>
        )}
      </div>
      <div style={{ width: '100%' }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
            showTotal: (total) => <span>共{total}条数据</span>,
          }}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.form_template_name}
                actions={[
                  <Tooltip title="填写">
                    <span
                      onClick={() => {
                        history.push({
                          pathname: `/workbench/normal-form/write-page`,
                          state: {
                            template: item,
                          },
                          search: `uid=${item.id}&version_number=${item.version_number}`,
                        });
                      }}
                    >
                      <EditOutlined key="edit" style={{ color: '#f39800', marginRight: 5 }} />
                      填写
                    </span>
                  </Tooltip>,
                  // <Tooltip title="查看详情">
                  //   <span
                  //     onClick={() => {
                  //       history.push({
                  //         pathname: `/workbench/normal-form/my-normal-template/detail`,
                  //         search: `uid=${item.uid}`,
                  //         state: {
                  //           data: item,
                  //         },
                  //       })
                  //     }}
                  //   >
                  //     <EyeOutlined key="eye" style={{ marginRight: 5 }} />
                  //     查看
                  //   </span>
                  // </Tooltip>,
                  <Tooltip title="统计">
                    <span
                      onClick={() => {
                        history.push({
                          pathname: `/workbench/normal-form/statistics`,
                          search: `id=${item.id}`,
                          state: {
                            id: item.id,
                            data: item,
                          },
                        });
                      }}
                    >
                      <OrderedListOutlined key="count" style={{ marginRight: 5 }} />
                      统计
                    </span>
                  </Tooltip>,
                  <Tooltip title="删除">
                    <span
                      onClick={() => {
                        Modal.confirm({
                          okText: '确认',
                          cancelText: '取消',
                          title: '提示',
                          content: '确认删除该表单模板吗？',
                          onOk: () => deleteForm(item.id),
                        });
                      }}
                    >
                      <DeleteOutlined style={{ marginRight: 5 }} />
                      删除
                    </span>
                  </Tooltip>,
                ]}
              >
                <Form labelCol={{ md: 5, lg: 5 }} labelAlign={'left'}>
                  <Form.Item label="简介" style={{ marginBottom: 0 }}>
                    {item.description}
                  </Form.Item>

                  <Form.Item label="版本号" style={{ marginBottom: 0 }}>
                    {item.version_name}
                  </Form.Item>
                  <Form.Item label="分享者" style={{ marginBottom: 0 }}>
                    {item.share_user_name}
                  </Form.Item>
                  <Form.Item label="分享时间" style={{ marginBottom: 0 }}>
                    {item.share_time}
                  </Form.Item>
                </Form>
              </Card>
            </List.Item>
          )}
          locale={{
            emptyText: (
              <div>
                <Empty description="您暂未创建普通表单模板">
                  <Button type="primary">创建模板</Button>
                </Empty>
              </div>
            ),
          }}
        ></List>
      </div>
      <Spin spinning={fetching} style={{ position: 'absolute', right: '50%', top: '50%' }} />
    </div>
  );
};

export default ShareNormalTemplates;
