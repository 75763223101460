import * as React from 'react'
import { Form, Input, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import * as formRequests from '@/requests/normalForm'

interface propsType {
  setCurrent: Function
  setFormName: Function
  setDescription: Function
  setFormTemplateId: Function
  setVersionName: Function
}
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

const CreateTemplateStepOne = (props: propsType) => {
  const [form] = Form.useForm()
  const handleNext = (values: any) => {
    console.log(values)
    props.setFormName(values.form_name)
    props.setDescription(values.description)
    props.setFormTemplateId(values.form_template_id)
    props.setVersionName(values.version_name)
    props.setCurrent(1)
  }
  const history = useHistory()
  return (
    <Form onFinish={handleNext} {...formItemLayout} style={{ width: 600 }} preserve={false} form={form}>
      <Form.Item
        label="表单名称"
        name="form_name"
        rules={[
          { required: true, message: '请输入表单名称！' },
          {
            validator: async (rule, value) => {
              if (!value) return
              const res = await formRequests.checkNameUnique({
                form_template_name: value,
              })
              if (res.code === 0) {
                return Promise.resolve()
              } else if (res.code === 90009) {
                return Promise.reject('该表单模板已存在！')
              } else {
                message.error(res.message)
              }
            },
          },
        ]}
      >
        <Input placeholder="表单名称" />
      </Form.Item>
      <Form.Item label="表单描述" name="description" rules={[{ required: true, message: '请输入表单描述' }]}>
        <Input placeholder="表单描述" />
      </Form.Item>
      <Form.Item label="表单编号" name="form_template_id" rules={[{ required: true, message: '请输入表单编号！' }]}>
        <Input placeholder="表单编号" />
      </Form.Item>
      <Form.Item label="版本号" name="version_name" rules={[{ required: true, message: '请输入版本号！' }]}>
        <Input placeholder="版本号" />
      </Form.Item>
      <Form.Item noStyle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: 36,
          }}
        >
          <Button
            onClick={() => {
              history.goBack()
            }}
            style={{ marginRight: 16 }}
          >
            取消
          </Button>

          <Button type="primary" htmlType={'submit'}>
            下一步
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default CreateTemplateStepOne
