import * as React from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { Modal, message } from 'antd'
import * as UserRequests from '@/requests/user'

const UploadAvatarWidget = ({
  visible,
  setAvatar,
  setModalVisible,
  file,
  userInfo,
  doFetch,
}: {
  visible: boolean
  setModalVisible: Function
  setAvatar: Function
  file: File
  userInfo: UserInfoItem
  doFetch: Function
}) => {
  const [url, setUrl] = React.useState<any>(null)
  const cropper = React.useRef<Cropper>(null)
  React.useEffect(() => {
    if (!file) return
    const fileReader = new FileReader()
    fileReader.onload = (e: any) => {
      const dataURL = e.target.result
      setUrl(dataURL)
    }
    fileReader.readAsDataURL(file)
  }, [file])
  return (
    <Modal
      title=""
      visible={visible}
      onCancel={() => {
        setModalVisible(false)
        setAvatar(null)
      }}
      okText="确认上传"
      cancelText="取消"
      onOk={() => {
        if (!cropper) {
          message.error('发生错误，请稍后再试！')
          setModalVisible(false)
        }
        message.loading('上传中')
        cropper.current &&
          cropper.current.getCroppedCanvas({ height: 100, width: 100 }).toBlob(async (blob: any) => {
            const fileee = new File([blob], `${userInfo.username}.png`, {
              type: blob.type,
            })

            const res = await UserRequests.uploadAvatar({
              user_id: userInfo.user_id,
              avatar: fileee,
            })
            if (res.code === 0) {
              message.destroy()
              message.success('上传成功')
              doFetch()
              setModalVisible(false)
            } else {
              message.error('上传失败')
            }
          })
      }}
    >
      <Cropper style={{ height: 400, width: '100%' }} src={url} aspectRatio={1} ref={cropper} />
    </Modal>
  )
}

export default UploadAvatarWidget
