import * as React from 'react'
import { Input } from 'antd'
import { EditFilled } from '@ant-design/icons'

const EditableCell = ({ child, OnFinishEditing }: { child: string; OnFinishEditing: Function }) => {
  const [editing, setEditing] = React.useState(false)
  const [value, setValue] = React.useState(child)

  const toggleEdit = () => {
    setEditing(!editing)
  }

  const save = () => {
    OnFinishEditing(value)
    toggleEdit()
  }

  return editing ? (
    <Input
      onBlur={save}
      onPressEnter={save}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      value={value}
      autoFocus
      size="small"
      style={{ margin: 0, maxWidth: 160 }}
    />
  ) : (
    <span onClick={toggleEdit} style={{ cursor: 'pointer' }}>
      {child}
      <EditFilled type="edit" style={{ color: '#1691ff' }} />
    </span>
  )
}

export default EditableCell
