import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  message,
  Modal,
  Button,
  Form,
  Input,
  Tooltip,
  Divider,
  Table,
  Empty,
  Checkbox,
  Row,
  Col,
  Tag,
  Select,
} from 'antd';
import * as authRequests from '@/requests/role';
import { formItemLayout, DataRule } from '../configs';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { PlusOutlined } from '@ant-design/icons';

const DataAuthInfoModal = ({
  dataAuth,
  setViewingDataAuth,
}: {
  dataAuth: DataAuthorityItem | undefined;
  setViewingDataAuth: Function;
}) => {
  if (!dataAuth) return null;
  const { create_time, data_authority_name, data_authority_id, asset_comps, v_asset_status, is_use, rules } = dataAuth;
  const assetRule = rules.find((x) => x.table === 'asset');
  const statusRule = rules.find((x) => x.table === 'status');
  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>权限详情
        </span>
      }
      okText="确认"
      cancelText={null}
      visible={!!dataAuth}
      onOk={() => setViewingDataAuth(undefined)}
      onCancel={() => setViewingDataAuth(undefined)}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="权限名称">
          <Input value={data_authority_name} disabled />
        </Form.Item>
        <Form.Item label="id">
          <Input value={data_authority_id} disabled />
        </Form.Item>
        <Form.Item label="资产组件">
          {asset_comps.map((x, index) => (
            <Tag color={'blue'} key={index}>
              {x}
            </Tag>
          ))}
        </Form.Item>
        <Form.Item label="地点信息">
          {v_asset_status.map((x, index) => (
            <Tag key={`v-status-${index}`}>{`${x.v_status_name}:${x.v_status_value}`}</Tag>
          ))}
        </Form.Item>
        <Form.Item label="状态">
          <Select value={is_use} disabled>
            <Select.Option value={1}>可用</Select.Option>
            <Select.Option value={0}>禁用</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="资产权限">
          <CheckboxGroup value={assetRule ? assetRule.type : []} disabled>
            <Row>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'r'}>读取</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'c'}>创建</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'u'}>更新</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'d'}>删除</Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        <Form.Item label="资产状态权限">
          <CheckboxGroup value={statusRule ? statusRule.type : []} disabled>
            <Row>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'r'}>读取</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'c'}>创建</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'u'}>更新</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'d'}>删除</Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        <Form.Item label="创建时间">
          <Input value={create_time} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const DataAuth = (props: RouteComponentProps) => {
  const [data, setData] = React.useState<DataAuthorityItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [createDataAuthModalVisible, setCreateDataAuthModalVisible] = React.useState(false);
  const [createDataAuthForm] = Form.useForm();
  const handleCreateDataAuthModalOK = async (values: any) => {
    doCreateDataAuth(values);
  };
  const handleCreateDataAuthCancel = () => {
    setCreateDataAuthModalVisible(false);
  };

  const [filterDataAuth, setFilterDataAuth] = React.useState('');
  const [editingDataAuth, setEditingDataAuth] = React.useState<DataAuthorityItem | undefined>(undefined);
  const [viewingDataAuth, setViewingDataAuth] = React.useState<DataAuthorityItem | undefined>(undefined);

  const [updateDataAuthModalVisible, setUpdateDataAuthModalVisible] = React.useState(false);
  const [updateDataAuthForm] = Form.useForm();
  const handleUpdateDataAuthModalOK = async (values: any) => {
    doUpdateDataAuth(values);
  };
  const handleUpdateDataAuthCancel = () => {
    setUpdateDataAuthModalVisible(false);
  };
  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true);

    if (!page) page = 1;
    if (!pageSize) pageSize = 10;
    const res = await authRequests.getDataAuthorityList({
      limit: pageSize,
      skip: pageSize * (page - 1),
      data_authority_name: filterDataAuth,
    });
    if (res.code === 0) {
      const vLengthList = res.data.map((x) => x.v_asset_status.length);
      const compsLengthList = res.data.map((x) => x.asset_comps.length);
      vLengthList.sort((a, b) => b - a);
      compsLengthList.sort((a, b) => b - a);
      setMaxVStatusCount(vLengthList[0]);
      setMaxCopmsCount(compsLengthList[0]);
      setPagination({
        ...pagination,
        total: res.total,
      });
      setData(res.data);
    } else {
      setData([]);
      message.error(res.message);
    }
    setLoading(false);
  };

  const doCreateDataAuth = async (values: any) => {
    setFetching(true);
    const hasAssetAuth = values['asset'].length > 0;
    const hasStatusAuth = values['status'].length > 0;
    let rule = [] as DataAuthorityRuleItem[];
    if (hasAssetAuth) {
      rule.push({ table: 'asset', type: values['asset'] });
    }
    if (hasStatusAuth) {
      rule.push({ table: 'status', type: values['status'] });
    }
    const res = await authRequests.createDataAuthorityItem({
      asset_comps: values['asset_comps'],
      data_authority_name: values['data_authority_name'],
      v_asset_status: values['v_asset_status'].map((x: string) => {
        return {
          v_status_name: x,
          v_status_value: values[x],
        };
      }),
      rules: rule,
    });
    if (res.code === 0) {
      message.success('创建成功');
      fetchData();
      setCreateDataAuthModalVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const doUpdateDataAuth = async (values: any) => {
    if (!editingDataAuth) return;
    setFetching(true);
    const hasAssetAuth = values['asset'].length > 0;
    const hasStatusAuth = values['status'].length > 0;
    let rule = [] as DataAuthorityRuleItem[];
    if (hasAssetAuth) {
      rule.push({ table: 'asset', type: values['asset'] });
    }
    if (hasStatusAuth) {
      rule.push({ table: 'status', type: values['status'] });
    }
    const res = await authRequests.updateDataAuthorityItem({
      data_authority_id: editingDataAuth.data_authority_id,
      asset_comps: values['asset_comps'],
      data_authority_name: values['data_authority_name'],
      v_asset_status: values['v_asset_status'].map((x: string) => {
        return {
          v_status_name: x,
          v_status_value: values[x],
        };
      }),
      rules: rule,
    });
    if (res.code === 0) {
      message.success('更新成功');
      fetchData();
      setUpdateDataAuthModalVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const colunms = [
    {
      title: '序号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'center',
      render: (text: any, record: DataAuthorityItem, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as 'center',
      render: (text: any, record: DataAuthorityItem, index: number) => `${record.data_authority_name}`,
    },
    ...Array(maxCompsCount)
      .fill(undefined)
      .map((comp, index) => {
        return {
          title: `资产组件${index + 1}`,
          key: `comps-${index}`,
          align: 'center' as 'center',
          render: (_: any, record: DataAuthorityItem, _index: number) => {
            const { asset_comps } = record;
            let value = asset_comps[index];
            return value ? value : '';
          },
        };
      }),
    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((vcomp, index) => {
        return {
          title: `地理组件${index + 1}`,
          key: `vcomps-${index}`,
          align: 'center' as 'center',
          render: (_: any, record: DataAuthorityItem, _index: number) => {
            const { v_asset_status } = record;
            let value = v_asset_status[index];
            return value ? `${value.v_status_name}:${value.v_status_value}` : '';
          },
        };
      }),
    {
      title: `资产权限`,
      key: `asset-rules`,
      align: 'center' as 'center',
      render: (_: any, record: DataAuthorityItem, _index: number) => {
        const { rules } = record;
        let value = rules.find((x) => x.table === 'asset');
        return value
          ? value.type.map((x: ruleTypes) => (
              <Tag color="blue" key={`asset-rule-${record.data_authority_id}-${x}`}>
                {DataRule[x]}
              </Tag>
            ))
          : '';
      },
    },
    {
      title: `资产状态权限`,
      key: `status-rules`,
      align: 'center' as 'center',
      render: (_: any, record: DataAuthorityItem, _index: number) => {
        const { rules } = record;
        let value = rules.find((x) => x.table === 'status');
        return value
          ? value.type.map((x: ruleTypes) => (
              <Tag color="blue" key={`status-rule-${record.data_authority_id}-${x}`}>
                {DataRule[x]}
              </Tag>
            ))
          : '';
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as 'center',
      render: (text: any, record: DataAuthorityItem, index: number) => (
        <span
          style={{
            color: record.is_use === 1 ? 'green' : 'red',
          }}
        >
          {record.is_use === 1 ? '启用' : '禁用'}
        </span>
      ),
    },

    {
      title: '操作',
      key: 'actions',
      align: 'center' as 'center',
      render: (text: any, record: DataAuthorityItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={(e) => {
                e.preventDefault();
                setViewingDataAuth(record);
              }}
            >
              详情
            </a>

            <Divider type="vertical" />
            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                setEditingDataAuth(record);
                setUpdateDataAuthModalVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />
            <Tooltip title="删除权限">
              <a
                key="delete"
                onClick={(e) => {
                  e.preventDefault();
                  Modal.confirm({
                    title: '提示',
                    content: '确认删除该权限吗？',
                    okText: '确认',
                    onOk: async () => {
                      const { data_authority_id } = record;
                      const res = await authRequests.deleteDataAuthorityItem({
                        data_authority_id,
                      });
                      if (res.code === 0) {
                        message.success('删除成功！');
                        fetchData();
                      } else {
                        message.error(res.message);
                      }
                    },
                    cancelText: '取消',
                    onCancel: () => {},
                  });
                }}
              >
                删除权限
              </a>
            </Tooltip>
          </span>
        );
      },
    },
  ];

  const CreateDataAuthForm = () => {
    const [selectedVomps, setSelectedVcomps] = React.useState<string[]>([]);
    return (
      <Form
        form={createDataAuthForm}
        preserve={false}
        {...formItemLayout}
        onFinish={handleCreateDataAuthModalOK}
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues)[0] === 'v_asset_status') {
            setSelectedVcomps(changedValues[Object.keys(changedValues)[0]]);
          }
        }}
      >
        <Form.Item
          label="权限名称"
          name="data_authority_name"
          rules={[{ required: true, message: '请输入权限名称！' }]}
        >
          <Input placeholder="请输入权限名称" />
        </Form.Item>
        <Form.Item label="资产组件" name="asset_comps" rules={[{ required: true, message: '请输入资产组件！' }]}>
          <Select mode="tags" placeholder="请输入资产组件" />
        </Form.Item>
        <Form.Item label="地理组件" name="v_asset_status" rules={[{ required: true, message: '请输入地理组件！' }]}>
          <Select mode="tags" placeholder="请输入地理组件"></Select>
        </Form.Item>
        {selectedVomps.map((v_comps: string, v_index: number) => {
          return (
            <Form.Item label={v_comps} key={v_index} name={v_comps}>
              <Input placeholder={`请输入${v_comps}值`} />
            </Form.Item>
          );
        })}
        <Form.Item label="资产权限" name="asset">
          <CheckboxGroup>
            <Row>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'r'}>读取</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'c'}>创建</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'u'}>更新</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'d'}>删除</Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        <Form.Item label="资产状态权限" name="status">
          <CheckboxGroup>
            <Row>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'r'}>读取</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'c'}>创建</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'u'}>更新</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'d'}>删除</Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCreateDataAuthCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const UpdateDataAuthForm = () => {
    const [selectedVomps, setSelectedVcomps] = React.useState<string[]>(
      editingDataAuth ? editingDataAuth.v_asset_status.map((x) => x.v_status_name) : []
    );
    if (!editingDataAuth) return null;
    const assetRule = editingDataAuth.rules.find((x) => x.table === 'asset');
    const statusRule = editingDataAuth.rules.find((x) => x.table === 'status');
    return (
      <Form
        form={updateDataAuthForm}
        preserve={false}
        {...formItemLayout}
        onFinish={handleUpdateDataAuthModalOK}
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues)[0] === 'v_asset_status') {
            setSelectedVcomps(changedValues[Object.keys(changedValues)[0]]);
          }
        }}
      >
        <Form.Item
          label="权限名称"
          name="data_authority_name"
          rules={[{ required: true, message: '请输入权限名称！' }]}
          initialValue={editingDataAuth.data_authority_name}
        >
          <Input placeholder="请输入权限名称" />
        </Form.Item>
        <Form.Item
          label="资产组件"
          name="asset_comps"
          rules={[{ required: true, message: '请输入资产组件！' }]}
          initialValue={editingDataAuth.asset_comps}
        >
          <Select mode="tags" placeholder="请输入资产组件" />
        </Form.Item>
        <Form.Item
          label="地理组件"
          name="v_asset_status"
          rules={[{ required: true, message: '请输入地理组件！' }]}
          initialValue={editingDataAuth.v_asset_status.map((x) => x.v_status_name)}
        >
          <Select mode="tags" placeholder="请输入地理组件"></Select>
        </Form.Item>
        {selectedVomps.map((v_comps: string, v_index: number) => {
          const value = editingDataAuth.v_asset_status.find((x) => x.v_status_name === v_comps);
          return (
            <Form.Item
              label={v_comps}
              key={v_index}
              name={v_comps}
              initialValue={value ? value.v_status_value : undefined}
            >
              <Input placeholder={`请输入${v_comps}值`} />
            </Form.Item>
          );
        })}
        <Form.Item label="资产权限" name="asset" initialValue={assetRule ? assetRule.type : []}>
          <CheckboxGroup>
            <Row>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'r'}>读取</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'c'}>创建</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'u'}>更新</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'d'}>删除</Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        <Form.Item label="资产状态权限" name="status" initialValue={statusRule ? statusRule.type : []}>
          <CheckboxGroup>
            <Row>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'r'}>读取</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'c'}>创建</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'u'}>更新</Checkbox>
              </Col>
              <Col span={8} style={{ padding: 10 }}>
                <Checkbox value={'d'}>删除</Checkbox>
              </Col>
            </Row>
          </CheckboxGroup>
        </Form.Item>
        <Form.Item noStyle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '36px 0 24px 0',
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleUpdateDataAuthCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="data-auth-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200, marginRight: 10 }}
            placeholder="搜索权限"
            value={filterDataAuth}
            onChange={(e) => setFilterDataAuth(e.target.value)}
          ></Input.Search>
        </div>

        <Button type="primary" icon={<PlusOutlined />} onClick={() => setCreateDataAuthModalVisible(true)}>
          创建权限
        </Button>
      </div>
      <div style={{ backgroundColor: '#ffffff' }}>
        <Table
          dataSource={data}
          columns={colunms}
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              setPagination({ ...pagination, page: page });
              fetchData(page, pagesize);
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            style: { marginRight: 16 },
          }}
          locale={{
            emptyText: (
              <Empty description="暂无权限，点击添加权限，创建第一条数据权限">
                <Button type="primary" onClick={() => setCreateDataAuthModalVisible(true)}>
                  创建权限
                </Button>
              </Empty>
            ),
          }}
          loading={loading}
          rowKey={(record) => record.data_authority_id.toString()}
        ></Table>
      </div>
      <DataAuthInfoModal dataAuth={viewingDataAuth} setViewingDataAuth={setViewingDataAuth} />
      {updateDataAuthModalVisible && (
        <Modal
          visible={updateDataAuthModalVisible}
          title={
            <span>
              <div className="title-prefix"></div>编辑权限
            </span>
          }
          destroyOnClose
          footer={null}
          onCancel={handleUpdateDataAuthCancel}
        >
          <UpdateDataAuthForm />
        </Modal>
      )}

      {createDataAuthModalVisible && (
        <Modal
          visible={createDataAuthModalVisible}
          title={
            <span>
              <div className="title-prefix"></div>创建权限
            </span>
          }
          footer={null}
          onCancel={handleCreateDataAuthCancel}
          destroyOnClose
        >
          <CreateDataAuthForm />
        </Modal>
      )}
    </div>
  );
};

export default DataAuth;
