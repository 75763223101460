import * as React from "react";
import { Select } from "antd";

const { Option } = Select;
const selectAfterXLSX = (
  <Select defaultValue=".xlsx" style={{ width: 80 }}>
    <Option value=".xlsx">.xlsx</Option>
  </Select>
);
const selectAfterPDF = (
  <Select defaultValue=".pdf" style={{ width: 80 }}>
    <Option value=".pdf">.pdf</Option>
  </Select>
);

const selectAfterCSV = (
  <Select defaultValue=".csv" style={{ width: 80 }}>
    <Option value=".csv">.csv</Option>
  </Select>
);

export { selectAfterXLSX, selectAfterPDF, selectAfterCSV };
