import * as React from 'react';
import { Upload, message, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

interface Props {
  action: string;
  value?: UploadFile<FileListItem>[];
  onChange?: (value: UploadFile<FileListItem>[]) => void;
  data?: any;
}

interface FileListItem {
  uid: string;
  name: string;
  status: string;
  percent: number;
  url: string;
  data: string;
}
function getBase64(file: any) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只支持上传 JPG/PNG 文件!');
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    message.error('图片大小不能超过1MB!');
  }
  return isJpgOrPng && isLt1M;
}

const SinglePicUpload: React.FC<Props> = (props: Props) => {
  const { action, value, onChange, data } = props;

  const [fileList, setUploadList] = React.useState<UploadFile<FileListItem>[]>([]);

  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState<string | undefined>('');
  const [previewTitle, setPreviewTitle] = React.useState('');
  const triggerChange = (changedValue: UploadFile<any>[]) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handlePreview = async (file: UploadFile<any>) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handleChange = (data: any) => {
    setUploadList(data.fileList);
    triggerChange(data.fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>点击上传</div>
    </div>
  );

  return (
    <>
      <Upload
        action={action}
        listType="picture-card"
        data={data}
        fileList={fileList || value}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default SinglePicUpload;
