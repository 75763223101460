import * as React from 'react'
import { Table, message, Button } from 'antd'
import * as BIRequests from '@/requests/bi'

const BIDataTableComponent = ({ req_data, type }: { req_data: BIReq; type: string }) => {
  const [data, setData] = React.useState<BIRes[]>([])
  const [fetching, setFetching] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 8,
    total: 0,
  })

  const fetchData = async () => {
    if (!req_data || !type) {
      return
    }
    setFetching(true)
    const res =
      type === '0'
        ? await BIRequests.getBIdatasFlagByTime(req_data as BIReq)
        : await BIRequests.getBIdatasFlagByVStatus(req_data as BIReq)
    if (res.code === 0) {
      if (res.data.length < 1) {
        message.info('无数据')
      }
      setData(res.data)
      setPagination({ ...pagination, total: res.data.length })
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }
  return data.length > 0 ? (
    <Table
      style={{ maxHeight: 400, overflow: 'scroll' }}
      size="small"
      bordered={false}
      loading={fetching}
      dataSource={data}
      pagination={{
        ...pagination,
        showSizeChanger: false,
        showQuickJumper: true,
      }}
      columns={[
        {
          align: 'center' as 'center',
          dataIndex: 'asset_status_name',
          key: 'asset_status_name',
          title: '资产状态名',
        },
        {
          align: 'center' as 'center',
          dataIndex: 'asset_status_value',
          key: 'asset_status_value',
          title: '资产状态值',
        },

        {
          align: 'center' as 'center',
          dataIndex: req_data.v_asset_flag ? 'v_asset_status_value' : 'time',
          key: 'v_asset_status_value',
          title: req_data.v_asset_flag ? req_data.v_asset_flag.v_status_name : '时间',
        },
      ]}
    />
  ) : (
    <div style={{ width: '100%', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button type="primary" onClick={fetchData}>
        查看数据
      </Button>
    </div>
  )
}

export default BIDataTableComponent
