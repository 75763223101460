import * as React from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import AnnotationOperation from '@labelbee/lb-components';
import '@labelbee/lb-components/dist/index.css';
import { useHistory, useLocation } from 'react-router-dom';
import { message, Modal, Breadcrumb } from 'antd';
import { FullscreenOutlined, FullscreenExitOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  saveLabelResult,
  getLabelProjectByUid,
  getLabelTaskByUid,
  getLabelTaskListByUid,
  updateLabelTask,
} from '@/requests/labelProject';
import { useCtxState } from '@/App.store';
import '../styles/labelPage.less';
import { dropByCacheKey, getCachingKeys } from 'react-router-cache-route';
import { Switch } from 'antd';

interface ImgItem {
  id: string;
  url: string;
  result: string;
}

//标注类型
enum LabelType {
  'lineTool' = 1, //多线段
  'polygonTool' = 2, //多边形
}

interface LabelResult {
  id: string;
  result: string;
  url: string;
}

const polygonConfigString = JSON.stringify({
  lowerLimitPointNum: 3,
  edgeAdsorption: true,
  drawOutsideTarget: false,
  copyBackwardResult: false,
  attributeConfigurable: true,
  attributeList: [],
  textConfigurable: true,
  textCheckType: 0,
  customFormat: '',
  isShowOrder: false,
  lineType: 0,
  lineColor: 0,
});

const lineToolConfigString = JSON.stringify({
  lineType: 0,
  attributeList: [],
  attributeConfigurable: true,
  textConfigurable: true,
});

const LabelPage = () => {
  const [imgList, setImgList] = React.useState<ImgItem[]>([]);
  const [{ cachedRoutes }, dispatch] = useCtxState();
  const handle = useFullScreenHandle();
  const history = useHistory();
  const location = useLocation();
  // const [taskList, setTaskList] = React.useState<LabelTaskItem[]>([]);
  const [stepList, setStepList] = React.useState([
    {
      step: 1, //  当前步骤
      dataSourceStep: 0, // 当前依赖步骤，若为原图则为 0
      tool: 'polygonTool', // 具体查询下方工具列表指定工具
      config: polygonConfigString,
    },
  ]);
  // const [imgIndex, setImgIndex] = React.useState(0);
  const [isFull, setIsFull] = React.useState(false);
  const [task, setTask] = React.useState<LabelTaskItem>();
  const [project, setProject] = React.useState<LabelProjectListItem>();
  const [fetching, setFetching] = React.useState(false);

  // 保存标注结果
  const daSave = async (data: string, taskID: string) => {
    const res = await saveLabelResult({
      task_uid: taskID,
      label_message: data,
    });
    if (res.code === 0) {
      message.success('保存成功');
    } else {
      message.error(res.message);
    }
  };
  // //初始化任务图片及项目数据
  // const fetchData = async (task_uid: LabelTaskItem['task_uid'], project_uid: LabelProjectListItem['project_uid']) => {
  //   setProject(undefined);
  //   setTask(undefined);
  //   const projectRes = await getLabelProjectByUid(project_uid);
  //   if (projectRes.code === 0) {
  //     const taskRes = await getLabelTaskListByUid({
  //       limit: 10000000,
  //       skip: 0,
  //       project_uid: project_uid,
  //     });

  //     if (taskRes.code === 0) {
  //       let _task = taskRes.data.find((x) => x.task_uid === task_uid);
  //       if (!_task) {
  //         message.error('该任务不存在');
  //       }
  //       const _index = taskRes.data.findIndex((x) => x.task_uid === task_uid);
  //       let _project = projectRes.data;
  //       if (_project.label_type === 2) {
  //         setStepList(
  //           stepList.map((x, index) => {
  //             return {
  //               ...x,
  //               tool: LabelType[2],
  //               config: JSON.stringify({
  //                 ...JSON.parse(polygonConfigString),
  //                 attributeList: _project.label_tag_list,
  //                 lineType: _project.line_type === '直线' ? 0 : 1,
  //               }),
  //             };
  //           })
  //         );
  //       }
  //       if (_project.label_type === 1) {
  //         setStepList(
  //           stepList.map((x, index) => {
  //             return {
  //               ...x,
  //               tool: LabelType[1],
  //               config: JSON.stringify({
  //                 ...JSON.parse(lineToolConfigString),
  //                 attributeList: _project.label_tag_list,
  //                 lineType: _project.line_type === '直线' ? 0 : 1,
  //               }),
  //             };
  //           })
  //         );
  //       }

  //       setImgList(
  //         taskRes.data.map((_task) => {
  //           return {
  //             id: _task.task_uid,
  //             url: _task.image_url,
  //             result: _task.label_message ? _task.label_message : '',
  //           };
  //         })
  //       );

  //       setImgIndex(_index);
  //       setTaskList(taskRes.data);
  //       setTask(_task);
  //       setProject(_project);
  //     }
  //   } else if (projectRes.code !== 0) {
  //     message.error(projectRes.message);
  //   } else {
  //     message.error('网络错误');
  //   }
  // };
  //初始化任务图片及项目数据
  const fetchData = async (task_uid: LabelTaskItem['task_uid'], project_uid: LabelProjectListItem['project_uid']) => {
    setProject(undefined);
    setTask(undefined);
    const projectRes = await getLabelProjectByUid(project_uid);
    if (projectRes.code === 0) {
      const taskRes = await getLabelTaskByUid(task_uid);

      if (taskRes.code === 0) {
        let _task = taskRes.data;
        if (!_task) {
          message.error('该任务不存在');
        }
        let _project = projectRes.data;
        if (_project.label_type === 2) {
          setStepList(
            stepList.map((x, index) => {
              return {
                ...x,
                tool: LabelType[2],
                config: JSON.stringify({
                  ...JSON.parse(polygonConfigString),
                  attributeList: _project.label_tag_list,
                  lineType: _project.line_type === '直线' ? 0 : 1,
                }),
              };
            })
          );
        }
        if (_project.label_type === 1) {
          setStepList(
            stepList.map((x, index) => {
              return {
                ...x,
                tool: LabelType[1],
                config: JSON.stringify({
                  ...JSON.parse(lineToolConfigString),
                  attributeList: _project.label_tag_list,
                  lineType: _project.line_type === '直线' ? 0 : 1,
                }),
              };
            })
          );
        }

        setImgList([
          {
            id: _task.task_uid,
            url: _task.image_url,
            result: _task.label_message ? _task.label_message : '',
          },
        ]);

        setTask(_task);
        setProject(_project);
      }
    } else if (projectRes.code !== 0) {
      message.error(projectRes.message);
    } else {
      message.error('网络错误');
    }
  };

  // React.useEffect(() => {
  //   const _task = taskList[imgIndex];
  //   if (_task) {
  //     setTask(_task);
  //   }
  // }, [imgIndex]);

  /**
   * 监听数据提交操作： 翻页
   * @param {IFileItem[]} data 当前提交的数据
   * @param {ESubmitType} submitType 触发 onSubmit 的方向判断
   * @param {number} imgIndex 提交结果的图片下标
   */
  //保存标注结果
  const onSave = (data: LabelResult, submitType: any, _imgIndex: number) => {
    daSave(data.result, data.id);
  };

  //更新任务的状态
  const updateLabelTaskStatus = async (task_uid: string, status: number) => {
    if (!task) return;
    setFetching(true);
    const res = await updateLabelTask({
      task_uid: task_uid,
      label_status: status,
    });
    if (res.code === 0) {
      message.success('更新成功');
      // const newRes = await getLabelTaskByUid(task_uid);
      if (res.code === 0) {
        setTask({
          ...task,
          label_status: status,
        });
      } else {
        message.error(res.message);
      }
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  /**
   * 翻页的回调
   * @param nextIndex
   */
  // const onPageChange = (nextIndex: number) => {
  //   setImgIndex(nextIndex);
  // };

  //返回上一页
  const goBack = () => {
    Modal.confirm({
      title: '提示',
      content: '未保存的工作将会丢失，确认退出标注吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        if (cachedRoutes.length < 2) {
          message.error('已经是最后一个窗口');
          return;
        }
        let arr = cachedRoutes.filter((x: string) => x !== '/workbench/label/label-task/label-page');
        dispatch({
          type: 'SAVE_CACHE_ROUTES',
          cachedRoutes: arr,
        });
        history.goBack();
        dropByCacheKey('/workbench/label/label-task/label-page');
      },
    });
  };
  const header = ({
    backNode,
    headerNameNode,
    stepListNode,
    headerOptionNode,
    langNode,
  }: {
    backNode: React.ReactNode;
    headerNameNode: React.ReactNode;
    stepListNode: React.ReactNode;
    headerOptionNode: React.ReactNode;
    langNode: React.ReactNode;
  }): React.ReactNode => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {backNode}{' '}
          {project && task && (
            <Breadcrumb>
              <Breadcrumb.Item>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({ pathname: '/workbench/label/project-list' });
                  }}
                >
                  标注项目
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: '/workbench/label/project-list/task-list',
                      search: `id=${project.project_uid}`,
                    });
                  }}
                >
                  {project.project_name}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{task.image_name}</Breadcrumb.Item>
            </Breadcrumb>
          )}
        </div>
        {headerNameNode}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {task && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 40.8,
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#cccccc',
              }}
              className="header-option-container"
            >
              <Switch
                checked={task.label_status === 1 ? true : false}
                size="small"
                loading={fetching}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(e) => {
                  updateLabelTaskStatus(task.task_uid, e ? 1 : 0);
                }}
              ></Switch>
              <span style={{ fontSize: 12 }}>{task.label_status === 1 ? '已完成' : '未完成'}</span>
            </div>
          )}
          {headerOptionNode}
          {!isFull && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 40.8,
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#cccccc',
              }}
              className="header-option-container"
              onClick={() => {
                handle.enter();
                setIsFull(true);
              }}
            >
              <FullscreenOutlined style={{ fontSize: 20, margin: '0 21px', color: 'grey' }}></FullscreenOutlined>
              <span style={{ fontSize: 12 }}>全屏</span>
            </div>
          )}
          {isFull && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 40.8,
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#cccccc',
              }}
              className="header-option-container"
              onClick={() => {
                handle.exit();
                setIsFull(false);
              }}
            >
              <FullscreenExitOutlined
                style={{ fontSize: 20, margin: '0 21px', color: 'grey' }}
              ></FullscreenExitOutlined>
              <span style={{ fontSize: 12 }}>退出全屏</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const sider = ({
    toolIcon,
    attributeList,
    annotationText,
    toolStyle,
    imageAttributeInfo,
    operation,
    tagToolSideBar,
    textToolSideBar,
    horizontal,
  }: {
    toolIcon: React.ReactNode;
    attributeList: React.ReactNode;
    annotationText: React.ReactNode;
    toolStyle: React.ReactNode;
    imageAttributeInfo: React.ReactNode;
    operation: React.ReactNode;
    tagToolSideBar: React.ReactNode;
    textToolSideBar: React.ReactNode;
    horizontal: React.ReactNode;
  }): React.ReactNode => {
    return (
      <>
        <div> {toolIcon}</div>
        <div>
          {attributeList} {annotationText}
        </div>
        <div style={{ overflow: 'auto' }}>
          {toolStyle}
          {imageAttributeInfo}
        </div>

        {operation}
      </>
    );
  };

  const footer = ({
    footerTips,
    hiddenTips,
    pageNumber,
    pagination,
    zoomController,
    curItems,
    footerDivider,
  }: {
    footerTips: React.ReactNode;
    hiddenTips: React.ReactNode;
    pageNumber: React.ReactNode;
    pagination: React.ReactNode;
    zoomController: React.ReactNode;
    curItems: React.ReactNode;
    footerDivider: React.ReactNode;
  }): React.ReactNode => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        {footerTips}

        <div style={{ display: 'flex', alignItems: 'center' }}>{zoomController}</div>
      </div>
    );
  };

  React.useEffect(() => {
    if (location.pathname === '/workbench/label/label-task/label-page') {
      if (location.search) {
        let task_uid = location.search.split('=')[1].split('&')[0];
        let project_uid = location.search.split('=')[2];
        fetchData(task_uid, project_uid);
      }
    }
  }, [location]);

  if (!task || !project) {
    return (
      <AnnotationOperation
        imgList={[]}
        stepList={stepList}
        header={header}
        sider={sider}
        goBack={goBack}
        // initialIndex={imgIndex}
        exportData
        onSave={onSave}
        className="label-page"
        style={{
          header: {
            width: '100%',
          },
          sider: {},
          footer: {},
        }}
        footer={footer}
      />
    );
  }

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <FullScreen handle={handle}>
        {
          <AnnotationOperation
            imgList={imgList}
            stepList={stepList}
            header={header}
            sider={sider}
            goBack={goBack}
            // initialIndex={imgIndex}
            exportData
            // onPageChange={onPageChange}
            onSave={onSave}
            headerName={project && task ? `${project.project_name}-${task.image_name}` : ''}
            className={isFull ? '' : 'label-page'}
            style={{
              header: {
                width: '100%',
              },
              sider: {},
              footer: {},
            }}
            footer={footer}
          />
        }
      </FullScreen>
    </div>
  );
};

export default LabelPage;
