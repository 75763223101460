import { emit } from "process";

export const authIcons = [
  "plus",
  "close",
  "stop",
  "edit",
  "form",
  "copy",
  "delete",
  "snippets",
  "diff",
  "align-left",
  "redo",
  "line-chart",
  "bar-chart",
  "eye",
  "home",
  "lock",
  "like",
  "phone",
  "tool",
  "user",
  "user-add",
];

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export enum DataRule {
  "r" = "读取",
  "c" = "创建",
  "u" = "修改",
  "d" = "删除",
}
