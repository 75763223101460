/// <reference path="type.d.ts" />
/// <reference path="labelProject.d.ts" />

import request from './_base';

/**
 * 获取所有项目列表
 * @param  {GetLabelProjectListReq} input
 */
export async function getAllLabelProjectList(
  input: GetLabelProjectListReq
): Promise<AssetsResourceProto<{ data: LabelProjectListItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/labelProject/list`,
    data: input,
  }) as any;
}

/**
 * 创建项目
 * @param  {CreateLabelProjectReq} input
 */
export async function createLabelProject(
  input: CreateLabelProjectReq
): Promise<AssetsResourceProto<{ data: LabelProjectListItem }>> {
  return request({
    method: 'POST',
    url: `/labelProject/new`,
    data: input,
  }) as any;
}

/**
 * 删除项目
 * @param  {LabelProjectListItem['project_uid']} id
 */
export async function deleteLabelProject(
  id: LabelProjectListItem['project_uid']
): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/labelProject/delete/${id}`,
  }) as any;
}

/**
 * 收藏项目
 * @param  {LabelProjectListItem['project_uid']} id
 */
export async function collectLabelProject(
  id: LabelProjectListItem['project_uid']
): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'GET',
    url: `/labelProject/favorite/${id}`,
  }) as any;
}

/**
 * 取消收藏项目
 * @param  {LabelProjectListItem['project_uid']} id
 */
export async function uncollectLabelProject(
  id: LabelProjectListItem['project_uid']
): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/labelProject/unfavorite/${id}`,
  }) as any;
}

/**
 * 更新项目
 * @param  {UpdateLabelPeojectReq} input
 */
export async function updateLabelProject(input: UpdateLabelProjectReq): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'PUT',
    url: `/labelProject/update`,
    data: input,
  }) as any;
}

/**
 * 根据项目uid创建任务
 * @param  {FormData} formData
 */
export async function createLabelTask(formData: FormData): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'POST',
    url: `/labelTask/new`,
    data: formData,
  }) as any;
}

/**
 * 根据项目uid查询任务list
 * @param  {GetLabelTaskListReq} input
 */
export async function getLabelTaskListByUid(
  input: GetLabelTaskListReq
): Promise<AssetsResourceProto<{ data: LabelTaskItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/labelTask/list`,
    data: input,
  }) as any;
}

/**
 * 设置任务的负责人
 * @param  {string} task_uid
 * * @param  {number} user_id
 */
export async function setLabelTaskWorker(
  task_uid: string,
  user_id: number
): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'GET',
    url: `/labelTask/principal/${task_uid}/${user_id}`,
  }) as any;
}

/**
 * 删除任务
 * @param  {string} id
 */
export async function deleteLabelTask(id: string): Promise<AssetsResourceProto<{ data: null }>> {
  return request({
    method: 'DELETE',
    url: `/labelTask/delete/${id}`,
  }) as any;
}

/**
 * 保存标注信息
 * @param  {SaveLabelResultReq} input
 */
export async function saveLabelResult(
  input: SaveLabelResultReq
): Promise<AssetsResourceProto<{ data: LabelTaskItem }>> {
  return request({
    method: 'PUT',
    url: `/labelTask/update/`,
    data: input,
  }) as any;
}

/**
 * 根据project_uid查询单个标注项目
 * @param  {LabelProjectListItem['project_uid']} uid
 */
export async function getLabelProjectByUid(
  uid: LabelProjectListItem['project_uid']
): Promise<AssetsResourceProto<{ data: LabelProjectListItem }>> {
  return request({
    method: 'GET',
    url: `/labelProject/one/${uid}`,
  }) as any;
}

/**
 * 根据task_uid查询单个标注任务
 * @param  {LabelTaskItem['task_uid']} uid
 */
export async function getLabelTaskByUid(
  uid: LabelTaskItem['task_uid']
): Promise<AssetsResourceProto<{ data: LabelTaskItem }>> {
  return request({
    method: 'GET',
    url: `/labelTask/one/${uid}`,
  }) as any;
}

/**
 * 下载项目标注结果
 * @param  {DownloadLabelResultReq} input
 */
export async function downloadLabelResult(input: DownloadLabelResultReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/labelTask/download`,
    data: input,
  }) as any;
}

/**
 * 获取登录人的待办标注任务
 */
export async function getMyTodoLabelTaskList(): Promise<AssetsResourceProto<{ data: PersonalLabelTasks }>> {
  return request({
    method: 'GET',
    url: `/labelProject/statisticsByCurrentlyUser`,
  }) as any;
}

/**
 * 标注任务高级筛选
 * @param  {GetTasksByConditionReq} input
 */
export async function getTasksByCondition(
  input: GetTasksByConditionReq
): Promise<AssetsResourceProto<{ data: LabelTaskItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/labelTask/listByCondition`,
    data: input,
  }) as any;
}

/**
 * 更新任务的状态值及标注信息
 * @param  {UpdateLabelTaskReq} input
 */
export async function updateLabelTask(
  input: UpdateLabelTaskReq
): Promise<AssetsResourceProto<{ data: LabelTaskItem }>> {
  return request({
    method: 'PUT',
    url: `/labelTask/update`,
    data: input,
  }) as any;
}

/**
 * 获取项目下的成员列表
 * @param  {string} project_id
 */
export async function getPrincipalUserIds(project_id: string): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'GET',
    url: `/labelProject/principalUserIds/${project_id}`,
  }) as any;
}

/**
 * 从其他项目导入任务并分配标注人
 * @param  {CopyLabelTasksReq} input
 */
export async function copyLabelTasks(input: CopyLabelTasksReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/labelProject/copyLabelTasks`,
    data: input,
  }) as any;
}
