import * as React from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { PageHeader, Button, message, Modal } from 'antd'
import * as BIRequests from '@/requests/bi'
import { Responsive, WidthProvider } from 'react-grid-layout'
import CustomPreviewChart from './CoustomPreviewChart'
import { ImportOutlined, LeftOutlined } from '@ant-design/icons'
const ResponsiveGridLayout = WidthProvider(Responsive)

const PreviewConfig = (props: RouteComponentProps) => {
  const location = useLocation<any>()
  let param = location.state
  const [config, setConfig] = React.useState<GetImportableConfigListRes | null>(param ? param.config : null)
  const [layout, setLayout] = React.useState<any>()
  const fetchLayout = async () => {
    if (!config) return
    const res = await BIRequests.getShareLayout({
      config_id: config.config_id,
    })
    if (res.code === 0) {
      setLayout(JSON.parse(res.data.config).layout)
    } else {
      message.error(res.message)
    }
  }
  React.useEffect(() => {
    fetchLayout()
  }, [])
  return (
    <div>
      <PageHeader
        title={null}
        ghost={true}
        style={{ padding: 0 }}
        extra={[
          <Button
            key="add-chart"
            type="primary"
            icon={<ImportOutlined />}
            onClick={() => {
              Modal.confirm({
                title: '提示',
                content: '确定导入改配置吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                  Modal.confirm({
                    title: '提示',
                    content: '是否覆盖原有配置？',
                    okText: '覆盖',
                    cancelText: '不覆盖',
                    onOk: async () => {
                      if (!config) return
                      message.loading('正在导入', 1)
                      const res = await BIRequests.importConig({
                        bi_config_share_uid: config.bi_config_share_uid,
                        over_write: true,
                      })
                      if (res.code === 0) {
                        message.success('导入成功')

                        props.history.replace('/base/chart-management/hi')
                      } else {
                        message.error(res.message)
                      }
                    },
                    onCancel: async () => {
                      if (!config) return
                      message.loading('正在导入', 1)
                      const res = await BIRequests.importConig({
                        bi_config_share_uid: config.bi_config_share_uid,
                        over_write: false,
                      })
                      if (res.code === 0) {
                        message.success('导入成功')

                        props.history.replace('/base/chart-management/hi')
                      } else {
                        message.error(res.message)
                      }
                    },
                  })
                },
              })
            }}
          >
            导入
          </Button>,

          <Button
            key="import-chart-config"
            type="primary"
            icon={<LeftOutlined />}
            onClick={() => props.history.goBack()}
          >
            返回
          </Button>,
        ]}
      ></PageHeader>
      <div
        className="charts-container"
        style={{
          margin: 10,
        }}
      >
        <ResponsiveGridLayout
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 3 }}
          isResizable={false}
          isDraggable={false}
          layouts={layout}
          draggableCancel=".chart-el"
        >
          {config &&
            config.bi_config.charts.map((chart, index) => {
              const commonGrid = { x: 0, y: 0, w: 3, h: 2, minW: 3, maxW: 12 }
              const grid = JSON.parse(chart.echart_config.grid)
              return (
                <div
                  key={index}
                  data-grid={{ ...commonGrid, x: grid.x, y: grid.y || 0 }}
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                    backgroundColor: '#ffffff',
                    overflow: 'hidden',
                    border: '1px solid #F0F0F0',
                  }}
                >
                  <CustomPreviewChart chart={chart} />
                </div>
              )
            })}
        </ResponsiveGridLayout>
      </div>
    </div>
  )
}

export default PreviewConfig
