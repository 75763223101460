import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Steps, Button, PageHeader, message } from 'antd'
import CreateFreeTemplateStepOne from '../components/CreateFreeFormTemplateStepOne'
import CreateTemplateStepTwo from '../components/CreateFormTemplateComplete'
import * as FormRequests from '@/requests/form'
const { Step } = Steps

const steps = [
  { title: '表单信息', content: '0' },
  { title: '完成创建', content: '1' },
]

const CreateFreeFormTemplate = (props: RouteComponentProps) => {
  const [current, setCurrent] = React.useState(0)
  const [fetching, setFetching] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [formUid, setFormUid] = React.useState('')

  const docreateFormTemplate = async (input: CreateFormTemplateReq) => {
    setFetching(true)
    const res = await FormRequests.createFormTemplate(input)
    if (res.code === 0) {
      message.success('创建成功')
      setFormUid(res.data.uid)
    } else {
      message.error(res.message)
      setIsError(true)
    }
    setFetching(false)
  }
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <PageHeader onBack={() => props.history.goBack()} title="创建自由表单" style={{ padding: 5, width: '100%' }} />
      <Steps current={current} style={{ padding: 20, maxWidth: 600 }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title}></Step>
        ))}
      </Steps>
      <div
        className="create-form-content"
        style={{
          minHeight: 700,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          <div style={{ display: current === 0 ? 'block' : 'none' }}>
            <CreateFreeTemplateStepOne setCurrent={setCurrent} docreateFormTemplate={docreateFormTemplate} />
          </div>
        }
        {
          <div style={{ display: current === 1 ? 'block' : 'none' }}>
            <CreateTemplateStepTwo {...props} fetching={fetching} isError={isError} formUid={formUid} />
          </div>
        }
      </div>
    </div>
  )
}

export default CreateFreeFormTemplate
