import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getDetectRecordsOfModel,
  getDetectedUsers,
  checkDetectResult,
  getBindAssetStatus,
  deleteDetectProject,
} from '@/requests/AIDetection';
import {
  message,
  Button,
  Form,
  Input,
  List,
  Tooltip,
  DatePicker,
  Popover,
  Row,
  Col,
  Image,
  Empty,
  Select,
  Avatar,
  Modal,
  Radio,
  Divider,
  Table,
  Tag,
  notification,
} from 'antd';
import {
  HistoryOutlined,
  SearchOutlined,
  PictureOutlined,
  OrderedListOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { download } from '@/utils/string';
import { useCtxState } from '@/App.store';
import '../styles/detectRecord.less';

interface CheckModalProps {
  visible: boolean;
  images: AiDetectDataDtolistItem[];
  setVisible: Function;
  fetchData: Function;
}

const CheckModal = (props: CheckModalProps) => {
  const { visible, images, setVisible, fetchData } = props;
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = async (values: any) => {
    console.log(values);
    setLoading(true);
    const res = await checkDetectResult({
      ai_detect_data_list: images.map((item) => {
        return {
          id: item.id,
          inaccurate_detection: values[`inaccurate_detection-${item.image_uid}`],
          inaccurate_detection_remark: values[`inaccurate_detection_remark${item.image_uid}`],
        };
      }),
    });
    if (res.code === 0) {
      fetchData();
      message.success('提交成功');
      setVisible(false);
    } else {
      message.error(res.message);
    }
  };

  return (
    <Modal
      visible={visible}
      title={'结果校验'}
      bodyStyle={{
        maxHeight: 700,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      onCancel={handleCancel}
      onOk={() => {
        Modal.confirm({
          title: '确认提交',
          content: '确认提交校验结果？',
          onOk: () => {
            form.submit();
          },
        });
      }}
      okButtonProps={{ loading: loading }}
      okText="提交"
    >
      <div>
        <Form form={form} style={{}} onFinish={handleOk}>
          {images.map((item, index) => {
            return (
              <div key={item.image_uid}>
                <Divider> {item.image_name}</Divider>
                <Form.Item
                  name={`inaccurate_detection-${item.image_uid}`}
                  initialValue={item.inaccurate_detection ? item.inaccurate_detection : '准确'}
                  rules={[{ required: true, message: '请选择准确度' }]}
                >
                  <Radio.Group>
                    <Radio value={'准确'}>准确</Radio>
                    <Radio value={'不准确'}>不准确</Radio>
                  </Radio.Group>
                </Form.Item>
                <Image src={item.detected_image_url ? item.detected_image_url : item.image_url}></Image>
                <Form.Item
                  name={`inaccurate_detection_remark${item.image_uid}`}
                  initialValue={item.inaccurate_detection_remark ? item.inaccurate_detection_remark : ''}
                >
                  <Input.TextArea placeholder="备注" />
                </Form.Item>
              </div>
            );
          })}
        </Form>
      </div>
    </Modal>
  );
};

const DetectRecords = () => {
  const [data, setData] = React.useState<DetectRecordsItem[]>([]);
  const [modelID, setModelID] = React.useState<string>('');
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 7,
    total: 0,
  });

  const history = useHistory();
  const location = useLocation();
  const [userOptions, setUserOptions] = React.useState<UserInfoItem[]>([]);
  const [filterUserId, setFilterUserId] = React.useState<number>();
  const [filterTime, setFilterTime] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [checkVisible, setCheckVisible] = React.useState(false);
  const [checkImages, setCheckImages] = React.useState<AiDetectDataDtolistItem[]>([]);
  const [assetStatus, setAssetStatus] = React.useState<DetectAssetStatus[]>([]);
  const [statusLoading, setStatusLoading] = React.useState(false);
  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const [downloadVisible, setDownloadVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [record, setRecord] = React.useState<DetectRecordsItem>();
  const [{ userInfo }] = useCtxState();

  React.useEffect(() => {
    if (location.pathname === '/workbench/ai/model-list/detect-records') {
      if (location.state) {
        let state = location.state as any;
        setModelID(state.model_id);
      } else {
        if (location.search) {
          let search = location.search as any;
          let model_id = search.split('=')[1];
          setModelID(model_id);
        }
      }
    }
  }, [location]);

  //下载检测报告
  const downloadDetectReport = async (id: number, name: string) => {
    message.loading('正在获取检测报告');
    setTimeout(() => {
      download(`/v2/api/aiDetectProject/downloadDetectPdf/${id}`, name);
      notification.open({
        message: '下载成功',
        description: `已成功下载${name}.pdf`,
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
      });
      message.destroy();
    }, 2000);
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getDetectRecordsOfModel({
      model_id: modelID,
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterUserId && { create_user_id: filterUserId }),
      ...(filterTime && { create_time_begin: filterTime[0], create_time_end: filterTime[1] }),
    });
    if (res.code === 0) {
      setPagination({
        current: page,
        pageSize: pageSize,
        total: res.total,
      });
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const fetchUsers = async () => {
    if (!modelID) {
      return;
    }
    const res = await getDetectedUsers(modelID);
    if (res.code === 0) {
      setUserOptions(res.data);
    } else {
      message.error(res.message);
    }
  };

  const fetchAssetStatus = async (id: number) => {
    setStatusLoading(true);
    const res = await getBindAssetStatus(id);
    if (res.code === 0) {
      const { data } = res;
      setAssetStatus(res.data);
      const vLengthList = data.map((x) => Object.keys(x.v_asset_status).length);
      const compsLengthList = data.map((x) => x.asset_comps.length);
      vLengthList.sort((a, b) => b - a);
      compsLengthList.sort((a, b) => b - a);
      setMaxVStatusCount(vLengthList[0]);
      setMaxCopmsCount(compsLengthList[0]);
    } else {
      message.error(res.message);
    }
    setStatusLoading(false);
  };

  const DetectStatusTag = (props: { status: string }) => {
    const { status } = props;
    if (!status) {
      return null;
    }
    let color = '';
    switch (status) {
      case '检测中':
        color = '#FFB800';
        break;
      case '检测成功':
        color = 'green';
        break;
      case '检测失败':
        color = '#FF4D4F';
        break;
      default:
        color = '#FFB800';
        break;
    }
    return (
      <Tag color={color} style={{ marginRight: 0 }}>
        {status}
      </Tag>
    );
  };

  //删除检测记录
  const deleteDetectRecord = async (id: number) => {
    const res = await deleteDetectProject(id);
    if (res.code === 0) {
      message.success('删除成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  React.useEffect(() => {
    if (modelID) {
      fetchData();
    }
  }, [modelID]);
  const ReportNameModal = () => {
    if (!record) return null;
    return (
      <Modal
        visible={downloadVisible}
        title={'填写检测项目名称'}
        destroyOnClose={true}
        width={500}
        onCancel={() => {
          setDownloadVisible(false);
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={async (values: any) => {
            console.log(values);
            setDownloadVisible(false);
            downloadDetectReport(record.id, values['report_name']);
          }}
        >
          <Form.Item
            initialValue={`${userInfo.username}${userInfo.company_user_id}_${moment().format(
              'YYYYMMDDhhmm'
            )}_${record.detect_type_list_str?.replace(',', '_')}报告`}
            name="report_name"
            rules={[
              {
                //校验项目名称是否除中文、英文、数字、下划线外的字符
                validator: (rule, value, callback) => {
                  if (!value.match(/^[a-zA-Z0-9_\u4e00-\u9fa5]+$/g)) {
                    callback('项目名称只能包含中文、英文、数字、下划线');
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input placeholder="请输入项目名称" />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <div className="detect-record-list-page">
      <div className="tools-wrapper">
        <div className="addr-list-filter-input-container">
          <Form layout="inline">
            <Form.Item label="检测人" className="filter-item">
              <Select
                value={filterUserId}
                onChange={(value: number) => {
                  setFilterUserId(value);
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    fetchUsers();
                  }
                }}
                placeholder="全部"
                allowClear
              >
                {userOptions.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      <List.Item.Meta
                        avatar={
                          item.avatar ? (
                            <Avatar src={`v2/api/user/avatar/${item.avatar}`} size={'small'} />
                          ) : (
                            <Avatar size={'small'}>{item.username}</Avatar>
                          )
                        }
                        title={
                          <span style={{ color: '#f39800' }}>
                            {item.username} <span style={{ fontSize: 10 }}>({item.company_user_id})</span>
                          </span>
                        }
                        description={null}
                        style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                      />
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="日期" className="filter-item">
              <DatePicker.RangePicker
                onChange={(value) => {
                  if (!value) return;
                  setFilterTime(
                    value.map((x) => {
                      return moment(x).format('YYYY-MM-DD') + ' 00:00:00';
                    })
                  );
                }}
              ></DatePicker.RangePicker>
            </Form.Item>
            <Button onClick={() => fetchData()} icon={<SearchOutlined />}>
              搜索
            </Button>
          </Form>
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            type="primary"
            onClick={() => {
              history.push('/workbench/ai/model-list/detect-records/my');
            }}
            icon={<HistoryOutlined />}
          >
            我的检测记录
          </Button>
        </div>
      </div>
      <div className="data-list-container">
        <List
          dataSource={data}
          loading={loading}
          pagination={{
            ...pagination,
            showTotal: (total) => `共 ${total} 条`,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
          renderItem={(item) => (
            <List.Item
              extra={[
                <span>
                  <Tooltip title="查看模型检测报告">
                    <Button
                      style={{ marginRight: 16, borderRadius: 5 }}
                      onClick={(e) => {
                        if (item.detect_result === '检测中') {
                          message.warning('检测中，请稍后查看');
                          return;
                        } else if (item.detect_result === '检测失败') {
                          message.warning('检测失败，请稍后查看');
                          return;
                        } else if (!item.detect_result) {
                          message.warning('检测结果为空，请稍后查看');
                          return;
                        }
                        setRecord(item);
                        setDownloadVisible(true);
                      }}
                    >
                      检测报告
                    </Button>
                  </Tooltip>
                  <Tooltip title="删除检测记录">
                    <Button
                      style={{ borderRadius: 5, marginRight: 16 }}
                      danger
                      onClick={(e) => {
                        Modal.confirm({
                          title: '提示',
                          content: '确认删除该检测记录吗？',
                          okText: '确认',
                          cancelText: '取消',
                          onOk: () => {
                            deleteDetectRecord(item.id);
                          },
                        });
                      }}
                    >
                      删除
                    </Button>
                  </Tooltip>

                  <Tooltip title="检测结果校验">
                    <Button
                      style={{ borderRadius: 5 }}
                      type="primary"
                      onClick={(e) => {
                        setCheckImages(item.ai_detect_data_dtolist);
                        setCheckVisible(true);
                      }}
                    >
                      结果校验
                    </Button>
                  </Tooltip>
                </span>,
              ]}
              style={{ marginBottom: 16, backgroundColor: '#fff', padding: 16, borderRadius: 5 }}
            >
              <List.Item.Meta
                title={
                  <div>
                    <span>
                      {item.project_name ? item.project_name : '未知'}{' '}
                      <span>
                        <DetectStatusTag status={item.detect_result} />
                      </span>
                    </span>
                  </div>
                }
                description={
                  <div>
                    <span style={{ marginRight: 16 }}>
                      检测人：{item.create_user_name}/{item.create_user_company_user_id}
                    </span>
                    <span style={{ marginRight: 16 }}>检测时间：{item.create_time}</span>
                    <span style={{ marginRight: 16 }}>模型编号：{item.model_id}</span>

                    <Popover
                      placement="bottom"
                      getPopupContainer={(triggerNode) =>
                        document.getElementsByClassName('detect-record-list-page')[0] as HTMLElement
                      }
                      content={
                        <Table
                          dataSource={assetStatus}
                          loading={statusLoading}
                          size={'small'}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                history.push({
                                  pathname: '/workbench/assets-management/status-list',
                                  state: {
                                    status_name: record.status_name,
                                    status_value: record.status_value,
                                  },
                                });
                              },
                            };
                          }}
                          columns={[
                            {
                              title: '资产状态名',
                              dataIndex: 'status_name',
                              key: 'status_name',
                            },
                            {
                              title: '资产状态值',
                              dataIndex: 'status_value',
                              key: 'status_value',
                            },
                            {
                              title: '状态值类型',
                              dataIndex: 'status_type',
                              key: 'status_type',
                            },
                            {
                              title: '资产名称',
                              dataIndex: 'asset_name',
                              key: 'asset_name',
                            },
                            ...Array(maxCompsCount)
                              .fill(undefined)
                              .map((_, idx) => {
                                const count = idx + 1;
                                const key = `AssetsComs-ColChild-${idx}`;
                                return {
                                  key: key,
                                  title: `资产组件${count}`,
                                  render(_: any, record: DetectAssetStatus) {
                                    if (!record.asset_comps) return null;
                                    const curName = record.asset_comps[idx] ? record.asset_comps[idx] : '';
                                    return <span>{curName}</span>;
                                  },
                                };
                              }),

                            ...Array(maxVStatusCount)
                              .fill(undefined)
                              .map((_, idx) => {
                                const count = idx + 1;
                                const key = `GeoInfo-ColChild-${idx}`;
                                return {
                                  key,
                                  title: `地理组件${count}`,
                                  render(_: any, record: DetectAssetStatus) {
                                    let curName = '';
                                    const _key = Object.keys(record.v_asset_status)[idx];
                                    if (_key) {
                                      curName = `${_key}：${record.v_asset_status[_key]}`;
                                    }
                                    return <span>{curName}</span>;
                                  },
                                };
                              }),
                          ]}
                        />
                      }
                      onVisibleChange={(visible) => {
                        if (visible) {
                          fetchAssetStatus(item.id);
                        } else {
                          setAssetStatus([]);
                        }
                      }}
                      title="资产状态记录"
                      trigger="click"
                    >
                      <Tooltip title="查看绑定的资产状态">
                        <OrderedListOutlined style={{ color: '#f39800', marginRight: 4 }} />

                        <a onClick={(e) => {}} style={{ marginRight: 16 }}>
                          {`资产状态记录(${item.bind_asset_status_num})`}
                        </a>
                      </Tooltip>
                    </Popover>
                    <Popover
                      content={
                        <Row style={{ maxWidth: 716 }} gutter={16}>
                          {item.ai_detect_data_dtolist.length > 0 ? (
                            item.ai_detect_data_dtolist.map((x) => {
                              return (
                                <Col key={x.image_uid}>
                                  <Image
                                    src={
                                      x.detected_image_url
                                        ? x.detected_image_url
                                        : `/v2/api/pic/picture/download/${10}/${x.image_uid}`
                                    }
                                    height={102}
                                    width={102}
                                  />
                                </Col>
                              );
                            })
                          ) : (
                            <Empty />
                          )}
                        </Row>
                      }
                      title="检测图片"
                      trigger="click"
                    >
                      <Tooltip title="模型检测图片" style={{ marginRight: 16 }}>
                        <PictureOutlined style={{ color: '#f39800', marginRight: 4 }} />

                        <a onClick={(e) => {}}>{`检测图片(${item.ai_detect_data_dtolist.length})`}</a>
                      </Tooltip>
                    </Popover>
                  </div>
                }
              ></List.Item.Meta>
            </List.Item>
          )}
        ></List>
      </div>
      {checkVisible && checkImages.length > 0 && (
        <CheckModal visible={checkVisible} images={checkImages} setVisible={setCheckVisible} fetchData={fetchData} />
      )}
      {downloadVisible && <ReportNameModal />}
    </div>
  );
};
export default DetectRecords;
