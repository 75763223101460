import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCtxState } from '@/App.store'
import * as formRequests from '@/requests/form'
import * as Utils from '@/utils/string'
import {
  message,
  Spin,
  Form,
  PageHeader,
  Input,
  Radio,
  DatePicker,
  Button,
  Divider,
  Upload,
  Modal,
  Select,
  Tooltip,
} from 'antd'
import '../styles/index.less'
import { PlusOutlined, QuestionCircleFilled, SaveOutlined, UploadOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: {
    xs: { span: 3 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

// interface StatusPic {
//   marker_location: string
//   uid: string
//   form_id: string
//   comment: string
//   size: any
//   name: any
//   type: any
// }

interface FormPageWritingDatas {
  form_id: string
  page_index: string
  uid: string
  form_page_contents: {
    status_value: string
    status_pics: any[]
    comment: string
    field_index: string
    asset_id: string
    field_type: string
    label: string
    form_id: string
    v_asset_comps: string[]
    private_vcomps: string[]
    v_asset_status: {
      v_name: string
      v_value: string
      regulation: Publicinitregulation
    }[]
  }[]
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const FormDataWritePage = () => {
  const [{ userInfo }] = useCtxState()
  const history = useHistory()
  const location = useLocation<any>()
  const [template, setTemplate] = React.useState<FormTemplateItem | undefined>(undefined)
  const [formData, setFormData] = React.useState<FormDataItem | undefined>(undefined)
  const [formPageData, setFormPageData] = React.useState<FormPageWritingDatas | undefined>(undefined)
  const [fetching, setFetching] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [loadingNextPage, setLoadingNextPage] = React.useState(false)
  const [loadingPrePage, setLoadingPrePage] = React.useState(false)
  const [previewVisible, setPreviewVisible] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState('')

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }
  const fetchFormData = async () => {
    if (!formData) return
    let form_id = ''
    form_id = location.state ? location.state.form_id : location.search.split('=')[1]
    const res = await formRequests.getFormDataById(form_id)
    if (res.code === 0) {
      setFormData({ ...formData, form_pages_data: res.data.form_pages_data })
    } else {
      message.error(res.message)
    }
  }

  const changeVassetValue = (reg: Publicinitregulation, v_value: string) => {
    let { value, mode, option } = reg
    if (mode === 'equal') {
      v_value = v_value
    }
    if (mode === 'recircle') {
      const index = option.indexOf(v_value)
      if (index === -1) {
        v_value = v_value
      }
      if (index === option.length - 1) {
        v_value = option[0]
      } else {
        v_value = option[index + 1]
      }
    }
    if (mode === 'arithmetic') {
      v_value = (Number(v_value) + Number(value)).toString()
    }
    return v_value
  }
  const initVassetValue = (reg: Publicinitregulation) => {
    let v_value: string = ''
    let { value, mode, option } = reg
    if (mode === 'equal') {
      v_value = value
    }
    if (mode === 'recircle') {
      v_value = option[0]
    }
    if (mode === 'arithmetic') {
      v_value = option[0]
    }
    return v_value
  }

  const initFormData = async () => {
    let form_id = ''
    form_id = location.state ? location.state.form_id : location.search.split('=')[1]
    const res = await formRequests.getFormDataById(form_id)
    if (res.code === 0) {
      setFormData(res.data)
      const TemplateRes = await formRequests.queryShareTemplate({
        uid: res.data.form_data.form_template_share_uid,
        version: res.data.form_data.form_template_version_number,
      })
      const { form_pages_data } = res.data
      if (TemplateRes.code === 0) {
        setTemplate(TemplateRes.data)
        setFormPageData({
          uid: '',
          form_id: res.data.form_data.form_id,
          page_index: (res.data.form_pages_data.length + 1).toString(),
          form_page_contents: TemplateRes.data.fields.map((x, index) => {
            const private_regulation =
              res.data.form_data.private_init_regulations.length > 0
                ? res.data.form_data.private_init_regulations[index]
                : undefined
            return {
              status_value: '',
              asset_id: '',
              field_index: x.field_index.toString(),
              comment: '',
              status_pics: [],
              field_type: x.field_type,
              label: x.label,
              form_id: res.data.form_data.form_id,
              v_asset_comps: x.v_asset_comps,
              private_vcomps: private_regulation
                ? private_regulation.v_asset_comp_regulation.map((reg) => reg.v_asset_comp)
                : [],
              v_asset_status: x.v_asset_comps.map((vcomp) => {
                let regulation: Publicinitregulation = {} as Publicinitregulation
                const public_res = res.data.form_data.public_init_regulations.find(
                  (item) => item.v_asset_comp === vcomp
                )
                const private_res = private_regulation
                  ? private_regulation.v_asset_comp_regulation.find((item) => item.v_asset_comp === vcomp)
                  : null
                if (public_res) {
                  regulation = public_res
                }
                if (private_res) {
                  regulation = private_res
                }
                return {
                  v_name: vcomp,
                  v_value:
                    form_pages_data.length > 0
                      ? changeVassetValue(
                          regulation,
                          form_pages_data[form_pages_data.length - 1].form_page_contents[index].v_asset_status[vcomp]
                        )
                      : initVassetValue(regulation),
                  regulation: regulation,
                }
              }),
            }
          }),
        })
      } else {
        message.error('未知错误！')
        history.goBack()
      }
    } else {
      message.error('未知错误！')
      history.goBack()
    }
    setFetching(false)
  }

  const handleNextPage = async () => {
    if (!template || !formPageData || !formData) return
    setLoadingNextPage(true)
    let page_index = formData.form_pages_data.findIndex((x) => x.uid === formPageData.uid)
    if (page_index === -1) {
      message.error('未知错误，请稍后尝试')
      return
    }
    if (page_index + 1 === formData.form_pages_data.length) {
      let form_id = ''
      form_id = location.state ? location.state.form_id : location.search.split('=')[1]
      const res = await formRequests.getFormDataById(form_id)
      if (res.code === 0) {
        setFormData({ ...formData, form_pages_data: res.data.form_pages_data })
        setFormPageData({
          ...formPageData,
          uid: '',
          page_index: (res.data.form_pages_data.length + 1).toString(),
          form_page_contents: formPageData.form_page_contents.map((item, i_index) => {
            return {
              ...item,
              status_value: '',
              status_pics: [],
              comment: '',
              asset_id: '',
              v_asset_status: item.v_asset_status.map((x) => {
                return {
                  ...x,
                  v_value: changeVassetValue(x.regulation, x.v_value),
                }
              }),
            }
          }),
        })
      } else {
        message.error('未知错误！')
      }
    } else {
      const uid = formData.form_pages_data[page_index + 1].uid

      const res = await formRequests.getSinglePageData(uid)
      if (res.code === 0) {
        setFormPageData({
          uid: res.data.uid,
          form_id: res.data.form_id,
          page_index: (formData.form_pages_data.findIndex((x) => x.uid === res.data.uid) + 1).toString(),
          form_page_contents: formPageData.form_page_contents.map((x, x_index) => {
            return {
              ...x,
              status_value: res.data.form_page_contents[x_index].status_value,
              status_pics: [],
              comment: res.data.form_page_contents[x_index].comment,
              asset_id: res.data.form_page_contents[x_index].asset_id,
              v_asset_status: x.v_asset_status.map((v_comp, v_index) => {
                return {
                  v_name: v_comp.v_name,
                  v_value: res.data.form_page_contents[x_index].v_asset_status[v_comp.v_name],
                  regulation: v_comp.regulation,
                }
              }),
            }
          }),
        })
      } else {
        message.error(res.message)
      }
    }

    setLoadingNextPage(false)
  }

  const handlePrePage = async () => {
    if (!template || !formPageData || !formData) return
    setLoadingPrePage(true)
    let page_index = formData.form_pages_data.findIndex((x) => x.uid === formPageData.uid)
    if (page_index === -1) {
      page_index = formData.form_pages_data.length
    }

    if (page_index === 0) {
      message.warning('已经是第一页')
      setLoadingPrePage(false)
      return
    }
    const uid = formData.form_pages_data[page_index - 1].uid

    const res = await formRequests.getSinglePageData(uid)
    if (res.code === 0) {
      setFormPageData({
        uid: res.data.uid,
        form_id: res.data.form_id,
        page_index: (formData.form_pages_data.findIndex((x) => x.uid === res.data.uid) + 1).toString(),
        form_page_contents: formPageData.form_page_contents.map((x, x_index) => {
          return {
            ...x,
            status_value: res.data.form_page_contents[x_index].status_value,
            status_pics: [],
            comment: res.data.form_page_contents[x_index].comment,
            asset_id: res.data.form_page_contents[x_index].asset_id,
            v_asset_status: x.v_asset_status.map((v_comp, v_index) => {
              return {
                v_name: v_comp.v_name,
                v_value: res.data.form_page_contents[x_index].v_asset_status[v_comp.v_name],
                regulation: v_comp.regulation,
              }
            }),
          }
        }),
      })
    } else {
      message.error(res.message)
    }
    setLoadingPrePage(false)
  }

  const saveSinglePageData = async () => {
    if (!template || !formPageData || !formData) return
    setSaving(true)
    try {
      if (!formPageData.uid) {
        const res = await formRequests.saveSingleData({
          ...formPageData,
          form_page_contents: formPageData.form_page_contents.map((x, index) => {
            let obj: any = {}
            x.v_asset_status.forEach((v, v_index) => {
              obj[v.v_name] = v.v_value
            })
            return {
              ...x,
              status_pics: x.status_pics.map((pic, pic_index) => {
                return {
                  marker_location: '',
                  pic_uid: pic.response.data,
                  form_id: x.form_id,
                  comment: '',
                }
              }),
              asset_name: template.fields[index].asset_name,
              asset_comps: template.fields[index].asset_comps,
              v_asset_name: template.fields[index].v_asset_name ? template.fields[index].v_asset_name : '轨行位置',
              v_asset_comps: x.v_asset_comps,
              v_asset_status: obj,
              status_name: template.fields[index].status_name,
              status_type: template.fields[index].status_value_type,
              tools:
                formData.form_data.user_tools.length > 0
                  ? formData.form_data.user_tools.filter((item) => item.user_id === userInfo.user_id)[0].tools
                  : [],
            }
          }),
        })
        if (res.code === 0) {
          message.success('保存成功')
          fetchFormData()
          setFormPageData({
            ...formPageData,
            uid: res.data.uid,
          })
        } else {
          message.error(res.message)
        }
      } else {
        const res = await formRequests.updateSinglePageData({
          ...formPageData,
          form_page_contents: formPageData.form_page_contents.map((x, index) => {
            let obj: any = {}
            x.v_asset_status.forEach((v, v_index) => {
              obj[v.v_name] = v.v_value
            })
            return {
              ...x,
              status_pics: x.status_pics.map((pic, pic_index) => {
                return {
                  marker_location: '',
                  pic_uid: pic.response.data,
                  form_id: x.form_id,
                  comment: '',
                }
              }),
              asset_name: template.fields[index].asset_name,
              asset_comps: template.fields[index].asset_comps,
              v_asset_name: template.fields[index].v_asset_name ? template.fields[index].v_asset_name : '轨行位置',
              v_asset_comps: template.fields[index].v_asset_comps,
              v_asset_status: obj,
              status_name: template.fields[index].status_name,
              status_type: template.fields[index].status_value_type,
              tools:
                formData.form_data.user_tools.length > 0
                  ? formData.form_data.user_tools.filter((item) => item.user_id === userInfo.user_id)[0].tools
                  : [],
            }
          }),
        })
        if (res.code === 0) {
          message.success('保存成功')
          fetchFormData()
        } else {
          message.error(res.message)
        }
      }
    } catch (e) {
      console.log(e)
      message.error('未知错误')
    }
    console.log('xxxx', formPageData, formData, template)

    setSaving(false)
  }

  const queryAsset = async (field_index: number) => {
    if (!template || !formPageData || !formData) return
    let obj: any = {}
    formPageData.form_page_contents[field_index].v_asset_status.forEach((v) => {
      obj[v.v_name] = v.v_value
    })
    const res = await formRequests.queryAsset({
      asset_name: template.fields[field_index].asset_name,
      asset_comps: template.fields[field_index].asset_comps,
      v_asset_name: template.fields[field_index].v_asset_name ? template.fields[field_index].v_asset_name : '轨行位置',
      status_name: template.fields[field_index].status_name,
      v_asset_comps: template.fields[field_index].v_asset_comps,
      v_asset_status: obj,
    })
    if (res.code === 0) {
      if (res.data.length === 0) {
        let arr = formPageData.form_page_contents.map((x) => x)
        arr[field_index].asset_id = ''

        setFormPageData({ ...formPageData, form_page_contents: arr })
      } else if (res.data.length === 1) {
        let arr = formPageData.form_page_contents.map((x) => x)
        arr[field_index].asset_id = res.data[0].asset_id
        res.data[0].v_asset_status.forEach((x) => {
          arr[field_index].v_asset_status = arr[field_index].v_asset_status.map((y) => {
            if (x.v_status_name === y.v_name) {
              return {
                ...y,
                v_value: x.v_status_value,
              }
            }
            return y
          })
        })
        setFormPageData({ ...formPageData, form_page_contents: arr })
      } else {
        Modal.confirm({
          title: '匹配到多个资产，请选择！',
          content: (
            <Select
              defaultValue={res.data[0].asset_id}
              style={{ minWidth: 400 }}
              onChange={(value: string) => {
                let obj: QueryAssetRes = JSON.parse(value)
                let arr = formPageData.form_page_contents.map((x) => x)
                arr[field_index].asset_id = obj.asset_id
                obj.v_asset_status.forEach((x) => {
                  arr[field_index].v_asset_status = arr[field_index].v_asset_status.map((y) => {
                    if (x.v_status_name === y.v_name) {
                      return {
                        ...y,
                        v_value: x.v_status_value,
                      }
                    }
                    return y
                  })
                })
                setFormPageData({ ...formPageData, form_page_contents: arr })
              }}
            >
              {res.data.map((x, _index) => {
                return (
                  <Select.Option value={JSON.stringify(x)}>
                    <span>
                      {x.asset_name + x.asset_id}
                      <Tooltip
                        title={x.v_asset_status.map((x) => (
                          <div>{`${x.v_status_name}：${x.v_status_value}`}</div>
                        ))}
                      >
                        <QuestionCircleFilled
                          style={{
                            color: '#f39800',
                            fontSize: 15,
                            marginLeft: 2,
                          }}
                        />
                      </Tooltip>
                    </span>
                  </Select.Option>
                )
              })}
            </Select>
          ),
          okText: '确认',
          cancelButtonProps: { style: { display: 'none' } },
          onOk: () => {
            if (!formPageData.form_page_contents[field_index].asset_id) {
              message.error('请选择一个资产！')
              return Promise.reject()
            } else {
              return Promise.resolve()
            }
          },
          width: 500,
        })
      }
    } else if (res.code === 70002) {
    } else {
      message.error(res.message)
    }
  }

  const submitFormData = async () => {
    if (!template || !formPageData || !formData) return
    setSaving(true)
    const res = await formRequests.submitFormData(formData.form_data.form_id)
    if (res.code === 0) {
      message.success('提交成功')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } else {
      message.error('提交失败，请稍后再试')
    }
    setSaving(false)
  }

  React.useEffect(() => {
    setFetching(true)
    initFormData()
  }, [])

  return (
    <div className="form-write-page">
      <PageHeader
        title={'填写表单'}
        onBack={() => history.goBack()}
        extra={[
          <Button
            key="submit"
            type="primary"
            icon={<UploadOutlined />}
            onClick={() => {
              Modal.confirm({
                title: '提示',
                content: '确认提交当前表单吗？',
                okText: '确认',
                onOk: () => {
                  submitFormData()
                },
                cancelText: '取消',
              })
            }}
          >
            提交表单
          </Button>,
          <Button
            key="save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => {
              message.success('保存成功')
              setTimeout(() => {
                history.goBack()
              }, 500)
            }}
          >
            保存表单
          </Button>,
        ]}
      ></PageHeader>

      <div
        style={{
          width: '100%',
          minHeight: 700,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!!!fetching && template && formData && formPageData && (
          <div
            style={{
              minWidth: 600,
              maxWidth: 600,
              border: '1px solid #f39800',
              borderRadius: 5,
              padding: 24,
              position: 'relative',
              backgroundColor: '#F9F9FB',
            }}
          >
            <div style={{ position: 'absolute', right: 20 }}>
              <span>{`第 ${formPageData.page_index} 页`}</span>
            </div>
            <div>
              <h3>
                <div className="title-prefix"></div>公有地点信息
              </h3>
              <Form layout="inline" style={{ marginBottom: 20 }}>
                {formData.form_data.public_init_regulations.map((x, _index) => {
                  const v_status = formPageData.form_page_contents[0].v_asset_status.filter(
                    (v) => v.v_name === x.v_asset_comp
                  )[0]
                  return (
                    <Form.Item label={x.v_asset_comp} key={x.v_asset_comp + _index}>
                      <Input
                        value={v_status.v_value}
                        style={{ maxWidth: 120 }}
                        onChange={(e) => {
                          let arr = formPageData.form_page_contents.map((field) => {
                            return {
                              ...field,
                              v_asset_status: field.v_asset_status.map((vcomp) => {
                                if (vcomp.v_name === x.v_asset_comp) {
                                  return {
                                    ...vcomp,
                                    v_value: e.target.value,
                                  }
                                } else {
                                  return vcomp
                                }
                              }),
                            }
                          })

                          setFormPageData({
                            ...formPageData,
                            form_page_contents: arr,
                          })
                        }}
                        onBlur={(e) => {
                          formPageData.form_page_contents.forEach((z, z_index) => {
                            queryAsset(z_index)
                          })
                        }}
                      />
                    </Form.Item>
                  )
                })}
              </Form>
            </div>
            <Divider />
            <Form labelCol={{ span: 24, offset: 0 }}>
              {formPageData.form_page_contents.map((field, _index: number) => {
                let { field_type, field_index, asset_id } = field
                return (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <h3>
                        <div className="title-prefix"></div>
                        {field.label}
                      </h3>
                      <div
                        style={{
                          paddingLeft: 10,
                          fontSize: 12,
                          paddingBottom: 8,
                          color: asset_id ? '#52C41A' : '#FAAD14',
                        }}
                      >
                        {asset_id && (
                          <span>
                            {`已匹配到资产：${asset_id}`}
                            <Tooltip
                              title={field.v_asset_status.map((x) => (
                                <div>{`${x.v_name}：${x.v_value}`}</div>
                              ))}
                            >
                              <QuestionCircleFilled
                                style={{
                                  color: '#f39800',
                                  fontSize: 15,
                                  marginLeft: 2,
                                }}
                              />
                            </Tooltip>
                          </span>
                        )}
                        {!asset_id && `未匹配资产`}
                      </div>
                    </div>

                    <Form {...formItemLayout} key={field_index + '-' + _index}>
                      <Form.Item label="图片" style={{ marginBottom: 0 }}>
                        <Upload
                          action={`/v2/api/form/data/status/pic`}
                          listType="picture-card"
                          method="POST"
                          fileList={field.status_pics}
                          onPreview={(file) => {
                            handlePreview(file)
                          }}
                          onChange={({ fileList }) => {
                            let arr = formPageData.form_page_contents.map((x) => x)

                            arr[_index].status_pics = fileList
                            setFormPageData({
                              ...formPageData,
                              form_page_contents: arr,
                            })
                          }}
                          transformFile={(file) => {
                            return new Promise((resolve) => {
                              const reader = new FileReader()
                              reader.readAsDataURL(file)
                              const uid = Utils.uuid()
                              reader.onload = (e: any) => {
                                const blob = e.target.result
                                const newFile = Utils.dataURLtoFile(blob, `${uid}.${file.name.split('.')[1]}`)
                                resolve(newFile)
                              }
                            })
                          }}
                          className="upload-list-inline "
                        >
                          {field.status_pics.length >= 8 ? null : (
                            <div>
                              <PlusOutlined />
                            </div>
                          )}
                        </Upload>
                      </Form.Item>
                      <Form.Item label="备注">
                        <Input
                          value={field.comment}
                          onChange={(e) => {
                            let arr = formPageData.form_page_contents.map((x) => x)
                            arr[_index].comment = e.target.value
                            setFormPageData({
                              ...formPageData,
                              form_page_contents: arr,
                            })
                          }}
                        />
                      </Form.Item>
                      {field.private_vcomps.length > 0 &&
                        field.private_vcomps.map((item, item_index) => {
                          const v_status = field.v_asset_status.filter((x) => x.v_name === item)[0]
                          return (
                            <Form.Item label={item} key={_index + '-' + item_index}>
                              <Input
                                value={v_status.v_value}
                                onChange={(e) => {
                                  let arr = formPageData.form_page_contents.map((x) => x)
                                  arr[_index].v_asset_status = field.v_asset_status.map((x) => {
                                    if (x.v_name === item) {
                                      return {
                                        ...x,
                                        v_value: e.target.value,
                                      }
                                    } else {
                                      return x
                                    }
                                  })

                                  setFormPageData({
                                    ...formPageData,
                                    form_page_contents: arr,
                                  })
                                }}
                                onBlur={(e) => {
                                  queryAsset(_index)
                                }}
                              />
                            </Form.Item>
                          )
                        })}
                      <Form.Item label="状态值">
                        {field_type === 'single_line_text' && (
                          <Input
                            onChange={(e) => {
                              let arr = formPageData.form_page_contents.map((x) => x)
                              arr[_index].status_value = e.target.value
                              setFormPageData({
                                ...formPageData,
                                form_page_contents: arr,
                              })
                            }}
                            value={field.status_value}
                          />
                        )}
                        {field_type === 'paragraph_text' && (
                          <Input.TextArea
                            rows={4}
                            onChange={(e) => {
                              let arr = formPageData.form_page_contents.map((x) => x)
                              arr[_index].comment = e.target.value
                              setFormPageData({
                                ...formPageData,
                                form_page_contents: arr,
                              })
                            }}
                            value={field.status_value}
                          />
                        )}
                        {field_type === 'single_choice' && (
                          <Radio.Group
                            value={field.status_value}
                            onChange={(e) => {
                              let arr = formPageData.form_page_contents.map((x) => x)
                              arr[_index].status_value = e.target.value
                              setFormPageData({
                                ...formPageData,
                                form_page_contents: arr,
                              })
                            }}
                          >
                            {template.fields[_index].multiple_choice.map((option) => {
                              return (
                                <Radio value={option} key={'option' + option + _index}>
                                  {option}
                                </Radio>
                              )
                            })}
                          </Radio.Group>
                        )}
                        {field_type === 'date' && <DatePicker />}
                      </Form.Item>
                    </Form>
                  </div>
                )
              })}
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => {
                  setPreviewVisible(false)
                }}
              >
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 50,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    handlePrePage()
                  }}
                  disabled={loadingNextPage || loadingPrePage || saving}
                >
                  上一页
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    saveSinglePageData()
                  }}
                  disabled={loadingNextPage || loadingPrePage || saving}
                >
                  保存
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleNextPage()
                  }}
                  disabled={!formPageData.uid || loadingNextPage || loadingPrePage || saving}
                >
                  下一页
                </Button>
              </div>
            </Form>
          </div>
        )}
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={fetching} tip="正在初始化..." />
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={saving} tip="保存中" />
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={loadingNextPage} tip="正在加载下一页" />
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={loadingPrePage} tip="正在加载上一页" />
      </div>
    </div>
  )
}

export default FormDataWritePage
