import * as React from 'react'
import { Result, Button } from 'antd'
import { withRouter, useHistory } from 'react-router-dom'

const NoMatched = () => {
  const history = useHistory()
  return (
    <Result
      status="404"
      title="404"
      subTitle="对不起，您请求的页面不存在"
      extra={
        <div>
          <Button type="primary" onClick={() => history.push('/')}>
            回到主页
          </Button>
          <Button type="primary" onClick={() => history.goBack()} style={{ marginLeft: 10 }}>
            返回上一页
          </Button>
        </div>
      }
    />
  )
}

export default withRouter(NoMatched)
