import { generateStoreManager } from './react-helpers/store';
import { RouteComponentProps } from 'react-router-dom';

const { attatch, useCtxState } = generateStoreManager();

interface WorkBnechState {
  sidebarCollapsed: boolean;
  userInfo: any;
  userSysMenus: SysMenuItem[];
  showHeader: boolean;
  cachedRoutes: string[];
  sysUsers: UserInfoItem[];
}

const state: WorkBnechState = {
  sidebarCollapsed: false,
  userInfo: [],
  userSysMenus: [] as SysMenuItem[],
  showHeader: true,
  cachedRoutes: [],
  sysUsers: [],
};

const reducer: React.Reducer<WorkBnechState, any> = (state, action) => {
  switch (action.type) {
    case 'toggle:sidebarCollapsed':
      return {
        ...state,
        sidebarCollapsed:
          typeof action.sidebarCollapsed === 'boolean' ? action.sidebarCollapsed : !state.sidebarCollapsed,
      };
    case 'SAVE_INFO':
      return {
        ...state,
        userInfo: action.info,
      };
    case 'SAVE_USER_SYSMENU':
      return {
        ...state,
        userSysMenus: action.info,
      };
    case 'toggle:showHeader':
      return {
        ...state,
        showHeader: typeof action.showHeader === 'boolean' ? action.showHeader : !state.showHeader,
      };
    case 'SAVE_CACHE_ROUTES':
      return {
        ...state,
        cachedRoutes: action.cachedRoutes,
      };
    case 'SAVE_SYS_USERS':
      return {
        ...state,
        sysUsers: action.users,
      };
    default:
      return state;
  }
};

const computer = {};

export const connect = (
  Target: React.Component | React.FunctionComponent | React.FunctionComponent<RouteComponentProps>
) => attatch(Target, { state, reducer });

export { useCtxState, computer };
