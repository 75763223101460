import * as React from 'react'
import { Button, message, Spin, List, Card, Form, DatePicker, Input, Tooltip, Select, Avatar } from 'antd'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import * as FormRequests from '@/requests/form'
import * as UserRequests from '@/requests/user'

import { connect } from '@/App.store'
import { EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons'
import '../styles/index.less'
import moment from 'moment'

const FormStatisticsData = (props: RouteComponentProps) => {
  const [data, setData] = React.useState<FormHeadDataItem[]>([])
  const [fetching, setFetching] = React.useState(false)
  const [createTimeBegin, setCreateTimeBegin] = React.useState('')
  const [createTimeEnd, setCreateTimeEnd] = React.useState('')
  const [submitTimeBegin, setSubmitTimeBegin] = React.useState('')
  const [submitTimeEnd, setSubmitTimeEnd] = React.useState('')
  const [userTool, setUserTool] = React.useState('')
  const [creatorId, setCreatorId] = React.useState('')
  const [cooperatorId, setCooperatorId] = React.useState('')
  const location = useLocation()
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })
  const [cooperOptions, setCooperOptions] = React.useState<UserInfoItem[]>([])
  const history = useHistory()
  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) page = 1
    if (!pageSize) pageSize = 10
    let id = ''
    if (location.state) {
      const state = location.state as any
      id = state.id
    }
    const res = await FormRequests.getFormStatisticsDataById({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      form_template_share_id: id,
      ...(createTimeBegin && {
        create_time_begin: createTimeBegin,
      }),
      ...(createTimeEnd && {
        create_time_end: createTimeEnd,
      }),
      ...(submitTimeBegin && {
        submitted_time_begin: submitTimeBegin,
      }),
      ...(submitTimeEnd && {
        submitted_time_end: submitTimeEnd,
      }),
      ...(creatorId && {
        create_company_user_id: creatorId,
      }),
      ...(cooperatorId && {
        cooperator_company_user_id: cooperatorId,
      }),
      ...(userTool && {
        user_tools: userTool,
      }),
    })

    if (res.code === 0) {
      if (res.data) {
        setData(res.data)
      } else {
        setData([])
      }
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      })
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }
  const fetchingUserLsit = async (searchCondition: string) => {
    setFetching(true)
    const res = await UserRequests.getUserList({
      limit: 100,
      skip: 0,
      search_condition: searchCondition,
    })
    if (res.code === 0) {
      setCooperOptions(res.data)
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }

  React.useEffect(() => {
    setFetching(true)
    let id = ''
    if (location.state) {
      const state = location.state as any
      id = state.id
    }
    fetchData()
  }, [])
  return (
    <div className="form-statistics-list-page">
      <div className="tools-wrapper">
        <div className="form-statistics-list-filter-input-container">
          <Form layout="inline">
            <Form.Item label="创建者" className="filter-item">
              <Select
                placeholder={'输入用户名搜索'}
                onSearch={(value) => {
                  fetchingUserLsit(value)
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    fetchingUserLsit('')
                  }
                }}
                showSearch
                showArrow={false}
                notFoundContent={fetching ? <Spin size="small" /> : '无用户'}
                onChange={(value: string) => {
                  const user = JSON.parse(value)

                  setCreatorId(user.company_user_id)
                }}
              >
                {cooperOptions.map((x: UserInfoItem, _index) => {
                  return (
                    <Select.Option value={JSON.stringify(x)} key={x.user_id + _index}>
                      <div
                        style={{
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {x.avatar ? (
                          <Avatar size={30} src={`v2/api/user/avatar/${x.avatar}`} style={{ marginRight: 10 }}></Avatar>
                        ) : (
                          <Avatar
                            size={30}
                            style={{
                              backgroundColor: '#c37878',
                              marginRight: 10,
                            }}
                          >
                            <strong>{x.username.slice(0, 1)}</strong>
                          </Avatar>
                        )}

                        {x.username}
                      </div>
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item label="合作者" className="filter-item">
              <Select
                placeholder={'输入用户名搜索'}
                onSearch={(value) => {
                  fetchingUserLsit(value)
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    fetchingUserLsit('')
                  }
                }}
                showSearch
                showArrow={false}
                notFoundContent={fetching ? <Spin size="small" /> : '无用户'}
                onChange={(value: string) => {
                  const user = JSON.parse(value)

                  setCooperatorId(user.company_user_id)
                }}
              >
                {cooperOptions.map((x: UserInfoItem, _index) => {
                  return (
                    <Select.Option value={JSON.stringify(x)} key={x.user_id + _index}>
                      <div
                        style={{
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {x.avatar ? (
                          <Avatar size={20} src={`v2/api/user/avatar/${x.avatar}`} style={{ marginRight: 10 }}></Avatar>
                        ) : (
                          <Avatar
                            size={20}
                            style={{
                              backgroundColor: '#c37878',
                              marginRight: 10,
                            }}
                          >
                            <strong>{x.username.slice(0, 1)}</strong>
                          </Avatar>
                        )}

                        {x.username}
                      </div>
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item label="工具" className="filter-item">
              <Input placeholder="资产ID" onChange={(e) => setUserTool(e.target.value)} />
            </Form.Item>
            <Form.Item label="创建时间" className="filter-item">
              <DatePicker.RangePicker
                onChange={(value) => {
                  if (value) {
                    setCreateTimeBegin(moment(value[0]).format('YYYY-MM-DD HH:mm:ss'))
                    setCreateTimeEnd(moment(value[1]).format('YYYY-MM-DD HH:mm:ss'))
                  }
                }}
                showTime
              />
            </Form.Item>
            <Form.Item label="提交时间" className="filter-item">
              <DatePicker.RangePicker
                onChange={(value) => {
                  if (value) {
                    setSubmitTimeBegin(moment(value[0]).format('YYYY-MM-DD HH:mm:ss'))
                    setSubmitTimeEnd(moment(value[1]).format('YYYY-MM-DD HH:mm:ss'))
                  }
                }}
                showTime
              />
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: 'flex' }}>
          <Button type="primary" onClick={() => fetchData()} icon={<SearchOutlined />}>
            搜索
          </Button>
        </div>
      </div>

      <div style={{ width: '100%' }}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize)
            },
            showTotal: (total) => <span>共 {total} 条数据</span>,
          }}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.form_template_name}
                actions={[
                  <Tooltip title="查看详情">
                    <span
                      onClick={(e) => {
                        e.preventDefault()

                        history.push({
                          pathname: `/workbench/forms-management/form-data-list/formdata-detail`,
                          state: {
                            form_id: item.form_id,
                          },
                          search: `form_id=${item.form_id}`,
                        })
                      }}
                    >
                      <EyeOutlined style={{ marginRight: 5 }} />
                      查看
                    </span>
                  </Tooltip>,
                ]}
              >
                <Form labelCol={{ md: 5, lg: 5 }} labelAlign={'left'}>
                  <Form.Item label="创建人" style={{ marginBottom: 0 }}>
                    {item.creator_name}
                  </Form.Item>

                  <Form.Item label="合作者" style={{ marginBottom: 0 }}>
                    {item.form_cooperators
                      ? item.form_cooperators.map((x) => {
                          return x.user_name + ','
                        })
                      : '无'}
                  </Form.Item>
                  <Form.Item label="提交日期" style={{ marginBottom: 0 }}>
                    {item.submitted_time}
                  </Form.Item>
                </Form>
              </Card>
            </List.Item>
          )}
        ></List>
      </div>
      <Spin spinning={fetching} style={{ position: 'absolute', right: '50%', top: '50%' }} />
    </div>
  )
}

export default connect(FormStatisticsData)
