import * as React from 'react';
import { Button, Form, Input, Divider } from 'antd';
import './index.less';
import { FilterConditionsType } from '../../index';
import { PlusOutlined } from '@ant-design/icons';

const StandardHeader = ({
  setCreateStandardModalVisible,
  filterConditions,
  setFilterConditions,
}: {
  setCreateStandardModalVisible: Function;
  filterConditions: FilterConditionsType;
  setFilterConditions: Function;
}) => {
  return (
    <div className="standard-header-container">
      <div>
        <Form layout="inline">
          <Form.Item label="标准名称" className="filter-item">
            <Input.Search
              placeholder="输入标准名称以筛选"
              onSearch={(value) =>
                setFilterConditions({
                  ...filterConditions,
                  standard_name: value,
                })
              }
            />
          </Form.Item>
          <Form.Item label="资产名称" className="filter-item">
            <Input.Search
              placeholder="输入资产名称以筛选"
              onSearch={(value) => setFilterConditions({ ...filterConditions, asset_name: value })}
            />
          </Form.Item>
          <Form.Item label="状态名称" className="filter-item">
            <Input.Search
              placeholder="输入资产状态名以筛选"
              onSearch={(value) => setFilterConditions({ ...filterConditions, status_name: value })}
            />
          </Form.Item>
        </Form>
      </div>
      <div>
        <Button type="primary" onClick={() => setCreateStandardModalVisible(true)} icon={<PlusOutlined />}>
          创建状态值标准
        </Button>
      </div>
    </div>
  );
};

export default StandardHeader;
