import * as React from 'react';
import {
  message,
  Divider,
  Popconfirm,
  Table,
  Form,
  Select,
  AutoComplete,
  Button,
  Input,
  Modal,
  Drawer,
  Tag,
} from 'antd';
import * as strUtils from '@/utils/string';
import * as SuggestionRequests from '@/requests/suggestion';
import './index.less';
import { arrSet } from '@/utils/array';
import * as assetTemplateRequests from '@/requests/assetTemplate';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import EditableControl from '@/ui-components/EditableControl';
import CopiableControl from '@/ui-components/CopiableControl';

const noOp = (...args: any[]) => undefined;

function getColumnConfigs<ROW extends AssetTemplateItem>({
  onEvent = noOp,
  maxVStatusCount,
  maxCompsCount,
  maxStatusCount,
}: {
  onEvent: Function;
  maxVStatusCount: number;
  maxCompsCount: number;
  maxStatusCount: number;
}): any[] {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'order',
      render(_: any, record: ROW, idx: number) {
        return idx + 1;
      },
    },
    {
      title: '资产名',
      align: 'center',
      key: 'asset_name',
      render(_: any, record: ROW) {
        if (!record.asset_name) return null;
        const curName = record.asset_name;
        return <span key={`asset_name-${record.id}`}>{curName ? curName : '-'}</span>;
      },
    },

    ...Array(maxCompsCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `AssetsComs-ColChild-${idx}`;
        return {
          key: key,
          align: 'center',
          title: `资产组件${count}`,
          render(_: any, record: ROW) {
            if (!record.asset_comps_list) return null;
            const curName = record.asset_comps_list[idx] ? record.asset_comps_list[idx] : '';
            return <span key={`comps-${idx}-${record.id}`}>{curName ? curName : '-'}</span>;
          },
        };
      }),
    {
      title: '地理名称',
      align: 'center',
      key: 'v_asset_name',
      render(_: any, record: ROW) {
        if (!record.v_asset_name) return null;
        const curName = record.v_asset_name;
        return <span key={`v_asset_name-${record.id}`}>{curName ? curName : '-'}</span>;
      },
    },

    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `GeoInfo-ColChild-${idx}`;
        return {
          key,
          align: 'center',
          title: `地理组件${count}`,
          render(_: any, record: ROW) {
            let curName = '';
            if (record.asset_addr_name_list[idx]) {
              curName = `${record.asset_addr_name_list[idx]}`;
            }
            return <span key={`v_asset_status-${record.id}-${idx}`}>{curName ? curName : '-'}</span>;
          },
        };
      }),
    ,
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (_: string, record: ROW) => {
        if (!record) return null;
        return (
          <span>
            <span className="span-btn ant-btn-link" onClick={() => onEvent('start:update-asset', record)} key="edit">
              编辑
            </span>
            <Divider type="vertical" />
            <Popconfirm
              title="确定删除这条资产模板么?"
              onConfirm={async () => {
                const res = await assetTemplateRequests.deleteAssetTemplate({
                  id: record.id,
                });
                if (res.code === 0) {
                  message.success('删除成功');
                  onEvent('start:delete-asset-success', record);
                } else {
                  message.error(res.message);
                }
              }}
              okType="danger"
              okText="确定"
              cancelText="不了"
            >
              <span className="span-btn ant-btn-link ant-btn-link__danger" key="delete">
                删除
              </span>
            </Popconfirm>
          </span>
        );
      },
    },
  ].filter((x) => x);
}

const AssetTemplateList = () => {
  const [data, setData] = React.useState<AssetTemplateItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFeching] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [editingItem, setEditingItem] = React.useState<AssetTemplateItem | undefined>(undefined);

  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const [maxStatusCount, setMaxStatusCount] = React.useState(0);

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([]);
  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([]);
  const [statusNameOptions, setStatusNameOptions] = React.useState<string[]>([]);

  const [filter_assetName, set_filter_assetName] = React.useState<string | undefined>(undefined);
  // const [filter_statusName, set_filter_statusName] = React.useState<string[] | undefined>(undefined)
  const [filter_assetComps, set_filter_assetComps] = React.useState<string[] | undefined>(undefined);

  const [createVisible, setCreateVisible] = React.useState(false);
  const [createForm] = Form.useForm();

  const [editCompsVisible, setEditCompsVisible] = React.useState(false);
  const [addVcompsVisible, setAddVcompsVisible] = React.useState(false);
  const [editingStatus, setEditingStatus] = React.useState<StatusNameList | undefined>(undefined);
  const [addStatusVisible, setAddStatusVisible] = React.useState(false);
  const handleCreateOk = (values: CreateAssetTemplateReq) => {
    doCreate(values);
  };
  // const handleUpdateCancel = () => {
  //   Modal.confirm({
  //     title: '温馨提示',
  //     content: '确认放弃当前编辑内容吗？',
  //     okText: '放弃',
  //     cancelText: '继续编辑',
  //     autoFocusButton: 'cancel',
  //     onOk: () => setUpdateVisible(false),
  //     onCancel: () => {},
  //   })
  // }
  // const handleUpdateOk = (values: any) => {
  //   doUpdate(values)
  // }
  const handleCreateCancel = () => {
    setCreateVisible(false);
  };
  const doCreate = async (values: any) => {
    setFeching(true);
    const checkRes = await assetTemplateRequests.checkNameAndComps({
      asset_name: values.asset_name,
      asset_comps_list: values.asset_comps_list,
    });
    if (checkRes.code === 0) {
      const res = await assetTemplateRequests.createAssetTemplate({
        asset_name: values.asset_name,
        asset_comps_list: values.asset_comps_list,
        asset_addr_name_list: values.asset_addr_name_list,
        v_asset_name: values.v_asset_name,
        status_name_list: values.status_name_list.map((x: string) => {
          return {
            status_name: x,
            status_type: '数值',
          };
        }),
      });
      if (res.code === 0) {
        message.success('创建成功');
        fetchData();
        setCreateVisible(false);
      } else {
        message.error(res.message);
      }
    } else {
      message.error('该模板已存在，不可重复创建');
    }

    setFeching(false);
  };

  const doUpdate = async () => {
    if (!editingItem) return;
    setFeching(true);
    const res = await assetTemplateRequests.updateAssetTemplate({
      ...({} as any),
      id: editingItem.id,
      asset_addr_name_list: editingItem.asset_addr_name_list,
      asset_comps_list: editingItem.asset_comps_list,
      asset_name: editingItem.asset_name,
      status_name_list: editingItem.status_name_list,
      v_asset_name: editingItem.v_asset_name,
    });
    if (res.code === 0) {
      message.success('更新成功');
      fetchData(pagination.current, pagination.pageSize);
      setEditingItem(undefined);
    } else {
      message.error(res.message);
    }
    setFeching(false);
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await assetTemplateRequests.getAssetTemplateList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_assetComps && {
        asset_comps_list: filter_assetComps,
      }),
      // ...(filter_statusName && {
      //   status_name_list: filter_statusName,
      // }),
    });
    if (res.code === 0) {
      const vLengthList = res.data.map((x) => x.asset_addr_name_list.length);
      const compsLengthList = res.data.map((x) => x.asset_comps_list.length);
      const statusLengthList = res.data.map((x) => x.status_name_list.length);
      vLengthList.sort((a, b) => b - a);
      compsLengthList.sort((a, b) => b - a);
      statusLengthList.sort((a, b) => b - a);
      setMaxVStatusCount(vLengthList[0]);
      setMaxCopmsCount(compsLengthList[0]);
      setMaxStatusCount(statusLengthList[0]);
      setData(res.data);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);
  return (
    <div className="asset-template-list-page">
      <div className="tools-wrapper">
        <div className="asset-template-list-filter-input-container">
          <Form layout="inline" colon={false}>
            <Form.Item label="资产名称" className="filter-item">
              <AutoComplete
                style={{ minWidth: 168 }}
                placeholder="全部"
                allowClear
                onSearch={async (value) => {
                  if (strUtils.isNull(value)) {
                    return;
                  }
                  if (value) {
                    const res = await SuggestionRequests.getAssetNameSuggestion(value);
                    if (res.code === 0) {
                      setAssetNameOptions(res.data);
                    } else {
                    }
                  } else {
                    return;
                  }
                }}
                onChange={(value: string) => set_filter_assetName(value)}
                value={filter_assetName ? filter_assetName : undefined}
                options={assetNameOptions.map((x) => {
                  return {
                    label: x,
                    value: x,
                  };
                })}
              ></AutoComplete>
            </Form.Item>
            <Form.Item label="资产组件" className="filter-item">
              <Select
                mode="tags"
                onChange={(values: string[]) => {
                  set_filter_assetComps(values);
                }}
                style={{ minWidth: 168 }}
                value={filter_assetComps ? filter_assetComps : undefined}
                placeholder="全部"
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                    if (!filter_assetName) return;
                    const res = await SuggestionRequests.getAssetCompsSuggestion(filter_assetName);
                    if (res.code === 0) {
                      setAssetCompsOptions(arrSet(res.data));
                    } else {
                    }
                  }
                }}
              >
                {assetCompsOptions.map((x: string) => {
                  return (
                    <Select.Option value={x} key={`asset_comps_option:${x}`}>
                      {x}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* <Form.Item label="资产状态" className="filter-item">
              <Select
                mode="tags"
                onChange={(values: string[]) => {
                  set_filter_statusName(values)
                }}
                style={{ minWidth: 168 }}
                value={filter_statusName ? filter_statusName : undefined}
                placeholder="全部"
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                    if (!filter_assetName) return

                    const res = await SuggestionRequests.getAssetStatusSuggestion({
                      asset_name: filter_assetName,
                      asset_comps: filter_assetComps ? filter_assetComps : [],
                    })
                    if (res.code === 0) {
                      setStatusNameOptions(res.data.map((x) => x.asset_status))
                    } else {
                    }
                  }
                }}
              >
                {statusNameOptions.map((x: string) => {
                  return (
                    <Select.Option value={x} key={`asset_comps_option:${x}`}>
                      {x}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item> */}
          </Form>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Button
            type="primary"
            style={{ marginRight: 10 }}
            onClick={() => fetchData(pagination.current, pagination.pageSize)}
            icon={<SearchOutlined />}
          >
            查询
          </Button>

          <Button
            onClick={() => {
              setCreateVisible(true);
            }}
            icon={<PlusOutlined />}
          >
            创建资产模板
          </Button>
        </div>
      </div>
      <div>
        <Table
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
          style={{ backgroundColor: '#ffffff' }}
          rowKey={(record: AssetTemplateItem, idx) => `asset-template-${record.id}/${idx}`}
          dataSource={data}
          loading={loading}
          columns={getColumnConfigs<(typeof data)[any]>({
            onEvent: (evt: string, payload: AssetTemplateItem) => {
              switch (evt) {
                case 'start:update-asset':
                  setEditingItem(payload);

                  break;
                case 'start:delete-asset-success':
                  fetchData(pagination.current, pagination.pageSize);
                  break;
              }
            },
            maxVStatusCount: maxVStatusCount,
            maxCompsCount: maxCompsCount,
            maxStatusCount,
          })}
          onRow={(record) => {
            return {
              onDoubleClick: (event) => {
                setEditingItem(record);
              },
            };
          }}
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => fetchData(page, pageSize),
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            showQuickJumper: true,
            style: { marginRight: 16 },
          }}
          className="asset-template-table"
        />
      </div>
      {createVisible && (
        <Modal
          title={
            <span>
              <div className="title-prefix"></div>
              创建资产模板
            </span>
          }
          onCancel={handleCreateCancel}
          destroyOnClose
          footer={null}
          visible={createVisible}
          maskClosable={false}
        >
          <Form form={createForm} preserve={false} layout={'vertical'} onFinish={handleCreateOk}>
            <Form.Item label="资产名称" name="asset_name" rules={[{ required: true, message: '请输入资产名称！' }]}>
              <Input placeholder="请输入资产名称" />
            </Form.Item>
            <Form.Item
              label="资产组件"
              name="asset_comps_list"
              rules={[{ required: true, message: '请输入资产组件！' }]}
            >
              <Select mode="tags" placeholder="请输入资产组件" />
            </Form.Item>
            <Form.Item label="地理名称" name="v_asset_name" rules={[{ required: true, message: '请输入地理名称！' }]}>
              <Input placeholder="请输入地理名称" />
            </Form.Item>
            <Form.Item
              label="地理组件"
              name="asset_addr_name_list"
              rules={[{ required: true, message: '请输入地理组件！' }]}
            >
              <Select mode="tags" placeholder="请输入地理组件" />
            </Form.Item>
            <Form.Item
              label="资产状态名"
              name="status_name_list"
              rules={[{ required: true, message: '请输入资产状态名！' }]}
            >
              <Select mode="tags" placeholder="请输入资产状态名" />
            </Form.Item>
            <Form.Item noStyle>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  margin: '36px 0 24px 0',
                  padding: '0 100px',
                }}
              >
                <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                  确认
                </Button>
                <Button onClick={handleCreateCancel} style={{ padding: '0 36px' }}>
                  取消
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {!!editingItem && (
        <Drawer
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="title-prefix"></div>
              <EditableControl
                style={{ cursor: 'pointer', display: 'inline' }}
                inputProps={{
                  value: editingItem?.asset_name,
                  placeholder: '编辑资产名称',
                  style: { width: 160 },
                }}
                onFinishEditing={async (newVal: string) => {
                  if (newVal === editingItem.asset_name) return;
                  setEditingItem({
                    ...editingItem,
                    asset_name: newVal,
                  });
                }}
              >
                <span className="drawer-title">{editingItem.asset_name}</span>
                <span className="drawer-gray-btn">编辑</span>
              </EditableControl>
              <Divider type="vertical" />
              <CopiableControl style={{ cursor: 'pointer' }} content={editingItem.asset_name}>
                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </div>
          }
          width={'50vw'}
          drawerStyle={{ minWidth: 600 }}
          visible={!!editingItem}
          destroyOnClose={true}
          closable
          onClose={() => {
            Modal.confirm({
              title: '提示',
              content: '保存修改？',
              okText: '保存',
              cancelText: '不保存',
              onOk: () => {
                doUpdate();
              },
              onCancel: () => setEditingItem(undefined),
            });
          }}
          maskClosable={false}
        >
          <Form preserve={false}>
            <Form.Item label="模板编号" className="flex-form-item__vertical">
              <CopiableControl style={{ cursor: 'pointer' }} content={editingItem.uid}>
                <span style={{ marginRight: 10 }}>{editingItem.uid}</span>

                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </Form.Item>

            <Form.Item label="资产组件" className="flex-form-item__vertical">
              <div>
                {editingItem.asset_comps_list.map((x, idx) => {
                  return (
                    <Tag color="cyan" key={idx}>
                      {x}
                    </Tag>
                  );
                })}
                <span
                  className="drawer-gray-btn"
                  onClick={() => {
                    setEditCompsVisible(true);
                  }}
                >
                  编辑
                </span>
                <Divider type="vertical" />
                <CopiableControl style={{ cursor: 'pointer' }} content={editingItem.asset_comps_list.toString()}>
                  <span className="drawer-gray-btn">复制</span>
                </CopiableControl>
              </div>
            </Form.Item>
            <Form.Item
              label="地理信息"
              className="flex-form-item__vertical"
              extra={
                <div style={{ marginTop: 16 }}>
                  {editingItem.asset_addr_name_list.map((x, v_index) => {
                    return (
                      <Tag key={x} style={{ cursor: 'pointer' }}>
                        {x}
                      </Tag>
                    );
                  })}
                  {editingItem.asset_addr_name_list.length !== 0 && (
                    <Tag
                      style={{ cursor: 'pointer' }}
                      color="orange"
                      onClick={() => {
                        setAddVcompsVisible(true);
                      }}
                    >
                      <PlusOutlined type="plus" />
                      添加
                    </Tag>
                  )}
                </div>
              }
            >
              <EditableControl
                style={{ cursor: 'pointer', display: 'inline' }}
                inputProps={{
                  value: editingItem.v_asset_name,
                  placeholder: '编辑地理名称',
                  style: { width: 160 },
                }}
                onFinishEditing={async (newVal: string) => {
                  if (newVal === editingItem.v_asset_name) return;
                  setEditingItem({
                    ...editingItem,
                    v_asset_name: newVal,
                  });
                }}
              >
                <Tag color="#f39800" style={{ fontSize: 12 }}>
                  {editingItem.v_asset_name}
                </Tag>
                <span className="drawer-gray-btn">编辑</span>
              </EditableControl>
              <Divider type="vertical" />
              <CopiableControl
                style={{ cursor: 'pointer' }}
                content={`${editingItem.v_asset_name},${editingItem.asset_addr_name_list.map((x) => x)}`}
              >
                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </Form.Item>
            <Divider>资产状态</Divider>
            <Table
              dataSource={editingItem.status_name_list}
              columns={[
                {
                  title: '序号',
                  align: 'center' as 'center',
                  key: 'order',
                  render(_: any, record: StatusNameList, idx: number) {
                    return idx + 1;
                  },
                },
                {
                  title: '资产状态名',
                  align: 'center' as 'center',
                  key: 'status_name',
                  render(_: any, record: StatusNameList, idx: number) {
                    return record.status_name;
                  },
                },
                {
                  title: '状态值类型',
                  align: 'center' as 'center',
                  key: 'status_type',
                  render(_: any, record: StatusNameList, idx: number) {
                    return record.status_type;
                  },
                },
                {
                  title: '记录时间',
                  align: 'center' as 'center',
                  key: 'time',
                  render(_: any, record: StatusNameList, idx: number) {
                    return record.create_time;
                  },
                },
                {
                  title: '操作',
                  align: 'center' as 'center',
                  dataIndex: 'option',
                  key: 'option',
                  render(_: any, record: StatusNameList, idx: number) {
                    return (
                      <>
                        <span
                          className="drawer-text-btn"
                          onClick={() => {
                            setEditingStatus(record);
                          }}
                        >
                          修改
                        </span>
                        <Divider type="vertical" />
                        <span
                          className="drawer-text-btn"
                          onClick={() => {
                            let arr = editingItem.status_name_list.filter((x) => x.id !== record.id);
                            setEditingItem({
                              ...editingItem,
                              status_name_list: arr,
                            });
                          }}
                        >
                          删除
                        </span>
                      </>
                    );
                  },
                },
              ]}
              size="small"
              pagination={false}
            ></Table>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                type="primary"
                size="small"
                style={{ padding: '0 24px', borderRadius: '0px 0px 10px 10px' }}
                onClick={() => setAddStatusVisible(true)}
              >
                +
              </Button>
            </div>
          </Form>
          {editCompsVisible && (
            <Modal
              visible={editCompsVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>编辑资产组件
                </span>
              }
              onOk={() => {
                setEditCompsVisible(false);
              }}
              onCancel={() => {
                setEditCompsVisible(false);
              }}
            >
              <Form layout="vertical" preserve={false}>
                <Form.Item
                  label="资产组件"
                  name="asset_comps_list"
                  rules={[{ required: true, message: '请选择资产组件' }]}
                  initialValue={editingItem.asset_comps_list}
                >
                  <Select
                    onChange={(value: string[]) => {
                      setEditingItem({
                        ...editingItem,
                        asset_comps_list: value,
                      });
                    }}
                    mode="tags"
                  ></Select>
                </Form.Item>
              </Form>
            </Modal>
          )}
          {addVcompsVisible && (
            <Modal
              visible={addVcompsVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>编辑地理组件
                </span>
              }
              onOk={() => {
                setAddVcompsVisible(false);
              }}
              onCancel={() => {
                setAddVcompsVisible(false);
              }}
            >
              <Form layout="vertical" preserve={false}>
                <Form.Item
                  label="地理组件"
                  name="asset_addr_name_list"
                  rules={[{ required: true, message: '请选择地理组件组件' }]}
                  initialValue={editingItem.asset_addr_name_list}
                >
                  <Select
                    onChange={(value: string[]) => {
                      setEditingItem({
                        ...editingItem,
                        asset_addr_name_list: value,
                      });
                    }}
                    mode="tags"
                  ></Select>
                </Form.Item>
              </Form>
            </Modal>
          )}
          {!!editingStatus && (
            <Modal
              visible={!!editingStatus}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>编辑资产状态
                </span>
              }
              onOk={() => {
                setEditingStatus(undefined);
              }}
              onCancel={() => {
                setEditingStatus(undefined);
              }}
            >
              <Form layout="vertical" preserve={false}>
                <Form.Item
                  label="资产状态名"
                  name="status_name"
                  rules={[{ required: true, message: '请选择输入资产状态名' }]}
                  initialValue={editingStatus.status_name}
                >
                  <Input
                    onChange={(e) => {
                      const arr = editingItem.status_name_list.map((x) => {
                        if (x.id === editingStatus.id) {
                          return {
                            ...x,
                            status_name: e.target.value,
                          };
                        } else {
                          return x;
                        }
                      });

                      setEditingItem({
                        ...editingItem,
                        status_name_list: arr,
                      });
                    }}
                  ></Input>
                </Form.Item>
                <Form.Item
                  label="状态值类型"
                  name="status_type"
                  rules={[{ required: true, message: '请选择状态值类型' }]}
                  initialValue={editingStatus.status_type}
                >
                  <Select
                    onChange={(value: string) => {
                      const arr = editingItem.status_name_list.map((x) => {
                        if (x.id === editingStatus.id) {
                          return {
                            ...x,
                            status_type: value,
                          };
                        } else {
                          return x;
                        }
                      });

                      setEditingItem({
                        ...editingItem,
                        status_name_list: arr,
                      });
                    }}
                  >
                    <Select.Option value="数值">数值</Select.Option>
                    <Select.Option value="文本">文本</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Modal>
          )}
          {addStatusVisible && (
            <Modal
              visible={addStatusVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>添加资产状态
                </span>
              }
              footer={null}
              onCancel={() => {
                setAddStatusVisible(false);
              }}
            >
              <Form
                layout="vertical"
                preserve={false}
                onFinish={(values) => {
                  let arr = editingItem.status_name_list.map((x) => x);
                  arr.unshift({
                    ...({} as any),
                    status_name: values.status_name,
                    status_type: values.status_type,
                    create_time: new Date().toISOString,
                  });
                  setEditingItem({
                    ...editingItem,
                    status_name_list: arr,
                  });
                  setAddStatusVisible(false);
                }}
              >
                <Form.Item
                  label="资产状态名"
                  name="status_name"
                  rules={[{ required: true, message: '请选择输入资产状态名' }]}
                >
                  <Input placeholder="请输入资产状态名"></Input>
                </Form.Item>
                <Form.Item
                  label="状态值类型"
                  name="status_type"
                  rules={[{ required: true, message: '请选择状态值类型' }]}
                  initialValue="数值"
                >
                  <Select placeholder="请选择状态值类型">
                    <Select.Option value="数值">数值</Select.Option>
                    <Select.Option value="文本">文本</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginTop: 36,
                      padding: '0 100px',
                    }}
                  >
                    <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                      确认
                    </Button>
                    <Button onClick={() => setAddStatusVisible(false)} style={{ padding: '0 36px' }}>
                      取消
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
          )}
        </Drawer>
      )}
    </div>
  );
};

export default AssetTemplateList;
