/// <reference path="user.d.ts" />

import request from './_base';

export async function login(input: {
  username: string;
  password: string;
}): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  let formData = new FormData();
  formData.append('username', input.username);
  formData.append('password', input.password);
  return request({
    method: 'POST',
    // url: "/login",
    url: '/login',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    },
  }) as any;
}

export async function info(): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'GET',
    url: '/user/info',
  }) as any;
}

export async function logout(): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'POST',
    url: '/logout',
    data: {},
  }) as any;
}

export async function register(input: RegisterReq): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'POST',
    url: '/user/register',
    data: input,
  }) as any;
}
// 注册账号手机验证码
export async function getSmsCode({
  phone,
  checkCode,
}: {
  phone: string;
  checkCode: string;
}): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'GET',
    url: `/user/register/send/short/message/${phone}/${checkCode}`,
  }) as any;
}

export async function checkUsernameDuplicate(input: { username: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'POST',
    url: '/user/register/username/check',
    data: input,
  }) as any;
}

export async function modifyPassword(input: {
  old_password: string;
  password: string;
  sm_code: string;
}): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'PUT',
    url: '/user/update/password',
    data: input,
  }) as any;
}
//修改密码手机验证码
export async function getModifyPasswordSmsCode({
  phone,
}: {
  phone: string;
}): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'GET',
    url: `/user/update/password/short/message/${phone}`,
  }) as any;
}
//修改用户信息通用手机验证码
export async function getUpdateUserInfoSmsCode({
  phone,
}: {
  phone: string;
}): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'GET',
    url: `/user/phone/update/short/message/${phone}`,
  }) as any;
}

export async function resetPassword(input: {
  phone: string;
  sm_code: string;
  password: string;
}): Promise<AssetsResourceProto<{ data: string }>> {
  return request({
    method: 'PUT',
    url: `/user/register/retrieve/password`,
    data: input,
  }) as any;
}

export async function checkEmailDuplicate(input: { email: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'GET',
    url: `/user/register/email/check/${input.email}`,
  }) as any;
}

export async function checkPhoneDuplicate(input: { phone: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'GET',
    url: `/user/register/phone/check/${input.phone}`,
  }) as any;
}
//验证邀请码有效性
export async function checkInvitationCode(input: { code: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'GET',
    url: `/user/register/invitation/check/${input.code}`,
  }) as any;
}
//上传头像
export function uploadAvatar(input: {
  user_id: string;
  avatar: File;
}): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  let formData = new FormData();
  formData.append('user_id', input.user_id);
  formData.append('avatar', input.avatar);
  return request({
    method: 'POST',
    url: '/user/avatar',
    data: formData,
  }) as any;
}
//获取除登录账号歪的user list
export async function getUserList(input: {
  limit: number;
  skip: number;
  search_condition: string;
}): Promise<AssetsResourceProto<{ data: UserInfoItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: '/user/list',
    data: input,
  }) as any;
}

/**
 * 修改用户信息
 * @param  {string} company 公司名
 * @param  {string} company_user_id 工号
 * @param  {string} email 邮箱
 * @param  {string} gender 性别
 * @param  {string} phone 手机号 // 若修改phone,则必须有sm_code,此sm_code是用于验证修改后phone,若只修改phone,其余字段置空.若修改其余字段,phone和sm_code置空
 * @param  {string} username 用户名
 * @param  {string} sm_code 验证码
 */
export async function updateUserInfo(input: UpdateUserInfoReq): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'PUT',
    url: `/user/update`,
    data: input,
  }) as any;
}

/**
 * 获取所有用户列表
 * @param  {GetAllUsersReq} input 

 */
export async function getAllUsers(
  input: GetAllUsersReq
): Promise<AssetsResourceProto<{ data: UserInfoItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/user/all`,
    data: input,
  }) as any;
}

/** =========权限相关=========== */

/**
 * 给用户赋予角色
 * @param  {GrantRoleToUserReqItem[]} input
 */
export async function grantRoleToUser(input: GrantRoleToUserReqItem[]): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/user/grant/roles`,
    data: input,
  }) as any;
}

/**
 * 删除用户角色
 * @param  {GrantRoleToUserReqItem[]} input
 */
export async function deleteUserRole(input: GrantRoleToUserReqItem[]): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/user/grant/roles`,
    data: input,
  }) as any;
}

/**
 * 更新user和role的绑定关系
 * @param  {GrantRoleToUserReqItem} input
 */
export async function updateUserRole(input: GrantRoleToUserReqItem): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/user/roles`,
    data: input,
  }) as any;
}

/**
 * 根据id获取用户的sysMenu
 * @param  {number} id
 */
export async function getUserSysMenuInfo(id: number): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'GET',
    url: `/user/sysmenu/${id}`,
  }) as any;
}

/**
 * 根据userId查询userName,userCompanyId 注意: userId为userId 不是user的id也不是userCompanyId
 * @param  {GetCompanyUserIdsReq} input
 */
export async function getUserCompanyIDInfo(
  input: GetCompanyUserIdsReq
): Promise<AssetsResourceProto<{ data: CompanyUserIdInfoItem[] }>> {
  return request({
    method: 'POST',
    url: `/user/company_user_id`,
    data: input,
  }) as any;
}

/**
 * 根据userId查询userInfo
 * @param  {string} user_id
 */
export async function getUserInfoByUserId(user_id: string): Promise<AssetsResourceProto<{ data: UserInfoItem }>> {
  return request({
    method: 'GET',
    url: `/user/info/${user_id}`,
  }) as any;
}

/**
 * 根据user_id删除user
 * @param  {UserInfoItem['id']} id
 */
export async function deleteUser(id: UserInfoItem['id']): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/user/deleteUser/${id}`,
  }) as any;
}

/**
 * 激活或者停用用户
 * @param  {ChangeUserStatusReq} input
 */
export async function changeUserStatus(input: ChangeUserStatusReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/user/deactivateOrActivateUsers`,
    data: input,
  }) as any;
}
