import * as React from 'react';
import {
  Form,
  Modal,
  Tooltip,
  Tag,
  DatePicker,
  Dropdown,
  Menu,
  Divider,
  Avatar,
  Input,
  Button,
  message,
  Radio,
  Select,
  Upload,
  List,
  notification,
  InputNumber,
  Space,
  Skeleton,
  Checkbox,
} from 'antd';
import EditableControl from '@/ui-components/EditableControl';
import '../styles/taskDetail.less';
import {
  BulbOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  DisconnectOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  LeftOutlined,
  LikeOutlined,
  LinkOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  RedoOutlined,
  RightOutlined,
  RollbackOutlined,
  SearchOutlined,
  SnippetsOutlined,
  StarOutlined,
  TagOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  getTaskById,
  getMembersById,
  getProjectAllTags,
  createTaskTag,
  getTaskTags,
  updateTaskTag,
  updateTask,
  assignTask,
  addWorkTime,
  getWorkTimeByTaskId,
  deleteWorkTime,
  getTaskFiles,
  updateWorkTime,
  getTaskMembers,
  removeTaskMember,
  joinTaskMember,
  getExecuteStatusList,
  getProjectWorkTimeTypeList,
  deleteTaskFlile,
  getProjectLogs,
  commentTask,
  createTask,
  getTaskList,
  retractCommentTask,
} from '@/requests/project';

import moment from 'moment';
import FileViewer from '@/ui-components/FileViewer';
import { download } from '@/utils/string';
import { useCtxState } from '@/App.store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { userInfo } from 'os';

interface PropsType {
  visible: boolean;
  setVisible: Function;
  _task: ProjectTaskItem;
  stage: TaskStagesItem;
  project: ProjectItem;
  onclose: Function;
}
interface TagSelectorPorpsType {
  task: ProjectTaskItem;
}

interface SetPlanHourModalPorps {
  visible: boolean;
  setVisible: Function;
  _task: ProjectTaskItem;
  fetchData: Function;
}
enum ExecuteStatus {
  '未开始' = 0,
  '已完成',
  '进行中',
  '挂起',
  '测试中',
}
enum PriorityLevel {
  '普通' = 0,
  '紧急',
  '非常紧急',
}
enum TaskLogType {
  '所有动态' = 0,
  '仅评论',
  '仅动态',
}

const holiday = ['无', '元旦', '春节', '清明节', '劳动节', '端午节', '中秋节', '国庆节', '妇女节'];
interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  is_join: number;
}

interface OptionType extends TaskTagItem {
  existed: boolean;
}

interface AddWorkTimeModalPorps {
  visible: boolean;
  setVisible: Function;
  _task: ProjectTaskItem;
  fetchData: Function;
  workTimes: WorkTimeItem[];
}

interface UpdateWorkTimeModalPorps {
  visible: boolean;
  setVisible: Function;
  _task: ProjectTaskItem;
  fetchData: Function;
  data: WorkTimeItem;
  workTimes: WorkTimeItem[];
}

interface AddChildTaskWidgetProps {
  visible: boolean;
  setVisible: Function;
  _task: ProjectTaskItem;
  fetchData: Function;
  taskMembers: Member[];
}

//生成优先级标签
const PriorityLevelTag = ({ level }: { level: number }) => {
  let color = '';
  if (level === 0) {
    color = 'green';
  }
  if (level === 1) {
    color = 'rgb(255, 153, 0)';
  }
  if (level === 2) {
    color = 'rgb(237, 63, 20)';
  }
  return (
    <Tag onClick={(e) => e.preventDefault()} color={color} style={{ cursor: 'pointer', borderRadius: 5 }}>
      {PriorityLevel[level]}
    </Tag>
  );
};

//根据时间string获取距离今天时间描述
const getDayDesc = (date: string) => {
  const _date = moment(date);
  const time = _date.format('HH:mm');
  const week = _date.format('dddd');
  const __date = _date.format('MM月DD日');
  const now = moment();
  const diff = now.diff(_date, 'days');
  if (diff === 0) {
    return '今天' + time;
  }
  if (diff === 1) {
    return '昨天' + time;
  }
  if (diff === 2) {
    return '前天' + time;
  }
  if (diff > 2 && diff < 7) {
    return week + time;
  }
  if (diff >= 7 && diff < 30) {
    return __date + time;
  }
  if (diff >= 30 && diff < 365) {
    return __date + time;
  }
  if (diff >= 365) {
    return __date + time;
  }
  return '';
};

//根据log_code获取log icon
const getLogIcon = (code: number) => {
  switch (code) {
    case 1:
      return <PlusOutlined />;
    case 6:
      return <CheckOutlined />;
    case 7:
      return <RedoOutlined />;
    case 8:
      return <PlusOutlined />;
    case 18:
      return <UserDeleteOutlined />;
    case 17:
      return <UserAddOutlined />;
    case 19:
      return <CalendarOutlined />;
    case 20:
      return <CalendarOutlined />;
    case 21:
      return <CalendarOutlined />;
    case 22:
      return <CalendarOutlined />;
    case 25:
      return <ClockCircleOutlined />;
    case 23:
      return <DeleteOutlined />;
    case 26:
      return <LinkOutlined />;
    case 27:
      return <DisconnectOutlined />;
    default:
      return <UserOutlined />;
  }
};

const TagSelector = (props: TagSelectorPorpsType) => {
  const { task } = props;
  const [options, setOptions] = React.useState<OptionType[]>([]);
  const [showCreator, setShowCreator] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [color, setColor] = React.useState('#1890ff');
  const [tagName, setTagName] = React.useState('');
  const [existTags, setExistTags] = React.useState<TaskTagItem[]>([]);

  const fetchAllTags = async () => {
    if (!task) return;
    const res = await getProjectAllTags({
      project_id: task.project_id,
    });
    if (res.code === 0) {
      setOptions(
        res.data.map((x) => {
          const _exist = existTags.find((_tag) => _tag.id === x.id);
          return {
            ...x,
            existed: _exist ? true : false,
          };
        })
      );
    } else {
      message.error('获取标签列表失败');
    }
  };

  const doCreate = async () => {
    if (!task) return;
    setFetching(true);
    const res = await createTaskTag({
      project_id: task.project_id,
      tag_name: tagName,
      tag_color: color,
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchAllTags();
      setShowCreator(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const fetchExistTags = async () => {
    if (!task) return;
    const res = await getTaskTags(task.id);
    if (res.code === 0) {
      setExistTags(res.data);
      setOptions(
        options.map((x) => {
          const _exist = res.data.find((_tag) => _tag.id === x.id);
          return {
            ...x,
            existed: _exist ? true : false,
          };
        })
      );
    } else {
      message.error(res.message);
    }
  };

  const updateTaskTags = async (input: UpdateTaskTagsReq) => {
    const res = await updateTaskTag(input);
    if (res.code === 0) {
      message.success('保存成功');
      fetchExistTags();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchExistTags();
    fetchAllTags();
  }, [task]);

  const colorOptions = ['#1890ff', '#f5222d', '#f90', '#52c41a', '#2fbdb3', '#797ec9'];

  const handleCreateCancel = () => {
    setShowCreator(false);
  };
  const handleCreateTagClick = () => {
    setShowCreator(true);
  };
  return (
    <Dropdown
      visible={visible}
      overlay={
        <div
          style={{ backgroundColor: '#ffffff', boxShadow: '0 2px 20px rgb(0 0 0 / 10%)', borderRadius: 4, width: 250 }}
        >
          {showCreator && (
            <div>
              <Form style={{ width: 250, paddingBottom: 1 }}>
                <Form.Item noStyle>
                  <Input
                    prefix={<TagOutlined />}
                    style={{ height: 40, margin: 12, width: 226 }}
                    placeholder="标签名称"
                    value={tagName}
                    onChange={(e) => {
                      setTagName(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item noStyle>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: 12 }}>
                    {colorOptions.map((_color) => {
                      return (
                        <div
                          style={{
                            width: 25,
                            height: 25,
                            backgroundColor: _color,
                            borderRadius: 25,
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          key={_color}
                          onClick={() => setColor(_color)}
                        >
                          {color === _color ? <CheckOutlined style={{ color: '#ffffff' }} /> : null}
                        </div>
                      );
                    })}
                  </div>
                </Form.Item>
                <Divider style={{ margin: 0 }}></Divider>
                <Form.Item noStyle>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: 12,
                    }}
                  >
                    <Button onClick={handleCreateCancel} style={{ padding: '0 36px', borderRadius: 5 }}>
                      取消
                    </Button>
                    <Button
                      type="primary"
                      style={{ padding: '0 36px', borderRadius: 5 }}
                      loading={fetching}
                      disabled={!tagName}
                      onClick={doCreate}
                    >
                      创建
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
          {!showCreator && (
            <>
              <div style={{ padding: 12 }}>
                <Input prefix={<SearchOutlined />} style={{ height: 40 }} placeholder="搜索标签" />
              </div>
              <div style={{ height: 250, overflow: 'scroll' }}>
                <Menu style={{ margin: 0, padding: 0 }} selectable>
                  {options.map((option) => {
                    return (
                      <Menu.Item
                        key={option.id}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          padding: 12,
                        }}
                        onClick={() => {
                          let input: UpdateTaskTagsReq = existTags.map((x) => {
                            return {
                              task_id: task.id,
                              task_tag_id: x.id,
                            };
                          });
                          if (option.existed) {
                            input = input.filter((x) => x.task_tag_id !== option.id);
                          } else {
                            input.push({
                              task_id: task.id,
                              task_tag_id: option.id,
                            });
                          }
                          updateTaskTags(input);
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <div
                            style={{
                              width: 7,
                              height: 7,
                              borderRadius: 7,
                              backgroundColor: option.tag_color,
                              marginRight: 12,
                            }}
                          ></div>
                          <span style={{ color: '#333', fontWeight: 500 }}> {option.tag_name}</span>
                        </div>
                        {option.existed && <CheckOutlined />}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
              <Divider style={{ margin: 0 }}></Divider>
              <div
                style={{
                  height: 60,
                  display: 'flex',
                  padding: 12,
                }}
              >
                <Button
                  type="primary"
                  style={{ width: '100%', borderRadius: 5, height: 40 }}
                  onClick={handleCreateTagClick}
                >
                  新建标签
                </Button>
              </div>
            </>
          )}
        </div>
      }
      onVisibleChange={(open) => {
        console.log(open);
        if (open) {
          fetchAllTags();
        }
        if (!open) {
          setVisible(false);
        }
      }}
      trigger={['click']}
    >
      <div>
        {existTags.map((tag) => {
          return (
            <Tag
              color={tag.tag_color}
              key={tag.id}
              closable
              onClose={() => {
                let input: UpdateTaskTagsReq = existTags.map((x) => {
                  return {
                    task_id: task.id,
                    task_tag_id: x.id,
                  };
                });
                input = input.filter((x) => x.task_tag_id !== tag.id);
                updateTaskTags(input);
              }}
            >
              {tag.tag_name}
            </Tag>
          );
        })}
        {
          <PlusCircleTwoTone
            twoToneColor={'#f39800'}
            style={{ cursor: 'pointer' }}
            onClick={() => setVisible(!visible)}
          />
        }
      </div>
    </Dropdown>
  );
};

const SetPlanHourModal = (props: SetPlanHourModalPorps) => {
  const { visible, setVisible, _task, fetchData } = props;

  const [task, setTask] = React.useState<ProjectTaskItem>();
  const [fetching, setFetching] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    setTask(_task);
  }, [_task]);

  const handleCancel = () => {
    setVisible(false);
  };

  const doUpdate = async (values: any) => {
    if (!_task) return;
    setFetching(true);
    const res = await updateTask({
      id: _task.id,
      plan_hour: values['plan_hour'],
    });
    setFetching(false);
    if (res.code === 0) {
      message.success('设置成功');
      fetchData(_task.id);
      setVisible(false);
    }
  };

  return (
    <Modal title="设置计划工时" visible={visible} onCancel={handleCancel} footer={null} destroyOnClose>
      <Form form={form} onFinish={doUpdate}>
        <Form.Item
          name="plan_hour"
          label="计划工时"
          rules={[{ required: true, message: '请输入计划工时' }]}
          initialValue={_task.plan_hour}
        >
          <Input style={{ width: '100%' }} suffix={<span>小时</span>} />
        </Form.Item>
        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCancel} style={{ marginRight: 16 }}>
              取消
            </Button>
            <Button type="primary" loading={fetching} htmlType="submit">
              确定
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddWorkTimeModal = (props: AddWorkTimeModalPorps) => {
  const { visible, setVisible, _task, fetchData, workTimes } = props;
  const [taskHourTypeList, setTaskHourTypeList] = React.useState<ProjectWorkTimeTypeItem[]>([]);
  const [task, setTask] = React.useState<ProjectTaskItem>();
  const [fetching, setFetching] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    setTask(_task);
    fetchTaskHourTypeList(_task.project_id);
  }, [_task]);

  const handleCancel = () => {
    setVisible(false);
  };

  //根据projectId查询项目下的任务工时种类list
  const fetchTaskHourTypeList = async (projectId: number) => {
    const res = await getProjectWorkTimeTypeList({
      project_id: projectId,
      limit: 10000000,
      skip: 0,
    });
    if (res.code === 0) {
      setTaskHourTypeList(res.data);
    }
  };

  const doAdd = async (values: any) => {
    if (!_task) return;
    setFetching(true);
    let endtime = new Date(moment(values['begin_time']).format('YYYY-MM-DD HH:mm:ss'));

    endtime.setHours(endtime.getHours() + Number(values['real_hour']));

    const res = await addWorkTime({
      task_id: _task.id,
      begin_time: moment(values['begin_time']).format('YYYY-MM-DD HH:mm:ss'),
      real_hour: values['real_hour'],
      man_hours_type: values['man_hours_type'].toString(),
      man_hours_definition: values['man_hours_definition'],
      content: values['content'],
      remark: '',
      end_time: moment(endtime).format('YYYY-MM-DD HH:mm:ss'),
    });
    if (res.code === 0) {
      message.success('添加成功');
      fetchData(_task.id);
      setVisible(false);
    } else {
      message.error('网络错误');
    }
    setFetching(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinishi = (values: any) => {
    doAdd(values);
  };
  if (!task) return null;
  return (
    <Modal
      title="登记工时记录"
      visible={visible}
      okText="确定"
      cancelText="取消"
      onCancel={handleCancel}
      width={520}
      onOk={handleOk}
      okButtonProps={{ loading: fetching }}
      cancelButtonProps={{ disabled: fetching }}
    >
      <Form layout="vertical" onFinish={onFinishi} form={form}>
        <div>
          <div style={{ fontSize: 15, color: '#333' }}>登记任务</div>
          <div>{task.task_name}</div>
        </div>
        <Divider style={{ margin: '5px 0px 20px 0px' }}></Divider>
        <div style={{ display: 'flex', marginBottom: 18 }}>
          <div style={{ marginRight: 36 }}>
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,.45)' }}>预估总工时</div>
            <div>
              <span style={{ fontSize: 22, fontWeight: 500, fontFamily: 'dinmedium' }}>{task.plan_hour} </span>
              小时
            </div>
          </div>
          <div>
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,.45)' }}>实际工时</div>
            <div>
              <span style={{ fontSize: 22, fontWeight: 500, fontFamily: 'dinmedium' }}>
                {workTimes.length > 0 ? workTimes.map((x) => x.real_hour).reduce((pre, cur) => pre + cur) : 0}
              </span>
              小时
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            label="开始时间"
            rules={[
              {
                required: true,
                message: '请选择开始时间',
              },
            ]}
            name="begin_time"
            initialValue={moment(new Date())}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item
            label="消耗时间"
            rules={[
              {
                required: true,
                message: '请输入消耗时间',
              },
            ]}
            name="real_hour"
          >
            <Input placeholder="请输入数字" suffix={<span>小时</span>} />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            label="班次类型"
            rules={[
              {
                required: true,
                message: '请选择班次类型',
              },
            ]}
            name="man_hours_type"
            initialValue={1}
          >
            <Radio.Group>
              {taskHourTypeList.map((x) => {
                return (
                  <Radio value={x.hours_type} key={x.id}>
                    {x.hours_type}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="节假日类型"
            rules={[
              {
                required: true,
                message: '请输入节假日类型',
              },
            ]}
            name="man_hours_definition"
            initialValue={'无'}
          >
            <Select style={{ width: 210 }}>
              {holiday.map((x) => {
                return (
                  <Select.Option value={x} key={x}>
                    {x}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="工作内容"
          name="content"
          rules={[
            {
              required: true,
              message: '请输入工作内容',
            },
          ]}
        >
          <Input.TextArea rows={4} placeholder="在这期间做了什么"></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};
const UpdateWorkTimeModal = (props: UpdateWorkTimeModalPorps) => {
  const { visible, setVisible, _task, fetchData, data, workTimes } = props;
  const [taskHourTypeList, setTaskHourTypeList] = React.useState<ProjectWorkTimeTypeItem[]>([]);
  const [task, setTask] = React.useState<ProjectTaskItem>();
  const [fetching, setFetching] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    setTask(_task);
    fetchTaskHourTypeList(_task.project_id);
  }, [_task]);

  const handleCancel = () => {
    setVisible(false);
  };

  //根据projectId查询项目下的任务工时种类list
  const fetchTaskHourTypeList = async (projectId: number) => {
    const res = await getProjectWorkTimeTypeList({
      project_id: projectId,
      limit: 10000000,
      skip: 0,
    });
    if (res.code === 0) {
      setTaskHourTypeList(res.data);
    }
  };

  const doUpdate = async (values: any) => {
    if (!_task) return;
    setFetching(true);
    let endtime = new Date(moment(values['begin_time']).format('YYYY-MM-DD HH:mm:ss'));
    console.log(values, endtime, values['begin_time']);
    endtime.setHours(endtime.getHours() + Number(values['real_hour']));

    const res = await updateWorkTime({
      id: data.id,
      task_id: _task.id,
      begin_time: moment(values['begin_time']).format('YYYY-MM-DD HH:mm:ss'),
      real_hour: values['real_hour'],
      man_hours_type: values['man_hours_type'].toString(),
      man_hours_definition: values['man_hours_definition'],
      content: values['content'],
      remark: '',
      end_time: moment(endtime).format('YYYY-MM-DD HH:mm:ss'),
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchData(_task.id);
      setVisible(false);
    } else {
      message.error('网络错误');
    }
    setFetching(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onFinishi = (values: any) => {
    doUpdate(values);
  };
  if (!task) return null;
  return (
    <Modal
      title="登记工时记录"
      visible={visible}
      okText="确定"
      cancelText="取消"
      onCancel={handleCancel}
      width={520}
      onOk={handleOk}
      okButtonProps={{ loading: fetching }}
      cancelButtonProps={{ disabled: fetching }}
    >
      <Form layout="vertical" onFinish={onFinishi} form={form}>
        <div>
          <div style={{ fontSize: 15, color: '#333' }}>登记任务</div>
          <div>{task.task_name}</div>
        </div>
        <Divider style={{ margin: '5px 0px 20px 0px' }}></Divider>
        <div style={{ display: 'flex', marginBottom: 18 }}>
          <div style={{ marginRight: 36 }}>
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,.45)' }}>预估总工时</div>
            <div>
              <span style={{ fontSize: 22, fontWeight: 500, fontFamily: 'dinmedium' }}>{task.plan_hour} </span>
              小时
            </div>
          </div>
          <div>
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,.45)' }}>实际工时</div>
            <div>
              <span style={{ fontSize: 22, fontWeight: 500, fontFamily: 'dinmedium' }}>
                {workTimes.map((x) => x.real_hour).reduce((pre, cur) => pre + cur)}{' '}
              </span>
              小时
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            label="开始时间"
            rules={[
              {
                required: true,
                message: '请选择开始时间',
              },
            ]}
            name="begin_time"
            initialValue={moment(data.begin_time)}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item
            label="消耗时间"
            rules={[
              {
                required: true,
                message: '请输入消耗时间',
              },
            ]}
            name="real_hour"
            initialValue={data.real_hour}
          >
            <Input placeholder="请输入数字" suffix={<span>小时</span>} />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item
            label="班次类型"
            rules={[
              {
                required: true,
                message: '请选择班次类型',
              },
            ]}
            name="man_hours_type"
            initialValue={data.man_hours_type}
          >
            <Radio.Group>
              {taskHourTypeList.map((x) => {
                return (
                  <Radio value={x.hours_type} key={x.id}>
                    {x.hours_type}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="节假日类型"
            rules={[
              {
                required: true,
                message: '请输入节假日类型',
              },
            ]}
            name="man_hours_definition"
            initialValue={data.man_hours_definition}
          >
            <Select style={{ width: 210 }}>
              {holiday.map((x) => {
                return (
                  <Select.Option value={x} key={x}>
                    {x}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          label="工作内容"
          name="content"
          rules={[
            {
              required: true,
              message: '请输入工作内容',
            },
          ]}
          initialValue={data.content}
        >
          <Input.TextArea rows={4} placeholder="在这期间做了什么"></Input.TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddChildTaskWidget = (props: AddChildTaskWidgetProps) => {
  const { visible, setVisible, _task, fetchData, taskMembers } = props;
  const [{ sysUsers, userInfo }] = useCtxState();
  const [assignTo, setAssignTo] = React.useState<number>();
  const [taskName, setTaskName] = React.useState<string>();
  const userSelector = (
    <div style={{ display: 'inline-block' }}>
      <Dropdown
        overlay={
          <Menu>
            <div style={{ padding: 12 }}>
              <Input prefix={<SearchOutlined />} style={{ height: 40 }} placeholder="搜索" />
            </div>
            <div style={{ height: 300, overflow: 'scroll' }}>
              <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>执行者</span>

              <div style={{ width: 250, padding: 12 }}>
                {assignTo ? (
                  <span>
                    <Avatar
                      style={{ marginRight: 8 }}
                      src={`v2/api/user/avatar/${taskMembers.filter((x) => x.user_id === assignTo)[0].avatar}`}
                    ></Avatar>
                    {taskMembers.filter((x) => x.user_id === assignTo)[0].user_name}
                  </span>
                ) : (
                  <span>
                    <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}></Avatar>
                    待认领
                  </span>
                )}
              </div>
              <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>其他参与者</span>
              <div>
                {taskMembers
                  .filter((x) => x.user_id !== assignTo)
                  .map((user) => {
                    return (
                      <div
                        key={user.user_id}
                        style={{ width: 250, padding: 12, cursor: 'pointer' }}
                        onClick={() => setAssignTo(user.user_id)}
                        className="user-item"
                      >
                        <Avatar style={{ marginRight: 8 }} src={`v2/api/user/avatar/${user.avatar}`}></Avatar>
                        {user.user_name}
                      </div>
                    );
                  })}
              </div>
            </div>
            <Divider style={{ margin: 0 }}></Divider>
            <div
              style={{
                height: 60,
                display: 'flex',
                padding: 12,
              }}
            >
              <Button type="primary" style={{ width: '100%', borderRadius: 5, height: 40 }}>
                邀请新成员
              </Button>
            </div>
          </Menu>
        }
        placement="bottomLeft"
        trigger={['click']}
      >
        <Tooltip title="点击设置执行者">
          {assignTo ? (
            <span style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}>
              <Avatar
                style={{ marginRight: 8 }}
                size={'small'}
                src={`v2/api/user/avatar/${taskMembers.filter((x) => x.user_id === assignTo)[0].avatar}`}
              ></Avatar>
            </span>
          ) : (
            <Avatar size={'small'} icon={<UserOutlined />} style={{ marginRight: 8 }}></Avatar>
          )}
        </Tooltip>
      </Dropdown>
    </div>
  );

  const handleOk = () => {
    doAddTask();
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const doAddTask = async () => {
    if (!taskName) return message.error('请输入任务标题');
    const res = await createTask({
      task_name: taskName,
      parent_task_id: _task.id,
      assign_to: assignTo,
      project_id: _task.project_id,
      task_stage_id: _task.task_stage_id,
    });
    if (res.code === 0) {
      message.success('添加成功');
      fetchData();
      setVisible(false);
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    if (userInfo) {
      setAssignTo(userInfo.id);
    }
  }, []);

  if (!visible) return null;
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', padding: '4px 12px 4px 5px' }}>
        {userSelector}
        <Input
          style={{ marginLeft: 4 }}
          value={taskName}
          placeholder="输入任务标题"
          onChange={(e) => {
            setTaskName(e.target.value);
          }}
        ></Input>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 12 }}>
        <Button type="text" style={{ borderRadius: 5 }} onClick={handleCancel}>
          取消
        </Button>
        <Button type="primary" style={{ borderRadius: 5 }} onClick={handleOk}>
          保存
        </Button>
      </div>
    </div>
  );
};
const TaskDetailModal = (props: PropsType) => {
  let { visible, setVisible, _task, stage, project, onclose } = props;
  const [{ sysUsers }] = useCtxState();
  const [userOptions, setUserOptions] = React.useState<Member[]>([]);
  const [showRemark, setShowRemark] = React.useState(false);
  const [addWorkTimeVisible, setAddWorkTimeVisible] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [task, setTask] = React.useState<ProjectTaskItem>();
  const [remark, setRemark] = React.useState(task ? task.remark : '');
  const [workTimes, setWorkTimes] = React.useState<WorkTimeItem[]>([]);
  const [files, setFiles] = React.useState<TaskFileItem[]>([]);
  const [taskMembers, setTaskMembers] = React.useState<Member[]>([]);
  const [editingWorkTime, setEditingWorkTime] = React.useState<WorkTimeItem>();
  const [editingVisible, setEditingVisible] = React.useState(false);
  const [setPlanHourVisible, setSetPlanHourVisible] = React.useState(false);
  const [executeStatus, setExecuteStatus] = React.useState<ExecuteStatusItem[]>([]);
  const [taskLogs, setTaskLogs] = React.useState<ProjectLogItem[]>([]);
  const [taskLogType, setTaskLogType] = React.useState<number>(0);
  const [logPagination, setLogPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [showAddChildTask, setShowAddChildTask] = React.useState(false);

  const [comment, setComment] = React.useState('');
  const [childTasks, setChildTasks] = React.useState<ProjectTaskItem[]>([]);
  const [taskId, setTaskId] = React.useState<ProjectTaskItem['id']>();
  const [parentTask, setParentTask] = React.useState<ProjectTaskItem>();

  const handleCancel = () => {
    setVisible(false);
  };
  //获取子任务列表
  const fetchChildTaskList = async () => {
    const res = await getTaskList({
      project_id: project.id,
      parent_task_id: taskId,
      limit: 10000,
      skip: 0,
    });
    if (res.code === 0) {
      setChildTasks(res.data);
    } else {
      message.error(res.message);
    }
  };
  //获取任务工时
  const fetchWorkTime = async (id: number) => {
    const res = await getWorkTimeByTaskId(id);
    if (res.code === 0) {
      setWorkTimes(res.data);
    } else {
      message.error('获取工时信息失败');
    }
  };
  //获取关联文件
  const fetchfiles = async (id: number) => {
    const res = await getTaskFiles({
      task_id: id,
      limit: 1000000,
      skip: 0,
    });
    if (res.code === 0) {
      setFiles(res.data);
    } else {
      message.error('获取关联文件失败');
    }
  };
  //获取项目成员列表
  const fetchProjectMembers = async () => {
    if (!project) return;
    const memRes = await getMembersById(project.id);
    if (memRes.code === 0) {
      setUserOptions(
        memRes.data.map((x) => {
          const match = sysUsers.find((y: UserInfoItem) => y.id === x.user_id) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            is_join: 1,
          };
        })
      );
    } else {
      message.error('获取成员列表失败');
    }
  };
  //获取任务详情
  const fetchData = async (id: number) => {
    const res = await getTaskById(id);
    if (res.code === 0) {
      setTask(res.data);
      setFiles(res.data.team_file_list);
      setWorkTimes(res.data.team_task_work_time_list);
      const users = res.data.team_task_member_list
        .map((x) => x.member_id)
        .concat([res.data.assign_to])
        .map((x) => {
          const match = sysUsers.find((y: UserInfoItem) => y.id === x) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            is_join: 1,
          };
        });

      setTaskMembers(users);
      setTaskLogs(res.data.log_list);
    } else {
      message.error('获取任务失败');
    }
  };

  //获取父任务信息
  const fetchParentTask = async (parent_task_id: number) => {
    if (parent_task_id === 0) {
      return;
    }
    const res = await getTaskById(parent_task_id);
    if (res.code === 0) {
      setParentTask(res.data);
    } else {
      message.error('获取父任务失败');
    }
  };
  //更新任务
  const doUpdateTask = async (input: UpdateTaskReq) => {
    setFetching(true);
    if (!task) return;
    const res = await updateTask(input);
    if (res.code === 0) {
      fetchData(task.id);
      message.success('保存成功');
    } else {
      message.error('网络错误');
    }
    setFetching(false);
  };
  //更新子任务完成状态
  const doUpdateChildTaskStatus = async (id: number, status: boolean) => {
    const res = await updateTask({
      id,
      done: status,
    });
    if (res.code === 0) {
      message.success('保存成功');
      fetchChildTaskList();
    } else {
      message.error('网络错误');
    }
  };
  //分配任务
  const doAssignTask = async (user_id: number) => {
    if (!task) return;
    const res = await assignTask({
      id: task.id,
      user_id,
    });

    if (res.code === 0) {
      message.success('保存成功');
      fetchData(task.id);
    } else {
      message.error('网络错误');
    }
  };
  //删除任务
  const doDeleteWorkTime = (id: number) => {
    if (!task) return;
    Modal.confirm({
      title: '提示',
      content: '确认删除该条工时记录吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const res = await deleteWorkTime(id);
        if (res.code === 0) {
          message.success('删除成功');
          fetchWorkTime(task.id);
        } else {
          message.error('网络错误');
        }
      },
      onCancel: () => {},
    });
  };
  //获取任务参与人
  const fetchTaskMembers = async (id: number) => {
    if (!task) return;
    const res = await getTaskMembers(id);
    if (res.code === 0) {
      const users = res.data
        .map((x) => x.member_id)
        .concat([task?.assign_to])
        .map((x) => {
          const match = sysUsers.find((y: UserInfoItem) => y.id === x) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            is_join: 1,
          };
        });
      setTaskMembers(users);
    } else {
      message.error('获取任务成员列表失败');
    }
  };
  //移除任务参与人
  const doRemoveTaskMember = async (user_id: number) => {
    if (!task) return;
    const res = await removeTaskMember(task.id, user_id);
    if (res.code === 0) {
      message.success('移除成功');
      fetchTaskMembers(task.id);
    } else if (res.code === 10003) {
      message.error('不能移除创建人或执行者');
    } else {
      message.error('网络错误');
    }
  };
  //添加任务参与人
  const doAddTaskMember = async (user_id: number) => {
    if (!task) return;
    const res = await joinTaskMember(task.id, user_id);
    if (res.code === 0) {
      message.success('添加成功');
      fetchTaskMembers(task.id);
    } else {
      message.error('网络错误');
    }
  };
  //根据projectId查询项目下的任务执行状态
  const fetchTaskStatus = async () => {
    if (!project) return;
    const res = await getExecuteStatusList({ project_id: project.id, limit: 1000000, skip: 0 });
    if (res.code === 0) {
      setExecuteStatus(res.data);
    } else {
      message.error('获取任务状态失败');
    }
  };

  //取消任务文件关联
  const doCancelFile = async (file_id: string) => {
    if (!task) return;
    const res = await deleteTaskFlile(task.id, file_id);
    if (res.code === 0) {
      message.success('取消关联成功');
      fetchfiles(task.id);
    } else {
      message.error('网络错误');
    }
  };
  //获取项目日志
  const fetchLogs = async (type: number, task_id?: number) => {
    if (!task_id) return;
    setFetching(true);
    const res = await getProjectLogs({ task_id, limit: logPagination.pageSize, skip: taskLogs.length });
    if (res.code === 0) {
      let _logs = [];
      if (type === 2) {
        _logs = res.data.filter((x) => !x.is_comment);
      } else if (type === 1) {
        _logs = res.data.filter((x) => x.is_comment);
      } else {
        _logs = res.data;
      }
      setTaskLogs([...taskLogs, ..._logs]);
      setLogPagination({ ...logPagination, total: res.total });
    } else {
      message.error('获取项目日志失败');
    }
    setFetching(false);
  };
  //刷新任务动态
  const refreshLogs = async (type: number, task_id?: number) => {
    if (!task_id) return;

    const res = await getProjectLogs({ task_id, limit: logPagination.pageSize, skip: 0 });
    if (res.code === 0) {
      let _logs = [];
      if (type === 2) {
        _logs = res.data.filter((x) => !x.is_comment);
      } else if (type === 1) {
        _logs = res.data.filter((x) => x.is_comment);
      } else {
        _logs = res.data;
      }

      setTaskLogs(_logs);
      setLogPagination({ ...logPagination, total: res.total });
    } else {
      message.error('获取项目日志失败');
    }
  };
  //任务评论
  const doComment = async () => {
    if (!task) return;
    if (comment.trim() === '') {
      message.error('评论内容不能为空');
      return;
    }
    const res = await commentTask({ task_id: task.id, content: comment, project_id: task.project_id });
    if (res.code === 0) {
      message.success('评论成功');
      setTaskLogs([]);
      setComment('');
      refreshLogs(taskLogType, task.id);
    } else {
      message.error('网络错误');
    }
  };
  //撤回消息
  const doRetractComment = async (id: ProjectLogItem['id']) => {
    if (!task) return;
    const res = await retractCommentTask(id);
    if (res.code === 0) {
      message.success('撤回成功');
      setTaskLogs([]);
      refreshLogs(taskLogType, task.id);
    } else {
      message.error('网络错误');
    }
  };

  React.useEffect(() => {
    fetchProjectMembers();
    fetchTaskStatus();
  }, [project]);

  React.useEffect(() => {
    if (_task) {
      setTaskId(_task.id);
    }
  }, [_task]);

  React.useEffect(() => {
    if (taskId) {
      fetchData(taskId);
      fetchChildTaskList();
    }
  }, [taskId]);

  React.useEffect(() => {
    if (task) {
      fetchParentTask(task.parent_task_id);
    }
  }, [task]);

  React.useEffect(() => {
    refreshLogs(taskLogType, task?.id);
  }, [taskLogType]);

  const headerDropDownMenu = (
    <Menu>
      <Menu.Item style={{ padding: 0 }}>
        <div style={{ width: 240, padding: '8px 16px', color: 'grey' }}>
          <CopyOutlined></CopyOutlined>
          <span style={{ paddingLeft: 4, fontWeight: 500 }}>复制任务</span>
        </div>
      </Menu.Item>
      <Menu.Item style={{ padding: 0 }}>
        <div style={{ width: 240, padding: '8px 16px', color: 'grey' }}>
          <SnippetsOutlined></SnippetsOutlined>
          <span style={{ paddingLeft: 4, fontWeight: 500 }}>移动项目</span>
        </div>
      </Menu.Item>
      <Menu.Item style={{ padding: 0 }}>
        <div style={{ width: 240, padding: '8px 16px', color: 'grey' }}>
          <StarOutlined></StarOutlined>
          <span style={{ paddingLeft: 4, fontWeight: 500 }}>收藏项目</span>
        </div>
      </Menu.Item>
      <Menu.Item style={{ padding: 0 }}>
        <div style={{ width: 240, padding: '8px 16px', color: 'grey' }}>
          <DeleteOutlined></DeleteOutlined>
          <span style={{ paddingLeft: 4, fontWeight: 500 }}>移动到回收站</span>
        </div>
      </Menu.Item>
      {/* <Divider style={{ margin: 0 }} />
      <Menu.Item style={{ padding: 0 }}>
        <div style={{ width: 240, padding: '8px 16px', color: 'grey', display: 'flex', alignItems: 'flex-start' }}>
          <DeleteOutlined></DeleteOutlined>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ paddingLeft: 4, fontWeight: 500 }}>移动到回收站</span>
            <span>仅参与者可见</span>
          </div>
        </div>
      </Menu.Item> */}
    </Menu>
  );
  const DoneStatusMenu = (
    <Menu>
      {task && (
        <>
          <Menu.Item
            style={{ padding: 0 }}
            onClick={() =>
              doUpdateTask({
                id: task.id,
                done: true,
              })
            }
          >
            <div
              style={{
                width: 240,
                padding: '8px 16px',
                color: 'grey',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tag color={'green'}>已完成</Tag>
              {task.done && (
                <span style={{ paddingLeft: 4, fontWeight: 500 }}>
                  <CheckOutlined></CheckOutlined>
                </span>
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{ padding: 0 }}
            onClick={() =>
              doUpdateTask({
                id: task.id,
                done: false,
              })
            }
          >
            <div
              style={{
                width: 240,
                padding: '8px 16px',
                color: 'grey',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tag color={'grey'}>未完成</Tag>
              {!!!task.done && (
                <span style={{ paddingLeft: 4, fontWeight: 500 }}>
                  <CheckOutlined></CheckOutlined>
                </span>
              )}
            </div>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  const ExcuteStatusMenu = (
    <Menu>
      {task &&
        executeStatus.map((item) => {
          return (
            <Menu.Item style={{ padding: 0 }} onClick={() => doUpdateTask({ id: task.id, execute_status: item.id })}>
              <div
                style={{
                  width: 240,
                  padding: '8px 16px',
                  color: 'grey',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {item.execute_status}
                {task.execute_status === item.id && (
                  <span style={{ paddingLeft: 4, fontWeight: 500 }}>
                    <CheckOutlined></CheckOutlined>
                  </span>
                )}
              </div>
            </Menu.Item>
          );
        })}
    </Menu>
  );
  const PriorityLevelMenu = (
    <Menu>
      {task && (
        <>
          {' '}
          <Menu.Item style={{ padding: 0 }} onClick={() => doUpdateTask({ id: task.id, priority_level: 0 })}>
            <div
              style={{
                width: 240,
                padding: '8px 16px',
                color: 'grey',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tag color={'green'}>普通</Tag>
              {task.priority_level === 0 && (
                <span style={{ paddingLeft: 4, fontWeight: 500 }}>
                  <CheckOutlined></CheckOutlined>
                </span>
              )}
            </div>
          </Menu.Item>
          <Menu.Item style={{ padding: 0 }} onClick={() => doUpdateTask({ id: task.id, priority_level: 1 })}>
            <div
              style={{
                width: 240,
                padding: '8px 16px',
                color: 'grey',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tag color={'rgb(255, 153, 0)'}>紧急</Tag>
              {task.priority_level === 1 && (
                <span style={{ paddingLeft: 4, fontWeight: 500 }}>
                  <CheckOutlined></CheckOutlined>
                </span>
              )}
            </div>
          </Menu.Item>
          <Menu.Item style={{ padding: 0 }} onClick={() => doUpdateTask({ id: task.id, priority_level: 2 })}>
            <div
              style={{
                width: 240,
                padding: '8px 16px',
                color: 'grey',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Tag color={'rgb(237, 63, 20)'}>非常紧急</Tag>
              {task.priority_level === 2 && (
                <span style={{ paddingLeft: 4, fontWeight: 500 }}>
                  <CheckOutlined></CheckOutlined>
                </span>
              )}
            </div>
          </Menu.Item>
        </>
      )}
    </Menu>
  );
  if (!!!task) {
    return null;
  }
  const _executeStatus = executeStatus.find((item) => item.id === task.execute_status);
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => {
        onclose();
      }}
      title={
        <span style={{ fontSize: 14, fontWeight: 'normal' }}>
          {project.project_name} · {stage.task_stages_name}
          {task.parent_task_id !== 0 && (
            <span
              className="goback"
              style={{ cursor: 'pointer', paddingLeft: 16 }}
              onClick={() => {
                setTaskId(task.parent_task_id);
              }}
            >
              <LeftOutlined></LeftOutlined>
              <span style={{ paddingLeft: 4 }}>返回上级</span>
            </span>
          )}
          {parentTask && (
            <span
              style={{ cursor: 'pointer' }}
              className="goback"
              onClick={() => {
                setTaskId(task.parent_task_id);
              }}
            >
              <Divider type="vertical"></Divider>
              <div style={{ paddingLeft: 4, maxWidth: 200, overflow: 'hidden', display: 'inline' }}>
                {parentTask.task_name}
              </div>
            </span>
          )}
        </span>
      }
      style={{ minWidth: 1200 }}
      className="task-detail-modal"
      maskClosable={false}
      bodyStyle={{ padding: 0 }}
      centered
      destroyOnClose
      closeIcon={
        <div style={{ width: 208, padding: '0px 20px', display: 'flex', justifyContent: 'space-between' }}>
          <Tooltip title="复制链接" style={{ cursor: 'pointer' }}>
            <LinkOutlined></LinkOutlined>
          </Tooltip>
          <Tooltip title="点个赞" style={{ cursor: 'pointer' }}>
            <LikeOutlined></LikeOutlined>
          </Tooltip>
          <Tooltip title="打开菜单" style={{ cursor: 'pointer' }}>
            <Dropdown overlay={headerDropDownMenu} trigger={['click']} placement="bottomCenter">
              <EllipsisOutlined></EllipsisOutlined>
            </Dropdown>
          </Tooltip>
          <Tooltip title="关闭面板" style={{ cursor: 'pointer' }}>
            <CloseOutlined onClick={handleCancel}></CloseOutlined>
          </Tooltip>
        </div>
      }
    >
      {task && (
        <div className="task-datail-body" style={{ display: 'flex', justifyContent: 'center', height: 800 }}>
          <div
            className="content-left"
            style={{ width: 800, borderRight: '1px solid #e5e5e5', padding: '24px 40px 36px 32px', overflow: 'scroll' }}
          >
            <Tooltip title="点击编辑">
              <EditableControl
                className="task-title"
                inputProps={{
                  value: task.task_name,
                  placeholder: '编辑任务名称',
                  style: {
                    border: 'none',
                    boxShadow: 'none',
                    fontSize: 20,
                    margin: 0,
                    padding: 0,
                    fontWeight: 500,
                    backgroundColor: 'rgba(0,0,0,.05)',
                  },
                }}
                onFinishEditing={async (newVal: string) => {
                  if (newVal === task.task_name) return;
                  doUpdateTask({ id: task.id, task_name: newVal });
                }}
              >
                <div style={{ width: '100%' }}>{task.task_name}</div>
              </EditableControl>
            </Tooltip>
            <Form labelCol={{ md: 4, lg: 4, xs: 4 }} labelAlign="left" colon={false} style={{ marginTop: 20 }}>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <CheckSquareOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>完成状态</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <Dropdown overlay={DoneStatusMenu} placement="bottomLeft" trigger={['click']}>
                  <span style={{ cursor: 'pointer' }}>
                    {task.done ? (
                      <Tag color="green">已完成</Tag>
                    ) : (
                      <span style={{ color: 'rgba(0,0,0,.45)' }}>未完成</span>
                    )}
                  </span>
                </Dropdown>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <DeploymentUnitOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>执行状态</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <Dropdown overlay={ExcuteStatusMenu} placement="bottomLeft" trigger={['click']}>
                  {
                    <span style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}>
                      {_executeStatus ? _executeStatus.execute_status : '未设置'}
                    </span>
                  }
                </Dropdown>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <UserOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>执行者</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                {taskMembers.length > 0 && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <div style={{ padding: 12 }}>
                          <Input prefix={<SearchOutlined />} style={{ height: 40 }} placeholder="搜索" />
                        </div>
                        <div style={{ height: 300, overflow: 'scroll' }}>
                          <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>执行者</span>
                          {/* <Menu.Item>
                           
                          </Menu.Item> */}
                          <div style={{ width: 250, padding: 12 }}>
                            {task.assign_to ? (
                              <span>
                                <Avatar
                                  style={{ marginRight: 8 }}
                                  src={`v2/api/user/avatar/${
                                    sysUsers.filter((x: any) => x.id === task.assign_to)[0].avatar
                                  }`}
                                ></Avatar>
                                {sysUsers.filter((x: any) => x.id === task.assign_to)[0].user_name}
                              </span>
                            ) : (
                              <span>
                                <Avatar icon={<UserOutlined />} style={{ marginRight: 8 }}></Avatar>
                                待认领
                              </span>
                            )}
                          </div>
                          <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>其他参与者</span>
                          <div>
                            {taskMembers
                              .filter((x) => x.user_id !== task.assign_to)
                              .map((user) => {
                                return (
                                  <div
                                    key={user.user_id}
                                    style={{ width: 250, padding: 12, cursor: 'pointer' }}
                                    onClick={() => doAssignTask(user.user_id)}
                                    className="user-item"
                                  >
                                    <Avatar
                                      style={{ marginRight: 8 }}
                                      src={`v2/api/user/avatar/${user.avatar}`}
                                    ></Avatar>
                                    {user.user_name}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <Divider style={{ margin: 0 }}></Divider>
                        <div
                          style={{
                            height: 60,
                            display: 'flex',
                            padding: 12,
                          }}
                        >
                          <Button type="primary" style={{ width: '100%', borderRadius: 5, height: 40 }}>
                            邀请新成员
                          </Button>
                        </div>
                      </Menu>
                    }
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <Tooltip title="点击设置执行者">
                      {task.assign_to ? (
                        <span style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}>
                          <Avatar
                            style={{ marginRight: 8 }}
                            size={'small'}
                            src={`v2/api/user/avatar/${sysUsers.filter((x: any) => x.id === task.assign_to)[0].avatar}`}
                          ></Avatar>
                          {sysUsers.filter((x: any) => x.id === task.assign_to)[0].user_name}
                        </span>
                      ) : (
                        <span style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}>
                          <Avatar size={'small'} icon={<UserOutlined />} style={{ marginRight: 8 }}></Avatar>
                          待认领
                        </span>
                      )}
                    </Tooltip>
                  </Dropdown>
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <CalendarOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>时间</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <>
                  <DatePicker
                    placeholder="设置开始时间"
                    bordered={false}
                    suffixIcon={null}
                    style={{ paddingLeft: 0, color: 'rgba(0,0,0,.45)' }}
                    onChange={(value) =>
                      doUpdateTask({ id: task.id, begin_time: moment(value).format('YYYY-MM-DD') + ' 00:00:00' })
                    }
                    clearIcon={false}
                    value={task.begin_time ? moment(task.begin_time) : undefined}
                  ></DatePicker>
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>-</span>
                  <DatePicker
                    placeholder="设置截止时间"
                    bordered={false}
                    suffixIcon={null}
                    onChange={(value) =>
                      doUpdateTask({ id: task.id, end_time: moment(value).format('YYYY-MM-DD') + ' 00:00:00' })
                    }
                    clearIcon={false}
                    value={task.end_time ? moment(task.end_time) : undefined}
                  ></DatePicker>
                </>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <FileTextOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>备注</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                {!showRemark &&
                  (task.description ? (
                    <div style={{ cursor: 'pointer' }} onClick={() => setShowRemark(true)}>
                      <Tooltip title="点击编辑备注">{task.description}</Tooltip>
                    </div>
                  ) : (
                    <div className="field-right">
                      <div style={{ width: '100%' }} onClick={() => setShowRemark(true)}>
                        添加备注
                      </div>
                    </div>
                  ))}
                {showRemark && (
                  <div>
                    <Input.TextArea onChange={(e) => setRemark(e.target.value)} value={remark} />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                      <Button style={{ borderRadius: 5, marginRight: 20 }} onClick={() => setShowRemark(false)}>
                        取消
                      </Button>
                      <Button
                        type="primary"
                        style={{ borderRadius: 5 }}
                        onClick={() =>
                          doUpdateTask({ id: task.id, description: remark }).then((e) => setShowRemark(false))
                        }
                      >
                        保存
                      </Button>
                    </div>
                  </div>
                )}
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <BulbOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>优先级</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <Dropdown overlay={PriorityLevelMenu} placement="bottomLeft" trigger={['click']}>
                  <Tooltip title="点击选择优先级">
                    <span>
                      <PriorityLevelTag level={task.priority_level}></PriorityLevelTag>
                    </span>
                  </Tooltip>
                </Dropdown>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <TagOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>标签</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <TagSelector task={task} />
              </Form.Item>
            </Form>
            <Form layout="vertical" style={{ marginBottom: 36 }}>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <UnorderedListOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>子任务</span>
                    {childTasks.length > 0 && (
                      <span style={{ color: 'rgba(0,0,0,.45)', paddingLeft: 8 }}>
                        <span style={{ paddingRight: 8 }}>·</span>
                        {childTasks.filter((x) => x.done).length}/{childTasks.length}
                      </span>
                    )}
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <div
                  style={{
                    padding: '10px 24px',
                    border: '1px solid #e5e5e5',
                    borderRadius: 4,
                    cursor: 'pointer',
                    marginBottom: 12,
                    marginTop: 12,
                  }}
                >
                  {taskMembers.length > 0 &&
                    childTasks.map((child) => {
                      return (
                        <div
                          style={{
                            width: '100%',
                            padding: '4px 12px 4 px 5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: 8,
                          }}
                          key={child.id}
                        >
                          <Checkbox
                            onChange={(e) => {
                              doUpdateChildTaskStatus(child.id, e.target.checked);
                            }}
                            checked={child.done}
                          ></Checkbox>
                          <div style={{ width: '100%', marginLeft: 24 }}>
                            <Avatar
                              style={{ marginRight: 8 }}
                              size={'small'}
                              src={`v2/api/user/avatar/${
                                taskMembers.filter((x) => x.user_id === child.assign_to)[0].avatar
                              }`}
                            ></Avatar>
                            <Button
                              type="text"
                              style={{ borderRadius: 5, width: 580, textAlign: 'left' }}
                              onClick={() => {
                                setTaskId(child.id);
                              }}
                            >
                              {child.task_name}
                            </Button>
                            <a style={{ color: 'rgba(0,0,0,.45)' }}>
                              <RightOutlined />
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  {showAddChildTask && (
                    <AddChildTaskWidget
                      _task={task}
                      visible={showAddChildTask}
                      setVisible={setShowAddChildTask}
                      fetchData={() => fetchChildTaskList()}
                      taskMembers={taskMembers}
                    />
                  )}
                  <a onClick={() => setShowAddChildTask(true)}>
                    <PlusOutlined style={{ marginRight: 4 }}></PlusOutlined>添加子任务
                  </a>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <ClockCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>
                      工时{' '}
                      {workTimes.length > 0 &&
                        ` · 实际工时 ${workTimes
                          .map((x) => x.real_hour)
                          .reduce((pre, cur) => pre + cur)} 小时，工时记录 ${workTimes.length} 条，预估总工时 ${
                          task.plan_hour ? task.plan_hour : 0
                        } 小时`}
                      {workTimes.length === 0 && `· 预估总工时 ${task.plan_hour ? task.plan_hour : 0} 小时`}
                      <Tooltip title="设置计划工时">
                        <EditOutlined
                          style={{ cursor: 'pointer', paddingLeft: 4 }}
                          onClick={() => {
                            setSetPlanHourVisible(true);
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <div
                  style={{
                    padding: '10px 24px',
                    border: '1px solid #e5e5e5',
                    borderRadius: 4,
                    cursor: 'pointer',
                    marginBottom: 12,
                    marginTop: 12,
                  }}
                >
                  {workTimes.length > 0 && userOptions.length > 0 && (
                    <List
                      dataSource={workTimes}
                      renderItem={(item, index) => {
                        return (
                          <List.Item
                            actions={[
                              <Tooltip title="编辑">
                                <EditOutlined
                                  onClick={() => {
                                    setEditingWorkTime(item);
                                    setEditingVisible(true);
                                  }}
                                />
                              </Tooltip>,
                              <Tooltip title="删除">
                                <DeleteOutlined onClick={() => doDeleteWorkTime(item.id)} />
                              </Tooltip>,
                            ]}
                            key={item.id}
                          >
                            {/* <List.Item.Meta
                              avatar={
                                <Avatar
                                  src={`v2/api/user/avatar/${
                                    userOptions.filter((x) => x.user_id === item.user_id)[0].avatar
                                  }`}
                                />
                              }
                              title={
                                <a href="/" style={{ fontWeight: 400 }}>
                                  {item.username + ' ' + `${item.begin_time}开始` + ' ' + `工时为${item.real_hour}小时`}
                                </a>
                              }
                              style={{ width: 80 }}
                              description={item.content}
                              key={item.id}
                            /> */}
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Avatar
                                src={`v2/api/user/avatar/${
                                  userOptions.filter((x) => x.user_id === item.user_id)[0].avatar
                                }`}
                              />
                              <div style={{ marginLeft: 16 }}>
                                <div>
                                  <span style={{ fontWeight: 400 }}>
                                    {item.username +
                                      ' ' +
                                      `${item.begin_time}开始` +
                                      ' ' +
                                      `工时为${item.real_hour}小时`}
                                    <Tag color="blue" style={{ marginLeft: 8 }}>
                                      {item.man_hours_type}
                                    </Tag>
                                    {item.man_hours_definition !== '无' && (
                                      <Tag color="volcano" style={{ marginLeft: 8 }}>
                                        {item.man_hours_definition}
                                      </Tag>
                                    )}
                                  </span>
                                </div>
                                <div>
                                  <span style={{ color: 'rgba(0,0,0,.45)' }}>{item.content}</span>
                                </div>
                              </div>
                            </div>
                          </List.Item>
                        );
                      }}
                    ></List>
                  )}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setAddWorkTimeVisible(true);
                    }}
                  >
                    <PlusOutlined style={{ marginRight: 4 }}></PlusOutlined>
                    添加工时
                  </a>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ color: 'rgba(0,0,0,.45)' }}>
                    <LinkOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    <span style={{ paddingLeft: 8 }}>关联文件</span>
                  </span>
                }
                style={{ margin: '14px 0px' }}
              >
                <div
                  style={{
                    padding: '10px 24px',
                    border: '1px solid #e5e5e5',
                    borderRadius: 4,
                    cursor: 'pointer',
                    marginBottom: 12,
                    marginTop: 12,
                  }}
                >
                  <Upload
                    action={`/v2/api/team/task/uploadFile`}
                    data={{ taskId: task.id }}
                    onChange={(info) => {
                      if (info.file.status === 'done') {
                        if (info.file.response.code === 10001) {
                          notification.open({
                            message: '上传失败',
                            description: '不支持的文件类型',
                            icon: <WarningOutlined style={{ color: 'red' }} />,
                          });
                        } else {
                          notification.open({
                            message: '上传成功',
                            description: '文件关联成功',
                            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
                          });
                          fetchfiles(task.id);
                        }
                      }
                    }}
                    showUploadList={false}
                  >
                    <a>
                      <PlusOutlined style={{ marginRight: 4 }}></PlusOutlined>上传文件
                    </a>
                  </Upload>
                </div>
                {files.length > 0 && (
                  <div
                    style={{
                      padding: '10px 24px',
                      border: '1px solid #e5e5e5',
                      borderRadius: 4,
                      cursor: 'pointer',
                      marginBottom: 12,
                      marginTop: 12,
                    }}
                  >
                    <List
                      dataSource={files}
                      renderItem={(item, index) => {
                        return (
                          <List.Item
                            actions={[
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  download(`/v2/api/team/task/downloadFile/${item.file_uid}`, item.file_name);
                                }}
                              >
                                下载
                              </a>,
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  Modal.confirm({
                                    title: '确定要取消关联吗？',
                                    content: '取消关联后，文件将不再显示在任务中',
                                    okText: '确定',
                                    cancelText: '取消',
                                    onOk: () => doCancelFile(item.file_uid),
                                  });
                                }}
                              >
                                取消关联
                              </a>,
                            ]}
                            key={item.file_uid}
                          >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <FileViewer file={item} key={item.file_uid} />{' '}
                              <span style={{ color: 'rgba(0,0,0,.45)', marginLeft: 8 }}>{item.file_name}</span>
                            </div>
                          </List.Item>
                        );
                      }}
                    ></List>
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
          <div className="content-right" style={{ width: 400 }}>
            {taskMembers.length > 0 && (
              <div
                style={{
                  padding: '15px 20px 20px 20px',
                  borderBottom: '1px solid #e5e5e5',
                }}
              >
                <span>
                  参与者 · <>{taskMembers.length}</>
                </span>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    paddingTop: 12,
                  }}
                >
                  {taskMembers.map((item, index) => {
                    return (
                      <Avatar
                        style={{ marginRight: 8, marginBottom: 4 }}
                        size={24}
                        key={index + '-' + item.user_id}
                        src={`v2/api/user/avatar/${item.avatar}`}
                      ></Avatar>
                    );
                  })}
                  <Dropdown
                    overlay={
                      <Menu>
                        <div style={{ padding: 12 }}>
                          <Input prefix={<SearchOutlined />} style={{ height: 40 }} placeholder="搜索" />
                        </div>
                        <div style={{ height: 300, overflow: 'scroll' }}>
                          <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>参与者</span>
                          {/* <Menu.Item>
                           
                          </Menu.Item> */}
                          <div style={{ width: 250 }}>
                            {taskMembers.map((item) => {
                              return (
                                <div
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: 12,
                                  }}
                                >
                                  <span>
                                    <Avatar
                                      style={{ marginRight: 8 }}
                                      src={`v2/api/user/avatar/${item.avatar}`}
                                    ></Avatar>
                                    {item.user_name}
                                  </span>
                                  <Button
                                    size="small"
                                    onClick={() =>
                                      Modal.confirm({
                                        title: '移除参与者',
                                        content: `确定要移除${item.user_name}吗？`,
                                        onOk: () => doRemoveTaskMember(item.user_id),
                                      })
                                    }
                                  >
                                    移除
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                          <span style={{ color: 'rgba(0,0,0,.45)', margin: '6px 12px' }}>其他项目成员</span>
                          <div>
                            {userOptions
                              .filter((x) => !taskMembers.find((y) => y.user_id === x.user_id))
                              .map((user) => {
                                return (
                                  <div
                                    key={user.user_id}
                                    style={{
                                      width: 250,
                                      padding: 12,
                                      cursor: 'pointer',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span>
                                      <Avatar
                                        style={{ marginRight: 8 }}
                                        src={`v2/api/user/avatar/${user.avatar}`}
                                      ></Avatar>
                                      {user.user_name}
                                    </span>
                                    <Button size="small" onClick={() => doAddTaskMember(user.user_id)}>
                                      添加
                                    </Button>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <Divider style={{ margin: 0 }}></Divider>
                        <div
                          style={{
                            height: 60,
                            display: 'flex',
                            padding: 12,
                          }}
                        >
                          <Button type="primary" style={{ width: '100%', borderRadius: 5, height: 40 }}>
                            邀请新成员
                          </Button>
                        </div>
                      </Menu>
                    }
                    placement="bottomLeft"
                    trigger={['click']}
                  >
                    <PlusCircleTwoTone style={{ fontSize: 24, cursor: 'pointer' }} />
                  </Dropdown>
                </div>
              </div>
            )}
            <div style={{}}>
              <div style={{ padding: '15px 20px 20px 20px' }}>
                <Dropdown
                  overlay={
                    <Menu selectedKeys={[taskLogType.toString()]}>
                      <Menu.Item
                        style={{ width: 240, padding: '8px 16px' }}
                        onClick={() => setTaskLogType(0)}
                        key={'0'}
                      >
                        所有动态
                      </Menu.Item>
                      <Menu.Item
                        style={{ width: 240, padding: '8px 16px' }}
                        onClick={() => setTaskLogType(1)}
                        key={'1'}
                      >
                        仅评论
                      </Menu.Item>
                      <Menu.Item
                        style={{ width: 240, padding: '8px 16px' }}
                        onClick={() => setTaskLogType(2)}
                        key={'2'}
                      >
                        仅动态
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()} className="log-type-menu">
                    <Space>
                      {TaskLogType[taskLogType]}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </div>
              <div
                style={{ color: 'rgba(0,0,0,.45', padding: '0px 20px', overflow: 'auto', height: 520 }}
                id="scrollableDiv"
              >
                <InfiniteScroll
                  dataLength={taskLogs.length}
                  next={() => {
                    fetchLogs(task.id);
                  }}
                  hasMore={taskLogs.length < logPagination.total}
                  loader={
                    <Divider plain style={{ color: 'rgba(0,0,0,.45' }}>
                      上滑加载更多
                    </Divider>
                  }
                  endMessage={
                    <Divider plain style={{ color: 'rgba(0,0,0,.45' }}>
                      没有更多动态了
                    </Divider>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <List
                    dataSource={taskLogs}
                    loading={fetching}
                    renderItem={(item, index) => {
                      const _user = userOptions.find((x) => x.user_id === item.creator_id);
                      //判断消息是否是三分钟内的
                      const isThreeMin = moment().diff(moment(item.create_time), 'minutes') < 3;
                      //判断是否是评论消息
                      const isComment = item.is_comment;
                      if (!_user) return null;
                      return (
                        <div style={{ display: 'flex', color: 'rgba(0,0,0,.45)' }}>
                          <div style={{ marginBottom: 24, marginRight: 12 }}>
                            {item.is_comment ? (
                              <Avatar size="small" src={`v2/api/user/avatar/${_user.avatar}`}></Avatar>
                            ) : (
                              getLogIcon(item.log_type_enum.log_code)
                            )}
                          </div>
                          <div
                            style={{
                              marginBottom: 24,
                              marginRight: 12,
                            }}
                          >
                            <div
                              style={{
                                position: 'absolute',
                                right: 0,
                              }}
                            >
                              {getDayDesc(item.create_time)}
                            </div>
                            {!isComment && (
                              <Tooltip title={`${item.remark}`}>
                                <div
                                  style={{
                                    maxWidth: 250,
                                    // overflow: 'hidden',
                                    // textOverflow: 'ellipsis',
                                    // whiteSpace: 'nowrap',
                                  }}
                                >
                                  {`${item.member_name} ${item.remark}`}
                                </div>
                              </Tooltip>
                            )}
                            {isComment && (
                              <div style={{ color: '#000000' }}>
                                {`${item.member_name} `}{' '}
                                {isThreeMin ? (
                                  <a
                                    style={{ fontSize: 12 }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      Modal.confirm({
                                        title: '确认删除评论？',
                                        icon: <ExclamationCircleOutlined />,
                                        content: '删除后无法恢复',
                                        okText: '确认',
                                        cancelText: '取消',
                                        onOk() {
                                          doRetractComment(item.id);
                                        },
                                      });
                                    }}
                                  >
                                    撤回
                                  </a>
                                ) : null}
                              </div>
                            )}
                            {isComment && <div style={{ marginTop: 8 }}>{item.remark}</div>}
                          </div>
                        </div>
                      );
                    }}
                  ></List>
                </InfiniteScroll>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '20px 0px',
                borderTop: '1px solid #e5e5e5',
                width: 400,
                position: 'absolute',
                bottom: 0,
              }}
            >
              <Input.TextArea
                style={{ width: 280, height: 32, marginRight: 24 }}
                placeholder="输入评论"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                value={comment}
              />
              <Button type="primary" style={{ borderRadius: 5 }} onClick={() => doComment()}>
                评论
              </Button>
            </div>
          </div>
        </div>
      )}
      {addWorkTimeVisible && task && (
        <AddWorkTimeModal
          visible={addWorkTimeVisible}
          setVisible={setAddWorkTimeVisible}
          _task={task}
          fetchData={fetchWorkTime}
          workTimes={workTimes}
        />
      )}
      {editingWorkTime && editingVisible && task && (
        <UpdateWorkTimeModal
          visible={editingVisible}
          setVisible={setEditingVisible}
          _task={task}
          fetchData={fetchWorkTime}
          data={editingWorkTime}
          workTimes={workTimes}
        />
      )}
      {setPlanHourVisible && task && (
        <SetPlanHourModal
          visible={setPlanHourVisible}
          setVisible={setSetPlanHourVisible}
          _task={task}
          fetchData={fetchData}
        />
      )}
    </Modal>
  );
};

export default TaskDetailModal;
