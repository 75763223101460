/// <reference path="type.d.ts" />
/// <reference path="rongcloud.d.ts" />

import request from './_base'

/**
 * 获取当前用户通信token
 */
export function getUserRongcloudToken(): Promise<AssetsResourceProto<{ data: GetUserRongcloudTokenRes }>> {
  return request({
    method: 'GET',
    url: `/im/token`,
  }) as any
}

/**
 * 创建群组
 * @param {createGroupReq} input
 */
export function createGroup(input: createGroupReq): Promise<AssetsResourceProto<{ data: GroupItem }>> {
  return request({
    method: 'POST',
    url: `/im/group`,
    data: input,
  }) as any
}

/**
 * 更新群组
 * @param {createGroupReq} input
 */
export function updateGroup(input: UpdateGroupReq): Promise<AssetsResourceProto<{ data: GroupItem }>> {
  return request({
    method: 'PUT',
    url: `/im/group`,
    data: input,
  }) as any
}

/**
 * 删除群组
 * @param {DeleteGroupReq} input
 */
export function deleteGroup(input: DeleteGroupReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/im/group`,
    data: input,
  }) as any
}

/**
 * 添加用户到群组
 * @param {EditGroupUsersReq} input
 */
export function addUser2Group(input: EditGroupUsersReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'PUT',
    url: `/im/group/user`,
    data: input,
  }) as any
}

/**
 * 从群组移除成员
 * @param {EditGroupUsersReq} input
 */
export function deleteUserFromGroup(input: EditGroupUsersReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/im/group/user`,
    data: input,
  }) as any
}

/**
 * 根据群组ID获取其中成员信息
 * @param {string} group_id
 */
export function getUserInfoOfGroup(group_id: string): Promise<AssetsResourceProto<{ data: UserInfoItem[] }>> {
  return request({
    method: 'GET',
    url: `/im/group/user/${group_id}`,
  }) as any
}

/**
 * 禁言群组成员
 * @param {MuteUserOfGroupReq} input
 */
export function muteGroupUser(input: MuteUserOfGroupReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'POST',
    url: `/im/group/user/mute`,
    data: input,
  }) as any
}

/**
 * 解除群组成员禁言
 * @param {MuteUserOfGroupReq} input
 */
export function demuteGroupUser(input: DemuteUserOfGroupReq): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/im/group/user/mute`,
    data: input,
  }) as any
}

/**
 * 获取群组禁言成员list
 * @param {string} group_id
 */
export function getMutedUsers(group_id: string): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'GET',
    url: `/im/group/user/mute/${group_id}`,
  }) as any
}

/**
 * 获取当前用户所在群组list
 * @param {GetMyGroupsListReq} input
 */
export function getMyGroups(
  input: GetMyGroupsListReq
): Promise<AssetsResourceProto<{ data: GroupItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/im/user/group/list`,
    data: input,
  }) as any
}

/**
 * 根据group_id查询group信息
 * @param {string} group_id
 */
export function queryGroup(group_id: string): Promise<AssetsResourceProto<{ data: GroupItem }>> {
  return request({
    method: 'GET',
    url: `/im/group/${group_id}`,
  }) as any
}
