import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getNormalFormStatisticsData, getShareTemplateById } from '@/requests/normalForm';
import { message, Descriptions, Divider, Table, Image, PageHeader, Button } from 'antd';

const NormalFormStatisticsData = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const [template, setTemplate] = React.useState<ShareTemplateItem>();
  const [fetching, setFetching] = React.useState(false);
  const [data, setData] = React.useState<NormalFormStatisticsDataItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 15,
    total: 0,
  });

  const fetchTemplate = async () => {
    let uid = '';
    uid = location.search.split('&')[0].split('=')[1];

    const res = await getShareTemplateById(uid);
    if (res.code === 0) {
      setTemplate(res.data);
    } else {
      message.error(res.code);
    }
  };

  const fetchFormData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 15;
    }
    let form_id = '';
    form_id = location.state ? location.state.id : location.search.split('=')[1];
    if (!form_id) return;

    const res = await getNormalFormStatisticsData({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      form_template_share_id: Number(form_id),
    });
    if (res.code === 0) {
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      });
      setData(res.data);
    } else {
      message.error('网络错误');
    }
    setFetching(false);
  };

  React.useEffect(() => {
    let state = location.state as any;
    if (state) {
      if (state.data) {
        setTemplate(state.data);
      }
    } else {
      fetchTemplate();
    }

    fetchFormData();
  }, []);
  return (
    <div style={{ padding: 16, background: '#ffffff' }}>
      <div>
        <PageHeader
          title={'数据统计'}
          extra={[
            <Button type="primary" onClick={() => history.goBack()}>
              返回
            </Button>,
          ]}
        />
        {template && (
          <Descriptions
            title={
              <span>
                <div className="title-prefix"></div>表单信息
              </span>
            }
            style={{ margin: '0 48px' }}
          >
            <Descriptions.Item label="模板名称">{template.form_template_name}</Descriptions.Item>
            <Descriptions.Item label="简介">{template.description}</Descriptions.Item>
            <Descriptions.Item label="模板id">{template.uid}</Descriptions.Item>
            <Descriptions.Item label="模板版本号">{template.version_number}</Descriptions.Item>
            <Descriptions.Item label="创建时间">{template.create_time}</Descriptions.Item>

            <Descriptions.Item label="填写次数">{data.length}</Descriptions.Item>
          </Descriptions>
        )}
        <Divider>表单数据</Divider>
        {template && (
          <Table
            dataSource={data}
            pagination={{
              ...pagination,
              onChange: (page, pageSize) => {
                fetchFormData(page, pageSize);
              },
              showTotal: (total) => <span>共{total}条数据</span>,
            }}
            columns={[
              {
                title: '序号',
                align: 'center' as 'center',
                key: 'no',
                render: (_: any, record: NormalFormStatisticsDataItem, idx: number) => `${idx + 1}`,
              },
            ]
              .concat(
                template.fields.map((x, index) => {
                  return {
                    title: x.title,
                    align: 'center' as 'center',
                    key: x.title,
                    render: (_: any, record: NormalFormStatisticsDataItem, idx: number) => {
                      const field_type = record.fields[index].field_type;
                      if (field_type === '多项选择') {
                        return (
                          <>{record.fields[index].multiple_choice_value.map((x) => x.value).toString()}</>
                        ) as any;
                      }
                      if (field_type === '图片多选') {
                        return record.fields[index].multiple_choice_value.map((x) => (
                          <Image
                            src={`/v2/api/file/pic/download/${x.value}`}
                            key={x.value}
                            width={31}
                            height={31}
                            style={{ padding: 1 }}
                          />
                        ));
                      }
                      if (field_type === '图片单选') {
                        return (
                          <Image
                            src={`/v2/api/file/pic/download/${record.fields[index].field_value}`}
                            width={30}
                            height={30}
                          />
                        );
                      }
                      return <>{record.fields[index].field_value}</>;
                    },
                  };
                })
              )
              .concat([
                {
                  title: '填写人',
                  align: 'center' as 'center',
                  key: 'user',
                  render: (_: any, record: NormalFormStatisticsDataItem, idx: number) => `${record.user_name}`,
                },
                {
                  title: '工号',
                  align: 'center' as 'center',
                  key: 'company_user_id',
                  render: (_: any, record: NormalFormStatisticsDataItem, idx: number) => `${record.company_user_id}`,
                },
                {
                  title: '填写时间',
                  align: 'center' as 'center',
                  key: 'time',
                  render: (_: any, record: NormalFormStatisticsDataItem, idx: number) => `${record.submitted_time}`,
                },
              ])}
          />
        )}
      </div>
    </div>
  );
};
export default NormalFormStatisticsData;
