import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Steps, Button, PageHeader, message, Spin, Result } from 'antd'
import { useCtxState } from '@/App.store'
import * as formRequests from '@/requests/form'
import CreateFormHeadStepOne from '../components/CreateFormHeadStepOne'
import CreateFormHeadStepTwo from '../components/CreateFormHeadStepThree'
import * as Utils from '@/utils/string'
import * as FormRequests from '@/requests/form'
const { Step } = Steps

const steps = [
  { title: '创建人签名', content: '1' },
  { title: '添加合作人及工具', content: '2' },
  { title: '完成创建', content: '3' },
]

const CreateFreeFormHead = () => {
  const history = useHistory()
  const location = useLocation<any>()
  const [{ userInfo }] = useCtxState()
  const [template, setTemplate] = React.useState<ShareFormTemplateItem | null>(null)
  const [formHeadData, setFormHeadData] = React.useState<CreateFormHeadReq>({
    form_tag: 0,
    hand_signatures: [],
    public_init_regulations: [],
    private_init_regulations: [],
    form_cooperators: [],
    user_tools: [],
    form_template_share_uid: location.state ? location.state.uid : location.search.split('=')[1],
    form_template_version_name: template ? template.version_name : '',
    form_template_version_number: template ? template.version_number : 0,
  })
  const [fecthing, setFetching] = React.useState(false)
  const [current, setCurrent] = React.useState(0)
  const [signatureImageDataUrl, setSignatureImageDataUrl] = React.useState('')
  const [createdFormHeadData, setCreatedFormHeadData] = React.useState<undefined | FormHeadDataItem>(undefined)

  const [creating, setCreating] = React.useState(false)
  const uploadSignature = async (): Promise<AssetsResourceProto<{ data: string }>> => {
    const blob = Utils.dataURLtoFile(signatureImageDataUrl, `${Utils.uuid()}.png`)
    const res = await FormRequests.uploadSignaturePic({ file: blob })
    if (res.code === 0) {
      return Promise.resolve(res)
    } else {
      message.error(res.message)
      return Promise.resolve(res)
    }
  }

  const doCreateFormHeader = async () => {
    setCreating(true)
    const uploadSignatureRes = await uploadSignature()
    if (uploadSignatureRes.code === 0) {
      const res = await FormRequests.createFormHeader({
        ...formHeadData,
        hand_signatures: [
          {
            user_name: userInfo.username,
            user_id: userInfo.user_id,
            hand_signature_pic_uid: uploadSignatureRes.data,
          },
        ],
      })
      if (res.code === 0) {
        setCreatedFormHeadData(res.data)
        setCurrent(2)
        setCreating(false)
      } else {
        setCreating(false)
        message.error(`创建失败：${res.message}`)
      }
    }
  }

  const fecthTemplate = async () => {
    let uid = ''
    let version_number = 0
    uid = location.state ? location.state.uid : location.search.split('&')[0].split('=')[1]
    version_number = location.state ? location.state.version_number : location.search.split('&')[1].split('=')[1]
    const res = await formRequests.queryShareTemplate({
      uid,
      version: version_number,
    })
    if (res.code === 0) {
      setTemplate(res.data)
      setFormHeadData({
        ...formHeadData,
        form_template_share_uid: res.data.uid,
        form_template_version_name: res.data.version_name,
        form_template_version_number: res.data.version_number,
      })
    } else {
      message.error(res.code)
    }
    setFetching(false)
  }
  React.useEffect(() => {
    setFetching(true)
    fecthTemplate()
  }, [])
  return fecthing ? (
    <Spin spinning={fecthing} tip="初始化中" style={{ position: 'absolute', right: '50%', top: '50%' }} />
  ) : (
    <div style={{ padding: 16, backgroundColor: '#ffffff' }}>
      <PageHeader onBack={() => history.goBack()} title="创建表单信息" style={{ padding: 5 }} />
      <Steps current={current} style={{ padding: 16, minWidth: 600 }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title}></Step>
        ))}
      </Steps>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {template && (
          <div style={{ display: current === 0 ? 'block' : 'none' }}>
            <CreateFormHeadStepOne
              setCurrent={setCurrent}
              template={template}
              formHeadData={formHeadData}
              setFormHeadData={setFormHeadData}
              userInfo={userInfo}
              setSignatureImageDataUrl={setSignatureImageDataUrl}
              signatureImageDataUrl={signatureImageDataUrl}
            />
          </div>
        )}
        {template && (
          <div style={{ display: current === 1 ? 'block' : 'none', width: '100%' }}>
            <CreateFormHeadStepTwo
              setCurrent={setCurrent}
              template={template}
              formHeadData={formHeadData}
              setFormHeadData={setFormHeadData}
              userInfo={userInfo}
              doCreateFormHeader={doCreateFormHeader}
            />
          </div>
        )}

        {template && (
          <div style={{ display: current === 2 ? 'block' : 'none', width: '100%' }}>
            <Result
              status="success"
              title="创建成功！"
              subTitle={`表单id：${
                createdFormHeadData ? createdFormHeadData.form_id : ''
              }，您可以在“我创建的表单”中查看`}
              extra={[
                <Button
                  type="primary"
                  key="query"
                  onClick={() => history.replace('/workbench/forms-management/form-data-list')}
                >
                  立即查看
                </Button>,
                <Button
                  type="primary"
                  key="query"
                  onClick={() => {
                    history.replace({
                      pathname: '/workbench/forms-management/form-data-list/submit-free-formdata',
                      state: {
                        form_id: createdFormHeadData ? createdFormHeadData.form_id : '',
                      },
                      search: `form_id=${createdFormHeadData ? createdFormHeadData.form_id : ''}`,
                    })
                  }}
                >
                  立即填写
                </Button>,
                <Button key="complete" onClick={() => history.replace('/base/forms-management/share-forms')}>
                  完成创建
                </Button>,
              ]}
            />
          </div>
        )}
        {creating && (
          <div style={{ position: 'absolute', left: '50%', top: '50%' }}>
            <Spin spinning={creating} tip="创建中" />
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateFreeFormHead
