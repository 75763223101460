import * as React from 'react'
import { Steps, message } from 'antd'
import CreateTemplateStepOne from '../components/CreateTempalteStepOne'
import CreateTemplateStepTwo from '../components/CreateTemplateStepTwo'

import CreateTemplateStepFive from '../components/CreateFormTemplateComplete'
import * as FormRequests from '@/requests/normalForm'
const { Step } = Steps

const steps = [
  { title: '表单信息', content: CreateTemplateStepOne },

  { title: '表单格式', content: '3' },

  { title: '完成创建', content: '5' },
]

const CreateNormalTemplate = () => {
  const [current, setCurrent] = React.useState(0)
  const [formName, setFormName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [formTemplateId, setFormTemplateId] = React.useState('')
  const [versionName, setVersionName] = React.useState('')
  const [fields, setFields] = React.useState<CreatePersonalTemplateFieldItem[]>([])
  const [fetching, setFetching] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [formUid, setFormUid] = React.useState('')
  const [pagination, setPagination] = React.useState({
    page: 1,
    total: 1,
  })

  const docreateFormTemplate = async () => {
    setFetching(true)
    const res = await FormRequests.createPersonalTemplate({
      form_template_name: formName,
      form_template_number: formTemplateId,
      version_name: versionName,
      description: description,
      fields: fields,
      status: 0,
    })
    if (res.code === 0) {
      message.success('创建成功')
      setCurrent(2)
      setFormUid(res.data.uid)
    } else {
      message.error(res.message)
      setIsError(true)
    }
    setFetching(false)
  }
  return (
    <div style={{ backgroundColor: '#ffffff', padding: 16 }}>
      <Steps current={current} style={{ padding: 20, minWidth: 600 }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title}></Step>
        ))}
      </Steps>
      <div
        className="create-form-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {
          <div style={{ display: current === 0 ? 'block' : 'none' }}>
            <CreateTemplateStepOne
              setCurrent={setCurrent}
              setDescription={setDescription}
              setFormName={setFormName}
              setFormTemplateId={setFormTemplateId}
              setVersionName={setVersionName}
            />
          </div>
        }
        {
          <div style={{ display: current === 1 ? 'block' : 'none', width: '100%' }}>
            <CreateTemplateStepTwo
              setCurrent={setCurrent}
              setFields={setFields}
              fields={fields}
              pagination={pagination}
              setPagination={setPagination}
              docreateFormTemplate={docreateFormTemplate}
            />
          </div>
        }

        {
          <div
            style={{
              display: current === 2 ? 'block' : 'none',
              width: '100%',
            }}
          >
            <CreateTemplateStepFive fetching={fetching} isError={isError} formUid={formUid} />
          </div>
        }
      </div>
    </div>
  )
}

export default CreateNormalTemplate
