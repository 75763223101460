import React from 'react';
import {
  Drawer,
  Form,
  Divider,
  Table,
  Tag,
  message,
  Tooltip,
  Empty,
  Button,
  Modal,
  Input,
  Select,
  InputNumber,
  Tabs,
  Spin,
  Checkbox,
  Popconfirm,
} from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import classnames from 'classnames';
import './index.less';
import {
  QuestionCircleFilled,
  HistoryOutlined,
  BarChartOutlined,
  TableOutlined,
  EditOutlined,
  SearchOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import * as AssetsRequest from '@/requests/assets';
import * as PicRequests from '@/requests/pictures';
import * as TagRequests from '@/requests/tag';
import { queryAssetAddrList } from '@/requests/location';
import EditableControl from '@/ui-components/EditableControl';
import CopiableControl from '@/ui-components/CopiableControl';
import { formatTime } from '../../utils/string';
import { findMax, findMin } from '../../utils/array';
import ReactEcharts from 'echarts-for-react';
import * as Utils from '@/utils/string';
import { arrSet } from '@/utils/array';
import * as SuggestionRequests from '@/requests/suggestion';

import StatusValueHistoryTable from './StatusValueHistoryTable';
import DropdownPicWidget from './DropdownPicWidget';
import PicWidget from './PicWidget';

import SingleUserCompanyIdInfoTooltip from '@/ui-components/SingleUserCompanyIdTooltip';
import TagDetail from './TagDetail';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components';
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer]);
export interface BaseStatusFormValues {
  status_name: string;
  status_type: string;
  status_value: string;
}

interface BaseTagFormValues {
  tag_name: string;
  tag_value: string;
}

interface UpdateStatusFormValues {
  status_name: string;
  status_type: string;
  is_batch: boolean;
}
export default function DrawerAssetEditor({
  asset_id = null,
  info,
  children,
  status,
  ...props
}: React.PropsWithChildren<{
  asset_id: string | null;
  info: UserInfoItem;
  children?: React.ReactNode;
  status?: StatusListItem;
}> &
  DrawerProps) {
  const [asset, setAsset] = React.useState<AssetItem | undefined>(undefined);
  const [assetStatuses, setAssetStatuses] = React.useState<AssetStatusItem[]>([]);
  const [addrs, setAddrs] = React.useState<AddrItem[]>([]);
  const [tags, setTags] = React.useState<TagItem[]>([]);
  const tagSet = new Set<string>(tags.map((x) => x.tag_name));
  const allAssetTags = Array.from(tagSet);
  const [currentStandardName, setCurrentStandardName] = React.useState<string>('');
  const StatusTagSet = new Set<string>(assetStatuses.map((x) => x.status_name));
  const allAssetStatusTags = Array.from(StatusTagSet).slice(0, 20);
  const [checkedStatusName, setCheckedStatusName] = React.useState(status ? status.status_name : '');
  const [currentFilteredAssetStatus] = assetStatuses.filter((x) => x.status_name === checkedStatusName);

  const [createStatusVisible, setCreateStatusVisible] = React.useState(false);
  const [createTagVisible, setCreateTagVisible] = React.useState(false);
  const [modifyStatusHistroyVisible, setModifyStatusHistoryVisible] = React.useState(false);
  const [updateStatusValueVisible, setUpdateStatusValueVisible] = React.useState(false);
  const [editStatusVisible, setEditStatusVisible] = React.useState(false);
  const [editCompsVisible, setEditCompsVisible] = React.useState(false);
  const [addVcompsVisible, setAddVcompsVisible] = React.useState(false);

  const [currentItemToUpdate, setCurrentItemToUpdate] = React.useState({} as AssetStatusItem);
  const [renderTable, setRenderTable] = React.useState(true);
  const [updateVStatusModalVisible, setUpdateVStatusModalVisible] = React.useState(false);
  const [currenUpdateVAssetStatusIndex, setCurrenUpdateVAssetStatusIndex] = React.useState(0);
  const [currentVstatus2Update, setCurrentVstatus2Update] = React.useState<VassetstatusItem | undefined>(undefined);
  const [UpdateAssetTagVisible, setUpdateAssetTagVisible] = React.useState(false);
  const [editingTag, SetEditingTag] = React.useState<string>('');

  const [loadingInfo, setLoadingInfo] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);

  const [createStatusForm] = Form.useForm();
  const [createTagForm] = Form.useForm();
  const [modifyStatusHistoryForm] = Form.useForm();
  const [updateStatusValueForm] = Form.useForm();
  const [editStatusForm] = Form.useForm();
  const [editCompsForm] = Form.useForm();
  const [addVcompsForm] = Form.useForm();

  const handleCreateStatusOk = async (values: BaseStatusFormValues) => {
    doCreateStatus(values);
  };
  const handleAddVcompsOk = (values: any) => {
    doAddVcomps(values);
  };
  const handleUpdateStatusValueOK = (value: { status_value: string }) => {
    doUpdateStatusValue(value);
  };
  const handleModifyStatusHistoryOK = (value: { status_value: string; comment: string }) => {
    doModifyStatusHistroy(value);
  };
  const handleEditStatusOk = (values: UpdateStatusFormValues) => {
    doUpdateStatus(values);
  };

  const handleCreateAssetTagOK = (values: BaseTagFormValues) => {
    doCreateTag(values);
  };

  const handleEditCompsOk = (values: { asset_comps: string[] }) => {
    doUpdateAssetComps(values);
  };

  //获取资产信息 包含资产状态信息
  const fetchAssetData = async (statusName?: string) => {
    if (!asset_id) return;
    fetchAssetTagsData();
    queryAddr();
    const res = await AssetsRequest.getAssetItem(asset_id);
    if (res.code === 0) {
      const { data } = res;
      setAsset(data);
      setAssetStatuses(data.asset_status);
      if (data.asset_status.length > 0 && !status && !checkedStatusName) {
        setCheckedStatusName(data.asset_status[0].status_name);
      }
      if (statusName) {
        setCheckedStatusName(statusName);
      }
    } else {
      message.error(res.message);
    }
    setLoadingInfo(false);
  };
  //获取资产标签
  const fetchAssetTagsData = async () => {
    if (!asset_id) return;
    const res = await TagRequests.getTagsByAssetId(asset_id);
    if (res.code === 0) {
      setTags(res.data);
    } else {
      message.error(res.message);
    }
  };
  //查询地理信息
  const queryAddr = async () => {
    if (!asset_id) return;
    const res = await queryAssetAddrList(asset_id);
    if (res.code === 0) {
      setAddrs(res.data);
    } else {
      message.error(res.message);
    }
  };
  //加载资产信息
  React.useEffect(() => {
    setCheckedStatusName('');
    if (status) {
      setCheckedStatusName(status.status_name);
    }
    setLoadingInfo(true);
    fetchAssetData();
  }, [asset_id]);

  //创建资产状态
  const doCreateStatus = async (values: BaseStatusFormValues) => {
    if (!asset) return;
    setFetching(true);
    const res = await AssetsRequest.createAssetStatus({
      ...({} as any),
      asset_id: asset.asset_id,
      status_value: values.status_value,
      status_type: values.status_type,
      status_name: values.status_name,
      user_id: String(info.user_id),
      user_name: info.username,
      task_id: Utils.uuid(),
      form_id: ``,
    });

    if (res.code === 0) {
      message.success('添加资产状态成功');
      fetchAssetData(res.asset_status.status_name);
      setCreateStatusVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  //创建资产标签
  const doCreateTag = async (values: BaseTagFormValues) => {
    if (!asset_id) return;
    setFetching(true);
    const res = await TagRequests.createTag({
      asset_id,
      tag_name: values.tag_name,
      tag_value: values.tag_value,
    });
    if (res.code === 0) {
      message.success('添加资产标签成功');
      fetchAssetTagsData();
      setCreateTagVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  //修改资产状态历史值
  const doModifyStatusHistroy = async (value: { status_value: string; comment: string }) => {
    if (!currentItemToUpdate) return;
    setFetching(true);
    const res = await AssetsRequest.modifyStatusValueHistory({
      ...({} as any),
      status_value: value.status_value,
      status_type: currentItemToUpdate.status_type,
      status_name: currentItemToUpdate.status_name,
      status_id: currentItemToUpdate.status_id,
      task_id: `modify${currentItemToUpdate.status_uid}to${value.status_value}`,
      form_id: 'modify-status-history-value-form',
      comment: value.comment ? value.comment : '',
    });
    if (res.code === 0) {
      message.success('修改历史资产状态值成功', 1);
      fetchAssetData();
      setModifyStatusHistoryVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  //更新资产状态值
  const doUpdateStatusValue = async (value: { status_value: string }) => {
    setFetching(true);
    if (!currentFilteredAssetStatus) {
      message.error('发生错误！');
      return;
    }
    if (value.status_value === currentFilteredAssetStatus.status_value) return;
    const res = await AssetsRequest.updateAssetStatus({
      ...({} as any),
      status_uid: currentFilteredAssetStatus.status_uid,
      asset_id: currentFilteredAssetStatus.asset_id,
      status_value: value.status_value,
      status_type: currentFilteredAssetStatus.status_type,
      status_name: currentFilteredAssetStatus.status_name,
      user_id: String(info.user_id),
      user_name: info.username,
      task_id: `update${currentFilteredAssetStatus.status_uid}to${value.status_value}`,
      form_id: 'update-status-value-form',
    });

    if (res.code === 0) {
      message.success('更新资产状态值成功！');
      fetchAssetData();
      setUpdateStatusValueVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  //更新资产状态名和值类型
  const doUpdateStatus = async (values: UpdateStatusFormValues) => {
    if (!currentFilteredAssetStatus) return;
    if (
      values.status_name === currentFilteredAssetStatus.status_name &&
      values.status_type === currentFilteredAssetStatus.status_type
    ) {
      setEditStatusVisible(false);
      return;
    }

    setFetching(true);
    if (values.is_batch) {
      const res = await AssetsRequest.updateStatusNameAndTypeByBatch({
        ...({} as any),
        status_name: values.status_name,
        status_type: values.status_type,
        status_uid: currentFilteredAssetStatus.status_uid,
      });
      if (res.code === 0) {
        setFetching(false);
        message.success('修改成功');
        fetchAssetData(values.status_name);
        setEditStatusVisible(false);
      } else {
        setFetching(false);
        message.error(res.message);
      }
    } else {
      const res = await AssetsRequest.updateStatusNameAndType({
        ...({} as any),
        status_name: values.status_name,
        status_type: values.status_type,
        status_uid: currentFilteredAssetStatus.status_uid,
      });
      if (res.code === 0) {
        setFetching(false);
        message.success('修改成功');
        fetchAssetData(values.status_name);
        setEditStatusVisible(false);
      } else {
        setFetching(false);
        message.error(res.message);
      }
    }
  };
  //更新资产组件
  const doUpdateAssetComps = async (values: { asset_comps: string[] }) => {
    if (!asset) return;
    setFetching(true);
    if (values.asset_comps.toString() === asset.asset_comps.toString()) {
      return;
    }
    const res = await AssetsRequest.updateAssetItem({
      ...asset,
      asset_comps: values.asset_comps,
    });
    if (res.code === 0) {
      message.success('修改成功');
      fetchAssetData();
      setEditCompsVisible(false);
    } else {
      message.error('修改失败');
    }
    setFetching(false);
  };
  //添加地理信息组件
  const doAddVcomps = async (values: any) => {
    if (!asset) return;
    setFetching(true);
    let v_asset_status = asset.v_asset_status.map((x) => x);
    let v_asset_comps = asset.v_asset_comps.map((x) => x);
    const res = await AssetsRequest.updateAssetItem({
      ...asset,
      v_asset_status: v_asset_status.concat(
        values.v_asset_comps.map((x: string) => {
          return {
            v_status_name: x,
            v_status_value: values[x],
            v_status_type: '文本',
          };
        })
      ),
      v_asset_comps: v_asset_comps.concat(values.v_asset_comps),
    });
    if (res.code === 0) {
      message.success('添加成功');
      fetchAssetData();
      setAddVcompsVisible(false);
    } else {
      message.error('添加失败');
    }
    setFetching(false);
  };
  //删除资产状态history
  const removeAssetStatusItem = (status_id: number, name: string) => {
    Modal.confirm({
      title: 'Confirm',
      content: `确认删除资产状态'${name}'?`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const res = await AssetsRequest.removeAssetStatus(status_id);
        if (res.code === 0) {
          message.success('删除成功', 1);
          fetchAssetData();
        } else {
          message.error(res.message);
        }
      },
    });
  };

  //删除资产标签
  const removeAssetTag = (id: string, name: string) => {
    Modal.confirm({
      title: '温馨提示',
      content: `确认删除'${name}'?`,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        const res = await TagRequests.deleteTag({
          asset_tag_uid: id,
        });
        if (res.code === 0) {
          message.success('删除成功', 1);

          fetchAssetTagsData();
        } else {
          message.error(res.message);
        }
      },
    });
  };
  //上传资产状态图片
  const uploadStatusPic = async (file: any, status: AssetStatusItem, comment?: string) => {
    if (!asset || !status) return;
    const upload = async () => {
      message.loading(`正在上传${file.name ? file.name : ''}`);
      const res = await PicRequests.uploadStatusPic({
        file,
        status_id: status.status_id,
        comment: comment ? comment : '',
        user_id: String(info.user_id),
        user_name: info.username,
        form_id: Utils.uuid(),
        task_id: Utils.uuid(),
        marker_location: 'no',
        asset_name: asset.asset_id,
        asset_comps: asset.asset_comps.toString(),
        v_asset_name: asset.v_asset_name,
        v_asset_comps: asset.v_asset_comps.toString(),
        v_asset_status: asset.v_asset_status.map((x) => x.v_status_value).toString(),
      });
      if (res.code === 0) {
        message.destroy();
        message.success('上传成功');
        fetchAssetData();
      } else {
        message.destroy();
        message.error(res.code);
      }
    };
    Modal.confirm({
      title: '添加备注',
      content: <Input onChange={(e) => (comment = e.target.value)} />,
      okText: '确认上传',
      cancelText: '跳过',
      onOk: () => {
        if (!comment) {
          message.error('请输入备注');
          return;
        }
        upload();
      },
      onCancel: () => {
        upload();
      },
    });
  };
  //上传资产图片
  const uploadAssetPic = async (file: any, comment?: string) => {
    if (!asset) return;
    const upload = async () => {
      message.loading(`正在上传${file.name ? file.name : ''}`);
      const res = await PicRequests.uploadAssetPic({
        file,
        asset_id: asset.asset_id,
        comment: comment ? comment : '',
        user_id: String(info.user_id),
        user_name: info.username,
        form_id: Utils.uuid(),
        task_id: Utils.uuid(),
        marker_location: 'no',
        asset_name: asset.asset_id,
        asset_comps: asset.asset_comps.toString(),
        v_asset_name: asset.v_asset_name,
        v_asset_comps: asset.v_asset_comps.toString(),
        v_asset_status: asset.v_asset_status.map((x) => x.v_status_value).toString(),
      });
      if (res.code === 0) {
        message.destroy();
        message.success('上传成功');
        fetchAssetData();
      } else {
        message.destroy();
        message.error(res.code);
      }
    };
    Modal.confirm({
      title: `添加备注（${file.name}）`,
      content: <Input onChange={(e) => (comment = e.target.value)} />,
      okText: '确认上传',
      cancelText: '跳过',
      onOk: () => {
        if (!comment) {
          message.error('请输入备注');
          return;
        }
        upload();
      },
      onCancel: () => {
        upload();
      },
    });
  };
  //BI配置
  const getAssetStatusOption = (tag: string) => {
    if (!tag) return [];
    const list = assetStatuses.filter((x) => x.status_name === tag);
    // list.reverse();
    return {
      title: {
        text: '',
      },
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: [
          {
            name: tag,
          },
        ],
      },
      toolbox: {
        feature: {
          saveAsImage: {
            title: '保存',
          },
        },
      },
      grid: {
        left: 36,
        right: 36,
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: list.map((x) => {
            return formatTime(x.update_time);
          }),
        },
      ],
      yAxis: [
        {
          type: 'value',
          min: findMin(list) > 0 ? 0 : findMin(list),
          max: Math.ceil(findMax(list)),
          interval: findMax(list) > 0 ? Math.ceil(findMax(list) / 5) : Math.ceil(-findMin(list) / 5),
        },
      ],
      series: [
        {
          name: `${tag}`,
          type: 'line',
          stack: '总量',
          areaStyle: { normal: {} },
          data: list.map((x) => {
            return x.status_value;
          }),
        },
      ],
    };
  };
  // 创建资产状态Form
  const CreateStatusForm = () => {
    const [type, setType] = React.useState('数值');
    return (
      <Form
        form={createStatusForm}
        onFinish={handleCreateStatusOk}
        layout="vertical"
        preserve={false}
        onValuesChange={(changedValues, allValues) => {
          if (Object.keys(changedValues)[0] === 'status_type') {
            setType(changedValues[Object.keys(changedValues)[0]]);
          }
        }}
      >
        <Form.Item
          label="资产状态名"
          name="status_name"
          rules={[
            { required: true, message: '请输入资产状态名称！' },
            {
              validator: async (rule, value, callback) => {
                if (!value) return;
                const res = allAssetStatusTags.indexOf(value);
                if (res === -1) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('该资产状态已存在！');
                }
              },
            },
          ]}
        >
          <Input placeholder="请输入资产状态名" />
        </Form.Item>
        <Form.Item
          label="状态值类型"
          name="status_type"
          rules={[{ required: true, message: '请选择资产状态值类型！' }]}
          shouldUpdate
          initialValue={'数值'}
        >
          <Select style={{ width: '100%' }} placeholder="请选择状态值类型">
            <Select.Option value="数值">数值</Select.Option>
            <Select.Option value="文本">文本</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="状态值"
          name="status_value"
          dependencies={['status_type']}
          rules={[
            {
              required: true,
              message: '请输入资产状态值',
            },
          ]}
        >
          {type === '数值' ? <InputNumber style={{ width: '100%' }} /> : <Input placeholder="请输入资产状态值" />}
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setCreateStatusVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  //创建资产标签Form
  const CreateTagForm = () => {
    return (
      <Form form={createTagForm} preserve={false} layout="vertical" onFinish={handleCreateAssetTagOK}>
        <Form.Item
          label="标签名称"
          name="tag_name"
          rules={[
            { required: true, message: '请输入标签名称' },
            {
              validator: async (rule, value) => {
                if (!value) return;
                const res = allAssetTags.indexOf(value);
                if (res === -1) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('该标签已存在');
                }
              },
            },
          ]}
        >
          <Input placeholder="请输入标签名称" />
        </Form.Item>
        <Form.Item label="标签状态" name="tag_value" rules={[{ required: true, message: '请输入标签状态值' }]}>
          <Input placeholder="请输入标签状态值" />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setCreateTagVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  //修改资产状态历史值Form
  const ModifyStatusHistroyForm = () => {
    if (!currentItemToUpdate) return null;
    const { status_value, comment } = currentItemToUpdate;
    return (
      <Form form={modifyStatusHistoryForm} preserve={false} layout="vertical" onFinish={handleModifyStatusHistoryOK}>
        <Form.Item
          label="状态值"
          name="status_value"
          rules={[{ required: true, message: '请输入状态值！' }]}
          initialValue={status_value}
        >
          <Input placeholder="请输入状态值" />
        </Form.Item>
        <Form.Item
          label="备注"
          name="comment"
          rules={[{ required: false, message: '请输入状态值！' }]}
          initialValue={comment}
        >
          <Input placeholder="请输入状态值" />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setModifyStatusHistoryVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  //更新资产状态值Form
  const UpdateStatusValueForm = () => {
    return (
      <Form form={updateStatusValueForm} preserve={false} layout="vertical" onFinish={handleUpdateStatusValueOK}>
        <Form.Item label="新状态值" name="status_value" rules={[{ required: true, message: '请输入新状态值' }]}>
          <Input placeholder="请输入新状态值" autoFocus />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setUpdateStatusValueVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  //编辑当前资产状态Form
  const EditStatusForm = () => {
    const valueTypeOptions = ['文本', '数值'];
    if (!currentFilteredAssetStatus) return null;
    // console.log('xxxxxxxxxxxx', currentFilteredAssetStatus)
    const { status_name, status_type } = currentFilteredAssetStatus;
    return (
      <Form form={editStatusForm} layout="vertical" onFinish={handleEditStatusOk} preserve={false}>
        <Form.Item
          label="资产状态名称"
          name="status_name"
          preserve={false}
          rules={[
            { required: true, message: '请输入资产状态名称！' },
            {
              validator: async (rule, value, callback) => {
                if (!value) return;
                const res = allAssetStatusTags.indexOf(value);
                if (res === -1) {
                  return Promise.resolve();
                }
                if (value === status_name) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('该资产状态已存在！');
                }
              },
            },
          ]}
          initialValue={status_name}
        >
          <Input placeholder="请输入资产状态名称" />
        </Form.Item>
        <Form.Item
          label="状态值类型"
          name="status_type"
          rules={[{ required: true, message: '请选择状态值类型' }]}
          preserve={false}
          initialValue={status_type}
        >
          <Select style={{ width: '100%' }} placeholder="请选择资产状态值类型">
            {valueTypeOptions.map((item) => {
              return (
                <Select.Option key={item.toString()} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="is_batch" valuePropName="checked" initialValue={false}>
          <Checkbox>同时修改同类资产状态值类型</Checkbox>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setEditStatusVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  //编辑资产组件Form
  const EditCompsForm = () => {
    const [options, setOptions] = React.useState<string[]>([]);
    if (!asset) return null;
    const { asset_comps, asset_name } = asset;
    return (
      <Form form={editCompsForm} layout="vertical" onFinish={handleEditCompsOk} preserve={false}>
        <Form.Item
          label="资产组件"
          name="asset_comps"
          rules={[{ required: true, message: '请选择资产组件' }]}
          initialValue={asset_comps}
        >
          <Select
            onDropdownVisibleChange={async (open) => {
              if (open) {
                const res = await SuggestionRequests.getAssetCompsSuggestion(asset_name);
                if (res.code === 0) {
                  setOptions(arrSet(res.data));
                } else {
                }
              }
            }}
            mode="tags"
          >
            {options.map((x, index) => {
              return (
                <Select.Option value={x.toString()} key={index}>
                  {x}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setEditCompsVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  //添加地理组件Forms
  const AddVcompsForm = () => {
    const [selectedComps, setSelectedComps] = React.useState<string[]>([]);
    return (
      <Form
        form={addVcompsForm}
        layout="vertical"
        onFinish={handleAddVcompsOk}
        preserve={false}
        onValuesChange={(changedValues, allValues) => {
          if (Object.keys(changedValues)[0] === 'v_asset_comps') {
            setSelectedComps(changedValues[Object.keys(changedValues)[0]]);
          }
        }}
      >
        <Form.Item label="地理组件" name="v_asset_comps" rules={[{ required: true, message: '请选择资产组件' }]}>
          <Select mode="tags" style={{ width: '100%' }} placeholder="请选择地理组件"></Select>
        </Form.Item>
        {selectedComps.map((x, index) => {
          return (
            <Form.Item key={x + index} label={x} name={x} rules={[{ required: true, message: `请输入${x}！` }]}>
              <Input placeholder={`请输入${x}`} />
            </Form.Item>
          );
        })}
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={() => setAddVcompsVisible(false)} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };
  return (
    <Drawer
      title={
        !loadingInfo && asset ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="title-prefix"></div>
            <EditableControl
              style={{ cursor: 'pointer', display: 'inline' }}
              inputProps={{
                value: asset.asset_name,
                placeholder: '编辑资产名称',
                style: { width: 160 },
              }}
              onFinishEditing={async (newVal: string) => {
                if (newVal === asset.asset_name) return;
                const res = await AssetsRequest.updateAssetItem({
                  ...asset,
                  asset_name: newVal,
                });
                if (res.code === 0) {
                  message.success('修改成功');
                  fetchAssetData();
                } else {
                  message.error('修改失败');
                }
              }}
            >
              <span className="drawer-title">{asset.asset_name}</span>
              <span className="drawer-gray-btn">编辑</span>
            </EditableControl>
            <Divider type="vertical" />
            <CopiableControl style={{ cursor: 'pointer' }} content={asset.asset_name}>
              <span className="drawer-gray-btn">复制</span>
            </CopiableControl>
          </div>
        ) : (
          ''
        )
      }
      width={'50vw'}
      drawerStyle={{ minWidth: 600 }}
      visible={!!asset_id}
      {...props}
      destroyOnClose={true}
      className={classnames('drawer-asset-viewer-wrapper', props.className)}
    >
      <Spin spinning={loadingInfo} style={{ position: 'absolute', right: '50%', top: '50%' }} tip="加载中..." />
      {!asset || loadingInfo ? null : (
        <main className="drawer-container">
          <Form preserve={false}>
            <Form.Item label="资产图片" className="flex-form-item__vertical">
              <div>
                <PicWidget asset={asset} fetchAssetData={fetchAssetData} uploadAssetPic={uploadAssetPic} />
              </div>
            </Form.Item>

            <Form.Item label="资产编号" className="flex-form-item__vertical">
              <CopiableControl style={{ cursor: 'pointer' }} content={asset.asset_id}>
                <span style={{ marginRight: 10 }}>{asset.asset_id}</span>

                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </Form.Item>

            <Form.Item label="资产组件" className="flex-form-item__vertical">
              <div>
                {asset.asset_comps.map((x, idx) => {
                  return (
                    <Tag color="cyan" key={idx}>
                      {x}
                    </Tag>
                  );
                })}
                <span
                  className="drawer-gray-btn"
                  onClick={() => {
                    setEditCompsVisible(true);
                  }}
                >
                  编辑
                </span>
                <Divider type="vertical" />
                <CopiableControl style={{ cursor: 'pointer' }} content={asset.asset_comps.toString()}>
                  <span className="drawer-gray-btn">复制</span>
                </CopiableControl>
              </div>
            </Form.Item>

            <Form.Item
              label="地理信息"
              className="flex-form-item__vertical"
              extra={
                <div style={{ marginTop: 8 }}>
                  {asset.v_asset_status.map((x, v_index) => {
                    return (
                      <Tooltip title="点击可编辑" key={x.asset_id + x.v_status_name + x.v_status_value}>
                        <Tag
                          key={x.v_status_name}
                          closable
                          style={{ cursor: 'pointer', marginBottom: 4 }}
                          onClick={() => {
                            setCurrentVstatus2Update(x);
                            setCurrenUpdateVAssetStatusIndex(v_index);
                            setUpdateVStatusModalVisible(true);
                          }}
                          onClose={(e: any) => {
                            e.preventDefault();
                            Modal.confirm({
                              title: '温馨提示',
                              content: '确认删除该地点名称吗？',
                              okText: '确认',
                              cancelText: '取消',
                              onOk: async () => {
                                const res = await AssetsRequest.updateAssetItem({
                                  ...asset,
                                  v_asset_status: asset.v_asset_status.filter((_, index) => index !== v_index),
                                  v_asset_comps: asset.v_asset_comps.filter((_, index) => index !== v_index),
                                });
                                if (res.code === 0) {
                                  message.success('删除成功');
                                  fetchAssetData();
                                } else {
                                  message.error('删除失败');
                                }
                              },
                            });
                          }}
                        >{`${x.v_status_name}：${x.v_status_value}`}</Tag>
                      </Tooltip>
                    );
                  })}
                  {asset.v_asset_status.length !== 0 && (
                    <Tag
                      style={{ cursor: 'pointer' }}
                      color="orange"
                      onClick={() => {
                        setAddVcompsVisible(true);
                      }}
                    >
                      <PlusOutlined type="plus" />
                      添加
                    </Tag>
                  )}
                </div>
              }
            >
              <EditableControl
                style={{ cursor: 'pointer', display: 'inline' }}
                inputProps={{
                  value: asset.v_asset_name,
                  placeholder: '编辑地理名称',
                  style: { width: 160 },
                }}
                key={`v_asset_name-${asset_id}`}
                onFinishEditing={async (newVal: string) => {
                  if (newVal === asset.v_asset_name) return;
                  const res = await AssetsRequest.updateAssetItem({
                    ...asset,
                    v_asset_name: newVal,
                  });
                  if (res.code === 0) {
                    message.success('修改成功');
                    fetchAssetData();
                  } else {
                    message.error('修改失败');
                  }
                }}
              >
                <Tag color="#f39800" style={{ fontSize: 12 }}>
                  {asset.v_asset_name}
                </Tag>
                <span className="drawer-gray-btn">编辑</span>
              </EditableControl>
              <Divider type="vertical" />
              <CopiableControl
                style={{ cursor: 'pointer' }}
                content={`${asset.v_asset_name},${asset.v_asset_status.map(
                  (x) => x.v_status_name + '：' + x.v_status_value
                )}`}
              >
                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </Form.Item>
            <Form.Item label="地点信息" className="flex-form-item__vertical">
              <div>
                {addrs.length > 0 ? (
                  addrs.map((x, idx, arr) => {
                    return (
                      <Tag color="#108ee9" key={idx} style={{ marginBottom: 4 }}>
                        {`${x.addr_status_name}:${x.addr_status_value}`}
                      </Tag>
                    );
                  })
                ) : (
                  <span style={{ marginRight: 10 }}>无</span>
                )}

                <CopiableControl
                  style={{ cursor: 'pointer' }}
                  content={
                    addrs.length > 0
                      ? addrs.map((x) => `${x.addr_status_name}:${x.addr_status_value}`).toString()
                      : '无'
                  }
                >
                  <span className="drawer-gray-btn">复制</span>
                </CopiableControl>
              </div>
            </Form.Item>
            <Form.Item label="资产标签" className="flex-form-item__vertical">
              <div>
                {allAssetTags.map((_x, _idx) => {
                  const tag = tags.filter((x) => x.tag_name === _x && x.is_use === 1);
                  if (tag.length < 1) return null;
                  return (
                    <Tooltip title="点击进入编辑" key={`tag-${_idx}`}>
                      <Tag
                        color="magenta"
                        style={{ cursor: 'pointer' }}
                        closable
                        onClick={() => {
                          SetEditingTag(_x);
                          setUpdateAssetTagVisible(true);
                        }}
                        onClose={(e: any) => {
                          e.preventDefault();
                          removeAssetTag(tag[0].asset_tag_uid, _x);
                        }}
                      >
                        {`${tag[0].tag_name}：${tag[0].tag_value}`}
                      </Tag>
                    </Tooltip>
                  );
                })}

                <Tag
                  style={{ cursor: 'pointer' }}
                  color="orange"
                  onClick={() => {
                    setCreateTagVisible(true);
                  }}
                >
                  <PlusOutlined />
                  添加
                </Tag>
              </div>
            </Form.Item>
            <Divider>资产状态</Divider>
            <Form.Item label="状态查询" className="flex-form-item__vertical" preserve={false}>
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="请输入需要查询的状态标签"
                optionFilterProp="children"
                suffixIcon={<SearchOutlined />}
                onChange={(value: string) => {
                  setCheckedStatusName(value);
                }}
                value={checkedStatusName}
                filterOption={(input, option) => {
                  if (!option) return false;
                  if (!option.props.children) {
                    return false;
                  }
                  return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {allAssetStatusTags ? (
                  allAssetStatusTags.map((tag: string) => {
                    return (
                      <Select.Option value={tag as string} key={tag}>
                        {tag}
                      </Select.Option>
                    );
                  })
                ) : (
                  <Select.Option value="无">无</Select.Option>
                )}
              </Select>
            </Form.Item>
            {assetStatuses && assetStatuses.length > 0 ? (
              <div>
                <Tabs
                  defaultActiveKey={status ? status.status_name : allAssetStatusTags[0]}
                  onChange={(activeKey) => {
                    setCheckedStatusName(activeKey);
                    setRenderTable(true);
                  }}
                  activeKey={checkedStatusName}
                  tabBarExtraContent={
                    <>
                      <Tooltip title="添加资产状态">
                        <PlusOutlined
                          onClick={() => {
                            setCreateStatusVisible(true);
                          }}
                          className="icon-btn"
                        >
                          +添加
                        </PlusOutlined>
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip title={'编辑'}>
                        <EditOutlined onClick={() => setEditStatusVisible(true)} className="icon-btn" />
                      </Tooltip>
                      {currentFilteredAssetStatus && currentFilteredAssetStatus.status_type === '数值' && (
                        <>
                          <Divider type="vertical" />
                          <Tooltip title={renderTable ? '点击切换为图表显示' : '点击切换为表格显示'}>
                            {renderTable ? (
                              <BarChartOutlined onClick={() => setRenderTable(!renderTable)} className="icon-btn" />
                            ) : (
                              <TableOutlined onClick={() => setRenderTable(!renderTable)} className="icon-btn" />
                            )}
                          </Tooltip>
                        </>
                      )}
                    </>
                  }
                >
                  {allAssetStatusTags.map((tag, index) => {
                    return (
                      <Tabs.TabPane tab={tag} key={tag}>
                        {renderTable ? (
                          <div>
                            <Table
                              dataSource={assetStatuses.filter((x) => x.status_name === tag).reverse()}
                              loading={loadingInfo}
                              rowClassName={(record, index) => {
                                if (status && record.status_id === status.status_id) {
                                  return 'editing-item';
                                } else {
                                  return '';
                                }
                              }}
                              rowKey={(record) => record.status_id.toString()}
                              pagination={
                                assetStatuses.filter((x) => x.status_name === tag).length > 10 ? undefined : false
                              }
                              size="small"
                              columns={(() => {
                                return [
                                  {
                                    title: '序号',
                                    align: 'center' as 'center',
                                    key: 'order',
                                    render(_: any, record: AssetStatusItem, idx: number) {
                                      return idx + 1;
                                    },
                                  },
                                  {
                                    title: '状态值',
                                    align: 'center' as 'center',
                                    dataIndex: 'value',
                                    key: 'value',
                                    render(_: any, record: AssetStatusItem) {
                                      return (
                                        <>
                                          {record.status_value}
                                          {/* 2020.08.25 对isuse===1 的状态提供修改和历史的展示*/}
                                          {true && (
                                            <Popconfirm
                                              title={<StatusValueHistoryTable status_id={record.status_id} />}
                                              icon={null}
                                              trigger="hover"
                                              okButtonProps={{
                                                style: { display: 'none' },
                                              }}
                                              cancelButtonProps={{
                                                style: { display: 'none' },
                                              }}
                                              destroyTooltipOnHide
                                            >
                                              <HistoryOutlined
                                                style={{
                                                  color: '#1691ff',
                                                  fontSize: 14,
                                                  marginLeft: 2,
                                                  cursor: 'pointer',
                                                }}
                                              />
                                            </Popconfirm>
                                          )}
                                        </>
                                      );
                                    },
                                  },
                                  {
                                    title:
                                      assetStatuses.filter((x) => x.status_name === tag)[0].status_standards.length >
                                      0 ? (
                                        <Select
                                          size="small"
                                          defaultValue={
                                            assetStatuses.filter((x) => x.status_name === tag)[0].status_standards[0]
                                              .status_standard_name
                                          }
                                          onChange={(option: string) => {
                                            setCurrentStandardName(option);
                                          }}
                                        >
                                          {assetStatuses
                                            .filter((x) => x.status_name === tag)[0]
                                            .status_standards.map((standard, index) => {
                                              return (
                                                <Select.Option
                                                  key={standard.uid + index}
                                                  value={standard.status_standard_name}
                                                >
                                                  {standard.status_standard_name}
                                                </Select.Option>
                                              );
                                            })}
                                        </Select>
                                      ) : (
                                        '标准'
                                      ),
                                    align: 'center' as 'center',
                                    dataIndex: 'standard',
                                    key: 'standard',
                                    render(_: any, record: AssetStatusItem) {
                                      let standardName =
                                        currentStandardName === ''
                                          ? record.status_standards.length > 0
                                            ? record.status_standards[0].status_standard_name
                                            : ''
                                          : currentStandardName;

                                      let value = '';
                                      let checkedStandard: Statusstandard[] = [];
                                      if (record.status_standards.length > 0) {
                                        checkedStandard = record.status_standards.filter(
                                          (x) => x.status_standard_name === standardName
                                        );
                                        if (checkedStandard.length > 0) {
                                          value = checkedStandard[0].standard_value;
                                        }
                                      }

                                      return checkedStandard.length > 0 ? (
                                        <span>
                                          {value}
                                          <Tooltip
                                            placement="right"
                                            title={
                                              <div>
                                                {checkedStandard[0].standard_ranges.map((x, index) => {
                                                  return (
                                                    <div
                                                      key={`${x.range_name}-${index}`}
                                                    >{`${x.range_name}:${x.range}`}</div>
                                                  );
                                                })}
                                              </div>
                                            }
                                          >
                                            <QuestionCircleFilled
                                              style={{
                                                color: '#1691ff',
                                                fontSize: 15,
                                                marginLeft: 2,
                                              }}
                                            />
                                          </Tooltip>
                                        </span>
                                      ) : (
                                        '无'
                                      );
                                    },
                                  },
                                  {
                                    title: '备注',
                                    align: 'center' as 'center',
                                    dataIndex: 'comment',
                                    key: 'comment',
                                    render(_: any, record: AssetStatusItem, idx: number) {
                                      return <span>{record.comment ? record.comment : '无'}</span>;
                                    },
                                  },
                                  {
                                    title: '记录人',
                                    align: 'center' as 'center',
                                    dataIndex: 'user_name',
                                    key: 'user_name',
                                    render(_: any, record: AssetStatusItem, idx: number) {
                                      return (
                                        <span>
                                          {record.user_name}
                                          <SingleUserCompanyIdInfoTooltip user_id={record.user_id} />
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    title: '记录时间',
                                    align: 'center' as 'center',
                                    dataIndex: 'creat_time',
                                    key: 'creat_time',
                                    render(_: any, record: AssetStatusItem, idx: number) {
                                      return <>{formatTime(record.create_time)}</>;
                                    },
                                  },

                                  {
                                    title: '图片',
                                    align: 'center' as 'center',
                                    dataIndex: 'image',
                                    key: 'image',
                                    render(_: any, record: AssetStatusItem) {
                                      return (
                                        <DropdownPicWidget
                                          status={record}
                                          upload={uploadStatusPic}
                                          fetchAssetData={fetchAssetData}
                                        />
                                      );
                                    },
                                  },
                                  {
                                    title: '操作',
                                    align: 'center' as 'center',
                                    dataIndex: 'option',
                                    key: 'option',
                                    render(_: any, record: AssetStatusItem, idx: number) {
                                      return (
                                        <>
                                          <span
                                            className="drawer-text-btn"
                                            onClick={() => {
                                              setCurrentItemToUpdate(record);
                                              setModifyStatusHistoryVisible(true);
                                            }}
                                          >
                                            修改
                                          </span>
                                          <Divider type="vertical" />
                                          <span
                                            className="drawer-text-btn"
                                            onClick={() => {
                                              removeAssetStatusItem(record.status_id, record.status_name);
                                            }}
                                          >
                                            删除
                                          </span>
                                        </>
                                      );
                                    },
                                  },
                                ];
                              })()}
                            />
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <Button
                                type="primary"
                                size="small"
                                style={{ padding: '0 24px', borderRadius: '0px 0px 10px 10px' }}
                                onClick={() => setUpdateStatusValueVisible(true)}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <ReactEChartsCore
                              echarts={echarts}
                              option={getAssetStatusOption(tag)}
                              style={{ height: '350px', width: '100%' }}
                              lazyUpdate={true}
                              notMerge={true}
                              theme={'theme_name'}
                            />
                          </div>
                        )}
                      </Tabs.TabPane>
                    );
                  })}
                </Tabs>
              </div>
            ) : (
              <div className="empty-container">
                <Divider />
                <Empty description="暂无资产状态记录" />
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateStatusVisible(true);
                  }}
                >
                  点击添加
                </Button>
              </div>
            )}
          </Form>
          {createStatusVisible && (
            <Modal
              visible={createStatusVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>创建资产状态
                </span>
              }
              footer={null}
              onCancel={() => {
                setCreateStatusVisible(false);
              }}
            >
              <CreateStatusForm />
            </Modal>
          )}
          {createTagVisible && (
            <Modal
              visible={createTagVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>创建标签
                </span>
              }
              footer={null}
              onCancel={() => {
                setCreateTagVisible(false);
              }}
            >
              <CreateTagForm />
            </Modal>
          )}
          {modifyStatusHistroyVisible && (
            <Modal
              visible={modifyStatusHistroyVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>修改历史状态值
                </span>
              }
              footer={null}
              onCancel={() => {
                setModifyStatusHistoryVisible(false);
              }}
            >
              <ModifyStatusHistroyForm />
            </Modal>
          )}
          {updateStatusValueVisible && (
            <Modal
              visible={updateStatusValueVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>更新状态值
                </span>
              }
              footer={null}
              onCancel={() => {
                setUpdateStatusValueVisible(false);
              }}
            >
              <UpdateStatusValueForm />
            </Modal>
          )}
          {editStatusVisible && (
            <Modal
              visible={editStatusVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>编辑资产状态
                </span>
              }
              footer={null}
              onCancel={() => {
                setEditStatusVisible(false);
              }}
            >
              <EditStatusForm />
            </Modal>
          )}
          {editCompsVisible && (
            <Modal
              visible={editCompsVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>编辑资产组件
                </span>
              }
              footer={null}
              onCancel={() => {
                setEditCompsVisible(false);
              }}
            >
              <EditCompsForm />
            </Modal>
          )}
          {addVcompsVisible && (
            <Modal
              visible={addVcompsVisible}
              destroyOnClose
              title={
                <span>
                  <div className="title-prefix"></div>添加地理信息
                </span>
              }
              footer={null}
              onCancel={() => {
                setAddVcompsVisible(false);
              }}
            >
              <AddVcompsForm />
            </Modal>
          )}
          {UpdateAssetTagVisible && (
            <TagDetail
              visible={UpdateAssetTagVisible}
              asset_id={asset_id}
              tag_name={editingTag}
              setVisible={setUpdateAssetTagVisible}
              setTagName={SetEditingTag}
              reload={fetchAssetTagsData}
            />
          )}
          <Modal
            visible={updateVStatusModalVisible}
            title={
              <span>
                <div className="title-prefix"></div>修改地理信息
              </span>
            }
            okText={fetching ? '提交中' : '确认修改'}
            okButtonProps={{ disabled: fetching, loading: fetching }}
            cancelText="取消"
            onOk={async () => {
              if (!currentVstatus2Update || !asset) return;
              setFetching(true);
              const v_asset_status = asset.v_asset_status.map((x) => x);
              v_asset_status[currenUpdateVAssetStatusIndex] = currentVstatus2Update;
              const res = await AssetsRequest.updateAssetItem({
                ...asset,
                v_asset_status: v_asset_status,
                v_asset_comps: v_asset_status.map((x) => x.v_status_name),
              });
              if (res.code === 0) {
                message.success('修改成功');
                fetchAssetData();
                setUpdateVStatusModalVisible(false);
              } else {
                message.error('修改失败');
              }
              setFetching(false);
            }}
            onCancel={() => setUpdateVStatusModalVisible(false)}
          >
            {currentVstatus2Update && (
              <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                <Form.Item label="地理组件">
                  <Input
                    value={currentVstatus2Update.v_status_name}
                    onChange={(e) =>
                      setCurrentVstatus2Update({
                        ...currentVstatus2Update,
                        v_status_name: e.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="地理值">
                  <Input
                    value={currentVstatus2Update.v_status_value}
                    onChange={(e) =>
                      setCurrentVstatus2Update({
                        ...currentVstatus2Update,
                        v_status_value: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </Form>
            )}
          </Modal>
        </main>
      )}
    </Drawer>
  );
}
