import * as React from 'react';
import { PageHeader, Button, Modal, message, Table, Descriptions, Divider, Tag, Tabs, Popconfirm, Image } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import { RouteComponentProps } from 'react-router-dom';
import { getFormDataById } from '@/requests/form';
import '../styles/index.less';
import * as FormRequests from '@/requests/form';
import { useHistory, useLocation } from 'react-router-dom';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const PicPreviewWidget = ({ pics }: { pics: any[] }) => {
  const [visible, setVisible] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  return (
    <>
      {pics.map((pic, index) => {
        return (
          <Image
            src={`/v2/api/pic/picture/download/50/${pic.pic_uid}`}
            style={{ height: 20, width: 20, margin: 2 }}
            preview={{ visible: false }}
            onClick={() => {
              setVisible(true);
              setCurrent(index);
            }}
          />
        );
      })}
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis), current: current }}>
          {pics.length !== 0
            ? pics.map((x, index) => {
                return <Image src={`/v2/api/pic/picture/download/50/${x.pic_uid}`} key={x.pic_uid} />;
              })
            : null}
        </Image.PreviewGroup>
      </div>
    </>
  );
};

const AllPagesData = ({ formData, fetching }: { formData: FormDataItem; fetching: boolean }) => {
  function getColConfg<ROW extends FormDataPageItem>({ formData }: { formData: FormDataItem }): any[] {
    if (formData.form_pages_data.length < 1) {
      return [];
    }
    let res = [] as any[];
    if (formData.form_pages_data[0].form_page_contents.length === 1) {
      return res.concat(
        [
          {
            title: '序号',
            align: 'center',
            key: 'no',
            render(_: any, record: ROW, idx: number) {
              return idx + 1;
            },
          },
        ],
        [
          {
            title: '状态名称',
            align: 'center',
            key: `status_name`,
            render(_: any, record: ROW, idx: number) {
              return record.form_page_contents[0].status_name;
            },
          },
          {
            title: '状态值',
            align: 'center',
            key: `status_value`,
            render(_: any, record: ROW, idx: number) {
              return record.form_page_contents[0].status_value;
            },
          },
          ...formData.form_pages_data[0].form_page_contents[0].v_asset_comps.map((vcomp, v_index) => {
            return {
              title: vcomp,
              align: 'center',
              key: `vcomp-${v_index}`,
              render(_: any, record: ROW, idx: number) {
                return record.form_page_contents[0].v_asset_status[vcomp];
              },
            } as any;
          }),
          {
            title: '图片',
            align: 'center',
            key: `-status_pic`,
            render(_: any, record: ROW, idx: number) {
              const pics = record.form_page_contents[0].status_pics;
              if (pics.length < 1) {
                return '无';
              }
              return <PicPreviewWidget pics={pics} />;
            },
          },
          {
            title: '备注',
            align: 'center',
            key: `commit`,
            render(_: any, record: ROW, idx: number) {
              const comment = record.form_page_contents[0].comment;
              if (!comment) {
                return '无';
              }
              return comment;
            },
          },
        ]
      );
    }
    return res.concat(
      [
        {
          title: '序号',
          align: 'center',
          key: 'no',
          render(_: any, record: ROW, idx: number) {
            return idx + 1;
          },
        },
      ],

      formData.form_pages_data[0].form_page_contents.map((x, _index) => {
        return {
          title: x.status_name,
          align: 'center',
          key: x.field_index,
          children: [
            {
              title: '状态值',
              align: 'center',
              key: `${x.field_index}-status_value`,
              render(_: any, record: ROW, idx: number) {
                return record.form_page_contents[_index].status_value;
              },
            },
            ...x.v_asset_comps.map((vcomp, v_index) => {
              return {
                title: vcomp,
                align: 'center',
                key: `${x.field_index}-vcomp-${v_index}`,
                render(_: any, record: ROW, idx: number) {
                  return record.form_page_contents[_index].v_asset_status[vcomp];
                },
              } as any;
            }),
            {
              title: '图片',
              align: 'center',
              key: `${x.field_index}-status_pic`,
              render(_: any, record: ROW, idx: number) {
                const pics = record.form_page_contents[_index].status_pics;
                if (pics.length < 1) {
                  return '无';
                }
                return (
                  <>
                    <PicPreviewWidget pics={pics} />
                  </>
                );
              },
            },
            {
              title: '备注',
              align: 'center',
              key: `${x.field_index}-commit`,
              render(_: any, record: ROW, idx: number) {
                const comment = record.form_page_contents[_index].comment;
                if (!comment) {
                  return '无';
                }
                return comment;
              },
            },
          ],
        };
      }),

      [
        {
          title: '提交者',
          align: 'center',
          key: 'user',
          render(_: any, record: ROW, idx: number) {
            return record.user_name;
          },
        },
      ]
    );
  }
  return (
    <Table
      scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      dataSource={formData.form_pages_data}
      columns={getColConfg({ formData })}
      className="formdata-table"
      bordered
      size="small"
      style={{ fontSize: 12 }}
      rowKey={(record, index) => record.page_index}
      loading={fetching}
    />
  );
};

const NoAssetFormDatas = ({ formData }: { formData: FormDataItem }) => {
  function getColConfg<ROW extends NoAssetFormDataPageItem>({ formData }: { formData: FormDataItem }): any[] {
    if (formData.form_pages_data.length < 1) {
      return [];
    }
    return [
      {
        title: '序号',
        align: 'center',
        key: 'no',
        render(_: any, record: ROW, idx: number) {
          return idx + 1;
        },
      },
      {
        title: '资产名称',
        align: 'center',
        key: 'asset_name',
        render(_: any, record: ROW, idx: number) {
          return record.asset_name;
        },
      },
      ...Array(maxCompsLength)
        .fill(undefined)
        .map((x, _index) => {
          const count = _index + 1;
          const key = `AssetsComs-ColChild-${_index}`;
          return {
            key: key,
            align: 'center',
            title: `资产组件${count}`,
            render(_: any, record: ROW) {
              if (!record.asset_comps) return null;

              const curName = record.asset_comps[_index] ? record.asset_comps[_index] : '';
              return curName;
            },
          };
        }),
      ...Array(maxVCompsLength)
        .fill(undefined)
        .map((x, _index) => {
          const count = _index + 1;
          const key = `VAssetsComs-ColChild-${_index}`;
          return {
            key: key,
            align: 'center',
            title: `地理组件组件${count}`,
            render(_: any, record: ROW) {
              if (!record.v_asset_comps || record.v_asset_statuses.length < 1) return null;

              const curName =
                record.v_asset_comps[_index] && record.v_asset_statuses[_index]
                  ? `${record.v_asset_comps[_index]}:${record.v_asset_statuses[_index].v_status_value}`
                  : '';
              return curName;
            },
          };
        }),
      {
        title: '提交者',
        align: 'center',
        key: 'user',
        render(_: any, record: ROW, idx: number) {
          return record.submitted_user_name;
        },
      },
      {
        title: '操作',
        key: 'action',
        align: 'center',
        render: (_: string, record: ROW) => {
          if (!record) return null;
          return (
            <span>
              <span className="span-btn ant-btn-link" onClick={() => doBind(record.uid)} key="bind">
                绑定
              </span>
              <Divider type="vertical" />
              <Popconfirm
                title="确定删除这条数据吗?"
                onConfirm={() => {
                  doDelete([record.uid]);
                }}
                okType="danger"
                okText="确定"
                cancelText="不了"
              >
                <span className="span-btn ant-btn-link ant-btn-link__danger" key="delete">
                  删除
                </span>
              </Popconfirm>
            </span>
          );
        },
      },
    ];
  }
  const [data, setData] = React.useState<NoAssetFormDataPageItem[]>([]);
  const [fetching, setFetching] = React.useState(false);
  const [pagination, setPagination] = React.useState<{
    page: number;
    pageSize: number;
    total: number;
  }>({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [maxCompsLength, setMaxCompsLength] = React.useState(0);
  const [maxVCompsLength, setMaxVCompsLength] = React.useState(0);

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = 10;
    }
    const res = await FormRequests.getNoAseetFormDatas({
      limit: pageSize,
      skip: pageSize * (page - 1),
      form_id: formData.form_data.form_id,
    });
    if (res.code === 0) {
      let compsLengths = res.data.map((x) => x.asset_comps.length);
      let vCompsLengths = res.data.map((x) => x.v_asset_comps.length);
      compsLengths.sort((a, b) => b - a);
      vCompsLengths.sort((a, b) => b - a);
      setMaxCompsLength(compsLengths[0]);
      setMaxVCompsLength(vCompsLengths[0]);
      setData(res.data);
      setPagination({
        ...pagination,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const doBind = async (uid: string) => {
    if (!uid) return;
    message.loading('正在绑定');
    const res = await FormRequests.bindAsset4NoAssetFormData({
      uid,
    });
    if (res.code === 0) {
      message.destroy();
      message.success('绑定成功');
      setFetching(true);
      fetchData();
    } else {
      message.destroy();
      message.error(res.message);
    }
  };

  const doDelete = async (uids: string[]) => {
    if (uids.length < 1) return;
    message.loading('正在删除');
    const res = await FormRequests.deleteNoAssetFormData({ uids });
    message.destroy();
    if (res.code === 0) {
      message.success('删除成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    setFetching(true);
    fetchData();
  }, []);

  return (
    <Table
      scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      dataSource={data}
      columns={getColConfg({ formData })}
      bordered
      size="small"
      className="formdata-table"
      style={{ fontSize: 12 }}
      rowKey={(record, index) => record.uid}
      loading={fetching}
      pagination={{
        ...pagination,
        onChange: (page, pageSize) => {
          setFetching(true);
          fetchData(page, pageSize);
        },
        showTotal: (total: number) => {
          return <span>{`共 ${total} 条数据`}</span>;
        },
      }}
    />
  );
};

const FormDataDetail = (props: RouteComponentProps) => {
  const history = useHistory();
  const location = useLocation<any>();

  const [formData, setFormData] = React.useState<FormDataItem | undefined>(undefined);
  const [submiting, setSubmiting] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const fetchFormData = async () => {
    let form_id = '';
    form_id = location.state ? location.state.form_id : location.search.split('=')[1];
    if (!form_id) return;

    const res = await getFormDataById(form_id);
    if (res.code === 0) {
      setFormData(res.data);
    } else {
      message.error('网络错误');
    }
    setFetching(false);
  };

  const submitFormData = async () => {
    if (!formData) return;
    setSubmiting(true);
    const res = await FormRequests.submitFormData(formData.form_data.form_id);
    if (res.code === 0) {
      message.success('提交成功');
      setTimeout(() => {
        history.goBack();
      }, 500);
    } else {
      message.error('提交失败，请稍后再试');
    }
    setSubmiting(false);
  };

  React.useEffect(() => {
    setFetching(true);
    fetchFormData();
  }, []);

  return (
    <div style={{ padding: 16, backgroundColor: '#ffffff' }}>
      {formData && (
        <div>
          <PageHeader
            title={'表单详情'}
            onBack={() => history.goBack()}
            style={{ padding: 0 }}
            extra={[
              <Button
                key="submit"
                type="primary"
                icon={<UploadOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: '提示',
                    content: '确认提交当前表单吗？',
                    okText: '确认',
                    onOk: () => {
                      submitFormData();
                    },
                    cancelText: '取消',
                  });
                }}
                style={{
                  display: formData.form_data.is_submitted ? 'none' : 'inline-block',
                }}
                loading={submiting}
              >
                提交表单
              </Button>,
              <Button
                key="edit"
                icon={<EditOutlined />}
                onClick={() => {
                  history.push({
                    pathname:
                      formData.form_data.form_tag === 1
                        ? '/workbench/forms-management/form-data-list/submit-formdata'
                        : '/workbench/forms-management/form-data-list/submit-free-formdata',
                    state: {
                      form_id: formData.form_data.form_id,
                    },
                    search: `form_id=${formData.form_data.form_id}`,
                  });
                }}
                style={{
                  display: formData.form_data.is_submitted ? 'none' : 'inline-block',
                }}
              >
                继续填写
              </Button>,
            ]}
          ></PageHeader>
          <Divider></Divider>
          <Descriptions
            title={
              <span>
                <div className="title-prefix"></div>表单信息
              </span>
            }
            style={{ margin: '0 48px' }}
          >
            <Descriptions.Item label="模板名称">{formData.form_data.form_template_name}</Descriptions.Item>
            <Descriptions.Item label="模板id">{formData.form_data.form_template_share_uid}</Descriptions.Item>
            <Descriptions.Item label="模板版本号">{formData.form_data.form_template_version_name}</Descriptions.Item>
            <Descriptions.Item label="创建时间">{formData.form_data.create_time}</Descriptions.Item>
            <Descriptions.Item label="表单id">{formData.form_data.form_id}</Descriptions.Item>
            <Descriptions.Item label="已填写页数">{formData.form_pages_data.length}</Descriptions.Item>
            <Descriptions.Item label="状态">
              {formData.form_data.is_submitted ? <Tag color="green">已提交</Tag> : <Tag color="gold">未提交</Tag>}
            </Descriptions.Item>
            {formData.form_data.is_submitted && (
              <Descriptions.Item label="提交时间">{formData.form_data.submitted_time}</Descriptions.Item>
            )}
          </Descriptions>
          <Divider>表单数据</Divider>
          {formData.form_data.is_submitted ? (
            <Tabs
              defaultActiveKey="1"
              renderTabBar={(props: any, defaultTabBar: any) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      paddingBottom: 20,
                    }}
                  >
                    <ButtonGroup>
                      {props.panes.map((x: any, _index: number) => {
                        return (
                          <Button
                            type={x.key === props.activeKey ? 'primary' : undefined}
                            key={_index}
                            onClick={() => props.onTabClick(x.key)}
                          >
                            {x.props.tab}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </div>
                );
              }}
            >
              <TabPane tab="填写详情" key="1">
                <AllPagesData formData={formData} fetching={fetching} />
              </TabPane>
              <TabPane tab="未绑定资产提交" key="2">
                <NoAssetFormDatas formData={formData} />
              </TabPane>
            </Tabs>
          ) : (
            <AllPagesData formData={formData} fetching={fetching} />
          )}
        </div>
      )}
    </div>
  );
};

export default FormDataDetail;
