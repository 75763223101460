import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getDetectRecordsOfModel,
  getDetectedUsers,
  checkDetectResult,
  getBindAssetStatus,
  getDetectPdf,
} from '@/requests/AIDetection';
import {
  message,
  Button,
  Form,
  Input,
  Badge,
  List,
  Tooltip,
  DatePicker,
  Popover,
  Row,
  Col,
  Image,
  Empty,
  Select,
  Avatar,
  Modal,
  Radio,
  Divider,
  Table,
} from 'antd';
import { SearchOutlined, PictureOutlined, OrderedListOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAllUsers } from '@/requests/user';

interface DetailModalProps {
  visible: boolean;
  setVisible: Function;
  images: AiDetectDataDtolistItem[];
}

// interface CheckModalProps {
//   visible: boolean;
//   images: AiDetectDataDtolistItem[];
//   setVisible: Function;
//   fetchData: Function;
// }

// const CheckModal = (props: CheckModalProps) => {
//   const { visible, images, setVisible, fetchData } = props;
//   const [loading, setLoading] = React.useState(false);
//   const [form] = Form.useForm();
//   const handleCancel = () => {
//     setVisible(false);
//   };
//   const handleOk = async (values: any) => {
//     console.log(values);
//     setLoading(true);
//     const res = await checkDetectResult({
//       ai_detect_data_list: images.map((item) => {
//         return {
//           id: item.id,
//           inaccurate_detection: values[`inaccurate_detection-${item.image_uid}`],
//           inaccurate_detection_remark: values[`inaccurate_detection_remark${item.image_uid}`],
//         };
//       }),
//     });
//     if (res.code === 0) {
//       fetchData();
//       message.success('提交成功');
//       setVisible(false);
//     } else {
//       message.error(res.message);
//     }
//   };

//   return (
//     <Modal
//       visible={visible}
//       title={'结果校验'}
//       bodyStyle={{
//         maxHeight: 700,
//         overflowY: 'auto',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//       }}
//       onCancel={handleCancel}
//       onOk={() => {
//         Modal.confirm({
//           title: '确认提交',
//           content: '确认提交校验结果？',
//           onOk: () => {
//             form.submit();
//           },
//         });
//       }}
//       okButtonProps={{ loading: loading }}
//       okText="提交"
//     >
//       <div>
//         <Form form={form} style={{}} onFinish={handleOk}>
//           {images.map((item, index) => {
//             return (
//               <div key={item.image_uid}>
//                 <Divider> {item.image_name}</Divider>
//                 <Form.Item
//                   name={`inaccurate_detection-${item.image_uid}`}
//                   initialValue={item.inaccurate_detection ? item.inaccurate_detection : '准确'}
//                   rules={[{ required: true, message: '请选择准确度' }]}
//                 >
//                   <Radio.Group>
//                     <Radio value={'准确'}>准确</Radio>
//                     <Radio value={'不准确'}>不准确</Radio>
//                   </Radio.Group>
//                 </Form.Item>
//                 <Image src={item.image_url}></Image>
//                 <Form.Item
//                   name={`inaccurate_detection_remark${item.image_uid}`}
//                   initialValue={item.inaccurate_detection_remark ? item.inaccurate_detection_remark : ''}
//                 >
//                   <Input.TextArea placeholder="备注" />
//                 </Form.Item>
//               </div>
//             );
//           })}
//         </Form>
//       </div>
//     </Modal>
//   );
// };

const DetailModal = (props: DetailModalProps) => {
  const { visible, setVisible, images } = props;
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <Modal visible={visible} title={'详情'} onCancel={handleCancel} footer={null} width={1000}>
      <div>
        <Table
          dataSource={images}
          pagination={{
            pageSize: 5,
          }}
          columns={[
            // 表格列的配置描述，具体项见下表
            {
              title: '序号',

              key: 'order',
              render: (text: string, record, index) => <span>{index + 1}</span>,
            },
            {
              title: '图片',
              dataIndex: 'image_url',
              key: 'image_url',
              render: (text: string) => <Image width={50} src={text} />,
            },

            {
              title: '检测人',
              dataIndex: 'create_user_name',
              key: 'create_user_name',
            },
            {
              title: '标记时间',
              dataIndex: 'update_time',
              key: 'update_time',
            },
            {
              title: '不准确信息',
              dataIndex: 'inaccurate_detection_remark',
              key: 'inaccurate_detection_remark',
              render: (text: string) => <span>{text ? text : '无'}</span>,
            },
          ]}
        />
      </div>
    </Modal>
  );
};

const InaccurateManagement = () => {
  const [data, setData] = React.useState<DetectRecordsItem[]>([]);
  const [modelID, setModelID] = React.useState<string>('');
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  // const history = useHistory();
  const location = useLocation();
  // const [myDetectRecordCount, setMyDetectRecordCount] = React.useState(0);
  const [userOptions, setUserOptions] = React.useState<UserInfoItem[]>([]);
  const [filterUserId, setFilterUserId] = React.useState<number>();
  const [filterTime, setFilterTime] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  // const [checkVisible, setCheckVisible] = React.useState(false);
  // const [checkImages, setCheckImages] = React.useState<AiDetectDataDtolistItem[]>([]);
  const [assetStatus, setAssetStatus] = React.useState<DetectAssetStatus[]>([]);
  const [statusLoading, setStatusLoading] = React.useState(false);
  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const [detailVisible, setDetailVisible] = React.useState(false);
  const [showingImages, setShowingImages] = React.useState<AiDetectDataDtolistItem[]>([]);

  React.useEffect(() => {
    if (location.pathname === '/workbench/ai/inaccurate-data') {
      // if (location.state) {
      //   let state = location.state as any;
      //   setModelID(state.model_id);
      // } else {
      //   if (location.search) {
      //     let search = location.search as any;
      //     let model_id = search.split('=')[1];
      //     setModelID(model_id);
      //   }
      // }
      fetchData();
    }
  }, [location]);

  // //获取检测报告链接
  // const getDetectReportUrl = async (id: number) => {
  //   message.loading('正在获取检测报告');
  //   const res = await getDetectPdf(id);
  //   if (res.code === 0) {
  //     setTimeout(() => {
  //       window.open(res.data);
  //       message.destroy();
  //     }, 1000);
  //   } else {
  //     message.error(res.message);
  //   }
  // };

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getDetectRecordsOfModel({
      // model_id: modelID,
      inaccurate_detection: '不准确',
      limit: pageSize,
      skip: (page - 1) * pageSize,
      ...(filterUserId && { user_id: filterUserId }),
      ...(filterTime && { create_time_begin: filterTime[0], create_time_end: filterTime[1] }),
    });
    if (res.code === 0) {
      setPagination({
        current: page,
        pageSize: pageSize,
        total: res.total,
      });
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const fetchUsers = async () => {
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: '',
    });
    if (res.code === 0) {
      setUserOptions(res.data);
    } else {
      message.error(res.message);
    }
  };

  const fetchAssetStatus = async (id: number) => {
    setStatusLoading(true);
    const res = await getBindAssetStatus(id);
    if (res.code === 0) {
      const { data } = res;
      setAssetStatus(res.data);
      const vLengthList = data.map((x) => Object.keys(x.v_asset_status).length);
      const compsLengthList = data.map((x) => x.asset_comps.length);
      vLengthList.sort((a, b) => b - a);
      compsLengthList.sort((a, b) => b - a);
      setMaxVStatusCount(vLengthList[0]);
      setMaxCopmsCount(compsLengthList[0]);
    } else {
      message.error(res.message);
    }
    setStatusLoading(false);
  };
  React.useEffect(() => {
    if (modelID) {
      fetchData();
    }
  }, [modelID]);

  return (
    <div className="model-list-page">
      <div className="tools-wrapper">
        <div className="addr-list-filter-input-container">
          <Form layout="inline">
            <Form.Item label="检测人" className="filter-item">
              <Select
                value={filterUserId}
                allowClear
                onChange={(value: number) => {
                  setFilterUserId(value);
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    fetchUsers();
                  }
                }}
                placeholder="全部"
              >
                {userOptions.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      <List.Item.Meta
                        avatar={
                          item.avatar ? (
                            <Avatar src={`v2/api/user/avatar/${item.avatar}`} size={'small'} />
                          ) : (
                            <Avatar size={'small'}>{item.username}</Avatar>
                          )
                        }
                        title={<span style={{ color: '#f39800' }}>{item.username}</span>}
                        description={null}
                        style={{ display: 'flex', alignItems: 'center', padding: 0 }}
                      />
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="日期" className="filter-item">
              <DatePicker.RangePicker
                onChange={(value) => {
                  if (!value) return;
                  setFilterTime(
                    value.map((x) => {
                      return moment(x).format('YYYY-MM-DD') + ' 00:00:00';
                    })
                  );
                }}
              ></DatePicker.RangePicker>
            </Form.Item>
          </Form>
        </div>
        <div style={{ display: 'flex' }}>
          <Button onClick={() => fetchData()} icon={<SearchOutlined />} type="primary">
            搜索
          </Button>
        </div>
      </div>
      <div className="model-list-container">
        <List
          dataSource={data}
          loading={loading}
          pagination={{
            ...pagination,
            showTotal: (total) => `共 ${total} 条`,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
          renderItem={(item) => (
            <List.Item
              extra={[
                <Popover
                  placement="bottom"
                  content={
                    <Table
                      dataSource={assetStatus}
                      loading={statusLoading}
                      pagination={{
                        pageSize: 8,
                      }}
                      size={'small'}
                      columns={[
                        {
                          title: '资产状态名',
                          dataIndex: 'status_name',
                          key: 'status_name',
                        },
                        {
                          title: '资产状态值',
                          dataIndex: 'status_value',
                          key: 'status_value',
                        },
                        {
                          title: '状态值类型',
                          dataIndex: 'status_type',
                          key: 'status_type',
                        },
                        {
                          title: '资产名称',
                          dataIndex: 'asset_name',
                          key: 'asset_name',
                        },
                        ...Array(maxCompsCount)
                          .fill(undefined)
                          .map((_, idx) => {
                            const count = idx + 1;
                            const key = `AssetsComs-ColChild-${idx}`;
                            return {
                              key: key,
                              title: `资产组件${count}`,
                              render(_: any, record: DetectAssetStatus) {
                                if (!record.asset_comps) return null;
                                const curName = record.asset_comps[idx] ? record.asset_comps[idx] : '';
                                return <span>{curName}</span>;
                              },
                            };
                          }),

                        ...Array(maxVStatusCount)
                          .fill(undefined)
                          .map((_, idx) => {
                            const count = idx + 1;
                            const key = `GeoInfo-ColChild-${idx}`;
                            return {
                              key,
                              title: `地理组件${count}`,
                              render(_: any, record: DetectAssetStatus) {
                                let curName = '';
                                const _key = Object.keys(record.v_asset_status)[idx];
                                if (_key) {
                                  curName = `${_key}：${record.v_asset_status[_key]}`;
                                }
                                return <span>{curName}</span>;
                              },
                            };
                          }),
                      ]}
                    />
                  }
                  onVisibleChange={(visible) => {
                    if (visible) {
                      fetchAssetStatus(item.id);
                    } else {
                      setAssetStatus([]);
                    }
                  }}
                  title="资产状态记录"
                  trigger="click"
                >
                  <Tooltip title="查看绑定的资产状态">
                    <OrderedListOutlined style={{ color: '#f39800', marginRight: 4 }} />

                    <a onClick={(e) => {}} style={{ marginRight: 16 }}>
                      {`资产状态记录(${item.bind_asset_status_num})`}
                    </a>
                  </Tooltip>
                </Popover>,
                <Popover
                  content={
                    <Row style={{ maxWidth: 716 }} gutter={16}>
                      {item.ai_detect_data_dtolist.length > 0 ? (
                        item.ai_detect_data_dtolist.map((x) => {
                          return (
                            <Col key={x.image_uid}>
                              <Image
                                src={`/v2/api/pic/picture/download/${100}/${x.image_uid}`}
                                height={102}
                                width={102}
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <Empty />
                      )}
                    </Row>
                  }
                  title="检测图片"
                  trigger="click"
                >
                  <Tooltip title="模型检测图片" style={{ marginRight: 16 }}>
                    <PictureOutlined style={{ color: '#f39800', marginRight: 4 }} />

                    <a onClick={(e) => {}}>{`检测图片(${item.ai_detect_data_dtolist.length})`}</a>
                  </Tooltip>
                </Popover>,
                <Button
                  style={{ borderRadius: 5, marginLeft: 16 }}
                  type="primary"
                  onClick={() => {
                    setShowingImages(item.ai_detect_data_dtolist.filter((x) => x.inaccurate_detection === '不准确'));
                    setDetailVisible(true);
                  }}
                >
                  查看详情
                </Button>,
              ]}
              style={{ marginBottom: 16, backgroundColor: '#fff', padding: 16, borderRadius: 5 }}
            >
              <List.Item.Meta
                title={
                  <div>
                    <span>{item.project_name || '未知'}</span>
                  </div>
                }
                description={
                  <div>
                    <div>
                      <span style={{ marginRight: 16 }}>检测总数量：{item.ai_detect_data_dtolist.length}</span>
                      <span style={{ marginRight: 16 }}>
                        不准确数量：
                        {item.ai_detect_data_dtolist.filter((x) => x.inaccurate_detection === '不准确').length}
                      </span>
                    </div>
                    <div>
                      <span style={{ marginRight: 16 }}>
                        检测人：{item.create_user_name}/{item.create_user_company_user_id}
                      </span>
                      <span style={{ marginRight: 16 }}>检测时间：{item.create_time}</span>
                      <span style={{ marginRight: 16 }}>模型编号：{item.model_id}</span>
                    </div>
                  </div>
                }
              ></List.Item.Meta>
            </List.Item>
          )}
        ></List>
      </div>
      {/* {checkVisible && checkImages.length > 0 && (
        <CheckModal visible={checkVisible} images={checkImages} setVisible={setCheckVisible} fetchData={fetchData} />
      )} */}
      {detailVisible && <DetailModal visible={detailVisible} setVisible={setDetailVisible} images={showingImages} />}
    </div>
  );
};
export default InaccurateManagement;
