import * as React from 'react'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Switch,
  Select,
  InputNumber,
  DatePicker,
  Checkbox,
  message,
  Empty,
  Image,
  TimePicker,
  Pagination,
  Cascader,
} from 'antd'
import {
  AlignLeftOutlined,
  CalendarOutlined,
  PicCenterOutlined,
  UnorderedListOutlined,
  PictureOutlined,
  DatabaseOutlined,
  DownSquareOutlined,
  NumberOutlined,
  FieldTimeOutlined,
  PartitionOutlined,
  BarsOutlined,
  ProfileOutlined,
  BlockOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'
import PicWall from '@/ui-components/PicWall'
import moment from 'moment'
import EditableTree, { NodeItem } from './EditableTree'

interface propsType {
  setCurrent: Function
  setFields: Function
  fields: CreatePersonalTemplateFieldItem[]
  pagination: { page: number; total: number }
  setPagination: Function
  docreateFormTemplate: Function
}

interface MultiLevel {
  value: string
  label: string
  key: string
  children: MultiLevel[]
}

const fieldTypeOptions = [
  { label: '单行文字', value: '单行文字', icon: <PicCenterOutlined /> },
  { label: '多行文字', value: '多行文字', icon: <AlignLeftOutlined /> },
  { label: '单项选择', value: '单项选择', icon: <UnorderedListOutlined /> },
  { label: '多项选择', value: '多项选择', icon: <BlockOutlined /> },
  { label: '图片单选', value: '图片单选', icon: <PictureOutlined /> },
  { label: '图片多选', value: '图片多选', icon: <DatabaseOutlined /> },
  { label: '下拉框', value: '下拉框', icon: <DownSquareOutlined /> },
  { label: '多级下拉框', value: '多级下拉框', icon: <PartitionOutlined /> },
  { label: '数字', value: '数字', icon: <NumberOutlined /> },
  { label: '日期', value: '日期', icon: <CalendarOutlined /> },
  { label: '时间', value: '时间', icon: <FieldTimeOutlined /> },
  { label: '资产', value: '资产', icon: <BarsOutlined /> },
  { label: '资产状态', value: '资产状态', icon: <ProfileOutlined /> },
]

const CreateTemplateStepTwo = (props: propsType) => {
  const { fields, setCurrent, setFields, pagination, setPagination, docreateFormTemplate } = props
  const [editingField, setEditingField] = React.useState(0)
  const currentFields = fields.filter((x) => x.page_num === pagination.page)

  const addField = () => {
    let arr = fields.map((x) => x)
    arr.push({
      field_num: currentFields.length + 1,
      page_num: pagination.page,
      field_type: '单行文字',
      default_value: ' ',
      title: '',
      subtitle: '',
      prompt: ' ',
      checkout: 0,
      required: 1,
      min_word_num: 0,
      max_word_num: 100,
      custom_error_prompt: 0,
      error_prompt: ' ',
      auto_fill: 0,
      multiple_choices: [
        {
          type: 0,
          value: '选项一',
          comment: '',
          pic_num: 0,
        },
        {
          type: 0,
          value: '选项二',
          comment: '',
          pic_num: 0,
        },
      ],
      value_decimal: 0,
      thousand_separator: 0,
      percentage: 0,
      multi_levels: [],
      asset: null,
      asset_status: null,
      addr_status: null,
      field_setting: '',
    })

    setFields(arr)
    setEditingField(arr.filter((x) => x.page_num === pagination.page).length - 1)
  }
  const transform: any = (data: NodeItem) => {
    return {
      value: data.title,
      label: data.title,
      key: data.key,
      children: data.children.map((x) => transform(x)),
    }
  }

  React.useEffect(() => {
    setEditingField(0)
  }, [pagination])

  return (
    <Row style={{ width: '100%', minHeight: 600 }} gutter={8}>
      <Col span={6} style={{ padding: 16 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h4>
            <div className="title-prefix"></div>
            字段类型
          </h4>
          <div>
            <Radio.Group
              buttonStyle="solid"
              value={currentFields[editingField] ? currentFields[editingField].field_type : undefined}
              onChange={(e) => {
                let newArr = fields.map((x) => {
                  if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                    return {
                      ...x,
                      field_type: e.target.value,
                      multiple_choices:
                        e.target.value === '图片单选' || e.target.value === '图片多选' ? [] : x.multiple_choices,
                    }
                  } else {
                    return x
                  }
                })

                setFields(newArr)
              }}
              disabled={currentFields.length === 0}
            >
              {fieldTypeOptions.map((x) => {
                return (
                  <Radio.Button
                    value={x.value}
                    key={x.value}
                    style={{
                      margin: 5,
                      width: '30%',
                      textAlign: 'center',
                      minWidth: 120,
                    }}
                  >
                    {x.icon}
                    {x.label}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </div>
        </div>
      </Col>

      <Col
        span={10}
        style={{
          padding: 16,
          borderLeft: '2px solid #1890ff',
          borderRight: '2px solid #1890ff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '100%' }}>
          {currentFields.map((field, field_index) => {
            const fieldType = field.field_type
            return (
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  flexDirection: 'column',
                  justifyContent: 'center',

                  width: '100%',
                  border: editingField === field_index ? '1px dashed #1890ff' : '1px dashed #ffffff',
                  minHeight: 100,
                  marginBottom: 10,
                  padding: 20,
                  backgroundColor: editingField === field_index ? '#FCFCFC' : '#ffffff',
                }}
                key={field_index}
                onClick={() => setEditingField(field_index)}
              >
                <Form.Item
                  label={field.title ? field.title : '未命名字段'}
                  extra={field.subtitle ? field.subtitle : '请添加字段说明'}
                  style={{ marginBottom: 0 }}
                  required={field.required ? true : false}
                >
                  {/* <p style={{ color: '#9D9D9D', fontSize: 13 }}>{field.asset_comps.toString().replace(/,/g, '-')}</p> */}
                  {fieldType === '单行文字' && (
                    <Input disabled style={{ width: 400 }} placeholder="单行文字" value={field.default_value} />
                  )}
                  {fieldType === '多行文字' && (
                    <Input.TextArea disabled style={{ width: 400 }} placeholder="多行文本" rows={3} />
                  )}

                  {fieldType === '单项选择' && (
                    <Radio.Group value={field.default_value} disabled>
                      {field.multiple_choices.map((x, index) => (
                        <Radio key={index} value={x.value}>
                          {x.value}
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                  {fieldType === '多项选择' && (
                    <Checkbox.Group
                      disabled
                      options={field.multiple_choices.map((x) => {
                        return {
                          label: x.value,
                          value: x.value,
                        }
                      })}
                      value={field.default_value.split(',')}
                    ></Checkbox.Group>
                  )}
                  {fieldType === '图片单选' && (
                    <Radio.Group disabled value={field.default_value}>
                      {field.multiple_choices.map((x, index) => (
                        <Radio key={index} value={x.value}>
                          <Image src={`/v2/api/file/pic/download/${x.value}`} width={58}></Image>
                        </Radio>
                      ))}
                    </Radio.Group>
                  )}
                  {fieldType === '图片多选' &&
                    field.multiple_choices.map((x, index) => {
                      return (
                        <Checkbox key="index" value={field.default_value.split(',')}>
                          <Image src={`/v2/api/file/pic/download/${x.value}`} width={58}></Image>
                        </Checkbox>
                      )
                    })}
                  {fieldType === '下拉框' && (
                    <Select style={{ width: 400 }} placeholder="下拉框" value={field.default_value}>
                      {field.multiple_choices.map((x, index) => {
                        return (
                          <Select.Option value={x.value} key={index}>
                            {x.value}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  )}
                  {fieldType === '多级下拉框' && (
                    <Cascader
                      style={{ width: 400 }}
                      placeholder="多级下拉框"
                      options={field.multi_levels.map((x) => JSON.parse(x))}
                    />
                  )}
                  {fieldType === '数字' && <InputNumber placeholder="数字" value={field.default_value} disabled />}
                  {fieldType === '日期' && (
                    <DatePicker
                      placeholder="日期"
                      disabled
                      value={moment(field.default_value)}
                      style={{ width: '100%' }}
                    />
                  )}
                  {fieldType === '时间' && (
                    <TimePicker
                      placeholder="时间"
                      disabled
                      value={moment(field.default_value, 'HH:mm:ss')}
                      style={{ width: '100%' }}
                    />
                  )}
                  {fieldType === '资产' && <Select style={{ width: 400 }} placeholder="选择资产" disabled></Select>}
                  {fieldType === '资产状态' && (
                    <Select style={{ width: 400 }} placeholder="选择资产状态" disabled></Select>
                  )}
                </Form.Item>
              </div>
            )
          })}

          <Button icon={<PlusOutlined />} type="primary" style={{ borderRadius: 0, width: '100%' }} onClick={addField}>
            添加字段
          </Button>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              margin: '16px 0',
            }}
          >
            <Button
              onClick={() => {
                setPagination({
                  ...pagination,
                  page: pagination.total + 1,
                  total: pagination.total + 1,
                })
              }}
            >
              添加新页
            </Button>
            <Pagination
              current={pagination.page}
              total={pagination.total}
              onChange={(page) => {
                setPagination({
                  ...pagination,
                  page,
                  total: pagination.total,
                })
              }}
              pageSize={1}
              showTotal={(total) => <span>共{total}页</span>}
            ></Pagination>
            <Button
              onClick={() => {
                if (pagination.total < 2) {
                  return
                }
                setFields(
                  fields
                    .filter((x) => x.page_num !== pagination.page)
                    .map((x, index) => {
                      return {
                        ...x,
                        page_num: index + 1,
                      }
                    })
                )
                setPagination({
                  page: pagination.page - 1,
                  total: pagination.total - 1,
                })
              }}
            >
              删除本页
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Button onClick={() => setCurrent(0)} type="primary" style={{ marginRight: 20 }}>
            上一步
          </Button>
          <Button
            onClick={() => {
              docreateFormTemplate()
            }}
            type="primary"
          >
            下一步
          </Button>
        </div>
      </Col>
      <Col
        span={7}
        style={{
          padding: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4>
              <div className="title-prefix"></div>
              编辑字段
            </h4>
            {currentFields.length < 1 && <Empty description="请先添加字段" />}
            {currentFields.length > 0 && currentFields[editingField] && (
              <Form layout="vertical">
                <Form.Item label="标题">
                  <Input
                    placeholder="请输入字段标题"
                    value={currentFields[editingField].title}
                    onChange={(e) => {
                      let arr = fields.map((x) => {
                        if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                          return {
                            ...x,
                            title: e.target.value,
                          }
                        } else {
                          return x
                        }
                      })
                      setFields(arr)
                    }}
                  />
                </Form.Item>
                <Form.Item label="字段说明">
                  <Input
                    placeholder="请输入字段说明"
                    value={currentFields[editingField].subtitle}
                    onChange={(e) => {
                      let arr = fields.map((x) => {
                        if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                          return {
                            ...x,
                            subtitle: e.target.value,
                          }
                        } else {
                          return x
                        }
                      })

                      setFields(arr)
                    }}
                  />
                </Form.Item>
                {currentFields[editingField].field_type === '单行文字' && (
                  <Form.Item label="默认值（非必填）">
                    <Input
                      placeholder="请输入默认值"
                      value={currentFields[editingField].default_value}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.target.value,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '多行文字' && (
                  <Form.Item label="默认值（非必填）">
                    <Input.TextArea
                      placeholder="请输入默认值"
                      value={currentFields[editingField].default_value}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.target.value,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '单项选择' && (
                  <Form.Item label="默认值（非必填）">
                    <Radio.Group
                      value={currentFields[editingField].default_value}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.target.value,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    >
                      {currentFields[editingField].multiple_choices.map((x, index) => (
                        <Radio key={index} value={x.value}>
                          {x.value}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '多项选择' && (
                  <Form.Item label="默认值（非必填）">
                    <Checkbox.Group
                      options={currentFields[editingField].multiple_choices.map((x) => {
                        return {
                          label: x.value,
                          value: x.value,
                        }
                      })}
                      value={currentFields[editingField].default_value.split(',')}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.toString(),
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    ></Checkbox.Group>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '图片单选' && (
                  <Form.Item label="默认值（非必填）">
                    <Radio.Group
                      value={currentFields[editingField].default_value}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.target.value,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    >
                      {currentFields[editingField].multiple_choices.map((x, index) => (
                        <Radio key={index} value={x.value}>
                          <Image src={`/v2/api/file/pic/download/${x.value}`} width={58}></Image>
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '图片多选' && (
                  <Form.Item label="默认值（非必填）">
                    <Checkbox.Group
                      value={currentFields[editingField].default_value.split(',')}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.toString(),
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    >
                      {currentFields[editingField].multiple_choices.map((x, index) => (
                        <Checkbox key="index" value={x.value}>
                          <Image src={`/v2/api/file/pic/download/${x.value}`} width={58}></Image>
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '下拉框' && (
                  <Form.Item label="默认值（非必填）">
                    <Select
                      style={{ width: '100%' }}
                      value={currentFields[editingField].default_value}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    >
                      {currentFields[editingField].multiple_choices.map((x, index) => {
                        return (
                          <Select.Option value={x.value} key={index}>
                            {x.value}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '多级下拉框' && (
                  <Form.Item label="默认值（非必填）">
                    <Cascader
                      style={{ width: '100%' }}
                      options={currentFields[editingField].multi_levels.map((x) => JSON.parse(x))}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.toString(),
                            }
                          } else {
                            return x
                          }
                        })
                        setFields(arr)
                      }}
                    />
                  </Form.Item>
                )}

                {currentFields[editingField].field_type === '数字' && (
                  <Form.Item label="默认值（非必填）">
                    <Input
                      placeholder="请输入默认值"
                      value={currentFields[editingField].default_value}
                      onChange={(e) => {
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: e.target.value,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '日期' && (
                  <Form.Item label="默认值（非必填）">
                    <DatePicker
                      style={{ width: '100%' }}
                      placeholder="请输入默认值"
                      value={moment(currentFields[editingField].default_value)}
                      onChange={(e) => {
                        console.log(moment(e).format('YYYY-MM-DD'))
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: moment(e).format('YYYY-MM-DD'),
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '时间' && (
                  <Form.Item label="默认值（非必填）">
                    <TimePicker
                      style={{ width: '100%' }}
                      placeholder="请输入默认值"
                      value={moment(currentFields[editingField].default_value, 'HH:mm:ss')}
                      onChange={(e) => {
                        console.log(moment('16:49:16', 'HH:mm:ss'))
                        let arr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              default_value: moment(e).format('HH:mm:ss'),
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(arr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '单项选择' && (
                  <Form.Item label="选项">
                    {currentFields[editingField].multiple_choices.map((x, option_index) => {
                      return (
                        <div
                          key={option_index}
                          style={{
                            display: 'flex',
                            marginBottom: 10,
                            alignItems: 'center',
                          }}
                        >
                          <Input
                            value={x.value}
                            onChange={(e) => {
                              let newArr = fields.map((x) => {
                                if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                                  return {
                                    ...x,
                                    multiple_choices: x.multiple_choices.map((y, y_index) => {
                                      if (y_index === option_index) {
                                        return { ...y, value: e.target.value }
                                      } else {
                                        return y
                                      }
                                    }),
                                  }
                                } else {
                                  return x
                                }
                              })

                              setFields(newArr)
                            }}
                          />
                          <MinusCircleOutlined
                            style={{
                              cursor: 'pointer',
                              fontSize: 15,
                              padding: 5,
                            }}
                            onClick={() => {
                              let newArr = fields.map((x) => {
                                if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                                  return {
                                    ...x,
                                    multiple_choices: x.multiple_choices.filter(
                                      (y, y_index) => y_index !== option_index
                                    ),
                                  }
                                } else {
                                  return x
                                }
                              })

                              if (currentFields[editingField].multiple_choices.length <= 2) {
                                message.info('选项数量不能小于2')
                                return
                              }

                              setFields(newArr)
                            }}
                          />
                        </div>
                      )
                    })}
                    <Button
                      size="small"
                      onClick={() => {
                        let newArr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            let arr = x.multiple_choices.map((y) => y)
                            arr.push({
                              type: 0,
                              value: '',
                              pic_num: 0,
                              comment: '',
                            })
                            return {
                              ...x,
                              multiple_choices: arr,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(newArr)
                      }}
                    >
                      添加
                    </Button>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '多项选择' && (
                  <Form.Item label="选项">
                    {currentFields[editingField].multiple_choices.map((x, option_index) => {
                      return (
                        <div
                          key={option_index}
                          style={{
                            display: 'flex',
                            marginBottom: 10,
                            alignItems: 'center',
                          }}
                        >
                          <Input
                            value={x.value}
                            onChange={(e) => {
                              let newArr = fields.map((x) => {
                                if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                                  return {
                                    ...x,
                                    multiple_choices: x.multiple_choices.map((y, y_index) => {
                                      if (y_index === option_index) {
                                        return { ...y, value: e.target.value }
                                      } else {
                                        return y
                                      }
                                    }),
                                  }
                                } else {
                                  return x
                                }
                              })

                              setFields(newArr)
                            }}
                          />
                          <MinusCircleOutlined
                            style={{
                              cursor: 'pointer',
                              fontSize: 15,
                              padding: 5,
                            }}
                            onClick={() => {
                              let newArr = fields.map((x) => {
                                if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                                  return {
                                    ...x,
                                    multiple_choices: x.multiple_choices.filter(
                                      (y, y_index) => y_index !== option_index
                                    ),
                                  }
                                } else {
                                  return x
                                }
                              })

                              if (currentFields[editingField].multiple_choices.length <= 2) {
                                message.info('选项数量不能小于2')
                                return
                              }

                              setFields(newArr)
                            }}
                          />
                        </div>
                      )
                    })}
                    <Button
                      size="small"
                      onClick={() => {
                        let newArr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            let arr = x.multiple_choices.map((y) => y)
                            arr.push({
                              type: 0,
                              value: '',
                              pic_num: 0,
                              comment: '',
                            })
                            return {
                              ...x,
                              multiple_choices: arr,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(newArr)
                      }}
                    >
                      添加
                    </Button>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '图片单选' && (
                  <Form.Item label="选项">
                    <PicWall
                      action={'/v2/api/file/pic/upload'}
                      onChange={(fileList) => {
                        let newArr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              multiple_choices: fileList.map((x, index) => {
                                return {
                                  value: x.response ? x.response.data : '',
                                  type: 1,
                                  comment: '',
                                  pic_num: index,
                                }
                              }),
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(newArr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '图片多选' && (
                  <Form.Item label="选项">
                    <PicWall
                      action={'/v2/api/file/pic/upload'}
                      onChange={(fileList) => {
                        let newArr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            return {
                              ...x,
                              multiple_choices: fileList.map((x, index) => {
                                return {
                                  value: x.response ? x.response.data : '',
                                  type: 1,
                                  comment: '',
                                  pic_num: index,
                                }
                              }),
                            }
                          } else {
                            return x
                          }
                        })
                        setFields(newArr)
                      }}
                    />
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '下拉框' && (
                  <Form.Item label="选项">
                    {currentFields[editingField].multiple_choices.map((x, option_index) => {
                      return (
                        <div
                          key={option_index}
                          style={{
                            display: 'flex',
                            marginBottom: 10,
                            alignItems: 'center',
                          }}
                        >
                          <Input
                            value={x.value}
                            onChange={(e) => {
                              let newArr = fields.map((x) => {
                                if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                                  return {
                                    ...x,
                                    multiple_choices: x.multiple_choices.map((y, y_index) => {
                                      if (y_index === option_index) {
                                        return { ...y, value: e.target.value }
                                      } else {
                                        return y
                                      }
                                    }),
                                  }
                                } else {
                                  return x
                                }
                              })

                              setFields(newArr)
                            }}
                          />
                          <MinusCircleOutlined
                            style={{
                              cursor: 'pointer',
                              fontSize: 15,
                              padding: 5,
                            }}
                            onClick={() => {
                              let newArr = fields.map((x) => {
                                if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                                  return {
                                    ...x,
                                    multiple_choices: x.multiple_choices.filter(
                                      (y, y_index) => y_index !== option_index
                                    ),
                                  }
                                } else {
                                  return x
                                }
                              })

                              if (currentFields[editingField].multiple_choices.length <= 2) {
                                message.info('选项数量不能小于2')
                                return
                              }

                              setFields(newArr)
                            }}
                          />
                        </div>
                      )
                    })}
                    <Button
                      size="small"
                      onClick={() => {
                        let newArr = fields.map((x) => {
                          if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                            let arr = x.multiple_choices.map((y) => y)
                            arr.push({
                              type: 0,
                              value: '',
                              pic_num: 0,
                              comment: '',
                            })
                            return {
                              ...x,
                              multiple_choices: arr,
                            }
                          } else {
                            return x
                          }
                        })

                        setFields(newArr)
                      }}
                    >
                      添加
                    </Button>
                  </Form.Item>
                )}
                {currentFields[editingField].field_type === '多级下拉框' && (
                  <EditableTree
                    onChange={(value) => {
                      let arr = fields.map((x) => {
                        if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                          return {
                            ...x,
                            multi_levels: value.map((item) => {
                              return JSON.stringify(transform(item))
                            }),
                          }
                        } else {
                          return x
                        }
                      })
                      console.log(arr)
                      setFields(arr)
                    }}
                  />
                )}
                <Form.Item label="是否必填">
                  <Switch
                    checked={!!currentFields[editingField].required}
                    onChange={(e) => {
                      let arr = fields.map((x) => {
                        if (x.page_num === pagination.page && x.field_num === editingField + 1) {
                          return {
                            ...x,
                            required: e ? 1 : 0,
                          }
                        } else {
                          return x
                        }
                      })

                      setFields(arr)
                    }}
                  />
                </Form.Item>
              </Form>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: -10,
            }}
          >
            <span>
              {editingField > 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (editingField === 0) return
                    setEditingField(editingField - 1)
                  }}
                >
                  上一字段
                </Button>
              )}
              {editingField < currentFields.length - 1 && (
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setEditingField(editingField + 1)
                  }}
                >
                  下一字段
                </Button>
              )}
              {currentFields.length > 0 && (
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    if (currentFields.length === 1) {
                      message.error('至少保留一个字段')
                      return
                    }
                    let arr = fields.filter((x) => x.page_num === pagination.page)
                    arr.splice(editingField, 1)

                    let newArr = fields
                      .filter((x) => x.page_num !== pagination.page)
                      .concat(
                        arr.map((x, index) => {
                          return {
                            ...x,
                            field_num: index + 1,
                          }
                        })
                      )
                    setFields(newArr)
                    setEditingField(0)
                  }}
                >
                  删除字段
                </Button>
              )}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default CreateTemplateStepTwo
