import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Tabs } from 'antd'
import MyFormDatas from './MyFormDatas'
import MyCooperativeForms from './MyCooperativeFormDatas'

const { TabPane } = Tabs

const FormHeaderList = (props: RouteComponentProps) => {
  const { history, location } = props
  const callback = (activeKey: any) => {
    let { search } = location
    if (search) {
      let arr = search.split('&')
      if (arr.length === 2) {
        search = `?key=${activeKey}&${arr[1]}`
      } else if (arr.length === 1) {
        search = `?key=${activeKey}&subKey=1`
      } else {
      }
    } else {
      search = `?key=${activeKey}&subKey=1`
    }
    history.push({
      pathname: `/workbench/forms-management/form-data-list`,
      search: search,
    })
  }
  return (
    <div style={{ padding: 16, backgroundColor: '#ffffff' }}>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        activeKey={location.search ? location.search.split('&')[0].split('=')[1] : '1'}
      >
        <TabPane tab="我的表单" key="1">
          <MyFormDatas />
        </TabPane>
        <TabPane tab="合作表单" key="2">
          <MyCooperativeForms />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default FormHeaderList
