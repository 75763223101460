import * as React from 'react'
import { Form, Input, Button, Layout, message, Checkbox, Row, Col, Select, Radio } from 'antd'
import * as userRequest from '@/requests/user'
import { withRouter, useHistory } from 'react-router-dom'
import './index.less'
import { encryptPwd } from '@/utils/string'
const { Content } = Layout

interface RegisterFormValues {
  email: string
  gender: string
  company: string //公司名
  password: string
  phone: string
  sm_code: string
  username: string
  user_id: string //工号
  invitation_code: string //邀请码
  confirm: string
  img_code: string
}

const RegisterPage = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [hasSendSms, setHasSendSms] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  let timer = 60
  const [phone, setPhone] = React.useState('')
  const register = async (input: RegisterReq) => {
    if (!input) return
    try {
      setFetching(true)
      const res = await userRequest.register({
        ...input,
      })
      if (res.code === 0) {
        message.success('注册成功')
        setFetching(false)
        history.replace('/login')
      } else if (res.code === 20013) {
        message.error('邀请码无效！')
      } else if (res.code === 400) {
        message.error(res.message)
      } else {
        message.info(res.message)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setFetching(false)
    }
  }

  const onFinish = (values: RegisterFormValues) => {
    const { email, gender, company, user_id, invitation_code, phone, password, username, sm_code } = values
    register({
      email,
      gender,
      company,
      user_id,
      invitation_code,
      phone,
      password: encryptPwd(password),
      username,
      sm_code,
    })
  }

  return (
    <div className="register-container">
      <Layout>
        <Content className="register-page-content">
          <div className="register-wrapper">
            <img src={process.env.PUBLIC_URL + '/img/common/logo_title_white.png'} alt="logo-header" height={28}></img>
            <div className="register-content">
              <div className="welcome">欢迎注册</div>
              <Form
                form={form}
                onFinish={onFinish}
                style={{ width: 750, margin: 'auto', display: 'flex', justifyContent: 'space-between' }}
                onValuesChange={(c_value) => {
                  if (Object.keys(c_value)[0] === 'phone') setPhone(c_value[Object.keys(c_value)[0]])
                }}
                initialValues={{ gender: 'male', agreement: true }}
              >
                <div className="left-content">
                  <Form.Item
                    name="email"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: '请输入您的电子邮箱',
                      },
                      {
                        validator: async (_, value) => {
                          if (!value) return
                          const res = await userRequest.checkEmailDuplicate({
                            email: value,
                          })
                          if (res.code === 0) {
                            return Promise.resolve()
                          } else if (res.code === 20012) {
                            return Promise.reject('该邮箱已被注册！')
                          }
                        },
                      },
                      {
                        type: 'email',
                        message: '请输入正确格式的邮箱',
                      },
                    ]}
                  >
                    <Input style={{ width: 300 }} placeholder="邮箱" />
                  </Form.Item>
                  <Form.Item style={{ display: 'flex' }}>
                    <span style={{ paddingRight: 50 }}>性别：</span>
                    <Form.Item
                      name="gender"
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: '请选择您的性别',
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={'male'}>男</Radio>
                        <Radio value={'female'}>女</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    name="company"
                    validateTrigger="onBlur"
                    rules={[{ required: true, whitespace: true, message: '请输入您的公司名称' }]}
                  >
                    <Input style={{ width: 300 }} placeholder="公司名" />
                  </Form.Item>
                  <Form.Item
                    name="user_id"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: '请输入您的工号' }]}
                  >
                    <Input style={{ width: 300 }} placeholder="工号" />
                  </Form.Item>
                  <Form.Item
                    name="invitation_code"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: '请输入邀请码！',
                      },
                      {
                        validator: async (rule, value, callback) => {
                          if (!value) return

                          const res = await userRequest.checkInvitationCode({
                            code: value,
                          })
                          if (res.code === 0) {
                            return Promise.resolve()
                          } else if (res.code === 20013) {
                            return Promise.reject('邀请码无效')
                          } else {
                            message.error(res.message)
                          }
                        },
                      },
                    ]}
                  >
                    <Input style={{ width: 300 }} placeholder="邀请码" />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 8 }}>
                    <Input.Group compact style={{ width: 300 }}>
                      <Select defaultValue="+86" style={{ width: '25%' }}>
                        <Select.Option value="+86">+86</Select.Option>
                      </Select>
                      <Form.Item
                        noStyle
                        name="phone"
                        validateTrigger="onBlur"
                        rules={[
                          { required: true, message: '请输入您的手机号码！' },
                          {
                            len: 11,
                            message: '请输入正确格式的手机号!',
                          },
                          {
                            validator: async (rule, value) => {
                              if (!value) return
                              const res = await userRequest.checkPhoneDuplicate({
                                phone: value,
                              })
                              if (res.code === 0) {
                                return Promise.resolve()
                              } else if (res.code === 20011) {
                                return Promise.reject('该手机号已被注册')
                              } else {
                                message.error(res.message)
                              }
                            },
                          },
                        ]}
                      >
                        <Input style={{ width: '75%' }} placeholder="手机号" />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <div style={{ width: 300, textAlign: 'right' }}>
                    <span>
                      已有账号？
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault()
                          history.push('/login')
                        }}
                      >
                        {'前往登录>'}
                      </a>
                    </span>
                  </div>
                </div>
                <div className="right-content">
                  <Form.Item
                    name="username"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: '请输入您的用户名！',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input style={{ width: 300 }} placeholder="用户名" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: '请输入您的密码!',
                      },
                    ]}
                  >
                    <Input.Password style={{ width: 300 }} placeholder="密码" />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    validateTrigger="onBlur"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: '请确认您的密码!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject('两次输入密码不一致!')
                        },
                      }),
                    ]}
                  >
                    <Input.Password style={{ width: 300 }} placeholder="确认密码" />
                  </Form.Item>
                  <Form.Item>
                    <Row style={{ width: 300 }} gutter={8}>
                      <Col span={10}>
                        <Form.Item
                          name="img_code"
                          validateTrigger="onBlur"
                          noStyle
                          rules={[{ required: true, message: '请输入图形验证码' }]}
                        >
                          <Input disabled={fetching} placeholder="图形验证码" />
                        </Form.Item>
                      </Col>
                      <Col span={14}>
                        {phone && phone.length === 11 && (
                          <Row
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Col>
                              <img
                                src={`/v2/api/user/register/image/code/${phone}`}
                                id="img-code"
                                alt="图形验证码"
                                height={30}
                              />
                            </Col>
                            <Col>
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const img = document.getElementById('img-code') as HTMLImageElement
                                  if (img) {
                                    img.src = `/v2/api/user/register/image/code/${phone}?code=${Math.random()}`
                                  }
                                }}
                              >
                                换一张
                              </a>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    name="sm_code"
                    validateTrigger="onBlur"
                    rules={[{ required: true, message: '请输入手机短信验证码' }]}
                  >
                    <Input
                      style={{ width: 300 }}
                      placeholder="手机验证码"
                      addonAfter={
                        <span
                          id="send-msm"
                          style={{
                            fontSize: 12,
                            fontWeight: 400,
                            color: hasSendSms ? '#A3B5C6' : '#F39800',
                            cursor: hasSendSms ? 'progress' : 'pointer',
                          }}
                          onClick={async () => {
                            if (hasSendSms) return
                            const phone = form.getFieldValue('phone')
                            const checkCode = form.getFieldValue('img_code')
                            if (!phone || !checkCode) {
                              form.validateFields(['phone', 'img_code'])
                              return
                            }

                            const res = await userRequest.getSmsCode({
                              phone,
                              checkCode,
                            })
                            if (res.code === 0) {
                              message.success('已成功向您的手机发送验证码，请注意查收!')
                              setHasSendSms(true)
                              const timers = setInterval(() => {
                                const btn = document.getElementById('send-msm') as any
                                if (!btn) {
                                  clearInterval(timers)
                                  return
                                }

                                timer -= 1
                                btn.innerText = `${timer}s重新获取`
                                // setTimer(timer - 1)
                                if (timer === 0) {
                                  setHasSendSms(false)
                                  // btn.innerText = `获取验证码`
                                  clearInterval(timers)
                                }
                              }, 1000)
                            } else if (res.code === 10020) {
                              message.error('图形验证码错误！')
                            } else {
                              message.error(res.message)
                            }
                          }}
                        >
                          {hasSendSms ? `${timer}s重新获取` : '获取验证码'}
                        </span>
                      }
                    />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: 2 }}>
                    <Button type="primary" htmlType="submit" style={{ width: 300 }} loading={fetching}>
                      {fetching ? '正在注册' : '立即注册'}
                    </Button>
                  </Form.Item>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject('请先阅读并同意《注册协议》'),
                      },
                    ]}
                  >
                    <Checkbox>
                      我已阅读并同意
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault()
                          // setVisible(true);
                        }}
                      >
                        《iMetro用户注册协议》
                      </a>
                    </Checkbox>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  )
}

export default withRouter(RegisterPage)
