import React from 'react';
import * as AssetsRequest from '@/requests/assets';
import { useCtxState, connect } from '@/App.store';
import './index.less';
import {
  Table,
  Form,
  Button,
  Input,
  message,
  Select,
  Tooltip,
  Modal,
  InputNumber,
  DatePicker,
  TreeSelect,
  Radio,
  Spin,
  Tag,
} from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  SearchOutlined,
  ShareAltOutlined,
  UpOutlined,
} from '@ant-design/icons';
import './index.less';
import DrawerAssetEditor from '@/ui-components/DrawerAssetEditor';
import * as SuggestionRequests from '@/requests/suggestion';
import * as utils from '@/utils/array';
import * as strUtils from '@/utils/string';
import { arrSet } from '@/utils/array';
import * as ConditionRequest from '@/requests/searchCondition';
import { download } from '@/utils/string';
import { useLocation } from 'react-router-dom';
moment.locale('zh-cn');

const noOp = (...args: any[]) => undefined;

function getColumnConfigs<ROW extends any>({
  onEvent = noOp,
  maxVStatusCount,
  maxCompsCount,
  maxStandardCount,
}: {
  disableEdit?: boolean;
  onEvent: Function;
  maxVStatusCount: number;
  maxCompsCount: number;
  maxStandardCount: number;
}): any[] {
  return [
    {
      title: '序号',
      align: 'center',
      key: 'no',
      render(_: any, record: StatusListItem, idx: number) {
        return idx + 1;
      },
    },

    {
      title: '资产状态名',
      align: 'center',
      key: 'status_name',
      render(_: any, record: StatusListItem) {
        if (!record.status_name) return null;
        const curName = record.status_name;
        return (
          <Tooltip title="双击进入资产编辑">
            <span key={record.status_name + record.status_uid}>{curName}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '资产状态值',
      align: 'center',
      key: 'status_value',
      render(_: any, record: StatusListItem) {
        if (!record.status_value) return null;
        const curName = record.status_value;
        return (
          <Tooltip title="双击进入资产编辑">
            <span key={record.status_value + record.status_uid}>{curName ? curName : '-'}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '状态值类型',
      align: 'center',
      key: 'value_type',
      render(_: any, record: StatusListItem) {
        if (!record.status_type) return null;
        const curName = record.status_type;
        return (
          <Tooltip title="双击进入资产编辑">
            <span key={record.status_type + record.status_uid}>{curName ? curName : '-'}</span>
          </Tooltip>
        );
      },
    },
    Array(maxStandardCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `status-standard-${idx}`;
        return {
          key,
          align: 'center',
          title: `标准${count}`,
          render(_: any, record: StatusListItem) {
            let curName = '';
            if (record.matched_standards.length < 1) {
              curName = '无';
            } else {
              const standard = record.matched_standards[idx];
              if (standard) {
                curName = `${standard.status_standard_name}：${standard.standard_value}`;
              } else {
                curName = '无';
              }
            }

            return (
              <Tooltip title="双击进入资产编辑">
                <span key={record.asset_id + 'standard' + idx}>{curName ? curName : '-'}</span>
              </Tooltip>
            );
          },
        };
      }),
    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key: string = `GeoInfo-ColChild-${idx}`;
        return {
          key,
          align: 'center',
          title: `地理组件${count}`,

          render(_: any, record: StatusListItem) {
            let curName = '';
            if (Object.entries(record.v_asset_status).length < 1) {
              curName = '';
            } else if (!Object.entries(record.v_asset_status)[idx]) {
              curName = '';
            } else {
              curName = `${Object.entries(record.v_asset_status)[idx].toString().replace(',', '：')}`;
            }
            return (
              <Tooltip title="双击进入资产编辑">
                <span key={record.toString()}>{curName ? curName : '-'}</span>
              </Tooltip>
            );
          },
        };
      }),
    {
      title: '资产名称',
      align: 'center',
      key: 'asset_name',
      render(_: any, record: StatusListItem) {
        if (!record.asset_name) return null;
        const curName = record.asset_name;
        return (
          <Tooltip title="双击进入资产编辑">
            <span key={record.asset_name + record.status_uid}>{curName ? curName : '-'}</span>
          </Tooltip>
        );
      },
    },
    ...Array(maxCompsCount)
      .fill(undefined)
      .map((_, idx) => {
        const count = idx + 1;
        const key = `AssetsComs-ColChild-${idx}`;
        return {
          key: key,
          align: 'center',
          title: `资产组件${count}`,
          render(_: any, record: StatusListItem) {
            if (!record.asset_comps) return null;
            const shouldCreateAndBind = !record.asset_comps;
            const curName = (shouldCreateAndBind ? '' : (record.asset_comps ? record.asset_comps : [])[idx]) || '';
            return (
              <Tooltip title="双击进入资产编辑">
                <span>{curName ? curName : '-'}</span>
              </Tooltip>
            );
          },
        };
      }),
    {
      title: '创建日期',
      align: 'center',
      key: 'create_time',
      render(_: any, record: StatusListItem) {
        if (!record.create_time) return null;
        const curName = record.create_time;
        return (
          <Tooltip title="双击进入资产编辑">
            <span key={record.create_time + record.status_uid}>{curName}</span>
          </Tooltip>
        );
      },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (_: string, record: ROW) => {
        if (!record) return null;
        return (
          <span>
            <span className="span-btn ant-btn-link" onClick={() => onEvent('start:update-asset', record)} key="edit">
              编辑
            </span>
          </span>
        );
      },
    },
  ].filter((x) => x);
}

const StatusList = () => {
  const [list, setList] = React.useState<StatusListItem[]>([]);
  const location = useLocation();
  const [{ userInfo }] = useCtxState();
  const { Option } = Select;

  const [visible, setVisible] = React.useState(false);
  const [excelFileName, setExcelFileName] = React.useState('资产状态信息');
  const [loadingList, setLoadingList] = React.useState(false);
  const [loadingExcel, setLoadingExcel] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [editingAssetUid, setEditingAssetUid] = React.useState<string | null>(null);
  const [editingVAssetId, setEditingVAssetId] = React.useState<string | null>(null);
  const [editingStatus, setEditingStatus] = React.useState<StatusListItem | undefined>(undefined);
  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0);
  const [maxCompsCount, setMaxCopmsCount] = React.useState(0);
  const [maxStandardCount, setMaxStandardCount] = React.useState(0);
  const [showFilterArea, setShowFilterArea] = React.useState(false);
  const [conditionPagination, setConditionPagination] = React.useState({
    page: 1,
    pageSize: 100000,
    total: 0,
  });
  const [condition, setCondition] = React.useState<ConditionItem | PublicConditionItem | undefined>(undefined);
  const [conditionList, setConditionList] = React.useState<ConditionItem[]>([]);
  const [PublicConditionList, setPublicConditionList] = React.useState<PublicConditionItem[]>([]);
  const [conditionType, setConditionType] = React.useState(0);
  const [filterConditionName, setFilterConditionName] = React.useState('');
  const [loadingCondition, setLoadingCondition] = React.useState(false);
  const conditionName = React.useRef('');
  const [conditionOpen, setConditionOpen] = React.useState(false);

  // 2020.04.12 添加高级筛选条件
  const [filter_assetName, set_filter_assetName] = React.useState<string | undefined>(undefined);
  const [filter_statusName, set_filter_statusName] = React.useState<
    { status_name: string; status_value: any }[] | null
  >(null);
  const [filter_assetComps, set_filter_assetComps] = React.useState<string[] | undefined>(undefined);
  const [filter_vAssetComps, set_filter_vAssetComps] = React.useState<{ status_name: string; status_value: string }[]>(
    []
  );
  const [filter_status_create_time, set_filter_status_create_time] = React.useState<{
    start: string;
    end: string;
    dates: any;
  } | null>(null);
  const [isAllData, setIsAllData] = React.useState(false);
  const [filter_status_standrd_uids, set_filter_status_standrd_uids] = React.useState<string[] | null>(null);
  const [filter_range_names, set_filter_range_names] = React.useState<string[] | null>(null);
  const [treeSslectValues, setTreeSslectValues] = React.useState<string[] | undefined>(undefined);
  const [statusNameOptions, setStatusNameOptions] = React.useState<StatusSuggestionItemV2[]>([]);
  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([]);
  const [vStatusValueOptions, setVStatusValueOptions] = React.useState<string[]>([]);

  const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([]);
  const [vAssetQueryOptions, setVAssetQueryOptions] = React.useState<string[]>([]);
  const [standardOptions, setStandardOptions] = React.useState<any>([]);

  React.useEffect(() => {
    if (location.pathname === '/workbench/assets-management/status-list') {
      if (location.state) {
        const state = location.state as any;
        set_filter_statusName([
          {
            status_name: state.status_name,
            status_value: state.status_value,
          },
        ]);
        doFetch(pagination.current, pagination.pageSize, state.status_name, state.status_value);
      } else {
        doFetch();
      }
    }
  }, [location]);

  const fetchConditionList = async ({ page = conditionPagination.page, pageSize = conditionPagination.pageSize }) => {
    setLoadingCondition(true);
    const res = await ConditionRequest.getMyConditionList({
      skip: (page - 1) * pageSize,
      limit: pageSize,
    });
    if (res.code === 0) {
      setConditionList(res.data);
    } else {
      message.error('网络错误');
    }
    setLoadingCondition(false);
  };
  const fetchPublicConditionList = async ({
    page = conditionPagination.page,
    pageSize = conditionPagination.pageSize,
  }) => {
    setLoadingCondition(true);
    const res = await ConditionRequest.getPublicConditionList({
      skip: (page - 1) * pageSize,
      limit: pageSize,
      ...(filterConditionName && {
        condition_name: filterConditionName,
      }),
    });
    if (res.code === 0) {
      setPublicConditionList(res.data);
    } else {
      message.error('网络错误');
    }
    setLoadingCondition(false);
  };

  const doFetch = async (page?: number, pageSize?: number, status_name?: string, status_value?: any) => {
    setLoadingList(true);
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    const res = await AssetsRequest.fetchFilteredStatusList({
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_statusName &&
        filter_statusName.length > 0 && {
          status_name: filter_statusName.map((x) => x.status_name),
        }),

      ...(filter_statusName && {
        status_value: filter_statusName.map((x) => x.status_value),
      }),
      ...(status_name && {
        status_name: [status_name],
      }),
      ...(status_value && {
        status_value: [status_value],
      }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps.length > 0 && {
        v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
      }),
      ...(filter_vAssetComps.length > 0 && {
        v_asset_status: filter_vAssetComps.map((x) => x.status_value),
      }),
      ...(filter_status_create_time && {
        start_status_create_time: filter_status_create_time.start,
        end_status_create_time: filter_status_create_time.end,
      }),
      ...(filter_range_names &&
        filter_status_standrd_uids && {
          range_names: filter_range_names,
          status_standard_uids: filter_status_standrd_uids,
        }),
      is_all_data: isAllData,
      skip: (page - 1) * pageSize,
      limit: pageSize,
    });
    if (res.code === 0) {
      const { data } = res;
      setList(data.filter((x) => !x.status_name.startsWith('!@#')));
      if (data.length > 0) {
        const vAssetStatusList = data.map((x) => {
          if (x.v_asset_status) {
            return Object.entries(x.v_asset_status).length;
          } else {
            return 0;
          }
        });
        vAssetStatusList.sort((a, b) => b - a);
        const compsList = data.map((x) => {
          if (x.asset_comps) {
            return x.asset_comps.length;
          } else {
            return 0;
          }
        });
        compsList.sort((a, b) => b - a);
        const standardList = data.map((x) => {
          if (x.matched_standards.length > 0) {
            return x.matched_standards.length;
          } else {
            return 0;
          }
        });
        standardList.sort((a, b) => b - a);
        setMaxCopmsCount(compsList[0]);
        setMaxVStatusCount(vAssetStatusList[0]);
        setMaxStandardCount(standardList[0]);
      }
      setPagination({ current: page, pageSize: pageSize, total: res.total });
    }
    setLoadingList(false);
  };

  const downloadExcel = async ({ total = pagination.total } = {}) => {
    setLoadingExcel(true);
    const res = await AssetsRequest.fetchFilteredStatusListDownload({
      ...(filter_assetName && {
        asset_name: filter_assetName,
      }),
      ...(filter_statusName &&
        filter_statusName.length > 0 && {
          status_name: filter_statusName.map((x) => x.status_name),
        }),

      ...(filter_statusName && {
        status_value: filter_statusName.map((x) => x.status_value),
      }),

      ...(filter_assetComps && { asset_comps: filter_assetComps }),
      ...(filter_vAssetComps && {
        v_asset_comps: filter_vAssetComps.map((x) => x.status_name),
      }),
      ...(filter_vAssetComps && {
        v_asset_status: filter_vAssetComps.map((x) => x.status_value),
      }),
      ...(filter_status_create_time && {
        start_status_create_time: filter_status_create_time.start,
        end_status_create_time: filter_status_create_time.end,
      }),
      ...(filter_range_names &&
        filter_status_standrd_uids && {
          range_names: filter_range_names,
          status_standard_uids: filter_status_standrd_uids,
        }),
      is_all_data: isAllData,
      skip: 0,
      limit: total,
    });
    if (res.code === 0) {
      const url = `/v2/api/asset_data/v2/advance/download/${res.data}/${excelFileName}`;

      download(url, excelFileName);
    } else {
      message.error(res.message);
    }
    setLoadingExcel(false);
    setVisible(false);
  };
  const doSaveCondition = async () => {
    if (!conditionName) {
      message.error('请输入搜索条件名');
      return;
    }
    if (condition) {
      const res = await ConditionRequest.updateConditionItem({
        asset_name: filter_assetName ? filter_assetName : '',
        condition_name: conditionName.current ? conditionName.current : '',
        asset_start_time: '',
        asset_end_time: '',
        tags: [],
        status_start_time: filter_status_create_time ? filter_status_create_time.start : '',
        status_end_time: filter_status_create_time ? filter_status_create_time.end : '',
        original: 1,
        asset_ids: [],
        asset_comps: filter_assetComps ? filter_assetComps : [],
        v_asset_comps: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : [],
        v_asset_status: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_value) : [],
        status_name: filter_statusName ? filter_statusName.map((x) => x.status_name) : [],
        status_value: filter_statusName ? filter_statusName.map((x) => x.status_value) : [],
        id: condition.id,
      });
      if (res.code === 0) {
        message.success('保存成功');
      } else {
        message.error('保存失败');
      }
    } else {
      const res = await ConditionRequest.createCondition({
        asset_name: filter_assetName ? filter_assetName : '',
        condition_name: conditionName.current ? conditionName.current : '',
        asset_start_time: '',
        asset_end_time: '',
        tags: [],
        status_start_time: filter_status_create_time ? filter_status_create_time.start : '',
        status_end_time: filter_status_create_time ? filter_status_create_time.end : '',
        original: 1,
        asset_ids: [],
        asset_comps: filter_assetComps ? filter_assetComps : [],
        v_asset_comps: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : [],
        v_asset_status: filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_value) : [],
        status_name: filter_statusName ? filter_statusName.map((x) => x.status_name) : [],
        status_value: filter_statusName ? filter_statusName.map((x) => x.status_value) : [],
      });
      if (res.code === 0) {
        message.success('保存成功');
      } else {
        message.error('保存失败');
      }
    }
  };

  const selectAfter = (
    <Select defaultValue=".csv" style={{ width: 80 }}>
      <Option value=".csv">.xlsx</Option>
    </Select>
  );

  const SaveConditionContent = () => {
    return (
      <Form>
        <Form.Item label="搜索条件名">
          <Input
            defaultValue={conditionName.current}
            placeholder="请输入搜索条件名称"
            onChange={(e) => (conditionName.current = e.target.value)}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="status-list-page">
      <div className="tools-wrapper">
        <div className="status-list-filter-input-container">
          <Form layout="inline" colon={false}>
            <Form.Item label="资产状态名" className="filter-item">
              <Select
                mode="multiple"
                showSearch
                allowClear
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                    if (filter_statusName) return;
                    const res = await SuggestionRequests.statusSuggestion();
                    if (res.code === 0) {
                      setStatusNameOptions(res.data);
                      setAssetNameOptions(Array.from(new Set<string>(res.data.map((x) => x.asset_name))));
                      setAssetCompsOptions(Array.from(new Set<string>(res.data.map((x) => x.asset_comps.toString()))));
                    }
                  }
                }}
                onChange={(values: string[]) => {
                  set_filter_statusName(
                    values.map((x) => {
                      return {
                        status_name: x,
                        status_value: '',
                      };
                    })
                  );
                }}
                style={{ minWidth: 168 }}
                value={filter_statusName ? filter_statusName.map((x) => x.status_name) : undefined}
                placeholder="全部"
                onSearch={async (value) => {
                  if (!value) return;
                  if (strUtils.isNull(value)) {
                    return;
                  }
                  const res = await SuggestionRequests.statusSuggestion(value);
                  if (res.code === 0) {
                    setStatusNameOptions(res.data);
                    setAssetNameOptions(Array.from(new Set<string>(res.data.map((x) => x.asset_name))));
                    setAssetCompsOptions(Array.from(new Set<string>(res.data.map((x) => x.asset_comps.toString()))));
                  }
                }}
                onSelect={async (value: string) => {
                  if (!value) return;
                  if (strUtils.isNull(value)) {
                    return;
                  }
                  const res = await SuggestionRequests.statusSuggestion(value);
                  if (res.code === 0) {
                    setAssetNameOptions(Array.from(new Set<string>(res.data.map((x) => x.asset_name))));
                    setAssetCompsOptions(Array.from(new Set<string>(res.data.map((x) => x.asset_comps.toString()))));
                  }
                }}
              >
                {Array.from(new Set<string>(statusNameOptions.map((x) => x.asset_status))).map((item, index) => {
                  return (
                    <Select.Option key={item + index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {filter_statusName &&
              filter_statusName.length > 0 &&
              filter_statusName.map((item) => {
                return (
                  <Form.Item
                    label={item.status_name}
                    key={'status_value input' + item.status_name}
                    className="filter-item"
                  >
                    <Input
                      placeholder={`全部`}
                      value={item.status_value}
                      onChange={(e) => {
                        let ary = filter_statusName.map((x) => x);
                        const newAry = ary.map((x) => {
                          if (x.status_name === item.status_name) {
                            x.status_value = e.target.value;
                          }
                          return x;
                        });
                        set_filter_statusName(newAry);
                      }}
                    />
                  </Form.Item>
                );
              })}
            <Button type="link" onClick={() => setShowFilterArea(!showFilterArea)}>
              {showFilterArea ? '点击收起' : '展开全部'}
              {showFilterArea ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </Form>

          <div style={{ display: showFilterArea ? 'block' : 'none' }}>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产名称" className="filter-item">
                <Select
                  style={{ minWidth: 168 }}
                  placeholder="全部"
                  onChange={(value: string) => set_filter_assetName(value)}
                  value={filter_assetName}
                >
                  {assetNameOptions.map((x: string) => {
                    return (
                      <Select.Option value={x} key={`asset_name_option:${x}`}>
                        {x}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="资产组件" className="filter-item">
                <Select
                  onChange={(values: string) => {
                    set_filter_assetComps(values.split(','));
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_assetComps ? filter_assetComps.toString() : undefined}
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (!filter_assetName) return;
                      const res = await SuggestionRequests.getAssetCompsSuggestion(filter_assetName);
                      if (res.code === 0) {
                        setAssetCompsOptions(res.data.map((x) => x.toString()));
                      } else {
                      }
                    }
                  }}
                >
                  {assetCompsOptions.map((x: string, index) => {
                    return (
                      <Select.Option value={x} key={`asset_comps_option:${index}`}>
                        {x}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="资产状态创建时间" className="filter-item">
                <DatePicker.RangePicker
                  value={filter_status_create_time ? filter_status_create_time.dates : undefined}
                  onChange={(dates, dateStrings) => {
                    if (!dates) return;
                    set_filter_status_create_time(
                      dates.length > 0
                        ? {
                            ...filter_status_create_time,
                            start: dateStrings[0] + ' 00:00:00',
                            end: dateStrings[1] + ' 00:00:00',
                            dates: dates,
                          }
                        : null
                    );
                  }}
                />
              </Form.Item>

              {/* <Form.Item label="是否包含历史值">
                <Radio.Group value={isAllData} onChange={(e) => setIsAllData(e.target.value)}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item> */}
            </Form>
            <Form layout="inline" colon={false}>
              <Form.Item label="地理信息" className="filter-item">
                <Select
                  onChange={(values: string[]) => {
                    set_filter_vAssetComps(
                      values.map((x) => {
                        const a = x.search('里程');
                        const b = x.search('轨枕号');
                        let orgValue = filter_vAssetComps.find((item) => item.status_name === x);
                        if (a === -1 && b === -1) {
                          return {
                            status_name: x,
                            status_value: orgValue ? orgValue.status_value : '',
                          };
                        } else {
                          return {
                            status_name: x,
                            status_value: orgValue ? orgValue.status_value : '0,0',
                          };
                        }
                      })
                    );
                  }}
                  style={{ minWidth: 168 }}
                  value={filter_vAssetComps ? filter_vAssetComps.map((x) => x.status_name) : undefined}
                  placeholder="全部"
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (!filter_assetName || !filter_assetComps) return;
                      const res = await SuggestionRequests.getAssetVCompsSuggestion({
                        asset_name: filter_assetName,
                        asset_comps: filter_assetComps,
                      });
                      if (res.code === 0) {
                        setVAssetQueryOptions(arrSet(res.data));
                      } else {
                      }
                    }
                  }}
                  mode="tags"
                >
                  {vAssetQueryOptions.map((item, index) => {
                    return (
                      <Select.Option key={item + index} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {filter_vAssetComps &&
                filter_vAssetComps.length > 0 &&
                filter_vAssetComps.map((item) => {
                  const a = item.status_name.search('里程');
                  const b = item.status_name.search('轨枕号');
                  if (a === -1 && b === -1) {
                    return (
                      <Form.Item
                        label={item.status_name}
                        key={'status_value input' + item.status_name}
                        className="filter-item"
                      >
                        <Select
                          style={{ minWidth: 168 }}
                          mode="tags"
                          placeholder={`全部`}
                          onDropdownVisibleChange={async (open) => {
                            if (open) {
                              const res = await SuggestionRequests.statusValueSuggestion({
                                v_status_name: item.status_name,
                              });
                              if (res.code === 0) {
                                setVStatusValueOptions(res.data.map((item) => item.v_status_value));
                              } else {
                              }
                            }
                            if (!open) {
                              setVStatusValueOptions([]);
                            }
                          }}
                          value={item.status_value === '' ? undefined : item.status_value.split(',')}
                          onChange={(values: string[]) => {
                            let ary = filter_vAssetComps.map((x) => x);
                            const newAry = ary.map((x) => {
                              if (x.status_name === item.status_name) {
                                x.status_value = values.toString();
                              }
                              return x;
                            });
                            set_filter_vAssetComps(newAry);
                          }}
                        >
                          {vStatusValueOptions.map((option) => {
                            return (
                              <Select.Option value={option} key={option}>
                                {option}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    );
                  } else {
                    const x = item.status_name.search('起始');
                    const y = item.status_name.search('迄止');
                    if (x === -1 && y === -1) {
                      return (
                        <Form.Item
                          label={item.status_name}
                          key={'status_value input' + item.status_name}
                          className="filter-item"
                        >
                          <InputNumber
                            onChange={(value: number) => {
                              if (!value) {
                                value = 0;
                              }
                              let ary = filter_vAssetComps.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.status_name === item.status_name) {
                                  const range = x.status_value.split(',');
                                  range[0] = value.toString();
                                  if (Number(range[0]) > Number(range[1])) {
                                    range[1] = value.toString();
                                  }
                                  x.status_value = range.toString();
                                }
                                return x;
                              });
                              set_filter_vAssetComps(newAry);
                            }}
                            value={Number(item.status_value.split(',')[0])}
                          />
                          ~
                          <InputNumber
                            onChange={(value: number) => {
                              if (!value) {
                                value = 0;
                              }
                              let ary = filter_vAssetComps.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.status_name === item.status_name) {
                                  const range = x.status_value.split(',');
                                  range[1] = value.toString();
                                  if (Number(range[0]) > Number(range[1])) {
                                    message.error('起始值不能大于迄止值');
                                    range[1] = range[0];
                                  }

                                  x.status_value = range.toString();
                                }
                                return x;
                              });
                              set_filter_vAssetComps(newAry);
                            }}
                            value={Number(item.status_value.split(',')[1])}
                          />
                        </Form.Item>
                      );
                    } else {
                      return (
                        <Form.Item
                          label={item.status_name}
                          key={'status_value input' + item.status_name}
                          className="filter-item"
                        >
                          <InputNumber
                            onChange={(value: number) => {
                              if (!value) {
                                value = 0;
                              }
                              let ary = filter_vAssetComps.map((x) => x);
                              const newAry = ary.map((x) => {
                                if (x.status_name === item.status_name) {
                                  x.status_value = value.toString();
                                }
                                return x;
                              });
                              set_filter_vAssetComps(newAry);
                            }}
                            value={Number(item.status_value)}
                            style={{ width: '100%', border: 'none' }}
                          />
                        </Form.Item>
                      );
                    }
                  }
                })}
            </Form>

            <Form layout="inline" colon={false}>
              <Form.Item label="资产状态标准" className="filter-item">
                <TreeSelect
                  value={treeSslectValues}
                  treeData={standardOptions}
                  treeCheckable
                  style={{ minWidth: 600 }}
                  allowClear
                  onChange={(value: string[], label, extra) => {
                    setTreeSslectValues(value);
                    set_filter_status_standrd_uids(utils.filterRepeat(value.map((x) => x.split('-')[0])));
                    set_filter_range_names(
                      utils.filterRepeat(value.map((x) => x.split('-')[0])).map((x) => {
                        const names = value.filter((val) => val.startsWith(x)).map((x) => x.split('-')[1]);
                        return names.toString();
                      })
                    );
                  }}
                  onFocus={async () => {
                    if (!filter_assetComps || !filter_assetName || !filter_statusName || filter_statusName.length < 1)
                      return;
                    const res = await SuggestionRequests.getStandardSuggestion({
                      asset_comps: filter_assetComps,
                      asset_name: filter_assetName,
                      status_name: filter_statusName.map((x) => x.status_name),
                    });
                    if (res.code === 0) {
                      if (res.data.length > 0) {
                        setStandardOptions(
                          res.data.map((standardItem, index1) => {
                            return {
                              value: standardItem.standard_name,
                              title: standardItem.standard_name,
                              key: `0-${index1}`,
                              selectable: false,
                              checkable: false,
                              children: standardItem.conditions.map((condition, index2) => {
                                return {
                                  value: condition.status_standard_uid,
                                  title: `适用地点：${condition.v_asset_status}`,
                                  key: `0-${index1}-${index2}`,
                                  selectable: true,
                                  checkable: true,
                                  children: JSON.stringify(condition.ranges)
                                    .slice(1, -1)
                                    .split('","')
                                    .map((x) => x.replace(/\"/g, ''))
                                    .map((x, index3) => {
                                      return {
                                        title: (
                                          <span>
                                            {`${standardItem.standard_name}[${x.split(':')[0]}]`}
                                            <Tooltip
                                              placement="right"
                                              title={
                                                <div>
                                                  <div>{`标准编号：${condition.status_standard_uid}`}</div>
                                                  <div>{`标准值范围：${x.split(':')[1]}`}</div>
                                                </div>
                                              }
                                            >
                                              <QuestionCircleOutlined
                                                style={{
                                                  color: '#1691ff',
                                                  fontSize: 15,
                                                }}
                                              />
                                            </Tooltip>
                                          </span>
                                        ),
                                        value: condition.status_standard_uid + '-' + x.split(':')[0],
                                        key: `0-${index1}-${index2}-${index3}`,
                                      };
                                    }),
                                };
                              }),
                            };
                          })
                        );
                      }
                    }
                  }}
                />
              </Form.Item>
            </Form>
          </div>
          <div style={{ display: showFilterArea ? 'flex' : 'none' }}>
            <Form layout="inline" colon={false}>
              <Form.Item label="搜索条件" className="filter-item">
                <Select
                  allowClear
                  style={{ minWidth: 300 }}
                  placeholder="请选择搜索条件"
                  onChange={(value: string) => {
                    if (!value) {
                      setCondition(undefined);

                      set_filter_assetName(undefined);
                      set_filter_assetComps(undefined);

                      set_filter_vAssetComps([]);
                      set_filter_status_create_time(null);
                      set_filter_statusName(null);
                      conditionName.current = '';
                      return;
                    }
                    const obj: ConditionItem | PublicConditionItem = JSON.parse(value);
                    setCondition(obj);
                    set_filter_assetName(obj.asset_name);
                    set_filter_assetComps(obj.asset_comps);

                    set_filter_vAssetComps(
                      obj.v_asset_comps.map((x, index) => {
                        return {
                          status_name: x,
                          status_value: obj.v_asset_status[index],
                        };
                      })
                    );
                    if (obj.status_name && obj.status_value) {
                      set_filter_statusName(
                        obj.status_name.map((x, index) => {
                          return {
                            status_name: x,
                            status_value: obj.status_value[index],
                          };
                        })
                      );
                    }

                    if (obj.status_start_time && obj.status_end_time) {
                      set_filter_status_create_time({
                        start: obj.status_start_time,
                        end: obj.status_end_time,
                        dates: [moment(obj.status_start_time.slice(0, 10)), moment(obj.status_end_time.slice(0, 10))],
                      });
                    }

                    conditionName.current = obj.condition_name;
                  }}
                  value={condition ? condition.condition_name : undefined}
                  onSearch={(value) => {
                    if (conditionType === 0) return;
                    setFilterConditionName(value);
                    fetchPublicConditionList({ page: 1, pageSize: 10 });
                  }}
                  dropdownRender={(menu) => (
                    <div>
                      {loadingCondition ? (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 100,
                          }}
                        >
                          <Spin spinning={loadingCondition} tip="加载中..." />
                        </div>
                      ) : (
                        menu
                      )}
                    </div>
                  )}
                  onDropdownVisibleChange={async (open) => {
                    if (open) {
                      if (conditionType === 0) {
                        fetchConditionList({ page: 1, pageSize: 10 });
                      } else {
                        fetchPublicConditionList({
                          page: 1,
                          pageSize: 10,
                        });
                      }
                    } else {
                    }
                  }}
                >
                  {conditionType === 0
                    ? conditionList.map((x, index) => {
                        return (
                          <Select.Option value={JSON.stringify(x)} key={`condition-item-${index}`}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {x.condition_name}
                              <span>
                                <Tooltip
                                  placement="bottom"
                                  title={
                                    <div>
                                      <div>
                                        <div>{`名称：${x.condition_name ? x.condition_name : '无'}`}</div>
                                        <div>{`资产名称：${x.asset_name ? x.asset_name : '无'}`}</div>
                                        <div>
                                          {`资产组件：`}
                                          {x.asset_comps && x.asset_comps.length > 0
                                            ? x.asset_comps.map((x, index) => <Tag key={`acomps-${index}`}>{x}</Tag>)
                                            : '无'}
                                        </div>
                                        <div>
                                          {`资产标签：`}{' '}
                                          {x.tags && x.tags.length > 0
                                            ? x.tags.map((x, index) => (
                                                <Tag key={`tag-${index}`}>{`${x.tag_name}:${x.tag_value}`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                        <div>
                                          {`地理组件：`}
                                          {x.v_asset_comps && x.v_asset_comps.length > 0
                                            ? x.v_asset_comps.map((v, v_index) => (
                                                <Tag key={`vcomp-${v_index}`}>{`${v}:${
                                                  x.v_asset_status[v_index] ? x.v_asset_status[v_index] : '全部'
                                                }`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      color: '#1890ff',
                                      paddingLeft: 8,
                                      cursor: 'pointer',
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="分享">
                                  <ShareAltOutlined
                                    style={{
                                      color: '#6dff18',
                                      paddingLeft: 8,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认分享‘${x.condition_name}’到公共搜索条件吗？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.shareMyConditionItem(x.id);
                                          if (res.code === 0) {
                                            message.success('分享成功');
                                          } else {
                                            message.error('分享失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="删除">
                                  <DeleteOutlined
                                    style={{
                                      color: 'red',
                                      paddingLeft: 8,
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认删除吗`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.delMyConditionItem(x.id);
                                          if (res.code === 0) {
                                            message.success('删除成功');
                                            setCondition(undefined);
                                          } else {
                                            message.error('删除失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </span>
                          </Select.Option>
                        );
                      })
                    : PublicConditionList.map((x, index) => {
                        return (
                          <Select.Option value={JSON.stringify(x)} key={`public-condition-item-${index}`}>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {x.condition_name}
                              <span>
                                <Tooltip
                                  placement="bottom"
                                  title={
                                    <div>
                                      <div>
                                        <div>{`名称：${x.condition_name ? x.condition_name : '无'}`}</div>
                                        <div>{`资产名称：${x.asset_name ? x.asset_name : '无'}`}</div>
                                        <div>
                                          {`资产组件：`}
                                          {x.asset_comps && x.asset_comps.length > 0
                                            ? x.asset_comps.map((x, index) => <Tag key={`acomps-${index}`}>{x}</Tag>)
                                            : '无'}
                                        </div>
                                        <div>
                                          {`资产标签：`}{' '}
                                          {x.tags && x.tags.length > 0
                                            ? x.tags.map((x, index) => (
                                                <Tag key={`tag-${index}`}>{`${x.tag_name}:${x.tag_value}`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                        <div>
                                          {`地理组件：`}
                                          {x.v_asset_comps && x.v_asset_comps.length > 0
                                            ? x.v_asset_comps.map((v, v_index) => (
                                                <Tag key={`vcomp-${v_index}`}>{`${v}:${
                                                  x.v_asset_status[v_index] ? x.v_asset_status[v_index] : '全部'
                                                }`}</Tag>
                                              ))
                                            : '无'}
                                        </div>
                                      </div>
                                    </div>
                                  }
                                >
                                  <InfoCircleOutlined
                                    style={{
                                      color: '#1890ff',
                                      paddingLeft: 8,
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="引入到我的搜索条件">
                                  <ImportOutlined
                                    style={{
                                      color: 'green',
                                      paddingLeft: 8,
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认导入‘${x.condition_name}’到我的搜索条件吗？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.importConditionFromPublic(x.id);
                                          if (res.code === 0) {
                                            message.success('导入成功');
                                          } else {
                                            message.error('导入失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip title="删除">
                                  <DeleteOutlined
                                    style={{
                                      color: 'red',
                                      paddingLeft: 8,
                                    }}
                                    onClick={() => {
                                      Modal.confirm({
                                        title: '提示',
                                        content: `确认删除吗？`,
                                        cancelText: '取消',
                                        okText: '确认',
                                        onCancel: () => {},
                                        onOk: async () => {
                                          const res = await ConditionRequest.delPublicConditionItem(x.id);
                                          if (res.code === 0) {
                                            message.success('删除成功');
                                            setCondition(undefined);
                                          } else {
                                            message.error('删除失败');
                                          }
                                        },
                                      });
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            </span>
                          </Select.Option>
                        );
                      })}
                </Select>
              </Form.Item>
              <Form.Item label="类型">
                <Radio.Group value={conditionType} onChange={(e) => setConditionType(e.target.value)}>
                  <Radio value={0}>我的</Radio>
                  <Radio value={1}>公共</Radio>
                </Radio.Group>
              </Form.Item>
              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: '保存搜索条件',
                    onCancel: () => {},
                    onOk: doSaveCondition,
                    okText: '确认',
                    cancelText: '取消',
                    content: <SaveConditionContent />,
                  });
                }}
              >
                保存条件
              </Button>
            </Form>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          <Button type="primary" style={{ marginRight: 10 }} onClick={() => doFetch()} icon={<SearchOutlined />}>
            查询
          </Button>
          <Button style={{ marginRight: 10 }} onClick={() => setVisible(true)} icon={<DownloadOutlined />}>
            下载
          </Button>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => {
              set_filter_assetComps(undefined);
              set_filter_vAssetComps([]);
              set_filter_assetName(undefined);
              set_filter_statusName(null);
              set_filter_range_names(null);
              set_filter_status_create_time(null);
              set_filter_status_standrd_uids(null);
              setTreeSslectValues(undefined);
            }}
            icon={<RedoOutlined />}
          >
            重置
          </Button>
        </div>
      </div>

      <Table
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        style={{ backgroundColor: '#fffffff' }}
        rowKey={(record, idx) => `status-item-${record.status_uid || {} || 'empty'}/${idx}`}
        dataSource={list}
        loading={loadingList}
        columns={getColumnConfigs<(typeof list)[any]>({
          onEvent: (evt: string, payload?: any) => {
            switch (evt) {
              case 'start:update-asset':
                setEditingAssetUid(payload.asset_id);

                setEditingStatus(payload);
                break;
              case 'create-assetcomps:success':
                doFetch();
                break;
              case 'update-asset:success':
                doFetch();
                break;
              case 'add-vasset-name:success':
                doFetch();
                break;
              case 'delete-asset:success':
                doFetch();
                break;
            }
          },
          maxVStatusCount: maxVStatusCount,
          maxCompsCount: maxCompsCount,
          maxStandardCount: maxStandardCount,
        })}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => {
              setEditingAssetUid(record.asset_id);
              record.v_asset_id && setEditingVAssetId(record.v_asset_id);
              setEditingStatus(record);
            },
          };
        }}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => doFetch(page, pageSize),
          showTotal: (total: number) => {
            return <span>{`共 ${total} 条数据`}</span>;
          },
          showQuickJumper: true,
          style: { marginRight: 16 },
        }}
        className="asset-table"
      />
      <DrawerAssetEditor
        asset_id={editingAssetUid}
        onClose={() => {
          setEditingAssetUid(null);
          setEditingStatus(undefined);
          doFetch();
        }}
        info={userInfo}
        status={editingStatus}
      ></DrawerAssetEditor>
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>导出资产状态
          </span>
        }
        cancelText="取消"
        okText={loadingExcel ? '数据生成中' : '确定'}
        okButtonProps={{ loading: loadingExcel }}
        visible={visible}
        onOk={() => downloadExcel()}
        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent>) => setVisible(false)}
      >
        <div style={{ marginBottom: 16 }}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="文件名称">
              <Input
                addonAfter={selectAfter}
                defaultValue="资产状态信息"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setExcelFileName(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default connect(StatusList);
