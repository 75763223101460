import * as React from 'react';
import {
  message,
  Modal,
  Tag,
  Button,
  Form,
  Input,
  Table,
  Divider,
  Checkbox,
  Row,
  Col,
  Spin,
  Empty,
  Switch,
} from 'antd';
import * as authRequests from '@/requests/role';
import * as UserRequests from '@/requests/user';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { formItemLayout } from '../configs';
import { RouteComponentProps } from 'react-router';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';

const UserInfoModal = ({ user, setEditingUser }: { user: UserInfoItem | undefined; setEditingUser: Function }) => {
  if (!user) return null;
  const { username, company_user_id, gender, phone, email, status } = user;
  return (
    <Modal
      title="用户信息"
      okText="确认"
      cancelText={null}
      visible={!!user}
      onOk={() => setEditingUser(undefined)}
      onCancel={() => setEditingUser(undefined)}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Form {...formItemLayout}>
        <Form.Item label="用户名">
          <Input value={username} disabled />
        </Form.Item>
        <Form.Item label="工号">
          <Input value={company_user_id} disabled />
        </Form.Item>
        <Form.Item label="性别">
          <Input value={gender === 'male' ? '男' : '女'} disabled />
        </Form.Item>
        <Form.Item label="电话">
          <Input value={phone} disabled />
        </Form.Item>
        <Form.Item label="邮箱">
          <Input value={email} disabled />
        </Form.Item>
        <Form.Item label="状态">
          <Input value={status} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const GrantRoleModal = (props: {
  user: UserInfoItem | undefined;
  setGrantUser: Function;
  grantRoleModalVisible: boolean;
  setGrantRoleModalVisible: Function;
  fetchData: Function;
  history: RouteComponentProps['history'];
}) => {
  const { user, setGrantUser, grantRoleModalVisible, setGrantRoleModalVisible, fetchData, history } = props;
  const [selectVals, setSelectVals] = React.useState<CheckboxValueType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [roles, setRoles] = React.useState<RoleItem[]>([]);

  React.useEffect(() => {
    if (user) {
      setSelectVals(user.roles.map((x) => x.id));
    }
    setLoading(true);
    fetchRoleData();
  }, [user]);
  //获取系统角色列表
  const fetchRoleData = async () => {
    const res = await authRequests.getRoleList({
      limit: 10000,
      skip: 0,
      name: '',
    });
    if (res.code === 0) {
      setRoles(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const onChange = (value: CheckboxValueType[]) => {
    setSelectVals(value);
  };
  //更新用户角色
  const updateUserRoles = async () => {
    if (!user) return;
    setFetching(true);
    const res = await UserRequests.updateUserRole({
      user_id: user.id,
      role_ids: selectVals as number[],
    });
    if (res.code === 0) {
      message.success('设置成功');
      fetchData();
      setGrantRoleModalVisible(false);
      setGrantUser(undefined);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };

  const goCreateRoles = () => {
    history.push('/base/auth-management/role');
  };
  if (!user) return null;
  return (
    <Modal
      title="分配角色"
      okText="确认"
      cancelText="取消"
      visible={grantRoleModalVisible}
      onOk={() => {
        updateUserRoles();
      }}
      onCancel={() => {
        setGrantRoleModalVisible(false);
        setGrantUser(undefined);
      }}
      okButtonProps={{ disabled: fetching, loading: fetching }}
      destroyOnClose
    >
      <div style={{ minHeight: 200 }}>
        {loading ? (
          <Spin tip="加载中" style={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : (
          <>
            {roles.length < 1 && (
              <div>
                <Empty description="暂无角色，请先创建角色">
                  <Button type="primary" onClick={goCreateRoles}>
                    去创建
                  </Button>
                </Empty>
              </div>
            )}
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange} value={selectVals}>
              <Row>
                {roles.map((x) => {
                  return (
                    <Col span={8} key={x.id} style={{ padding: 10 }}>
                      <Checkbox value={x.id}>{x.name}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </>
        )}
      </div>
    </Modal>
  );
};

const UserManage = (props: RouteComponentProps) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<UserInfoItem[]>([]);
  const [filterUser, setFilterUser] = React.useState('');
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [editingUser, setEditingUser] = React.useState<UserInfoItem>();
  const [grantRoleModalVisible, setGrantRoleModalVisible] = React.useState(false);
  const [grantUser, setGrantUser] = React.useState<UserInfoItem>();

  //获取系统用户列表
  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await UserRequests.getAllUsers({
      limit: pageSize,
      skip: pageSize * (page - 1),
      username: filterUser,
    });
    if (res.code === 0) {
      setPagination({
        ...pagination,
        total: res.total,
      });
      setData(res.data);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  //删除用户
  const deleteUser = async (id: number) => {
    const res = await UserRequests.deleteUser(id);
    if (res.code === 0) {
      message.success('操作成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  //更改用户状态
  const updateUserStatus = async (id: number, status: boolean) => {
    const res = await UserRequests.changeUserStatus({
      user_ids: [id],
      activate_user: status,
    });
    if (res.code === 0) {
      message.success('操作成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const colunms = [
    {
      title: '序号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'left',
      render: (text: any, record: UserInfoItem, index: number) => `${index + 1}`,
    },
    {
      title: '用户',
      dataIndex: 'username',
      key: 'username',
      align: 'left' as 'left',
      render: (text: any, record: UserInfoItem, index: number) => (
        <span>
          {record.gender === 'male' && (
            <ManOutlined
              style={{
                marginRight: 5,
                fontSize: 14,
                color: '#2db7f5',
              }}
            />
          )}
          {record.gender === 'female' && (
            <WomanOutlined
              style={{
                marginRight: 5,
                fontSize: 14,
                color: 'pink',
              }}
            />
          )}
          {record.username}
        </span>
      ),
    },
    {
      title: '员工编号',
      dataIndex: 'company_user_id',
      key: 'company_user_id',
      align: 'left' as 'left',
      render: (text: any, record: UserInfoItem, index: number) =>
        `${record.company_user_id ? record.company_user_id : '-'}`,
    },
    {
      title: '电话',
      dataIndex: 'phone',
      key: 'phone',
      align: 'left' as 'left',
      render: (text: any, record: UserInfoItem, index: number) => `${record.phone ? record.phone : '-'}`,
    },

    {
      title: '职位',
      dataIndex: 'role',
      key: 'role',
      align: 'left' as 'left',
      render: (text: any, record: UserInfoItem, index: number) => (
        <span>
          {record.roles.length > 0
            ? record.roles.map((x, index) => {
                return (
                  <Tag key={`${x.id}-${index}`} color="volcano">
                    {x.name}
                  </Tag>
                );
              })
            : '无'}
        </span>
      ),
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as 'left',
      render: (text: any, record: UserInfoItem, index: number) => {
        return (
          <Switch
            size="small"
            checkedChildren="启用"
            unCheckedChildren="停用"
            checked={record.status === 'active'}
            onChange={(checked) => {
              updateUserStatus(record.id, checked);
            }}
          />
        );
      },
    },

    {
      title: '操作',
      key: 'actions',
      align: 'center' as 'left',
      render: (text: any, record: UserInfoItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={(e) => {
                e.preventDefault();
                setEditingUser(record);
              }}
            >
              查看
            </a>

            <Divider type="vertical" />

            <a
              key="edit"
              onClick={(e) => {
                e.preventDefault();
                setGrantUser(record);
                setGrantRoleModalVisible(true);
              }}
            >
              赋予角色
            </a>
            <Divider type="vertical" />
            <a
              key="delete"
              onClick={(e) => {
                Modal.confirm({
                  title: '温馨提示',
                  content: '确认删除该用户吗？',
                  onOk: () => deleteUser(record.id),
                  onCancel: () => {},
                  okText: '确认',
                  cancelText: '取消',
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];
  return (
    <div className="user-manage-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ width: 200 }}
            placeholder="搜索用户"
            value={filterUser}
            onChange={(e) => setFilterUser(e.target.value)}
          ></Input.Search>
        </div>
      </div>
      <div style={{ backgroundColor: '#ffffff' }}>
        <Table
          dataSource={data}
          columns={colunms}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              fetchData(page, pagesize);
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>;
            },
            showQuickJumper: true,
            style: { marginRight: 16 },
          }}
          loading={loading}
          rowKey={(record) => record.id.toString()}
        ></Table>
      </div>
      <UserInfoModal user={editingUser} setEditingUser={setEditingUser} />
      {grantRoleModalVisible && (
        <GrantRoleModal
          user={grantUser}
          setGrantUser={setGrantUser}
          grantRoleModalVisible={grantRoleModalVisible}
          setGrantRoleModalVisible={setGrantRoleModalVisible}
          fetchData={fetchData}
          {...props}
        />
      )}
    </div>
  );
};

export default UserManage;
