import React from 'react'
import { Modal, message, Spin, TreeSelect } from 'antd'
import * as authRequests from '@/requests/role'

type SelectedAuth = string[]

interface Props {
  visible: boolean
  setVisible: Function
  fetchData: Function
  role: RoleItem | undefined
}

interface TreeNode {
  title: string
  value: number
  key: string
  children: TreeNode[]
}

const UpdateRoleAuthTable = (props: Props) => {
  const { visible, setVisible, fetchData, role } = props
  const [fetching, setFetching] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [treeNodes, setTreeNodes] = React.useState<TreeNode[]>([])
  const [selectedAuthes, setSelectedAuthes] = React.useState<SelectedAuth>([])

  const handleOk = async () => {
    if (!role) return
    doUpdate({
      role_id: role.id,
      sys_menu_ids: selectedAuthes.map((x) => Number(x)),
    })
  }

  const handleCancel = () => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }

  const handleTreeData = function (org_data: SysMenuItem[]): TreeNode[] {
    if (!org_data || org_data.length < 1) return []
    return org_data.map((x) => {
      return {
        title: x.name,
        key: x.id.toString(),
        children: handleTreeData(x.children),
        value: x.id,
      }
    })
  }

  const fetchAuthes = async () => {
    const res = await authRequests.getSysMenuTree()
    if (res.code === 0) {
      if (res.data.length > 0) {
        setTreeNodes(handleTreeData(res.data))
      }
    } else {
      message.error(res.message)
    }
    setLoading(false)
    return res.data
  }

  const fetchRoleAuthInfo = async () => {
    if (!role) return
    const res = await authRequests.getSysMenu(role.id)

    if (res.code === 0) {
      setSelectedAuthes(res.data.map((x) => x.id.toString()))
    } else {
      message.error(res.message)
    }
  }

  React.useEffect(() => {
    setLoading(true)

    fetchAuthes()
    fetchRoleAuthInfo()
  }, [])

  const doUpdate = async (input: AuthorityReq) => {
    setFetching(true)
    const res = await authRequests.updateRoleAuthes(input)
    if (res.code === 0) {
      message.success('保存成功')
      setVisible(false)
      fetchData()
    } else {
      message.error('保存失败')
    }
    setFetching(false)
  }

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>权限设置
        </span>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="保存"
      cancelText="取消"
      okButtonProps={{ loading: fetching }}
      destroyOnClose
    >
      <div style={{ minHeight: 300 }}>
        {loading ? (
          <Spin tip="加载中" spinning={loading} style={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : (
          <TreeSelect
            treeData={treeNodes}
            multiple
            onChange={(value: string[]) => {
              setSelectedAuthes(value)
            }}
            style={{ width: '100%' }}
            treeCheckable={true}
            treeDefaultExpandAll
            placeholder={'请选择权限'}
            value={selectedAuthes}
          />
        )}
      </div>
    </Modal>
  )
}

export default UpdateRoleAuthTable
