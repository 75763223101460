import * as React from 'react'
import { Button, Input, Modal, Steps, Form, Divider, Select, message, Tabs, Table, Empty, Result } from 'antd'
import DataSeriesItemForm from './CreateChartForm'
import * as BIRequest from '../../../requests/bi'
import { PlusOutlined } from '@ant-design/icons'
export enum ChartType {
  '折线图(时间线)' = '0',
  '折线图(地点线)' = '1',
  '箱线图(时间线)' = '2',
  '箱线图(地点线)' = '3',
}
export interface DataSeriesItem {
  id: number
  postData: BIReq
  res?: BIRes[]
}

export interface ChartConfigItem {
  title: string
  type: ChartType | string
  grid: string
}
const AddChartFormModal = ({
  visible,
  setVisible,
  config,
  setConfig,
  userInfo,
  fetchConfig,
}: {
  visible: boolean
  setVisible: Function
  config: BIconfigItem | null
  setConfig: Function
  userInfo: UserInfoItem
  fetchConfig: Function
}) => {
  const [currentStep, setCurrentStep] = React.useState(0)
  const [dataSeries, SetDataSeries] = React.useState<DataSeriesItem[]>([])
  const [fetching, setFetching] = React.useState(false)
  const chartTypeOptions: ['折线图(时间线)', '折线图(地点线)', '箱线图(时间线)', '箱线图(地点线)'] = [
    '折线图(时间线)',
    '折线图(地点线)',
    '箱线图(时间线)',
    '箱线图(地点线)',
  ]
  const [chartConfigs, setChartConfigs] = React.useState<ChartConfigItem>({
    type: '0',
    title: '',
    grid: '',
  })

  const formItemLayout = {
    labelCol: {
      xs: { span: 4 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
    },
  }

  const handleCancel = (e: any) => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }
  const setGrid = () => {
    if (config === null) {
      return JSON.stringify({ x: 0, y: 0 })
    } else {
      return JSON.stringify({
        x: (config.charts.length * 3) % 12,
        y: Math.ceil(config.charts.length / 4) - 1,
      })
    }
  }
  const checkInput = () => {
    if (currentStep === 1) {
      return !!!dataSeries.every((x) => x.res)
    }
    if (currentStep === 0) {
      return chartConfigs.title === '' || chartConfigs.type === ''
    }
  }
  return (
    <Modal
      visible={visible}
      afterClose={() => setCurrentStep(0)}
      destroyOnClose
      title={
        <span>
          <div className="title-prefix"></div>
          创建图表
        </span>
      }
      footer={[
        currentStep === 1 && (
          <Button onClick={() => setCurrentStep(currentStep - 1)} key="pre">
            上一步
          </Button>
        ),
        currentStep !== 2 && (
          <Button
            type="primary"
            onClick={
              currentStep === 1
                ? async () => {
                    if (config === null) {
                      const res = await BIRequest.createBIconfig({
                        user_name: userInfo.username,
                        user_id: userInfo.user_id.toString(),
                        charts: [
                          {
                            echart_config: {
                              ...chartConfigs,
                              grid: setGrid(),
                            },
                            data_series: dataSeries.map((x) => {
                              return {
                                req_data: x.postData,
                              }
                            }),
                          },
                        ],
                      })
                      if (res.code === 0) {
                        setCurrentStep(currentStep + 1)
                      } else {
                        message.error(res.message)
                      }
                    } else {
                      const res = await BIRequest.addChart({
                        user_name: userInfo.username,
                        user_id: userInfo.user_id.toString(),
                        config_id: config.config_id,
                        echart_config: { ...chartConfigs, grid: setGrid() },
                        data_series: dataSeries.map((x) => {
                          return {
                            req_data: x.postData,
                          }
                        }),
                      })
                      if (res.code === 0) {
                        setCurrentStep(currentStep + 1)
                      } else {
                        message.error(res.message)
                      }
                    }
                  }
                : () => {
                    if (chartConfigs.type === '0' || chartConfigs.type === '2') {
                      SetDataSeries([
                        {
                          id: 0,
                          postData: {
                            asset_name: '',
                            asset_comps: '',
                            asset_status: '',
                            v_asset_query: [],
                          },
                        },
                      ])
                    } else {
                      SetDataSeries([
                        {
                          id: 0,
                          postData: {
                            asset_name: '',
                            asset_comps: '',
                            asset_status: '',
                            v_asset_query: [],
                            v_asset_flag: {
                              v_status_name: '',
                              v_status_value: '',
                            },
                          },
                        },
                      ])
                    }
                    setCurrentStep(currentStep + 1)
                  }
            }
            key="next"
            disabled={checkInput()}
          >
            {'下一步'}
          </Button>
        ),
      ]}
      onCancel={handleCancel}
      style={{ minWidth: 900, minHeight: '50vh' }}
    >
      <div>
        <Steps current={currentStep} type="navigation" size="small" style={{ paddingTop: 0 }}>
          <Steps.Step title="选择图表样式" />
          <Steps.Step title="选择数据" />
          <Steps.Step title="生成图表" />
        </Steps>
        {currentStep === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              marginTop: 100,
              marginBottom: 80,
            }}
          >
            <Form {...formItemLayout} style={{ minWidth: 500 }}>
              <Form.Item label="名称">
                <Input
                  autoFocus
                  value={chartConfigs.title}
                  placeholder="请输入图表名称"
                  onChange={(e) => {
                    setChartConfigs({
                      ...chartConfigs,
                      title: e.target.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                label="视图类型"
                validateStatus={chartConfigs.type === '' ? 'error' : ''}
                help={chartConfigs.type === '' ? '请选择图表类型' : ''}
              >
                <Select
                  onChange={(value: string) =>
                    setChartConfigs({
                      ...chartConfigs,
                      type: value,
                    })
                  }
                  defaultActiveFirstOption
                  defaultValue="折线图(时间线)"
                >
                  {chartTypeOptions.map(
                    (x: '折线图(时间线)' | '折线图(地点线)' | '箱线图(时间线)' | '箱线图(地点线)') => {
                      return (
                        <Select.Option value={ChartType[x]} key={`chart_type_option:${x}`}>
                          {x}
                        </Select.Option>
                      )
                    }
                  )}
                </Select>
              </Form.Item>
            </Form>
          </div>
        )}
        {currentStep === 1 && (
          <div>
            <Tabs
              defaultActiveKey="1"
              tabBarExtraContent={
                <>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      let data = dataSeries.map((x) => x)
                      if (chartConfigs.type === '0' || chartConfigs.type === '2') {
                        console.log('时间线')
                        data.push({
                          id: data.length,
                          postData: {
                            asset_name: '',
                            asset_comps: '',
                            asset_status: '',
                            v_asset_query: [],
                          },
                        })
                      } else {
                        console.log('地点线')
                        data.push({
                          id: data.length,
                          postData: {
                            asset_name: '',
                            asset_comps: '',
                            asset_status: '',
                            v_asset_query: [],
                            v_asset_flag: {
                              v_status_name: '',
                              v_status_value: '',
                            },
                          },
                        })
                      }

                      SetDataSeries(data)
                    }}
                  >
                    添加
                  </Button>
                </>
              }
            >
              {dataSeries.map((item, index) => {
                return (
                  <Tabs.TabPane tab={`数据系列${index + 1}`} key={`tab_key:${index}`}>
                    <DataSeriesItemForm
                      data={item}
                      index={index}
                      dataSeries={dataSeries}
                      SetDataSeries={SetDataSeries}
                      chartConfigs={chartConfigs}
                      key={`dataSeries:${index + item.id}`}
                      setFeching={setFetching}
                    />
                    <Divider>数据预览</Divider>
                    {item.res ? (
                      <Table
                        style={{ maxHeight: 200, overflow: 'scroll' }}
                        size="small"
                        loading={fetching}
                        bordered={false}
                        dataSource={item.res}
                        pagination={false}
                        columns={[
                          {
                            align: 'center' as 'center',
                            dataIndex: 'asset_status_name',
                            key: 'asset_status_name',
                            title: '资产状态名',
                          },
                          {
                            align: 'center' as 'center',
                            dataIndex: 'asset_status_value',
                            key: 'asset_status_value',
                            title: '资产状态值',
                          },

                          {
                            align: 'center' as 'center',
                            dataIndex: item.postData.v_asset_flag ? 'v_asset_status_value' : 'time',
                            key: 'v_asset_status_value',
                            title: item.postData.v_asset_flag ? item.postData.v_asset_flag.v_status_name : '时间',
                          },
                        ]}
                      />
                    ) : (
                      <Empty />
                    )}
                  </Tabs.TabPane>
                )
              })}
            </Tabs>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <Result
              status="success"
              title="生成图表成功！"
              subTitle="点击“完成，退出。"
              extra={[
                <Button
                  type="primary"
                  key="finish"
                  onClick={() => {
                    message.success('添加图表成功！')
                    setVisible(false)
                    fetchConfig()
                  }}
                >
                  完成
                </Button>,
              ]}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default AddChartFormModal
