import * as React from 'react';
import { Button, Modal, Tabs, Form, Input, Select, message, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import MyProjectList from '../components/MyProjectList';
import ProjectDeleted from '../components/ProjectDeleted';
import ProjectArchive from '../components/ProjectArchive';
import ProjectCollect from '../components/ProjectCollect';
import { createLabelProject } from '@/requests/labelProject';
import SinglePicUpload from '@/ui-components/SinglePicUpload';

const { TabPane } = Tabs;

interface CreateLabelProjectFormItem {
  project_name: string;
  project_remark?: string;

  label_type?: number;
  line_type: string;
  label_tag_list?: string[];
  cover?: any;
}

const LabelProjectList = () => {
  const [createVisible, setCreateVisible] = React.useState(false);

  const [createForm] = Form.useForm();
  const [fetching, setFetching] = React.useState(false);
  const projectListRef = React.useRef<any>(null);

  const doCreate = async (values: CreateLabelProjectReq) => {
    setFetching(true);
    const res = await createLabelProject(values);
    if (res.code === 0) {
      message.success('创建成功');
      if (projectListRef.current) {
        projectListRef.current.fetchData();
      }

      setCreateVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const handleCreateOk = (values: CreateLabelProjectFormItem) => {
    console.log(values);
    doCreate({
      project_name: values.project_name,
      label_type: values.label_type,
      label_tag_list: values.label_tag_list
        ? values.label_tag_list.map((x) => {
            return {
              key: x,
              value: x,
            };
          })
        : [],
      line_type: values.line_type,
      project_remark: values.project_remark ? values.project_remark : '',
      ...(values.cover &&
        values.cover[0].status === 'done' && {
          project_thumbnail_url: values.cover[0].response.data,
        }),
    });
  };

  const handleCreateCancel = () => {
    setCreateVisible(false);
  };

  const handleCreateClick = () => {
    setCreateVisible(true);
  };

  return (
    <div style={{ backgroundColor: '#ffffff', padding: 16 }}>
      <Tabs
        defaultActiveKey="1"
        onChange={() => {}}
        tabBarGutter={32}
        destroyInactiveTabPane
        tabBarExtraContent={
          <Button type="primary" onClick={handleCreateClick} icon={<PlusOutlined />}>
            创建新项目
          </Button>
        }
      >
        <TabPane tab="全部项目" key="1">
          <MyProjectList ref={projectListRef} />
        </TabPane>
        <TabPane tab="我的收藏" key="2">
          <ProjectCollect ref={projectListRef} />
        </TabPane>
        <TabPane tab="已归档" key="3">
          <ProjectArchive />
        </TabPane>
        <TabPane tab="回收站" key="4">
          <ProjectDeleted />
        </TabPane>
      </Tabs>
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>创建项目
          </span>
        }
        visible={createVisible}
        footer={null}
        onCancel={handleCreateCancel}
        destroyOnClose
      >
        <Form
          form={createForm}
          onFinish={handleCreateOk}
          preserve={false}
          labelCol={{
            md: 5,
            xl: 5,
            sm: 5,
          }}
        >
          <Form.Item
            label="项目名称"
            name="project_name"
            rules={[
              {
                required: true,
                message: '请输入项目名称',
              },
            ]}
          >
            <Input placeholder="请输入项目名称" />
          </Form.Item>

          <Form.Item label="项目描述" name="project_remark">
            <Input.TextArea placeholder="项目描述" />
          </Form.Item>
          <Form.Item label="项目封面 " name="cover" extra="最佳图片比例为300*150">
            <SinglePicUpload action="/v2/api/file/pic/upload" />
          </Form.Item>
          <Form.Item
            label="标注类型"
            name={'label_type'}
            initialValue={1}
            rules={[
              {
                required: true,
                message: '请选择标注类型',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={1}>多段线标记</Radio>
              <Radio value={2}>多边形标记</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="标注类型"
            name={'line_type'}
            initialValue={'直线'}
            rules={[
              {
                required: true,
                message: '请选择线条类型',
              },
            ]}
          >
            <Radio.Group>
              <Radio value={'直线'}>直线</Radio>
              <Radio value={'曲线'}>曲线</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="标注标签" name={'label_tag_list'}>
            <Select mode="tags" placeholder="输入或选择标签" />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 36,
                padding: '0 100px',
              }}
            >
              <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                确认
              </Button>
              <Button onClick={handleCreateCancel} style={{ padding: '0 36px' }}>
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default LabelProjectList;
