import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { message, Modal, Button, Input, Empty, Tooltip, Divider, Table } from 'antd'
import * as CoordinateRequests from '@/requests/location'
import '../styles/index.less'
import CreateCoordinateModal from '../components/CreateCoordinateModal'
import UpdateCoordinateModal from '../components/UpdateCoordinateModal'
import { PlusOutlined } from '@ant-design/icons'

const CoordinateList = (props: RouteComponentProps) => {
  const [data, setData] = React.useState<CoordinateItem[]>([])
  const [loading, setLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  })

  const [createCoordinateModalVisible, setCreateCoordinateModalVisible] = React.useState(false)

  const [editingCoordinate, setEditingCoordinate] = React.useState<CoordinateItem | undefined>(undefined)

  const [updateCoordinateModalVisible, setUpdateCoordinateVisible] = React.useState(false)

  const [maxVStatusCount, setMaxVStatusCount] = React.useState(0)

  const fetchData = async (page?: number, pageSize?: number) => {
    setLoading(true)

    if (!page) page = 1
    if (!pageSize) pageSize = 10
    const res = await CoordinateRequests.queryCoordinateList({
      limit: pageSize,
      skip: pageSize * (page - 1),
    })
    if (res.code === 0) {
      const vLengthList = res.data.map((x) => x.location_coordinate_addrs.length)
      vLengthList.sort((a, b) => b - a)

      setMaxVStatusCount(vLengthList[0])
      setPagination({
        ...pagination,
        page,
        pageSize,
        total: res.total,
      })
      setData(res.data)
    } else {
      setData([])
      message.error(res.message)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  const colunms = [
    {
      title: '序号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => `${index + 1}`,
    },
    {
      title: '地理标签',
      dataIndex: 'location_name',
      key: 'location_name',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => `${record.location_name}`,
    },
    {
      title: 'x坐标',
      dataIndex: 'coordinate_x',
      key: 'coordinate_x',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => `${record.coordinate_x}`,
    },
    {
      title: 'y坐标',
      dataIndex: 'coordinate_y',
      key: 'coordinate_y',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => `${record.coordinate_y}`,
    },
    {
      title: 'z坐标',
      dataIndex: 'coordinate_z',
      key: 'coordinate_z',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => `${record.coordinate_z}`,
    },

    ...Array(maxVStatusCount)
      .fill(undefined)
      .map((vcomp, index) => {
        return {
          title: `地理组件${index + 1}`,
          key: `vcomps-${index}`,
          align: 'center' as 'center',
          render: (_: any, record: CoordinateItem, _index: number) => {
            const { location_coordinate_addrs } = record
            let value = location_coordinate_addrs[index]
            return value ? `${value.addr_comp_name}:${value.addr_comp_value}` : ''
          },
        }
      }),
    {
      title: '坐标编号',
      dataIndex: 'location_coordinate_uid',
      key: 'location_coordinate_uid',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => `${record.location_coordinate_uid}`,
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center' as 'center',
      render: (text: any, record: CoordinateItem, index: number) => {
        return (
          <span>
            <Tooltip title="编辑">
              <a
                key="edit"
                onClick={(e) => {
                  e.preventDefault()
                  setEditingCoordinate(record)
                  setUpdateCoordinateVisible(true)
                }}
              >
                编辑
              </a>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="删除">
              <a
                type="delete"
                key="delete"
                onClick={(e) => {
                  e.preventDefault()
                  Modal.confirm({
                    title: '提示',
                    content: '确认删除该地理坐标吗？',
                    okText: '确认',
                    onOk: async () => {
                      const { location_coordinate_uid } = record
                      const res = await CoordinateRequests.deleteCoordinate({
                        location_coordinate_uid,
                      })
                      if (res.code === 0) {
                        message.success('删除成功！')
                        fetchData()
                      } else {
                        message.error(res.message)
                      }
                    },
                    cancelText: '取消',
                    onCancel: () => {},
                  })
                }}
              >
                删除
              </a>
            </Tooltip>
          </span>
        )
      },
    },
  ]
  return (
    <div className="coordinate-list-page">
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200, border: 'none' }}
            placeholder="搜索地理坐标"
          ></Input.Search>
        </div>

        <Button type="primary" icon={<PlusOutlined />} onClick={() => setCreateCoordinateModalVisible(true)}>
          新建地理坐标
        </Button>
      </div>
      <div style={{ backgroundColor: '#ffffff' }}>
        <Table
          scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
          dataSource={data}
          columns={colunms}
          pagination={{
            ...pagination,
            onChange: (page, pagesize) => {
              setPagination({ ...pagination, page: page })
              fetchData(page, pagesize)
            },
            showTotal: (total: number) => {
              return <span>{`共 ${total} 条数据`}</span>
            },
            showQuickJumper: true,
            style: { marginRight: 16 },
          }}
          locale={{
            emptyText: (
              <Empty description="暂无地理坐标">
                <Button type="primary" onClick={() => setCreateCoordinateModalVisible(true)}>
                  创建地理坐标
                </Button>
              </Empty>
            ),
          }}
          loading={loading}
          rowKey={(record) => record.location_coordinate_uid.toString()}
        ></Table>
      </div>
      {createCoordinateModalVisible && (
        <CreateCoordinateModal
          visible={createCoordinateModalVisible}
          setVisible={setCreateCoordinateModalVisible}
          fetchData={fetchData}
        />
      )}
      {updateCoordinateModalVisible && editingCoordinate && (
        <UpdateCoordinateModal
          visible={updateCoordinateModalVisible}
          setVisible={setUpdateCoordinateVisible}
          data={editingCoordinate}
          fetchData={fetchData}
        />
      )}
    </div>
  )
}

export default CoordinateList
