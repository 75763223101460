import * as React from 'react'
import { Result, Button } from 'antd'
import { withRouter, useHistory } from 'react-router-dom'

const BadRequest = () => {
  const history = useHistory()
  return (
    <Result
      status="500"
      title="500"
      subTitle="服务端错误！请稍后再试！"
      extra={
        <div>
          <Button type="primary" onClick={() => history.push('/')}>
            回到主页
          </Button>
          <Button type="primary" onClick={() => history.goBack()} style={{ marginLeft: 10 }}>
            重试
          </Button>
        </div>
      }
    />
  )
}

export default withRouter(BadRequest)
