import * as React from 'react'
import { Collapse, Tag, Input, Select, message, Button, Table, Modal, Badge, Form } from 'antd'
import { CloseCircleFilled, CloseCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import EditableControl from '@/ui-components/EditableControl'
import * as SuggestionRequests from '@/requests/suggestion'
import * as StandardRequest from '@/requests/standard'
import EditableCell from '@/ui-components/EditableCell'
import * as Utils from '@/utils/string'
import './index.less'

const emptyTemplateData = {
  asset_comps: [] as string[],
  asset_name: '',
  asset_status_name: '',
  status_value_type: '',
  virtual_asset_comps: null,
  virtual_asset_name: '',
  standards: [] as Standard[],
  levels: [] as string[],
  create_time: '',
  form_id: '',
  id: 0,
  is_matched: false,
  task_id: '',
  update_time: '',
  user_id: '',
  user_name: '',
  standard_names: [],
  standard_condition_uid: '',
}

const CreateStandardTemplate = ({
  standard,
  setStandardToCopy,
  setTemplateVisible,
  userInfo,
  doFetch,
}: {
  standard?: Standards | undefined
  setStandardToCopy: Function
  setTemplateVisible: Function
  userInfo: any
  doFetch: Function
}) => {
  const [data, setData] = React.useState<Standards>(standard ? standard : emptyTemplateData)

  const [assetNameOptions, setAssetNameOptions] = React.useState<string[]>([])

  const [addStandardModalVisible, setAddStandardModalVisible] = React.useState(false)
  const [addLevelModalVisible, setAddLevelModalVisible] = React.useState(false)
  const [addCompsModalVisible, setAddCompsModalVisible] = React.useState(false)
  const [addVCompsModalVisible, setAddVCompsModalVisible] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)

  const formatTableData = (levels: string[], standards: Standard[]) => {
    return levels.map((x, index) => {
      let obj = { level: x } as any
      standards.map((standard) => {
        obj[standard.standard_name] = standard.ranges[index].range
        obj['key'] = standard.ranges[index].range_name + index
      })
      return obj
    })
  }

  const AddStandardForm = () => {
    const [name, setName] = React.useState('')
    const handleOk = () => {
      const standards = data.standards.map((x) => x)
      const ranges = data.levels.map((x) => {
        return {
          range_name: x,
          range: '',
        }
      })
      standards.push({
        ...({} as any),
        standard_name: name,
        ranges: ranges,
      })
      setData({ ...data, standards: standards })
      setAddStandardModalVisible(false)
    }
    const handleCancel = () => {
      setAddStandardModalVisible(false)
    }
    return (
      <Modal
        title="添加标准"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={addStandardModalVisible}
        okText="确认"
        cancelText="取消"
      >
        <Form>
          <Form.Item label="标准名称">
            <Input placeholder="请输入标准名称" value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }
  const AddlevelForm = () => {
    const [name, setName] = React.useState('')
    const handleOk = () => {
      const levels = data.levels.map((x) => x)
      levels.push(name)
      const standards = data.standards.map((x) => {
        const ranges = x.ranges.map((y) => y)
        ranges.push({
          range_name: name,
          range: '',
        } as any)
        return {
          ...x,
          ranges: ranges,
        }
      })

      setData({ ...data, levels: levels, standards: standards })
      setAddLevelModalVisible(false)
    }
    const handleCancel = () => {
      setAddLevelModalVisible(false)
    }
    return (
      <Modal
        title="添加等级"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={addLevelModalVisible}
        okText="确认"
        cancelText="取消"
      >
        <Form>
          <Form.Item label="等级名称">
            <Input placeholder="请输入等级名称" value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
        </Form>
      </Modal>
    )
  }

  const AddCompsForm = () => {
    const [comps, setComps] = React.useState(data.asset_comps)
    const [assetCompsOptions, setAssetCompsOptions] = React.useState<string[]>([])
    const handleOk = () => {
      setData({ ...data, asset_comps: comps })
      setAddCompsModalVisible(false)
    }
    const handleCancel = () => {
      setAddCompsModalVisible(false)
    }
    return (
      <Modal
        title="添加资产组件"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={addCompsModalVisible}
        okText="确认"
        cancelText="取消"
      >
        <Form>
          <Form.Item label="适用的资产组件">
            <Select
              onDropdownVisibleChange={async (open) => {
                const assetName = data.asset_name
                if (open) {
                  if (!assetName) {
                    return
                  }
                  const res = await SuggestionRequests.getAssetCompsSuggestion(assetName)
                  if (res.code === 0) {
                    let options: string[] = []
                    res.data.map((x) => (options = options.concat(x)))
                    const CompsSet = new Set<string>(options)
                    options = Array.from(CompsSet)
                    console.log(options, CompsSet)
                    setAssetCompsOptions(options)
                  } else {
                    message.error('网络错误')
                  }
                }
              }}
              mode="tags"
              value={comps}
              onChange={(option: string[]) => {
                setComps(option)
              }}
            >
              {assetCompsOptions.map((x: string) => {
                return (
                  <Select.Option value={x} key={`asset_comps_option:${x}`}>
                    {x}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    )
  }
  const AddVCompsForm = () => {
    const [vcomps, setVcomps] = React.useState(data.virtual_asset_comps)
    const [vassetCompsOptions, setVassetCompsOptions] = React.useState<string[]>([])
    const handleOk = () => {
      setData({ ...data, virtual_asset_comps: vcomps })
      setAddVCompsModalVisible(false)
    }
    const handleCancel = () => {
      setAddVCompsModalVisible(false)
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
      },
    }
    const getComps = () => {
      const keys = Object.keys(vcomps)
      return keys
    }

    return (
      <Modal
        title="添加地点信息"
        onOk={handleOk}
        onCancel={handleCancel}
        visible={addVCompsModalVisible}
        okText="确认"
        cancelText="取消"
      >
        <Form {...formItemLayout}>
          <Form.Item label="地点信息">
            <Select
              mode="tags"
              onDropdownVisibleChange={async (open) => {
                const assetName = data.asset_name
                const asset_comps = data.asset_comps
                if (open) {
                  if (!assetName) {
                    return
                  }
                  const res = await SuggestionRequests.getAssetVCompsSuggestion({
                    asset_name: assetName,
                    asset_comps: asset_comps,
                  })
                  if (res.code === 0) {
                    let options: string[] = []
                    res.data.map((x) => (options = options.concat(x)))
                    const CompsSet = new Set<string>(options)
                    options = Array.from(CompsSet)
                    console.log(options, CompsSet)
                    setVassetCompsOptions(options)
                  } else {
                    message.error('网络错误')
                  }
                }
              }}
              placeholder="请选择地点信息"
              value={Object.keys(vcomps).length > 0 ? getComps() : undefined}
              onChange={(option: string[]) => {
                let comps = JSON.parse(JSON.stringify(vcomps))
                let realcomps = {} as any
                console.log(option)
                option.map((item) => {
                  if (!comps[item]) {
                    realcomps[item] = ''
                  } else {
                    realcomps[item] = comps[item]
                  }
                })
                setVcomps(realcomps)
              }}
            >
              {vassetCompsOptions.map((item, _index) => {
                return (
                  <Select.Option key={item.toString() + _index} value={item}>
                    {item}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
          {getComps().map((item, _index) => {
            return (
              <Form.Item label={item} key={item + _index}>
                <Input
                  value={vcomps[item] ? vcomps[item] : undefined}
                  onChange={(e) => {
                    let comps = JSON.parse(JSON.stringify(vcomps))
                    comps[item] = e.target.value
                    setVcomps(comps)
                  }}
                />
              </Form.Item>
            )
          })}
        </Form>
      </Modal>
    )
  }

  return (
    <div
      style={{
        border: '1px solid #1691ff',
        width: '100%',
        borderRadius: 4,
        marginBottom: 20,
        padding: 16,
      }}
      className="template-item-container"
    >
      <AddStandardForm />
      <AddlevelForm />
      <AddCompsForm />
      <AddVCompsForm />
      <div className="item-header" style={{ display: 'flex', height: '100%', marginBottom: 15 }}>
        <div className="status-name">
          <EditableCell
            child={data.asset_status_name ? data.asset_status_name : '未编辑'}
            OnFinishEditing={(value: string) => {
              setData({ ...data, asset_status_name: value })
            }}
          />
        </div>
        <div className="standard-col1">
          <div className="standard-col2">
            <div className="key">适用的资产名</div>
            <div className="value">
              <Select
                showSearch
                onSearch={async (value) => {
                  if (Utils.isNull(value)) {
                    return
                  }
                  if (value) {
                    const res = await SuggestionRequests.getAssetNameSuggestion(value)
                    if (res.code === 0) {
                      setAssetNameOptions(res.data)
                    } else {
                      message.error('网络错误')
                    }
                  } else {
                    return
                  }
                }}
                size="small"
                value={data.asset_name ? data.asset_name : undefined}
                placeholder="输入适用的资产名称"
                onChange={(value: string) => {
                  setData({ ...data, asset_name: value })
                }}
                style={{ minWidth: 150 }}
              >
                {assetNameOptions.map((x: string) => {
                  return (
                    <Select.Option value={x.toString()} key={`asset_name_option:${x}`}>
                      {x}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className="standard-col1">
          <div className="standard-col2">
            <div className="key">值类型</div>
            <div className="value">
              <Select
                size="small"
                value={data.status_value_type ? data.status_value_type : undefined}
                placeholder={'请选择值类型'}
                style={{ minWidth: 150 }}
                onChange={(value: string) => setData({ ...data, status_value_type: value })}
              >
                {['数值', '文本'].map((item) => {
                  return (
                    <Select.Option value={item} key={`status_value_type:${item}`}>
                      {item}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
        </div>
        <div className="standard-col1">
          <div className="standard-col2">
            <div className="key">适用地点</div>
            <div className="value">
              {JSON.stringify(data.virtual_asset_comps).length > 2
                ? Object.entries(data.virtual_asset_comps).map((x) => (
                    <Tag key={x.toString()}>{`${x[0]}：${x[1]}`}</Tag>
                  ))
                : null}
              <Tag color="blue" style={{ cursor: 'pointer' }} onClick={() => setAddVCompsModalVisible(true)}>
                <PlusOutlined type="plus" />
              </Tag>
            </div>
          </div>
        </div>
        <div className="standard-col1">
          <div className="standard-col2">
            <div className="key">适用的资产组件</div>
            <div className="value">
              {data.asset_comps.map((x) => {
                return (
                  <Tag
                    color="blue"
                    key={x}
                    closable
                    onClose={(e: any) => {
                      const asset_comps = data.asset_comps.map((comp) => comp)
                      const index = asset_comps.indexOf(x)
                      asset_comps.splice(index, 1)
                      setData({ ...data, asset_comps: asset_comps })
                    }}
                  >
                    {x}
                  </Tag>
                )
              })}
              <Tag color="blue" style={{ cursor: 'pointer' }} onClick={() => setAddCompsModalVisible(true)}>
                <PlusOutlined type="plus" />
              </Tag>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative', backgroundColor: '#ffffff' }}>
        <Table
          style={{
            paddingBottom: 30,
            marginRight: 140,
            paddingLeft: 140,
          }}
          dataSource={formatTableData(data.levels, data.standards)}
          size="middle"
          pagination={false}
          bordered={false}
          columns={[
            {
              title: '等级' as any,
              align: 'left' as 'left',
              key: 'level',

              render(_: any, record: any, idx: number) {
                return (
                  <div key={idx + record.level} style={{ display: 'flex', alignItems: 'center' }}>
                    <EditableCell
                      child={record.level}
                      OnFinishEditing={(value: string) => {
                        const levels = data.levels.map((level) => {
                          if (level === record.level) {
                            return value
                          }
                          return level
                        })
                        const standards = data.standards.map((standard) => {
                          const ranges = {} as Standardrange
                          levels.map((level: string, index) => {
                            ranges.range = standard.ranges[index].range
                          })
                          return { ...standard, ranges: ranges }
                        })
                        setData({
                          ...data,
                          levels: levels,
                        })
                      }}
                    />
                    <DeleteOutlined
                      type="delete"
                      style={{
                        color: '#1691ff',
                        marginLeft: 5,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const levels = data.levels.map((x) => x)
                        const index = levels.indexOf(record.level)
                        levels.splice(index, 1)
                        const standards = data.standards.map((standard) => {})
                        setData({
                          ...data,
                          levels: levels,
                        })
                      }}
                    />
                  </div>
                )
              },
            },
          ]
            .concat(
              data.standards.map((x, _index) => {
                return {
                  title: (
                    <Badge
                      count={
                        <CloseCircleFilled
                          style={{ fontSize: 18, color: 'red' }}
                          onClick={() => {
                            const standards = data.standards.map((x) => x)
                            standards.splice(_index, 1)
                            setData({ ...data, standards: standards })
                          }}
                        />
                      }
                      style={{ cursor: 'pointer' }}
                      title="删除"
                    >
                      <Tag color="blue">{x.standard_name}</Tag>
                    </Badge>
                  ),
                  align: 'center' as 'left',

                  key: x.standard_name + _index,
                  render(_: any, record: any, idx: number) {
                    return (
                      <div key={idx + record[x.standard_name]}>
                        <EditableCell
                          child={record[x.standard_name] ? record[x.standard_name] : '-'}
                          OnFinishEditing={(value: string) => {
                            const standards = data.standards.map((standard) => {
                              if (standard.standard_name === x.standard_name) {
                                const ranges = JSON.parse(JSON.stringify(standard.ranges))
                                ranges[record.level] = value
                                return {
                                  ...standard,
                                  ranges: ranges,
                                }
                              }
                              return standard
                            })

                            setData({ ...data, standards: standards })
                          }}
                        />
                      </div>
                    )
                  },
                }
              })
            )
            .concat({
              title: (
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setAddStandardModalVisible(true)
                  }}
                >
                  添加标准
                </Button>
              ),
              align: 'center' as 'left',
              key: 'add',

              render(_: any, record: any, idx: number) {
                return <div key={idx + '-'}>-</div>
              },
            })}
        />
        <Button
          style={{
            marginLeft: 140,
          }}
          icon={<PlusOutlined />}
          onClick={() => {
            setAddLevelModalVisible(true)
          }}
        >
          添加等级
        </Button>

        <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
          <Button
            type="primary"
            style={{ marginLeft: 20 }}
            loading={fetching}
            onClick={async () => {
              setFetching(true)
              const res = await StandardRequest.createStandardByForm({
                ...({} as any),
                asset_status_name: data.asset_status_name,
                status_value_type: data.status_value_type,
                levels: data.levels,
                standards: data.standards,
                asset_name: data.asset_name,
                asset_comps: data.asset_comps,
                virtual_asset_comps: data.virtual_asset_comps,
                virtual_asset_name: '',
                form_id: 'not form',
                task_id: `create standard copy from ${data.standard_condition_uid}`,
                user_id: String(userInfo.user_id),
                user_name: userInfo.username,
              })
              if (res.code === 0) {
                message.success('创建成功')
                setStandardToCopy(null)
                setTemplateVisible(false)
                doFetch()
              } else {
                message.error(res.message)
              }
              setFetching(false)
            }}
          >
            创建
          </Button>

          <Button
            style={{ marginLeft: 10 }}
            onClick={() => {
              setStandardToCopy(null)
              setTemplateVisible(false)
            }}
          >
            取消
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CreateStandardTemplate
