import * as React from 'react'
import { Form, Input, Button } from 'antd'
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment'
import { ClearOutlined } from '@ant-design/icons'

interface PropsType {
  setCurrent: Function
  template: ShareFormTemplateItem
  formHeadData: CreateFormHeadReq
  setFormHeadData: Function
  userInfo: UserInfoItem
  setSignatureImageDataUrl: Function
  signatureImageDataUrl: string
}

const formItemLayout = {
  labelCol: {
    xs: { span: 6 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

const CreateFormHeadStepOne = (props: PropsType) => {
  const { setCurrent, template, userInfo, setSignatureImageDataUrl, signatureImageDataUrl } = props
  const SignatureCanvasRef = React.useRef(null) as any

  return (
    <div
      style={{
        width: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h2>{template.form_name}</h2>
      <div style={{ padding: 10 }}>
        <span style={{ paddingRight: 20 }}>文本编号：{template.version_name}</span>
        <span>版本号：{template.version_number}</span>
      </div>
      <div style={{ width: '100%' }}>
        <Form {...formItemLayout}>
          <Form.Item label="记录人">
            <Input value={userInfo.username} disabled />
          </Form.Item>
          <Form.Item label="记录人ID">
            <Input value={userInfo.user_id} disabled />
          </Form.Item>
          <Form.Item label="记录人签名">
            <SignatureCanvas
              penColor="blue"
              canvasProps={{
                width: 400,
                height: 200,
                className: 'sigCanvas',
              }}
              backgroundColor="#f0f0f0"
              onEnd={(e) => {
                setSignatureImageDataUrl(SignatureCanvasRef.current.toDataURL())
              }}
              ref={SignatureCanvasRef}
            />
            <Button
              style={{ position: 'absolute', right: 0 }}
              onClick={() => SignatureCanvasRef.current.clear()}
              icon={<ClearOutlined />}
            >
              清除
            </Button>
          </Form.Item>
          <Form.Item label="记录日期">{moment().format('YYYY-MM-DD')}</Form.Item>
          <Form.Item label=" " colon={false}>
            <Button
              type="primary"
              onClick={() => {
                setCurrent(1)
              }}
              disabled={signatureImageDataUrl ? false : true}
              style={{ padding: '0 36px' }}
            >
              下一步
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ width: '100%' }}></div>
    </div>
  )
}
export default CreateFormHeadStepOne
