import * as React from 'react';
import {
  Table,
  Image,
  Input,
  Button,
  Modal,
  Upload,
  message,
  Divider,
  Progress,
  List,
  Avatar,
  Form,
  DatePicker,
  Select,
  Dropdown,
  Menu,
  Breadcrumb,
  Checkbox,
} from 'antd';
import {
  deleteLabelTask,
  getLabelProjectByUid,
  downloadLabelResult,
  getTasksByCondition,
  updateLabelTask,
} from '@/requests/labelProject';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PlusOutlined,
  InboxOutlined,
  EditOutlined,
  UserAddOutlined,
  SearchOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import '../styles/taskList.less';
import type { UploadProps } from 'antd/es/upload/interface';
import SetLabelPersonModal from '../components/LabelPersonModal';
import MemberModal from '../components/MemberModal';
import { getAllUsers } from '@/requests/user';
import moment from 'moment';
import Axios from 'axios';
import querystring from 'querystring';
import CopyPorjectModal from '../components/CopyProjectModal';

const { Dragger } = Upload;
interface Member {
  user_id: UserInfoItem['id'];
  avatar: UserInfoItem['avatar'];
  user_name: string;
  email: string;
  company_user_id: string;
}

const SetLabelPersonBatchModal = ({
  project,
  visible,
  setVisible,
  setUser,
}: {
  project: LabelProjectListItem;
  visible: boolean;
  setVisible: Function;
  setUser: Function;
}) => {
  const [members, setMembers] = React.useState<Member[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [filterName, setFilterName] = React.useState('');

  const searchUser = async () => {
    setLoading(true);
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: filterName,
    });
    const memRes = project.participant_user_list;

    if (res.code === 0) {
      if (res.data.length < 1) {
        setMembers([]);
      } else {
        setMembers(
          memRes.map((x) => {
            const match = res.data.find((y) => y.id === x.id) as any;
            return {
              user_id: match.id,
              avatar: match.avatar,
              user_name: match.username,
              email: match.email,
              company_user_id: match.company_user_id,
            };
          })
        );
      }
    }
    setLoading(false);
  };

  const handleCnacel = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    searchUser();
  }, []);
  if (!project) return null;
  return (
    <Modal
      visible={visible}
      onCancel={handleCnacel}
      footer={null}
      title="设置标注人"
      bodyStyle={{ padding: 16, height: 450, overflow: 'scroll' }}
      destroyOnClose={true}
    >
      <div>
        <div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="输入昵称或邮箱查找"
            onChange={(e) => setFilterName(e.target.value)}
            onPressEnter={() => {
              searchUser();
            }}
            value={filterName}
          />
        </div>
        <div style={{ marginTop: 16 }}>
          <List
            dataSource={members}
            locale={{
              emptyText: '没有搜索到相关成员',
            }}
            loading={loading}
            renderItem={(item) => (
              <List.Item
                extra={
                  <Button
                    onClick={() => {
                      setUser(item);
                      setVisible(false);
                    }}
                  >
                    设置
                  </Button>
                }
              >
                <List.Item.Meta
                  avatar={
                    item.avatar ? (
                      <Avatar src={`v2/api/user/avatar/${item.avatar}`} />
                    ) : (
                      <Avatar>{item.user_name}</Avatar>
                    )
                  }
                  title={`${item.user_name}(工号：${item.company_user_id})`}
                  description={item.email}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

const DownloadModal = ({
  visible,
  setVisible,
  project,
}: {
  visible: boolean;
  setVisible: Function;
  project: LabelProjectListItem;
}) => {
  const [loading, setLoading] = React.useState(false);
  const [fileName, setFilname] = React.useState('标注结果');
  const [form] = Form.useForm();
  const [members, setMembers] = React.useState<Member[]>([]);
  const searchUser = async () => {
    const res = await getAllUsers({
      limit: 1000,
      skip: 0,
      username: '',
    });
    const memRes = project.participant_user_list;
    if (res.code === 0) {
      setMembers(
        memRes.map((x) => {
          const match = res.data.find((y) => y.id === x.id) as any;
          return {
            user_id: match.id,
            avatar: match.avatar,
            user_name: match.username,
            email: match.email,
            company_user_id: match.company_user_id,
          };
        })
      );
    }
  };
  const doDownload = async (values: any) => {
    if (!project) return;

    try {
      setLoading(true);
      const res = await downloadLabelResult({
        project_uid: project.project_uid,
        ...(values.task_uid && {
          task_uid: values.task_uid,
        }),
        ...(values.user_ids && {
          user_ids: values.user_ids,
        }),
        ...(values.image_name && {
          image_name: values.image_name,
        }),
        ...(values.task_start_time && {
          task_start_time: moment(values.task_start_time).format('YYYY-MM-DD HH:MM:SS'),
        }),
        ...(values.task_end_time && {
          task_end_time: moment(values.task_end_time).format('YYYY-MM-DD HH:MM:SS'),
        }),
      });

      let link = document.createElement('a');
      const body = document.querySelector('body');
      link.download = `${fileName}.json`;
      link.href = 'data:text/plain,' + JSON.stringify(res);
      if (body !== null) {
        link.style.display = 'none';
        body.appendChild(link);

        link.click();
        body.removeChild(link);
      }
      setVisible(false);
      setLoading(false);
    } catch (e) {
      message.error(e as any);
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleOk = (values: any) => {
    console.log(values);
    doDownload(values);
  };
  return (
    <Modal visible={visible} title={'导出标注数据'} destroyOnClose={true} footer={null} onCancel={handleCancel}>
      <Form.Item label="文件名称">
        <Input
          value={fileName}
          onChange={(e) => {
            setFilname(e.target.value);
          }}
          addonAfter=".json"
        />
      </Form.Item>
      <Divider>筛选条件</Divider>
      <Form
        form={form}
        onFinish={handleOk}
        preserve={false}
        labelCol={{
          md: 4,
          xl: 4,
          sm: 4,
        }}
      >
        <Form.Item name={'task_uid'} label={'任务uid'}>
          <Input />
        </Form.Item>
        <Form.Item name={'user_ids'} label={'执行人'}>
          <Select
            mode="multiple"
            onDropdownVisibleChange={(open) => {
              if (open) {
                searchUser();
              }
            }}
          >
            {members.map((x) => {
              return (
                <Select.Option value={x.user_id} key={x.user_id}>
                  <span>
                    {x.avatar ? <Avatar src={`v2/api/user/avatar/${x.avatar}`} /> : <Avatar>{x.user_name}</Avatar>}{' '}
                    <span style={{ paddingLeft: 8 }}>{x.user_name}</span>
                  </span>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name={'task_start_time'} label={'开始时间'}>
          <DatePicker showTime style={{ width: '100%' }}></DatePicker>
        </Form.Item>
        <Form.Item name={'task_end_time'} label={'结束时间'}>
          <DatePicker showTime style={{ width: '100%' }}></DatePicker>
        </Form.Item>
        <Form.Item name={'iamge_name'} label={'图片名称'}>
          <Input />
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={loading}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const LabelTaskList = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = React.useState<LabelTaskItem[]>([]);
  const [project, setProject] = React.useState<LabelProjectListItem>();
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [uploadVisible, setUploadVisible] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showProgress, setShowProgress] = React.useState(false);
  const [setLabelPersonVisible, setSetLabelPersonVisible] = React.useState(false);
  const [editTask, setEditTask] = React.useState<LabelTaskItem>();
  const [memberVisible, setMemberVisible] = React.useState(false);
  const [principal_user, setPrincipal_user] = React.useState<Member>();
  const [visible1, setVisible1] = React.useState(false);
  const [downloadVisible, setDownloadVisible] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [filterName, setFilterName] = React.useState('');

  const [copyVisible, setCopyVisible] = React.useState(false);
  const handleCopyClick = () => {
    setCopyVisible(true);
  };

  const uploadRequest = Axios.create({
    baseURL: `/v2/api/`,
    paramsSerializer: (params) => querystring.stringify(params),
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    onUploadProgress: (progressEvent) => {
      let _percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      setPercent(_percent);
    },
  });

  uploadRequest.interceptors.response.use(
    function (response) {
      const { data } = response;
      if (data.code === 20001) {
        message.error('请登录');
        setTimeout(() => window.location.replace(`/#/login`), 500);
      }
      if (data.code === 20006) {
        Modal.warning({
          title: '风险提示',
          okText: '我知道了',
          content: '您的账号已在另一设备登录，如果这不是您本人操作，您的密码可能已泄露，请及时修改密码！',
          onOk: () => {
            setTimeout(() => window.location.replace(`/#/login`), 500);
          },
          onCancel: () => {},
          cancelText: null,
        });
      }
      if (data.code === 200) {
        setPercent(100);
      }
      return response.data as any;
    },
    function (error) {
      let res = error.response;
      if (res) {
        let { status, data } = res;

        return {
          code: status,
          message: data.message,
        } as any;
      }
      return Promise.reject(error);
    }
  );

  /**
   * 根据项目uid创建任务
   * @param  {FormData} formData
   */
  const createLabelTask = async (formData: FormData): Promise<AssetsResourceProto<{ data: null }>> => {
    return uploadRequest({
      method: 'POST',
      url: `/labelTask/new`,
      data: formData,
    }) as any;
  };

  const fetchData = async (page?: number, pageSize?: number, project_uid?: string) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    if (!project_uid) {
      if (location.search) {
        project_uid = location.search.split('=')[1];
      } else {
        if (project) {
          project_uid = project.project_uid;
        } else {
          project_uid = '';
        }
      }
    }
    setLoading(true);

    const res = await getTasksByCondition({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      project_uid: project_uid,
      ...(filterName && {
        image_name: filterName,
      }),
    });
    if (res.code === 0) {
      setData(res.data);
      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const doDelete = async (id: string) => {
    const res = await deleteLabelTask(id);
    if (res.code === 0) {
      message.success('删除成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  const fetchProject = async (uid: LabelProjectListItem['project_uid']) => {
    const res = await getLabelProjectByUid(uid);
    if (res.code === 0) {
      setProject(res.data);
    } else {
      message.error(res.message);
    }
  };

  //取消上传
  const handleUploadCancel = () => {
    setFileList([]);
    setPrincipal_user(undefined);
    setUploadVisible(false);
  };
  //上传图片并创建任务
  const handleUploadOk = async () => {
    if (!project) return;
    // if (fileList.length > 9) {
    //   message.error('最多上传9张图片');
    //   return;
    // }
    setUploading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('multipartFiles', file as any);
    });

    formData.append('projectUid', project.project_uid);
    if (principal_user) {
      formData.append('principalUserId', principal_user.user_id as any);
    }
    setShowProgress(true);

    const res = await createLabelTask(formData);
    if (res.code === 0) {
      message.success('任务创建成功');
      setFileList([]);
      setPrincipal_user(undefined);
      setUploadVisible(false);
      fetchData();
    } else {
      message.error(res.message);
    }
    setShowProgress(false);
    setUploading(false);
    setPercent(0);

    console.log(fileList);
  };

  //更新任务的状态
  const updateLabelTaskStatus = async (task_uid: string, status: number) => {
    const res = await updateLabelTask({
      task_uid: task_uid,
      label_status: status,
    });
    if (res.code === 0) {
      message.success('更新成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any, fileList) => {
      setFileList(fileList);

      return false;
    },
    fileList,
  };

  const handleMemberClick = () => {
    if (!project) return;

    setMemberVisible(true);
  };

  React.useEffect(() => {
    if (location.pathname === '/workbench/label/project-list/task-list') {
      if (location.state) {
        const { project } = location.state as any;
        setProject(project);
      } else if (location.search) {
        fetchProject(location.search.split('=')[1]);
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (location.pathname === '/workbench/label/project-list/task-list') {
      fetchData();
    }
  }, [location]);

  const columns = [
    {
      title: '状态',
      align: 'center' as 'center',
      key: 'status',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return (
          <Checkbox
            onChange={(e) => {
              updateLabelTaskStatus(record.task_uid, e.target.checked === true ? 1 : 0);
            }}
            checked={record.label_status === 1}
          >
            {record.label_status === 1 ? '已完成' : '未完成'}
          </Checkbox>
        );
      },
    },
    {
      title: '图片名',
      align: 'center' as 'center',
      key: 'fileName',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.image_name;
      },
    },

    {
      title: '标注人',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.principal_user ? (
          <span>
            {record.principal_user.username}{' '}
            <a
              onClick={(e) => {
                e.preventDefault();
                setEditTask(record);
                setSetLabelPersonVisible(true);
              }}
            >
              <EditOutlined></EditOutlined>
            </a>
          </span>
        ) : (
          <a
            onClick={(e) => {
              e.preventDefault();
              setEditTask(record);
              setSetLabelPersonVisible(true);
            }}
          >
            设置标注人
          </a>
        );
      },
    },
    {
      title: '创建时间',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.create_time;
      },
    },
    {
      title: '最后标注时间',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.end_time;
      },
    },
    {
      title: '上传人',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.create_user.username;
      },
    },

    {
      title: '图像',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        // return <Image src={`/v2/api/pic/picture/download/${24}/${record.image_uid}?type=1`} width={24} height={24} />;
        return <Image src={record.image_url} width={24} height={24} />;
      },
    },
    {
      title: '已标记数',
      align: 'center' as 'center',
      key: 'order',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return record.label_num;
      },
    },
    {
      title: '操作',
      align: 'center' as 'center',
      key: 'actions',
      render: (value: any, record: LabelTaskItem, index: number) => {
        return (
          <span>
            {project && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/workbench/label/label-task/label-page',

                    search: `task_uid=${record.task_uid}&project_uid=${project.project_uid}`,
                  });
                }}
              >
                进入标注
              </a>
            )}

            <Divider type="vertical"></Divider>
            <a
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: '提示',
                  content: '确认删除该任务吗？',
                  okText: '确认',
                  cancelText: '取消',
                  onOk: () => doDelete(record.task_uid),
                  onCancel: () => {},
                });
              }}
            >
              删除
            </a>
          </span>
        );
      },
    },
  ];

  if (!project) return null;
  return (
    <div className="label-task-list-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a
            onClick={(e) => {
              e.preventDefault();
              history.push({ pathname: '/workbench/label/project-list' });
            }}
          >
            标注项目
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{project.project_name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="tools-wrapper">
        <div className="filter-item">
          <Input.Search
            onSearch={() => fetchData()}
            style={{ maxWidth: 200 }}
            placeholder="输入图片名搜索任务"
            onChange={(e) => {
              setFilterName(e.target.value);
            }}
          ></Input.Search>
        </div>

        <span>
          <Button
            icon={<ExportOutlined />}
            style={{ marginRight: 16 }}
            onClick={() => {
              setDownloadVisible(true);
            }}
          >
            导出标注结果
          </Button>
          <Button
            icon={<UserAddOutlined />}
            style={{ marginRight: 16 }}
            onClick={() => {
              handleMemberClick();
            }}
          >
            编辑成员
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setUploadVisible(true);
                  }}
                >
                  上传图片创建
                </Menu.Item>
                <Menu.Item onClick={handleCopyClick}>从已有项目导入</Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button type="primary" icon={<PlusOutlined />}>
              新建任务
            </Button>
          </Dropdown>
        </span>
      </div>

      <Table
        style={{ marginTop: 8 }}
        dataSource={data}
        columns={columns}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => fetchData(page, pageSize),
          showTotal: (total) => <span>共 {total} 条数据</span>,
        }}
        loading={loading}
      ></Table>
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>创建标注任务{' '}
            {principal_user ? (
              <span style={{ fontSize: 14, color: '#646464' }}>
                标注人：{principal_user.user_name}{' '}
                <EditOutlined
                  style={{ color: '#f39800' }}
                  onClick={() => {
                    setVisible1(true);
                  }}
                />
              </span>
            ) : (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setVisible1(true);
                }}
              >
                <span style={{ fontSize: 14 }}>设置标注人</span>
              </a>
            )}
          </span>
        }
        visible={uploadVisible}
        onCancel={handleUploadCancel}
        okButtonProps={{ loading: uploading, onClick: handleUploadOk, disabled: fileList.length === 0 }}
        okText={'创建'}
        cancelButtonProps={{ disabled: uploading }}
        destroyOnClose
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击或将文件拖动至此处上传，支持批量上传，最多9张图片</p>
          <p className="ant-upload-hint">Click or drag file to this area to upload,multinomial,Up to nine sheets</p>
          {showProgress && (
            <div>
              <Progress percent={percent} />
              {percent === 100 && <span>上传完成，正在创建任务，请耐心等候</span>}
            </div>
          )}
        </Dragger>
        {visible1 && (
          <SetLabelPersonBatchModal
            visible={visible1}
            setVisible={setVisible1}
            project={project}
            setUser={setPrincipal_user}
          />
        )}
      </Modal>
      {setLabelPersonVisible && (
        <SetLabelPersonModal
          task={editTask}
          setTask={setEditTask}
          fetchData={fetchData}
          visible={setLabelPersonVisible}
          setVisible={setSetLabelPersonVisible}
          project={project}
        />
      )}

      {project && memberVisible && (
        <MemberModal visible={memberVisible} setVisible={setMemberVisible} project={project} setProject={setProject} />
      )}
      {project && <DownloadModal visible={downloadVisible} setVisible={setDownloadVisible} project={project} />}
      {copyVisible && (
        <CopyPorjectModal
          visible={copyVisible}
          setVisible={setCopyVisible}
          editingPorject={project}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default LabelTaskList;
