import * as React from 'react';
import { Upload, message, Modal, Badge, Image } from 'antd';
import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import * as PicRequests from '@/requests/pictures';
const PicWidget = ({
  asset,
  fetchAssetData,
  uploadAssetPic,
}: {
  asset: AssetItem;
  fetchAssetData: Function;
  uploadAssetPic: Function;
}) => {
  const [visible, setVisible] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {asset.asset_pics.length !== 0
        ? asset.asset_pics.map((x, index) => {
            return (
              <div style={{ padding: 5 }} key={x.uid}>
                <Badge
                  count={
                    <CloseCircleFilled
                      style={{ color: '#f5222d' }}
                      onClick={() => {
                        Modal.confirm({
                          title: '提示',
                          content: '确认删除该图片吗？',
                          okText: '确认',
                          cancelText: '取消',
                          onOk: async () => {
                            const res = await PicRequests.deletePic(x.uid);
                            if (res.code === 0) {
                              message.success('删除成功');
                              fetchAssetData();
                            } else {
                              message.error(res.code);
                            }
                          },
                        });
                      }}
                    />
                  }
                >
                  <Image
                    src={`/v2/api/pic/picture/download/50/${x.uid}`}
                    style={{ height: 50, width: 50 }}
                    preview={{ visible: false }}
                    onClick={() => {
                      setVisible(true);
                      setCurrent(index);
                    }}
                  />
                </Badge>
              </div>
            );
          })
        : null}
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis), current: current }}>
          {asset.asset_pics.length !== 0
            ? asset.asset_pics.map((x, index) => {
                return <Image src={`/v2/api/pic/picture/download/50/${x.uid}`} key={x.uid} />;
              })
            : null}
        </Image.PreviewGroup>
      </div>
      <Upload
        multiple={true}
        showUploadList={false}
        beforeUpload={(file, fileList) => {
          const checkType =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/jpg' ||
            file.type === 'image/tiff' ||
            file.type === 'image/bmp' ||
            file.type === 'image/gif';
          if (!checkType) {
            message.error('只支持上传 JPG/PNG/JEPG/TIFF/BMP/GIF 格式的图片!');
            return false;
          }
          const checkSize = file.size / 1024 / 1024 < 10;
          if (!checkSize) {
            message.error('图片大小不能大于10MB!');
            return false;
          }
          uploadAssetPic(file);
          return false;
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            border: '1px dashed #d9d9d9',
            margin: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#fafafa',
            cursor: 'pointer',
          }}
        >
          <PlusOutlined style={{ fontSize: 32, color: '#999' }} />
        </div>
      </Upload>
    </div>
  );
};

export default PicWidget;
