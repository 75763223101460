import * as React from 'react';
import { Form, Input, Button, Layout, message, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import * as userRequest from '@/requests/user';
import { withRouter, useHistory } from 'react-router-dom';
import { encryptPwd } from '@/utils/string';

import './index.less';

interface FormValues {
  user_name: string;
  password: string;
  remember: boolean;
}

const LoginPage = () => {
  const [fetching, setFetching] = React.useState(false);
  const [form] = Form.useForm();

  const { Content } = Layout;
  const history = useHistory();

  React.useEffect(() => {
    const accountStr = localStorage.getItem('account');
    if (accountStr) {
      const account = JSON.parse(accountStr);
      form.setFieldsValue({
        user_name: account.user_name,
        password: account.password,
      });
    }
  });

  const doLogin = async (value: { username: string; password: string }) => {
    if (!value) return;
    try {
      setFetching(true);
      const res = await userRequest.login({
        username: value.username,
        password: encryptPwd(value.password),
      });
      if (res.code === 20000) {
        //根据当前时间提示上午好，下午好，晚上好
        const now = new Date();
        const hour = now.getHours();
        if (hour < 6) {
          message.success(`凌晨好！${res.data.username}`);
        } else if (hour < 9) {
          message.success(`早上好！${res.data.username}`);
        } else if (hour < 12) {
          message.success(`早上好！${res.data.username}`);
        } else if (hour < 14) {
          message.success(`中午好！${res.data.username}`);
        } else if (hour < 17) {
          message.success(`下午好！${res.data.username}`);
        } else if (hour < 19) {
          message.success(`下午好！${res.data.username}`);
        } else if (hour < 22) {
          message.success(`晚上好！${res.data.username}`);
        } else {
          message.success(`晚上好！${res.data.username}`);
        }
        setFetching(false);
        history.replace('/');
      } else if (res.code === 20004) {
        message.error('用户名不存在！');
      } else if (res.code === 20002) {
        message.error('密码错误！');
      } else {
        message.error('网络错误！');
      }
      setFetching(false);
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const onFinish = (values: FormValues) => {
    const { remember } = values;
    if (remember) {
      const account = form.getFieldsValue(['user_name', 'password']);
      localStorage.setItem('account', JSON.stringify(account));
    } else {
      localStorage.removeItem('account');
    }
    doLogin({
      username: values.user_name,
      password: values.password,
    });
  };

  return (
    <div className="login-container">
      <Layout>
        <Content className="login-page-content">
          <div className="login-content">
            <div className="left-content">
              {/* <img
                src={'https://imetro-web-imgges.oss-cn-beijing.aliyuncs.com/client-images/login_left_bg.png'}
                style={{ marginRight: 8 }}
              ></img>
              <img
                src={process.env.PUBLIC_URL + '/img/common/logo_title_white.png'}
                alt="logo-header"
                height={28}
                style={{ position: 'absolute', left: 16, top: 16 }}
              ></img> */}
              <img
                src={'https://imetro-web-imgges.oss-cn-beijing.aliyuncs.com/client-images/login_left_bg_with_logo.png'}
                style={{ marginRight: 8 }}
              ></img>
            </div>
            <div className="right-content">
              <img
                src={process.env.PUBLIC_URL + '/img/common/login_right_bg.png'}
                style={{ position: 'absolute', right: 0 }}
                height={500}
              ></img>
              <div className="form-container">
                <div className="title">迈创优管</div>

                {/* <div className="subtitle">资产管理系统</div> */}
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  form={form}
                  style={{ width: 300, margin: 'auto' }}
                >
                  <div className="welcome">欢迎登陆</div>
                  <Form.Item name="user_name" rules={[{ required: true, message: '请输入您的用户名或手机号' }]}>
                    <Input
                      prefix={<UserOutlined style={{ color: '#2D3843' }} />}
                      placeholder="手机号/邮箱"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入您的密码' }]}
                    style={{ marginBottom: 8 }}
                  >
                    <Input
                      prefix={<LockOutlined style={{ color: '#2D3843' }} />}
                      type="password"
                      placeholder="密码"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 8 }}>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox style={{ color: '#646464' }}>记住密码</Checkbox>
                    </Form.Item>
                    <a
                      href="/"
                      style={{ float: 'right' }}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push('/reset-password');
                      }}
                    >
                      忘记密码?
                    </a>
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0, marginTop: 40 }}>
                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={fetching}>
                      {fetching ? '正在登录' : '登录'}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <div>
                      <span>
                        还没有账号？
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            history.replace('/register');
                          }}
                        >
                          {'立即注册>'}
                        </a>
                      </span>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default withRouter(LoginPage);
