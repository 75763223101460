import * as React from 'react';
import { Button, Modal, Tabs, Form, Input, Select, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import MyProjectList from '../components/MyProjectList';
import ProjectDeleted from '../components/ProjectDeleted';
import ProjectArchive from '../components/ProjectArchive';
import ProjectCollect from '../components/ProjectCollect';
import { createProject } from '@/requests/project';

const { TabPane } = Tabs;

const ProjectList = () => {
  const [createVisible, setCreateVisible] = React.useState(false);

  const [createForm] = Form.useForm();
  const [fetching, setFetching] = React.useState(false);
  const projectListRef = React.useRef<any>(null);

  const doCreate = async (values: CreateProjectReq) => {
    setFetching(true);
    const res = await createProject(values);
    if (res.code === 0) {
      message.success('创建成功');
      if (projectListRef.current) {
        projectListRef.current.fetchData();
      }

      setCreateVisible(false);
    } else {
      message.error(res.message);
    }
    setFetching(false);
  };
  const handleCreateOk = (values: CreateProjectReq) => {
    console.log(values);
    doCreate({
      project_name: values.project_name,
      template_code: values.template_code ? values.template_code : '0',
      description: values.description ? values.description : '',
    });
  };

  const handleCreateCancel = () => {
    setCreateVisible(false);
  };

  const handleCreateClick = () => {
    setCreateVisible(true);
  };
  return (
    <div style={{ backgroundColor: '#ffffff', padding: 16 }}>
      <Tabs
        defaultActiveKey="1"
        onChange={() => {}}
        tabBarGutter={32}
        destroyInactiveTabPane
        tabBarExtraContent={
          <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateClick}>
            创建新项目
          </Button>
        }
      >
        <TabPane tab="全部项目" key="1">
          <MyProjectList ref={projectListRef} />
        </TabPane>
        <TabPane tab="我的收藏" key="2">
          <ProjectCollect ref={projectListRef} />
        </TabPane>
        <TabPane tab="已归档" key="3">
          <ProjectArchive />
        </TabPane>
        <TabPane tab="回收站" key="4">
          <ProjectDeleted />
        </TabPane>
      </Tabs>
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>创建任务
          </span>
        }
        visible={createVisible}
        footer={null}
        onCancel={handleCreateCancel}
        destroyOnClose
      >
        <Form
          form={createForm}
          onFinish={handleCreateOk}
          preserve={false}
          labelCol={{
            md: 5,
            xl: 5,
            sm: 5,
          }}
        >
          <Form.Item
            label="项目名称"
            name="project_name"
            rules={[
              {
                required: true,
                message: '请输入项目名称',
              },
            ]}
          >
            <Input placeholder="请输入项目名称" />
          </Form.Item>
          <Form.Item label="项目模板" name="template_code">
            <Select placeholder="项目模板" />
          </Form.Item>
          <Form.Item label="项目描述" name="description">
            <Input.TextArea placeholder="项目描述" />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: 36,
                padding: '0 100px',
              }}
            >
              <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
                确认
              </Button>
              <Button onClick={handleCreateCancel} style={{ padding: '0 36px' }}>
                取消
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectList;
