import Axios from "axios";
import querystring from "querystring";

const singleton = Axios.create({
  // baseURL: `${process.env.REACT_APP_IMERTO_HTTP_BASE}`,
  baseURL: `/`,
  paramsSerializer: (params) => querystring.stringify(params),
  headers: {
    "Content-Type":
      "multipart/form-data; boundary=----WebKitFormBoundarybGsrh6CxvZphA3y8",
  },
});

singleton.interceptors.response.use(
  function (response) {
    // let { status, data } = response

    // return {
    //   code: status,
    //   data: data,
    //   message: data.message
    // } as any

    return response.data as any;
  },
  function (error) {
    let res = error.response;
    if (res) {
      let { status, data } = res;
      return {
        code: status,
        message: data.message,
      } as any;
    }
    return Promise.reject(error);
  }
);

export default singleton;
