import React, { forwardRef } from 'react';
import { message, List, Avatar, Progress, Divider, Tooltip, Modal } from 'antd';

import {
  getAllLabelProjectList,
  deleteLabelProject,
  collectLabelProject,
  uncollectLabelProject,
} from '@/requests/labelProject';
import { DeleteOutlined, SettingOutlined, StarFilled, StarOutlined, UserAddOutlined } from '@ant-design/icons';
import '../styles/projectList.less';
import EditProjectModal from './EditProjectModal';
import MemberModal from './MemberModal';
import { useHistory } from 'react-router-dom';
enum LabelType {
  '多段线标注' = 1,
  '多边形标注' = 2,
}

const DeletedProjectList = (props: any, ref: any) => {
  const history = useHistory();
  const [data, setData] = React.useState<LabelProjectListItem[]>([]);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 8,
    total: 0,
  });
  const [editingProject, setEditingProject] = React.useState<LabelProjectListItem>();

  const [editVisible, setEditVisible] = React.useState(false);
  const [memberVisible, setMemberVisible] = React.useState(false);
  React.useImperativeHandle(ref, () => {
    return {
      fetchData,
    };
  });

  const [loading, setLoading] = React.useState(false);

  const handleEditClick = (project: LabelProjectListItem) => {
    if (!project) return;

    setEditingProject(project);
    setEditVisible(true);
  };

  const handleMemberClick = (project: LabelProjectListItem) => {
    if (!project) return;
    setEditingProject(project);
    setMemberVisible(true);
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.page;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getAllLabelProjectList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });

    if (res.code === 0) {
      setData(res.data.filter((x) => x.deleted === true));

      setPagination({
        page,
        pageSize,
        total: res.total,
      });
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  const doDelete = async (project_code: LabelProjectListItem['project_uid']) => {
    if (!project_code) return;
    const res = await deleteLabelProject(project_code);
    if (res.code === 0) {
      message.success('操作成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  //收藏项目
  const doCollect = async (project_id: LabelProjectListItem['project_uid']) => {
    if (!project_id) return;
    const res = await collectLabelProject(project_id);
    if (res.code === 0) {
      message.success('收藏成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };
  //取消收藏项目
  const doCancelCollect = async (project_id: LabelProjectListItem['project_uid']) => {
    if (!project_id) return;
    const res = await uncollectLabelProject(project_id);
    if (res.code === 0) {
      message.success('取消收藏成功');
      fetchData();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="project-list">
      <List
        dataSource={data}
        loading={loading}
        renderItem={(item) => {
          return (
            <List.Item
              key={item.id}
              extra={[
                <div className="project-props-container">
                  <div className="key">标注类型</div>
                  <div className="value">{LabelType[item.label_type]}</div>
                </div>,
                <div className="project-props-container">
                  <div className="key">创建日期</div>
                  <div className="value">{item.create_time}</div>
                </div>,
                <div className="project-props-container">
                  <div className="key">创建人</div>
                  <div className="value">{item.create_user.username}</div>
                </div>,
                <div className="project-props-container">
                  <div className="key">进度</div>
                  <div className="value" style={{ minWidth: 200 }}>
                    <Progress percent={Math.floor(Number(item.project_schedule) * 100)} status="active" />
                  </div>
                </div>,
                <div style={{ display: 'flex' }}>
                  <Tooltip title="添加成员">
                    <UserAddOutlined style={{ cursor: 'pointer' }} onClick={() => handleMemberClick(item)} />
                  </Tooltip>
                  <Divider type="vertical"></Divider>
                  <Tooltip title="项目设置">
                    <SettingOutlined style={{ cursor: 'pointer' }} onClick={() => handleEditClick(item)} />
                  </Tooltip>

                  <Divider type="vertical"></Divider>
                  <Tooltip title={item.favorite ? '取消收藏' : '收藏'}>
                    {item.favorite ? (
                      <StarFilled
                        style={{ cursor: 'pointer', color: '#f38900' }}
                        onClick={() => doCancelCollect(item.project_uid)}
                      />
                    ) : (
                      <StarOutlined style={{ cursor: 'pointer' }} onClick={() => doCollect(item.project_uid)} />
                    )}
                  </Tooltip>

                  <Divider type="vertical"></Divider>
                  <Tooltip title="移至回收站">
                    <DeleteOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        Modal.confirm({
                          title: '温馨提示',
                          content: '确认将该项目移至回收站吗？',
                          okText: '确认',
                          onOk: () => doDelete(item.project_uid),
                          cancelText: '取消',
                          onCancel: () => {},
                        });
                      }}
                    />
                  </Tooltip>
                </div>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    style={{ width: 50, height: 50 }}
                    src={
                      item.project_thumbnail_url
                        ? `/v2/api/file/pic/download/${item.project_thumbnail_url}`
                        : process.env.PUBLIC_URL + '/img/common/project-cover.png'
                    }
                  />
                }
                title={
                  <a
                    style={{ color: '#F39800' }}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/workbench/project/detail`,
                        state: {
                          project: item,
                        },
                        search: `id=${item.id}`,
                      });
                    }}
                  >
                    {item.project_name}
                  </a>
                }
                description={item.project_remark}
              ></List.Item.Meta>
            </List.Item>
          );
        }}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            fetchData(page, pageSize);
          },
          showTotal: (total) => <span>共{total}条数据</span>,
        }}
      />
      {editVisible && (
        <EditProjectModal
          visible={editVisible}
          setVisible={setEditVisible}
          project={editingProject}
          setProject={setEditingProject}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default forwardRef(DeletedProjectList);
