import * as React from 'react';
import { Image } from 'antd';
import {
  FileExcelFilled,
  FilePdfOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
const DOWNLOAD_ACTION = `/v2/api/team/task/downloadFile/`;

interface PropsType {
  file: TaskFileItem;
}

const FileViewer = (props: PropsType) => {
  const { file } = props;

  if (file === undefined || file === null) return null;
  if (!file) {
    return <span>无</span>;
  }
  if (file.file_name.endsWith('png') || file.file_name.endsWith('jpg') || file.file_name.endsWith('jpeg')) {
    return (
      <span style={{ marginRight: 8 }}>
        <Image width={24} height={24} src={DOWNLOAD_ACTION + file.file_uid} />
      </span>
    );
  }
  if (file.file_name.endsWith('pdf')) {
    return <FilePdfOutlined style={{ fontSize: 24, color: '#F39800' }} />;
  }

  if (file.file_name.endsWith('xlsx') || file.file_name.endsWith('xls') || file.file_name.endsWith('csv')) {
    return <FileExcelFilled style={{ fontSize: 24, color: 'green' }} />;
  }
  if (file.file_name.endsWith('word') || file.file_name.endsWith('docx') || file.file_name.endsWith('doc')) {
    return <FileWordOutlined style={{ fontSize: 24, color: 'blue' }} />;
  }
  if (file.file_name.endsWith('zip')) {
    return <FileZipOutlined style={{ fontSize: 24, color: 'purple' }} />;
  }
  if (file.file_name.endsWith('txt')) {
    return <FileTextOutlined style={{ fontSize: 24 }} />;
  }
  return <span>{file.file_name}</span>;
};

export default FileViewer;
