import React from 'react'
import * as BIRequests from '@/requests/bi'
import { message } from 'antd'
import 'echarts/lib/chart/boxplot'
import { chunkByInterval, getAxisLable } from '@/utils/array'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, GridComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer])

const ChartLegendIcons = ['circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none']
const dataTool = require('echarts/extension/dataTool')

const CustomPreviewChart = ({ chart }: { chart: ChartItem }) => {
  const [dataSeries, setDataSeries] = React.useState(chart.data_series)
  const [fetching, setFetching] = React.useState(false)
  // const [interval, setInterval] = React.useState(10);

  const getLoadingOption = () => {
    return {
      text: '加载中...',
      color: '#1890ff',
      textColor: '#270240',
      maskColor: 'rgba(194, 88, 86, 0.3)',
      zlevel: 0,
    }
  }

  const onChartReady = (chart: any) => {
    chart.hideLoading()
  }

  const getFlagType = (type: string) => {
    switch (type) {
      case '0':
        return 0 //时间
      case '1':
        return 1 //地点
      case '2':
        return 0
      case '3':
        return 1
      default:
        return 0
    }
  }

  const getChartType = (type: string) => {
    switch (type) {
      case '0':
        return 'line' //折线
      case '1':
        return 'line' //折线
      case '2':
        return 'boxplot' // 箱线
      case '3':
        return 'boxplot' // 箱线
      default:
        return 'line'
    }
  }

  React.useEffect(() => {
    let type = getFlagType(chart.echart_config.type)
    let datas = dataSeries.map((x) => x)
    dataSeries.map(async (x, index) => {
      const cacheData = localStorage.getItem(`${chart.chart_uid}_cache_data${index}`)
      if (!cacheData) {
        setFetching(true)
        const res =
          type === 0
            ? await BIRequests.getBIdatasFlagByTime(x.req_data as BIReq)
            : await BIRequests.getBIdatasFlagByVStatus(x.req_data as BIReq)
        if (res.code === 0) {
          datas[index].res_data = res.data
          setDataSeries(datas)
          // localStorage.setItem(`${chart.chart_uid}_cache_data${index}`, JSON.stringify(res.data))
        } else {
          message.error('网络错误')
        }
        setFetching(false)
      } else {
        datas[index].res_data = JSON.parse(cacheData)
        setDataSeries(datas)
      }
    })
  }, [chart])

  const getOption = (
    datas: {
      req_data: BIReq
      res_data?: BIRes[] | undefined
    }[]
  ) => {
    if (datas.some((x) => x.res_data === undefined)) return {}
    return {
      title: {
        text: `${chart.echart_config.title}`,
      }, //图表名称
      tooltip: {
        trigger: 'axis',
      }, // 提示框
      legend: {
        data: chart.data_series.map((x, index) => {
          return {
            name: x.req_data.asset_status,
            icon: ChartLegendIcons[index],
            textStyle: {},
          }
        }),
      }, //图例
      toolbox: {
        feature: {
          dataView: {},
        },
        emphasis: {
          iconStyle: {
            textFill: 'currentColor',
          },
        },
      }, //工具栏
      grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: datas.map((data, index) => {
        if (!data.res_data)
          return {
            type: 'category',
            name: data.req_data.asset_status,
            boundaryGap: false,
            nameLocation: 'middle',
            nameTextStyle: {
              verticalAlign: 'center',
            },
            data: [],
          }
        return {
          show: index === 0,
          type: getFlagType(chart.echart_config.type) === 0 ? 'category' : 'category',
          name: data.req_data.v_asset_flag ? data.req_data.v_asset_flag.v_status_name : '',
          boundaryGap: false,
          nameLocation: 'middle',
          nameTextStyle: {
            verticalAlign: 'top',
            fontSize: 12,
            align: 'center',
            padding: [5, 0, 0, 0],
          },
          data:
            getFlagType(chart.echart_config.type) === 0
              ? data.res_data.map((x) => x.time)
              : data.res_data
                  .sort((a, b) => Number(a.v_asset_status_value) - Number(b.v_asset_status_value))
                  .map((x) => x.v_asset_status_value),
        }
      }),

      yAxis: datas.map((data, index) => {
        return {
          type: 'value',
          nameTextStyle: {
            padding: index === 0 ? [0, 0, 0, 20] : [0, 40, 0, 0],
          },
          name: data.req_data.asset_status,
        }
      }),
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 20,
        },
        {
          show: true,
          height: 20,
          type: 'slider',
          top: '90%',
          xAxisIndex: [0],
          start: 0,
          end: 20,
        },
      ],
      series: datas.map((data) => {
        if (!data.res_data)
          return {
            name: data.req_data.asset_status,
            type: 'line',
            // stack: "总量",
            areaStyle: { normal: {} },
            data: [],
          }
        return {
          name: data.req_data.asset_status,
          type: 'line',
          // stack: "总量",
          areaStyle: { normal: {} },
          data: data.res_data.map((x) => x.asset_status_value),
        }
      }),
    }
  }

  const getBoxOption = (
    datas: {
      req_data: BIReq
      res_data?: BIRes[] | undefined
    }[]
  ) => {
    if (datas.some((x) => x.res_data === undefined)) return {}

    const chunkList = datas.map((x) => {
      if (!x.res_data) return
      return chunkByInterval(x.res_data, 10)
    })
    const axisLabels = datas.map((x) => getAxisLable(x.res_data, 10))
    const data = chunkList.map((x) => dataTool.prepareBoxplotData(x))
    console.log('chunklist:', chunkList, 'data:', data, 'lables:', axisLabels)
    return {
      title: [
        {
          text: chart.echart_config.title,
        },
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: chart.data_series.map((x, index) => {
          return x.req_data.asset_status
        }),
      },

      toolbox: {
        feature: {
          dataView: {},
        },
        emphasis: {
          iconStyle: {
            textFill: 'currentColor',
          },
        },
      }, //工具栏
      grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: data.map((x, x_index) => {
        return {
          type: 'category',
          data: x.axisData,
          boundaryGap: true,
          nameGap: 30,
          splitArea: {
            show: false,
          },
          axisLabel: {
            formatter: (value: any, index: number) => {
              return `${datas[0].req_data.v_asset_flag ? datas[0].req_data.v_asset_flag.v_status_name : ''}:${
                axisLabels[x_index][index]
              }`
            },
          },
          splitLine: {
            show: false,
          },
        }
      }),
      yAxis: datas.map((x, index) => {
        return {
          type: 'value',
          name: x.req_data.asset_status,
          nameTextStyle: {
            padding: index === 0 ? [0, 0, 0, 20] : [0, 40, 0, 0],
          },
          splitArea: {
            show: true,
          },
        }
      }),
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 20,
        },
        {
          show: true,
          height: 20,
          type: 'slider',
          top: '90%',
          xAxisIndex: [0],
          start: 0,
          end: 20,
        },
      ],
      series: datas.map((x, index) => {
        return {
          name: x.req_data.asset_status,
          type: 'boxplot',
          data: data[index].boxData,
          tooltip: {
            formatter: function (param: any) {
              return [
                'Experiment ' + param.name + ': ',
                'upper: ' + param.data[5],
                'Q3: ' + param.data[4],
                'median: ' + param.data[3],
                'Q1: ' + param.data[2],
                'lower: ' + param.data[1],
              ].join('<br/>')
            },
          },
        }
      }),
    }
  }

  return (
    <ReactEChartsCore
      option={getChartType(chart.echart_config.type) === 'line' ? getOption(dataSeries) : getBoxOption(dataSeries)}
      style={{ width: '100%', height: '100%' }}
      className={'chart-el'}
      echarts={echarts}
      showLoading={fetching}
      loadingOption={getLoadingOption()}
      onChartReady={onChartReady}
    />
  )
}

export default CustomPreviewChart
