const MessageTypeMapper = (rc_type: string) => {
  switch (rc_type) {
    case 'RC:TxtMsg':
      return 'text';
    case 'RC:ImgMsg':
      return 'photo';
    case 'RC:HQVCMsg':
      return 'audio';
    case 'RC:FileMsg':
      return 'file';
    case 'RC:SightMsg':
      return 'video';
    default:
      return 'text';
  }
};

const getDateString = (timestamp: number) => {
  const now = new Date();
  const N_D = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
  const N_Y = now.getFullYear();
  const N_M = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;
  const date = new Date(timestamp);
  const Y = date.getFullYear();
  const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  const s = date.getSeconds();
  if (N_D === D && N_Y === Y && N_M == M) {
    return h + ':' + m;
  } else {
    return `${Y}/${M}/${D}`;
  }
};
const TxtMsgType = {
  FORM: '@#Form&*',
  NORMALFORM: '@#NormalForm&*',
  ASSET: '~!Asset#&',
  DEFECT: '~!DEFECT#&',
  ASSET_STATUS: '*&assetValue#%',
  GROUP: '**%%裠屪%%**',
  GEOGRAPHIC: '~!GEOGRAPHIC#&',
  AIPIC: '@#AIPIC&*',
};

const getTxtMsgType = (latesMessage: any) => {
  const content = latesMessage.content.content;
  if (typeof content !== 'string') return '[未知消息类型]';
  if (!content) return '[未知消息类型]';
  if (content.startsWith(TxtMsgType.FORM) && content.endsWith(TxtMsgType.FORM)) {
    return '[表单]';
  } else if (content.startsWith(TxtMsgType.NORMALFORM) && content.endsWith(TxtMsgType.NORMALFORM)) {
    return '[一般表单]';
  } else if (content.startsWith(TxtMsgType.ASSET_STATUS) && content.endsWith(TxtMsgType.ASSET_STATUS)) {
    return '[资产状态]';
  } else if (content.startsWith(TxtMsgType.ASSET) && content.endsWith(TxtMsgType.ASSET)) {
    return '[资产]';
  } else if (content.startsWith(TxtMsgType.DEFECT) && content.endsWith(TxtMsgType.DEFECT)) {
    return '[缺陷]';
  } else if (content.startsWith(TxtMsgType.GROUP) && content.endsWith(TxtMsgType.GROUP)) {
    return '[群组消息]';
  } else if (content.startsWith(TxtMsgType.GEOGRAPHIC) && content.endsWith(TxtMsgType.GEOGRAPHIC)) {
    return '[地理信息]';
  } else if (content.startsWith(TxtMsgType.AIPIC) && content.endsWith(TxtMsgType.AIPIC)) {
    return '[ai图片]';
  } else {
    return content;
  }
};

const getLatesMessage = (latesMessage: any) => {
  switch (latesMessage.messageType) {
    case 'RC:TxtMsg':
      return getTxtMsgType(latesMessage);
    case 'RC:ImgMsg':
      return '[图片]';
    case 'RC:HQVCMsg':
      return '[语音]';
    case 'RC:FileMsg':
      return '[文件]';
    case 'RC:SightMsg':
      return '[视频]';
    default:
      return '[未知类型消息]';
  }
};

const getMessageDateString = (timestamp: number) => {
  const now = new Date();
  const N_D = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
  const N_Y = now.getFullYear();
  const N_M = now.getMonth() + 1 < 10 ? '0' + (now.getMonth() + 1) : now.getMonth() + 1;
  const date = new Date(timestamp);
  const Y = date.getFullYear();
  const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
  const s = date.getSeconds();
  if (N_D === D && N_Y === Y && N_M == M) {
    return h + ':' + m;
  } else if (N_Y !== Y) {
    return `${Y}/${M}/${D} ${h}:${m}`;
  } else {
    return `${M}/${D} ${h}:${m}`;
  }
};

export { MessageTypeMapper, getDateString, TxtMsgType, getLatesMessage, getMessageDateString };
