/// <reference path="type.d.ts" />
/// <reference path="suggestion.d.ts" />

import request from './_base'

//根据资产名称获取suggestion
export async function getAssetNameSuggestion(input?: string): Promise<AssetsResourceProto<{ data: string[] }>> {
  return request({
    method: 'GET',
    url: input ? `/suggestion/assetname/${input}` : '/suggestion/assetname/%20',
  }) as any
}

export async function getAssetCompsSuggestion(input: string): Promise<AssetsResourceProto<{ data: string[][] }>> {
  return request({
    method: 'GET',
    url: `/suggestion/assetcomps/${input}`,
  }) as any
}

export async function getAssetStatusSuggestion(input: {
  asset_comps: string[]
  asset_name: string
}): Promise<AssetsResourceProto<{ data: StatusSuggestionItem[] }>> {
  return request({
    method: 'POST',
    url: `/suggestion/assetstatus`,
    data: input,
  }) as any
}

export async function getAssetVCompsSuggestion(input: {
  asset_comps: string[]
  asset_name: string
}): Promise<AssetsResourceProto<{ data: string[][] }>> {
  return request({
    method: 'POST',
    url: `/suggestion/vassetcomps`,
    data: input,
  }) as any
}

export async function getStandardSuggestion(input: {
  asset_comps: string[]
  asset_name: string
  status_name: string[]
}): Promise<AssetsResourceProto<{ data: StandardSuggestionItem[] }>> {
  return request({
    method: 'POST',
    url: `/asset_data/standard/suggestion/get`,
    data: input,
  }) as any
}

/**
 * 根据statusName获取assetName,assetComps,statusName的suggestion
 * @param  {string} input 用户输入
 */
export async function statusSuggestion(
  input?: string
): Promise<AssetsResourceProto<{ data: StatusSuggestionItemV2[] }>> {
  return request({
    method: 'GET',
    url: input ? `/suggestion/statusname/${input}` : `/suggestion/statusname/%20`,
  }) as any
}

/**
 * 根据vStatusName获取vStatusValue的suggestion
 * @param  {statusName} input 用户输入
 */
export async function statusValueSuggestion(input: {
  v_status_name: string
}): Promise<AssetsResourceProto<{ data: VStatusValueOptionItem[] }>> {
  return request({
    method: 'POST',
    url: `/suggestion/asset_addr`,
    data: input,
  }) as any
}
