import * as React from 'react';
import { Avatar, Input, Button, Popover, message, List, Skeleton, Tag, Tabs, Select, Form, Tooltip } from 'antd';
import {
  SmileOutlined,
  FileProtectOutlined,
  BarsOutlined,
  ProfileOutlined,
  SearchOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import './index.less';
import * as RongIMLib from '@rongcloud/imlib-v4';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import * as FormRequests from '@/requests/form';
import { fetchFilteredAssetList, fetchFilteredStatusList } from '@/requests/assets';
import { isNull } from '@/utils/string';
import * as SuggestionRequests from '@/requests/suggestion';
import { getShareTemplateList } from '@/requests/normalForm';

const { TabPane } = Tabs;

interface PropsType {
  im: RongIMLib.IMClient;
  currentChat: ChatListItem;
  handleSendMessageSuccess: Function;
}

const MessageSenderWidget = (props: PropsType) => {
  const { im, currentChat, handleSendMessageSuccess } = props;
  const [inputTxt, setInputTxt] = React.useState('');

  //发送文本消息
  const sendTxtMessage = () => {
    if (!currentChat) return;
    if (inputTxt === '') return;
    let conversation = im.Conversation.get({
      targetId: currentChat.target_id,
      type: currentChat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: inputTxt, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        setInputTxt('');
        console.log('发送文字消息成功', message);
      });
  };

  const sendFormMessage = (form: FormDataItem) => {
    if (!currentChat) return;
    const prefix = `@#Form&*`;
    const formStr = JSON.stringify(form);

    let conversation = im.Conversation.get({
      targetId: currentChat.target_id,
      type: currentChat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + formStr + prefix, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        console.log('发送表单消息成功', message);
      });
  };
  //发送一般表单消息
  const sendNormalFormMessage = (form: FormDataItem) => {
    if (!currentChat) return;
    const prefix = `@#NormalForm&*`;
    const formStr = JSON.stringify(form);

    let conversation = im.Conversation.get({
      targetId: currentChat.target_id,
      type: currentChat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + formStr + prefix, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        console.log('发送表单消息成功', message);
      });
  };
  //发送资产消息
  const sentAssetMessage = (Asset: AssetItem) => {
    if (!currentChat) return;
    const prefix = '~!Asset#&';
    const AssetStr = JSON.stringify(Asset);

    let conversation = im.Conversation.get({
      targetId: currentChat.target_id,
      type: currentChat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + AssetStr + prefix, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        console.log('发送表单消息成功', message);
      });
  };

  //发送资产状态消息
  const sentAssetStatusMessage = (Asset: StatusListItem) => {
    if (!currentChat) return;
    const prefix = '*&assetValue#%';
    const StatusStr = JSON.stringify(Asset);

    let conversation = im.Conversation.get({
      targetId: currentChat.target_id,
      type: currentChat.type,
    });
    conversation
      .send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT, // 'RC:TxtMsg'
        content: {
          content: prefix + StatusStr + prefix, // 文本内容
        },
      })
      .then(function (message) {
        handleSendMessageSuccess(message);
        console.log('发送资产状态消息成功', message);
      });
  };

  const handleEmojiClick = (e: React.MouseEvent, emojiObject: IEmojiData) => {
    console.log(emojiObject.emoji);
    setInputTxt(inputTxt.concat(emojiObject.emoji));
  };
  return (
    <div
      style={{
        height: 150,
        borderTop: '1px solid #e8e8e8',
        display: 'flex',
      }}
    >
      <div
        style={{
          padding: '0 16px',
          height: '100%',
          width: 800,
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingTop: 16,
            color: '#24292e',
          }}
        >
          <Tooltip title="表情">
            <Popover content={<MyEmojiPicker onEmojiClick={handleEmojiClick} />} trigger="click">
              <SmileOutlined style={{ fontSize: 18, color: '#24292e' }} />
            </Popover>
          </Tooltip>
          <Tooltip title="表单">
            <Popover trigger="click" content={<FormPicker sender={sendFormMessage} />} style={{ minWidth: 600 }}>
              <FileProtectOutlined style={{ marginLeft: 16, fontSize: 18, color: '#24292e' }} />
            </Popover>
          </Tooltip>
          <Tooltip title="一般表单">
            <Popover trigger="click" content={<NormalFormPicker sender={sendNormalFormMessage} />}>
              <ProfileOutlined style={{ marginLeft: 16, fontSize: 18, color: '#24292e' }} />
            </Popover>
          </Tooltip>
          <Tooltip title="资产">
            <Popover trigger="click" content={<AssetPicker sender={sentAssetMessage} />}>
              <BarsOutlined style={{ marginLeft: 16, fontSize: 18, color: '#24292e' }} />
            </Popover>
          </Tooltip>
          <Tooltip title="资产状态">
            <Popover trigger="click" content={<AssetStatusPicker sender={sentAssetStatusMessage} />}>
              <InfoCircleOutlined style={{ marginLeft: 16, fontSize: 18, color: '#24292e' }} />
            </Popover>
          </Tooltip>
        </div>
        <div style={{ overflow: 'scroll', height: 109 }}>
          <Input.TextArea
            style={{
              border: 'none',
              boxShadow: 'none',
              resize: 'none',
              padding: '4px 0',
            }}
            value={inputTxt}
            onChange={(e) => setInputTxt(e.target.value)}
            placeholder="说点什么吧"
            autoSize={true}
          ></Input.TextArea>
        </div>
      </div>
      <div
        style={{
          width: 100,
          display: 'flex',
          flexDirection: 'column-reverse',
          padding: 16,
        }}
      >
        <Button
          type="primary"
          style={{ backgroundColor: '#1890ff', border: 'none', borderRadius: 5 }}
          onClick={() => sendTxtMessage()}
        >
          发送
        </Button>
      </div>
    </div>
  );
};

const MyEmojiPicker = (props: { onEmojiClick: (e: React.MouseEvent, emojiObject: IEmojiData) => void }) => {
  const { onEmojiClick } = props;
  return (
    <EmojiPicker
      onEmojiClick={onEmojiClick}
      disableAutoFocus
      disableSearchBar
      disableSkinTonePicker
      pickerStyle={{ border: 'none', boxShadow: 'none' }}
      groupNames={{
        smileys_people: 'yellow faces',
        recently_used: '最近使用',
      }}
      groupVisibility={{
        flags: false,
      }}
    />
  );
};

const MyUnsubmitedFormDatas = (props: { sender: Function }) => {
  const { sender } = props;
  const [fetching, setFetching] = React.useState(false);
  const [formDatas, setFormDatas] = React.useState<
    {
      data: FormDataItem | undefined;
      loading: boolean;
    }[]
  >([]);

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    setFetching(true);
    setFormDatas(
      [...new Array(10)].map(() => ({
        data: undefined,
        loading: true,
      }))
    );
    if (!page) page = pagination.current;
    if (!pageSize) pageSize = pagination.pageSize;

    const res = await FormRequests.getMyFormHeaderList((page - 1) * pageSize, pageSize);

    if (res.code === 0) {
      setPagination({ ...pagination, total: res.total });
      setFormDatas(
        res.data.map((x) => {
          return { data: x, loading: false };
        })
      );
    } else {
      setFormDatas([]);
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ width: 600 }}>
      <List
        dataSource={formDatas}
        loading={fetching}
        pagination={{
          ...pagination,
          onChange: (page, pagesize) => {
            setPagination({ ...pagination, current: page });
            fetchData(page, pagesize);
          },
        }}
        renderItem={(item) => {
          return (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-more"
                  onClick={(e) => {
                    e.preventDefault();
                    sender(item.data);
                  }}
                >
                  发送
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={item.data ? `/v2/api/user/avatar/${item.data.form_data.creator_avatar}` : ''} />}
                  title={item.data ? item.data.form_data.form_template_name : ''}
                  description={item.data ? item.data.form_data.submitted_time : ''}
                />
                <div>
                  {item.data ? (
                    item.data.form_pages_data.length === 0 ? (
                      <Tag color="gold">未填写</Tag>
                    ) : (
                      <Tag color="green">已填写</Tag>
                    )
                  ) : null}
                </div>
              </Skeleton>
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
};

const MyUnsubmitedCooperativeForms = (props: { sender: Function }) => {
  const { sender } = props;
  const [fetching, setFetching] = React.useState(false);
  const [formDatas, setFormDatas] = React.useState<
    {
      data: FormDataItem | undefined;
      loading: boolean;
    }[]
  >([]);

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    setFetching(true);
    setFormDatas(
      [...new Array(10)].map(() => ({
        data: undefined,
        loading: true,
      }))
    );
    if (!page) page = pagination.current;
    if (!pageSize) pageSize = pagination.pageSize;

    const res = await FormRequests.getMyUnsubmitedCooperativeFormList((page - 1) * pageSize, pageSize);

    if (res.code === 0) {
      setPagination({ ...pagination, total: res.total });
      setFormDatas(
        res.data.map((x) => {
          return { data: x, loading: false };
        })
      );
    } else {
      setFormDatas([]);
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ width: 600 }}>
      <List
        loading={fetching}
        dataSource={formDatas}
        pagination={{
          ...pagination,
          onChange: (page, pagesize) => {
            setPagination({ ...pagination, current: page });
            fetchData(page, pagesize);
          },
        }}
        renderItem={(item) => {
          return (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-more"
                  onClick={(e) => {
                    e.preventDefault();
                    sender(item.data);
                  }}
                >
                  发送
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={item.data ? `/v2/api/user/avatar/${item.data.form_data.creator_avatar}` : ''} />}
                  title={item.data ? item.data.form_data.form_template_name : ''}
                  description={
                    item.data
                      ? `创建者：${item.data.form_data.creator_name}(ID：${item.data.form_data.creator_id})`
                      : ''
                  }
                />
                <div>{item.data ? item.data.form_data.submitted_time : null}</div>
              </Skeleton>
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
};

const FormPicker = (props: { sender: Function }) => {
  const { sender } = props;
  const handleFormSelected = (form: FormDataItem) => {
    sender(form);
  };
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="我的表单" key="1">
        <MyUnsubmitedFormDatas sender={sender} />
      </TabPane>
      <TabPane tab="合作表单" key="2">
        <MyUnsubmitedCooperativeForms sender={sender} />
      </TabPane>
    </Tabs>
  );
};

const NormalFormPicker = (props: { sender: Function }) => {
  const { sender } = props;
  const [fetching, setFetching] = React.useState(false);
  const [formDatas, setFormDatas] = React.useState<
    {
      data: ShareTemplateItem | undefined;
      loading: boolean;
    }[]
  >([]);

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchData = async (page?: number, pageSize?: number) => {
    setFetching(true);
    setFormDatas(
      [...new Array(10)].map(() => ({
        data: undefined,
        loading: true,
      }))
    );
    if (!page) page = pagination.current;
    if (!pageSize) pageSize = pagination.pageSize;

    const res = await getShareTemplateList({
      skip: (page - 1) * pageSize,
      limit: pageSize,
    });

    if (res.code === 0) {
      setPagination({ ...pagination, total: res.total });
      setFormDatas(
        res.data.map((x) => {
          return { data: x, loading: false };
        })
      );
    } else {
      setFormDatas([]);
      message.error(res.message);
    }
    setFetching(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ width: 600 }}>
      <List
        loading={fetching}
        dataSource={formDatas}
        pagination={{
          ...pagination,
          onChange: (page, pagesize) => {
            setPagination({ ...pagination, current: page });
            fetchData(page, pagesize);
          },
        }}
        renderItem={(item) => {
          return (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-more"
                  onClick={(e) => {
                    e.preventDefault();
                    sender(item.data);
                  }}
                >
                  发送
                </a>,
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  title={item.data ? item.data.form_template_name : ''}
                  description={
                    item.data ? `创建者：${item.data.create_user_name}(ID：${item.data.create_user_id})` : ''
                  }
                />
              </Skeleton>
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
};

const AssetPicker = (props: { sender: Function }) => {
  const { sender } = props;
  const [filterAssetIds, setFilterAssetIds] = React.useState<string[]>([]);
  const [AssetList, setAssetList] = React.useState<AssetItem[]>([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const fetchData = async () => {
    if (filterAssetIds.length < 1) {
      return;
    }
    setLoading(true);
    const res = await fetchFilteredAssetList({
      limit: 1000,
      skip: 0,
      asset_ids: filterAssetIds,
    });
    if (res.code === 0) {
      setAssetList(res.data);
      setTotal(res.total);
    } else {
      message.error('网络错误');
    }
    setLoading(false);
  };
  return (
    <div style={{ width: 600, height: 500 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0',
        }}
      >
        <Select
          mode="tags"
          style={{ minWidth: 500, maxWidth: 500 }}
          onChange={(value: string[]) => {
            setFilterAssetIds(value);
          }}
          placeholder="资产id"
          value={filterAssetIds}
        ></Select>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => fetchData()}>
          搜索
        </Button>
      </div>
      <List
        dataSource={AssetList}
        loading={loading}
        pagination={{
          total: total,
        }}
        locale={{ emptyText: '输入资产id进行查询' }}
        renderItem={(item) => {
          return (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-more"
                  onClick={(e) => {
                    e.preventDefault();
                    sender(item);
                  }}
                >
                  发送
                </a>,
              ]}
            >
              <List.Item.Meta
                title={item.asset_name}
                description={
                  <span>
                    资产组件：
                    {item.asset_comps.map((x, index) => (
                      <Tag color="#1890ff" key={index}>
                        {x}
                      </Tag>
                    ))}
                  </span>
                }
              />
              <div>{item.create_time}</div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

const AssetStatusPicker = (props: { sender: Function }) => {
  const { sender } = props;
  const [filterStatusName, setFilterStatusName] = React.useState<string[]>([]);
  const [filterStatusValue, setFilterStatusValue] = React.useState<string[]>([]);
  const [statusList, setStatusList] = React.useState<StatusListItem[]>([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [statusNameOptions, setStatusNameOptions] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(false);
  const fetchData = async (page?: number, pageSize?: number) => {
    if (!filterStatusName) return;
    setLoading(true);
    if (!page) page = pagination.current;
    if (!pageSize) pageSize = pagination.pageSize;
    const res = await fetchFilteredStatusList({
      limit: (page - 1) * pageSize,

      skip: pageSize,
      status_name: filterStatusName,
      status_value: filterStatusValue,
    });
    if (res.code === 0) {
      setStatusList(res.data);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: res.total,
      });
    } else {
      message.error('网络错误');
    }
    setLoading(false);
  };
  return (
    <div style={{ width: 600, height: 500, overflow: 'scroll' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px 0',
        }}
      >
        <div>
          <Form layout="inline">
            <Form.Item label="资产状态名">
              <Select
                mode="tags"
                style={{ minWidth: 200, maxWidth: 500 }}
                onChange={(value: string[]) => {
                  setFilterStatusName(value);
                  setFilterStatusValue(value.map((x) => ''));
                }}
                placeholder="资产状态名"
                value={filterStatusName}
                onDropdownVisibleChange={async (open) => {
                  if (open) {
                    if (filterStatusName) return;
                    const res = await SuggestionRequests.statusSuggestion();
                    if (res.code === 0) {
                      setStatusNameOptions(res.data.map((x) => x.asset_status));
                    }
                  }
                }}
                onSearch={async (value) => {
                  if (!value) return;
                  if (isNull(value)) {
                    return;
                  }
                  const res = await SuggestionRequests.statusSuggestion(value);
                  if (res.code === 0) {
                    setStatusNameOptions(res.data.map((x) => x.asset_status));
                  }
                }}
              >
                {Array.from(new Set<string>(statusNameOptions)).map((item, index) => {
                  return (
                    <Select.Option key={item + index} value={item}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
          <Form layout="inline">
            {filterStatusName.map((x, index) => {
              return (
                <Form.Item key={index} label={x}>
                  <Input
                    value={filterStatusValue[index]}
                    onChange={(e) => {
                      let arr = filterStatusValue.map((v) => v);
                      arr[index] = e.target.value;
                      setFilterStatusValue(arr);
                    }}
                  />
                </Form.Item>
              );
            })}
          </Form>
        </div>

        <Button type="primary" icon={<SearchOutlined />} onClick={() => fetchData()}>
          搜索
        </Button>
      </div>
      <List
        dataSource={statusList}
        loading={loading}
        pagination={{
          ...pagination,
          onChange: (page, pagesize) => {
            setPagination({ ...pagination, current: page });
            fetchData(page, pagesize);
          },
        }}
        locale={{ emptyText: '输入资产状态名进行查询' }}
        renderItem={(item) => {
          return (
            <List.Item
              actions={[
                <a
                  key="list-loadmore-more"
                  onClick={(e) => {
                    e.preventDefault();
                    sender(item);
                  }}
                >
                  发送
                </a>,
              ]}
            >
              <List.Item.Meta
                title={`${item.status_name}：${item.status_value}`}
                description={
                  <span>
                    资产组件：
                    {item.asset_comps.map((x, index) => (
                      <Tag color="#1890ff" key={index}>
                        {x}
                      </Tag>
                    ))}
                  </span>
                }
              />
              <div>{item.create_time}</div>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default MessageSenderWidget;
