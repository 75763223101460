import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useCtxState } from '@/App.store'
import * as formRequests from '@/requests/form'
import * as Utils from '@/utils/string'
import { message, Spin, Form, PageHeader, Input, Button, Divider, Upload, Modal, Select } from 'antd'
import '../styles/index.less'
import { PlusOutlined, UploadOutlined, SaveOutlined } from '@ant-design/icons'

const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
}

interface StatusPic {
  marker_location: string
  uid: string
  form_id: string
  comment: string
  size: any
  name: any
  type: any
}

interface FormPageWritingDatas {
  form_id: string
  page_index: string
  uid: string
  form_page_contents: {
    status_value: string
    status_pics: any[]
    comment: string
    field_index: string
    asset_id: string
    status_name: string
    status_type: string
    form_id: string
    v_asset_comps: string[]
    asset_comps: string[]
    asset_name: string
    v_asset_status: {
      v_name: string
      v_value: string
    }[]
    v_asset_name: string
  }
}

function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const FreeFormDataWritePage = () => {
  const [{ userInfo }] = useCtxState()
  const history = useHistory()
  const location = useLocation<any>()
  const [template, setTemplate] = React.useState<FormTemplateItem | undefined>(undefined)
  const [formData, setFormData] = React.useState<FormDataItem | undefined>(undefined)
  const [formPageData, setFormPageData] = React.useState<FormPageWritingDatas | undefined>(undefined)
  const [fetching, setFetching] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  const [loadingNextPage, setLoadingNextPage] = React.useState(false)
  const [loadingPrePage, setLoadingPrePage] = React.useState(false)
  const [previewVisible, setPreviewVisible] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState('')

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
  }
  const fetchFormData = async () => {
    if (!formData) return
    let form_id = ''
    form_id = location.state ? location.state.form_id : location.search.split('=')[1]
    const res = await formRequests.getFormDataById(form_id)
    if (res.code === 0) {
      setFormData({ ...formData, form_pages_data: res.data.form_pages_data })
    } else {
      message.error(res.message)
    }
  }

  const initFormData = async () => {
    let form_id = ''
    form_id = location.state ? location.state.form_id : location.search.split('=')[1]
    const res = await formRequests.getFormDataById(form_id)
    if (res.code === 0) {
      setFormData(res.data)
      const TemplateRes = await formRequests.queryShareTemplate({
        uid: res.data.form_data.form_template_share_uid,
        version: res.data.form_data.form_template_version_number,
      })
      const { form_pages_data } = res.data
      if (TemplateRes.code === 0) {
        setTemplate(TemplateRes.data)
        setFormPageData({
          uid: '',
          form_id: res.data.form_data.form_id,
          page_index: (res.data.form_pages_data.length + 1).toString(),
          form_page_contents: {
            status_value: '',
            asset_id: '',
            field_index: '1',
            comment: '',
            status_pics: [],
            status_name: '',
            status_type: '',
            asset_comps: [],
            form_id: res.data.form_data.form_id,
            v_asset_comps: [],
            v_asset_status: [],
            asset_name: '',
            v_asset_name: '',
          },
        })
      } else {
        message.error('未知错误！')
        history.goBack()
      }
    } else {
      message.error('未知错误！')
      history.goBack()
    }
    setFetching(false)
  }

  const handleNextPage = async () => {
    if (!template || !formPageData || !formData) return
    setLoadingNextPage(true)
    let page_index = Number(formPageData.page_index) - 1

    if (page_index + 1 === formData.form_pages_data.length) {
      let form_id = ''
      form_id = location.state ? location.state.form_id : location.search.split('=')[1]
      const res = await formRequests.getFormDataById(form_id)
      if (res.code === 0) {
        setFormData({ ...formData, form_pages_data: res.data.form_pages_data })
        setFormPageData({
          ...formPageData,
          uid: '',
          page_index: (res.data.form_pages_data.length + 1).toString(),
          form_page_contents: {
            status_value: '',
            asset_id: '',
            field_index: '1',
            comment: '',
            status_pics: [],
            status_name: '',
            status_type: '',
            asset_comps: [],
            form_id: res.data.form_data.form_id,
            v_asset_comps: [],
            v_asset_status: [],
            asset_name: '',
            v_asset_name: '',
          },
        })
      } else {
        message.error('未知错误！')
      }
    } else {
      const uid = formData.form_pages_data[page_index + 1].uid

      const res = await formRequests.getSinglePageData(uid)
      if (res.code === 0) {
        const pageData = res.data.form_page_contents[0]
        setFormPageData({
          uid: res.data.uid,
          form_id: res.data.form_id,
          page_index: res.data.page_index,
          form_page_contents: {
            ...pageData,
            form_id: res.data.form_id,
            status_pics: [],
            v_asset_status: Object.keys(pageData.v_asset_status).map((v_comp, v_index) => {
              return {
                v_name: v_comp,
                v_value: pageData.v_asset_status[v_comp],
              }
            }),
          },
        })
      } else {
        message.error(res.message)
      }
    }

    setLoadingNextPage(false)
  }

  const handlePrePage = async () => {
    if (!template || !formPageData || !formData) return
    setLoadingPrePage(true)
    let page_index = Number(formPageData.page_index) - 1
    if (page_index <= 0) {
      message.warning('已经是第一页')
      setLoadingPrePage(false)
      return
    }
    const uid = formData.form_pages_data[page_index - 1].uid

    const res = await formRequests.getSinglePageData(uid)
    if (res.code === 0) {
      const pageData = res.data.form_page_contents[0]
      console.log(
        Object.keys(pageData.v_asset_status).map((v_comp, v_index) => {
          return {
            v_name: v_comp,
            v_value: pageData.v_asset_status[v_comp],
          }
        })
      )
      setFormPageData({
        uid: res.data.uid,
        form_id: res.data.form_id,
        page_index: res.data.page_index,
        form_page_contents: {
          ...pageData,
          form_id: res.data.form_id,
          asset_id: pageData.asset_id,
          status_pics: [],
          v_asset_status: Object.keys(pageData.v_asset_status).map((v_comp, v_index) => {
            return {
              v_name: v_comp,
              v_value: pageData.v_asset_status[v_comp],
            }
          }),
        },
      })
    } else {
      message.error(res.message)
    }
    setLoadingPrePage(false)
  }

  const saveSinglePageData = async () => {
    if (!template || !formPageData || !formData) return
    setSaving(true)
    try {
      if (!formPageData.uid) {
        let obj: any = {}
        formPageData.form_page_contents.v_asset_status.forEach((v, v_index) => {
          obj[v.v_name] = v.v_value
        })
        const res = await formRequests.saveSingleData({
          ...formPageData,
          form_page_contents: [
            {
              ...formPageData.form_page_contents,
              v_asset_status: obj,
              status_pics: formPageData.form_page_contents.status_pics.map((pic, pic_index) => {
                return {
                  marker_location: '',
                  pic_uid: pic.response.data,
                  form_id: formPageData.form_page_contents.form_id,
                  comment: '',
                }
              }),
              tools:
                formData.form_data.user_tools.length > 0
                  ? formData.form_data.user_tools.filter((item) => item.user_id === userInfo.user_id)[0].tools
                  : [],
            },
          ],
        })
        if (res.code === 0) {
          message.success('保存成功')
          fetchFormData()
          setFormPageData({
            ...formPageData,
            uid: res.data.uid,
          })
        } else {
          message.error(res.message)
        }
      } else {
        let obj: any = {}
        formPageData.form_page_contents.v_asset_status.forEach((v, v_index) => {
          obj[v.v_name] = v.v_value
        })
        const res = await formRequests.updateSinglePageData({
          ...formPageData,
          form_page_contents: [
            {
              ...formPageData.form_page_contents,
              v_asset_status: obj,
              status_pics: formPageData.form_page_contents.status_pics.map((pic, pic_index) => {
                return {
                  marker_location: '',
                  pic_uid: pic.response.data,
                  form_id: formPageData.form_page_contents.form_id,
                  comment: '',
                }
              }),
              tools:
                formData.form_data.user_tools.length > 0
                  ? formData.form_data.user_tools.filter((item) => item.user_id === userInfo.user_id)[0].tools
                  : [],
            },
          ],
        })
        if (res.code === 0) {
          message.success('保存成功')
          fetchFormData()
        } else {
          message.error(res.message)
        }
      }
    } catch (e) {
      console.log(e)
      message.error('未知错误')
    }
    console.log('xxxx', formPageData, formData, template)

    setSaving(false)
  }

  const queryAsset = async () => {
    if (!template || !formPageData || !formData) return
    let obj: any = {}
    formPageData.form_page_contents.v_asset_status.forEach((v) => {
      obj[v.v_name] = v.v_value
    })
    if (formPageData.form_page_contents.v_asset_status.length < 1) return
    if (!formPageData.form_page_contents.status_name) return
    const res = await formRequests.queryAsset({
      asset_name: formPageData.form_page_contents.asset_name,
      asset_comps: formPageData.form_page_contents.asset_comps,
      v_asset_name: formPageData.form_page_contents.v_asset_name,
      status_name: formPageData.form_page_contents.status_name,
      v_asset_comps: formPageData.form_page_contents.v_asset_comps,
      v_asset_status: obj,
    })
    if (res.code === 0) {
      if (res.data.length === 0) {
        setFormPageData({
          ...formPageData,
          form_page_contents: {
            ...formPageData.form_page_contents,
            asset_id: '',
          },
        })
      } else if (res.data.length === 1) {
        setFormPageData({
          ...formPageData,
          form_page_contents: {
            ...formPageData.form_page_contents,
            asset_id: res.data[0].asset_id,
          },
        })
      } else {
        Modal.confirm({
          title: '匹配到多个资产，请选择！',
          content: (
            <Select
              defaultValue={res.data[0].asset_id}
              style={{ minWidth: 400 }}
              onChange={(value: string) => {
                setFormPageData({
                  ...formPageData,
                  form_page_contents: {
                    ...formPageData.form_page_contents,
                    asset_id: value,
                  },
                })
              }}
            >
              {res.data.map((x, _index) => {
                return <Select.Option value={x.asset_id}>{x.asset_name + x.asset_id}</Select.Option>
              })}
            </Select>
          ),
          okText: '确认',
          cancelButtonProps: { style: { display: 'none' } },
          onOk: () => {
            if (!formPageData.form_page_contents.asset_id) {
              message.error('请选择一个资产！')
              return Promise.reject()
            } else {
              return Promise.resolve()
            }
          },
          width: 500,
        })
      }
    } else if (res.code === 70002) {
    } else {
      // message.error(res.message);
    }
  }

  const submitFormData = async () => {
    if (!template || !formPageData || !formData) return
    setSaving(true)
    const res = await formRequests.submitFormData(formData.form_data.form_id)
    if (res.code === 0) {
      message.success('提交成功')
      setTimeout(() => {
        history.goBack()
      }, 500)
    } else {
      message.error('提交失败，请稍后再试')
    }
    setSaving(false)
  }

  React.useEffect(() => {
    setFetching(true)
    initFormData()
  }, [])

  return (
    <div className="free-form-write-page">
      <PageHeader
        title={'填写自由表单'}
        onBack={() => history.goBack()}
        extra={[
          <Button
            key="submit"
            type="primary"
            icon={<UploadOutlined />}
            onClick={() => {
              Modal.confirm({
                title: '提示',
                content: '确认提交当前表单吗？',
                okText: '确认',
                onOk: () => {
                  submitFormData()
                },
                cancelText: '取消',
              })
            }}
          >
            提交表单
          </Button>,
          <Button
            key="save"
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => {
              message.success('保存成功')
              setTimeout(() => {
                history.goBack()
              }, 500)
            }}
          >
            保存表单
          </Button>,
        ]}
      ></PageHeader>

      <div
        style={{
          width: '100%',
          minHeight: 700,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {!!!fetching && template && formData && formPageData && (
          <div
            style={{
              minWidth: 600,
              maxWidth: 600,
              border: '1px solid #f39800',
              padding: 24,
              position: 'relative',
              backgroundColor: '#F9F9FB',
              borderRadius: 5,
            }}
          >
            <div style={{ position: 'absolute', right: 20 }}>
              <span>{`第 ${formPageData.page_index} 页`}</span>
            </div>
            <div>
              <Form style={{ marginBottom: 20 }} {...formItemLayout}>
                <Form.Item label="地理组件">
                  <Select
                    mode="tags"
                    style={{ minWidth: 360 }}
                    placeholder="请选择地理组件"
                    value={formPageData.form_page_contents.v_asset_comps}
                    onChange={(value: string[]) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          v_asset_comps: value,
                          v_asset_status: value.map((x) => {
                            return {
                              v_name: x,
                              v_value: '',
                            }
                          }),
                        },
                      })
                    }}
                  ></Select>
                </Form.Item>
                {formPageData.form_page_contents.v_asset_status.map((vcomp, index) => {
                  return (
                    <Form.Item label={vcomp.v_name} key={`v_comps-${index}`}>
                      <Input
                        value={vcomp.v_value}
                        onChange={(e) => {
                          let arr = formPageData.form_page_contents.v_asset_status.map((x) => x)
                          arr[index].v_value = e.target.value
                          setFormPageData({
                            ...formPageData,
                            form_page_contents: {
                              ...formPageData.form_page_contents,
                              v_asset_status: arr,
                            },
                          })
                        }}
                        onBlur={(e) => {
                          queryAsset()
                        }}
                      />
                    </Form.Item>
                  )
                })}
              </Form>
            </div>
            <Divider />

            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    paddingLeft: 10,
                    fontSize: 12,
                    paddingBottom: 8,
                    color: formPageData.form_page_contents.asset_id ? '#52C41A' : '#FAAD14',
                  }}
                >
                  {formPageData.form_page_contents.asset_id &&
                    `已匹配资产：${formPageData.form_page_contents.asset_id}`}
                  {!formPageData.form_page_contents.asset_id && `未匹配资产`}
                </div>
              </div>

              <Form {...formItemLayout}>
                <Form.Item label="资产名">
                  <Input
                    value={formPageData.form_page_contents.asset_name}
                    onChange={(e) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          asset_name: e.target.value,
                        },
                      })
                    }}
                    onBlur={(e) => {
                      queryAsset()
                    }}
                  />
                </Form.Item>
                <Form.Item label="资产组件">
                  <Select
                    value={formPageData.form_page_contents.asset_comps}
                    mode="tags"
                    onChange={(value: string[]) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          asset_comps: value,
                        },
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item label="地理名称">
                  <Input
                    value={formPageData.form_page_contents.v_asset_name}
                    onChange={(e) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          v_asset_name: e.target.value,
                        },
                      })
                    }}
                    onBlur={(e) => {
                      queryAsset()
                    }}
                  />
                </Form.Item>
                <Form.Item label="资产状态名">
                  <Input
                    value={formPageData.form_page_contents.status_name}
                    onChange={(e) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          status_name: e.target.value,
                        },
                      })
                    }}
                    onBlur={(e) => {
                      queryAsset()
                    }}
                  />
                </Form.Item>
                <Form.Item label="资产状态值">
                  <Input
                    value={formPageData.form_page_contents.status_value}
                    onChange={(e) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          status_value: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item label="状态值类型">
                  <Select
                    defaultValue="数值"
                    value={formPageData.form_page_contents.status_type}
                    onChange={(value: string) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          status_type: value,
                        },
                      })
                    }}
                  >
                    <Select.Option value="数值">数值</Select.Option>
                    <Select.Option value="文本">文本</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="图片" style={{ marginBottom: 0 }}>
                  <Upload
                    action={`/v2/api/form/data/status/pic`}
                    listType="picture-card"
                    method="POST"
                    fileList={formPageData.form_page_contents.status_pics}
                    onPreview={(file) => {
                      handlePreview(file)
                    }}
                    onChange={({ fileList }) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          status_pics: fileList,
                        },
                      })
                    }}
                    transformFile={(file) => {
                      return new Promise((resolve) => {
                        const reader = new FileReader()
                        reader.readAsDataURL(file)
                        const uid = Utils.uuid()
                        reader.onload = (e: any) => {
                          const blob = e.target.result
                          const newFile = Utils.dataURLtoFile(blob, `${uid}.${file.name.split('.')[1]}`)
                          resolve(newFile)
                        }
                      })
                    }}
                    className="upload-list-inline "
                  >
                    {formPageData.form_page_contents.status_pics.length >= 8 ? null : (
                      <div>
                        <PlusOutlined />
                      </div>
                    )}
                  </Upload>
                </Form.Item>
                <Form.Item label="备注">
                  <Input
                    value={formPageData.form_page_contents.comment}
                    onChange={(e) => {
                      setFormPageData({
                        ...formPageData,
                        form_page_contents: {
                          ...formPageData.form_page_contents,
                          comment: e.target.value,
                        },
                      })
                    }}
                  />
                </Form.Item>
              </Form>
            </div>

            <Modal
              visible={previewVisible}
              footer={null}
              onCancel={() => {
                setPreviewVisible(false)
              }}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 50,
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  handlePrePage()
                }}
                disabled={loadingNextPage || loadingPrePage || saving}
              >
                上一页
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  saveSinglePageData()
                }}
                disabled={loadingNextPage || loadingPrePage || saving}
              >
                保存
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleNextPage()
                }}
                disabled={!formPageData.uid || loadingNextPage || loadingPrePage || saving}
              >
                下一页
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={fetching} tip="正在初始化..." />
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={saving} tip="保存中" />
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={loadingNextPage} tip="正在加载下一页" />
      </div>
      <div style={{ position: 'absolute', right: '50%', top: '50%' }}>
        <Spin spinning={loadingPrePage} tip="正在加载上一页" />
      </div>
    </div>
  )
}

export default FreeFormDataWritePage
