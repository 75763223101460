import React from 'react'
import { Modal, Input, Form, Select, message, InputNumber, Button } from 'antd'
import * as AddrRequests from '@/requests/location'
interface ModalProps {
  visible: boolean
  setVisible: Function
  fetchData: Function
}
const CreateAddrModal = (props: ModalProps) => {
  const { visible, setVisible, fetchData } = props
  const [fetching, setFetching] = React.useState(false)
  const [form] = Form.useForm()
  const [valueType, setValueType] = React.useState('文本')
  const [selectedAddr, setSelectedAddr] = React.useState<string[]>([])

  const handleOk = async (values: any) => {
    doCreate({
      addr_status_name: values['addr_status_name'],
      addr_status_type: values['addr_status_type'],
      addr_status_value: values['addr_status_value'],
      addr_name: values['addr_name'],
      comment: values['comment'] ? values['comment'] : '',
      addr_comps: values['addr_comps'].map((x: string) => {
        return {
          addr_comp_name: x,
          addr_comp_value: values[x],
        } as BaseAddrs
      }),
    })
  }

  const handleCancel = (e: any) => {
    Modal.confirm({
      title: '温馨提示',
      content: '确认放弃当前编辑内容吗？',
      okText: '放弃',
      cancelText: '继续编辑',
      autoFocusButton: 'cancel',
      onOk: () => setVisible(false),
      onCancel: () => {},
    })
  }

  const doCreate = async (input: CreateAddrReq) => {
    setFetching(true)
    const res = await AddrRequests.createAddr(input)
    if (res.code === 0) {
      message.success('创建成功')
      setVisible(false)
      fetchData()
    } else {
      message.error('创建失败')
    }
    setFetching(false)
  }

  return (
    <Modal
      title={
        <span>
          <div className="title-prefix"></div>创建地点信息
        </span>
      }
      visible={visible}
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        form={form}
        preserve={false}
        onFinish={handleOk}
        onValuesChange={(changedValues) => {
          if (Object.keys(changedValues)[0] === 'addr_status_type') {
            setValueType(changedValues[Object.keys(changedValues)[0]])
          }
          if (Object.keys(changedValues)[0] === 'addr_comps') {
            setSelectedAddr(changedValues[Object.keys(changedValues)[0]])
          }
        }}
        labelCol={{
          md: 5,
          xl: 5,
          sm: 5,
        }}
      >
        <Form.Item
          label="地理状态名"
          name="addr_status_name"
          rules={[
            {
              required: true,
              message: '请输入地理状态名',
            },
          ]}
        >
          <Input placeholder="请输入地理状态名" />
        </Form.Item>
        <Form.Item
          label="状态值类型"
          name="addr_status_type"
          rules={[{ required: true, message: '请选择状态值类型！' }]}
          initialValue={'文本'}
        >
          <Select placeholder="请选择状态值类型">
            <Select.Option value="数值">数值</Select.Option>
            <Select.Option value="文本">文本</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="地理状态值"
          name="addr_status_value"
          rules={[{ required: true, message: '请输入地理状态值！' }]}
        >
          {valueType === '数值' ? <InputNumber style={{ width: '100%' }} /> : <Input />}
        </Form.Item>
        <Form.Item label="备注" name="comment">
          <Input.TextArea placeholder="备注" />
        </Form.Item>
        <Form.Item label="地理名称" name="addr_name" rules={[{ required: true, message: '请输入地理名称！' }]}>
          <Input placeholder="请输入地理名称" />
        </Form.Item>
        <Form.Item label="地理组件" name="addr_comps" rules={[{ required: true, message: '请输入地理组件！' }]}>
          <Select mode="tags" placeholder="输入地理组件" />
        </Form.Item>
        {selectedAddr.map((x, _index) => {
          return (
            <Form.Item
              label={x}
              key={_index}
              name={x}
              rules={[
                {
                  required: true,
                  message: `请输入${x}值！`,
                },
              ]}
            >
              <Input placeholder={`请输入${x}值`} />
            </Form.Item>
          )
        })}
        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 36,
              padding: '0 100px',
            }}
          >
            <Button type="primary" htmlType={'submit'} style={{ padding: '0 36px' }} loading={fetching}>
              确认
            </Button>
            <Button onClick={handleCancel} style={{ padding: '0 36px' }}>
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateAddrModal
