/// <reference path="type.d.ts" />
/// <reference path="form.d.ts" />

import request from './_base'

export async function getPersonalFormTempalteList(input: {
  page?: number
  pageSize?: number
}): Promise<AssetsResourceProto<{ data: FormTemplateItem[]; total: number }>> {
  if (!input.page) {
    input.page = 1
  }
  if (!input.pageSize) {
    input.pageSize = 10
  }
  const { page, pageSize } = input
  return request({
    method: 'GET',
    url: `/form/template/${pageSize * (page - 1)}/${pageSize}`,
  }) as any
}

export async function createFormTemplate(
  input: CreateFormTemplateReq
): Promise<AssetsResourceProto<{ data: FormTemplateItem }>> {
  return request({
    method: 'POST',
    url: `/form/template`,
    data: input,
  }) as any
}
// 校验formName,formId,versionName的唯一性

export async function validateDuplication(input: {
  form_name?: string
  form_template_id?: string
  version_name?: string
}): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'POST',
    url: '/form/template/unique',
    data: input,
  }) as any
}
//根据uid删除个人模板
export async function deletePersonalTemplate(input: { uid: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    data: input,
    url: '/form/template',
  }) as any
}

//根据uid查询个人模板
export async function queryPersonalTemplate(input: {
  uid: string
}): Promise<AssetsResourceProto<{ data: FormTemplateItem }>> {
  return request({
    method: 'GET',
    url: `/form/template/${input.uid}`,
  }) as any
}
// 修改个人模板表单
export async function updatePersonalTemplate(
  input: FormTemplateItem
): Promise<AssetsResourceProto<{ data: FormTemplateItem }>> {
  return request({
    method: 'PUT',
    url: '/form/template',
    data: input,
  }) as any
}
//分享个人表单模板
export async function sharePersonalTemplate(input: { uid: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'POST',
    url: '/form/template/share',
    data: input,
  }) as any
}
//查看分享的表单模板list
export async function getShareFormTempalteList(input: {
  page?: number
  pageSize?: number
  form_name: string
}): Promise<AssetsResourceProto<{ data: ShareFormTemplateItem[]; total: number }>> {
  if (!input.page) {
    input.page = 1
  }
  if (!input.pageSize) {
    input.pageSize = 10
  }
  const { page, pageSize } = input
  return request({
    method: 'POST',
    url: `/form/template/share/list`,
    data: {
      skip: pageSize * (page - 1),
      limit: pageSize,
      form_name: input.form_name,
    },
  }) as any
}
//根据uid查询分享表单
export async function queryShareTemplate(input: {
  uid: string
  version: number
}): Promise<AssetsResourceProto<{ data: ShareFormTemplateItem }>> {
  return request({
    method: 'GET',
    url: `/form/template/share/${input.uid}/${input.version}`,
  }) as any
}

// 修改分享模板表单
export async function updateShareTemplate(
  input: ShareFormTemplateItem
): Promise<AssetsResourceProto<{ data: ShareFormTemplateItem }>> {
  return request({
    method: 'PUT',
    url: '/form/template/share',
    data: input,
  }) as any
}
//根据uid删除分享模板
export async function deleteShareTemplate(input: { uid: string }): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    data: input,
    url: '/form/template/share',
  }) as any
}

// 上传 signature pic
export async function uploadSignaturePic(input: { file: Blob }): Promise<AssetsResourceProto<{ data: string }>> {
  const formData = new FormData()
  formData.append('file', input.file)
  return request({
    method: 'POST',
    url: '/form/data/signature/pic',
    data: formData,
  }) as any
}

// 使用模板填写数据,初始化formTemplate,即创建formHeader
export async function createFormHeader(
  input: CreateFormHeadReq
): Promise<AssetsResourceProto<{ data: FormHeadDataItem }>> {
  return request({
    method: 'POST',
    url: '/form/data',
    data: input,
  }) as any
}

//根据登录人获取其创建的formdata

export async function getMyFormHeaderList(
  skip: number = 0,
  limit: number = 10
): Promise<
  AssetsResourceProto<{
    data: FormDataItem[]
    total: number
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/list/${skip}/${limit}`,
  }) as any
}
// 根据登录人，获取未提交的合作表单
export async function getMyUnsubmitedCooperativeFormList(
  skip: number = 0,
  limit: number = 10
): Promise<
  AssetsResourceProto<{
    data: FormDataItem[]
    total: number
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/list/cooperative/${skip}/${limit}`,
  }) as any
}
//根据登录人获取，获取其已提交的formdata
export async function getMySubmitedFormList(
  skip: number = 0,
  limit: number = 10
): Promise<
  AssetsResourceProto<{
    data: FormDataItem[]
    total: number
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/list/submitted/${skip}/${limit}`,
  }) as any
}

//根据登录人获取，获取其已提交的合作formdata
export async function getMySubmitedCooperativeFormList(
  skip: number = 0,
  limit: number = 10
): Promise<
  AssetsResourceProto<{
    data: FormDataItem[]
    total: number
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/list/submitted/cooperative/${skip}/${limit}`,
  }) as any
}

//根据formId 获取获取formData
export async function getFormDataById(form_id: string): Promise<
  AssetsResourceProto<{
    data: FormDataItem
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/group/${form_id}`,
  }) as any
}

//根据formId 删除formData
export async function deleteFormDataById(form_id: string): Promise<
  AssetsResourceProto<{
    data: any
  }>
> {
  return request({
    method: 'DELETE',
    url: `/form/data/${form_id}`,
  }) as any
}

//根据资产信息查询是否存在资产（填写表单时使用）
export async function queryAsset(input: QueryAssetReq): Promise<AssetsResourceProto<{ data: QueryAssetRes[] }>> {
  return request({
    method: 'POST',
    url: `/form/data/asset`,
    data: input,
  }) as any
}

//保存单页数据
export async function saveSingleData(
  input: SaveSingleFormPageReq
): Promise<AssetsResourceProto<{ data: FormDataPageItem }>> {
  return request({
    method: 'POST',
    url: `/form/data/page`,
    data: input,
  }) as any
}

// 获取单页数据
export async function getSinglePageData(uid: string): Promise<
  AssetsResourceProto<{
    data: FormDataPageItem
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/page/${uid}`,
  }) as any
}

//更新单页数据
export async function updateSinglePageData(input: UpdateSinglePageDataReq): Promise<
  AssetsResourceProto<{
    data: FormDataPageItem
  }>
> {
  return request({
    method: 'PUT',
    url: `/form/data/page`,
    data: input,
  }) as any
}

// submit一个formData(formId对应下的所有pageData)
export async function submitFormData(form_id: string): Promise<
  AssetsResourceProto<{
    data: any
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/submit/${form_id}`,
  }) as any
}
// 根据formId撤销一个formData 的submit
export async function cancelSubmitFormData(form_id: string): Promise<
  AssetsResourceProto<{
    data: any
  }>
> {
  return request({
    method: 'GET',
    url: `/form/data/submit/back/${form_id}`,
  }) as any
}

// 获得已提交,但无资产的formData List
export async function getNoAseetFormDatas(
  input: GetNoAssetFormDatasReq
): Promise<AssetsResourceProto<{ data: NoAssetFormDataPageItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/form/data/submitted/noasset`,
    data: input,
  }) as any
}

//为已提交,但无资产的formData,创建资产并绑定
export async function bindAsset4NoAssetFormData(input: {
  uid: string
}): Promise<AssetsResourceProto<{ data: string }>> {
  return request({
    method: 'POST',
    url: `/form/data/submitted/noasset/new`,
    data: input,
  }) as any
}

// 删除 已提交,但无资产的formData
export async function deleteNoAssetFormData(input: { uids: string[] }): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'delete',
    url: `/form/data/submitted/noasset`,
    data: input,
  }) as any
}

// 删除 已提交,但无资产的formData
export async function getFormStatisticsDataById(
  input: GetFormStatisticsDataByIdReq
): Promise<AssetsResourceProto<{ data: FormHeadDataItem[]; total: number }>> {
  return request({
    method: 'POST',
    url: `/form/statistics/asset-form/list`,
    data: input,
  }) as any
}
