import * as React from 'react'
import { Steps, message } from 'antd'
import CreateTemplateStepOne from '../components/CreateTempalteStepOne'
import CreateTemplateStepTwo from '../components/CreateTemplateStepTwo'
import CreateTemplateStepThree from '../components/CreateTemplateStepThree'
import CreateTemplateStepFour from '../components/CreateTemplateStepFour'
import CreateTemplateStepFive from '../components/CreateFormTemplateComplete'
import * as FormRequests from '@/requests/form'
const { Step } = Steps

const steps = [
  { title: '表单信息', content: CreateTemplateStepOne },
  { title: '选择资产状态', content: '2' },
  { title: '表单格式', content: '3' },
  { title: '自动规则', content: '4' },
  { title: '完成创建', content: '5' },
]

const CreateFormTemplate = () => {
  const [current, setCurrent] = React.useState(0)
  const [formName, setFormName] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [formTemplateId, setFormTemplateId] = React.useState('')
  const [versionName, setVersionName] = React.useState('')
  const [fields, setFields] = React.useState<FormTemplateFieldItem[]>([])
  const [fetching, setFetching] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [formUid, setFormUid] = React.useState('')

  const docreateFormTemplate = async () => {
    setFetching(true)
    const res = await FormRequests.createFormTemplate({
      ...({} as any),
      form_name: formName,
      form_template_id: formTemplateId,
      description: description,
      version_name: versionName,
      fields: fields.map((field, _index) => {
        return {
          field_index: _index,
          field_type: field.field_type,
          asset_name: field.asset_name,
          asset_comps: field.asset_comps,
          status_name: field.status_name,
          default_status_value: field.default_status_value,
          is_confirmed: field.is_confirmed,
          status_value_type: field.status_value_type,
          label: field.label,
          notes: field.notes,
          is_private: field.is_private,
          validation: field.validation,
          multiple_choice: field.multiple_choice,
          allow_other: field.allow_other,
          v_asset_comps: field.v_asset_comps,
          v_asset_comps_regulation: field.v_asset_comps_regulation,
        }
      }),
    })
    if (res.code === 0) {
      message.success('创建成功')
      setFormUid(res.data.uid)
    } else {
      message.error(res.message)
      setIsError(true)
    }
    setFetching(false)
  }
  return (
    <div style={{ backgroundColor: '#ffffff', padding: 16 }}>
      <Steps current={current} style={{ padding: 20, minWidth: 600 }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title}></Step>
        ))}
      </Steps>
      <div
        className="create-form-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          <div style={{ display: current === 0 ? 'block' : 'none' }}>
            <CreateTemplateStepOne
              setCurrent={setCurrent}
              setDescription={setDescription}
              setFormName={setFormName}
              setFormTemplateId={setFormTemplateId}
              setVersionName={setVersionName}
            />
          </div>
        }
        {
          <div style={{ display: current === 1 ? 'block' : 'none' }}>
            <CreateTemplateStepTwo setCurrent={setCurrent} setFields={setFields} fields={fields} />
          </div>
        }
        {
          <div
            style={{
              display: current === 2 ? 'block' : 'none',
              width: '100%',
            }}
          >
            <CreateTemplateStepThree setCurrent={setCurrent} setFields={setFields} fields={fields} />
          </div>
        }
        {
          <div
            style={{
              display: current === 3 ? 'block' : 'none',
              width: '100%',
            }}
          >
            <CreateTemplateStepFour
              setCurrent={setCurrent}
              setFields={setFields}
              fields={fields}
              docreateFormTemplate={docreateFormTemplate}
            />
          </div>
        }
        {
          <div
            style={{
              display: current === 4 ? 'block' : 'none',
              width: '100%',
            }}
          >
            <CreateTemplateStepFive fetching={fetching} isError={isError} formUid={formUid} />
          </div>
        }
      </div>
    </div>
  )
}

export default CreateFormTemplate
