import React from 'react'
import { Drawer, Form, Divider, Tag, message, Tooltip, Button, Modal, Input, Spin } from 'antd'
import {} from '@ant-design/icons'
import { DrawerProps } from 'antd/lib/drawer'
import classnames from 'classnames'
import * as defectRequests from '@/requests/defect'
import CopiableControl from '@/ui-components/CopiableControl'

export default function DrawerAssetEditor({
  status_defect_uid,
  children,
  ...props
}: React.PropsWithChildren<{
  status_defect_uid: string
  children?: React.ReactNode
}> &
  DrawerProps) {
  const [data, setData] = React.useState<DefectItem | undefined>(undefined)
  const [updateDefectModalVisible, setUdateDefectModalVisible] = React.useState(false)
  const [bindAssetFormVisible, setBindAssetFormVisible] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [submiting, setSubmiting] = React.useState(false)

  const updateDefectFormEl = React.useRef(null) as any
  const bindAssetFormEl = React.useRef(null) as any
  const [updateDefectForm] = Form.useForm()

  const handleUpdateDefectOK = (values: any) => {
    console.log(values)
  }
  const handleBindAssettOK = (e: any) => {
    bindAssetFormEl.current.props.onSubmit(e)
  }

  const fetchData = async () => {
    if (!status_defect_uid) return
    const res = await defectRequests.getDefectById(status_defect_uid)
    if (res.code === 0) {
      setData(res.data)
    } else {
      message.error(res.message)
    }
    setFetching(false)
  }

  const closeDefect = () => {
    if (!status_defect_uid) return
    Modal.confirm({
      title: '提示',
      content: '确认关闭这条缺陷吗？',
      onOk: async () => {
        const res = await defectRequests.updateDefect({
          status_defect_uid,
          close: true,
        })
        if (res.code === 0) {
          message.success('关闭成功')
          fetchData()
        } else {
          message.error(res.message)
        }
      },
      okText: '确认',
      cancelText: '取消',
    })
  }
  React.useEffect(() => {
    setFetching(true)
    fetchData()
  }, [status_defect_uid])

  // const UpdateDefectForm = Form.create<FormComponentProps>({
  //   name: "update-status-name-and-value-form",
  // })((props: FormComponentProps) => {
  //   const handleSubmit = async (e: any) => {
  //     e.preventDefault();
  //     props.form.validateFields(async (err, values) => {
  //       if (!err) {
  //         setSubmiting(true);
  //         const res = await defectRequests.updateDefect({
  //           status_defect_uid: status_defect_uid,
  //           status_name: values["status_name"],
  //           status_value: values["status_value"],
  //         });
  //         if (res.code === 0) {
  //           message.success("修改成功");
  //           setUdateDefectModalVisible(false);
  //           fetchData();
  //         } else {
  //           message.error(res.message);
  //         }
  //         setSubmiting(false);
  //       }
  //     });
  //   };

  //   const { getFieldDecorator } = props.form;
  //   return data ? (
  //     <Form
  //       ref={updateDefectFormEl}
  //       onSubmit={handleSubmit}
  //       {...formItemLayout}
  //     >
  //       <Form.Item label="资产状态名称">
  //         {getFieldDecorator("status_name", {
  //           rules: [{ required: true, message: "请输入资产状态名称！" }],
  //           initialValue: data.status_name ? data.status_name : "",
  //         })(<Input />)}
  //       </Form.Item>
  //       <Form.Item label="资产状态值">
  //         {getFieldDecorator("status_value", {
  //           rules: [{ required: true, message: "请输入资产状态值！" }],
  //           initialValue: data.status_value ? data.status_value : "",
  //         })(<Input />)}
  //       </Form.Item>
  //     </Form>
  //   ) : null;
  // });

  // const BindAssetForm = Form.create<FormComponentProps>({
  //   name: "bind-asset-form",
  // })((props: FormComponentProps) => {
  //   const handleSubmit = async (e: any) => {
  //     e.preventDefault();
  //     props.form.validateFields(async (err, values) => {
  //       if (!err) {
  //         setSubmiting(true);
  //         const res = await defectRequests.bindDefect2Asset({
  //           status_defect_uid: status_defect_uid,
  //           asset_id: values["asset_id"],
  //         });
  //         if (res.code === 0) {
  //           message.success("绑定成功");
  //           setBindAssetFormVisible(false);
  //           fetchData();
  //         } else {
  //           message.error(res.message);
  //         }
  //         setSubmiting(false);
  //       }
  //     });
  //   };
  //   const { getFieldDecorator } = props.form;
  //   return data ? (
  //     <Form ref={bindAssetFormEl} onSubmit={handleSubmit} {...formItemLayout}>
  //       <Form.Item label="资产编号">
  //         {getFieldDecorator("asset_id", {
  //           rules: [{ required: true, message: "请输入资产编号！" }],
  //         })(<Input placeholder="请输入资产编号" />)}
  //       </Form.Item>
  //     </Form>
  //   ) : null;
  // });

  return (
    <Drawer
      title={
        !fetching && data ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <span className="drawer-title">{data.status_name}</span>

              <span
                className="drawer-gray-btn"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setUdateDefectModalVisible(true)
                }}
              >
                编辑
              </span>

              <Divider type="vertical" />
              <CopiableControl style={{ cursor: 'pointer' }} content={data.status_name}>
                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
              <Divider type="vertical" />
              <span
                className="drawer-gray-btn"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setBindAssetFormVisible(true)
                }}
              >
                绑定资产
              </span>
              <Divider type="vertical" />
              <span
                className="drawer-gray-btn"
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  closeDefect()
                }}
              >
                关闭
              </span>
            </div>
          </div>
        ) : (
          ''
        )
      }
      width={'50vw'}
      drawerStyle={{ minWidth: 900 }}
      visible={!!status_defect_uid}
      {...props}
      destroyOnClose={true}
      className={classnames('drawer-asset-viewer-wrapper', props.className)}
    >
      <Spin spinning={fetching} style={{ position: 'absolute', right: '50%', top: '50%' }} tip="加载中..." />
      {!data || fetching ? null : (
        <main className="drawer-container">
          <Form>
            <Form.Item label="缺陷编号" className="flex-form-item__vertical">
              <CopiableControl style={{ cursor: 'pointer' }} content={data.uid}>
                <Tooltip title="点击可复制">
                  <span style={{ marginRight: 10 }}> {data.uid}</span>
                  <CopiableControl style={{ cursor: 'pointer' }} content={data.uid}>
                    <span className="drawer-gray-btn">复制</span>
                  </CopiableControl>
                </Tooltip>
              </CopiableControl>
            </Form.Item>
          </Form>
          <Form layout="inline">
            <Form.Item label="缺陷等级" style={{ paddingRight: 50 }}>
              <div>
                {data.defect_name.map((x, idx, arr) => {
                  return (
                    <Tag color="red" key={idx}>
                      {x}
                    </Tag>
                  )
                })}
              </div>
            </Form.Item>
            <Form.Item label="缺陷状态" style={{ paddingRight: 50 }}>
              <div>
                {data.processing_status === 1 && <Tag color="yellow">待处理</Tag>}
                {data.processing_status === 2 && <Tag color="#108ee9">待验收</Tag>}
                {data.processing_status === 3 && <Tag color="#87d068">已关闭</Tag>}
              </div>
            </Form.Item>
          </Form>
          <Form>
            <Form.Item label="资产名称" className="flex-form-item__vertical">
              <CopiableControl style={{ cursor: 'pointer' }} content={data.asset_name}>
                <span style={{ marginRight: 10 }}>{data.asset_name}</span>
                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </Form.Item>
            <Form.Item label="资产组件" className="flex-form-item__vertical">
              <div>
                {data.asset_comps.map((x, idx, arr) => {
                  return (
                    <Tag color="cyan" key={idx}>
                      {x}
                    </Tag>
                  )
                })}
                <CopiableControl style={{ cursor: 'pointer' }} content={data.asset_comps.toString()}>
                  <span className="drawer-gray-btn">复制</span>
                </CopiableControl>
              </div>
            </Form.Item>
            <Form.Item
              label="地理信息"
              className="flex-form-item__vertical"
              extra={
                <div style={{ marginTop: 8 }}>
                  {data.v_asset_status.map((x, v_index) => {
                    return (
                      <Tag
                        key={v_index + x.v_status_name + x.v_status_value}
                        style={{ cursor: 'pointer' }}
                        color="orange"
                      >{`${x.v_status_name}：${x.v_status_value}`}</Tag>
                    )
                  })}
                </div>
              }
            >
              {data.v_asset_name ? (
                <Tag color="geekblue" style={{ fontSize: 12 }}>
                  {data.v_asset_name}
                </Tag>
              ) : (
                <span style={{ marginRight: 10 }}>无</span>
              )}
              <CopiableControl
                style={{ cursor: 'pointer' }}
                content={`${data.v_asset_name},${data.v_asset_status.map(
                  (x) => x.v_status_name + '：' + x.v_status_value
                )}`}
              >
                <span className="drawer-gray-btn">复制</span>
              </CopiableControl>
            </Form.Item>
          </Form>
          <div
            style={{
              padding: '16px 16px 0 16px',
              border: '1px solid #3D9CEB',
              borderRadius: 10,
              position: 'relative',
              marginTop: 24,
            }}
          >
            <div
              style={{
                width: 50,
                padding: '2px 0',
                borderRadius: 13,
                backgroundColor: '#3D9CEB',
                textAlign: 'center',
                color: '#ffffff',
                position: 'absolute',
                top: -13,
              }}
            >
              记录
            </div>
            <Form layout="inline">
              <Form.Item label="记录人" style={{ paddingRight: 80 }}>
                {data.recorder_user_name ? data.recorder_user_name : '-'}
              </Form.Item>
              <Form.Item label="记录人ID" style={{ paddingRight: 80 }}>
                {data.recorder_user_id ? data.recorder_user_id : '-'}
              </Form.Item>
              <Form.Item label="记录时间" style={{ paddingRight: 80 }}>
                {data.record_time ? data.record_time : '-'}
              </Form.Item>
            </Form>
            <Form layout="inline">
              <Form.Item label="记录缺陷值" className="flex-form-item__vertical">
                {data.status_value}
              </Form.Item>
            </Form>
          </div>

          <div
            style={{
              padding: '16px 16px 0 16px',
              border: '1px solid #F26872',
              borderRadius: 10,
              position: 'relative',
              marginTop: 24,
            }}
          >
            <div
              style={{
                width: 50,
                padding: '2px 0',
                borderRadius: 13,
                backgroundColor: '#F26872',
                textAlign: 'center',
                color: '#ffffff',
                position: 'absolute',
                top: -13,
              }}
            >
              处理
            </div>
            <Form layout="inline">
              <Form.Item label="处理人" style={{ paddingRight: 80 }}>
                {data.handler_user_name ? data.handler_user_name : '-'}
              </Form.Item>
              <Form.Item label="处理人ID" style={{ paddingRight: 80 }}>
                {data.handler_user_id ? data.handler_user_id : '-'}
              </Form.Item>
              <Form.Item label="处理时间" style={{ paddingRight: 80 }}>
                {data.handle_time ? data.handle_time : '-'}
              </Form.Item>
            </Form>
            <Form layout="inline">
              <Form.Item label="处理后状态值" className="flex-form-item__vertical">
                {data.status_value}
              </Form.Item>
            </Form>
            <Form layout="inline">
              <Form.Item label="任务链接" style={{ paddingRight: 50 }}>
                {'-'}
              </Form.Item>
            </Form>
          </div>

          <div
            style={{
              padding: '16px 16px 0 16px',
              border: '1px solid #3B9732',
              borderRadius: 10,
              position: 'relative',
              marginTop: 24,
            }}
          >
            <div
              style={{
                width: 50,
                padding: '2px 0',
                borderRadius: 13,
                backgroundColor: '#3B9732',
                textAlign: 'center',
                color: '#ffffff',
                position: 'absolute',
                top: -13,
              }}
            >
              验收
            </div>
            <Form layout="inline">
              <Form.Item label="验收人" style={{ paddingRight: 80 }}>
                {data.acceptor_user_name ? data.acceptor_user_name : '-'}
              </Form.Item>
              <Form.Item label="验收人ID" style={{ paddingRight: 80 }}>
                {data.acceptor_user_id ? data.acceptor_user_id : '-'}
              </Form.Item>
              <Form.Item label="验收时间" style={{ paddingRight: 80 }}>
                {data.acceptance_time ? data.acceptance_time : '-'}
              </Form.Item>
            </Form>
            <Form layout="inline">
              <Form.Item label="验收状态值" className="flex-form-item__vertical">
                {data.status_value}
              </Form.Item>
            </Form>
            <Form layout="inline">
              <Form.Item label="任务链接" style={{ paddingRight: 50 }}>
                {'-'}
              </Form.Item>
            </Form>
          </div>

          <Modal
            visible={updateDefectModalVisible}
            destroyOnClose
            title={
              <span>
                <div className="title-prefix"></div>修改缺陷
              </span>
            }
            okText={submiting ? '修改中' : '确认'}
            okButtonProps={{
              disabled: submiting,
              loading: submiting,
            }}
            cancelText="取消"
            onOk={handleUpdateDefectOK}
            onCancel={() => setUdateDefectModalVisible(false)}
          >
            {/* <UpdateDefectForm /> */}
          </Modal>
          <Modal
            visible={bindAssetFormVisible}
            title={
              <span>
                <div className="title-prefix"></div>绑定资产
              </span>
            }
            okText={submiting ? '提交中' : '绑定'}
            okButtonProps={{ disabled: submiting, loading: submiting }}
            cancelText="取消"
            onOk={handleBindAssettOK}
            onCancel={() => setBindAssetFormVisible(false)}
          >
            {/* <BindAssetForm /> */}
          </Modal>
        </main>
      )}
    </Drawer>
  )
}
