import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { getAIModelList, getMyDetectRecords, getModelHistoryList, deleteModel } from '@/requests/AIDetection';
import { message, Button, Form, Input, List, Tooltip, Popover, Checkbox, Divider, Modal } from 'antd';
import { DeleteOutlined, HistoryOutlined, SearchOutlined, UnorderedListOutlined } from '@ant-design/icons';
import '../styles/modelList.less';

const AIModelList = (props: any, ref: any) => {
  const [data, setData] = React.useState<AIModelInfoItem[]>([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });
  const [historyPagination, setHistoryPagination] = React.useState({
    current: 1,
    pageSize: 8,
    total: 0,
  });
  const [myDetectRecordCount, setMyDetectRecordCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [historyData, setHistoryData] = React.useState<AIModelInfoItem[]>([]);
  const [historyLoading, setHistoryLoading] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState<AIModelInfoItem | null>(null);

  const history = useHistory();
  //获取模型列表
  const fetchModelList = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }
    setLoading(true);
    const res = await getAIModelList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setPagination({
        current: page,
        pageSize,
        total: res.total,
      });
      setData(res.data);
      setSelectedModel(res.data[0]);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  //获取模型历史列表
  const fetchModelHistoryList = async (page?: number, pageSize?: number) => {
    if (!selectedModel) return;
    if (!page) {
      page = historyPagination.current;
    }
    if (!pageSize) {
      pageSize = historyPagination.pageSize;
    }
    setHistoryLoading(true);
    const res = await getModelHistoryList({
      limit: pageSize,
      skip: (page - 1) * pageSize,
    });
    if (res.code === 0) {
      setHistoryPagination({
        current: page,
        pageSize,
        total: res.total + 1,
      });
      setHistoryData(data.concat(res.data));
    } else {
      message.error(res.message);
    }
    setHistoryLoading(false);
  };
  //获取我的检测记录数
  const fetchMyDetectRecordCount = async () => {
    setLoading(true);
    const res = await getMyDetectRecords({
      limit: 100000000,
      skip: 0,
    });
    if (res.code === 0) {
      setMyDetectRecordCount(res.total);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };

  //删除模型
  const doDeleteModel = async (model_id: string) => {
    const res = await deleteModel(model_id);
    if (res.code === 0) {
      message.success('删除成功');
      fetchModelList();
    } else {
      message.error(res.message);
    }
  };

  React.useEffect(() => {
    fetchModelList();
    fetchMyDetectRecordCount();
  }, []);
  if (!selectedModel) return null;
  return (
    <div className="model-list-page">
      <div className="tools-wrapper">
        <div className="addr-list-filter-input-container">
          <Form layout="inline">
            <Form.Item label="名称" className="filter-item">
              <Input placeholder="输入模型名称" onChange={(e) => {}} />
            </Form.Item>
            <Button onClick={() => fetchModelList()} icon={<SearchOutlined />}>
              搜索
            </Button>
          </Form>
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            type="primary"
            onClick={() => {
              history.push('/workbench/ai/model-list/detect-records/my');
            }}
            icon={<HistoryOutlined />}
          >
            我的检测记录:{myDetectRecordCount}
          </Button>
        </div>
      </div>
      <div className="model-list-container">
        <List
          dataSource={[selectedModel]}
          loading={loading}
          renderItem={(item) => (
            <List.Item
              extra={[
                <span>
                  <Tooltip title={`模型检测记录数量：${item.ai_detect_data_num}`}>
                    <UnorderedListOutlined style={{ color: '#f39800', marginRight: 4 }} />
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({
                          pathname: '/workbench/ai/model-list/detect-records',
                          state: {
                            model_id: item.model_id,
                          },
                          search: `?model_id=${item.model_id}`,
                        });
                      }}
                    >
                      {`检测记录(${item.ai_detect_data_num})`}
                    </a>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title={`删除模型`}>
                    <DeleteOutlined style={{ color: '#f39800', marginRight: 4 }} />
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                          title: '删除模型',
                          content: '确定删除该模型吗？',
                          okText: '确定',
                          cancelText: '取消',
                          onOk: () => {
                            doDeleteModel(item.model_id);
                          },
                        });
                      }}
                    >
                      {`删除模型`}
                    </a>
                  </Tooltip>
                </span>,
                <Button
                  style={{ marginLeft: 16, borderRadius: 5 }}
                  type={'primary'}
                  onClick={() => {
                    history.push({
                      pathname: '/workbench/ai/model-list/detection',
                      state: {
                        model: selectedModel,
                      },
                      search: `?model_id=${item.model_id}`,
                    });
                  }}
                >
                  开始检测
                </Button>,
              ]}
              style={{ marginBottom: 16, backgroundColor: '#fff', padding: 16, borderRadius: 5 }}
            >
              <List.Item.Meta
                title={
                  <div>
                    <span>{item.custom_model_name || '模型名称，用户自定义'}</span>
                    <Popover
                      trigger={'click'}
                      placement="bottomRight"
                      onVisibleChange={(visible) => {
                        if (visible) {
                          fetchModelHistoryList();
                        }
                      }}
                      getPopupContainer={(triggerNode) =>
                        document.getElementsByClassName('model-list-page')[0] as HTMLElement
                      }
                      // visible={historyVisible}
                      content={
                        <List
                          style={{ width: 1000 }}
                          loading={historyLoading}
                          pagination={{
                            ...historyPagination,
                            onChange: (page, pageSize) => {
                              fetchModelHistoryList(page, pageSize);
                            },
                            showTotal: (total) => `共${total}条`,
                          }}
                          dataSource={historyData}
                          renderItem={(_item) => (
                            <List.Item
                              extra={[
                                <Button
                                  style={{ marginLeft: 16, borderRadius: 5 }}
                                  type={'primary'}
                                  onClick={() => {
                                    if (_item.ai_detect_data_num === 0) {
                                      message.error('该模型暂无检测记录');
                                      return;
                                    }
                                    history.push({
                                      pathname: '/workbench/ai/model-list/detect-records',
                                      state: {
                                        model_id: _item.model_id,
                                      },
                                      search: `?model_id=${_item.model_id}`,
                                    });
                                  }}
                                >
                                  检测记录：{_item.ai_detect_data_num}
                                </Button>,
                              ]}
                              style={{ marginBottom: 16, backgroundColor: '#fff', padding: 16, borderRadius: 5 }}
                            >
                              <List.Item.Meta
                                title={
                                  <div>
                                    <span>
                                      {selectedModel && (
                                        <Checkbox
                                          checked={_item.model_id === selectedModel.model_id}
                                          style={{ marginRight: 8 }}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setSelectedModel(_item);
                                              message.success(`已切换模型版本为${_item.model_id}`);
                                            }
                                          }}
                                        />
                                      )}
                                      {_item.custom_model_name || '模型名称，用户自定义'}
                                    </span>
                                  </div>
                                }
                                description={
                                  <div>
                                    <div>
                                      <span style={{ marginRight: 16 }}>
                                        测试集比例：<span>{_item.test_frac}</span>
                                      </span>
                                      <span style={{ marginRight: 16 }}>
                                        thickness： <span>{_item.thickness}</span>
                                      </span>

                                      <span>
                                        迭代次数：<span>{_item.iterations}</span>
                                      </span>
                                    </div>
                                    <div>
                                      <span style={{ marginRight: 16 }}>模型编号：{_item.model_id}</span>
                                      <span>模型文件：{_item.model_name}</span>
                                    </div>
                                    <div>
                                      <span>
                                        训练任务时间：<span style={{ marginRight: 16 }}>{_item.create_time}</span>
                                      </span>
                                      <span>模型生成日期：{_item.create_time}</span>
                                    </div>
                                  </div>
                                }
                              ></List.Item.Meta>
                            </List.Item>
                          )}
                        ></List>
                      }
                    >
                      <Tooltip title="历史模型版本">
                        <HistoryOutlined style={{ color: '#f39800', marginLeft: 8 }} onClick={() => {}} />
                      </Tooltip>
                    </Popover>
                  </div>
                }
                description={
                  <div>
                    <div>
                      <span style={{ marginRight: 16 }}>
                        测试集比例：<span>{item.test_frac}</span>
                      </span>
                      <span style={{ marginRight: 16 }}>
                        thickness： <span>{item.thickness}</span>
                      </span>

                      <span>
                        迭代次数：<span>{item.iterations}</span>
                      </span>
                    </div>
                    <div>
                      <span style={{ marginRight: 16 }}>模型编号：{item.model_id}</span>
                      <span>模型文件：{item.model_name}</span>
                    </div>
                    <div>
                      <span>
                        训练任务时间：<span style={{ marginRight: 16 }}>{item.create_time}</span>
                      </span>
                      <span>模型生成日期：{item.create_time}</span>
                    </div>
                  </div>
                }
              ></List.Item.Meta>
            </List.Item>
          )}
        ></List>
      </div>
    </div>
  );
};

export default AIModelList;
