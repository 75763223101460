/// <reference path="type.d.ts" />
/// <reference path="pictures.d.ts" />

import request from './_base'

/**
 * 上传资产图片
 */
export async function uploadAssetPic(input: any): Promise<
  AssetsResourceProto<{
    data: string
  }>
> {
  const formData = new FormData()
  for (let key in input) {
    formData.append(key, input[key])
  }
  return request({
    method: 'POST',
    url: `/pic/upload/asset`,
    data: formData,
  }) as any
}

/**
 * 上传资产状态图片
 */
export async function uploadStatusPic(input: any): Promise<
  AssetsResourceProto<{
    data: string
  }>
> {
  const formData = new FormData()
  for (let key in input) {
    formData.append(key, input[key])
  }
  return request({
    method: 'POST',
    url: `/pic/upload/status`,
    data: formData,
  }) as any
}

/**
 * 根据图片uid假删除图片 后台任务会在90天后真实删除assetPic和oss中的图片信息
 * @param  {string} uid 图片uid
 */
export async function deletePic(uid: string): Promise<AssetsResourceProto<{ data: any }>> {
  return request({
    method: 'DELETE',
    url: `/pic/${uid}`,
  }) as any
}
