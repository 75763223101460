import React from 'react';
import { message } from 'antd';

function selectInput(el: HTMLInputElement | HTMLTextAreaElement) {
  el.select();
}

const execCmd = function (cmdName: 'copy' | 'cut' = 'copy') {
  try {
    const successful = document.execCommand(cmdName);
    if (successful) {
      message.success('复制成功');
    }
  } catch (err) {
    console.log('Oops, unable to copy');
  }
};

function CopiableControl(
  {
    as: _as = 'span',
    actionType = 'copy',
    triggerType = 'click',
    content = '',
    children,
    ...props
  }: React.PropsWithChildren<{
    as?: any;
    actionType?: 'copy' | 'cut';
    triggerType?: 'click' | 'hover';
    content?: string;
    children: React.ReactNode;
  }> &
    React.HTMLAttributes<any>,
  ref: any
) {
  const El = _as;

  const $pRef = React.useRef<HTMLParagraphElement>(null);
  const $textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const doCopy = () => {
    if (!$textAreaRef.current) return;

    selectInput($textAreaRef.current);
    execCmd();
  };

  const doCut = () => {
    if (!$textAreaRef.current) return;

    selectInput($textAreaRef.current);
    execCmd('cut');
  };

  const styles = {
    position: 'fixed' as 'fixed',
    top: -9999,
    left: -9999,
    height: 1,
    width: 1,
    overflow: 'hidden',
  };

  return (
    <>
      <El
        onClick={() => {
          switch (actionType) {
            case 'copy':
              $pRef.current && $pRef.current.click();
              break;
            case 'cut':
              $textAreaRef.current && $textAreaRef.current.click();
              break;
          }
        }}
        {...props}
      >
        {children}
      </El>
      <textarea ref={$textAreaRef} onClick={() => doCut()} value={content} style={styles} />
      <p ref={$pRef} onClick={() => doCopy()} style={styles}>
        {content}
      </p>
    </>
  );
}

export default React.forwardRef(CopiableControl);
