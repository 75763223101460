import * as React from 'react'
import { Popover, message, Spin } from 'antd'
import * as userRequests from '@/requests/user'
import { QuestionCircleFilled } from '@ant-design/icons'

interface PropsType {
  user_id: UserInfoItem['user_id']
}

const Content = (props: PropsType) => {
  const { user_id } = props
  const [data, setData] = React.useState<CompanyUserIdInfoItem | undefined>(undefined)
  const [loading, setLoading] = React.useState(false)
  const fetchData = async () => {
    if (!user_id) return
    const res = await userRequests.getUserCompanyIDInfo({
      user_ids: [user_id],
    })
    const { data, code } = res
    if (code === 0) {
      if (data.length > 0) {
        setData(res.data[0])
      }
    } else {
      message.error('网络错误')
    }
    setLoading(false)
  }

  React.useEffect(() => {
    setLoading(true)
    fetchData()
  }, [])

  return (
    <div style={{ minWidth: 200, minHeight: 100, position: 'relative' }}>
      <Spin spinning={loading} tip="正在加载..." style={{ position: 'absolute', top: '50%', right: '50%' }}></Spin>
      {data && (
        <>
          <p>{`用户名：${data.username}`}</p>
          <p>{`工号：${data.company_user_id}`}</p>
          <p>{`用户id：${data.user_id}`}</p>
        </>
      )}
    </div>
  )
}

const SingleUserCompanyIdInfoTooltip = (props: PropsType) => {
  return (
    <div style={{ display: 'inline', paddingLeft: 4, paddingRight: 4 }}>
      <Popover placement="top" title={'用户信息'} content={<Content {...props} />} destroyTooltipOnHide>
        <QuestionCircleFilled
          style={{
            color: '#1691ff',
            fontSize: 15,
            marginLeft: 2,
          }}
        />
      </Popover>
    </div>
  )
}
export default SingleUserCompanyIdInfoTooltip
