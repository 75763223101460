/// <reference path="type.d.ts" />
/// <reference path="standard.d.ts" />

import request from './_base'

//新建标准结构体
export async function createStandardByForm(input: CreateStandardsReq): Promise<
  AssetsResourceProto<{
    data: Standards
  }>
> {
  return request({
    method: 'POST',
    url: `/status/standard/standards/new`,
    data: input,
  }) as any
}
//新建结构体中单条标准
export async function createStandardItem(input: CreateStandardReq): Promise<
  AssetsResourceProto<{
    data: Standards
  }>
> {
  return request({
    method: 'POST',
    url: `/status/standard/standard/new`,
    data: input,
  }) as any
}
//获取状态值标准list
export async function getStandardList(
  input: GetStandardListReq,
  pageNum: number,
  pageSize: number
): Promise<
  AssetsResourceProto<{
    data: Standards[]
    total: number
  }>
> {
  return request({
    method: 'POST',
    url: `/status/standard/standards/list/find/${pageNum}/${pageSize}`,
    data: input,
  }) as any
}
//删除单个结构体
export async function deleteStandard(uid: string): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    url: `/status/standard/standards/delete/${uid}`,
  }) as any
}
//删除单条标准
export async function deleteStandardItem(uid: string): Promise<AssetsResourceProto<{ data: Standards }>> {
  return request({
    method: 'DELETE',
    url: `/status/standard/standard/delete/${uid}`,
  }) as any
}
//设置某个range为defect(缺陷)

export async function setDefect(input: SetDefectReq): Promise<AssetsResourceProto<{ data: Standards }>> {
  return request({
    method: 'POST',
    url: `/status/standard/defect`,
    data: input,
  }) as any
}

//**************差值标准*******************

export async function getDiffStandardList(
  input: GetStandardListReq,
  pageNum?: number,
  pageSize?: number
): Promise<
  AssetsResourceProto<{
    data: DiffStandards[]
    total: number
  }>
> {
  if (!pageNum) {
    pageNum = 1
  }
  if (!pageSize) {
    pageSize = 10
  }
  return request({
    method: 'POST',
    url: `/status/diff/standards/list/find/${pageNum}/${pageSize}`,
    data: input,
  }) as any
}

export async function deleteDiffStandard(uid: string): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    url: `/status/diff/standards/delete/${uid}`,
  }) as any
}

export async function deleteDiffStandardItem(uid: string): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'DELETE',
    url: `/status/diff/standard/delete/${uid}`,
  }) as any
}

export async function createDiffStandardItem(input: {
  standard_condition_uid: string
  standard_name: string
  ranges: any
  user_id: string
  user_name: string
  task_id: string
  form_id: string
}): Promise<
  AssetsResourceProto<{
    standard: DiffStandards
  }>
> {
  if (!input.form_id) input.form_id = 'not form'
  // if (!input.commemt) input.commemt = "9528";
  if (!input.task_id) input.task_id = 'crete-diff-standard-item'

  return request({
    method: 'POST',
    url: `/status/diff/standard/new`,
    data: input,
  }) as any
}

export async function createDiffStandardByForm(input: DiffStandards): Promise<
  AssetsResourceProto<{
    data: DiffStandards
  }>
> {
  return request({
    method: 'POST',
    url: `/status/diff/standards/new`,
    data: input,
  }) as any
}

/**
 * 更新单条standard的range和name
 * @param  {UpdateSingleStandardReq} input 用户输入
 */

export async function UpdateSingleStandard(input: UpdateSingleStandardReq): Promise<AssetsResourceProto<{}>> {
  return request({
    method: 'PUT',
    url: `/status/standard/standard`,
    data: input,
  }) as any
}

/**
 * 更新整个标准结构体
 * @param  {UpdateStandardsReq} input 用户输入
 */

export async function UpdateStandards(input: UpdateStandardsReq): Promise<AssetsResourceProto<{ data: Standards }>> {
  return request({
    method: 'PUT',
    url: `/status/standard/standards`,
    data: input,
  }) as any
}
