import * as React from 'react'
import 'react-chat-elements/dist/main.css'
import { Avatar, Input, Button, message, List, Form, Tag } from 'antd'
import { ManOutlined, WomanOutlined } from '@ant-design/icons'
import './index.less'
import * as UserRequests from '@/requests/user'

interface PropsType {
  sendTrunIntoSingleNotice: (targetId: string) => void
  setRoute: Function
}

const Contacts = (props: PropsType) => {
  const { sendTrunIntoSingleNotice, setRoute } = props
  const [users, setUsers] = React.useState<UserInfoItem[]>([])
  const [filterUserName, setFilterUserName] = React.useState('')
  const [currentUser, setCurrentUser] = React.useState<UserInfoItem | undefined>(undefined)
  const fetchAllUser = async () => {
    const res = await UserRequests.getAllUsers({
      limit: 10000,
      skip: 0,
      username: filterUserName,
    })
    if (res.code === 0) {
      setUsers(res.data)
      if (res.data.length > 0) {
        setCurrentUser(res.data[0])
      }
    } else {
      message.error(res.message)
    }
  }

  React.useEffect(() => {
    fetchAllUser()
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: 250,
          borderRight: '1px solid #e8e8e8',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 16,
          }}
        >
          <Input.Search
            value={filterUserName}
            onChange={(e) => {
              setFilterUserName(e.target.value)
            }}
            placeholder="搜索用户"
            onSearch={(value) => fetchAllUser()}
            size={'small'}
          />
        </div>
        <div
          style={{
            overflow: 'auto',
            height: 644,
          }}
        >
          {currentUser && (
            <List
              dataSource={users}
              split={false}
              renderItem={(item) => {
                return (
                  <List.Item
                    key={item.user_id}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: item.user_id === currentUser.user_id ? '#F5F5F5' : 'white',
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                    onClick={() => setCurrentUser(item)}
                  >
                    <List.Item.Meta
                      avatar={
                        item.avatar ? (
                          <Avatar src={`/v2/api/user/avatar/${item.avatar}`} shape="square" />
                        ) : (
                          <Avatar shape="square" style={{ backgroundColor: '#1890ff' }}>
                            {item.username.slice(0, 1)}
                          </Avatar>
                        )
                      }
                      title={item.username}
                    />
                  </List.Item>
                )
              }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: 900,
        }}
      >
        {currentUser && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 100,
            }}
          >
            {currentUser.avatar ? (
              <Avatar src={`/v2/api/user/avatar/${currentUser.avatar}`} shape="square" size={80} />
            ) : (
              <Avatar shape="square" style={{ backgroundColor: '#1890ff' }} size={80}>
                {currentUser.username.slice(0, 1)}
              </Avatar>
            )}
            <h3 style={{ padding: 16, color: '#24292e' }}>
              {currentUser.username}{' '}
              {currentUser.gender === 'male' && (
                <ManOutlined
                  style={{
                    marginLeft: 5,
                    fontSize: 14,
                    color: '#2db7f5',
                  }}
                />
              )}
              {currentUser.gender === 'female' && (
                <WomanOutlined
                  style={{
                    marginLeft: 5,
                    fontSize: 14,
                    color: 'pink',
                  }}
                />
              )}
            </h3>
            <Form>
              <Form.Item label="手机:" className="flex-form-item__vertical">
                {currentUser.phone}
              </Form.Item>
              <Form.Item label="邮箱:" className="flex-form-item__vertical">
                {currentUser.email}
              </Form.Item>
              <Form.Item label="职位:" className="flex-form-item__vertical">
                {currentUser.roles.length > 0
                  ? currentUser.roles.map((x, index) => (
                      <Tag color="blue" key={index}>
                        {x.name}
                      </Tag>
                    ))
                  : '无'}
              </Form.Item>
            </Form>
            <Button
              type="primary"
              style={{ borderRadius: 5, padding: '0 24px' }}
              onClick={() => {
                sendTrunIntoSingleNotice(currentUser.user_id)
                setRoute('chat')
              }}
            >
              发消息
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Contacts
