//项目文件列表页面
import * as React from 'react';
import { getProjectFiles, deleteProjectFlile, getFileListTree } from '@/requests/project';
import {
  UpCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Table, Button, Upload, notification, Modal, Tooltip } from 'antd';
import { useCtxState } from '@/App.store';
import { download } from '@/utils/string';
import FileViewer from '@/ui-components/FileViewer';
import '../styles/projectFileList.less';

interface PropsType {
  project: ProjectItem;
}
const ProjectFileList = (props: PropsType) => {
  const { project } = props;
  const [{ sysUsers }] = useCtxState();
  const [data, setData] = React.useState<TaskFileItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const _fetchData = async () => {
    const res = await getFileListTree({
      task_id: 41,
    });
  };

  const fetchData = async (page?: number, pageSize?: number) => {
    if (!page) {
      page = pagination.current;
    }
    if (!pageSize) {
      pageSize = pagination.pageSize;
    }

    setLoading(true);
    const res = await getProjectFiles({
      limit: pageSize,
      skip: (page - 1) * pageSize,
      project_id: project.id,
    });
    if (res.code === 0) {
      setData(res.data);
    }
    setLoading(false);
  };

  const doDelete = async (id: string) => {
    if (!id) return;
    if (!project) return;
    const res = await deleteProjectFlile(project.id, id);
    if (res.code === 0) {
      notification.open({
        message: '删除成功',
        description: '文件删除成功',
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
      });
      fetchData();
    } else {
      notification.open({
        message: '删除失败',
        description: '文件删除失败',
        icon: <WarningOutlined style={{ color: 'red' }} />,
      });
    }
  };

  React.useEffect(() => {
    fetchData();
    _fetchData();
  }, [project]);
  return (
    <div style={{ width: '65%', minWidth: 900, backgroundColor: '#ffffff', margin: 'auto' }}>
      <Table
        className="project-file-list"
        title={() => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ fontSize: 18 }}>我的文件</span>
              <Upload
                action={`/v2/api/team/task/uploadFile`}
                data={{ projectId: project.id }}
                onChange={(info) => {
                  if (info.file.status === 'done') {
                    if (info.file.response.code === 10001) {
                      notification.open({
                        message: '上传失败',
                        description: '不支持的文件类型',
                        icon: <WarningOutlined style={{ color: 'red' }} />,
                      });
                    } else {
                      notification.open({
                        message: '上传成功',
                        description: '文件关联成功',
                        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
                      });
                      fetchData();
                    }
                  }
                }}
                showUploadList={false}
              >
                <Button type="dashed" style={{ borderRadius: 5 }} icon={<UpCircleOutlined></UpCircleOutlined>}>
                  上传
                </Button>
              </Upload>
            </div>
          );
        }}
        dataSource={data}
        loading={loading}
        pagination={{
          ...pagination,
          onChange: (page, pageSize) => {
            fetchData(page, pageSize);
          },
          showTotal: (total) => {
            return `共${total}条`;
          },
          style: { marginRight: 16 },
          size: 'small',
        }}
        columns={[
          {
            title: '文件名',
            key: 'name',
            align: 'left',
            width: 500,
            render: (text, record) => {
              return (
                <div style={{ display: 'flex' }}>
                  <FileViewer file={record} key={record.file_uid} />{' '}
                  <span style={{ color: '#333', marginLeft: 8, fontWeight: 500 }}>{record.file_name}</span>
                </div>
              );
            },
          },
          {
            title: '创建日期',
            key: 'create_time',
            align: 'right',
            render: (text, record) => {
              return <span style={{ color: 'rgba(0,0,0,.45)' }}> {record.create_time}</span>;
            },
          },
          {
            title: '创建人',
            key: 'create_user',
            align: 'right',
            render: (text, record) => {
              const user = sysUsers.find((item: UserInfoItem) => item.id === record.create_user_id);
              return (
                <span style={{ color: 'rgba(0,0,0,.45)' }}> {record.create_user_id ? user?.username : '未知'}</span>
              );
            },
          },
          {
            title: '',
            key: 'action',
            align: 'right',
            render: (text, record) => {
              return (
                <div>
                  <Tooltip title="下载">
                    <DownloadOutlined
                      onClick={() => {
                        download(`/v2/api/team/task/downloadFile/${record.file_uid}`, record.file_name);
                      }}
                      style={{ marginRight: 16, color: 'rgba(0,0,0,.45)' }}
                    >
                      下载
                    </DownloadOutlined>
                  </Tooltip>
                  <Tooltip title="删除">
                    <DeleteOutlined
                      onClick={() => {
                        Modal.confirm({
                          title: '删除文件',
                          content: '确定删除该文件吗？',
                          onOk: () => {
                            doDelete(record.file_uid);
                          },
                        });
                      }}
                      style={{ marginRight: 16, color: 'rgba(0,0,0,.45)' }}
                    >
                      删除
                    </DeleteOutlined>
                  </Tooltip>

                  <Tooltip title="复制链接">
                    <LinkOutlined style={{ color: 'rgba(0,0,0,.45)' }}></LinkOutlined>
                  </Tooltip>
                </div>
              );
            },
          },
        ]}
      ></Table>
    </div>
  );
};

export default ProjectFileList;
