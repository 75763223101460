import * as React from 'react'
import './index.less'
import StandardHeader from './components/Header'
import { Tabs } from 'antd'
import { connect, useCtxState } from '@/App.store'
import Standards from './components/StatusStandard'
const { TabPane } = Tabs
export interface FilterConditionsType {
  asset_name: string
  standard_name: string
  status_name: string
}
const AssetStatusStandardHome = () => {
  const [{ userInfo }] = useCtxState()
  const [createStandardModalVisible, setCreateStandardModalVisible] = React.useState(false)
  const [filterConditions, setFilterConditions] = React.useState<FilterConditionsType>({
    status_name: '',
    standard_name: '',
    asset_name: '',
  })
  return (
    <div className="standard-container">
      <StandardHeader
        setCreateStandardModalVisible={setCreateStandardModalVisible}
        filterConditions={filterConditions}
        setFilterConditions={setFilterConditions}
      />
      <div className="standard-content-container">
        <Tabs defaultActiveKey="1">
          <TabPane tab="状态值标准" key="1">
            <div style={{ position: 'relative' }}>
              <Standards
                setCreateStandardModalVisible={setCreateStandardModalVisible}
                userInfo={userInfo}
                filterConditions={filterConditions}
                createStandardModalVisible={createStandardModalVisible}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default connect(AssetStatusStandardHome)
