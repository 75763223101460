import * as React from 'react'
import { Form, Input, Button, Row, Col, Radio, Switch, Select, InputNumber, DatePicker, Checkbox, message } from 'antd'
import {
  AlignLeftOutlined,
  CalendarOutlined,
  PicCenterOutlined,
  UnorderedListOutlined,
  PhoneOutlined,
  MailOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'

interface propsType {
  setCurrent: Function
  setFields: Function
  fields: FormTemplateFieldItem[]
}

const fieldTypeOptions = [
  { label: '单行文字', value: 'single_line_text', icon: <PicCenterOutlined /> },
  { label: '多行文字', value: 'paragraph_text', icon: <AlignLeftOutlined /> },
  { label: '单项选择', value: 'single_choice', icon: <UnorderedListOutlined /> },
  // { label: "多项选择", value: "multiple_choice", icon: "ordered-list" },
  { label: '日期', value: 'date', icon: <CalendarOutlined /> },
  // { label: "数字", value: "number", icon: "number" },
  // { label: "电话", value: "phone", icon: "phone" },
  // { label: "下拉框", value: "drop_down", icon: "down-square" },
]

const CreateTemplateStepThree = (props: propsType) => {
  const { fields, setCurrent, setFields } = props

  const [editingField, setEditingField] = React.useState(0)

  React.useEffect(() => {}, [])
  const preField = () => {
    if (editingField === 0) return
    setEditingField(editingField - 1)
  }

  const nextFiled = () => {
    if (editingField === fields.length - 1) return
    setEditingField(editingField + 1)
  }

  const getCheckBoxOptions = (field_index: number) => {
    if (!fields[field_index].multiple_choice) {
      return [
        { label: '选项一', value: 'Apple' },
        { label: '选项二', value: 'Pear' },
        { label: '选项三', value: 'Orange' },
      ]
    }
    return fields[field_index].multiple_choice.map((x) => {
      return {
        label: x,
        value: x,
      }
    })
  }

  return fields.length > 0 ? (
    <Row style={{ width: '100%' }} gutter={8}>
      <Col span={5} style={{ padding: 16 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h4>
            <div className="title-prefix"></div>
            字段类型
          </h4>
          <div>
            <Radio.Group
              buttonStyle="solid"
              style={{ textAlign: 'center' }}
              value={fields[editingField].field_type}
              onChange={(e) => {
                let newArr = fields.map((x) => x)
                newArr[editingField].field_type = e.target.value
                setFields(newArr)
              }}
            >
              {fieldTypeOptions.map((x) => {
                return (
                  <Radio.Button
                    value={x.value}
                    key={x.value}
                    style={{
                      margin: 5,
                      width: '45%',
                      textAlign: 'center',
                      minWidth: 130,
                    }}
                  >
                    {x.icon}
                    {x.label}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </div>
        </div>
      </Col>

      <Col
        span={14}
        style={{
          padding: 16,
          borderLeft: '2px solid #1890ff',
          borderRight: '2px solid #1890ff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '100%' }}>
          {fields.map((field, field_index) => {
            const fieldType = field.field_type
            return (
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  flexDirection: 'column',
                  justifyContent: 'center',

                  width: '100%',
                  border: editingField === field_index ? '1px dashed #1890ff' : '1px dashed #ffffff',
                  minHeight: 100,
                  marginBottom: 10,
                  padding: 20,
                  backgroundColor: editingField === field_index ? '#FCFCFC' : '#ffffff',
                }}
                key={field_index}
                onClick={() => setEditingField(field_index)}
              >
                <h4>{field.label}</h4>
                <p style={{ color: '#9D9D9D', fontSize: 13 }}>{field.asset_comps.toString().replace(/,/g, '-')}</p>
                {fieldType === 'single_line_text' && <Input disabled style={{ width: 400 }} placeholder="单行文本" />}
                {fieldType === 'paragraph_text' && (
                  <Input.TextArea disabled style={{ width: 400 }} placeholder="多行文本" rows={3} />
                )}

                {fieldType === 'phone' && (
                  <Input disabled style={{ width: 400 }} placeholder="电话" prefix={<PhoneOutlined />} />
                )}
                {fieldType === 'email' && (
                  <Input disabled style={{ width: 400 }} placeholder="email" prefix={<MailOutlined />} />
                )}
                {fieldType === 'number' && <InputNumber disabled style={{ width: 400 }} placeholder="数字" />}
                {fieldType === 'date' && <DatePicker disabled style={{ width: 400 }} />}
                {fieldType === 'drop_down' && <Select disabled style={{ width: 400 }} placeholder="下拉框" />}
                {fieldType === 'multiple_choice' && (
                  <Checkbox.Group options={getCheckBoxOptions(field_index)} disabled />
                )}
                {fieldType === 'single_choice' && <Radio.Group options={getCheckBoxOptions(field_index)} disabled />}
              </div>
            )
          })}
        </div>
        <div style={{}}>
          <Button onClick={() => setCurrent(1)} type="primary" style={{ marginRight: 20 }}>
            上一步
          </Button>
          <Button
            onClick={() => {
              setCurrent(3)
              console.log('xxxxxxxxx', fields)
            }}
            type="primary"
          >
            下一步
          </Button>
        </div>
      </Col>
      <Col
        span={5}
        style={{
          padding: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '100%' }}>
            <h4>
              <div className="title-prefix"></div>
              编辑字段
            </h4>
            <Form title="单行文字" style={{ width: '100%' }} layout="vertical">
              <Form.Item label="标题">
                <Input
                  value={fields[editingField].label}
                  onChange={(e) => {
                    let newArr = fields.map((x) => x)
                    newArr[editingField].label = e.target.value
                    setFields(newArr)
                  }}
                />
              </Form.Item>
              <Form.Item label="默认值">
                <Input
                  value={fields[editingField].default_status_value}
                  onChange={(e) => {
                    let newArr = fields.map((x) => x)
                    newArr[editingField].default_status_value = e.target.value
                    setFields(newArr)
                  }}
                />
              </Form.Item>
              <Form.Item label="注意事项">
                <Input
                  defaultValue="无"
                  value={fields[editingField].notes}
                  onChange={(e) => {
                    let newArr = fields.map((x) => x)
                    newArr[editingField].notes = e.target.value
                    setFields(newArr)
                  }}
                />
              </Form.Item>
              <Form.Item label="值类型">
                <Select value={fields[editingField].status_value_type} disabled>
                  <Select.Option value="数值">数值</Select.Option>
                  <Select.Option value="文本">文本</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="是否公开">
                <Switch
                  defaultChecked={true}
                  checked={Boolean(fields[editingField].is_private)}
                  onChange={(checked: boolean) => {
                    let newArr = fields.map((x) => x)
                    newArr[editingField].is_private = Number(checked)
                    setFields(newArr)
                  }}
                />
              </Form.Item>
              <Form.Item label="是否允许协作">
                <Switch
                  defaultChecked={true}
                  checked={Boolean(fields[editingField].allow_other)}
                  onChange={(checked: boolean) => {
                    let newArr = fields.map((x) => x)
                    newArr[editingField].allow_other = Number(checked)
                    setFields(newArr)
                  }}
                />
              </Form.Item>
              {(fields[editingField].field_type === 'single_choice' ||
                fields[editingField].field_type === 'multiple_choice' ||
                fields[editingField].field_type === 'drop_down') && (
                <Form.Item label="选项">
                  {fields[editingField].multiple_choice.map((x, option_index) => {
                    return (
                      <div
                        key={option_index}
                        style={{
                          display: 'flex',
                          marginBottom: 10,
                          alignItems: 'center',
                        }}
                      >
                        <Input
                          value={fields[editingField].multiple_choice[option_index]}
                          onChange={(e) => {
                            let newArr = fields.map((x) => x)
                            newArr[editingField].multiple_choice[option_index] = e.target.value
                            setFields(newArr)
                          }}
                        />
                        <MinusCircleOutlined
                          style={{
                            cursor: 'pointer',
                            fontSize: 15,
                            padding: 5,
                          }}
                          onClick={() => {
                            let newArr = fields.map((x) => x)
                            if (newArr[editingField].multiple_choice.length <= 2) {
                              message.info('选项数量不能小于2')
                              return
                            }
                            newArr[editingField].multiple_choice.splice(option_index, 1)
                            setFields(newArr)
                          }}
                        />
                      </div>
                    )
                  })}
                  <Button
                    size="small"
                    onClick={() => {
                      let newArr = fields.map((x) => x)
                      newArr[editingField].multiple_choice.push('')
                      setFields(newArr)
                    }}
                  >
                    添加
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: -10,
            }}
          >
            <span>
              {editingField > 0 && (
                <Button type="primary" onClick={preField}>
                  上一字段
                </Button>
              )}
              {editingField < fields.length - 1 && (
                <Button type="primary" style={{ marginLeft: 20 }} onClick={nextFiled}>
                  下一字段
                </Button>
              )}
            </span>
          </div>
        </div>
      </Col>
    </Row>
  ) : null
}

export default CreateTemplateStepThree
