import * as React from 'react';
import { message, Upload, Modal, Input } from 'antd';
import { download } from '@/utils/string';

const CustomMenuItem = (props: { url: string; title: string; isBatch?: boolean }) => {
  return props.isBatch ? (
    <Upload
      showUploadList={true}
      action={props.url}
      multiple
      beforeUpload={(file, fileList) => {
        console.log('file is:', file);

        const checkType = file.name.slice(-3) === 'csv';
        if (!checkType) {
          message.error('只支持上传 CSV 格式的文件!');
          return false;
        }
        const checkSize = file.size / 1024 / 1024 < 300;
        if (!checkSize) {
          message.error('文件大小不能大于300MB!');
          return false;
        }

        if (checkSize && checkType) {
          message.loading('上传中');
        }

        return checkSize && checkType;
      }}
      onChange={({ file, fileList, event }) => {
        if (file.status === 'done') {
          message.destroy();
          message.success('任务创建成功，请在上传任务列表中查看上传状态');
        }
        if (file.status === 'error') {
          message.destroy();
          message.error('上传失败');
        }
      }}
    >
      <span className="dropmenu-item">{props.title}</span>
    </Upload>
  ) : (
    <Upload
      showUploadList={true}
      action={props.url}
      beforeUpload={(file, fileList) => {
        console.log('file is:', file);

        const checkType = file.name.slice(-3) === 'csv';
        if (!checkType) {
          message.error('只支持上传 CSV 格式的文件!');
          return false;
        }
        const checkSize = file.size / 1024 / 1024 < 300;
        if (!checkSize) {
          message.error('文件大小不能大于300MB!');
          return false;
        }

        if (checkSize && checkType) {
          message.loading('上传中');
        }

        return checkSize && checkType;
      }}
      onChange={({ file, fileList, event }) => {
        if (file.status === 'done') {
          message.destroy();
          message.success('任务创建成功，请在上传任务列表中查看上传状态');
        }
        if (file.status === 'error') {
          message.destroy();
          message.error('上传失败');
        }
      }}
    >
      <span className="dropmenu-item">{props.title}</span>
    </Upload>
  );
};

const CostomUploadForTemplateMenu = (props: { id: number; title: string }) => {
  return (
    <Upload
      showUploadList={false}
      action={`v2/api/asset/csv/template/upload/${props.id}`}
      beforeUpload={(file, fileList) => {
        console.log('file is:', file);
        const checkType = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';

        if (!checkType) {
          message.error('只支持上传 CSV 格式的文件!');
          return false;
        }
        const checkSize = file.size / 1024 / 1024 < 300;
        if (!checkSize) {
          message.error('文件大小不能大于300MB!');
          return false;
        }
        if (checkSize && checkType) {
          message.loading('上传中');
        }
        return checkSize;
      }}
      onChange={({ file, fileList, event }) => {
        if (file.status === 'done') {
          message.destroy();
          message.success('上传成功');
        }
        if (file.status === 'error') {
          message.destroy();
          message.error('上传失败');
        }
      }}
    >
      {props.title}
    </Upload>
  );
};

const CustomMenuItemForTemplateMenu = (props: { id: number; title: string }) => {
  const filename = React.useRef('');
  return (
    <span
      onClick={() => {
        Modal.confirm({
          title: '请输入文件名',
          content: <Input onChange={(e) => (filename.current = e.target.value)} />,
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            download(`/v2/api/asset/csv/template/download/${props.id}`, `${filename.current}`);
          },
          onCancel: () => {},
        });
      }}
    >
      {props.title}
    </span>
  );
};

export { CustomMenuItem, CostomUploadForTemplateMenu, CustomMenuItemForTemplateMenu };
