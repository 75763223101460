import * as React from 'react';
import { message, Modal, Button, Form, Input, Select, Empty, Divider, Tree, Spin, Table, Layout, Radio } from 'antd';
import * as authRequests from '@/requests/role';
import '../styles/index.less';
import CreateAuthModal from '../components/CreateAuthModal';
import UpdateAuthModal from '../components/UpdateAuthModal';
import { formItemLayout } from '../configs';
import { PlusCircleOutlined } from '@ant-design/icons';

const AuthorityManage = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<SysMenuItem[] | undefined>(undefined);
  const [createAuthModalVisible, setCreateAuthModalVisible] = React.useState(false);
  const [tableData, setTableData] = React.useState<SysMenuItem[]>([]);
  const [parentId, setParentId] = React.useState<number | null>(0);
  const [btnName, setBtnName] = React.useState('');
  const [viewingAuth, setViewingAuth] = React.useState<SysMenuItem | undefined>(undefined);
  const [updateAuthModalVisible, setUpdateAuthModalVisible] = React.useState(false);
  const [editingAuth, setEditingAuth] = React.useState<SysMenuItem | undefined>(undefined);

  //获取系统权限列表
  const fetchData = async () => {
    const res = await authRequests.getSysMenuTree();
    if (res.code === 0) {
      if (res.data.length > 0) {
        setData(res.data);
        handleTableData(res.data, parentId);
      }
    } else {
      message.error(res.message);
    }
    setLoading(false);
    return res.data;
  };

  //将系统权限数据处理成tree数据,用于渲染tree组件
  const handleTreeData = function (org_data: SysMenuItem[]): any {
    if (!org_data || org_data.length < 1) return;
    return org_data.map((x) => {
      return {
        title: x.name,
        key: x.id,
        icon: x.icon,
        children: handleTreeData(x.children),
      };
    });
  };

  //将系统权限数据处理成table数据,用于渲染table组件
  const handleTableData = function (treeNode: SysMenuItem[], key: number | null) {
    if (key === null) return;
    if (!treeNode || !treeNode.length) return;

    for (let i = 0, len = treeNode.length; i < len; i++) {
      if (treeNode[i].id === key) {
        setTableData(treeNode[i].children);
        break;
      } else {
        if (treeNode[i].children.length >= 1) {
          handleTableData(treeNode[i].children, key);
        }
      }
    }
  };

  React.useEffect(() => {
    setLoading(true);
    fetchData().then((res) => {
      if (res.length > 0) {
        setParentId(1);
        handleTableData(res, 1);
        setBtnName(res[0].name);
      }
    });
  }, []);

  const colunms = [
    {
      title: '序号',
      dataIndex: 'order',
      key: 'order',
      align: 'center' as 'left',
      render: (text: any, record: SysMenuItem, index: number) => `${index + 1}`,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      align: 'left' as 'left',
      render: (text: any, record: SysMenuItem, index: number) => `${record.name}`,
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
      align: 'left' as 'left',
      render: (text: any, record: SysMenuItem, index: number) => `${record.comment ? record.comment : '-'}`,
    },
    {
      title: 'url',
      dataIndex: 'url',
      key: 'url',
      align: 'left' as 'left',
      render: (text: any, record: SysMenuItem, index: number) => `${record.url}`,
    },
    {
      title: '状态',
      dataIndex: 'visible',
      key: 'visible',
      align: 'left' as 'left',
      render: (text: any, record: SysMenuItem, index: number) => (
        <span
          style={{
            color: record.visible === '可见' ? 'green' : 'red',
          }}
        >
          {record.visible}
        </span>
      ),
    },
    {
      title: '操作',
      key: 'actions',
      align: 'center' as 'left',
      render: (text: any, record: SysMenuItem, index: number) => {
        return (
          <span>
            <a
              key="view"
              onClick={() => {
                setViewingAuth(record);
              }}
            >
              查看
            </a>

            <Divider type="vertical" />

            <a
              key="edit"
              onClick={() => {
                setEditingAuth(record);
                setUpdateAuthModalVisible(true);
              }}
            >
              编辑
            </a>

            <Divider type="vertical" />

            <a
              key="delete"
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: '提示',
                  content: '将删除该权限以及其所有子节点权限，确认删除吗？',
                  okText: '确认',
                  onOk: async () => {
                    const { id } = record;
                    const res = await authRequests.deleteSysMenuItem({
                      id,
                    });
                    if (res.code === 0) {
                      message.success('删除成功！');
                      fetchData();
                    } else {
                      message.error(res.message);
                    }
                  },
                  cancelText: '取消',
                  onCancel: () => {},
                });
              }}
            >
              删除权限
            </a>
          </span>
        );
      },
    },
  ];

  const AuthInfoModal = ({
    authItem,
    setEditingAuth,
  }: {
    authItem: SysMenuItem | undefined;
    setEditingAuth: Function;
  }) => {
    if (!authItem) return null;
    const { name, id, parent_id, comment, create_time, request_type, url, menu_type, visible } = authItem;
    return (
      <Modal
        title={
          <span>
            <div className="title-prefix"></div>权限详情
          </span>
        }
        okText="确认"
        cancelText={null}
        visible={!!authItem}
        onOk={() => setEditingAuth(undefined)}
        onCancel={() => setEditingAuth(undefined)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Form {...formItemLayout}>
          <Form.Item label="权限名称">
            <Input value={name} disabled />
          </Form.Item>
          <Form.Item label="id">
            <Input value={id} disabled />
          </Form.Item>
          <Form.Item label="父级">
            <Input value={parent_id} disabled />
          </Form.Item>
          <Form.Item label="描述">
            <Input.TextArea value={comment} disabled />
          </Form.Item>
          <Form.Item label="请求类型">
            <Select value={request_type} disabled />
          </Form.Item>
          <Form.Item label="url">
            <Input value={url} disabled />
          </Form.Item>
          <Form.Item label="权限类型">
            <Input value={menu_type} disabled />
          </Form.Item>
          <Form.Item label="是否可见">
            <Radio.Group value={visible} disabled>
              <Radio value={'可见'}>可见</Radio>
              <Radio value={'不可见'}>不可见</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  return (
    <div className="auth-manage-page">
      <div>
        {loading ? (
          <Spin spinning={loading} tip="加载中" style={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : data ? (
          <Layout>
            <Layout.Sider
              style={{
                padding: 8,
                border: '1px solid #f0f0f0',
                minWidth: 300,
              }}
              theme="light"
            >
              <h4>
                <div className="title-prefix"></div>目录结构
              </h4>
              <Divider></Divider>
              <Tree
                treeData={handleTreeData(data)}
                onSelect={(key, e) => {
                  let key_num: number | null;
                  if (key.length < 1) {
                    key_num = null;
                  } else {
                    key_num = Number(key[0]);
                  }
                  setParentId(key_num);
                  handleTableData(data, key_num);
                  console.log(e);
                  setBtnName(e.node.title as string);
                }}
                defaultSelectedKeys={['1']}
                defaultExpandedKeys={['1']}
              />
            </Layout.Sider>
            <Layout.Content
              style={{
                backgroundColor: '#ffffff',
                marginLeft: 20,
                padding: 16,
                border: '1px solid #f0f0f0',
              }}
            >
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => setCreateAuthModalVisible(true)}
                disabled={parentId === 0 || parentId === null}
                style={{ marginBottom: 16 }}
              >
                {`创建${btnName}子权限`}
              </Button>
              <Table
                dataSource={tableData}
                // style={{ height: '100%', margin: '0 10px' }}
                columns={colunms as any}
                expandIcon={() => null}
                rowKey={(record) => record.id.toString()}
              ></Table>
            </Layout.Content>
          </Layout>
        ) : (
          <Empty description="还未创建任何权限，点击创建根权限">
            <Button type="primary" onClick={() => setCreateAuthModalVisible(true)} disabled={loading}>
              创建权限
            </Button>
          </Empty>
        )}
      </div>
      <CreateAuthModal
        visible={createAuthModalVisible}
        setVisible={setCreateAuthModalVisible}
        fetchData={fetchData}
        parent_id={parentId}
      />
      <AuthInfoModal authItem={viewingAuth} setEditingAuth={setViewingAuth} />
      {updateAuthModalVisible && (
        <UpdateAuthModal
          visible={updateAuthModalVisible}
          setVisible={setUpdateAuthModalVisible}
          fetchData={fetchData}
          authItem={editingAuth}
        />
      )}
    </div>
  );
};

export default AuthorityManage;
